import { createSlice } from "@reduxjs/toolkit";

import {
  fetchListingMatches,
  fetchListingMatchesFilters,
  updateListingMatches,
} from "./thunk";

const initialState = {
  listingMatches: [],
  links: {},
  filters: {
    supply_channel_filter: [],
    sales_channel_filter: [],
    match_type_filter: [],
    accuracy_filter: [],
    monthly_sales_filter: [],
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  loading: "idle",
  error: null,
  isLogin: false,
  selected: [],
  quantity: [],
  changes: 0,
};

export const listingMatchSlice = createSlice({
  name: "listing-match",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    removeListingMatch: (state, action) => {
      let id = action.payload.id;
      // console.log("id", id);
      state.listingMatches = [
        ...state.listingMatches.filter((item) => item.id !== id),
      ];
    },
    updateFilter: (state, action) => {
      // console.log(action, 'updated here')
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    setSelection: (state, action) => {
      if (state.selected?.includes(action.payload)) {
        return {
          ...state,
          selected: state.selected?.filter((item) => item !== action.payload),
        };
      }
      return { ...state, selected: [...state.selected, action.payload] };
    },
    clearSelection: (state, action) => {
      return { ...state, selected: [] };
    },
    increaseChanges: (state, action) => {
      return {
        ...state,
        changes: action.payload,
      };
    },
    decreaseChanges: (state, action) => {
      return {
        ...state,
        changes: action.payload,
      };
    },
    removeSelectionListingMatch: (state, action) => {
      state.listingMatches = [
        ...state.listingMatchData.filter(
          (item) => !state.selected.includes(item.id)
        ),
      ];
    },

    setQuantity: (state, action) => {
      // console.log('working', action.payload);
      const quaObj = state.listingMatches.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            sales_quantity: action.payload.salesQuantity,
            supply_quantity: action.payload.supplyQuantity,
          };
        }
        return item;
      });

      return {
        ...state,
        listingMatches: quaObj,
      };

      // return{
      //   ...state,
      //   quantity: action.payload.quantity
      // }
      //console.log("***********", state.quantity.map(id => id.id).find(action.payload.id))
      // if (state?.quantity.map(item => (item.payload.id === action.payload.id )  {
      //   console.log('Here', item)
      //   return {
      //     ...state,
      //     quantity: action.payload.quantity
      //   }
      // )
      // }

      // state?.quantity.map((item) => {
      //   if (item.payload.id === action.payload.id) {
      //     console.log('Here')
      //     return {
      //       ...state,
      //       quantity: action.payload
      //     }
      //   }
      // })
      // if (!state?.quantity.map(id => id.id).includes(action.payload.id)) {
      //   console.log('Here Now')
      //   return { ...state, quantity: [...state.quantity, action.payload] }
      // }
      // else if (state.quantity.map(id => id.id).includes(action.payload.id)){
      //   return {...state, quantity: [...state.quantity, quantity:quantity+1]}
      // }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchListingMatches.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchListingMatches.fulfilled, (state, action) => {
        // console.log(action,);
        if (state.loading === "pending") {
          state.loading = "idle";
          let len = state.listingMatches.length;
          let data = action.payload.data.filter((item) => {
            return !state.listingMatches
              .map((item) => item.id)
              .includes(item.id);
          });
          state.listingMatches = [...state.listingMatches, ...data];

          // state.listingMatches = [...state.listingMatches, ...action.payload.data.slice(0,20 - len)];
          // state.listingMatches = [...state.listingMatches, ...action.payload.data];
          state.listingMatches = action.payload.data;

          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchListingMatches.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(updateListingMatches.fulfilled, (state, action) => {})

      .addCase(fetchListingMatchesFilters.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.filtersMeta = action.payload?.data;
      });
  },
});

export const {
  updateFilter,
  removeListingMatch,
  setSelection,
  clearSelection,
  removeSelectionListingMatch,
  setQuantity,
  increaseChanges,
  decreaseChanges,
  updateListingMatch,
  updateBoundFilter,
} = listingMatchSlice.actions;

export {
  fetchListingMatches,
  updateListingMatches,
  fetchListingMatchesFilters,
};

export default listingMatchSlice.reducer;
