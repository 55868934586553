/**
 * @author Oguz Cinar
 * @description InventoryPurchases
 */

import InventoryPurchases from "../inventory-purchases";

const ProductInventoryPurchases = ({ product_id }) => {
  return (
    <div>
      <InventoryPurchases
        hideFilter={"product"}
        product_id={product_id}
        displayTitle={"hidden"}
      />
    </div>
  );
};

export default ProductInventoryPurchases;
