function quantityHandler(num, unit) {
    if (num === null || num === undefined) {
        return '';
    }

    if (num === 0) {
        return '0';
    }

    if (typeof num === "string") {
        num = parseFloat(num);
    }

    if (isNaN(num)) {
        return '';
    }

    const numString = num.toString();
    const decimalIndex = numString.indexOf(".");

    if (unit) {
        if (decimalIndex !== -1 && decimalIndex <= numString.length - 4 && numString.substr(decimalIndex, 4) === ".000") {
            return numString.substring(0, decimalIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + unit?.code; // add comma separator
        } else if (decimalIndex !== -1) {
            return parseFloat(numString).toFixed(3) + ' ' + unit?.code; // fix to 3 decimal places
        } else {
            return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + unit?.code; // add comma separator
        }
    } else {
        if (decimalIndex !== -1 && decimalIndex <= numString.length - 4 && numString.substr(decimalIndex, 4) === ".000") {
            return numString.substring(0, decimalIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ","); // add comma separator
        } else if (decimalIndex !== -1) {
            return parseFloat(numString).toFixed(3); // fix to 3 decimal places
        } else {
            return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // add comma separator
        }
    }

}


export default quantityHandler;