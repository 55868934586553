import api from "../../../../../../store/orders/api";
import prepareDataToView from "./prepareDataToView";

const updateOrderItemRow = (orderId, orderItemId) => {
    return new Promise((resolve, reject) => {
        api.viewOrder(orderId)
            .then((response) => {
                const orderItem = prepareDataToView(response?.data?.data)
                resolve(orderItem?.summary?.order_items.find(
                    (item) => item.id === orderItemId
                ));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default updateOrderItemRow;
