import { useSelector } from "react-redux";
import React from "react";
import constant from "../../../constant";

const FiltersView = (selectedFilers) => {
  return [
    {
      name: "name",
      label: "Name",
      type: "input",
      placeholder: "ex. Pegasus, Amazon SAZ1, Amazon MOB9, Walmart K49...",
      defaultValue: selectedFilers?.name || null,
      toggle: true,
      innerClassName: "bg-gray-50",
    },
    {
      name: "country",
      label: "Country",
      placeholder: "Select the country",
      type: "Select",
      features: {
        isMulti: true,
        formatOptionLabel: (child) => (
          <div className={"flex flex-row items-center gap-[8px]"}>
            <span className={"text-gray-800"}>{child?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: constant.APIURL.GET_COUNTRIES,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: selectedFilers?.country || null,
      toggle: true,
    },
    {
      name: "state",
      label: "State",
      placeholder: "Select the state",
      type: "Select",
      features: {
        isMulti: true,
        formatOptionLabel: (child) => (
          <div className={"flex flex-row items-center gap-[8px]"}>
            <span className={"text-gray-800"}>{child?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: constant.APIURL.GET_STATES,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: selectedFilers?.state || null,
      toggle: true,
    },
  ];
};

export default FiltersView;
