import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { fetchLoading } from '.';

const getAllPickupPoints = createAsyncThunk(
  'get:api/v1/pickup_point',
  async (payload, { dispatch, getState }) => {
    if (!payload?.append) {
      dispatch(fetchLoading());
    }
    const data = await api.GetAllPickupPoints(
      typeof payload === "string" ? payload : payload.query
    );
    return { ...data?.data, append: payload?.append };
  }
);


export {
  getAllPickupPoints,
};