import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import React, { useEffect } from "react";
import UI from "../../dynamic-form/elements";
import { FiLoader } from "react-icons/fi";
import DynamicForm from "../../dynamic-form";
import priceHandler from "../../../utils/priceHandler";

const FooterPagination = ({
  meta,
  resultsText
}) => {
  function priceHandler2(
    num,
    currency,
    thousandsSeparator = ",",
    decimalSeparator = "."
  ) {
    if (num === null || num === undefined) {
      return "";
    }
    if (typeof num === "string") {
      num = num.replace(/,/g, "");
    }
  
    if (isNaN(num) || num === "" || num === null || num === undefined) {
      return "-";
    }
  
    const symbol = currency?.symbol || "";
    const position = currency?.position || "0";
  
    let numString;
  
    // Check if the number is an integer
    if (Number.isInteger(parseFloat(num))) {
      numString = parseFloat(num).toString(); // Keep it as an integer string
    } else {
      // Ensure the number is always formatted to two decimal places
      numString = parseFloat(num).toFixed(2);
    }
  
    if (decimalSeparator !== ".") {
      numString = numString.replace(".", decimalSeparator);
    }
  
    if (position === "0") {
      return addSeparator(numString, thousandsSeparator) + symbol;
    } else {
      return symbol + addSeparator(numString, thousandsSeparator);
    }
  }
  
  function addSeparator(num, separator) {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  }
  
  const [innerMera, setInnerMeta] = React.useState(meta);
  const [wait, setWait] = React.useState(true);
  useEffect(() => {
    setInnerMeta(meta);
    setWait(false);
  }, [meta]);

  let Pagination = {
    from: 1,
    to: innerMera?.to || 0,
    total: innerMera?.total || 0,
    current_page: innerMera?.current_page || 1,
    last_page: innerMera?.last_page || 1,
    totalPages: Array.from(
      { length: Math.min(Math.ceil((innerMera?.total || 0) / 20), 5) },
      (_, index) => {
        const startPage = Math.max(innerMera?.current_page - 2, 1);
        const pageNumber = startPage + index;
        return pageNumber <= innerMera?.last_page ? pageNumber : null;
      }
    ).filter((pageNumber) => pageNumber !== null),
    limit: meta?.per_page,
  };

  const handlePageChange = (pageNumber) => {
    setWait(true);
    setInnerMeta({ ...innerMera, current_page: pageNumber });
  };

  if (Pagination.totalPages[0] !== 1) {
    if (Pagination.last_page > 2 && Pagination.current_page - 3 !== 1) {
      Pagination.totalPages.unshift("...");
    }

    Pagination.totalPages.unshift(1);
  }
  if (
    Pagination.totalPages[Pagination.totalPages.length - 1] !==
    Pagination.last_page
  ) {
    if (
      Pagination.last_page > 2 &&
      Pagination.current_page + 3 !== Pagination.last_page
    ) {
      Pagination.totalPages.push("...");
    }
    Pagination.totalPages.push(Pagination.last_page);
  }
  const rowPerpageRef = React.useRef();
  const resultsLowerCase = resultsText?.toLowerCase()
  return (
    <div
      className={
        "flex items-center justify-between px-[16px] h-[48px] fixed bottom-0 left-0 w-full shadow bg-white z-4"
      }
    >
      <div className="flex items-center mx-[8px]  text-base font-normal text-gray-900">
        © 2024 Arbitbox.{" "}
        <span className="text-gray-400"> &nbsp; All rights reserved.</span>
      </div>
      <p className="text-[16px] text-gray-900 font-medium">
        <span className={"text-gray-400"}>Showing</span> {Pagination.from}
        <span className={"text-gray-900"}>-</span>
        {Pagination.to}
        &nbsp;<span className={"text-gray-400"}>of</span>&nbsp;
        {priceHandler2(Pagination.total)} {resultsLowerCase || "results"}
      </p>
    </div>
  );
};
export default FooterPagination;
