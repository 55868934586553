import React, {useEffect} from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import ItemID from "../../dynamic-page/components/ItemID";

const TableView = (list, handleTableAction) => {

    const nullHandle = (data, chained) => {
        if (!data || !chained) {
            return "-";
        } else {
            return chained;
        }
    };

    const [defaultOptions, setDefaultOptions] = React.useState([]);
    useEffect(() => {
        fetchEmployees("?filter[status]=active").then((response) => {
            const options = mapOptionsToValues(response.data);
            setDefaultOptions(options);
        });
    }, []);

    const fetchEmployees = async (params) => {
        let url = "api/v1/users";
        if (params) {
            url = url.concat(params);
        }
        const response = await axios.get(url, {withCredentials: true});
        return response.data;
    };

    const mapOptionsToValues = (options) => {
        return options.map((option) => ({
            value: option?.id,
            label: option?.first_name,
            image: option?.image_url,
        }));
    };

    const loadOptions = async (inputValue) => {
        let url = `?filter[name]=` + inputValue + "&filter[status]=1";
        return new Promise((resolve) => {
            fetchEmployees(url)
                .then((response) => {
                    const options = mapOptionsToValues(response.data);
                    resolve(options);
                })
                .catch((error) => {
                });
        });
    };

    const renderOption = (option) => {
        return (
            <div className="flex items-center">
                <img
                    src={option.image || "/assets/images/defaultAvatar.png"}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/images/defaultAvatar.png";
                    }}
                    alt="profile"
                    className="w-8 h-8 rounded-full mr-2"
                />
                <div>{option.label}</div>
            </div>
        );
    };

    return {
        list: list,
        theme: {
            "brand name": (item) => (
                <div className="flex items-center h-14 text-[16px] font-[400] text-gray-900 justify-start text-center">
                    {nullHandle(item, item?.name)}
                </div>
            ),
            "brand code": (item) => (
                <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-start text-center">
                    {nullHandle(item, item?.code)}
                </div>
            ),
            "assigned to": (item) => (
                <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-start text-center">
                    <AsyncSelect
                        loadOptions={loadOptions}
                        placeholder="Search Employees"
                        defaultOptions={defaultOptions}
                        options={defaultOptions}
                        cacheOptions
                        onChange={(selected) => {
                            handleTableAction("assign", {
                                assigned_user_id: selected?.value,
                                brand_ids: [item?.id],
                            });
                        }}
                        value={
                            item?.assigned
                                ? {
                                    label: item?.assigned?.first_name,
                                    value: item?.assigned?.id,
                                    image: item?.assigned?.image_url,
                                }
                                : null
                        }
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                height: "fit-content",
                                // minHeight: 50,
                                width: 250,
                                // minWidth: 200,
                                border: "none",
                                borderBottom: "1px solid #E5E5E5",
                                paddingBottom: 5,
                                backgroundColor: "transparent",
                                // borderRadius: 10,
                                // border:
                                //    state.isFocused
                                //     ? "1px solid #00A3FF"
                                //     : "1px solid #E5E5E5",
                                boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                                // "&:hover": {
                                //   border: state.isFocused
                                //     ? "1px solid #00A3FF"
                                //     : "1px solid #E5E5E5",
                                // },
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? "#00A3FF" : "white",
                                color: state.isSelected ? "white" : "black",
                                "&:hover": {
                                    backgroundColor: "#00A3FF",
                                    color: "white",
                                },
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                width: 300,
                                borderRadius: 10,
                                border: state.isFocused
                                    ? "1px solid #00A3FF"
                                    : "1px solid #E5E5E5",
                                boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                                "&:hover": {
                                    border: state.isFocused
                                        ? "1px solid #00A3FF"
                                        : "1px solid #E5E5E5",
                                },
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                width: "100%",
                            }),
                        }}
                        components={
                            // hide the | icon
                            {
                                IndicatorSeparator: () => null,
                            }
                        }
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        formatOptionLabel={renderOption}
                    />
                </div>
            ),

            "created by": (item) => (
                <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-start text-center">
                    {nullHandle(item, item?.created_by?.name)}
                </div>
            ),
        },
    };
};
export default TableView;
