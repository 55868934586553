import React, {useEffect, useRef} from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import selectedItem from "./select-util/selectedItem";
import fetchOptions from "./select-util/fetchOptions";
import returnValue from "./select-util/returnValue";
import style from "./select-util/style";
import checkUrl from "./select-util/checkUrls";
import {addNewAction, addNewView} from "./select-util/addNew";
import debounce from "./select-util/debounce";

const DynamicSelect = ({
                           item,
                           value,
                           validationMessage,
                           handleChange,
                           bind,
                           formData,
                           selectedValue,
                       }) => {
    if (
        item?.defaultValue &&
        typeof item?.defaultValue === "string" &&
        item?.defaultValue.includes(",")
    ) {
        item.defaultValue = item.defaultValue.split(",");
    }

    const SelectComponent = item?.addNew ? CreatableSelect : Select;

    const [loadedOptions, setLoadedOptions] = React.useState([]);
    const [innerValue, setInnerValue] = React.useState(
        item?.defaultBound
            ? {
                label: item?.defaultBound?.name || "",
                value: item?.defaultBound?.id || "",
            }
            : null
    );
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [innerFormData, setInnerFormData] = React.useState(formData);
    const [initial, setInitial] = React.useState(false);

    const selectRef = useRef(null);

    const loadOptions = debounce((inputValue) => {
        if (item.api) {
            const defaultVal = item?.defaultBound ? null : item.defaultValue;
            setLoading(true);
            fetchOptions(
                item.api,
                inputValue,
                value,
                defaultVal,
                item?.list,
                formData,
                item?.defaultBound
            ).then((data) => {
                setLoading(false);
                setLoaded(true);
                setLoadedOptions(data);
            });
        } else if (item.list && !item.api) {
            setLoadedOptions(item.list);
        }
    }, 500);

    const selectOption = () => {
        const selected = selectedItem(value, loadedOptions);
        if (bind) bind(selected, item.name);
        return selected;
    };

    useEffect(() => {
        if (
            value &&
            value !== item?.defaultBound?.id &&
            loadedOptions.length > 0 &&
            !initial
        ) {
            // setInnerValue(selectOption())
            setShowValue(selectOption());
        }
    }, [loadedOptions]);

    useEffect(() => {
        if (loadedOptions.length === 0 && /*!initial &&*/ !item?.disablePreload) {
            loadOptions();
        }
    }, []);

    useEffect(() => {
        if (value && value !== item?.defaultBound?.id) {
            setInnerValue(selectOption());
            // setShowValue(selectOption())
        }
        // if (value === item?.defaultValue) {
        //   // setInnerValue(null)
        //   setShowValue(selectOption())
        // }

        const check = checkUrl(
            item.api,
            formData,
            innerFormData,
            value,
            loadedOptions,
            loading
        );
        if (
            value &&
            !showValue &&
            // value is not found in the loaded options
            loadedOptions.filter((option) => option.value === value).length === 0
        ) {
            if (check.related) {
                if (check.load) {
                    loadOptions();
                }
            } else {
                loadOptions();
            }
        }
        if (check.reset) {
            setLoaded(false);
        }
        setIsDisabled(check.disable);

        if (value === "") {
            selectRef.current.setValue(null);
            setLoadedOptions([]);
        }
        // if(!loading) setIsDisabled(false);
    }, [value, loadedOptions, loading]);

    // useEffect(() => {
    //   const updatedOptions = selectedValue
    //     ? [...loadedOptions, selectedValue] // Add the selectedValue to the options
    //     : loadedOptions; // Use the original options if selectedValue is null

    //   setLoadedOptions(updatedOptions);
    // }, [selectedValue]);

    useEffect(() => {
        const check = checkUrl(
            item.api,
            formData,
            innerFormData,
            value,
            loadedOptions,
            loading
        );
        if (check.reset) {
            setLoaded(false);
        }
        setInnerFormData(formData);
        setIsDisabled(check.disable);

        if (check.load) {
            loadOptions();
        }

        if (check.reset) {
            setLoadedOptions([]);
            setInnerValue(null);
            handleChange(null, item.name, null);
        }
    }, [formData]);

    const [showValue, setShowValue] = React.useState(innerValue);

    return (
        <div className={`${item.innerClassName || ""} dynamicForm`}>
            <style>
                {`
                    .dynamicForm .css-1f43avz-a11yText-A11yText,
                    .css-b62m3t-container {
                        width: 100%;
                    }
                    .dynamicForm .css-1y6o3d0-menu {width:100%}

                    .dynamicForm  arbit-select__clear-indicator  {
                      display: none !important;
                      visibility: hidden !important;
                    }
                `}
            </style>
            {/*{loadedOptions.length < 1 ?
                <>Loading..</>
                :*/}
            <SelectComponent
                ref={selectRef}
                //loadOptions={loadOptions}
                onInputChange={(changeValue) => {
                    //if (changeValue !== value && ) {
                    loadOptions(changeValue);
                    if (changeValue === "") {
                        setInnerValue(null);
                        setShowValue(null);
                        loadOptions("");
                    }
                    //}
                }}
                classNamePrefix="arbit-select"
                isClearable={true}
                isMulti={item?.isMulti}
                isLoading={value || innerValue || showValue ? false : loading}
                options={loadedOptions}
                // defaultMenuIsOpen={true}
                menuPortalTarget={document.body}
                //defaultOptions
                value={item?.features?.isMulti ? innerValue : showValue}
                formatCreateLabel={addNewView}
                onCreateOption={(newOption) => {
                    addNewAction(newOption, item, formData, loadOptions);
                }}
                defaultValue={null}
                onChange={(newData) => {
                    setShowValue(newData);
                    setInnerValue(newData);
                    handleChange(returnValue(newData), item.name, newData);
                }}
                className={`h-full !min-w-full block ${
                    item.innerClassName2 || ""
                } !bg-transparent`}
                isDisabled={
                    isDisabled ||
                    (item?.disabled && typeof item?.disabled === "function"
                        ? item?.disabled(formData)
                        : item?.disabled)
                }
                placeholder={item.placeholder}
                onMenuOpen={() => {
                    setInitial(true);
                    if (!item?.features?.isMulti) {
                        setShowValue("");
                    }

                    // if (loadedOptions.length === 0 && !item?.disablePreload )
                    //   loadOptions();
                    // if (loadedOptions.length === 0) loadOptions();
                }}
                onMenuClose={() => {
                    setShowValue(selectOption(value, loadedOptions));
                }}
                onFocus={() => {
                    // when focus remove the singleValue component or hide it
                    if (!item?.features?.isMulti) {
                        setShowValue("");
                    }
                }}
                onBlur={() => {
                    setInitial(false);
                    // when blur remove the singleValue component or hide it
                    if (!item?.features?.isMulti) {
                        setShowValue(selectOption(value, loadedOptions));
                    }
                }}
                styles={style({
                    validation: validationMessage,
                    style: item.style || {},
                })}
                {...item.features}
            />
            {/*}*/}
        </div>
    );
};

export default DynamicSelect;
