// ------------------------------
// Good Acceptance
// ------------------------------

// Import main libraries
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// Import dynamic page libraries
import TabButton from "../dynamic-page/elements/TabButton";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import util from "../dynamic-page/util";
import DynamicTable from "../dynamic-page";
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
import SupplyPurchaeModal from "../Supplies/newSupplyPurchase";
import SingleSupplyPurchaseModal from "./single-purchase-modal";
import CreatePaymentModal from "../payments-purchase/add-edit-modal";
import AddAdjustmentModal from "../inventory-adjustments/add-edit-modal";
import EditModal from "./purchase-edit-modal";
import ConfirmModal from "../dynamic-page/util/confirmModal";
// Import model-view libraries
import filtersView from "../inventory-purchases/modals-view/filters";
import sortView from "./modals-view/sort";

// Import redux actions
import {
  bulkDeleteSupPurchases,
  fetchSupPurchases as fetchData,
  fetchSupPurchasesFilters as fetchFilters,
  removeSupPurchases,
  updateBoundFilter,
  updateFilter,
  updateSupplyPurchase,
  updateSupPurchasesStatus,
  updateSupPurchasesStatuses,
} from "../../store/supply-purchase";

import { supplyFulfilment } from "../../store/supplies";
import notifier from "../dynamic-page/util/notifier";
import getActionList from "../dynamic-page/util/getActionList";
import TableView from "./modals-view/table";
import { createPayment } from "../../store/purchase-payments";
import { createInventory } from "../../store/inventory";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import moment from "moment";
import useSupplyTabsCounts from "../../utils/useSupplyTabsCounts";

// Main Component
const SupplyPurchases = ({ product_id }) => {
  // Define hooks.
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // First page load.
  useEffect(() => {
    const startPageByFilters = {
      search: "",
      sort: "-created_at",
      page: 1,
      limit: 10,
      filters: {
        only_acceptable: 1,
        ...(product_id && { product_id: product_id }),
      },
      includes: ["supplier,product.brand.assigned,supplyRequest"],
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(
      storeQuery,
      "type=supply_purchases",
      "term",
      true
    );

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.supplyPurchase);
  const tableData = useSelector((state) => state.supplyPurchase.supPurchases);
  const user = useSelector((state) => state.authUser.authUser);
  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle form modal.
  const [calledItemInAction, setCalledItemInAction] = useState(null);

  // Handle modal.
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [visiblePurchaseModal, setVisiblePurchaseModal] = useState(false);
  const [visibleSinglePurchaseModal, setVisibleSinglePurchaseModal] =
    useState(false);
  const [visibleCreatePaymentModal, setVisibleCreatePaymentModal] =
    useState(false);
  const [visibleAdjustmentModal, setVisibleAdjustmentModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const termParam = searchParams.get("term");
  const [searchTerm, setSearchTerm] = useState(termParam || "");

  const [accounts, setAccounts] = useState([]);

  const optionsAccounts = accounts?.map((item) => {
    return {
      value: item.id,
      label: item.bankName,
      availableBalance: item?.availableBalance,
    };
  });

  const onFulfill = (data, callBack) => {
    dispatch(supplyFulfilment(data))
      .unwrap()
      .then((result) => {
        callBack();
        notifier({ type: result?.type, message: result?.message });
        if (result?.type === "success") {
          setVisiblePurchaseModal(false);
          onPaginationApply(1);
        }
      })
      .catch((error) => {
        toast.error("Error");
      });
  };

  const addPayment = (payload) => {
    dispatch(createPayment(payload))
      .unwrap()
      .then((res) => {
        notifier({ type: res?.data?.type, message: res?.data?.message });
        if (res?.status !== 200 && res?.status !== 204) {
        } else {
          dispatch(fetchData);
          if (fetchFilters) {
            dispatch(fetchFilters);
          }
        }
      })
      .catch((err) => {
        toast.error("Something went wrong" || err?.message);
      });
    setSelectedRow([]);
  };

  const onAddAdjustment = (payload) => {
    dispatch(createInventory(payload))
      .unwrap()
      .then((res) => {
        notifier({ type: res?.data?.type, message: res?.data?.message });
        if (res?.status !== 200 && res?.status !== 204) {
        } else {
          dispatch(fetchData);
          if (fetchFilters) {
            dispatch(fetchFilters);
          }
        }
      })
      .catch((err) => {
        toast.error("Something went wrong" || err?.message);
      });
    setSelectedRow([]);
  };

  const onUpdate = (id, payload) => {
    dispatch(updateSupplyPurchase({ id, payload }))
      .unwrap()
      .then((res) => {
        notifier({ type: res?.data?.type, message: res?.data?.message });
        if (res?.status !== 200 && res?.status !== 204) {
        } else {
          // dispatch(fetchData);
          onPaginationApply();
          setVisibleEditModal(false);
          if (fetchFilters) {
            dispatch(fetchFilters);
          }
        }
      })
      .catch((err) => {
        toast.error("Something went wrong" || err?.message);
      });
    setSelectedRow([]);
  };

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    setSelectedRow([]);
    return orderId;
  };

  const onActivate = (item) => {
    dispatch(updateSupPurchasesStatus({ ids: giveSelectedItemId(item) }))
      .unwrap()
      .then((res) => {
        if (res?.status !== 200) {
          toast.info(res?.data?.message);
        } else {
          dispatch(
            updateSupPurchasesStatuses({
              status: item.status === 1 ? 0 : 1,
              ids: [item.id],
            })
          );
          if (currentTab !== "All") {
            dispatch(removeSupPurchases([item.id]));
          }
          dispatch(fetchFilters);
          toast.success(res?.data?.message || "Status updated successfully");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong" || err?.message);
      });

    setSelectedRow([]);
  };

  const onDelete = (ids) => {
    dispatch(bulkDeleteSupPurchases(ids))
      .unwrap()
      .then((result) => {
        util.notifier({
          type:
            result?.status === 200 || result?.status === 204
              ? "success"
              : "error",
          message:
            result?.status === 200 || result?.status === 204
              ? "Request deleted successfully"
              : result?.message,
          errors: result?.errors,
        });
        if (result?.status === 200 || result?.status === 204) {
          onPaginationApply();
          setConfirmationModalVisibility(false);
          // dispatch(fetchFilters(urlQueryLoader(pageData?.filters)))
        }
      })
      .catch((error) => {
        toast.error("Error deleting request");
      });
  };

  const actionList = {
    view: {
      label: "View",
      onClick: (item) => {
        setUpdateItem(item);
        setVisibleSinglePurchaseModal(true);
      },
      bulkDisable: true,
    },
    edit: {
      label: "Edit",
      onClick: (item) => {
        setUpdateItem(item);
        setVisibleEditModal(true);
      },
      bulkDisable: true,
    },
    inactive: {
      label: "Inactive",
      onClick: (item) => {
        onActivate(item);
      },
    },
    active: {
      label: "Active",
      onClick: (item) => {
        onActivate(item);
      },
    },
    createAdjustment: {
      label: "Create Adjustment",
      onClick: (item) => {
        setUpdateItem(item);
        setVisibleAdjustmentModal(true);
      },
      bulkDisable: true,
    },
    createPayment: {
      label: "Create Payment",
      onClick: (item) => {
        setUpdateItem(item);
        setVisibleCreatePaymentModal(true);
      },
      bulkDisable: true,
    },
    delete: {
      label: "Delete",
      onClick: (item) => {
        // onDelete(giveSelectedItemId(item))
        setCalledItemInAction(giveSelectedItemId(item));
        setConfirmationModalVisibility(true);
      },
    },
  };
  const statusList = {
    1: {
      label: "Active",
      actions: [
        actionList.view,
        actionList.edit,
        actionList.createAdjustment,
        actionList.createPayment,
        actionList.delete,
      ],
    },
  };

  const actions = (row) => {
    return getActionList(row, statusList, "none");
  };

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  // Handle Tabs
  const onHandleTab = (item) => {
    // onFilterApply({status: item})
    switch (item) {
      case "awaiting_supply":
        navigate(`/inventory/supply_requests?term=${searchTerm}`);
        break;
      case "awaiting_payment":
        navigate(`/inventory/supply_purchases?term=${searchTerm}`);
        break;
      case "awaiting_delivery":
        navigate(`/inventory/goods_acceptance?term=${searchTerm}`);
        break;
    }
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);
    setSearchTerm(search);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const handleTableFilters = (filters, bound) => {
    onFilterApply(filters, bound);
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });

    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const exportMapper = (data) => {
    return data?.length
      ? data?.map((item) => {
          return {
            "Supply Purchase Id": item?.id || "-",
            "Purchased At":
              moment(item?.created_at)?.format("YYYY-MM-DD HH:mm:ss") || "-",
            "Product Id": item?.product?.apid || "-",
            "Product Name": item?.product?.title || "-",
            "Product Brand": item?.product?.brand?.name || "-",
            "Product Assign": item?.product?.brand?.assigned?.name || "-",
            "Product Type":
              item?.product?.type === 1 ? "Single" : "Grouped" || "-",
            "Product Image Url": item?.product?.image || "-",
            "Supply Request Id": item?.supply_request?.id || "-",
            "Supplier Name": item?.supplier?.name || "-",
            "Request Url": item?.supply_request?.url || "-",
            Currency: item?.currency?.code || "-",
            "Unit Purchased Price":
              item?.purchase_price?.[item?.currency?.code] || "-",
            "Purchased Quantity": item?.quantity || "-",
            "Purchased Total": item?.sub_total || "-",
            "Estimated Delivery Date": item?.estimated_delivery_date || "-",
            "Purchased Comment": item?.note || item?.comment || "-",
          };
        })
      : [];
  };

  const headers = [
    "Supply Purchase Id",
    "Purchased At",
    "Product Id",
    "Product Name",
    "Product Brand",
    "Product Assign",
    "Product Type",
    "Product Image Url",
    "Supply Request Id",
    "Supplier Name",
    "Request Url",
    "Currency",
    "Unit Purchased Price",
    "Purchased Quantity",
    "Purchased Total",
    "Estimated Delivery Date",
    "Purchased Comment",
  ];

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {!product_id && (
          <>
            <ArbitProductPage.Title
              title={"Supply"}
              allowExport={true}
              exported={{
                exportData: selectedRow?.length > 0 ? selectedRow : tableData,
                exportMapper: exportMapper,
                headers: headers,
              }}
              loading={pageData.loading}
              filter={{
                filters: pageData?.boundFilters,
                pageFilters: pageData?.filters?.filters,
                discard: ["only_acceptable", "status", "product", "product_id"],
                items: filtersView(pageData?.filters?.filters),
                callback: (rs, bound) => {
                  onFilterApply(rs, bound);
                },
                count:
                  Object.values(pageData?.filters?.filters ?? {}).filter(
                    (item) => item != null && item !== ""
                  ).length -
                  (pageData?.filters?.filters?.status === undefined ||
                  pageData?.filters?.filters?.status === null
                    ? 0
                    : 1) -
                  1,
              }}
              sort={{
                items: sortList,
                selected: pageData?.filters?.sort,
              }}
              actionList={actions(selectedRow)}
            />

            <div
              className={
                "flex flex-row justify-between px-[16px] py-[4px] items-center"
              }
            >
              <SearchInput
                className="w-[362px]"
                placeholder="Search..."
                onSubmit={onSearchApply}
                defaultValue={pageData?.filters?.search}
              />

              <TabButton
                className="whitespace-nowrap"
                selectedClassName="whitespace-nowrap"
                callBack={onHandleTab}
                active="awaiting_payment"
                list={[
                  {
                    id: "awaiting_supply",
                    name: "Awaiting Purchase",
                  },
                  {
                    id: "awaiting_payment",
                    name: "Awaiting Acceptance",
                  },
                  {
                    id: "awaiting_delivery",
                    name: "Goods Acceptances",
                  },
                ]}
              />
            </div>
          </>
        )}

        <ArbitProductPage.Content className="content">
          {!product_id && (
            <style>
              {!product_id
                ? `
                             @media (768px <= width <= 1000px) {
                                .content .contt {
                                    height: calc(100vh - 220px) !important;
                                }
                            }
                            @media (max-width: 767px) {
                                .content .contt {
                                    height: calc(100vh - 314px) !important;
                                }
                            }
                    `
                : `
                    height: 200px !important;
                    `}
            </style>
          )}
          <DynamicTable
            view={TableView}
            mini={!!product_id}
            hiddenFields={product_id ? ["product", "image"] : []}
            data={TableView(tableData, handleTableFilters)}
            onSelect={(row) => {
              setSelectedRow(row);
            }}
            selectedRow={selectedRow}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            actions={actions}
            loading={pageData.loading}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              hideSelect: !!product_id,
              header: {
                className: "justify-center !px-[16px]",
                "tracking id": { className: "!pr-[30px]" },
                approve: { className: "!py-[0px] hidden" },
                id: { className: "hidden" },
                note: { className: "hidden" },
                status: { className: "hidden" },
                image: { className: "hidden" },
                qty: { className: "!justify-end !w-full " },
                cost: { className: "!justify-end !w-full " },
              },
              row: {
                className:
                  "!px-[8px] !py-[0px] !h-[64px] !w-[100%] !items-center !justify-center flex text-[12px] ",
                // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                product: {
                  className: "!justify-center !min-w-[300px] w-[100%]",
                  td: "w-[100%]",
                },
                qty: {
                  className: " !justify-end",
                },
                "supply channel": {
                  className: " !justify-start",
                  td: product_id ? "w-[100%]" : "",
                },

                _actions: { className: "!py-[0px]" },
              },
            }}
          />
        </ArbitProductPage.Content>

        {!product_id && (
          <FooterPagination
            meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
            loading={pageData.loading}
            pageData={pageData}
            onLimit={onHandleLimit}
          />
        )}
      </ArbitProductPage.Page>

      {visiblePurchaseModal && (
        <SupplyPurchaeModal
          onClose={() => setVisiblePurchaseModal(false)}
          show={visiblePurchaseModal}
          // type={updateType}
          // selectedSupply={updateItem}
          // onUpdate={onUpdate}
          onFulfill={onFulfill}
        />
      )}
      {visibleSinglePurchaseModal && (
        <SingleSupplyPurchaseModal
          onClose={() => setVisibleSinglePurchaseModal(false)}
          visible={visibleSinglePurchaseModal}
          //   type={updateType}
          data={updateItem}
          //   onUpdate={onUpdate}
        />
      )}
      {visibleCreatePaymentModal && (
        <CreatePaymentModal
          onClose={() => setVisibleCreatePaymentModal(false)}
          show={visibleCreatePaymentModal}
          type="Add"
          purchase={updateItem}
          optionsAccounts={optionsAccounts}
          onAdd={addPayment}
        />
      )}
      {visibleAdjustmentModal && (
        <AddAdjustmentModal
          onClose={() => setVisibleAdjustmentModal(false)}
          show={visibleAdjustmentModal}
          purchase={updateItem}
          onAdd={onAddAdjustment}
          userId={user?.id}
          type="Add"
        />
      )}

      {visibleEditModal && (
        <EditModal
          onClose={() => setVisibleEditModal(false)}
          show={visibleEditModal}
          selectedSupply={updateItem || selectedRow[0]}
          onUpdate={onUpdate}
          type="Edit"
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText="Are you sure you want to delete the purchase?         (Remember, this action will not only delete the purchase but also the related supply request.)"
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText="Yes, Delete Purchase"
          confirmColor="red"
          cancelText="Cancel"
          cancelColor="blue"
          styles={{
            confirmButton: "!bg-red-50 ",
            cancelButton: "!bg-white ",
            infoText: "!font-medium",
          }}
          onConfirm={() => {
            // onDelete(row?.id)
            onDelete(calledItemInAction);
          }}
        />
      )}
    </>
  );
};

export default SupplyPurchases;
