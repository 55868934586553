import React, {memo, useState} from "react";
import AddItemForm from "./addItemForm";
import ItemList from "./itemList"
import {useDispatch} from "react-redux";

import prepareDataToAddItem from "../util/prepareDataToAddItem";
import FooterActions from "./FooterActions";


const SummaryItems = memo(({orderItems, summaryForm, callBack, validation, addCallBack, isView, id}) => {

    const [actionList, setActionList] = useState([])
    const [editProductForm, setEditProductForm] = useState(null)

    const dispatch = useDispatch()
    const handleAddProduct = async (item, duplicateLocal) => {
        if (isView && !duplicateLocal) {
            const mapData = await prepareDataToAddItem(item)
            // dispatch(apiActions({
            //     data: mapData,
            //     action: "order-item",
            //     method: "POST",
            //     orderId: id?.order[0],
            // })).then((result) => {
            //     util.notifier(result?.payload?.data)
            //     if (result?.payload?.data?.type === "success") {
            //         callBack([item, ...orderItems || [] ])
            //     }
            // })
        } else {
            callBack([item, ...orderItems || []])
        }

        //callBack([item, ...orderItems || [] ])
    }
    const handleEditProduct = (item) => {
        setEditProductForm(item)
    }
    const handleUpdateProduct = (items) => {
        callBack(items)
    }
    const handleActionButton = (actions) => {
        setActionList(actions)
    }

    return (
        // <Box title={<span className={`${validation?.validationErrors?.order_items && !orderItems?.length ? "text-red-600" : ""}`}>Order Items ({orderItems?.length || 0})</span>} className={"flex flex-grow justify-end"} innerClassName ={"justify-end overflow-hidden"}
        //     //  options={
        //     //      <>
        //     //          {actionList?.count > 0 && actionList?.list.length > 0 &&
        //     //              <div className={"relative"}>
        //     //                  <DropDownMenu
        //     //                      toggleButton = {(selected)=>(
        //     //                          <UI.Button
        //     //                              item={{
        //     //                                  label: (<div className={"flex items-center justify-between gap-[12px]"}>Actions<Icons.arrow className={"w-[8px]"}/></div>),
        //     //                                  buttonType: "primary",
        //     //                                  className: `${selected ? "bg-blue-200 text-blue-600" : "text-red-600"} w-[100px] h-[34px]`
        //     //                              }}
        //     //                              onClick={actionList.callback}
        //     //                          />
        //     //                      )}
        //     //                      itemMenu = {actionList}
        //     //                  />
        //     //                  <div className={`absolute flex top-[6px]  font-bold -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[18px] min-w-[18px] items-center justify-center ${actionList?.count<1 ? "hidden" : "block"}`}>{actionList?.count}</div>
        //     //              </div>
        //     //          }

        //     //      </>
        //     //  }
        // >
        <div className=' flex flex-col justify-between flex-1  gap-[24px] overflow-hidden rounded-[0px] h-full'>
            {!isView && <AddItemForm
                callBack={handleAddProduct}
                editProduct={editProductForm}
                itemList={orderItems}
                type={summaryForm?.type}
            />}
            <ItemList
                orderItems={isView ? summaryForm?.order_items : orderItems}
                actionButton={handleActionButton}
                actionCallBack={handleUpdateProduct}
                actionEditCallBack={handleEditProduct}
                actionConnectCallBack={editProductForm}
                actionAddProduct={handleAddProduct}
                orderId={{id: 11}}
                isView={isView}
                type={summaryForm?.type}
            />
            <FooterActions
                list={isView ? summaryForm?.order_items : orderItems}
                data={summaryForm}
                type={summaryForm?.type}
                addCallBack={addCallBack}
                isView={isView}
            />
        </div>
        // </Box>
    )

})
//setProducts={setProducts} products={products} form={form} setForm={setForm}
export default SummaryItems;