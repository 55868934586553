import axios from './../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

const fetchSupplyListings = async (params) => {
    let url = constants.APIURL.GET_SUPPLY_LISTINGS
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const updateSupplyListings = async (payload) => {
    const response = await axios.put(constants.APIURL.GET_SUPPLY_LISTINGS_UPDATE, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}

const updateBulkSupplyListings = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_BULK_SUPPLY_LISTINGS_UPDATE, payload, {withCredentials: true})
    return response
}

const duplicateSupplyListings = async (payload) => {
    // console.log(payload);
    const response = await axios.post(constants.APIURL.POST_SUPPLY_LISTING + "/" + payload + "/duplicate", {withCredentials: true})
    // console.log(payload);
    return response
}

const activateSupplyListings = async (payload) => {
    const response = await axios.put(constants.APIURL.POST_SUPPLY_LISTING + "/" + payload + "/status", {withCredentials: true})
    return response
}

const refreshSupplyListig = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_REFRESH_LISTING, payload, {withCredentials: true})
    return response
}

const deleteSupplyListings = async (payload) => {
    const response = await axios.delete(constants.APIURL.POST_SUPPLY_LISTING + "/" + payload, {withCredentials: true})
    // console.log(response);
    return response
}

const fetchSupplyListingFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_SUPPLY_LISTINGS_FILTERS, {withCredentials: true})
    return response
}

const attachSupplyListings = async (params, payload) => {
    let url = constants.APIURL.GET_PRODUCT + "/" + params + "/listing"
    const response = await axios.post(url, payload, {withCredentials: true})
    return response
}

const detachSupplyListings = async (params, payload) => {
    let url = constants.APIURL.GET_PRODUCT + "/" + params + "/detach-listings"
    const response = await axios.delete(url, {withCredentials: true, data: payload})
    return response
}


export default {
    fetchSupplyListings,
    updateSupplyListings,
    updateBulkSupplyListings,
    duplicateSupplyListings,
    activateSupplyListings,
    refreshSupplyListig,
    deleteSupplyListings,
    fetchSupplyListingFilters,
    attachSupplyListings,
    detachSupplyListings
}