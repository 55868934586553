import { createSlice } from "@reduxjs/toolkit";
import {
  createBrand,
  deleteBrand,
  fetchBrands,
  searchBrands,
  updateBrand,
} from "./thunk";

const initialState = {
  brands: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {},
  meta: {},
};

export const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchBrands: (state, action) => {
      state.brands = action.payload;
    },
    updateFilter: (state, action) => {
      state.filters = { ...action.payload };
    },
    updateBrandStatus: (state, action) => {
      const { status, id } = action.payload;
      state.brands = state.brands.map((brand) => {
        if (brand.id === id) {
          return { ...brand, status };
        }
        return brand;
      });
    },
    removeBrand: (state, action) => {
      const id = action.payload;
      state.brands = state.brands.filter((brand) => brand.id !== id);
    },
    bulkDeleteBrands: (state, action) => {
      const ids = action.payload;
      state.brands = state.brands.filter((brand) => !ids.includes(brand.id));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loading = "idle";
        state.brands = action.payload.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      });
  },
});

export const { updateFilter, updateBoundFilter, removeBrand } =
  brandsSlice.actions;

export { fetchBrands, createBrand, updateBrand, deleteBrand, searchBrands };

export default brandsSlice.reducer;
