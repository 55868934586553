import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchCarrierService = async (params) => {
    let url = constants.APIURL.GET_CARRIER_SERVICES;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const searchCarrierService = async (params) => {
    let url = constants.APIURL.GET_CARRIER_SERVICES + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteCarrierService = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_CARRIER_SERVICE + payload, {withCredentials: true});
    return response?.data
};

const createCarrierService = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_CARRIER_SERVICE, payload, {withCredentials: true});
    return response
};

const updateCarrierService = async (id, payload) => {
    let url = constants.APIURL.PUT_UPDATE_CARRIER_SERVICE + id;
    const response = await axios.post(url, payload, {withCredentials: true});
    return response;
};

const fetchCarrierServicesFilters = async (params) => {
    let url = constants.APIURL.GET_CARRIER_SERVICES_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const bulkdeleteCarrierServices = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_CARRIER_SERVICES, {
        withCredentials: true,
        data: payload
    });
    return response?.data
};

const bulkStatusupdateCarrierServices = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_CARRIER_SERVICES_STATUS, payload, {withCredentials: true});
    return response?.data
};

const restoreCarrierServices = async (payload) => {
    const response = await axios.patch(constants.APIURL.POST_RESTORE_CARRIER_SERVICES, payload, {withCredentials: true});
    return response?.data
};

export default {
    fetchCarrierService,
    deleteCarrierService,
    createCarrierService,
    updateCarrierService,
    searchCarrierService,
    fetchCarrierServicesFilters,
    bulkdeleteCarrierServices,
    bulkStatusupdateCarrierServices,
    restoreCarrierServices
};
