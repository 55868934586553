import upperCaseString from "../../dynamic-page/util/upperCaseString";
import { useSelector } from "react-redux";
import React from "react";

const FiltersView = (selectedFilers) => {
  const filtersMeta = useSelector(
    (state) => state.goodsAcceptance?.filtersMeta
  );
  return [
    {
      name: "supplier",
      label: "Supplier",
      placeholder: "Filter by Supplier",
      type: "Select",
      api: {
        url: "/api/v1/channel?filter[designation]=SOURCE",
        view: "/api/v1/channel",
        query: "&term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          logo: item?.logo,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img src={logo} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.supplier,
      toggle: true,
      clear: true,
    },
    {
      name: "quantity",
      label: "Quantity",
      placeholder: "Filter by quantity",
      type: "Range",
      defaultValue: selectedFilers?.quantity,
      toggle: true,
      clear: true,
    },
    {
      name: "available_quantity",
      label: "Available Quantity",
      placeholder: "Filter by available quantity",
      type: "Range",
      defaultValue: selectedFilers?.available_quantity,
      toggle: true,
      clear: true,
    },
    {
      name: "brand_id",
      label: "Brand",
      placeholder: "Select Brand",
      type: "Select",
      api: {
        url: "/api/v1/brands",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        }),
      },
      defaultValue: selectedFilers?.brand_id || null,
      toggle: true,
      clear: true,
    },
    {
      name: "assign_id",
      label: "Assigned Employee",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users?filter[status]=1",
        view: "/api/v1/employees",
        skipCancel: true,
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.employee_id || item?.id,
          label: item?.name,
          avatar: item?.image_url || item?.profile_image,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.assign_id,
      toggle: true,
      clear: true,
    },
    {
      name: "accepted_by",
      label: "Accepted By",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          avatar: item?.image_url,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.accepted_by,
      toggle: true,
      clear: true,
    },
    {
      name: "warehouse",
      label: "Warehouse",
      placeholder: "Filter by warehouse",
      type: "Select",
      api: {
        url: "api/v1/warehouses",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          avatar: item?.image_url,
        }),
      },
      features: {
        isMulti: true,
      },
      defaultValue: selectedFilers?.warehouse,
      toggle: true,
      clear: true,
    },

    {
      name: "product_id",
      label: "Product",
      type: "Select",
      api: {
        url: "/api/v1/product",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.title, "word", true),
        }),
      },
      features: {
        isMulti: true,
      },
      reset: true,
      clear: true,
      toggle: true,
      style: {
        control: {
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      placeholder: "Filter by products",
      defaultValue: selectedFilers?.product_id,
    },
    {
      name: "expiry_date",
      label: "Expiry Date",
      placeholder: "Filter by expire date",
      type: "DateRange",
      defaultValue: selectedFilers?.expiry_date,
      toggle: true,
      clear: true,
    },
    {
      name: "accepted_at",
      label: "Accepted At",
      placeholder: "Filter by accept date",
      type: "DateRange",
      defaultValue: selectedFilers?.accepted_at,
      toggle: true,
      clear: true,
    },
  ];
};

export default FiltersView;
