import Modal from "../sub-elements/modal"
import ArbitNormalBadge from "../sub-elements/normal-badge"
import {dateHelper} from "../../utils/helper"
import {HiExternalLink} from "react-icons/hi"
import priceHandler from "../../utils/priceHandler"
import quantityHandler from "../../utils/quantityHandler"

const SinglePurchaseModal = ({isOpen, onClose, data, show}) => {


    let unitCost = data?.purchase_price + data?.tax?.rate * data?.purchase_price

    return (
        <Modal isOpen={isOpen} show center={true} onClose={onClose} title='Purchase Details'>
            <div className="p-4 max-w-[1300px] min-w-[1200px] overflow-x-auto max-h-[80vh] overflow-y-scroll">
                <div className="w-[1200px] overflow-auto">
                    <table className="table-auto w-full  table_purchase ">
                        <thead>
                        <tr className="bg-gray-50">
                            <th className="w-1/8 p-2 ">Purchase No</th>
                            <th className="w-1/8 p-2 ">Provider</th>
                            <th className="w-1/8 p-2 ">Provider UCode</th>
                            <th className="w-1/8 p-2 ">PO Reference</th>
                            <th className="w-1/8 p-2 ">Payment Terms</th>
                            <th className="w-1/8 p-2 ">PO Date</th>
                            <th className="w-1/8 p-2 ">Purchase Date</th>
                            <th className="w-1/8 p-2 ">Status</th>
                            <th className="w-1/8 p-2 ">Created By</th>
                            <th className="w-2/8 p-2 ">Note</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="">
                            <td className="p-2 ">{data?.purchase_no || '-'}</td>
                            <td className="p-2 ">{data?.supplier?.name ? (
                                <a href={data?.supplier?.url} target='_blank' className="text-black" rel="noreferrer">
                                    {data?.supplier?.name}
                                    <HiExternalLink className="inline-block h-3 w-3 text-xs"/>
                                </a>
                            ) : '-'}</td>
                            <td className="p-2 ">{data?.supplier?.ucode || '-'}</td>
                            <td className="p-2 ">{data?.po_reference || '-'}</td>
                            <td className="p-2 ">{data?.payment_terms || '-'}</td>
                            <td className="p-2 ">{data?.po_date ? dateHelper(data?.po_date) : '-'}</td>
                            <td className="p-2 ">{data?.purchase_date ? dateHelper(data?.purchase_date) : '-'}</td>

                            <td className="p-2 ">
                                <ArbitNormalBadge
                                    title={
                                        data?.status === 0
                                            ? 'Inactive'
                                            : data?.status === 1
                                                ? 'Active'
                                                : 'Deleted'
                                    }
                                    bgColor={
                                        data?.status === 0
                                            ? 'purple-100'
                                            : data?.status === 1
                                                ? 'green-100'
                                                : 'red-100'
                                    }
                                />
                            </td>
                            <td className="p-2 ">{data?.created_by?.name || '-'}</td>
                            <td className="p-2  break-words">{data?.comment || '-'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-6">
                    <h1 className="text-xl font-medium text-gray-600 mb-4">Product:</h1>
                    <table className="table-auto w-full table_purchase">
                        <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2">APID</th>
                            <th className="px-4 py-2">Product Name</th>
                            <th className="px-4 py-2">Purchased Qty</th>
                            <th className="px-4 py-2">Unit Price</th>
                            <th className="px-4 py-2">Unit Tax</th>
                            <th className="px-4 py-2">Unit Cost</th>
                            <th className="px-4 py-2">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="bg-white">
                            <td className=" px-4 py-2">{data?.product?.apid}</td>
                            <td className=" px-4 py-2">{data?.product?.title}</td>
                            <td className=" px-4 py-2">{quantityHandler(data?.quantity, data?.product?.unit)}</td>
                            <td className=" px-4 py-2">{priceHandler(data?.purchase_price, data?.currency)}</td>
                            <td className=" px-4 py-2">{data?.tax ? priceHandler(data?.tax?.rate * data?.purchase_price, data?.currency) : '-'}</td>
                            <td className=" px-4 py-2">{priceHandler(+(data?.tax_amount / data?.quantity) + +data?.purchase_price, data?.currency)}</td>
                            <td className=" px-4 py-2">{priceHandler(data?.sub_total, data?.currency)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-6">
                    <h1 className="text-xl font-medium text-gray-600 mb-4">Summary:</h1>
                    <table className="table-auto w-full table_purchase">
                        <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2">Subtotal:</th>
                            <th className="px-4 py-2">Discount:</th>
                            <th className="px-4 py-2">Transport:</th>
                            <th className="px-4 py-2">Tax({data?.tax?.rate}):</th>
                            <th className="px-4 py-2">Total:</th>
                            <th className="px-4 py-2">Total Paid:</th>
                            <th className="px-4 py-2">Total Due:</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="bg-white">
                            <td className=" px-4 py-2 font-bold">{priceHandler(data?.sub_total, data?.currency)}</td>
                            <td className=" px-4 py-2 font-bold">{priceHandler(data?.discount, data?.currency)}</td>
                            <td className=" px-4 py-2 font-bold">{priceHandler(data?.transport, data?.currency)}</td>
                            <td className=" px-4 py-2 font-bold">{priceHandler(data?.tax_amount, data?.currency)}</td>
                            <td className=" px-4 py-2 font-bold">{priceHandler(data?.total, data?.currency)}</td>
                            <td className=" px-4 py-2 font-bold">{priceHandler(data?.total_paid, data?.currency)}</td>
                            <td className=" px-4 py-2 font-bold">{priceHandler(data?.total_due, data?.currency)}</td>
                        </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </Modal>
    )

}

export default SinglePurchaseModal