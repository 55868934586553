// urlQueryBuilder.js
// -----------------------------------------
// Create url query string from filters.

// Import hasValue function to check if the value is not null or undefined.
import hastValue from "../../dynamic-form/util/hasValue";

const urlQueryBuilder = (updatedFilters, base, searchType, reset) => {
  // Initial url array. you can call function with base, to start with base query.
  // for example: urlQueryBuilder(updatedFilters, "limit=1")
  const urlArray = base ? [base] : [];

  // Destructure updatedFilters
  const { includes, filters, sort, search, page, limit, tab } = updatedFilters;
  // Check if "search" is included in the query.
  //if (search || search==="") {
  if (searchType === "term") urlArray.push(`term=${search || ""}`);
  if (searchType === "search") urlArray.push(`search=${search || ""}`);
  if (searchType === "filter") urlArray.push(`filter[name]=${search || ""}`);
  //}

  if (includes) {
    urlArray.push(`include=${includes}`);
  }

  // Check if "filters" has any value.
  if (filters) {
    // Loop through the filters and add them to the url array.
    Object.keys(filters).forEach((key) => {
      if (hastValue(filters[key])) {
        urlArray.push(`filter[${key}]=${filters[key]}`);
      }
    });
  }

  // Check if "sort" is set.
  if (sort) {
    urlArray.push(`sort=${sort}`);
  }

  if (limit) {
    urlArray.push(`limit=${limit}`);
  }

  // Check if pagination is called.
  if (page) {
    urlArray.push(`page=${page}`);
  }

  if (tab) {
    urlArray.push(`tab=${tab}`);
  }

  let returnUrl = urlArray.length > 0 ? "?" + urlArray.join("&") : null;
  if (search && searchType !== "term" && searchType !== "filter") returnUrl = "/search" + returnUrl;

  const url = new URL(window.location.href);
  url.search = new URLSearchParams(returnUrl.replace(/(page=\d+)/, "")); // remove the page part from return url

  // Redirect or update the URL with the query string
  if (url.search.toString() !== window.location.search.toString() && !reset) {
    window.history.pushState({}, "", url.toString());
    //window.location.href = url.toString();
  }

  // Return url query string.
  return returnUrl; //urlArray.length > 0 ? "?" + urlArray.join("&") : null
};
export default urlQueryBuilder;
