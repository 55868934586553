// locale/api.js
// ----------------------------------------------------------------

import axios from "../../middleware/axios";
import constants from "../../constant";

// Call the API to fetch the locale.
const fetchLocale = async (params) => {
    // Check if api called for specific locale or all locales.
    const url = params
        ?
        constants.APIURL.GET_LOCALE.concat(params)
        :
        constants.APIURL.GET_LOCALE;
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

export default {fetchLocale};
