import {ReactComponent as airplane} from "./airplane.svg";
import {ReactComponent as chart} from "./chart.svg";
import {ReactComponent as clock} from "./clock.svg";
import {ReactComponent as cube} from "./cube.svg";
import {ReactComponent as exclamation} from "./exclamation.svg";
import {ReactComponent as exclamationCircle} from "./exclamationCircle.svg";
import {ReactComponent as happy} from "./happy.svg";
import {ReactComponent as link} from "./link.svg";
import {ReactComponent as truck} from "./truck.svg";

export default {
    airplane,
    chart,
    clock,
    cube,
    exclamation,
    exclamationCircle,
    happy,
    link,
    truck
}