import React from "react";
import Icon from "./icons";
import {howLongFromNow} from "../../utils/helper";

const TableView = ({data}) => {
    //const isValidData = Array.isArray(data) && data.length > 0;

    // a function for summing two numbers one of them or both might be null
    const sum = (a, b) => {
        if (a == null && b == null) {
            return "-";
        }
        return a + b;
    };

    return (
        <tbody>
        {data.map((item, index) => (
            <tr className="text-sm" key={index}>
                <td className="py-4 border-b-[1px] border-b-gray-200 border-solid pl-3">
                    <div className="flex flex-row justify-start items-center">
                        <button type="button">
                            <Icon.Switch/>
                        </button>
                        {item.switch}
                    </div>
                </td>
                <td className="py-4 border-b-[1px] border-b-gray-200 border-solid">
            <span
                className={`rounded-md uppercase font-medium py-1 px-3 ${
                    item.fulfilment === "FBA"
                        ? "bg-orange-100 text-orange-800"
                        : item.fulfilment === "FBM"
                            ? "bg-blue-100 text-blue-800"
                            : "" // Default styles when neither FBA nor FBM
                }`}
            >
              {item.fulfilment}
            </span>
                </td>
                <td className="py-4 border-b-[1px] border-b-gray-200 border-solid text-gray-900">
                    {item.account.name}
                </td>
                <td className="py-4 border-b-[1px] border-b-gray-200 border-solid text-gray-500">
                    {item.sku}
                </td>
                <td className="py-4 border-b-[1px] border-b-gray-200 border-solid text-gray-900">
                    ${item.price}
                </td>
                <td className="py-4 border-b-[1px] border-b-gray-200 border-solid text-gray-500">
                    {sum(
                        item?.fba_quantity_tooltip?.available_quantity,
                        item?.fba_quantity_tooltip?.reserved_quantity
                    )}
                </td>
                <td className="py-4 border-b-[1px] border-b-gray-200 border-solid text-gray-500">
                    {howLongFromNow(item.updated_at)}
                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableView;
