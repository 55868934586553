import React from "react";
import {
  HiExternalLink,
  HiOfficeBuilding,
  HiShoppingCart,
} from "react-icons/hi";
import moment from "moment";
import Util from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
import { useNavigate } from "react-router-dom";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import priceHandler from "../../../utils/priceHandler";
import StatusSteps from "../../dynamic-page/elements/StatusSteps";
import removeDecimals from "../../../utils/removeDecimals";
import descriptiveContent from "../../../utils/descriptiveContent";
import ItemID from "../../dynamic-page/components/ItemID";
import StatusHistory from "../../goods-acceptance/components/SupplyStatusHistory";
import Icons from "../../orders/icons";
import ProductName from "../../dynamic-page/components/productName";
import { Tooltip } from "react-tooltip";

const TableView = (list, handleTableFilters) => {
  const navigate = useNavigate();

  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  const unitsQty = (item) => {
    if (item === 1) {
      return item + " unit";
    } else {
      return item + " units";
    }
  };

  const tooltipContent = (item) => {
    return [
      <div className={"font-bold"}>Supply Status History</div>,
      item?.supply_purchase?.good_acceptances?.length > 0 && (
        <div className=" justify-start items-center gap-1">
          {item?.supply_purchase?.good_acceptances?.map((acceptance, index) => (
            <div>
              <span className="text-gray-900 text-xs font-normal leading-[18px]">
                Accepted{" "}
                <span className="font-semibold">
                  {acceptance?.quantity && unitsQty(+acceptance?.quantity)}
                </span>
              </span>
              <span className="text-gray-500 text-xs font-normal leading-[18px]">
                {" "}
                by{" "}
              </span>
              <span className="text-gray-900 text-xs font-normal leading-[18px]">
                {acceptance?.accepted_by?.name}
              </span>
              <span className="text-gray-500 text-xs font-normal leading-[18px]">
                {" "}
                at{" "}
              </span>
              <span className="text-gray-900 text-xs font-normal leading-[18px]">
                {moment(acceptance?.accpeted_at).format("MMM D, YYYY HH:mm")}
              </span>
            </div>
          ))}
        </div>
      ),
      item?.supply_purchase?.created_by && (
        <div className=" justify-start items-center gap-1">
          <div>
            <span className="text-gray-900 text-xs font-normal leading-[18px]">
              Purchased{" "}
              <span className="font-semibold">
                {item?.supply_purchase?.quantity &&
                  unitsQty(+item?.supply_purchase?.quantity)}
              </span>
            </span>
            <span className="text-gray-500 text-xs font-normal leading-[18px]">
              {" "}
              by{" "}
            </span>
            <span className="text-gray-900 text-xs font-normal leading-[18px]">
              {item?.supply_purchase?.created_by?.name}
            </span>
            <span className="text-gray-500 text-xs font-normal leading-[18px]">
              {" "}
              at{" "}
            </span>
            <span className="text-gray-900 text-xs font-normal leading-[18px]">
              {moment(item?.supply_purchase?.created_at).format(
                "MMM D, YYYY HH:mm"
              )}
            </span>
          </div>
        </div>
      ),
      <div className=" justify-start items-center gap-1">
        <div>
          <span className="text-gray-900 text-xs font-normal leading-[18px]">
            Requested{" "}
            <span className="font-semibold">
              {item?.qty && unitsQty(+item?.qty)}
            </span>
          </span>
          <span className="text-gray-500 text-xs font-normal leading-[18px]">
            {" "}
            by{" "}
          </span>
          <span className="text-gray-900 text-xs font-normal leading-[18px]">
            {item?.requester?.name}
          </span>
          <span className="text-gray-500 text-xs font-normal leading-[18px]">
            {" "}
            at{" "}
          </span>
          <span className="text-gray-900 text-xs font-normal leading-[18px]">
            {moment(item?.request_date_time).format("MMM D, YYYY HH:mm")}
          </span>
        </div>
      </div>,
    ];
  };

  const nullHandle = (data, chained) => {
    if (!data || !chained) {
      return "-";
    } else {
      return chained;
    }
  };

  const typeList = {
    0: "Debit",
    1: "Credit",
  };

  const handleSupplyCss = (item) => {
    if (item === "WEBSITE") {
      return "bg-blue-100 text-blue-700 ";
    }
    if (item === "WHOLESALER") {
      return "bg-green-100 text-green-700  ";
    }
    if (item === "MANUFACTURER") {
      return "bg-yellow-100 text-yellow-700  ";
    }
  };

  const handleImage = (item) => {
    if (item === "WEBSITE") {
      return <Icons.globeAlt className="w-3 h-3 " />;
    }
    if (item === "WHOLESALER") {
      return <Icons.rectangleGroup className="w-3 h-3 " />;
    }
    if (item === "MANUFACTURER") {
      return <Icons.wrench className="w-3 h-3 " />;
    }
  };

  return {
    list: list,
    theme: {
      note: (item) => (
        <div className=" flex items-center">
          <Util.popUp
            toggle={() =>
              descriptiveContent(
                <div
                  className={`flex items-center justify-center transition rounded-full ${activeStyle(
                    item?.comment
                  )}`}
                >
                  <Icons.requestNote className="w-5 h-5  cursor-pointer" />
                </div>,
                "Internal Note",
                null,
                "top"
              )
            }
            action={"click"}
            // leftSpace={style?.leftSpace}
          >
            <div
              style={{ hyphens: "auto" }}
              className={
                "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
              }
            >
              {item?.comment ? (
                Util.upperCaseString(item?.comment)
              ) : (
                <span className={"text-gray-400"}>note is empty!</span>
              )}
            </div>
          </Util.popUp>
        </div>
      ),
      status: (item) => (
        <StatusSteps
          list={[
            {
              label: <HiShoppingCart className="w-5 h-5" />,
              status: "awaiting",
            },
            {
              label: <HiOfficeBuilding className="w-5 h-5" />,
              status:
                item?.supply_request?.delivery_status === "Delivered"
                  ? "completed"
                  : item?.supply_request?.delivery_status
                      ?.split(" ")[0]
                      ?.toLowerCase(),
            },
          ]}
          tooltip={
            <StatusHistory id={item?.id} url="supply-request" includes={""} />
          }
        />
      ),
      id: (item) => (
        <div className="flex flex-col justify-center items-center gap-1  inline-flex">
          <ItemID id={item.id} />
          {item?.reason === "order" && item?.related_reason?.length > 0 ? (
            <util.popUp
              toggle={() => (
                <ArbitNormalBadge
                  className={` ${
                    item?.reason === "order" ? "underline" : ""
                  } !font-medium
                  `}
                  title={
                    item.reason === "warehouse_restocking"
                      ? "WHS"
                      : item.reason === "fulfilment_restocking"
                      ? "FLS"
                      : "ORD"
                  }
                  bgColor={
                    item.reason === "warehouse_restocking"
                      ? "purple-100"
                      : item.reason === "fulfilment_restocking"
                      ? "blue-100"
                      : "red-100"
                  }
                  textColor={
                    item.reason === "warehouse_restocking"
                      ? "purple-800"
                      : item.reason === "fulfilment_restocking"
                      ? "blue-800"
                      : "red-800 "
                  }
                />
              )}
              action="click"
            >
              <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[300px]">
                {item?.related_reason?.map((order, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center gap-2"
                  >
                    <span className="text-gray-700 font-medium">{order}</span>
                    <div>
                      <util.copyText
                        className={"text-[12px]"}
                        text={order}
                        hint={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </util.popUp>
          ) : (
            <ArbitNormalBadge
              className={` !font-medium 
              `}
              title={
                item.reason === "warehouse_restocking"
                  ? "WHS"
                  : item.reason === "fulfilment_restocking"
                  ? "FLS"
                  : "ORD"
              }
              bgColor={
                item.reason === "warehouse_restocking"
                  ? "purple-100"
                  : item.reason === "fulfilment_restocking"
                  ? "blue-100"
                  : "red-100"
              }
              textColor={
                item.reason === "warehouse_restocking"
                  ? "purple-800"
                  : item.reason === "fulfilment_restocking"
                  ? "blue-800"
                  : "red-800"
              }
            />
          )}
        </div>
      ),
      "requested at": (item) => (
        <util.dateDisplay item={item} date={item?.created_at} />
      ),

      image: (item) => (
        <div
          className={
            "w-[65px] h-[64px] max-h-[64px] justify-center items-center flex"
          }
        >
          {item?.product?.image ? (
            <ImageMagnifier
              src={item?.product?.image}
              className={"!max-h-[64px]"}
            />
          ) : (
            <img
              src={"/assets/images/noImage.png"}
              className={"h-[52px] w-[52px]"}
            />
          )}
        </div>
      ),
      product: (item) => (
        <ProductName
          item={item?.product}
          user={item?.product?.brand?.assigned}
          handleTableFilters={handleTableFilters}
        />
      ),
      "supply channel": (item) => (
        <div className="flex items-center gap-1">
          <div
            className={`w-6 h-6 rounded-full  flex justify-center items-center ${handleSupplyCss(
              item?.supplier?.supply_channel_type
            )}`}
          >
            {handleImage(item?.supplier?.supply_channel_type)}
          </div>
          {item?.supplier
            ? descriptiveContent(
                <div
                  className={`flex  justify-start text-base font-medium items-center gap-1 max-w-[164px] ${
                    item?.url && "cursor-pointer"
                  }`}
                  onClick={() => {
                    item?.url && window.open(item?.url, "_blank");
                  }}
                >
                  <span className=" truncate max-w-[128px] text-base font-medium">
                    {item?.supplier?.name}
                  </span>
                  {item?.url && (
                    <HiExternalLink className="w-4 h-4 text-gray-300 hover:text-gray-500 cursor-pointer" />
                  )}
                </div>,
                item?.supplier?.name.length > 17 && item?.supplier?.name
              )
            : ""}
        </div>
      ),
      "max. price": (item) => (
        <div className=" h-[38px] w-[100%] flex justify-end items-start gap-0.5 px-2 inline-flex">
          <div className="justify-end items-center gap-0.5 inline-flex">
            <div className="text-[#046C4E] text-2xl font-bold leading-[24px]">
              {item?.max_acceptable_price
                ? priceHandler(
                    item?.max_acceptable_price?.[
                      item?.max_acceptable_price_currency?.code
                    ],
                    item?.max_acceptable_price_currency
                  )
                : "-"}
            </div>
          </div>
        </div>
      ),
      qty: (item) => (
        // <div className="w-[79px] h-16 px-4 py-3   flex-col justify-center items-end gap-0.5 inline-flex">
        <div className="justify-center items-center px-2 gap-1 inline-flex flex-col">
          <div className="text-purple-700 text-2xl font-bold leading-[24px] flex items-center gap-1">
            <span>{removeDecimals(item?.qty)}</span>
          </div>
        </div>
        // </div>
      ),
    },
  };
};
export default TableView;
