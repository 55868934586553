import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchVat = async (params) => {
    let url = constants.APIURL.GET_VAT_RATES;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const searchVat = async (params) => {
    let url = constants.APIURL.GET_VAT_RATES + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteVat = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_VAT_RATE + payload, {withCredentials: true});
    return response;
};

const createVat = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_VAT_RATE, payload, {withCredentials: true});
    return response;
};

const updateVat = async (id, payload) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_VAT_RATE + id, payload, {withCredentials: true});
    return response;
};

const fetchVatsFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_VAT_RATES_FILTERS, {withCredentials: true});
    return response?.data;
};

const bulkDeleteVats = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_VAT_RATES, {withCredentials: true, data: payload});
    return response;
};

const bulkStatusUpdateVats = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_BULK_VAT_RATES_STATUS, payload, {withCredentials: true});
    return response;
};

const restoreVats = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_VAT_RATES, payload, {withCredentials: true});
    return response;
};

export default {
    fetchVat,
    deleteVat,
    createVat,
    updateVat,
    searchVat,
    fetchVatsFilters,
    bulkDeleteVats,
    bulkStatusUpdateVats,
    restoreVats
};
