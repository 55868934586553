import Title from "./elements/Title";
import TitleNew from "./elements/TitleNew";
import classNames from "./elements/classNames";
import Tab from "./elements/Tab";
import Content from "./elements/Content";
import Footer from "./elements/Footer";

const Page = ({ children, className }) => {
  return <div className={classNames("font-inter", className)}>{children}</div>;
};
export default {
  Page,
  Title,
  Content,
  Footer,
  Tab,
  TitleNew,
};
