const sortView = ({ callback }) => {
  return {
    list: [
      {
        label: "Created At",
        value: "created_at",
        onClick: (item) => {
          callback("created_at");
        },
      },
      {
        label: "Product",
        value: "product",
        onClick: (item) => {
          callback("product");
        },
      },
      {
        label: "Updated At",
        value: "updated_at",
        onClick: (item) => {
          callback("updated_at");
        },
      },
      {
        label: "Supplier",
        value: "supplier",
        onClick: (item) => {
          callback("supplier");
        },
      },
      {
        label: "Created By",
        value: "created_by",
        onClick: (item) => {
          callback("created_by");
        },
      },

      {
        label: "Purchase Price",
        value: "purchase_price",
        onClick: (item) => {
          callback("purchase_price");
        },
      },
      {
        label: "Quantity",
        value: "quantity",
        onClick: (item) => {
          callback("quantity");
        },
      },
      {
        label: "Subtotal",
        value: "sub_total",
        onClick: (item) => {
          callback("sub_total");
        },
      },
    ],
  };
};

export default sortView;
