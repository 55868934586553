import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiRefreshCw } from "react-icons/fi";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
// import sortView from "./modals-view/sort";
import formView from "./modals-view/form";
import PrimaryModal from "./modals-view/primary";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActionList from "../dynamic-page/util/getActionList";

import {
  addTargetSeller,
  fetchTargetSellerListings,
  fetchTargetSellerListingsFilters,
  fetchTargetSellers,
  fetchTargetSellersFilters,
  handleApprovementAction,
  removeListing,
  updateBoundFilter,
  updateFilter,
  updateRow as updateRowAction,
  updateWeight,
} from "../../store/target-seller-folder";
import {
  createTargetSellerFolder as createRow,
  updateTargetSellerFolder as updateRow,
} from "../../store/target-seller-listings";
import api from "../../store/target-seller-folder/api";

import UI from "../dynamic-form/elements";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import FormModal from "../dynamic-page/FormModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoSettingsSharp } from "react-icons/io5";
import folderFormView from "../target-seller-listing/modals-view/form";
import { refreshAccountListings } from "../../store/fc-restocking";
import {
  closeNotification,
  startNotification,
} from "../dynamic-page/util/notifierLive";
import ListingDetailModal from "../../components/listing-detail-modal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import Icons from "../orders/icons";

const TargetSellers = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation()

  const { id, type } = useParams();
  const [folder, setFolder] = useState(null);

  const pageData = useSelector((state) => state.targetSellerFolder);

  // Initial page at first load.
  useEffect(() => {
    const query = urlQueryLoader();
    const tab =
      type === "sellers" && query?.filters?.status === 1
        ? "targetSellers"
        : type === "sellers" && query?.filters?.status === 0
        ? "prospectiveSellers"
        : type === "listings" && query?.filters?.status === 1
        ? "targetListings"
        : type === "listings" && query?.filters?.status === 0
        ? "prospectiveListings"
        : "targetSellers";

    setTabStatus(tab);

    const fetchData =
      tab === "targetSellers" || tab === "prospectiveSellers"
        ? fetchTargetSellers
        : fetchTargetSellerListings;

    const startPageByFilters = {
      filters: { status: 1 , is_data_loaded: 1 },
      sort: null,
      page: 1,
      limit: 10,
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term",true);
    api.fetchTargetSellerFolder(id).then((res) => {
      setFolder(res?.data);
    });

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(id))
    const querry =
      Object.keys(storeQuery?.filters)?.length > 1 ? urlQuery : null;
    dispatch(
      fetchTargetSellerListingsFilters({
        id: id,
        query: `&filter[status]=${storeQuery?.filters?.status}`,
      })
    );
    dispatch(
      fetchTargetSellersFilters({
        id: id,
        query: `&filter[status]=${storeQuery?.filters?.status}`,
      })
    );
    dispatch(fetchData({ id, query: urlQuery }));

    // dispatch(fetchSingleRow(id));

    // dispatch(fetchFilters(id));
  }, [location]);

  const handleRefreshListings = async () => {
    // dispatch(fetchData(urlQueryBuilder(pageData?.filters , null , "term"))).then((rs)=>{
    //     // util.notifier(rs.payload.data);
    //     setRefreshing(false);
    //     setRefreshingDate (rs.payload.data.data.latest_refresh);
    // })

    const identifiers = selectedRow?.map((item) => {
      return item?.sales_channel_listing?.identifier;
    });

    const ids = selectedRow?.map((item) => {
      return item?.id;
    });

    if (identifiers) {
      try {
        setRefreshing(true);

        let a = startNotification({
          title: `Refreshing ${identifiers.length} Listings`,
        });
        // Dispatch the refreshAccountListings thunk with the identifiers array in the payload
        // dispatch(updateRow({path: `orders.[id:${orderId}]`, value: {...rs.payload.data[0], _updatedRow : true}}))
        // update the selected rows in the table to add the _updatedRow property use the ids
        // ids.map((id) => {
        //   dispatch(updateRow({ path: `fcRestockings.[id:${id}]`, value: { ...pageData.fcRestockings.find((item) => item.id === id), _updatedRow: true } }))
        // })

        dispatch(refreshAccountListings({ identifiers }))
          .unwrap()
          .then((rs) => {
            // util.notifier({type : rs.type , message: rs.message});
            closeNotification(a, {
              toast: {
                type: rs.type === "validation_error" ? "error" : rs.type,
                message: rs.message,
                errors: rs.errors,
              },
            });
            // loop on the identifiers and and for each one extract the id for the row and replace the row with the matching row from rs.data that have the same identifier
            if (rs.type === "success") {
              identifiers.map((identifier) => {
                const row = rs.data.find(
                  (item) => item.identifier === identifier
                );
                const originalRow = pageData.targetSellerData.find(
                  (item) => item.sales_channel_listing.identifier === identifier
                );
                dispatch(
                  updateRowAction({
                    path: `targetSellerData.[id:${originalRow.id}]`,
                    value: {
                      ...originalRow,
                      sales_channel_listing: row,
                      _updatedRow: true,
                    },
                  })
                );
              });

              ids.map((id) => {
                setTimeout(() => {
                  dispatch(
                    updateRowAction({
                      path: `targetSellerData.[id:${id}]._updatedRow`,
                      value: false,
                    })
                  );
                }, 3000);
              });
            }
            setRefreshing(false);
            // setRefreshingDate (rs.meta.timestamp)
          });

        setSelectedRow(null);

        // Fetch data again to refresh the table
        // const urlQuery = urlQueryBuilder(pageData?.filters, null, "term");
        // await dispatch(fetchData(urlQuery));
      } catch (error) {
        // Handle errors if the refresh fails
      }
    } else {
      util.notifier({ message: "Please Select at Least One Row" });
    }
  };
  // Get data from store.

  // state to detect which table to render
  const [tabStatus, setTabStatus] = useState("targetSellers");

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleListingModal, setVisibleListingModal] = useState(false);
  const [salesModalListingData, setModalSalesListingData] = useState(null); // State to store salesListing data

  const openListingModal = (modalListing) => {
    setModalSalesListingData(modalListing); // Set salesListing data in the state
    setVisibleListingModal(true);
  };

  // Handle form modal.
  const [calledItemInAction, setCalledItemInAction] = useState(null);
  const [formData, setFormData] = useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);
  const [refreshingDate, setRefreshingDate] = useState(null);

  // Handle order details modal.
  const [modalVisibility, setModalVisibility] = useState(false);
  const [PrimaryModalVisibility, setPrimaryModalVisibility] = useState(false);
  const [primaryModalData, setPrimaryModalData] = useState(null);
  const [settingsModalVisibility, setSettingsModalVisibility] = useState(false);

  const fetchData =
    tabStatus === "targetSellers" || tabStatus === "prospectiveSellers"
      ? fetchTargetSellers
      : fetchTargetSellerListings;

  const passedFetchFunction = (params) =>
    fetchData({
      ...params,
      id,
    });

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData: passedFetchFunction,
  });

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    setSelectedRow([]);
    return orderId;
  };

  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };

  const onSubmitApply = (data) => {
    dispatch(addTargetSeller({ id, payload: data }))
      .unwrap()
      .then((res) => {
        handleNotifyUser({ type: res?.type, message: res?.message });
        if (res?.type === "success") {
          setModalVisibility(false);
          onPaginationApply();
        }
      });
  };

  const handleApprovementCallback = async (action, type, item) => {
    const folderId = id;
    dispatch(handleApprovementAction({ action, type, folderId, data: item }))
      .unwrap()
      .then((res) => {
        handleNotifyUser({ type: res?.type, message: res?.message });
        if (res?.type === "success") {
          // onPaginationApply()
          if (item?.length > 1) {
            onPaginationApply();
          } else {
            dispatch(removeListing({ ids: item }));
          }
        }
        // dispatch(fetchFilters({id ,  query : urlQueryLoader()}))
      });
  };

  const handleUpdateWeight = async (item, weight) => {
    dispatch(updateWeight({ id: item?.id, payload: weight }))
      .unwrap()
      .then((res) => {
        handleNotifyUser({ type: res?.type, message: res?.message });
        if (res?.type === "success") {
          // onPaginationApply()
        }
      });
  };

  // Initial action list
  const actionList = {
    approve: {
      label: "Approve",
      onClick: (item) => {
        const ids = giveSelectedItemId(item);
        handleApprovementCallback(type, "approve", ids);
      },
    },
    reject: {
      label: "Reject",
      onClick: (item) => {
        const ids = giveSelectedItemId(item);
        handleApprovementCallback(type, "reject", ids);
      },
    },
    createOffer: {
      label: "Create Offer",
      onClick: (row) => {
        // if (!row?.product_id) { util.notifier({ message: "No Product Connected" }); return}
        window.open(
          `/listing/offers#action=create&
        &identifier=${row?.asin}
        &channel=${row?.salesChannel?.id}
        `,
          "_blank"
        );
      },
      bulkDisable: true,
    },
  };

  const listingsActionList =
    type === "sellers"
      ? [actionList.reject]
      : [actionList.reject, actionList.createOffer];
  const statusList = {
    1: {
      label: "Approved",
      actions: listingsActionList,
    },
    0: {
      label: "Pending",
      actions: [actionList.approve, actionList.reject],
    },
    2: {
      label: "Rejected",
      actions: [actionList.approve],
    },
  };

  let sortList = {
    list: [
      {
        label: "Last Updated",
        value: "updated_at",
        onClick: (item) => {
          onSortApply("updated_at");
        },
      },
      {
        label: "Created At",
        value: "created_at",
        onClick: (item) => {
          onSortApply("created_at");
        },
      },
    ],
  };

  if (type === "listings") {
    sortList = {
      list: [
        ...sortList.list,
        {
          label: "Buybox Price",
          value: "buybox_price",
          onClick: (item) => {
            onSortApply("buybox_price");
          },
        },
        {
          label: "Total Offer Count",
          value: "total_offer_count",
          onClick: (item) => {
            onSortApply("total_offer_count");
          },
        },
        {
          label: "Sales Channel Top",
          value: "sales_channel_top",
          onClick: (item) => {
            onSortApply("sales_channel_top");
          },
        },
        {
          label: "Supply Cost",
          value: "supply_cost",
          onClick: (item) => {
            onSortApply("supply_cost");
          },
        },
        {
          label: "FBA Max Ideal Cost",
          value: "fba_max_ideal_cost",
          onClick: (item) => {
            onSortApply("fba_max_ideal_cost");
          },
        },
        {
          label: "FBM Max Ideal Cost",
          value: "fbm_max_ideal_cost",
          onClick: (item) => {
            onSortApply("fbm_max_ideal_cost");
          },
        },
        {
          label: "Billable Weight",
          value: "billable_weight",
          onClick: (item) => {
            onSortApply("billable_weight");
          },
        },
        {
          label: "Actual Weight",
          value: "actual_weight",
          onClick: (item) => {
            onSortApply("actual_weight");
          },
        },
        {
          label: "Dimensional Weight",
          value: "dimensional_weight",
          onClick: (item) => {
            onSortApply("dimensional_weight");
          },
        },
        {
          label: "Best Sellers Rank",
          value: "sales_rank",
          onClick: (item) => {
            onSortApply("sales_rank");
          },
        },
      ],
    };
  } else {
    sortList = {
      list: [
        {
          label: "Listings Count",
          value: "listingCount",
          onClick: (item) => {
            onSortApply("listingCount");
          },
        },
        {
          label: "Last 30 Days Rating Count",
          value: "30DaysRatingCount",
          onClick: (item) => {
            onSortApply("30DaysRatingCount");
          },
        },
        // 90, 365
        {
          label: "Last 90 Days Rating Count",
          value: "90DaysRatingCount",
          onClick: (item) => {
            onSortApply("90DaysRatingCount");
          },
        },
        {
          label: "Last 365 Days Rating Count",
          value: "365DaysRatingCount",
          onClick: (item) => {
            onSortApply("365DaysRatingCount");
          },
        },
        {
          label: "Lifetime Days Ratings Count",
          value: "lifetimeRatingCount",
          onClick: (item) => {
            onSortApply("lifetimeRatingCount");
          },
        },
      ],
    };
  }

  // handle actions of statuses
  //
  const actions = (row) => {
    // todo const innerRow = row.map((item) => { add actions_id to item }

    return getActionList(row, statusList);
  };

  const [abortController, setAbortController] = useState(null);

  // Handle Tabs
  const onHandleTab = (item) => {
    // onFilterApply({ status: item });

    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the current tab
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    const signal = newAbortController.signal;

    setTabStatus(item);

    const fetchData =
      item === "targetSellers" || item === "prospectiveSellers"
        ? fetchTargetSellers
        : fetchTargetSellerListings;

    const fetchFilters =
      item === "targetSellers" || item === "prospectiveSellers"
        ? fetchTargetSellersFilters
        : fetchTargetSellerListingsFilters;

    if (item === "targetListings") {
      navigate(`/growth/target_seller_folders/${id}/listings`);

      //   const startPageByFilters = {
      //     filters: { status : 1 },
      //     sort: null,
      //   };
      // const storeQuery = storeQueryBuilder(startPageByFilters, {
      //   filters: { status : 1},
      // });
      //   const urlQuery = urlQueryBuilder(storeQuery, null);

      //   // api.fetchTargetSellerListings()
      //   dispatch(updateFilter(storeQuery))
      //   dispatch(fetchData({ id, query: urlQuery }))

      const currentStoreFilters =
        tabStatus === "prospectiveListings" ? pageData?.filters : {};
      const storeQuery = storeQueryBuilder(currentStoreFilters, {
        filters: { status: 1, is_data_loaded: 1 },
        search: "",
      });
      const urlQuery = urlQueryBuilder(storeQuery, null, "term");

      dispatch(updateFilter(storeQuery));
      // if (tabStatus !== 'prospectiveListings') {

      // dispatch(fetchTargetSellerListingsFilters({ id: id , query :  `&filter[status]=1` }))
      // }
      dispatch(fetchData({ id, query: urlQuery, signal: signal }));
      dispatch(fetchFilters({ id, query: `&filter[status]=1` }));
    } else if (item === "targetSellers") {
      //   const startPageByFilters = {
      //     filters: { status : 1 },
      //     sort: null,
      //   };
      // const storeQuery = storeQueryBuilder(startPageByFilters, {
      //   filters: { status : 1},
      // });
      //   const urlQuery = urlQueryBuilder(storeQuery, null);
      //   dispatch(updateFilter(storeQuery))
      //   dispatch(fetchData({ id, query: urlQuery }))

      navigate(`/growth/target_seller_folders/${id}/sellers`);

      const currentStoreFilters =
        tabStatus === "prospectiveSellers" ? pageData?.filters : {};
      const storeQuery = storeQueryBuilder(currentStoreFilters, {
        filters: { status: 1, is_data_loaded: 1 },
        search: "",
      });
      const urlQuery = urlQueryBuilder(storeQuery, null, "term");

      dispatch(updateFilter(storeQuery));
      // if (tabStatus !== 'prospectiveSellers') {
      dispatch(
        fetchTargetSellersFilters({ id: id, query: `&filter[status]=1` })
      );
      // }
      dispatch(fetchData({ id, query: urlQuery, signal: signal }));
    } else if (item === "prospectiveListings") {
      navigate(`/growth/target_seller_folders/${id}/listings`);

      // filter by status = 0
      // const startPageByFilters = {
      //   filters: { status : 0 },
      //   sort: null,
      // };
      // const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
      // const urlQuery = urlQueryBuilder(storeQuery, null);
      // dispatch(updateFilter(storeQuery))
      // dispatch(fetchData({ id, query: urlQuery }))
      const currentStoreFilters =
        tabStatus === "targetListings" ? pageData?.filters : {};
      const storeQuery = storeQueryBuilder(currentStoreFilters, {
        filters: { status: 0, is_data_loaded: 1 },
        search: "",
      });
      const urlQuery = urlQueryBuilder(storeQuery, null, "term");

      dispatch(updateFilter(storeQuery));
      // if (tabStatus !== 'targetListings') {
      dispatch(
        fetchTargetSellerListingsFilters({ id: id, query: `&filter[status]=0` })
      );
      // }
      dispatch(fetchData({ id, query: urlQuery, signal: signal }));
    } else if (item === "prospectiveSellers") {
      navigate(`/growth/target_seller_folders/${id}/sellers`);

      // filter by status = 0
      const currentStoreFilters =
        tabStatus === "targetSellers" ? pageData?.filters : {};
      const storeQuery = storeQueryBuilder(currentStoreFilters, {
        filters: { status: 0, is_data_loaded: 1 },
        search: "",
      });
      const urlQuery = urlQueryBuilder(storeQuery, null, "term");

      dispatch(updateFilter(storeQuery));
      // if (tabStatus !== 'targetSellers') {
      dispatch(
        fetchTargetSellersFilters({ id: id, query: `&filter[status]=0` })
      );
      // }
      dispatch(fetchData({ id, query: urlQuery, signal: signal }));
    }
  };

  //   const isFirstRender = useRef(true);

  //   useEffect(() => {
  //     if (!isFirstRender.current) {
  //         dispatch(fetchTargetSellerListingsFilters({id : id }));
  //         dispatch(fetchTargetSellersFilters({id : id }));
  //     } else {
  //         isFirstRender.current = false;
  //     }
  // }, [tabStatus]);

  //   useEffect(() => {
  //     dispatch(fetchTargetSellerListingsFilters({id : id }))
  //     dispatch(fetchTargetSellersFilters({id : id }))
  //  }, [tabStatus]);

  // Handle Pagination

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: { is_data_loaded: 1 },
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    // fetch filters but if search is empty remove the status filter from the query
    if (search === "") {
      const storeQuery = storeQueryBuilder(currentStoreFilters, {
        search: search,
        filters: { status: null, is_data_loaded: 1 },
      });
      const urlQuery = urlQueryBuilder(storeQuery, null, "term");
      // dispatch(fetchFilters({id ,  query : urlQuery}));
    } else {
      // dispatch(fetchFilters({ id, query: urlQuery }));
    }
    dispatch(fetchData({ id, query: urlQuery }));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    // if the filters doesnt include is_data_loaded add it to the filters
    if (!filters?.is_data_loaded) {
      filters = { ...filters, is_data_loaded: 1 };
    }
    // it status filter is 2 then call onHandleTab to change the tab and pass rejectedSellers or rejectedListings depending on the type
    if (
      filters?.status === 2 ||
      (Array.isArray(filters?.status) && filters?.status.includes(2))
    ) {
      const rejected =
        type === "sellers" ? "rejectedSellers" : "rejectedListings";
      onHandleTab(rejected);
    }
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters({id ,  query : urlQuery}));
    // if all of the filters are empty remove the status filter from the query
    if (
      Object.values(filters).filter((item) => item !== null && item !== "")
        .length === 0
    ) {
      const storeQuery = storeQueryBuilder(currentStoreFilters, {
        filters: { status: null },
      });
      const urlQuery = urlQueryBuilder(storeQuery, null, "term");
      // dispatch(fetchFilters({ id, query: urlQuery }));
    } else {
      // dispatch(fetchFilters({ id, query: urlQuery }));
    }
    // dispatch(fetchFilters({id, query: `&filter[status]=${filters?.status}`}));
    dispatch(fetchData({ id, query: urlQuery }));
  };

  const onBrandFilter = (brandId) => {
    onFilterApply({ brand: brandId });
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters({id ,  query : urlQuery}));
    dispatch(fetchData({ id, query: urlQuery }));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    let currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    // dispatch(fetchFilters({id ,  query : urlQuery}));
    await dispatch(fetchData({ id, query: urlQuery }));
  };
  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const handleCallBackActions = (data, type, name) => {
    setPrimaryModalData({
      type: type,
      data: data,
      name: name,
    });
    setPrimaryModalVisibility(true);
  };

  const onFolderUpdate = async (data) => {
    const { id, ...payload } = data;
    if (!id) {
      await dispatch(createRow(payload)).unwrap().then(handleNotifyUser);
    } else {
      await dispatch(updateRow({ id: id, payload }))
        .unwrap()
        .then((res) => {
          if (res?.type === "success") {
            // fetch the folder again
            api.fetchTargetSellerFolder(id).then((res) => {
              setFolder(res?.data);
            });
          }
          return res;
        })
        .then((rs) => {
          handleNotifyUser({
            type: rs?.type,
            message: `${data?.name} has been updated successfully`,
            errors: rs?.errors,
          });
        });
    }
  };

  // Render View of Page
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={folder?.name}
          subTitle={folder?.salesChannel?.name}
          logo={folder?.salesChannel?.logo}
          form={
            tabStatus === "targetSellers"
              ? {
                  callback: (rs) => {
                    setModalVisibility(true);
                  },
                }
              : null
          }
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: ["status", "is_data_loaded"],
            items: filtersView(
              pageData?.filters?.filters,
              pageData?.filtersMeta,
              tabStatus
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            skipStatus: true,
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.status === undefined ||
              pageData?.filters?.filters?.status === null
                ? 0
                : 1)-(pageData?.filters?.filters?.is_data_loaded === undefined ||
                  pageData?.filters?.filters?.is_data_loaded === null
                    ? 0
                    : 1),
          }}
          actionList={actions(selectedRow)}
          options={[
            {
              callBack: handleRefreshListings,

              data: refreshing,
              view: ({ callBack, data }) =>
                type === "listings" && (
                  <UI.Button
                  item={{
                    label: (
                      <div
                        className={`flex flex-row items-center  justify-center gap-[4px] ${
                          !data ? "" : ""
                        }`}
                      >
                        <div className={"flip-x transform scale-x-[-1]"}>
                          <Icons.arrowPath
                            className={`h-5 w-5 ${
                              data ? "animate-spin rotate-[reverse]" : ""
                            }`}
                          />
                        </div>
                        {/* {!data && <TimeAgo timestamp={refreshingDate} /> } */}
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!w-auto !min-w-[28px] !h-[28px] !p-0 ",
                  }}
                    // onClick={handleRowClick}
                    onClick={() => {
                      if (!data) {
                        callBack();
                      }
                    }}
                  />
                ),
                condition: () => selectedRow?.length > 0,
              },
            {
              callBack: () => {
                setSettingsModalVisibility(true);
              },
              order: 3,
              data: refreshing,
              view: ({ callBack, data }) => (
                <UI.Button
                  item={{
                    label: <IoSettingsSharp className={`h-5 w-5 `} />,
                    buttonType: "icon",
                    className: "!w-[28px] !h-[28px] ",
                  }}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
            },
          ]}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            callBack={onHandleTab}
            active={tabStatus}
            loading={pageData?.filterLoading}
            list={[
              {
                id: "targetSellers",
                name: "Target Sellers",
                count:
                  // if type is sellers and status is 1 then count is the count of target sellers
                  tabStatus === "targetSellers" && pageData?.loading === "idle"
                    ? pageData?.meta?.total
                    : pageData?.filtersMeta?.sellersFilters?.data?.statuses?.find(
                        (item) => item?.name === "Approved"
                      )?.count,
              },
              {
                // prospective sellers
                id: "prospectiveSellers",
                name: "Prospective Sellers",
                count:
                  // if type is sellers and status is 0 then count is the count of prospective sellers
                  tabStatus === "prospectiveSellers" &&
                  pageData?.loading === "idle"
                    ? pageData?.meta?.total
                    : pageData?.filtersMeta?.sellersFilters?.data?.statuses?.find(
                        (item) => item?.name === "Pending"
                      )?.count,
              },
              {
                id: "targetListings",
                name: "Target Listings",
                count:
                  // if type is listings and status is 1 then count is the count of target listings
                  tabStatus === "targetListings" && pageData?.loading === "idle"
                    ? pageData?.meta?.total
                    : pageData?.filtersMeta?.listingsFilters?.data?.statuses?.find(
                        (item) => item?.name === "Approved"
                      )?.count,
              },
              {
                id: "prospectiveListings",
                name: "Prospective Listings",
                count:
                  // if type is listings and status is 0 then count is the count of prospective listings
                  tabStatus === "prospectiveListings" &&
                  pageData?.loading === "idle"
                    ? pageData?.meta?.total
                    : pageData?.filtersMeta?.listingsFilters?.data?.statuses?.find(
                        (item) => item?.name === "Pending"
                      )?.count,
              },
            ]}
          />
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(
                  pageData?.targetSellerData,
                  tabStatus,
                  folder,
                  handleCallBackActions,
                  handleApprovementCallback,
                  handleUpdateWeight,
                  onBrandFilter,
                  openListingModal
                )}
                actions={actions}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                loading={pageData.loading}
                meta={pageData?.meta}
                appending={loading}
                loadMoreData={loadMoreData}
                style={{
                  table: {
                    style: { width: "100%", minWidth: "100%" },
                  },
                  header: {
                    className: "justify-center !pl-[16px] !pr[8px]",
                    "tracking id": { className: "!pr-[30px]" },
                    approve: { className: "!py-[0px] hidden" },
                  },
                  row: {
                    className:
                      "!px-[8px] !py-[8px] !items-center !justify-center flex text-[12px] ",
                    // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                    title: {
                      // className: " !min-w-max !w-fit !max-w-fit",
                    },
                  },
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {modalVisibility && (
        <FormModal
          title={"New Target Seller to Folder "}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
          }}
          onSubmit={onSubmitApply}
        />
      )}
      {settingsModalVisibility && (
        <FormModal
          title={"Folder Settings"}
          formView={folderFormView}
          visibility={settingsModalVisibility}
          data={folder}
          onClose={() => {
            setSettingsModalVisibility(false);
          }}
          onSubmit={onFolderUpdate}
        />
      )}
      {PrimaryModalVisibility && (
        <PrimaryModal
          item={primaryModalData}
          visibility={PrimaryModalVisibility}
          onClose={() => {
            setPrimaryModalVisibility(false);
          }}
        />
      )}

      {visibleListingModal && (
        <ListingDetailModal
          show={visibleListingModal}
          onClose={() => setVisibleListingModal(false)}
          modalListing={salesModalListingData}
        />
      )}
    </>
  );
};

export default TargetSellers;
