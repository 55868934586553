import Icons from "../../icons";
import util from "../../../dynamic-page/util";
import React from "react";
import Button from "../../../dynamic-form/elements/Button";

const GoToTrackURL = Icons.goToTrackURL;
const HideLabel = Icons.hideLabel;
const ShowLabel = Icons.showLabel;
const Star = Icons.star;
const Track = Icons.track;

const shipmentLabelList = ({ shipmentData, actions, disabled }) => {
  return (
    <div
      className={
        "min-w-max px-[8px] py-[4px] text-[14px] flex flex-col gap-[10px]"
      }
    >
      {shipmentData?.shipment_labels?.map((item, index) => (
        <div
          className={
            "flex flex-row justify-between items-center gap-[20px] py-[2px]"
          }
          key={item?.tracking_number}
        >
          <button
            className={
              "flex flex-row gap-[4px] items-center box-content cursor-pointer"
            }
            onClick={() => window.open(item?.tracking_url, "_blank")}
          >
            {/* <util.popUp
                            toggle={(selected) => (item?.warehouse?.flag ?
                                    <img
                                        onClick={() =>
                                            actions.handleFilterWarehouse(
                                                item?.warehouse?.id
                                            )
                                        }
                                        className={"min-w-[16px] h-[16px]"}
                                        src={item?.warehouse?.flag}
                                    /> : <IoStorefront className={"w-4 h-4 text-green-300"}
                                                       onClick={() =>
                                                           actions.handleFilterWarehouse(
                                                               item?.warehouse?.id
                                                           )
                                                       }
                                    />
                            )}
                            action={"hover"}
                        >
                            <div className={"text-[12px] text-gray-900 font-medium"}>
                                {item?.warehouse?.name}
                            </div>
                        </util.popUp> */}
            {item?.international_carrier?.logo ? (
              <img
                className={"min-w-[20px] h-[20px]"}
                src={item?.international_carrier?.logo}
                alt={item?.international_carrier?.name}
              />
            ) : (
              <Track className={"w-[16px] h-4 text-blue-400"} />
            )}
            <span>{item?.tracking_number}</span>
            <GoToTrackURL className={"text-blue-700 w-[9px]"} />
          </button>

          <div className={"flex flex-row gap-[10px]"}>
            <div className={"flex flex-row gap-[6px]"}>
              <span className={"text-gray-400 min-w-max"}>
                {item?.last_status_update
                  ? new Date(item?.last_status_update).toLocaleDateString()
                  : "No status update date!"}
              </span>

              <span className={"text-gray-400 font-semibold min-w-max"}>
                {util.upperCaseString(item?.current_status, "word")}
              </span>
            </div>
            <div
              className={
                "text-blue-600 h-[16px] flex flex-row items-center gap-[4px]"
              }
            >
              {/*<Button item={{buttonType: "flat"}}>
                                <Icons.shipmentDetail className={`w-[20px] p-[2px]`}/>
                            </Button>*/}
              <Button
                item={{ buttonType: "flat" }}
                onClick={() =>
                  actions.handleViewSecodaryLabel(item)
                }
              >
                {item?.is_hidden ? (
                  <HideLabel className={"w-[20px] p-[0px] opacity-30"} />
                ) : (
                  <ShowLabel className={"w-[20px] p-[0px]"} />
                )}
              </Button>
              <Button
                item={{ buttonType: "flat" }}
                onClick={() => {
                  if (!disabled) actions.handleIsPrimaryToggle(item?.id);
                }}
              >
                <Star
                  className={`w-[16px] m-[2px] ${
                    !item?.is_primary && "opacity-30"
                  }`}
                />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default shipmentLabelList;
