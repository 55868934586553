import filterObjectByKeys from "../../../../../dynamic-page/util/filterObjectByKeys";

const prepareDataToViewItem = (detailView) => {
    let innerItem = filterObjectByKeys(detailView, [
        "item_image:image",
        "item_title:title",
        "item_sku:sku",
        "item_identifier:listing_id",
        "upc",
        "qty",
        "unit_price",
        "arbitbox_product.id:arbitbox_product_id",
        "unit_price_currency.id:unit_price_currency",
        "id",
        "item_qty_status_code",
        "item_qty_color_code",
        "customer_note",
        "customer_notes_enabled",
        "internal_notes",
        "internal_notes_enabled",
        "shipping_content",
        "shipping_content_enabled",
        "shipping_documents",
        "item_status",
        "item_status_color_code",
        "status",
        "sales_channel_item_id",
        "sales_channel_product_id",
        "unit_shipping_price",
        "is_adjustment",
        "created_at",
        "updated_at",
        "gtip",
        "gtip_enabled",
    ]);

    return {
        ...innerItem,
        _data: {
            arbitbox_product_id: {data: detailView.arbitbox_product},
            unit_price_currency: {data: detailView?.unit_price_currency},
            total_price_currency: {data: detailView?.total_price_currency},
            order_id: detailView.order_id,
            isView: true,
        },
    };
};

export default prepareDataToViewItem;
