import upperCase from "../../dynamic-page/util/upperCaseString";
import * as Yup from "yup";

const createForm = (data) => {
  return [
    {
      name: "type",
      type: "Module",
      // label : "Components",
      module: methodModule,
      defaultValue: data?.type || 1,
      className: "col-span-2",
      container : '!gap-0'
      // placeholder: "Select a Single Product to add more to the Grouped Product",
      // condition: (data) => data?.type === 2,
  },
    {
      name: "name",
      label: "Transfer Name",
      placeholder: "ex. Pegasus Cosmetics Transfer",
      type: "Input",
      defaultValue: data?.name || null, //|| data?.order_number || null,
      validation: Yup.string().required('Name is required'),
      innerClassName: " bg-[rgba(0,0,0,0.03)]",
      clear: false,
      reset: false,
    },
    {
      name: "recipient_name",
      label: "Recipient Name",
      placeholder: "ex. John Doe",
      type: "Input",
      defaultValue: data?.recipient_name || null, //|| data?.order_number || null,
      validation: Yup.string().required('Recipient name is required'),
      innerClassName: " bg-[rgba(0,0,0,0.03)]",
      clear: false,
      reset: false,
    },
    {
      label: "Transfer from",
      name: "from_warehouse_id",
      type: "Select",
      api: {
        url: "/api/v1/warehouses",
        //url: '/api/v1/warehouses',
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCase(item?.name, "word", true),
        }),
      },
      // className: "h-[42px]",
      style: {
        control: {
          background: "rgba(0,0,0,0.03)",
          paddingTop: "0px",
          paddingBottom: "0px"
        },
        menu: {
          whiteSpace: 'wrap !important',
        },
      },
      defaultValue: data?.from_warehouse_id || null,
      reset: false,
      clear: false,
      validation: Yup.string().required('Source is required'),
      placeholder: "Select the source",
    },
    {
      name: "destination",
      label: "Transfer to",
      placeholder: "Select the destination",
      type: "Select",
      // innerClassName: "flex flex-col ",
      api: {
        url: "/api/v1/warehouses?include=state",
        skipCancel: true,
        url2: "/api/v1/pickup-points?include=state",
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        optionValue: (item) => ({
          // value: item?.id,
          type: "warehouse",
          value: 'warehouse|' + item?.id,
          label: upperCase(item?.name, "word", true),
        }),
        optionValue2: (item) => ({
          type: "pickup_point",
          value:  'pickup_point|' + item?.id,
          label: upperCase(item?.name, "word", true),
        }),
      },
      style: {
        control: {
          background: "rgba(0,0,0,0.03)",
          paddingTop: "0px",
          paddingBottom: "0px"
        },
        menu: {
          whiteSpace: 'wrap !important',
        },
      },
      clear: false,
      reset: false,
      defaultValue: data?.destination || null, //|| data?.sales_channel?.id || null,
      validation: Yup.string().required('Destination is required'),
    },
  ];
}

export default createForm;



const methodModule = ({ item, binded, change, data }) => {

  return (
    <div className="flex gap-2  items-center w-[668px] ">
            <div
        onClick={() => {
          change('product', "type");
              }}
              className="flex relative gap-3.5 cursor-pointer items-center p-5 flex-1 rounded-[8px] drop-shadow-lg bg-white"
      >
        <input 
          type="radio"
          name="type"
          value={'product'}
          checked={data?.type === 'product'}
          className="absolute top-4 right-4"
        />
              <div className="w-[48px] h-[48px] bg-blue-100 flex justify-center items-center rounded-[8px]">
                <svg
                  className="w-6 h-6 text-blue-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="text-[18px] leading-[27px] font-bold text-gray-900 ">
                  Product Transfer
                </span>
                <span className="text-[14px] leading-[17.5px] text-gray-500 font-normal ">
                  between warehouses
                </span>
              </div>
            </div>
            <div
        onClick={() => {
          change('order', "type");
              }}
              className="flex relative cursor-pointer gap-3.5 items-center p-5 flex-1 rounded-[8px] drop-shadow-lg bg-white"
      >
        <input 
          type="radio"
          name="type"
          value={'order'}
          checked={data?.type === 'order'}
          className="absolute top-4 right-4"
        />
              <div className="w-[48px] h-[48px] bg-green-100 flex justify-center items-center rounded-[8px]">
                <svg
                  class="w-6 h-6 text-green-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="text-[18px] leading-[27px] font-bold text-gray-900 ">
                  Order Transfer
                </span>
                <span className="text-[14px] leading-[17.5px] text-gray-500 font-normal ">
                  to smooth delivery
                </span>
              </div>
            </div>
          </div>
  )
}