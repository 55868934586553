import React, {memo, useState} from "react";
import Box from "../../../../../dynamic-page/elements/Box"
import AddItemForm from "./addItemForm";
import ItemList from "./itemList"
import DropDownMenu from "../../../../../dynamic-page/elements/DropDownMenu";
import UI from "../../../../../dynamic-form/elements";
import Icons from "../../../../icons";
import {useDispatch} from "react-redux";
import {apiActions} from "../../../../../../store/orders";
import util from "../../../../../dynamic-page/util";

import prepareDataToAddItem from "../util/prepareDataToAddItem";
import FooterActions from "./FooterActions";


const SummaryItems = memo(({orderItems, callBack, validation, isView, id, actionCallBack, data, update}) => {

    const [actionList, setActionList] = useState([])
    const [editProductForm, setEditProductForm] = useState(null)

    const dispatch = useDispatch()
    const handleAddProduct = async (item, duplicateLocal) => {
        if (isView && !duplicateLocal) {
            const mapData = await prepareDataToAddItem(item)
            dispatch(apiActions({
                data: mapData,
                action: "order-item",
                method: "POST",
                orderId: id?.order[0],
            })).then((result) => {
                util.notifier(result?.payload?.data)
                if (result?.payload?.data?.type === "success") {
                    callBack([item, ...orderItems || []])
                }
            })
        } else {
            callBack([item, ...orderItems || []])
        }

        //callBack([item, ...orderItems || [] ])
    }
    const handleEditProduct = (item) => {
        // setEditProductForm(item)
    }
    const handleUpdateProduct = (items) => {
        callBack(items)
    }
    const handleActionButton = (actions) => {
        setActionList(actions)
    }
    return (
        isView ? <Box title={<span
            className={`${validation?.validationErrors?.order_items && !orderItems?.length ? "text-red-600" : ""}`}>Order Items ({orderItems?.length || 0})</span>}
                      className={"flex flex-grow justify-end !max-h-[600px] "} innerClassName={"justify-end overflow-hidden "}
                      options={
                          <>
                              {actionList?.count > 0 && actionList?.list.length > 0 &&
                                  <div className={"relative"}>
                                      <DropDownMenu
                                          toggleButton={(selected) => (
                                              <UI.Button
                                                  item={{
                                                      label: (<div
                                                          className={"flex items-center justify-between gap-[12px]"}>Actions<Icons.arrow
                                                          className={"w-[8px]"}/></div>),
                                                      buttonType: "primary",
                                                      className: `${selected ? "bg-blue-200 text-blue-600" : "text-red-600"} w-[100px] h-[34px]`
                                                  }}
                                                  onClick={actionList.callback}
                                              />
                                          )}
                                          itemMenu={actionList}
                                      />
                                      <div
                                          className={`absolute flex top-[6px]  font-bold -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[18px] min-w-[18px] items-center justify-center ${actionList?.count < 1 ? "hidden" : "block"}`}>{actionList?.count}</div>
                                  </div>
                              }

                          </>
                      }
        >
            <div className=' flex flex-col justify-start flex-1  gap-[24px] overflow-auto rounded-[0px] h-full'>
                <ItemList
                    orderItems={orderItems}
                    actionButton={handleActionButton}
                    actionCallBack={handleUpdateProduct}
                    actionEditCallBack={handleEditProduct}
                    actionConnectCallBack={editProductForm}
                    actionAddProduct={handleAddProduct}
                    orderId={id?.order?.[0]}
                    isView={isView}
                    is_manual={data?.summary?.is_manual}
                    update={update}
                />
                {/* <AddItemForm
                    callBack={handleAddProduct}
                    editProduct={editProductForm}
                    itemList={orderItems}
                /> */}
            </div>
        </Box> : (
                <Box 
                              className={"flex flex-grow !pt-3 justify-end"} innerClassName={"justify-end overflow-hidden"}
                >
            <div className=' flex flex-col justify-between flex-1  overflow-hidden rounded-[0px] h-full'>
                <AddItemForm
                    callBack={handleAddProduct}
                    editProduct={editProductForm}
                    itemList={orderItems}
                    // type = {summaryForm?.type}
                />
                <ItemList
                    orderItems={orderItems}
                    actionButton={handleActionButton}
                    actionCallBack={handleUpdateProduct}
                    actionEditCallBack={handleEditProduct}
                    actionConnectCallBack={editProductForm}
                    actionAddProduct={handleAddProduct}
                    orderId={id?.order[0]}
                            // type = {summaryForm?.type}
                            update={update}
                    isView={isView}
                />
                <FooterActions
                    list={orderItems}
                    // type={summaryForm?.type}
                    data={data}
                    actionCallBack={actionCallBack}
                />
                    </div>
                </Box>
        )
    )

})
//setProducts={setProducts} products={products} form={form} setForm={setForm}
export default SummaryItems;