import React from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import util from "../../dynamic-page/util";
import descriptiveContent from "../../../utils/descriptiveContent";
import {HiExternalLink} from "react-icons/hi";

const orderId = ({order, callBack, orderId, country, offer}) => {
    return (
        <div className={"justify-start w-full"}>
            <div className={"flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] "}>


                {order?.country?.flag &&
                    <util.popUp
                        toggle={(selected) => (
                            descriptiveContent(<img src={order?.country?.flag}
                                                    className={"h-[12px] w-[17px] rounded-[2px]"}
                                                    style={{objectFit: 'cover'}}/>, order?.country?.name)
                        )}
                        action={"hover"}
                    >
                        <div className={"px-[8px] py-[4px]"}> {order?.country?.name} </div>
                    </util.popUp>


                }


                {order?.marketplace && <img
                    className="h-[20px] object-cover"
                    src={order?.marketplace?.logo}
                />}
                {order?.account &&
                    <span className={"text-[14px h-18px items-center flex"}>{upperCaseString(order?.account)}</span>}
                {/* {order?.logo && descriptiveContent( <img src={order?.logo} className={"h-[12px] "} />, order?.name)}
                {order?.account && <span className={"text-[14px h-18px items-center flex"}>{descriptiveContent(upperCaseString(order?.account) , 'Account')}</span>} */}

            </div>

            <div
                className={" whitespace-no-wrap text-blue-600 text-[12px] hover:text-blue-700 cursor-pointer flex flex-row "}
            >
                <span onClick={() => {
                    // callBack({action:"orderDetails", data: {id: orderId}})
                    callBack(offer)
                }}>
                    {descriptiveContent(order?.order_number, 'Identifier')}
                </span>
                <div>
                    <util.copyText className={"text-[12px] hover:text-gray-500"} text={order?.order_number} hint={false}/>
                </div>
                <HiExternalLink className="w-4 h-4 text-gray-300 hover:text-gray-500 cursor-pointer"
                                onClick={() => {
                                    window.open(order?.listingUrl, '_blank')
                                }}
                />
            </div>
        </div>

    )

}
export default orderId;