const sortView = ({callback}) => {
    return {
        list: [

            {
                label: "Quantity",
                value: "quantity",
                onClick: (item) => {
                    callback("quantity");
                }
            },
            {
                label: "Created Date",
                value: "created_at",
                onClick: (item) => {
                    callback("created_at");
                }
            },
            {
                label: "Expire Date",
                value: "expiry_date",
                onClick: (item) => {
                    callback("expiry_date");
                }
            },
            {
                label: "Product",
                value: "product",
                onClick: (item) => {
                    callback("product");
                }
            },
            {
                label: "Available Quantity",
                value: "available_quantity",
                onClick: (item) => {
                    callback("available_quantity");
                }
            },
            {
                label: "Warehouse",
                value: "warehouse",
                onClick: (item) => {
                    callback("warehouse");
                }
            },
            {
                label: "Accepted By",
                value: "accepted_by",
                onClick: (item) => {
                    callback("accepted_by");
                }
            },

        ]


    }
}

export default sortView