import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchShippingPrice = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGPRICING;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const searchShippingPrice = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGPRICING + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteShippingPrice = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_SHIPPINGPRICING + payload, {withCredentials: true});
    return response;
};

const createShippingPrice = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_SHIPPINGPRICING, payload, {withCredentials: true});
    return response;
};

const updateShippingPrice = async (id, payload) => {
    let url = constants.APIURL.PUT_SHIPPINGPRICING + id;
    const response = await axios.put(url, payload, {withCredentials: true});
    return response;
};

const fetchShippingPricesFilters = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGPRICING_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const bulkDeleteShippingPrices = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BULK_SHIPPING_PRICING_LIST, {
        withCredentials: true,
        data: payload
    });
    return response;
};

const bulkStatusUpdateShippingPrices = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_SHIPPING_PRICING_LIST_STATUS, payload, {withCredentials: true});
    return response;
};

const restoreShippingPrices = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_RESTORE_SHIPPINGPRICELIST, payload, {withCredentials: true});
    return response;
};

const duplicateShippingPrice = async (id) => {
    const response = await axios.post(
        constants.APIURL.DUPLICATE_SHIPPINGPRICING + id,
        {withCredentials: true}
    )
    return response;
}

export default {
    fetchShippingPrice,
    deleteShippingPrice,
    createShippingPrice,
    updateShippingPrice,
    searchShippingPrice,
    fetchShippingPricesFilters,
    bulkDeleteShippingPrices,
    bulkStatusUpdateShippingPrices,
    restoreShippingPrices,
    duplicateShippingPrice
};
