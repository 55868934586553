const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Name",
                value: "name",
                onClick: (item) => {
                    callback("name");
                }
            },
            {
                label: "Code",
                value: "code",
                onClick: (item) => {
                    callback("code");
                }
            },
            {
                label: "Status",
                value: "status",
                onClick: (item) => {
                    callback("status");
                }
            },


        ]
    }
}

export default sortView;