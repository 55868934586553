import axios from "./../../middleware/axios";
import constants from "../../constant";


const fetchAccounts = async (params) => {
    let url = constants.APIURL.GET_ACCOUNTS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
};

const updateAccounts = async ({payload}) => {
    // const response = await axios.post(constants.APIURL.GET_ACCOUNTS_UPDATE, apiutils.obj2Form(payload), { withCredentials: true })
    // return response
}

const fetchAccountsFilters = async (params) => {
    let url = constants.APIURL.GET_ACCOUNTS_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
}

// bulk status update 
const updateAccountsStatus = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_ACCOUNTS_STATUS, payload, {withCredentials: true})
    return response
}

// single delete 
const deleteAccount = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_ACCOUNTS + payload, {withCredentials: true})
    return response
}

// single restore
const restoreAccount = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_ACCOUNTS + payload + '/restore', {withCredentials: true})
    return response
}

const bulkDeleteAccounts = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_ACCOUNTS, {data: payload}, {withCredentials: true})
    return response
}

const createAccount = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_ACCOUNT, payload, {withCredentials: true})
    return response
}

const updateAccount = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_ACCOUNT + id, payload, {withCredentials: true})
    return response
}

const attachProxies = async ({id, payload}) => {
    const response = await axios.post(constants.APIURL.PUT_UPDATE_ACCOUNT + id + '/proxy/attach', {proxies: payload}, {withCredentials: true})
    return response
}

const detachProxies = async ({id, payload}) => {
    const response = await axios.post(constants.APIURL.PUT_UPDATE_ACCOUNT + id + '/proxy/detach', {proxies: payload}, {withCredentials: true})
    return response
}


const syncWithMarketplace = async () => {
    return await axios.post(constants.APIURL.POST_SYNC_WITH_MARKETPLACE, null, {withCredentials: true})
}

const authorizeStore = async (id) => {
    const response = await axios.post(constants.APIURL.GET_ACCOUNTS + '/' + id + '/authorize-store', null, {withCredentials: true})
    return response
}


const authorizeStoreCallBack = async (payload, marketplace) => {
    return await axios.post(constants.APIURL.ACCOUNTS_STORE_OAUTH_CALLBACK + '/' + marketplace, payload, {withCredentials: true})
}


export default {
    fetchAccounts,
    updateAccounts,
    fetchAccountsFilters,
    updateAccountsStatus,
    deleteAccount,
    restoreAccount,
    bulkDeleteAccounts,
    createAccount,
    updateAccount,
    attachProxies,
    detachProxies,
    syncWithMarketplace,
    authorizeStore,
    authorizeStoreCallBack
};
