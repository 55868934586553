import phone from "./phone.png";
import {ReactComponent as globe} from "./globe-alt.svg";
import {ReactComponent as mail} from "./mail.svg";
import {ReactComponent as location} from "./location-marker.svg";
import {ReactComponent as wholesaler} from "./wholesaler.svg";
import {ReactComponent as website} from "./website.svg";
import {ReactComponent as Amount} from "./Amount.svg";

export default {
    phone,
    globe,
    mail,
    location,
    wholesaler,
    website,
    Amount
}