import {useEffect, useState} from "react"
import Modal from "../sub-elements/modal"
import {HiCheck,} from "react-icons/hi";
import ArbitButton from "../sub-elements/button";
import Dropdown from "../sub-elements/dropdown";
import axios from '../../middleware/axios';
import constants from '../../constant';
import AsyncSelect from 'react-select/async';
import priceHandler from "../../utils/priceHandler";

import {useFormik} from "formik";
import * as Yup from "yup";

import moment from "moment";
import DescriptiveDropDown from "../sub-elements/descriptive-drop-down";
import quantityHandler from "../../utils/quantityHandler";

// import 'react-Select.js/dist/react-Select.js.css'
// import 'react-virtualized/styles.css'
// import 'react-virtualized-Select.js/styles.css'

const ShippingCostListAddEditModal = ({type, userId, data, onClose, onUpdate, onAdd, show, purchases, purchase}) => {


    const [account_id, setAccountId] = useState()
    const [submit, setSubmit] = useState(false)


    const suppliesOptions = purchases?.map(option => ({
        value: option?.id,
        label: `#${option?.purchase_no}  |  ` + (option?.product?.title ? option?.product?.title : '') + `  |   QTY: ${quantityHandler(option?.quantity)}`,
        description: 'Created By:  ' + option?.created_by?.name + ' | ' + 'Created At:  ' + moment(option?.created_at).format('DD-MM-YYYY'),
        purchase_price: option?.total_due,
        currency: option?.currency,
    }));


    useEffect(() => {
        if (type === "Edit") {
            setAccountId({
                value: data?.account?.id,
                label: data?.account?.bankName,
                availableBalance: data?.account?.availableBalance,
                currency: data?.account?.currency,
            })
            setPurchaseOption({
                value: data?.purchase?.id,
                label: `#${data?.purchase?.purchase_no}  |  ` + (data?.purchase?.product?.title ? data?.purchase?.product?.title : '') + `  |   QTY: ${quantityHandler(data?.purchase?.quantity)}`,
                description: 'Created By:  ' + data?.purchase?.created_by?.name + ' | ' + 'Created At:  ' + moment(data?.purchase?.created_at).format('DD-MM-YYYY'),
                purchase_price: parseInt(priceHandler(data?.purchase?.purchase_price)),
                currency: data?.purchase?.currency,
            })
        }
    }, [data, type])


    const [purchaseOption, setPurchaseOption] = useState('')

    const fetchPurchases = async (params) => {
        let url = constants.APIURL.GET_SUPPLY_PURCHASES
        if (params)
            url = url.concat(params)
        const response = await axios.get(url, {withCredentials: true});
        // console.log(response);
        return response.data

    }

    // console.log(parseInt(priceHandler(1777)));

    const mapCategoriesOptionsToValues = options => {
        return options.map(option => ({
            value: option?.id,
            label: `#${option?.purchase_no}  |  ` + (option?.product?.title ? option?.product?.title : '') + `  |   QTY: ${quantityHandler(option?.quantity)}`,
            description: 'Created By:  ' + option?.created_by?.name + ' | ' + 'Created At:  ' + moment(option?.created_at).format('DD-MM-YYYY'),
            purchase_price: option?.total_due,
            currency: option?.currency,
        }));
    };
// useEffect (() => {
//   if (type === 'Edit') {
//     const fetchPurchases = async (params) => {
//       let url = constants.APIURL.GET_SUPPLY_PURCHASES + `/${data?.purchase_id}`
//       if (params)
//           url = url.concat(params)
//       const response = await axios.get(url, { withCredentials: true });
//       console.log(response);
//       setPurchase({
//         value: response?.data?.data?.id,
//         label: `#${response?.data?.data?.purchase_no}  |  ` + (response?.data?.data?.product?.title ? response?.data?.data?.product?.title : '' ) + `   |   QTY: ${priceHandler(response?.data?.data?.quantity)}` ,
//         description: 'Created By:  ' + response?.data?.data?.created_by?.name + ' | ' + 'Created At:  ' + moment(response?.data?.data?.created_at).format('DD-MM-YYYY'),
//       })
//       return response.data

//   }
//   fetchPurchases()
//   }
// }, [])


    const loadPurchasesOptions = async (inputValue) => {


        let url;

        url = "/search?term=" + inputValue

        // console.log(url);
        return new Promise(resolve => {
            setTimeout(() => {
                fetchPurchases(url)
                    .then(response => {
                        // console.log(response);
                        const options = mapCategoriesOptionsToValues(response.data);
                        // console.log(options);
                        // setProducts(options)
                        resolve(options)
                    })
                    .catch(error => {
                            // console.log(error);
                        }
                    );
            }, 1000)
        });

    }


    const validationSchema = Yup.object({
        account: Yup.string().required("Required"),
        amount: Yup.number().required("Required"),
        status: Yup.string().required("Required"),
        payment_date: Yup.string().required("Required"),
        currency_id: Yup.mixed().required("Required"),
        ...!purchase && {
            purchase_id: Yup.mixed().required("Required"),
        }
    })
    const formik = useFormik({
        initialValues: type !== 'Add' ? {
            account: data?.account?.id,
            amount: data?.amount,
            status: data?.status,
            payment_date: data?.date?.split(' ')[0],
            note: data?.note,
            cheque_no: data?.transaction?.chequeNo,
            voucher_no: data?.transaction?.voucherNo,
            currency_id: {
                value: data?.currency?.id,
                label: data?.currency?.name,
            },
            purchase_id: data?.purchase?.id,
        } : {
            account: "",
            amount: purchase?.total_due || purchase?.total_due === 0 ? purchase?.total_due : "",
            status: 1,
            payment_date: moment().format('YYYY-MM-DD'),
            note: "",
            cheque_no: "",
            voucher_no: "",
            currency_id: ""
        },
        validationSchema: validationSchema,
        enableReinitialze: true,
        onSubmit: (values) => {
            setSubmit(true)
            const payload = {
                ...values,
                purchase_id: purchase?.id || purchaseOption?.value,
                currency_id: values?.currency_id?.value,
            }
            if (type === "Add") {
                onAdd(payload)

            } else {
                const payload = {
                    ...values,
                    currency_id: values?.currency_id?.value,
                    purchase_id: purchaseOption?.value,

                }
                onUpdate(data?.id, payload)
            }
            onClose()
        }
    })


    const mapOptionsToValues = options => {
        return options?.map(option => ({
            value: option?.id,
            label: option?.name || option?.display_name,

        }));
    };
    const mapAccountsOptionsToValues = options => {
        return options?.map(option => ({
            value: option?.id,
            label: option?.bankName,
            availableBalance: option?.availableBalance,
            currency: option?.currency,

        }));
    };

    const [currencies, setCurrencies] = useState([])
    useEffect(() => {
        async function fetchCurrencies() {
            await axios.get(constants.APIURL.GET_CURRENCIES, {withCredentials: true}).then(response => {
                    // setCurrencies(response?.data?.data)
                    const options = mapOptionsToValues(response?.data?.data);
                    setCurrencies(options)
                }
            )
        }

        fetchCurrencies()
    }, [])

    const fetchCurrencies = async (params) => {
        let url = "api/v1/currencies"
        if (params)
            url = url.concat(params)
        const response = await axios.get(url, {withCredentials: true});
        return response.data
    }

    const currenciesOptions = async (inputValue) => {
        return new Promise(resolve => {
            fetchCurrencies("/search?term=" + inputValue)
                .then(response => {
                    const options = mapOptionsToValues(response.data);
                    resolve(options)
                })
                .catch(error => {
                        // console.log(error);
                    }
                );
        });
    };


    const [accounts, setAccounts] = useState([])
    const [accountsOptions, setAccountsOptions] = useState([])
    useEffect(() => {
        async function fetchAccounts() {
            await axios.get(constants.APIURL.GET_BANK_ACCOUNTS, {withCredentials: true}).then(response => {
                    // setAccounts(response?.data?.data)
                    const options = mapAccountsOptionsToValues(response?.data?.data);
                    setAccounts(options)
                }
            )
        }

        fetchAccounts()
    }, [])

    const fetchAccounts = async (params) => {
        let url = "api/v1/bank-accounts"
        if (params)
            url = url.concat(params)
        const response = await axios.get(url, {withCredentials: true});
        return response.data
    }

    const loadAccountsOptions = async (inputValue) => {
        return new Promise(resolve => {
            fetchAccounts("/search?term=" + inputValue + '&not_deleted=true')
                .then(response => {
                    const options = mapAccountsOptionsToValues(response.data);
                    resolve(options)
                })
                .catch(error => {
                        // console.log(error);
                    }
                );
        });
    };


    useEffect(() => {
        if (!purchase && purchaseOption && type === 'Add') {
            formik.setFieldValue('purchase_id', purchaseOption?.value)
            formik.setFieldValue('amount', purchaseOption?.purchase_price)
            formik.setFieldValue('currency_id', {
                value: purchaseOption?.currency?.id,
                label: purchaseOption?.currency?.name,
            })
        }
        if (purchase) {
            // formik.setFieldValue('purchase_id', purchase?.id)
            // formik.setFieldValue('amount', priceHandler(purchase?.total_due))
            formik.setFieldValue('currency_id', {
                value: purchase?.currency?.id,
                label: purchase?.currency?.name,
            })
        }

    }, [purchaseOption])


    return (
        <Modal title={`  ${type === 'Add' ? 'Add New' : "Edit"} Purchase Payment`} show={show} onClose={onClose}
               center={true}>
            <form className="form-content" onSubmit={formik.handleSubmit}>
                {
                    // console.log(formik, 'formik')
                }
                <div className="flex flex-col gap-[8px]">
                    {!purchase && <>
                        <label className="w-[150px] text-[16px] text-gray-700" htmlFor="purchase">Purchase</label>
                        <DescriptiveDropDown
                            loadOptions={loadPurchasesOptions}
                            placeholder={'Search for a purchase'}
                            options={suppliesOptions}
                            cacheOptions
                            defaultOptions
                            width={'685px'}
                            height={'42px'}
                            data={purchaseOption}
                            setData={setPurchaseOption}
                            submit={submit}
                        />
                    </>}
                    <div className="grid grid-cols-2 items-start gap-[16px] w-full justify-center">
                        <div className="flex flex-col  gap-[8px]">
                            <label className="w-[150px] text-[16px] text-gray-700" htmlFor="name">Account</label>
                            <AsyncSelect
                                id="account"
                                loadOptions={loadAccountsOptions}
                                defaultOptions
                                onChange={(data) => {
                                    setAccountId(data)
                                    formik.values.account = data.value;
                                    formik.handleChange.account = data.value;
                                }}
                                onBlur={formik.handleBlur}
                                value={account_id}
                                placeholder={'Select Account'}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '334px',
                                        height: '42px',
                                        border: `${(formik.touched.account && formik.errors.account) ? "1px solid red" : "1px solid #E5E7EB"}`,
                                        borderRadius: '8px',
                                    }),
                                    menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '334px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                    }),
                                }}
                            />
                        </div>
                        {/* disabled input to show the available balance */}

                        <div className="flex flex-col  gap-[8px]">
                            <label className="w-[150px] text-[16px] text-gray-700" htmlFor="type">Available
                                Balance</label>
                            <input
                                id="availableBalance"
                                name="availableBalance"
                                type="text"
                                placeholder="Available Balance"
                                className={`w-[334px] h-[40px] border bg-gray-200 border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8]`}
                                value={account_id?.availableBalance || account_id?.availableBalance === 0 ? priceHandler(account_id?.availableBalance, account_id?.currency) : ''}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col  gap-[8px] ">
                            <label className="w-[334px] text-[16px] text-gray-700" htmlFor="amount">Amount</label>
                            {/* <SearchableDropdown
              options={options}
              data={fromState}
              setData={setFromState}
              
            /> */}
                            <input
                                id="amount"
                                name="amount"
                                type="number"
                                placeholder="Amount"
                                className={`w-[334px] h-[40px] border border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8] ${formik.touched.amount && formik.errors.amount ? 'border-red-500' : ''}`}
                                value={formik.values.amount}
                                onChange={(e) => {
                                    if (e.target.value >= 0)
                                        formik.handleChange(e)
                                }}
                                onBlur={formik.handleBlur}
                            />
                        </div>

                        <div className="flex flex-col  gap-[8px] ">
                            <label className="text-base text-[16px] text-gray-700">Currency</label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isDisabled={true}
                                loadOptions={currenciesOptions}
                                placeholder={'Currency'}
                                className="border-none h-[42px] bg-transparent w-[338px]"
                                name="currency"
                                value={formik.values.currency_id}
                                onChange={(value) => {
                                    // console.log(value);
                                    formik.setFieldValue('currency_id', value)
                                }}
                                onBlur={formik.handleBlur}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '334px',
                                        height: '42px',
                                        border: `${(formik.touched.currency_id && formik.errors.currency_id) ? "1px solid red" : "1px solid #E5E7EB"}`,
                                        borderRadius: '8px',
                                    }),
                                    menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '334px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                    }),
                                }}
                            />
                        </div>

                        {type === 'Edit' && <div className="flex flex-col  gap-[8px]">
                            <label className="w-[150px] text-[16px] text-gray-700" htmlFor="status">Status</label>
                            {/* dropdown for status */}
                            <Dropdown
                                bg_color="white"
                                text_color="gray"
                                width="334px"
                                height="42px"
                                id='category_type'
                                value={
                                    formik.values.status === 1 ? 'Active' : formik.values.status === 0 ? 'Inactive' : 'Select Status'
                                }
                                degree={formik.values.status === null || formik.values.status === '' ? '400' : '600'}
                                items={['Active', 'Inactive']}
                                onSelect={(item) => {
                                    formik.setFieldValue('status', item === 'Active' ? 1 : 0)
                                }
                                }
                                align="left"
                                border_color={formik.touched.status && formik.errors.status ? 'red' : 'gray'}
                            />

                        </div>}

                        <div className="flex flex-col  gap-[8px]">
                            <label className="w-[150px] text-[16px] text-gray-700" htmlFor="payment_date">Payment
                                Date</label>
                            <input
                                id="payment_date"
                                name="payment_date"
                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                placeholder="Date"
                                className={`w-[334px] h-[40px] border border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8] ${formik.touched.payment_date && formik.errors.payment_date ? 'border-red-500' : ''}`}
                                value={formik.values.payment_date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />

                        </div>

                        <div className="flex flex-col  gap-[8px]">
                            <label className="w-[150px] text-[16px] text-gray-700" htmlFor="cheque_no">Cheque
                                No.</label>
                            <input
                                id="cheque_no"
                                name="cheque_no"
                                type="text"
                                placeholder="Cheque No."
                                className={`w-[334px] h-[40px] border border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8]`}
                                value={formik.values.cheque_no}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col  gap-[8px]">
                            <label className="w-[150px] text-[16px] text-gray-700" htmlFor="voucher_no">Voucher
                                No.</label>
                            <input
                                id="voucher_no"
                                name="voucher_no"
                                type="text"
                                placeholder="Voucher No."
                                className={`w-[334px] h-[40px] border border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8]`}
                                value={formik.values.voucher_no}
                                onChange={formik.handleChange}
                            />
                        </div>


                    </div>
                    <div className="flex flex-col w-full gap-[8px]">
                        <label className="w-[150px] text-[16px] text-gray-700" htmlFor="note">Note</label>
                        <textarea
                            id="note"
                            name="note"
                            type="text"
                            placeholder="Note"
                            className={`w-full h-[84px] border overflow-hidden border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8]`}
                            value={formik.values.note}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="flex justify-end">
                        <div className="mx-1">
                            <ArbitButton
                                type='submit'
                                onClick={() => setSubmit(true)}
                            >
                                <HiCheck className="mr-2 h-5 w-5"/>
                                Save
                            </ArbitButton>
                        </div>
                        <div className="mx-1">
                            <ArbitButton
                                bg_color="white"
                                border_color="blue"
                                text_color="blue-600"
                                onClick={() => onClose()}
                            >
                                Cancel
                            </ArbitButton>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

ShippingCostListAddEditModal.defaultProps = {
    type: "Add",
    onUpdate: () => {
    },
    onCancel: () => {
    },
    onAdd: () => {
    },
    data: {}
}

export default ShippingCostListAddEditModal;
