export function obj2Form(data, imageKeys = []) {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null && !imageKeys.includes(key)) {
            // Stringify objects
            formData.append(key, JSON.stringify(value));
        } else {
            formData.append(key, value);
        }
    });

    return formData;
}
