const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Name",
                value: "name",
                onClick: (item) => {
                    callback("name");
                }
            },
            {
                label: "Created At",
                value: "created_At",
                onClick: (item) => {
                    callback("created_At");
                }
            },
            {
                label: "Updated At",
                value: "updated_at",
                onClick: (item) => {
                    callback("updated_at");
                }
            },
            {
                label: "Type",
                value: "type",
                onClick: (item) => {
                    callback("type");
                }
            },


        ]
    }
}

export default sortView;