import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchPaymentMethods = async (params) => {
    let url = constants.APIURL.GET_PAYMENT_METHODS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

const searchPaymentMethods = async (params) => {
    let url = constants.APIURL.GET_PAYMENT_METHODS + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response?.data;
};

const deletePaymentMethod = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_PAYMENT_METHOD + payload, {withCredentials: true});
    return response;
};

const createPaymentMethod = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_PAYMENT_METHOD, payload, {withCredentials: true});
    return response;
};

const updatePaymentMethod = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_PAYMENT_METHOD + id, payload, {withCredentials: true});
    return response;
};

const bulkDeletePaymentMethods = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_PAYMENT_METHODS, {
        withCredentials: true,
        data: payload
    });
    return response;
};

const bulkRestorePaymentMethods = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_PAYMENT_METHODS, payload, {withCredentials: true});
    return response;
};

const bulkUpdatePaymentMethodsStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_BULK_PAYMENT_METHODS_STATUS, payload, {withCredentials: true});
    return response;
};

const fetchPaymentMethodsFilters = async (params) => {
    let url = constants.APIURL.GET_PAYMENT_METHODS_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response?.data;
};

export default {
    fetchPaymentMethods,
    deletePaymentMethod,
    createPaymentMethod,
    updatePaymentMethod,
    searchPaymentMethods,
    bulkDeletePaymentMethods,
    bulkRestorePaymentMethods,
    bulkUpdatePaymentMethodsStatus,
    fetchPaymentMethodsFilters
};
