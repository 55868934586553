import { createSlice } from "@reduxjs/toolkit";
import {
  addUser,
  fetchAudits,
  fetchUserActivities,
  fetchUsers,
  fetchUsersFilters,
  fetchUsersPermissions,
  updateUser,
} from "./thunk";

const initialState = {
  users: [],
  links: {},
  filters: {
    department_filter: [],
    position_filter: [],
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  loading: "idle",
  error: null,
  isLogin: false,
  status: null,
  audits: [],
  activities: [],
  activityStatus: "idle",
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchUser: (state, action) => {
      return { ...state, channels: action?.payload };
    },
    addUser: (state, action) => {
      // console.log('State', state);
      return { ...state, channels: action?.payload };
    },
    updateUser: (state, action) => {
      return { ...state, channels: action?.payload };
    },
    updateFilter: (state, action) => {
      // console.log('Update State', action);
      return {
        ...state,
        filters: {
          ...action?.payload,
        },
      };
    },
    getMetaFilter: (state, action) => {
      return {
        ...state,
        filtersMeta: action?.payload,
      };
    },
    // // update user status (active/inactive) when given user id
    // updateUserStatus: (state, action) => {
    //   const { status, id } = action?.payload;
    //   console.log(status, id);
    //   // update given users ids and status

    //   return {
    //     ...state,
    //     users: updatedUsers
    //   }
    // //  return {
    // //     ...state,
    // //     users: state.users.map((user) => {
    // //       if (user.id === id) {
    // //         return {
    // //           ...user,
    // //           status: status
    // //         }
    // //       }
    // //       return user;
    // //     })
    // //  }
    // },
    // update the users status (active/inactive) when given user ids
    updateUserStatus: (state, action) => {
      const { status, ids } = action?.payload;
      // update given users ids and status
      const updatedUsers = state.users.map((user) => {
        if (ids.includes(user.id)) {
          return {
            ...user,
            status: status,
          };
        }
        return user;
      });
      return {
        ...state,
        users: updatedUsers,
      };
    },
    // remove users when given user ids
    removeUsers: (state, action) => {
      const ids = action?.payload;
      // remove given users ids
      const updatedUsers = state.users.filter((user) => {
        return !ids.includes(user.id);
      });
      return {
        ...state,
        users: updatedUsers,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.users = action?.payload["data"];
          state.links = action?.payload["links"];
          state.meta = action?.payload["meta"];
        }
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchUsersFilters.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.filtersMeta = action?.payload?.data;
      })

      .addCase(fetchUsersPermissions.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchUsersPermissions.fulfilled, (state, action) => {
        state.uersPermissions = action.payload;
        // console.log(action?.payload, 'thunk now');
        state.status = "idle";
      })
      .addCase(fetchAudits.fulfilled, (state, action) => {
        state.audits = action.payload;
        // console.log(action?.payload, 'thunk now');
        state.status = "idle";
      })
      .addCase(fetchUserActivities.pending, (state, action) => {
        state.activityStatus = "pending";
      })
      .addCase(fetchUserActivities.fulfilled, (state, action) => {
        state.activityStatus = "idle";
        state.activities = action.payload;
      });
  },
});

export const {
  fetchChannel,
  updateFilter,
  extraReducers,
  getMetaFilter,
  updateUserStatus,
  removeUsers,
  updateBoundFilter,
} = usersSlice.actions;

export {
  fetchUsers,
  fetchUsersFilters,
  addUser,
  updateUser,
  fetchUsersPermissions,
  fetchAudits,
  fetchUserActivities,
};

export default usersSlice.reducer;
