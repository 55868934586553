import MainModal from "../MainModal";
import ReactDOM from 'react-dom';
import Pdf from "../../dynamic-page/elements/Pdf";

const pdfViewer = (pdfUrl, isPrint, title) => {

    // -----------------------------------------------------
    // create container

    let renderViewDom = null;
    let renderPrintDom = null;

    const pdfViewContainer = document.getElementById('pdf-view-container');
    const pdfPrintContainer = document.getElementById('pdf-print-container');

    if (!isPrint) {
        if (!pdfViewContainer) {
            const newPdfViewContainer = document.createElement('div');
            newPdfViewContainer.setAttribute('id', 'pdf-view-container');
            document.body.appendChild(newPdfViewContainer);
            renderViewDom = newPdfViewContainer;
        } else {
            renderViewDom = pdfViewContainer;
        }
    } else {
        if (!pdfPrintContainer) {
            const newPdfPrintContainer = document.createElement('div');
            newPdfPrintContainer.setAttribute('id', 'pdf-print-container');
            document.body.appendChild(newPdfPrintContainer);
            renderPrintDom = newPdfPrintContainer;
        } else {
            renderPrintDom = pdfPrintContainer;
        }
    }


    // -----------------------------------------------------
    // handle view modal
    const closeModal = () => {
        ReactDOM.unmountComponentAtNode(renderViewDom); // Clean the container
    };
    const ModalReturn = (
        <MainModal
            item={{
                title: title || "Pdf Viewer",
                cancel: closeModal,
                view: (item) => <Pdf url={pdfUrl}/>,
            }}
        />
    );

    // -----------------------------------------------------
    // handle print mode

    let PrintReturn = (
        <div>
            <iframe
                id="pdfViewerIframe"
                style={{
                    width: "0px",
                    height: "0px",
                    position: "absolute",
                    top: "0px",
                    zIndex: "999999",
                    display: "none"
                }}
                frameBorder="0"
                src={`/print/${encodeURIComponent(pdfUrl)}`}

            ></iframe>
        </div>
    );


    // -----------------------------------------------------


    if (isPrint) {
        ReactDOM.unmountComponentAtNode(renderPrintDom);
        ReactDOM.render(PrintReturn, renderPrintDom);
    } else {
        ReactDOM.unmountComponentAtNode(renderViewDom);
        ReactDOM.render(ModalReturn, renderViewDom);
    }
};

export default pdfViewer;
