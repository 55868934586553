import {useState} from "react"
import PropTypes from "prop-types"
import {HiEyeOff, HiEye} from "react-icons/hi"

const ArbitPasswordInput = ({label, value, placeholder, error, onChange, height}) => {
    const [passwordError, setPasswordError] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false)
    const onPasswordValidator = (value) => {
        if (value === null || value === undefined || value === "") return "This field must be filled."
        if (value.length < 8) return "Password must be 8 characters at least."
        return ""
    }

    return (
        <div>
            <div className="flex items-start mb-2">
                <label className="text-lg text-black font-bold">{label}</label>
            </div>
            <div className="relative flex items-center">
                <input
                    className={`w-full py-4 border border-gray-300 rounded-md pl-4 
                  bg-white focus:bg-white focus:ring-indigo-500 focus:ring-2 
                  focus:ring-offset-2 focus:ring-opacity-90 focus:outline-none h-${height}`}
                    type={
                        passwordVisible
                            ? "text"
                            : "password"
                    }
                    placeholder={placeholder}
                    defaultValue={value}
                    onChange={
                        (e) => {
                            onChange(e)
                            setPasswordError(onPasswordValidator(e.target.value))
                        }
                    }
                />
                <div
                    className="absolute right-[16px] text-[20px] cursor-pointer"
                    onClick={
                        () => setPasswordVisible(!passwordVisible)
                    }
                >
                    {
                        passwordVisible
                            ? <HiEye/>
                            : <HiEyeOff/>
                    }
                </div>
            </div>
            <div className="flex items-start ml-2 text-[12px] text-red-400">
                {
                    error ? error : passwordError
                }
            </div>
        </div>
    )
}

ArbitPasswordInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func
}

ArbitPasswordInput.defaultProps = {
    label: "Password",
    value: null,
    placeholder: "",
    error: null,
    onChange: () => {
    }
}

export default ArbitPasswordInput;
