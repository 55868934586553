const Box = ({children, title, options, className, innerClassName}) => {
    return (
        <div
            className={`D_BoxContainer flex flex-col  min-h-full h-[100%] rounded-b-[8px] !justify-start overflow-hidden ${className ? className : ""}`}
            style={{
                boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05)",
                border: "1px solid #E5E7EB",
                borderRadius: "8px"
            }}>
            {(title || options) &&
                <div className={"D_BoxHeader px-[16px] py-[12px] flex flex-row justify-between items-center"}>
                    <span className={"font-bold text-[20px] text-gray-900"}>{title}</span>
                    <span>{options && options}</span>
                </div>}
            <div
                className={`D_BoxContent rounded-b-[8px] overflow-auto h-full ${innerClassName ? innerClassName : ""}`}>{children}</div>
        </div>
    )
}
export default Box