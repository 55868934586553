function quantityHandler(num, unit) {
    if (num === null || num === undefined) {
        return '';
    }

    if (num === 0) {
        return '0';
    }

    if (typeof num === "string") {
        num = num.replace(/,/g, '');
        num = parseFloat(num);
    }

    const formattedNumber = num.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: num % 1 === 0 ? 0 : 2,
    });

    if (unit && unit.code) {
        return formattedNumber + ' ' + unit.code;
    } else {
        return formattedNumber;
    }
}

export default quantityHandler;
