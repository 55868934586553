import upperCaseString from "../../dynamic-page/util/upperCaseString";
import { useSelector } from "react-redux";

const FiltersView = (selectedFilers) => {
  const filtersMeta = useSelector((state) => state?.shipments?.filtersMeta);
  return [
    {
      name: "current_status_id",
      label: "Shipment Status",
      placeholder: "Filter by status",
      type: "Checkbox",
      list: filtersMeta?.shipment_statuses?.map((item) => ({
        label: upperCaseString(item?.name),
        value: item?.id,
        count: item?.count,
      })),
      defaultValue: selectedFilers?.["current_status_id"],
      toggle: true,
      clear: true,
      features: {
        isMulti: true,
      },
      checkAll: true,
    },

    {
      name: "internationalCarrier.name",
      label: "Carrier Name",
      placeholder: "Filter by carrier name",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilers?.["internationalCarrier.name"],
    },
    {
      name: "internationalCarrier.id",
      label: "Carrier ID",
      placeholder: "Filter by carrier id",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilers?.["internationalCarrier.id"],
    },
    {
      name: "employeess.name",
      label: "Assigned Employee",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          avatar: item?.image_url,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.["employees.name"],
      toggle: true,
      clear: true,
    },
    {
      name: "billable_weight",
      label: "Billable Weight",
      placeholder: "Filter by billable weight",
      type: "Range",
      defaultValue: selectedFilers?.billable_weight,
      toggle: true,
      clear: true,
    },
    {
      name: "order_actual_weight_fbm",
      label: "Actual Weight",
      placeholder: "Filter by actual weight",
      type: "Range",
      defaultValue: selectedFilers?.order_actual_weight_fbm,
      toggle: true,
      clear: true,
    },
    {
      name: "order_dimensional_weight_fbm",
      label: "Dim Weight",
      placeholder: "Filter by dim weight",
      type: "Range",
      defaultValue: selectedFilers?.order_dimensional_weight_fbm,
      toggle: true,
      clear: true,
    },
    {
      name: "tracking_number",
      label: "Shipment Tracking Number",
      placeholder: "Filter by shipment tracking number",
      type: "Input",
      defaultValue: selectedFilers?.tracking_number,
      toggle: true,
      clear: true,
    },
  ];
};

export default FiltersView;
