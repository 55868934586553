const urlQueryLoader = (initialObject, removeableFilters = []) => {
  const urlParams = new URLSearchParams(window.location.search);
  const updatedObject = { ...initialObject };
  urlParams.forEach((value, key) => {
    const isRemovableFilter = removeableFilters.includes(key);
    //if (value !== '' && value !== null && value !== undefined) {
    if (key.startsWith("filter[") && key.endsWith("]") && !isRemovableFilter) {
      const filterKey = key.slice(7, -1); // Extract the key from "filter[key]"
      const parsedValue = filterKey.includes("number")
        ? value
        : parseParamValue(value);
      if (
        parsedValue !== "" &&
        parsedValue !== null &&
        parsedValue !== undefined
      ) {
        updatedObject.filters = {
          ...(updatedObject.filters || {}),
          [filterKey]: parsedValue,
        };
      }
    } else if (key === "term") {
      updatedObject.search = value;
    } else if (key === "sort") {
      updatedObject.sort = value;
    } else if (key === "limit") {
      updatedObject.limit = value;
    } else if (key === "tab") {
      updatedObject.tab = value;
    } else if (key === "/search?search") {
      updatedObject.search = value;
    } else if (key === "page") {
    } else {
      const parsedValue = parseParamValue(value);
      if (
        parsedValue !== "" &&
        parsedValue !== null &&
        parsedValue !== undefined
      ) {
        updatedObject[key] = parsedValue;
      }
    }
    //}
  });
  return updatedObject;
};

const parseParamValue = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export default urlQueryLoader;
