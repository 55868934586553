import React from "react";
import Components from "./components";
import quantityHandler from "../../utils/noDecimalHandler";
import priceHandler from "../../utils/priceHandler"
import {useDispatch, useSelector} from "react-redux";
import {
    fetchDashboardStatuses,
    fetchOffersStatusesFilters as fetchOffersData,
    fetchOrdersStatusesFilters as fetchOrderData,
    fetchSalesStatusesFilters as fetchSalesData,
    fetchShipmentsStatusesFilters as fetchShipmentsData,
    fetchTargetStatusesFilters as fetchTargetData
} from '../../store/dashboard';
import { updateCurrentPage } from "../../store/product";

const toggleBodyScroll = (enableScroll) => {
    const body = document.body;
    body.style.overflow = enableScroll ? "hidden" : "auto";
};

const Dashboard = () => {
    
    const dispatch = useDispatch();

    React.useEffect(() => {
        // Disable body scroll when the component mounts
        toggleBodyScroll(false);
        dispatch(updateCurrentPage(""))
        dispatch(fetchOrderData());
        dispatch(fetchDashboardStatuses());
        dispatch(fetchShipmentsData());
        dispatch(fetchSalesData());
        dispatch(fetchOffersData());
        dispatch(fetchTargetData());
    }, [dispatch]);


    // Get data from store.
    const orders_statuses = useSelector((state) => state.dashboard.statuses) ?? [];
    const dashboard_statuses = useSelector((state) => state.dashboard.dashboardStatuses) ?? {}
    const shipment_statuses = useSelector((state) => state.dashboard.shipment_statuses) ?? [];
    const offers_statuses = useSelector((state) => state.dashboard.offers_statuses) ?? []
    const sales_statuses = useSelector((state) => state.dashboard.sales_statuses) ?? [];
    const target_statuses = useSelector((state) => state.dashboard.target_statuses) ?? [];
    // const loading = useSelector((state) => state.dashboard.loading);
    // Order Status
    const allOrdersStatuses = [...orders_statuses, {id: 0, count: dashboard_statuses?.orders?.cancel_requested}];

    const renderedOrdersStatuses = allOrdersStatuses.filter(item => [14, 3, 2, 0].includes(item.id)).map((item, index) => (
        <Components.ordersStatBoxes key={index} statusId={item.id} count={quantityHandler(item.count)}/>
    ));

    // Supply Status
    const allSupplyStatuses = [{
        id: 2,
        count: dashboard_statuses?.supply?.awaiting_supply
    }, {
        id: 3,
        count: dashboard_statuses?.supply?.awaiting_purchase
    }, {id: 0, count: dashboard_statuses?.supply?.late_acceptance}];

    const renderedSupplyStatuses = allSupplyStatuses.filter(item => [3, 2, 0].includes(item.id)).map((item, index) => (
        <Components.supplyStatBoxes key={index} statusId={item.id} count={quantityHandler(item.count)}/>
    ));

    // Shipment Status
    const allShipStatuses = [...shipment_statuses, {id: 0, count: 0}];

    const filteredShipStatuses = allShipStatuses.filter(item => [4, 3, 5].includes(item.id));

    const sortedShipStatuses = filteredShipStatuses.sort((a, b) => {
        const order = [4, 3, 5];
        return order.indexOf(a.id) - order.indexOf(b.id);
    });

    const renderedShipStatuses = sortedShipStatuses.map((item, index) => (
        <Components.shipStatBoxes key={index} statusId={item.id} count={quantityHandler(item.count)}/>
    ));

    // Fulfilment Status
    const allFullStatuses = [...offers_statuses, {id: 0, count: 0}];

    const filteredFullStatuses = allFullStatuses.filter(item => [1, 2, 3, 4].includes(item.id));

    const sortedFullStatuses = filteredFullStatuses.sort((a, b) => {
        const order = [1, 2, 3, 4];
        return order.indexOf(a.id) - order.indexOf(b.id);
    });

    const renderedFullStatuses = sortedFullStatuses.map((item, index) => {
        let countText = item.id === 2 ? `$ ${priceHandler(item.count)}` : quantityHandler(item.count);
        return <Components.fullStatBoxes key={index} statusId={item.id} count={countText}/>;
    });

    //Sales Channel Listings Status
    const allSalesStatuses = [...sales_statuses, {id: 0, count: 0}];

    const renderedSalesStatuses = allSalesStatuses.filter(item => [1].includes(item.id)).map((item, index) => (
        <Components.salesStatBoxes key={index} statusId={item.id} count={quantityHandler(item.count)}/>
    ));

    // Target Status
    const allTargetStatuses = [...target_statuses, {id: 0, count: 0}];

    const filteredTargetStatuses = allTargetStatuses.filter(item => [1, 3, 2].includes(item.id));

    const sortedTargetStatuses = filteredTargetStatuses.sort((a, b) => {
        const order = [1, 3, 2];
        return order.indexOf(a.id) - order.indexOf(b.id);
    });

    const renderedTargetStatuses = sortedTargetStatuses.map((item, index) => (
        <Components.targetStatBoxes key={index} statusId={item.id} count={quantityHandler(item.count)}/>
    ));


    return (
        <>
            <div className="flex flex-col justify-between items-start p-3 mt-2">
                <div className="flex flex-col justify-between items-start w-full mb-4">
                    <Components.Heading text="Orders" fontSize="24px" padding="0 14px"/>
                    <div className="grid grid-cols-4 gap-x-3 w-full mt-3">
                        {renderedOrdersStatuses}
                    </div>
                </div>
                <div className="flex flex-col justify-between items-start w-full mb-4">
                    <Components.Heading text="Supply" fontSize="24px" padding="0 14px"/>
                    <div className="grid grid-cols-3 gap-x-3 w-full mt-3">
                        {renderedSupplyStatuses}
                    </div>
                </div>
                <div className="flex flex-col justify-between items-start w-full mb-4">
                    <Components.Heading text="Shipments" fontSize="24px" padding="0 14px"/>
                    <div className="grid grid-cols-3 gap-x-3 w-full mt-3">
                        {renderedShipStatuses}
                    </div>
                </div>
                <div className="flex flex-col justify-between items-start w-full mb-4">
                    <Components.Heading text="Fulfillment Center" fontSize="24px" padding="0 14px"/>
                    <div className="grid grid-cols-4 gap-x-3 w-full mt-3">
                        {renderedFullStatuses}
                    </div>
                </div>
                <div className="flex flex-col justify-between items-start w-full mb-4">
                    <Components.Heading text="Sales Channel Listings" fontSize="24px" padding="0 14px"/>
                    <div className="grid grid-cols-1 gap-x-3 w-full mt-3">
                        {renderedSalesStatuses}
                    </div>
                </div>
                <div className="flex flex-col justify-between items-start w-full mb-4">
                    <Components.Heading text="Target Sellers" fontSize="24px" padding="0 14px"/>
                    <div className="grid grid-cols-3 gap-x-3 w-full mt-3">
                        {renderedTargetStatuses}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;