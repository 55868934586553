// locale/thunk.js
// ----------------------------------------------------------------

import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

// Create the fetchAndSetLocale async thunk.
export const fetchAndSetLocale = createAsyncThunk(
    "get:api/v1/locale/:lng}",
    async (lng, thunkAPI) => {
        const response = await api.fetchLocale(lng);
        return response.data;

        // --> This code attempts to check if the locale is already loaded.
        // --> But it has been commented out because our keys may be updated during development.
        /*
        //const state = thunkAPI.getState();
        //const localeState = state.locale.list[lng];
        
        // Check if the locale is already loaded.
        if (localeState && localeState?.loading === "idle") {
            // If the locale is "ALREADY LOADED", return it.
            const response = await api.fetchLocale(lng);
            return response.data;
            
        } else {
            // If the locale is "NOT LOADED", fetch it.
            await thunkAPI.dispatch(fetchLocale(lng)).unwrap();
            const state = thunkAPI.getState();
            const localeState = state.locale.list[lng];
            return localeState.data;
        }*/
    }
);

export default {fetchAndSetLocale};
