function filterObjectByKeys(obj, keys) {
    return keys.reduce((filteredObj, key) => {
        const [oldKey, newKey] = key.split(":");
        const nestedKeys = oldKey.split(".");
        let nestedObj = obj;

        for (let i = 0; i < nestedKeys.length; i++) {
            const nestedKey = nestedKeys[i];
            if (nestedObj && nestedObj.hasOwnProperty(nestedKey)) {
                nestedObj = nestedObj[nestedKey];
            } else {
                nestedObj = undefined;
                break;
            }
        }

        if (nestedObj !== undefined && nestedObj !== null && nestedObj !== "") {
            filteredObj[newKey || oldKey] = nestedObj;
        } else {
            filteredObj[newKey || oldKey] = '';
        }

        return filteredObj;
    }, {});
}

export default filterObjectByKeys;
