import React, { useEffect, useRef, useState } from "react";
import util from "./index";
import Icons from "../../orders/icons";
import Util from "./index";

function ExpandableText({
  callBack,
  children,
  forceTooltip,
  maxLines,
  className,
  tooltip = false,
  tooltipClassName = "!max-w-[450px]",
  forcePosition = "bottom",
  action = "hover",
  delay = 500,
  hideCopy,
  productCopyIcon,
  propagation,
  innerClassName,
  iconClass
}) {
  const [expanded, setExpanded] = useState(false);
  const [numLines, setNumLines] = useState(0);
  const [isThreeDotClicked, setThreeDotClicked] = useState(false);
  const ref = useRef();
  const innerRef = useRef();

  useEffect(() => {
    const lineHeight = innerRef?.current
      ? window.getComputedStyle(innerRef.current).lineHeight
      : 0;
    const height = innerRef?.current ? innerRef.current.clientHeight : 0;
    const numLines = Math.round(height / parseFloat(lineHeight));
    setNumLines({
      lineHeight: lineHeight,
      height: height,
      numLines: numLines,
    });
  }, [children]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (!tooltip) {
      ref.current.classList.add("importantHeight");
      if (
        parseInt(numLines.height) >
        parseInt(numLines.lineHeight) * parseInt(maxLines)
      ) {
        setExpanded(!expanded);
      }
    }
  };

  const IsTooltip = ({ children, text }) => (
    <>
      {(tooltip && isItMultiLine) || forceTooltip  ? (
        <util.popUp
          toggle={(selected) => <>{children}</>}
          forcePosition={forcePosition}
          className={tooltipClassName}
          action={action}
          delay={delay}
        >
          <p className="whitespace-normal  ">{text}</p>
        </util.popUp>
      ) : (
        <>{children}</>
      )}
    </>
  );

  const isItMultiLine =
    parseInt(numLines.height) >
    parseInt(numLines.lineHeight) * parseInt(maxLines);
  return (
    <div className={"relative group w-[100%]"}>
      <style>
        {`
                    .expandableText {
                        overflow: hidden;
                        transition: max-height 0.0s ease-out;
                    }
                    
                    .expandableText.expanded {
                        max-height: 1000px;
                    }
                    
                    .expandableText.collapsed {
                        max-height: ${
                          parseInt(numLines.lineHeight) * parseInt(maxLines)
                        }px;
                    }
                    .expandableText.tooltip {
                        max-height: ${
                          parseInt(numLines.lineHeight) * parseInt(maxLines)
                        }px;
                    }
              `}
      </style>

      {children && (
        <IsTooltip text={children}>
          {/*<p style={{
                        whiteSpace: expanded ? "normal" : 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%'
                    }}
                        onClick={()=> {
                            setExpanded(!expanded);
                        }}
                    >
                        {children}
                    </p>*/}
          <div className=" flex items-center gap-1.5 justify-between">
            <p
              ref={ref}
              className={`!overflow-hidden  whitespace-pre-wrap  expandableText ${
                expanded ? "expanded" : "collapsed"
              } ${className} ${tooltip ? "tooltip" : ""}`}
              onClick={callBack ? callBack : toggleExpanded}
            >
              <p
                className={`
                        ${innerClassName && innerClassName}`}
                ref={innerRef}
              >
                {children}
              </p>
            </p>

            {!hideCopy && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Util.copyText
                  className={"text-[9px]"}
                  text={children}
                  hint={false}
                  iconClass={iconClass}
                  productCopyIcon={true}
                />
              </span>
            )}
          </div>
        </IsTooltip>
      )}
    </div>
  );
}

const CopyIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 20 20"
    class="!w-4 !h-4 "
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
    <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
  </svg>
);

export default React.memo(ExpandableText);
