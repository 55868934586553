import upperCaseString from "../../dynamic-page/util/upperCaseString";
import constants from "../../../constant";

const filtersView = (selectedFilters, filtersMeta) => {

    return (

        [
            {
                name: "channel",
                label: "Channel",
                placeholder: "Filter by Channel",
                type: "Select",
                api: {
                    url: "/api/v1/channel",
                    query: "?term={query}",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: item?.name,
                        logo: item?.logo,
                    }),
                },
                features: {
                    isMulti: true,
                    formatOptionLabel: ({label, logo}) => (
                        <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                      <span
                          className={"w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "}
                      >
                        <img src={logo || "/assets/images/img-placehoder.png"}/>
                      </span>
                            <span>{upperCaseString(label)}</span>
                        </div>
                    ),
                },
                defaultValue: selectedFilters?.channel,
                toggle: true,
                clear: true,
            },

            {
                name: "category",
                label: "Category",
                placeholder: "Select Category",
                type: "Select",
                api: {
                    url: constants.APIURL.GET_CATEGORIES,
                    query: "?filter[name]={query}",
                    optionValue: (item) => ({
                        value: item.id,
                        label: item.name,
                    }),
                },
                features: {
                    formatOptionLabel: ({label, count}) => {
                        return (
                            <div className={"flex flex-row gap-[8px] items-center justify-between min-h-[25px]"}>
                                <span>{upperCaseString(label)}</span>
                                <span
                                    className={"min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "}>
                          {count}
                        </span>
                            </div>
                        );
                    },
                },
                isMulti: true,
                defaultValue: selectedFilters?.category || null,
                toggle: true,
                clear: true,
            },

            {
                name: "referral_fee",
                label: "Referral Fee",
                placeholder: "Filter by Referral Fee",
                type: "Range",
                defaultValue: selectedFilters?.referral_fee,
                toggle: true,
                clear: true,
            },
            {
                name: "min_fee",
                label: "Min Fee",
                placeholder: "Filter by Min Fee",
                type: "Range",
                defaultValue: selectedFilters?.min_fee,
                toggle: true,
                clear: true,
            },
            {
                name: "treshold",
                label: "Treshold",
                placeholder: "Filter by Treshold",
                type: "Range",
                defaultValue: selectedFilters?.treshold,
                toggle: true,
                clear: true,
            },
            {
                name: "over_treshold",
                label: "Over Treshold",
                placeholder: "Filter by Over Treshold",
                type: "Range",
                defaultValue: selectedFilters?.over_treshold,
                toggle: true,
                clear: true,
            },

        ])
}

export default filtersView;