import React from "react";
import util from "../../dynamic-page/util";

const Item = ({product, orderId, offer, openListingModal}) => {
    return (
        <div className={"w-[227px] gap-[0px] flex flex-col"}>
            <div className={"flex flex-row whitespace-pre-wrap gap-[8px] justify-between"}>
                <util.truncateText
                    maxLines={1}
                    className={"text-[12px]"}
                    tooltip={true}
                >
                    {offer?.title}
                </util.truncateText>
                {/* {multiple &&
                    <span className={"text-pink-800 font-semibold min-w-max"}>+{(product.length)-1} Items more</span>
                } */}
            </div>
            <div className={"flex justify-between"}>
                {/* {multiple?
                    <div className={"text-pink-600 flex flex-row gap-[4px] items-center"}>
                        <Icons.collection className={"h-[12px]"}/> Multiple SKU
                    </div>
                    : */}
                <div className={"flex flex-row gap-[4px] text-gray-600"}>
                    <util.copyText
                        className={"text-[12px]"}
                        text={offer?.sku}
                        hint={false}
                        iconClass='!w-3 !h-3 ml-1'
                    >
                        {util.shortenString(offer?.sku, 30)}
                    </util.copyText>
                </div>
            </div>
        </div>
    );
};

export default Item;
