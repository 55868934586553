const FiltersView = (selectedFilers) => {
    return [
        {
            name: "name",
            label: "Name",
            placeholder: "Filter by Name",
            type: "Input",
            defaultValue: selectedFilers?.name || null,
            toggle: true,
            clear: true,
        },
        {
            name: "code",
            label: "Code",
            placeholder: "Filter by Code",
            type: "Input",
            defaultValue: selectedFilers?.code || null,
            toggle: true,
            clear: true,
        },
        {
            name: "symbol",
            label: "Symbol",
            placeholder: "Filter by Symbol",
            type: "Input",
            defaultValue: selectedFilers?.symbol || null,
            toggle: true,
            clear: true,
        },
    ];
};

export default FiltersView;
