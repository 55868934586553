 import { useState } from "react";
import Icons from "../../orders/icons";
import Util from "../../dynamic-page/util";
import { useForm } from "react-hook-form";
import ProductDeleteModal from "../../product/Product-delete-modal";
const DirtySelect = ({
  isValue,
  id,
  texts,
  type,
  tooltipPosition,
  placeholder,
  attributeId,
  className,
  titleClassName,
  textArea,
  title,
  handleValueClicked,
  titleDesc,
}) => {
  const hasValue = isValue;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitting, touchedFields, submitCount },
  } = useForm({
    defaultValues: {
      value: hasValue ? (hasValue?.value ? hasValue?.value : hasValue) : "",
    },
    mode: "onChange",
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const onSubmit = async (data) => {
    await handleValueClicked({
      reset,
      attributeId,
      value: data?.value,
      setDeleteModal,
    });
  };
  return (
    <div
      className={`flex flex-col gap-2 rounded-[8px] ${className && className} `}
    >
      <form
        onSubmit={
          watch("value")
            ? handleSubmit(onSubmit)
            : (e) => {
                e.preventDefault();
                setDeleteModal(true);
              }
        }
        className="flex justify-between items-center"
      >
        <div className="flex gap-1 items-center">
          <label
            className={`text-[14px] leading-[21px] font-medium text-gray-900 ${
              titleClassName && titleClassName
            }`}
          >
            {title}
            {titleDesc && (
              <span className="font-semibold text-gray-500 text-[12px] ml-1 leading-normal">
                {titleDesc}
              </span>
            )}
          </label>
          {texts && (
            <Util.popUp
              toggle={() => (
                <Icons.info className="w-5 h-5 text-gray-300 hover:text-gray-500" />
              )}
              action="hover"
              forcePosition={tooltipPosition}
            >
              <div className="flex flex-col gap-1.5 p-1.5 px-2 w-[297px]">
                <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                  {texts?.tooltipTitle}
                </span>
                <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                  {texts?.tooltipDesc}
                </span>
              </div>
            </Util.popUp>
          )}
        </div>
        {isDirty && (
          <button
            type="submit"
            className="text-blue-700 hover:text-blue-500 text-[14px] leading-normal font-medium"
          >
            <Icons.chechkCircle className="w-5 h-5" />
          </button>
        )}
      </form>
      <div
        className={`flex justify-between ${
          isFocused ? "border-blue-600" : "border-gray-300"
        } bg-gray-50 border rounded-[8px] pr-4 ${!textArea && "items-center"}`}
      >
        {!textArea ? (
          <input
            className="bg-gray-50  rounded-lg w-full border-none [&::-webkit-inner-spin-button]:appearance-none"
            placeholder={placeholder && placeholder}
            type={type ? type : "text"}
            {...register("value")}
            onFocus={handleFocus}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
            onBlur={handleBlur}
          />
        ) : (
          <textarea
            {...register("value")}
            className="border w-full resize-none border-none h-[96px] bg-gray-50 rounded-[8px] px-4 py-2"
            placeholder={placeholder && placeholder}
            onFocus={handleFocus}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
            onBlur={handleBlur}
          />
        )}
        {watch("value") && (
          <Icons.close
            onClick={() => setValue("value", "", { shouldDirty: true })}
            className={`w-[18px] h-[18px] ${
              textArea && "mt-2"
            } cursor-pointer text-gray-500`}
          />
        )}
      </div>
      {deleteModal && (
        <ProductDeleteModal
          name={"the attribute"}
          show={isDirty}
          onClose={() => setDeleteModal(false)}
          handleProductDeletion={handleSubmit(onSubmit)}
        />
      )}
    </div>
  );
};
export default DirtySelect;
