const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Min. Fee",
                value: "min_fee",
                onClick: (item) => {
                    callback("min_fee");
                },
            },
            {
                label: "Treshold",
                value: "treshold",
                onClick: (item) => {
                    callback("treshold");
                },
            },
            {
                label: "OVER TRESHOLD",
                value: "over_treshold",
                onClick: (item) => {
                    callback("over_treshold");
                },
            },
            // add more here for referral_fee category and channel_id

            {
                label: "Referral Fee",
                value: "referral_fee",
                onClick: (item) => {
                    callback("referral_fee");
                },
            },

            {
                label: "Category",
                value: "category",
                onClick: (item) => {
                    callback("category");
                },
            },

            {
                label: "Channel",
                value: "channel_id",
                onClick: (item) => {
                    callback("channel_id");
                },
            },
        ],
    };
};

export default sortView;
