import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchCarrier = async (params) => {
    let url = constants.APIURL.GET_CARRIERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const searchCarrier = async (params) => {
    let url = constants.APIURL.GET_CARRIERS + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteCarrier = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_CARRIER + payload, {withCredentials: true});
    return response?.data
};

const createCarrier = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_CARRIER, payload, {withCredentials: true});
    return response;
};

const updateCarrier = async (id, payload) => {
    let url = constants.APIURL.PUT_UPDATE_CARRIER + id;
    const response = await axios.post(url, payload, {withCredentials: true});
    return response;
};

const fetchCarriersFilters = async (params) => {
    let url = constants.APIURL.GET_CARRIERS_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const bulkDeleteCarriers = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_CARRIERS, {withCredentials: true, data: payload});
    return response?.data
};

const bulkStatusUpdateCarriers = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_CARRIERS_STATUS, payload, {withCredentials: true});
    return response?.data
};

const restoreCarriers = async (payload) => {
    const response = await axios.patch(constants.APIURL.POST_RESTORE_CARRIERS, payload, {withCredentials: true});
    return response?.data
};

export default {
    fetchCarrier,
    deleteCarrier,
    createCarrier,
    updateCarrier,
    searchCarrier,
    fetchCarriersFilters,
    bulkDeleteCarriers,
    bulkStatusUpdateCarriers,
    restoreCarriers
};
