import React from "react";
import moment from "moment";
import {howLongFromNow} from "../../../utils/helper";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import priceHandler from "../../../utils/priceHandler";
import removeDecimals from "../../../utils/removeDecimals";
import Components from "../components";
import OrderRowAction from "../components/rowActions";
import util from "../../dynamic-page/util";

const TableView = (list) => {
    const nullHandle = (data, chained) => {
        if (!data || !chained) {
            return "-";
        } else {
            return chained;
        }
    };
    const quantityTooltipList = (item) => {
        if (!item) {
            return [];
        }
        return [
            {
                title: "Available",
                value:
                    item?.available_quantity != null ? item?.available_quantity : "-",
            },
            {
                title: "Reserved",
                value: item?.reserved_quantity != null ? item?.reserved_quantity : "-",
            },
            {
                title: "Inbound",
                value: item?.inbound_quantity != null ? item?.inbound_quantity : "-",
            },
            {
                title: "Unfulfillable",
                value:
                    item?.unfulfillable_quantity != null
                        ? item?.unfulfillable_quantity
                        : "-",
            },
        ];
    };

    const agedCostTooltip = (item) => {
        if (!item) {
            return [];
        }

        let result = [];

        for (let key in item) {
            let title = key
                .replace("estimated_ais_", "")
                .replace("_", " - ")
                .replace("_days", "")
                .replace("- plus", "+");
            result.push({
                title: title,
                value: "$" + item[key],
            });
        }

        return result;
    };

    return {
        list: list,
        theme: {
            "control": (item) => (
                <div className={"w-[50px] h-[50px]"}>
                    <OrderRowAction actions={{
                        order_id: item?.id,
                        customer_note: item?.customer_note,
                        internal_notes: item?.internal_notes,
                        shipping_content: item?.shipping_content,
                        shipping_documents: item?.shipping_documents,

                        customer_note_enabled: item?.customer_note_enabled,
                        internal_notes_enabled: item?.internal_notes_enabled,
                        shipping_content_enabled: item?.shipping_content_enabled,
                        shipping_documents_enabled: item?.shipping_documents_enabled,
                    }}/>
                </div>
            ),
            "LAST UPDATE": (item) => (
                <util.dateDisplay item={item} date={item?.updated_at} />
            ),

            "channel & SKU": (item) => (
                <div className={"flex flex-col w-[215px] gap-2 !px-[8px] !py-[12px]"}>
                    <Components.sku
                        orderId={item.sku}
                        order={{
                            order_number: item?.sku,
                            country: item?.channel?.country,
                            account: item?.account?.name,
                            ...item?.channel,
                        }}
                    />
                </div>
            ),
            "f. type": (item) => (
                <div className="flex flex-col justify-start items-start gap-1">
                    <ArbitNormalBadge
                        title={item.fulfilment}
                        bgColor={
                            item.fulfilment === "FBM"
                                ? "blue-100"
                                : item.fulfilment === "FBA"
                                    ? "red-50"
                                    : "gray-100"
                        }
                        textColor={item.fulfilment === "FBM" ? "blue-800" : "red-500"}
                    />
                </div>
            ),
            "title": (item) => (
                <div
                    className={"w-full flex flex-row gap-[16px] justify-start"}
                >
                    <div
                        className={
                            "w-[65px] h-[64px] max-h-[64px] justify-center items-center flex"
                        }
                    >
                        {item?.image ? (
                            <ImageMagnifier src={item?.image} className={"!max-h-[64px]"}/>
                        ) : (
                            <img
                                src={"/assets/images/noImage.png"}
                                className={"h-[52px] w-[52px]"}
                                alt=""
                            />
                        )}
                    </div>
                    <Components.item
                        product={[item?.product]}
                        orderId={item?.id}
                        offer={item}
                    />
                </div>
            ),
            "price": (item) => (
                <Components.numbers data={item?.price} processFunction={priceHandler}
                                    currency={{symbol: '$', position: 1}} color={"text-green-700"}/>
            ),
            "T. Quantity": (item) => (
                <Components.numbers data={item?.quantity} processFunction={removeDecimals}
                                    list={item?.fulfilment === "FBA" ? quantityTooltipList(item?.fba_quantity_tooltip) : null}
                                    color={"text-purple-700"}/>
            ),
        },
    };
};
export default TableView;
