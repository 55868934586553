import upperCaseString from "../../dynamic-page/util/upperCaseString";
import UI from "../../dynamic-form/elements";
import { WarehouseTooltip } from "..";

const formView = (formDefaultValues, params) => {
  return [
    {
      label: "Point of Departure",
      name: "onWarehouse",
      type: "Select",
      api: {
        url: "/api/v1/warehouses?include=state,country",
        skipCancel: true,
        url2: "/api/v1/pickup-points?include=state,country",
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        optionValue: (item) => ({
          // value: item?.id,
          value: upperCaseString(item?.name, "word", true) + `id:${item?.id}`,
          label: upperCaseString(item?.name, "word", true),
        }),
        optionValue2: (item) => ({
          type: "pickup",
          value: upperCaseString(item?.name, "word", true) + `id:${item?.id}`,
          label: upperCaseString(item?.name, "word", true),
        }),
      },
      className: "h-[42px]",
      style: {
        control: {
          height: "42px !important",
          minHeight: "auto",
          width: "100% !important",
          background: "#F9FAFB",
          border: "1px solid #D1D5DB !important",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      reset: false,
      clear: false,
      defaultValue: formDefaultValues?.onWarehouse || null,
      placeholder: "Select the departure point",
    },
    {
      name: "country",
      label: "to Country", //+ (data?.country ? typeof data?.country === "object" ? data?.country?.id : data?.country : null),
      placeholder: "Select the country",
      type: "Select",
      api: {
        url: "api/v1/country?sort=name",
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          flag: item?.flag,
        }),
      },
      features: {
        formatOptionLabel: ({ label, flag }) => (
          <div
            className={"flex flex-row gap-[12px] items-center min-h-[25px] "}
          >
            {flag && (
              <span
                className={
                  "w-[28px] h-[20px] overflow-hidden flex flex-col justify-center items-center  "
                }
              >
                <img
                  src={flag.toLowerCase()}
                  className={"object-cover w-[28px] h-[20px] rounded-[2px]"}
                />
              </span>
            )}
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      style: {
        control: {
          height: "42px !important",
          minHeight: "auto",
          width: "100% !important",
          background: "#F9FAFB",
          border: "1px solid #D1D5DB !important",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      defaultValue: formDefaultValues?.country || null,
      reset: false,
      clear: true,
    },
    {
      name: "state",
      label: "to State ", // +  data?.state,//(data?.state ? typeof data?.state === "object" ? data?.state?.id : data?.state : null),
      placeholder: "Select the state",
      type: "Select",
      api: {
        url: "/api/v1/state?filter[country_id]={country}",
        query: "&term={query}",
        view: "/api/v1/state",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
        }),
      },
      defaultValue: formDefaultValues?.state || null, //4853,//1734,//data?.state || null,
      style: {
        control: {
          height: "42px !important",
          minHeight: "auto",
          width: "100% !important",
          background: "#F9FAFB",
          border: "1px solid #D1D5DB !important",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      reset: false,
      clear: true,
    },

    {
      name: "dim_divison",
      label: "DIM Divisior",
      placeholder: "DIM",
      type: "Select",
      list: [
        {
            label: "3000",
            value: "3000"
        },
        {
            label: "5000",
            value: "5000"
        },
        {
          label: "6000",
          value: "6000"
      },
    ],
      style: {
        control: {
          height: "40px !important",
          minHeight: "auto",
          width: "100% !important",
          background: "#F9FAFB",
          border: "1px solid #D1D5DB !important", // Adding the border property here
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      clear: false,
      reset: false,
      defaultValue: formDefaultValues?.dim_divison || 5000,
      labelIcon: WarehouseTooltip("Modal"),
    },
    {
      name: "calculate3",
      type: "Module",
      module: UI.LineCss,
      defaultValue: 2,
    },
    {
      name: "height",
      label: "Height",
      labelDiscription: "cm",
      placeholder: "cm",
      type: "Number",
      defaultValue: formDefaultValues?.height || "0",
      clear: false,
      reset: false,
      innerClassName: "bg-[#F9FAFB] border border-[#D1D5DB] !h-[40px]",
    },

    {
      name: "width",
      label: "Width",
      labelDiscription: "cm",
      placeholder: "cm",
      type: "Number",
      clear: false,
      reset: false,
      defaultValue: formDefaultValues?.width || "0",
      innerClassName: "bg-[#F9FAFB] border border-[#D1D5DB] !h-[40px]",
    },
    {
      name: "length",
      label: "Length",
      labelDiscription: "cm",
      placeholder: "cm",
      type: "Number",
      clear: false,
      reset: false,
      defaultValue: formDefaultValues?.length || "0",
      innerClassName:
        "bg-[#F9FAFB] border border-[#D1D5DB] !h-[40px] text-[14px] leading-[17.5px]  text-gray-900 ",
    },
    {
      name: "calculate",
      type: "Module",
      module: UI.LineCss,
      defaultValue: 1,
    },
    {
      name: "weight",
      label: `Weight`,
      labelDiscription: "kg",
      placeholder: "kg",
      type: "Number",
      clear: false,
      reset: false,
      style: {
        labelDiscription: {
          className: "text-blue-500 bg-red-500 justify-center",
        },
      },
      defaultValue: formDefaultValues?.weight || null,
      innerClassName: "bg-[#F9FAFB] border border-[#D1D5DB] !h-[40px] ",
    },
    {
      name: "calculate",
      type: "Module",
      module: UI.CalculateWeight,
      defaultValue: 1,
    },
    {
      name: "calculate2",
      type: "Module",
      module: UI.CalculatorDatas,
      defaultValue: 1,
    },
  ];
};
export default formView;
