import React, { useEffect, useRef } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import selectedItem from "./select-util/selectedItem";
import fetchOptions from "./select-util/fetchOptions";
import returnValue from "./select-util/returnValue";
import style from "./select-util/style";
import checkUrl from "./select-util/checkUrls";
import { addNewAction, addNewView } from "./select-util/addNew";
import debounce from "./select-util/debounce";

const SelectComponent = ({
  item,
  value,
  validationMessage,
  handleChange,
  bind,
  formData,
  ...rest
}) => {
  const SelectComponent = item?.addNew ? CreatableSelect : Select;

  const [loadedOptions, setLoadedOptions] = React.useState([]);
  const [innerValue, setInnerValue] = React.useState(
    item?.defaultBound
      ? {
          label: item?.defaultBound?.name,
          value: item?.defaultBound?.id,
        }
      : null
  );
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [innerFormData, setInnerFormData] = React.useState(formData);
  const [initial, setInitial] = React.useState(false);

  const selectRef = useRef(null);
  const valueRef = useRef(null);

  const loadOptions = debounce((inputValue, first) => {
    setInitial(true);
    if (item?.features?.isMulti && typeof value === "string" && value?.split(",").length > 1) {
      value = value.split(",");
    }
    if (item?.features?.isMulti && typeof item?.defaultValue === "string" && item?.defaultValue?.split(",").length > 1) {
      item.defaultValue = item.defaultValue.split(",");
    }
    if (item.api && (!loaded || inputValue) && !loading) {
      setLoading(true);
      fetchOptions(
        item.api,
        inputValue,
        first ? value : null,
        first ? item.defaultValue : null,
        item?.list,
        formData
      ).then((data) => {
        setLoading(false);
        setLoaded(true);
        if (data.length === 0 && !inputValue) {
          item?.notify &&
            item.notify(
              "No carrier found that can be activated for this provider",
              "info"
            );
        }
        setLoadedOptions([...(item?.api?.list || []), ...data]);
      });
    } else if (item.list && !item.api) {
      setLoadedOptions(item.list);
    }
  }, 500);

  const selectOption = () => {
    const selected = selectedItem(value, loadedOptions, innerValue, item?.features?.isMulti);
    if (bind) bind(selected, item.name);
    return selected;
  };

  useEffect(() => {
    const check = checkUrl(
      item.api,
      formData,
      innerFormData,
      value,
      loadedOptions,
      loading
    );
    setIsDisabled(check.disable);
    !loading && setInnerValue(selectOption());

    if (check.reset) {
      setLoaded(false);
    }
    if (check.related) {
      if (check.load) {
        loadOptions("", item?.defaultBound ? false : true);
      }
    } else {
      if (value && !item?.defaultBound) loadOptions("", true);
    }
    if (value === "") {
      selectRef.current.setValue(null);
    }
  }, [value, loadedOptions, loading]);

  useEffect(() => {
    if (loadedOptions.length === 0 && !item?.disablePreload && !item?.defaultValue) {
      loadOptions("", item?.defaultBound ? false : true);
    }
  }, []);

  useEffect(() => {
    const check = checkUrl(
      item.api,
      formData,
      innerFormData,
      value,
      loadedOptions,
      loading
    );
    if (check.reset) {
      setLoaded(false);
    }
    setInnerFormData(formData);
    setIsDisabled(check.disable);

    if (check.reset) {
      setLoadedOptions([]);
      setInnerValue(null);
      setTimeout(() => {
        handleChange(null, item.name, null);
      }, 100);
    }
  }, [formData]);

  // Custom filter function for react-select
  const customFilterOption = (option, inputValue) => {
    const normalizeString = (str) =>
      str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const label = normalizeString(typeof option.label === 'object' ? option.value  : option.label);
    const input = normalizeString(inputValue);
    if (item?.execludedValues && item?.execludedValues?.(formData)?.includes(option?.value)) {
      return false;
    }  
    return label.includes(input);
  };

  return (
    <div className={`${item.innerClassName || ""} dynamicForm`}>
      <style>
        {`
          .dynamicForm .css-1f43avz-a11yText-A11yText,
          .css-b62m3t-container {
              width: 100%;
          }
          .dynamicForm .css-1y6o3d0-menu {width:100%}
        `}
      </style>
      <SelectComponent
        ref={selectRef}
        onInputChange={(changeValue) => {
          loadOptions(changeValue, false);
        }}
        isLoading={loading}
        options={loadedOptions}
       filterOption={customFilterOption}  
        value={innerValue}
        formatCreateLabel={addNewView}
        onCreateOption={(newOption) => {
          addNewAction(newOption, item, formData, loadOptions);
        }}
        defaultValue={null}
        onChange={(newData) => {
          setInnerValue(newData);
          valueRef.current = newData;
          handleChange(returnValue(newData), item.name, newData);
          if (item?.instantSubmit) {
            handleChange(null, item.name, null);
          }
        }}
        className={`h-full !min-w-full block ${
          item.innerClassName2 || ""
        } !bg-transparent`}
        isClearable={false}
        isDisabled={
          isDisabled ||
          (item?.disabled && typeof item?.disabled === "function"
            ? item?.disabled(formData)
            : item?.disabled)
        }
        placeholder={item.placeholder}
        menuPlacement={item.menuPlacement || "auto"}
        onMenuOpen={() => {
          // Force reload options when menu opens
          loadOptions("", true);

          if (!item?.features?.isMulti) {
            valueRef.current = innerValue;
            setInnerValue(null);
          }
        }}
        menuPortalTarget={document.body}
        onMenuClose={() => {
          if (!item?.features?.isMulti) {
            if (!item?.instantSubmit) {
              setInnerValue(valueRef.current);
            }
          }
        }}
        styles={style({
          validation: validationMessage,
          style: item.style || {},
        })}
        {...item.features}
      />
    </div>
  );
};

export default SelectComponent;