import React from "react";
import UI from "../../../dynamic-form/elements";
import util from "../../../dynamic-page/util";
import descriptiveContent from "../../../../utils/descriptiveContent";
import Icons from "../../../orders/icons";
import Components from "..";

const OrderRowAction = ({ actions, style, callBack }) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };
  return (
    <div className="flex flex-col gap-1">
      <div className={"flex justify-center items-center"}>
        {/* Internal note is here */}
        <util.popUp
          toggle={(selected) =>
            descriptiveContent(
              <div className={"relative"}>
                <UI.Button
                  item={{
                    buttonType: "flat",
                    className: `p-[2px] w-[23px] h-[23px]  ${activeStyle(
                      actions?.internal_notes > 0
                    )}`,
                  }}
                >
                  <Icons.internalNote className="w-[23px] h-[23px]" />
                </UI.Button>
                {actions?.internal_notes > 0 && (
                  <div
                    className={
                      "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                    }
                  >
                    {actions?.internal_notes}
                  </div>
                )}
              </div>,
              "Internal note",
              null,
              "top"
            )
          }
          leftSpace={style?.leftSpace}
        >
          <div className={"w-[525px]"}>
            <Components.InternalNote
              callBack={callBack ? callBack : null}
              notes={actions?.internal_notes}
              id={
                actions?.item_id
                  ? `${actions?.order_id}/${actions?.item_id}`
                  : actions?.order_id
              }
            />
          </div>
        </util.popUp>
      </div>
      <div className={"flex justify-center items-center"}>
        {/* Shipping Content is here */}

        <util.popUp
          toggle={(selected) =>
            descriptiveContent(
              <div>
                <UI.Button
                  item={{
                    buttonType: "flat",
                    className: `p-[2px] w-[23px] h-[23px] ${activeStyle(
                      !!actions?.shipping_content 
                    )}`,
                  }}
                >
                  <Icons.shippingContent className="w-[23px] h-[23px]" />
                </UI.Button>
              </div>,
              "Shipping content",
              null,
              "top"
            )
          }
          leftSpace={style?.leftSpace}
          className=""
        >
          <div className={"px-[8px] py-[4px]"}>
            <Components.ShippingContent
              shipping_content={actions?.shipping_content}
              gtip={actions?.gtip}
              id={
                actions?.item_id
                  ? `${actions?.order_id}/${actions?.item_id}`
                  : actions?.order_id
              }
              callBack={(data) => {
                if (callBack) {
                  callBack(data);
                }
              }}
            />
          </div>
        </util.popUp>
      </div>
    </div>
  );
};
export default OrderRowAction;
