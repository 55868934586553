import React from "react";
import UI from "../../dynamic-form/elements";
import {HiExternalLink} from "react-icons/hi";
import ArbitToggle from "../../sub-elements/toggle";
import {FaRegEdit} from "react-icons/fa";

const Fbm = ({modalListing, fulfilmentHandler}) => {
    const fbmData01 = [
        {
            title: "Show FBM restocking sugg. for this listing.",
            value: "FBM Restocking",
            elements: (
                <ArbitToggle
                    status={modalListing?.fbm_eligibility ?? false}
                    bgColor={
                        modalListing?.fbm_eligibility ? "bg-blue-600" : "bg-gray-400"
                    }
                    handleFulfilment={() => fulfilmentHandler("fbm", modalListing?.id)}
                />
            ),
        },
        {
            title: "FBM Total Inventory",
            value: modalListing?.fbm_total_quantity ?? "-",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 hover:text-blue-500 text-gray-300 cursor-pointer"
                    onClick={() => {
                        window.open(modalListing?.sales_channel_listing?.url, "_blank");
                    }}
                />
            ),
        },
        // Add more data items as needed
    ];
    const fbmData02 = [
        {
            title: "Use min & max values when FBM pricing.",
            value: "FBM Repricer",
            elements: (
                <ArbitToggle
                    status={modalListing?.fbm_eligibility ?? false}
                    bgColor={
                        modalListing?.fbm_eligibility ? "bg-blue-600" : "bg-gray-400"
                    }
                    handleFulfilment={() => fulfilmentHandler("fbm", modalListing?.id)}
                />
            ),
        },
        {
            title: "FBM Repricer Rule",
            value: "Match Buybox",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 hover:text-blue-500 text-gray-300 cursor-pointer"
                    onClick={() => {
                        window.open(modalListing?.sales_channel_listing?.url, "_blank");
                    }}
                />
            ),
        },
        // Add more data items as needed
    ];
    const fbmData03 = [
        {
            title: "FBM Min. Price",
            value: modalListing?.fbm_min_price || modalListing?.fbm_min_price === 0 ? `$${modalListing?.fbm_min_price}` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        {
            title: "FBM Current Price",
            value: modalListing?.fbm_min_price ? modalListing?.fbm_max_price ? `$${modalListing?.fbm_min_price}` : `$${modalListing?.fbm_min_price}` : modalListing?.fbm_max_price ? `$${modalListing?.fbm_max_price}` : "-",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 hover:text-blue-500 text-gray-300 cursor-pointer"
                    onClick={() => {
                        window.open(modalListing?.sales_channel_listing?.url, "_blank");
                    }}
                />
            ),
        },
        {
            title: "FBM Max. Price",
            value: modalListing?.fbm_max_price || modalListing?.fbm_max_price === 0 ? `$${modalListing?.fbm_max_price}` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        // Add more data items as needed
    ];
    const fbmData04 = [
        {
            title: "FBM Min. Margin",
            value: modalListing?.fbm_min_margin || modalListing?.fbm_min_margin === 0 ? `${modalListing?.fbm_min_margin}%` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        {
            title: "FBM Current Margin",
            value: modalListing?.fbm_margin || modalListing?.fbm_margin === 0 ? `${modalListing?.fbm_margin}%` : "-",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 hover:text-blue-500 text-gray-300 cursor-pointer"
                    onClick={() => {
                        window.open(modalListing?.sales_channel_listing?.url, "_blank");
                    }}
                />
            ),
        },
        {
            title: "FBM Max. Margin",
            value: modalListing?.fbm_max_margin || modalListing?.fbm_max_margin === 0 ? `${modalListing?.fbm_max_margin}%` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        // Add more data items as needed
    ];

    return (
        <div className="mt-5 flex flex-col justify-between items-start gap-y-3 gap-x-2 w-full">
            <h3 className="text-left text-gray-900 text-2xl font-bold">Inventory</h3>
            <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbmData01}/>
            </div>
            {/* <h3 className="text-left text-gray-900 text-2xl font-bold">Repricer</h3>
      <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
        <UI.StatBoxList data={fbmData02} />
      </div> */}
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbmData03}/>
            </div>
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbmData04}/>
            </div>
        </div>
    );
};

export default Fbm;
