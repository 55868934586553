// FormModal.js
// -----------------------------------------
// Create container for Normal Form Modal.

// Import general libraries
import React, { useCallback, useEffect, useRef, useState } from "react";
import DynamicForm from "../../dynamic-form";
import MainModal from "../../dynamic-page/MainModal";
import UI from "../../dynamic-form/elements";
// Import Elements

// Main Component: AddEditModal
const FormModal = React.memo(
  ({
    title, // Title of form modal.
    prefix = null, // Title prefix, if exists.
    formView, // View Model of form.
    data = null, // Data to be edited. If null, form will be in add mode.
    onSubmit, // Function to handle form submit.
    onClose, // Function to handle close form modal.
    container,
    createNew,
    saveText,
    billableWeight,
    calc, //thats for calculator modal
    visibility, // Boolean to handle visibility of form modal.,
    params,
    buttonsContainer,
    containerClassName,
  }) => {
    const [update, setUpdate] = React.useState(false);
    const [clicked, setClicked] = useState(false);
    const [carriers, setCarriers] = React.useState([]);

    // Create ref to handle form data.
    const myFormRef = useRef();
    const checkboxRef = useRef();
    const createNewRef = useCallback((node) => {
      checkboxRef.current = node;

      // if (node)
    }, []);
    const isoDateToHuman = (date) => {
      return new Date(date).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const daysHoursAgoFromIso = (date) => {
      return (
        Math.floor((new Date() - new Date(date)) / 1000 / 60 / 60 / 24) +
        "d " +
        (Math.floor((new Date() - new Date(date)) / 1000 / 60 / 60) % 24) +
        "h"
      );
    };
    useEffect(() => {}, [checkboxRef.current?.checked]);

    const myFormCallBack = async (e) => {
      e.preventDefault();
      setClicked(true);
      let formData = await myFormRef?.current?.getData();
      if (formData) {
        setClicked(false);
        // ------------------------------
        // Add additional data to form data if required.
        // Ex: formData.created_by = userId;
        // ------------------------------

        // Add id to form data if data is not null.
        if (data) {
          formData.id = data.id;
        }

        // Call onSubmit function to handle form submit.
        onSubmit(formData, async () => {
          if (checkboxRef.current?.checked) {
            await myFormRef.current?.reset();
          } else {
            onClose();
          }
        });

        // Close modal
        //  onClose()
      }
    };
    // Render Component
    return (
      // Configure Modal
      <MainModal
        containerClassName="px-[0px] pb-[0px]"
        headerClassName="pb-[12px] pt-4"
        item={{
          title: `${
            !data?.id ? (prefix ? prefix : "Add New") : "Edit"
          }  ${title}`, // If data is null, show "Add New" else "Edit".,
          submit: myFormCallBack,
          cancel: onClose,
          view: (item) => (
            <form onSubmit={myFormCallBack}>
              {container ? (
                container(
                  <DynamicForm items={formView(data, params)} ref={myFormRef} />
                )
              ) : (
                <div className="min-w-[775px] grid grid-cols-2 items-start gap-[16px] w-full justify-center px-[16px] border-t border-t-gray-200 pt-[24px] mt-[10px]">
                  {/* Dynamic form is here */}
                  <DynamicForm items={formView(data, params)} ref={myFormRef} />
                </div>
              )}

              {/* Form control Buttons are here */}
              <div
                className={` ${
                  calc
                    ? `fixed  ${
                        clicked ? "top-[226px]" : "top-[202px]"
                      } right-9`
                    : "flex  w-full items-center mt-[8px]   px-[16px] gap-[8px]"
                } flex  w-full ${
                  createNew ? "justify-between" : "justify-end"
                } items-center mt-[8px]   px-[16px] gap-[8px] ${
                  buttonsContainer && buttonsContainer
                }`}
              >
                {createNew && (
                  <div className="">
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      ref={createNewRef}
                      disabled={false}
                      className={
                        "w-[20px] h-[20px] mr-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-md "
                      }
                    />
                    <label for="scales">Create Another</label>
                  </div>
                )}

                {!calc && (
                  <div className="flex flex-row gap-[8px]">
                    <UI.Button
                      item={{
                        label: <div className={"flex flex-row"}>Cancel</div>,
                        buttonType: "alt",
                        className: "h-[37px] !px-[16px]",
                      }}
                      onClick={() => onClose()}
                    />
                    <UI.Button
                      item={{
                        label: (
                          <div className={"flex flex-row"}>
                            {saveText ? (data?.id ? "Save" : saveText) : "Save"}
                          </div>
                        ),
                        buttonType: "primary",
                        className: "h-[37px] !px-[16px]",
                      }}
                      onClick={myFormCallBack}
                    />
                  </div>
                )}
              </div>
            </form>
          ),
        }}
      />
    );
  }
);

export default FormModal;
