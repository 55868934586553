import React from "react";
import UI from "../../../../../dynamic-form/elements";

function FooterActions({list, type, addCallBack, data, isView}) {
    let compressedValues = {};

    compressedValues =
        type === 2
            ? {
                Products: list?.length,
                Quantity: list?.reduce((a, b) => a + +(b["qty"] || 0), 0),
                "Total Actual Weight": list
                    ?.reduce(
                        (a, b) =>
                            a +
                            +(b?._data?.product?.data?.fbm_actual_weight || 0) *
                            (+b?.qty || 1),
                        0
                    )
                    .toFixed(2),
                "Total Dim. Weight": list
                    ?.reduce(
                        (a, b) =>
                            a +
                            +(b?._data?.product?.data?.fbm_dimensional_weight || 0) *
                            (+b?.qty || 1),
                        0
                    )
                    .toFixed(2),
                "Billable Weight": Math.max(
                    list?.reduce(
                        (a, b) =>
                            a +
                            +(b?._data?.product?.data?.fbm_dimensional_weight || 0) *
                            (+b?.qty || 1),
                        0
                    ),
                    list?.reduce(
                        (a, b) =>
                            a +
                            +(b?._data?.product?.data?.fbm_actual_weight || 0) *
                            (+b?.qty || 1),
                        0
                    )
                ).toFixed(2),
            }
            : type === 1 || !type
                ? {
                    Shipments: list?.length,
                    "Total Actual Weight": list
                        ?.reduce(
                            (a, b) =>
                                a + +(b?._data?.shipment?.data?.weight?.actual_weight || 0),
                            0
                        )
                        .toFixed(2),
                    "Total Dim. Weight": list
                        ?.reduce(
                            (a, b) =>
                                a + +(b?._data?.shipment?.data?.weight?.dim_weight || 0),
                            0
                        )
                        .toFixed(2),
                    "Billable Weight": Math.max(
                        list?.reduce(
                            (a, b) =>
                                a + +(b?._data?.shipment?.data?.weight?.dim_weight || 0),
                            0
                        ),
                        list?.reduce(
                            (a, b) =>
                                a + +(b?._data?.shipment?.data?.weight?.actual_weight || 0),
                            0
                        )
                    ).toFixed(2),
                    "Sold Price":
                        "$ " +
                        list
                            ?.reduce(
                                (a, b) =>
                                    a + +(b?._data?.shipment?.data?.order?.items_paid || 0),
                                0
                            )
                            .toFixed(2),
                }
                : {
                    Products: list?.length,
                    Quantity: list?.reduce((a, b) => a + +(b["qty"] || 0), 0),
                    "Total Actual Weight": list
                        ?.reduce(
                            (a, b) =>
                                a +
                                +(b?._data?.offer?.data?.product?.fbm_actual_weight || 0) *
                                (+b?.qty || 1),
                            0
                        )
                        .toFixed(2),
                    "Total Dim. Weight": list
                        ?.reduce(
                            (a, b) =>
                                a +
                                +(b?._data?.offer?.data?.product?.fbm_dimensional_weight || 0) *
                                (+b?.qty || 1),
                            0
                        )
                        .toFixed(2),
                    "Billable Weight": Math.max(
                        list?.reduce(
                            (a, b) =>
                                a +
                                +(b?._data?.offer?.data?.product?.fbm_dimensional_weight || 0) *
                                (+b?.qty || 1),
                            0
                        ),
                        list?.reduce(
                            (a, b) =>
                                a +
                                +(b?._data?.offer?.data?.product?.fbm_actual_weight || 0) *
                                (+b?.qty || 1),
                            0
                        )
                    ).toFixed(2),
                };

    return (
        <div className="flex justify-between items-center">
            {/* table summary */}
            <div className="flex flex-1 gap-2">
                {list?.length > 0 &&
                    Object.keys(compressedValues).map((key, index) => (
                        <div className=" h-[41px] py-1  flex-col justify-between items-end inline-flex">
                            <div className="text-black text-xs font-normal font-['Inter'] leading-3">
                                {key}
                            </div>
                            <div className="text-black text-sm font-semibold font-['Inter'] leading-[21px]">
                                {compressedValues[key]}
                            </div>
                        </div>
                    ))}
            </div>
            {/* buttons */}
            <div className="flex  flex-row gap-[8px] justify-end py-[12px]">
                {/* <UI.Button
                            item={{
                                className: 'text-white text-sm font-medium font-Inter leading-[21px]',
                                buttonType: 'secondary',
                                // disabled: submitState,
                            }}
                            
                        >
                            Create Transfer
                        </UI.Button>
                        
                        <UI.Button
              item={{
                className: 'h-[52px] px-[24px] text-[16px]',
                buttonType: 'primary',
                // disabled: submitState,
              }}
            >
              Save & Create Shipment
            </UI.Button> */}

                {!isView && (
                    <UI.Button
                        item={{
                            label: <div className={"flex flex-row"}>Create Transfer</div>,
                            buttonType: "alt",
                            className: "h-[37px] !px-[16px]",
                        }}
                        onClick={() => {
                            addCallBack(data, {action: "save"});
                        }}
                    />
                )}
                {!isView && (
                    <UI.Button
                        item={{
                            label: (
                                <div className={"flex flex-row"}>Create Transfer & Ship</div>
                            ),
                            buttonType: "primary",
                            className: "h-[37px] !px-[16px]",
                        }}
                        onClick={() => {
                            addCallBack(data, {action: "save", ship: true});
                        }}
                    />
                )}
            </div>
        </div>
    );
}

export default FooterActions;
