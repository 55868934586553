import axios from './../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

let abortController;

export const fetchOrders = async (params) => {
    // If there's an existing AbortController, abort the previous request
    if (abortController) {
        abortController.abort();
    }

    // Create a new AbortController
    abortController = new AbortController();

    let url = constants.APIURL.GET_ORDERS;

    if (params) {
        url = url.concat(params);
    }

    try {
        const response = await axios.get(
            url,
            {
                withCredentials: true,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                // Pass the AbortController's signal to the request
                signal: abortController.signal,
            }
        );
        return response

    } catch (error) {
        // Check if the error is due to abortion
        if (error.name === 'AbortError') {
            throw error;
            // You may want to handle abortion-specific logic here
        } else {
            // Handle other errors
            throw error;
        }
    }
};
export const fetchSingleOrder = async (params) => {
    let url = `${constants.APIURL.GET_ORDERS}?filter[id]=${params}&include=shipmentLabels.fromState.country,suggestedShippingCost,salesAccount,salesAccount.channel,salesAccount.currency,shipmentLabels.internationalCarrier,firstItem`
    return await axios.get(url, {withCredentials: true})
}


const viewOrder = async (params) => {
    let url = constants.APIURL.GET_ORDERS_VIEW + params
    return await axios.get(url, {withCredentials: true,})
}

const fetchOrdersFilters = async (params) => {
    let url = constants.APIURL.GET_ORDERS_FILTERS
    if (params)
        url = url.concat(params).concat("&filter[order_status]=")
    return await axios.get(url, {withCredentials: true,})
}

const bulkDeleteOrders = async (params) => {
    let url = constants.APIURL.BULK_DELETE_ORDERS
    if (params)
        url = url.concat(params)
    return await axios.delete(url, {withCredentials: false,})
}

const bulkRestoreOrders = async (params) => {
    let url = constants.APIURL.POST_RESTORE_ORDERS
    if (params)
        url = url.concat(params)
    return await axios.post(url, {withCredentials: false,})
}

// Shipment Carrier
const fetchShipmentCarrier = async (payload) => {
    let url = constants.APIURL.CALCULATOR_DATAS + `?from_state_id=${payload?.fromStateId}&to_state_id=${payload?.state}&billable_weight=${payload?.billable_weight}&fresh=${payload?.fresh}`
    const response = await axios.get(url,
        {withCredentials: true},
    );
    return response.data
}
const createShipment = async (params) => {
    const url = constants.APIURL.GET_ORDERS_VIEW + "create-shipment-label" + `/${params?.order_id}/${params?.warehouse_id}/${params?.shipment_pricing_id}`
    const rs = await axios.post(url,
        {withCredentials: true,});
    return rs
}

// Notes
const fetchNotes = async (params) => {
    const url = `${constants.APIURL.GET_ORDERS}/${params?.orderId}/notes`;
    return await axios.get(url, {withCredentials: true,})
}

const deleteNote = async (params) => {
    const url = `${constants.APIURL.GET_ORDERS}/${params?.orderId}/notes/${params?.noteId}`;
    return await axios.delete(url, {withCredentials: true,});
}


const addNote = async (params) => {
    const url = `${constants.APIURL.GET_ORDERS}/${params?.orderId}/notes`;
    return await axios.post(url, {note: params?.note}, {withCredentials: true,});
}

// Save weight
const updateBillableWeight = async (params) => {
    const url = `${constants.APIURL.GET_ORDERS}/${params?.orderId}`;
    return await axios.post(url, {billable_weight: params?.billable_weight}, {withCredentials: true,});
}


const createOrder = async (payload) => {
    const url = `${constants.APIURL.GET_ORDERS}${payload?.orderId ? "/" + payload?.orderId : ''}`
    return await axios.post(url, payload.data, {withCredentials: true,})
    //return await axios.post(url, apiutils.obj2Form(payload), {withCredentials: true,})
}

const deleteOrder = async (payload) => {
    const url = `${constants.APIURL.GET_ORDERS}`

    return await axios.delete(url, {
        data:{orders:payload.orders},
        withCredentials: true
    })
}

const createSupplyRequest = async (payload) => {
    const url = `${constants.APIURL.POST_SUPPLY_REQUEST}/order`
    return await axios.post(url, payload, {withCredentials: true,})
}
const createSupplyRequestOrderItem = async (payload) => {
    const url = `${constants.APIURL.POST_SUPPLY_REQUEST}/order/${payload.orderId}/order-item`
    return await axios.post(url, payload.items, {withCredentials: true,})
}


//Actions
const apiActions = async (params) => {
    const actionsEndpoints = {
        "print-label-mark-shipped": "POST",
        "mark-as-shipped": "POST",
        "buyer-cancelled": "POST",
        "hold-order": "POST",
        "set-carrier-service": "POST",
        "print-label": "POST",
        'print-label-notify': 'POST',
        "duplicate": "POST",
        "merge": "POST",
        "split": "POST",
        "shipstation/refresh-stores": "POST",

        "cancelled-by-buyer": "POST",
        "mark-as-refunded": "POST",
        "jump-to-initial-state": "POST",
        "set-primary-shipment-label": "POST",
        "toggle-shipping-label-visibility": "POST",
        "update-shipping-content": "POST",
        "update-gtip": "POST",
        "cancelOrderItems": "POST",
        "connect-product": "POST",
        "disconnect-product": "POST",
        "order-item/mark-as-refunded": "POST",
        "order-item": "POST",
        "summary": "POST",
        "recipient": "POST",
        "sync-carrier-pricing": "POST",
        'order-item/mark-as-pending': 'POST',
        'shipment-labels/mark-as-primary': "POST",


    }
    return axios({
        method: params?.method || actionsEndpoints[params?.action],
        url: `${constants.APIURL.GET_ORDERS}${params?.orderId ? `/${params?.orderId}` : ""}${params?.action ? '/' + params?.action : ''}${params?.itemId ? `/${params?.itemId}` : ''}`,
        data: params?.data ? params?.data : null,
        withCredentials: true
    })
}

// Actions for order detail
const apiDetailActions = async (params) => {
    const actionsEndpoints = {
        "request-refund": "PATCH",
        "buyer-cancelled": "PATCH",
        'cancelOrderItems': 'POST',
        'connect-product': 'POST',
        'disconnect-product': 'POST',
        'update-shipping-content': 'POST',
        'reset-shipping-content': 'DELETE',
        'mark-as-pending': 'POST',
    }
    return axios({
        method: actionsEndpoints[params?.action],
        url: params?.action === 'cancelOrderItems' ? `${constants.APIURL.GET_ORDERS}/${params?.orderId}/cancelOrderItems` :
            params?.action === 'connect-product' ? `${constants.APIURL.GET_ORDERS}/${params?.orderId}/${params?.itemId}/${params?.action}` :
                params?.action === 'disconnect-product' ? `${constants.APIURL.GET_ORDERS}/${params?.orderId}/${params?.itemId}/${params?.action}` :
                    params?.action === 'update-shipping-content' ? `${constants.APIURL.GET_ORDERS}/${params?.orderId}/${params?.itemId}/${params?.action}` :
                        params?.action === 'reset-shipping-content' ? `${constants.APIURL.GET_ORDERS}/${params?.orderId}/${params?.itemId}/${params?.action}` :
                            `${constants.APIURL.GET_ORDERS}/${params?.orderId}/order-items/${params?.action}`,


        // url: `${constants.APIURL.GET_ORDERS}/${params?.orderId}/order-items/${params?.action}`,
        data: apiutils.obj2Form(params?.data),
        withCredentials: true
    })
}


export default {
    fetchOrders, fetchSingleOrder, viewOrder, fetchOrdersFilters, bulkDeleteOrders, bulkRestoreOrders,
    apiActions, apiDetailActions, createOrder, deleteOrder, // Order

    fetchShipmentCarrier, createShipment, //Shipment
    addNote, deleteNote, fetchNotes,// Notes
    updateBillableWeight, // weight

    createSupplyRequest, createSupplyRequestOrderItem
}
