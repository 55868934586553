const MANDATORY = "This field is mandatory."
const LOGIN_SUCCESS = "You logined successfully."
const LOGIN_FAILED = "You failed in login."
const REGISTER_SUCCESS = "You registerd successfully."
const REGISTER_FAILED = "You failed in registration."
const PASSWORD_DISMATCH = "Password is not matched."
const PASSWORD_WEEK = "Password is too week."
const PASSWORD_EMPTY = "Password is empty."
const EMAIL_EMPTY = "Email is empty."

export default {
    MANDATORY,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    PASSWORD_DISMATCH,
    PASSWORD_WEEK,
    PASSWORD_EMPTY,
    EMAIL_EMPTY
}
