const tabsView = (pageData) => {
    return ({

        in: {
            label: "IN",
            count: pageData?.filtersMeta?.types?.find((item) => item.type === "in")?.count || 0,
            filter: {
                type: 'in'
            },
        },
        out: {
            label: "OUT",
            count: pageData?.filtersMeta?.types?.find((item) => item.type === "out")?.count || 0,
            filter: {
                type: 'out'
            },
        },
        all: {
            label: "All",
            count: pageData?.filtersMeta?.types?.reduce((acc, item) => acc + item.count, 0) || 0,
            filter: {
                type: 'in,out'
            },
            active: true,

        },
    })
}
export default tabsView;