const tabsView = (pageData) => {
    return ({

        active: {
            label: "Active",
            count: pageData?.filtersMeta?.filters?.statuses?.find((item) => item.type === 'active')?.count || 0,
            filter: {
                status: 1
            },
        },
        inactive: {
            label: "Inactive",
            count: pageData?.filtersMeta?.filters?.statuses?.find((item) => item.type === 'inactive')?.count || 0,
            filter: {
                status: 0
            },
        },
        deleted: {
            label: "Deleted",
            count: pageData?.filtersMeta?.filters?.statuses?.find((item) => item.type === 'deleted')?.count || 0,
            filter: {
                status: 2
            },
        },
        all: {
            label: "All",
            count: pageData?.filtersMeta?.filters?.statuses?.reduce((acc, item) => acc + item.count, 0) || 0,
            filter: {
                status: ''
            },
            active: true,

        },
    })
}
export default tabsView;