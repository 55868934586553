import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchUnits = createAsyncThunk(
    "get:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.fetchUnits(payload);
        return response.data;
    }
);

const createUnit = createAsyncThunk(
    "post:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.createUnit(payload);
        return response;
    }
);

const updateUnit = createAsyncThunk(
    "put:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.updateUnit(payload);
        return response;
    }
);

const deleteUnit = createAsyncThunk(
    "delete:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.deleteUnit(payload);
        return response;
    }
);

const searchUnits = createAsyncThunk(
    "get:api/v1/currencies/search",
    async (payload, thunkAPI) => {
        const response = await api.searchUnits(payload);
        return response.data;
    }
);

const bulkDeleteUnits = createAsyncThunk(
    "delete:api/v1/currencies/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteUnits(payload);
        return response;
    }
);

const updateUnitsStatus = createAsyncThunk(
    "put:api/v1/currencies/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdateUnitsStatus(payload);
        return response;
    }
);

const bulkRestoreUnits = createAsyncThunk(
    "put:api/v1/currencies/bulk/restore",
    async (payload, thunkAPI) => {
        const response = await api.bulkRestoreUnits(payload);
        return response;
    }
);

const fetchUnitsFilters = createAsyncThunk(
    "get:api/v1/currencies/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchUnitsFilters(payload);
        return response;
    }
);


export {
    fetchUnits,
    createUnit,
    updateUnit,
    deleteUnit,
    searchUnits,
    bulkDeleteUnits,
    updateUnitsStatus,
    bulkRestoreUnits,
    fetchUnitsFilters
};
