import upperCase from "../../dynamic-page/util/upperCaseString";

const filtersView = (selectedFilters, filtersMeta) => {
  let billable = [];
  if (selectedFilters?.billable_weight?.length > 0) {
    billable = selectedFilters?.billable_weight;
  } else {
    if (
      selectedFilters?.billable_weight !== null &&
      typeof selectedFilters?.billable_weight !== "undefined"
    ) {
      billable.push(selectedFilters?.billable_weight);
      billable.push(selectedFilters?.billable_weight);
    }
  }

  return [
    {
      name: "carrier_ids",
      label: "Carrier",
      placeholder: "Filter by carrier",
      type: "Select",
      list: filtersMeta?.data?.carriers?.map((item) => ({
        label: upperCase(item?.name),
        value: item?.id,
        count: item?.count,
      })),
      features: {
        formatOptionLabel: ({ label, count }) => {
          return (
            <div
              className={
                "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
              }
            >
              <span>{upperCase(label)}</span>
              <span
                className={
                  "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                }
              >
                {count}
              </span>
            </div>
          );
        },
        isMulti: true,
      },
      defaultValue: selectedFilters?.carrier_ids,
      toggle: true,
      clear: true,
    },
    {
      name: "service_name",
      label: "Service Name",
      placeholder: "Filter by Service Name",
      type: "Input",
      defaultValue: selectedFilters?.service_name || null,
      toggle: true,
      clear: true,
    },
    {
      name: "from_state_ids",
      label: "From State",
      placeholder: "Filter by from state",
      type: "Select",
      
      api: {
        url: "/api/v1/state",
        query: "?filter[name]={query}",
        skipCancel:true,
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          country: item?.country?.name || "",
          flag: item?.country?.flag || "",
        }),
      },
      features: {
        formatOptionLabel: ({ label, count }) => {
          return (
            <div
              className={
                "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
              }
            >
              <span>{upperCase(label)}</span>
              <span
                className={
                  "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                }
              >
                {count}
              </span>
            </div>
          );
        },
        isMulti: true,
      },
      defaultValue: selectedFilters?.from_state_ids,
      toggle: true,
      clear: true,
    },
    {
      name: "to_state_ids",
      label: "To State",
      placeholder: "Filter by to state",
      type: "Select",
      
      api: {
        url: "/api/v1/state",
        query: "?filter[name]={query}",
        skipCancel:true,
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          country: item?.country?.name || "",
          flag: item?.country?.flag || "",
        }),
      },
      features: {
        formatOptionLabel: ({ label, count }) => {
          return (
            <div
              className={
                "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
              }
            >
              <span>{upperCase(label)}</span>
              <span
                className={
                  "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                }
              >
                {count}
              </span>
            </div>
          );
        },
        isMulti: true,
      },
      defaultValue: selectedFilters?.to_state_ids,
      toggle: true,
      clear: true,
    },
    {
      name: "billable_weight",
      label: "Billable Weight",
      placeholder: "Filter by billable weight",
      type: "Range",
      defaultValue: billable.length !== 0 ? billable : null,
      toggle: true,
      clear: true,
    },
  ];
};

export default filtersView;
