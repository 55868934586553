import { createSlice } from "@reduxjs/toolkit";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  list: [],
};

export const toastNotificationSlice = createSlice({
  name: "notificationData",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    addNotification: (state, action) => {
      //console.log("addNotification", action.payload)
      state.list.push({ ...action.payload });
    },

    updateNotificationData: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },

    deleteAllNotifications: (state) => {
      state.list = [];
    },
  },
});

export const {
  addNotification,
  updateNotificationData,
  deleteAllNotifications,
} = toastNotificationSlice.actions;
export default toastNotificationSlice.reducer;
