import React from 'react'
import icons from '../../orders/icons'
import priceHandler from '../../../utils/priceHandler'

function ListingSales({item, noArrows}) {
    return (
        <div className=" justify-start gap-4 items-center px-2 inline-flex h-[104px]">
            {item?.weekly && <div className="   flex-col justify-between items-start  inline-flex">
                <div className="flex-col justify-start items-start flex">
                    <div className="text-gray-700 text-xs font-medium leading-3">Last 7d Sales</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-blue-700 text-2xl font-bold leading-6">
                            {item?.weekly?.sales ? item?.weekly?.sales : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
                <div className="flex-col justify-start items-start flex">
                    <div className="text-gray-700 text-xs font-medium leading-3">Last 7d Revenue</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-blue-700 text-2xl font-bold leading-6">
                            {item?.weekly?.revenue ? '$' + priceHandler(item?.weekly?.revenue) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
            </div>}
            {item?.monthly && <div className="   flex-col justify-between items-start  inline-flex">
                <div className="flex-col justify-start items-start flex">
                    <div className="text-gray-700 text-xs font-medium leading-3">Last 30d Sales</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-blue-700 text-2xl font-bold leading-6">
                            {item?.monthly?.sales ? item?.monthly?.sales : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
                <div className="flex-col justify-start items-start flex">
                    <div className="text-gray-700 text-xs font-medium leading-3">Last 30d Revenue</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-blue-700 text-2xl font-bold leading-6">
                            {item?.monthly?.revenue ? '$' + priceHandler(item?.monthly?.revenue) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
            </div>}
            {item?.biMonthly && <div className="   flex-col justify-between items-start  inline-flex">
                <div className="flex-col justify-start items-start flex">
                    <div className="text-gray-700 text-xs font-medium leading-3">Last 60d Sales</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-blue-700 text-2xl font-bold leading-6">
                            {item?.biMonthly?.sales ? priceHandler(item?.biMonthly?.sales) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
                <div className="flex-col justify-start items-start flex">
                    <div className="text-gray-700 text-xs font-medium leading-3">Last 60d Revenue</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-blue-700 text-2xl font-bold leading-6">
                            {item?.biMonthly?.revenue ? '$' + priceHandler(item?.biMonthly?.revenue) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ListingSales