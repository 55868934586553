const sortView = ({callback}) => {
    return {
        list: [

            {
                label: "Title",
                value: "title",
                onClick: (item) => {
                    callback("title");
                }
            },
            {
                label: "Weighted Average",
                value: "weighted_avg",
                onClick: (item) => {
                    callback("weighted_avg");
                }
            },
            {
                label: "Created by",
                value: "created_by",
                onClick: (item) => {
                    callback("created_by");
                }
            },
            {
                label: "Inventory Count",
                value: "inventory_count",
                onClick: (item) => {
                    callback("inventory_count");
                }
            },
            {
                label: "Purchase Price",
                value: "purchase_price",
                onClick: (item) => {
                    callback("purchase_price");
                }
            },
            {
                label: "Cost",
                value: "cost",
                onClick: (item) => {
                    callback("cost");
                }
            },
            {
                label: "Total Ordered Count",
                value: "order_count",
                onClick: (item) => {
                    callback("order_count");
                }
            },
            {
                label: "Resupply Count",
                value: "restocking_qty",
                onClick: (item) => {
                    callback("restocking_qty");
                }
            },

        ]


    }
}

export default sortView