import Listing from "./Listing";
import Product from "./Product";
import Sales from "./Sales";
import Fba from "./Fba";
import Fbm from "./Fbm";
import ListingOffers from "./ListingOffers";
import YourOffers from "./YourOffers";

//Export Elements as default
export default {
    Listing,
    Product,
    Sales,
    Fba,
    Fbm,
    ListingOffers,
    YourOffers
};