/**
 * @author Redwan J
 * @description ProductInventoryPage
 */

import DynamicTable from "../dynamic-page";

const tableView = (list, callBack) => {


    return {
        list: list ?? [],
        theme: {
            warehouse: (item) => (
                <div>
                    {item?.name}
                </div>
            ),
            quantity: (item) => (
                <div
                    className="text-purple-700 text-2xl font-bold flex flex-row justify-end gap-[4px] items-center leading-none">
                    {item?.available_quantity}
                </div>
            ),
        },
    };
};


// handle actions of statuses
const actions = (row) => {
    return [];
};

const ProductInventoryPage = ({loading, product_id, inventory = []}) => {

    return (
        <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">

                <DynamicTable
                    view={tableView}
                    data={tableView(inventory)}
                    actions={actions}
                    loading={loading}
                    selectedRow={[]}
                    style={{
                        header: {
                            className: "flex justify-start",
                            "tracking id": {
                                className: "min-w-min",
                            },
                            warehouse: {
                                th: "w-[200px]",
                            },
                            quantity: {
                                th: "w-[400px]",
                            },
                        },
                        row: {
                            className: "flex items-start justify-start",
                            "tracking id": {
                                className: "min-w-min",
                            },
                            warehouse: {
                                td: "w-[100px]",
                            },
                            quantity: {
                                td: "w-[400px]",
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default ProductInventoryPage;

