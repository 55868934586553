import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./modals-view/table";
import filtersView from "./modals-view/filters";
import sortView from "./modals-view/sort";
import tabsView from "./modals-view/tabs";
import actionsView from "./modals-view/actions";
import form from "./modals-view/form";
import optionsView from "./modals-view/options";

// Main Component
const Accounts = () => {
    // Initial Page
    const Page = usePage({
        pageTitle: "Sales Channel Accounts",
        api: {
            fetch: "accounts",
            filters: "accounts/filters",
            query:
                "include=channel,shipstationStore,language,country,currency",
        },
        ModelsView: {
            table: tableView,
            filter: filtersView,
            sort: sortView,
            tabs: tabsView,
            actions: actionsView,
            headerOption: optionsView,
            forms: {
                add: form,
                connect: 1,
                download: 1,
            },
        },
        pageSettings: {
            rowStyles: {
                channel: {
                    td: '!min-w-[150px]'
                }
            },
            ignoredFilters:["status"]
        },
    });

    // return View
    return Page
};

export default Accounts;
