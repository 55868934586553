import Dropdown from "../sub-elements/dropdown";
import { HiCheck } from "react-icons/hi";
import ArbitButton from "../sub-elements/button";
import MainModal from "../dynamic-page/MainModal";
import AsyncSelect from "react-select/async";
// import 'react-Select.js/dist/react-Select.js.css'
// import 'react-virtualized/styles.css'
// import 'react-virtualized-Select.js/styles.css'
import upperCaseString from "../dynamic-page/util/upperCaseString";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../middleware/axios";
import constants from "../../constant";
import { useEffect, useState } from "react";
import moment from "moment";
import priceHandler from "../../utils/priceHandler";
import DescriptiveDropDown from "../sub-elements/descriptive-drop-down";
import quantityHandler from "../../utils/quantityHandler";
import Select from "react-select";

const NewSupply = ({
  show,
  setShow,
  user,
  selectedSupply,
  supplyList,
  onClose,
  onFulfill,
  product,
  type,
  onUpdate,
}) => {
  const [sourceType, setSourceType] = useState("Create From Product");

  console.log(selectedSupply);

  const validationSchema = Yup.object({
    supplier_id: Yup.mixed().required("Required"),
    is_paid: Yup.mixed().required("Required"),
  });

  const dateTime = new Date(selectedSupply?.created_at);
  const date =
    dateTime.getFullYear() +
    "-" +
    (dateTime.getMonth().toLocaleString() + 1) +
    "-" +
    dateTime.getDate();

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [submit, setSubmit] = useState(false);

  // useEffect(() => {
  //     async function fetchProducts() {
  //         await axios.get(constants.APIURL.GET_SUPPLY_REQUESTS + "&filter[supplyRequest]=" + selectedSupply?.supplyRequest?.id, { withCredentials: true }).then(response => {
  //             setProducts(response?.data?.data)
  //         }
  //         )
  //     }
  //     fetchProducts()
  // }, [])

  const fetchSources = async (params) => {
    let url = "api/v1/channel";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const mapOptionsToValues = (options) => {
    return options?.map((option) => ({
      value: option?.id,
      label: option?.name || option?.display_name,
      image: option?.image,
      title: option?.name,
      product: option?.id,
      position: option?.position,
      symbol: option?.symbol,
    }));
  };

  const sourcesOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchSources("?filter[name]=" + inputValue + "&not_deleted=true")
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const [vats, setVats] = useState([]);
  useEffect(() => {
    async function fetchVats() {
      await axios
        .get(constants.APIURL.GET_VAT_RATES + "?not_deleted=true", {
          withCredentials: true,
        })
        .then((response) => {
          // setVats(response?.data?.data)
          const options = mapOptionsToValues(response?.data?.data);
          setVats(options);
        });
    }

    fetchVats();
  }, []);

  const fetchVats = async (params) => {
    let url = "api/v1/vat-rates";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const vatsOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchVats("/search?term=" + inputValue + "&not_deleted=true")
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    async function fetchCurrencies() {
      await axios
        .get(constants.APIURL.GET_CURRENCIES, { withCredentials: true })
        .then((response) => {
          // setCurrencies(response?.data?.data)
          const options = mapOptionsToValues(response?.data?.data);
          setCurrencies(options);
        });
    }

    fetchCurrencies();
  }, []);

  const fetchCurrencies = async (params) => {
    let url = "api/v1/currencies";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const currenciesOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchCurrencies("/search?term=" + inputValue)
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const mapProductsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: `#${option?.id}  |  ` + (option?.title ? option?.title : ""),
      description:
        "APID:  " +
        option?.apid +
        " | " +
        "Created At:  " +
        moment(option?.request_date_time).format("DD-MM-YYYY"),
      image: option?.image,
      assign: option?.brand?.assigned?.name,
      profile: option?.brand?.assigned?.profile_image,
    }));
  };

  const mapRequestsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label:
        `#${option?.id}  |  ` +
        (option?.product?.title ? option?.product?.title : "") +
        `  |   QTY: ${quantityHandler(option?.qty)}`,
      description:
        "Created By:  " +
        option?.requester?.name +
        " | " +
        "Created At:  " +
        moment(option?.request_date_time).format("DD-MM-YYYY"),
      qty: parseInt(priceHandler(option?.qty)),
    }));
  };

  const [purchaseOptions, setPurchaseOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    async function fetchPurchases() {
      await axios
        .get(constants.APIURL.GET_SUPPLY_REQUESTS, { withCredentials: true })
        .then((response) => {
          // setPurchaseOptions(response?.data?.data)
          const validOptions = response?.data?.data?.filter(
            (option) => option?.is_writable === true
          );
          const options = mapRequestsOptionsToValues(validOptions);
          setPurchaseOptions(options);
        });
    }

    fetchPurchases();
  }, []);

  useEffect(() => {
    async function fetchProducts() {
      await axios
        .get(constants.APIURL.GET_PRODUCT, { withCredentials: true })
        .then((response) => {
          // setProductOptions(response?.data?.data)
          const options = mapProductsOptionsToValues(response?.data?.data);
          setProductOptions(options);
        });
    }

    fetchProducts();
  }, []);

  const [purchase, setPurchase] = useState("");

  const fetchPurchases = async (params) => {
    let url = constants.APIURL.GET_SUPPLY_REQUESTS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  // useEffect (() => {
  //   if (type === 'Edit') {
  //     const fetchPurchases = async (params) => {
  //       let url = constants.APIURL.GET_SUPPLY_PURCHASES + `/${selectedSupply?.purchase_id}`
  //       if (params)
  //           url = url.concat(params)
  //       const response = await axios.get(url, { withCredentials: true });
  //       setPurchase({
  //         value: response?.data?.data?.id,
  //         label: `#${response?.data?.data?.purchase_no}  |  ` + (response?.data?.data?.product?.title ? response?.data?.data?.product?.title : '' ) + `   |   QTY: ${priceHandler(response?.data?.data?.quantity)}` ,
  //         description: 'Created By:  ' + response?.data?.data?.created_by?.name + ' | ' + 'Created At:  ' + moment(response?.data?.data?.created_at).format('DD-MM-YYYY'),
  //       })
  //       return response.data

  //   }
  //   fetchPurchases()
  //   }
  // }, [])

  const loadPurchasesOptions = async (inputValue) => {
    let url;

    url = "/search?term=" + inputValue + "&not_deleted=1";

    return new Promise((resolve) => {
      setTimeout(() => {
        fetchPurchases(url)
          .then((response) => {
            const options = mapRequestsOptionsToValues(response.data);
            resolve(options);
          })
          .catch((error) => {});
      }, 1000);
    });
  };

  const [productOption, setProductOption] = useState("");

  const fetchProducts = async (params) => {
    let url = constants.APIURL.GET_PRODUCT + "/search?term=";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  // const mapProductsOptionsToValues = options => {
  //     return options.map(option => ({
  //         value: option?.id,
  //         label: `#${option?.id}  |  ` + (option?.title ? option?.title : '' )  ,
  //         description: 'APID:  ' + option?.apid + ' | ' + 'Created At:  ' + moment(option?.request_date_time).format('DD-MM-YYYY'),
  //     }));
  // };

  useEffect(() => {
    if (type === "Edit") {
      //     const fetchProducts = async (params) => {
      //       let url = constants.APIURL.GET_SUPPLY_PURCHASES + `/${selectedSupply?.purchase_id}`
      //       if (params)
      //           url = url.concat(params)
      //       const response = await axios.get(url, { withCredentials: true });
      //       setProduct({
      //         value: response?.data?.data?.id,
      //         label: `#${response?.data?.data?.purchase_no}  |  ` + (response?.data?.data?.product?.title ? response?.data?.data?.product?.title : '' ) + `   |   QTY: ${priceHandler(response?.data?.data?.quantity)}` ,
      //         description: 'Created By:  ' + response?.data?.data?.created_by?.name + ' | ' + 'Created At:  ' + moment(response?.data?.data?.created_at).format('DD-MM-YYYY'),
      //       })
      //       return response.data

      //   }
      //   fetchProducts()

      if (selectedSupply?.supply_request) {
        setSourceType("Create From Supply Request");
        setPurchase({
          value: selectedSupply?.supply_request?.id,
          label:
            `#${selectedSupply?.supply_request?.id}  |  ` +
            (selectedSupply?.supply_request?.product?.title
              ? selectedSupply?.supply_request?.product?.title
              : "") +
            `|  QTY: ${quantityHandler(selectedSupply?.supply_request?.qty)}`,
          description:
            "Created By:  " +
            selectedSupply?.supply_request?.requester?.name +
            " | " +
            "Created At:  " +
            moment(selectedSupply?.supply_request?.request_date_time).format(
              "DD-MM-YYYY"
            ),
        });
      } else {
        setProductOption({
          value: selectedSupply?.product?.id,
          label:
            `#${selectedSupply?.product?.id}  |  ` +
            (selectedSupply?.product?.title
              ? selectedSupply?.product?.title
              : ""),
          description:
            "APID:  " +
            selectedSupply?.product?.apid +
            " | " +
            "Created At:  " +
            moment(selectedSupply?.product?.created_at).format("DD-MM-YYYY"),
        });
      }
    }
  }, []);

  const loadProductsOptions = async (inputValue) => {
    let url;

    url = inputValue + "&not_deleted=true";

    return new Promise((resolve) => {
      setTimeout(() => {
        fetchProducts(url)
          .then((response) => {
            const options = mapProductsOptionsToValues(response.data);
            resolve(options);
          })
          .catch((error) => {});
      }, 1000);
    });
  };

  const fetchBankAccounts = async (params) => {
    let url = constants.APIURL.GET_BANK_ACCOUNTS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const mapBankAccountsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: upperCaseString(option?.bankName) + " | " + option?.accountNumber,
    }));
  };

  const bankAccountsOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchBankAccounts("/search?term=" + inputValue + "&not_deleted=true")
        .then((response) => {
          const options = mapBankAccountsOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const formik = useFormik({
    initialValues: {
      supplier_id: {
        value: selectedSupply?.supplier?.id,
        label: selectedSupply?.supplier?.name,
      },
      is_paid: selectedSupply?.is_paid ? 1 : 0,
      bank_account_id: selectedSupply?.bank_account_id?.value,
      estimate_delivery_date: selectedSupply?.estimate_delivery_date,
      status: 1,
      currency_id: {
        value: selectedSupply?.currency?.id,
        label: selectedSupply?.currency?.name,
      },
      note: selectedSupply?.note,
      purchase_price: selectedSupply?.purchase_price?.TRY,
      quantity: selectedSupply?.quantity,
      product_id: selectedSupply?.product?.id,
    },

    validationSchema,
    onSubmit: (values) => {
      const payload = {
        supplier_id: values?.supplier_id?.value,
        is_paid: values?.is_paid,
        bank_account_id: values?.bank_account_id,
        estimate_delivery_date: values?.estimate_delivery_date,
        status: 1,
        currency_id: values?.currency_id?.value,
        note: values?.note,
        purchase_price: values?.purchase_price,
        quantity: values?.quantity,
        product_id: values?.product_id,
      };

      onUpdate(selectedSupply?.id, payload);
    },
  });

  const onSubmit = () => {
    setSubmit(true);
  };

  useEffect(() => {
    if (selectedSupply) {
      setProductOption({
        value: selectedSupply?.product?.id,
        label:
          `#${selectedSupply?.product?.id}  |  ` +
          (selectedSupply?.product?.title
            ? selectedSupply?.product?.title
            : ""),
        description:
          "APID:  " +
          selectedSupply?.product?.apid +
          " | " +
          "Created At:  " +
          moment(selectedSupply?.product?.created_at).format("DD-MM-YYYY"),
      });
      setSelectedProduct({
        product_id: selectedSupply?.product?.id,
        title: selectedSupply?.product?.title,
        image: selectedSupply?.product?.image,
        quantity: selectedSupply?.qty,
        purchase_price: selectedSupply?.product?.purchase_price,
        assign: selectedSupply?.product?.brand?.assigned?.name,
        profile: selectedSupply?.product?.brand?.assigned?.profile_image,
      });
    }
    if (product) {
      setSelectedProduct({
        product_id: product?.id,
        title: product?.title,
        image: product?.image,
        purchase_price: product?.purchase_price,
        assign: product?.brand?.assigned?.name,
        profile: product?.brand?.assigned?.profile_image,
      });
      setProductOption({
        value: product?.id,
        label: `#${product?.id}  |  ` + (product?.title ? product?.title : ""),
        description:
          "APID:  " +
          product?.apid +
          " | " +
          "Created At:  " +
          moment(product?.created_at).format("DD-MM-YYYY"),
      });
    }
  }, [selectedSupply]);

  useEffect(() => {
    if (purchase && type === "Add") {
      formik.setFieldValue("amount", purchase?.qty || "");
    }
    if (selectedSupply?.requester) {
      formik.setFieldValue("amount", +priceHandler(selectedSupply?.qty) || "");
    }
  }, [purchase, selectedSupply]);

  return (
    <MainModal
      item={{
        title:
          type === "Edit"
            ? "Edit Supply Purchase"
            : "Create New Supply Purchase",
        submit: formik.handleSubmit,
        cancel: onClose,
        view: (item) => (
          <form
            onSubmit={formik.handleSubmit}
            className="w-[90vw] border-t border-t-gray-200 pt-[24px] px-4"
          >
            {/* <h2 className="font-medium text-[24px] leading-[36px] text-gray-900">#{selectedSupply?.id}</h2> */}

            <div className="grid grid-cols-3 gap-2">
              <div className="flex flex-col h-[106px] space-y-[8px] ">
                <label className="text-base font-medium text-gray-900">
                  Supplier
                </label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={sourcesOptions}
                  placeholder={"Search for a Supplier"}
                  className="border-none h-[42px] bg-transparent w-[338px]"
                  name="provider"
                  value={formik.values.supplier_id}
                  onChange={(value) => {
                    formik.setFieldValue("supplier_id", value);
                  }}
                  onBlur={formik.handleBlur}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: 42,
                      minHeight: 42,
                      width: "100%",
                      backgroundColor: "#F9FAFB",
                      borderRadius: 10,
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : formik.errors.supplier_id
                        ? "1px solid #FF0000"
                        : "1px solid #E5E5E5",

                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#00A3FF" : "white",
                      color: state.isSelected ? "white" : "black",

                      "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      height: 300,
                      zIndex: 999,
                      // borderRadius: 10,
                      overflow: "hidden",
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                      height: 42,
                      minHeight: 42,
                    }),
                  }}
                />
              </div>
              <div className="flex flex-col h-[106px] w-full gap-[8px]">
                <label
                  className="text-base font-medium text-gray-900"
                  htmlFor="status"
                >
                  Payment
                </label>
                <Select
                  options={[
                    { value: 1, label: "Completed" },
                    { value: 0, label: "Missing" },
                  ]}
                  placeholder="Select Payment"
                  value={{
                    value: formik.values.is_paid,
                    label:
                      formik.values.is_paid === 1 ? "Completed" : "Missing",
                  }}
                  onChange={(option) =>
                    formik.setFieldValue("is_paid", option.value)
                  }
                  className="!w-full" // Forces Select to take full width
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#F9FAFB",
                      borderColor: formik.errors.is_paid
                        ? "#FF0000"
                        : state.isFocused
                        ? "#00A3FF"
                        : "#E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        borderColor: state.isFocused ? "#00A3FF" : "#E5E5E5",
                      },
                      height: 42,
                      minHeight: 42,
                      borderRadius: 10,
                      width: "100%", // Sets control width to full
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#00A3FF" : "white",
                      color: state.isSelected ? "white" : "black",
                      "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 999,
                      width: "100%", // Sets menu width to full
                    }),
                    container: (provided) => ({
                      ...provided,
                      width: "100%", // Ensures the container itself is full width
                    }),
                  }}
                />
              </div>
              <div className="flex flex-col space-y-[8px] ">
                <label className="text-base font-medium text-gray-900">
                  Payment Method{" "}
                  <span className="text-xs text-gray-400">(optional)</span>
                </label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={bankAccountsOptions}
                  placeholder={"Bank Account"}
                  className="border-none h-[42px] bg-transparent w-[338px]"
                  name="bank_account"
                  value={formik.values.bank_account_id}
                  onChange={(value) => {
                    formik.setFieldValue("bank_account_id", value);
                  }}
                  onBlur={formik.handleBlur}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: 42,
                      minHeight: 42,
                      width: "100%",
                      backgroundColor: "#F9FAFB",
                      borderRadius: 10,
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#00A3FF" : "white",
                      color: state.isSelected ? "white" : "black",

                      "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      width: "100%",
                      zIndex: 999,
                      // borderRadius: 10,
                      overflow: "hidden",
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                      height: 42,
                      minHeight: 42,
                    }),
                  }}
                />
              </div>
            </div>

            {/* product */}

            <div className="flex flex-col w-full h-[106px] gap-[6px]">
              <label className="text-base font-medium text-gray-900">
                Product
              </label>
              <DescriptiveDropDown
                loadOptions={loadProductsOptions}
                placeholder={"Search for a Product"}
                options={productOptions}
                disabled={true}
                width={"100%"}
                height={"42px"}
                data={productOption}
                setData={(data) => {
                  setProductOption(data);
                  setSelectedProduct({
                    ...selectedProduct,
                    product_id: data?.value,
                    title: data?.label,
                    image: data?.image,
                    assign: data?.assign,
                    profile: data?.profile,
                  });
                }}
                submit={submit}
              />
            </div>

            {/* the second grid for price, currency, .. */}
            <div className="grid grid-cols-4 gap-2">
              <div className="flex flex-col space-y-[8px]  ">
                <label className="text-base font-medium text-gray-900">
                  Purchase Price
                </label>
                <input
                  type="number"
                  className={`bg-gray-50 border h-[42px] text-gray-500 p-3 rounded-xl ${
                    formik.errors.purchase_price
                      ? "border-[#FF0000]"
                      : "border-gray-200"
                  } `}
                  name="purchase_price"
                  placeholder="Purchase Price"
                  value={formik.values.purchase_price}
                  onChange={(e) => {
                    // it should be a number bigger than 0
                    if (e.target.value >= 0) {
                      formik.setFieldValue("purchase_price", e.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex flex-col space-y-[8px]">
                <label className="text-base font-medium text-gray-900">
                  Currency
                </label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={currenciesOptions}
                  placeholder={"Currency"}
                  className="border-none h-[42px] bg-transparent w-[338px]"
                  name="currency"
                  value={formik.values.currency_id}
                  onChange={(value) => {
                    formik.setFieldValue("currency_id", value);
                  }}
                  onBlur={formik.handleBlur}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: 42,
                      minHeight: 42,
                      width: "100%",
                      backgroundColor: "#F9FAFB",
                      borderRadius: 10,
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : formik.errors.currency_id
                        ? "1px solid #FF0000"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#00A3FF" : "white",
                      color: state.isSelected ? "white" : "black",

                      "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      width: "100%",
                      zIndex: 999,
                      // borderRadius: 10,
                      overflow: "hidden",
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                      height: 42,
                      minHeight: 42,
                    }),
                  }}
                />
              </div>
              <div className="flex flex-col h-[106px] space-y-[8px]">
                <label className="text-base font-medium text-gray-900">
                  Quantity
                </label>
                <input
                  type="number"
                  className={`bg-gray-50 border  h-[42px]  text-gray-500 p-3 rounded-xl ${
                    formik.errors.quantity
                      ? "border-[#FF0000]"
                      : "border-gray-200"
                  }`}
                  name="amount"
                  placeholder="Quantity"
                  value={formik.values.quantity}
                  min={0}
                  onChange={(e) => {
                    // it should be a number bigger than 0
                    if (e.target.value >= 0) {
                      formik.setFieldValue("quantity", e.target.value);
                    }
                  }}
                  // onBlur={formik.handleBlur}
                />
                {/* {formik.touched.amount && formik.errors.amount ? (
                                        <ErrorComponent errorMessage={formik.errors.amount} />
                                    ) : null} */}
              </div>
              <div className="flex flex-col h-[106px] space-y-[8px]">
                <label className="text-base font-medium text-gray-900">
                  Estimated Delivery Date
                </label>
                <input
                  type="date"
                  className={`bg-gray-50 border border-gray-200 h-[42px] text-gray-500 p-3 rounded-xl ${
                    formik.errors.estimate_delivery_date
                      ? "border-[#FF0000]"
                      : "border-gray-200"
                  }`}
                  name="estimate_delivery_date"
                  value={formik.values.estimate_delivery_date}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "estimate_delivery_date",
                      e.target.value
                    );
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            {/* note */}
            <div className="flex flex-col w-full h-[130px] gap-[6px]">
              <label className="text-base font-medium text-gray-900">
                Note <span className="text-xs text-gray-400">(optional)</span>
              </label>
              <textarea
                className="bg-gray-50 border border-gray-200 text-gray-500 p-3 rounded-xl h-[84px]"
                name="comment"
                value={formik.values.note}
                onChange={(e) => {
                  formik.setFieldValue("note", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* <NewSupplyTable missingQty={missingQty} products={products} request={selectedSupply} setProducts={setProducts} /> */}
            <div className="flex justify-end mt-2">
              <div className="mx-1">
                <ArbitButton
                  type="submit"
                  // onClick={() => onSubmit()}
                >
                  <HiCheck className="mr-2 h-5 w-5" />
                  Submit
                </ArbitButton>
              </div>
              <div className="mx-1">
                <ArbitButton
                  bg_color="white"
                  border_color="blue"
                  text_color="blue-600"
                  onClick={() => onClose()}
                >
                  Cancel
                </ArbitButton>
              </div>
            </div>
          </form>
        ),
      }}
    />
  );
};

export default NewSupply;
