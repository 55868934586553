import React from "react";


const Pending = ({count = 10, h = 10}) => (
    <div className={`block w-full h-[${count * (h * 2)}px] relative`}>
        <style>
            {`
                @keyframes skeleton-pulse {
                    0% {
                        left: 0%;
                        transform: translateX(-100%);
                        opacity: 0;
                    }
                    50% {
                        opacity: 1;
                    }
                    100% {
                        left: 100%;
                        transform: translateX(100%);
                        opacity: 0;
                    }
                }
            `}

        </style>
        <div className={`w-full gap-[${h}px] flex flex-col overflow-hidden`}>
            {[...Array(count)].map((item, index) => (
                <div className={`px-[0px] h-[${h}px] w-full relative`} key={index}>
                    <div className="skeleton transition absolute h-full" style={{
                        //height: '15px',
                        //opacity: 0.5 + Math.floor(Math.random()),
                        width: `${Math.floor(Math.random() * 70) + 30}%`,
                        borderRadius: '50px',
                        background: 'linear-gradient(to right, #eeeeee 0%, #dddddd 50%, #eeeeee 100%)',
                        animation: `skeleton-pulse ${(Math.random() * 2) + 0.5}s infinite`
                    }}></div>
                </div>
            ))}
        </div>
    </div>
)

export default Pending;
