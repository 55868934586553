/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Title - a title of arbit product page
 */

import PropTypes from "prop-types"
import ArbitTextDropdown from "../../sub-elements/arbit-text-dropdown";
import Dropdown from "../../sub-elements/dropdown";
import {HiFilter, HiArrowNarrowUp, HiArrowNarrowDown, HiPlus, HiSortAscending} from "react-icons/hi";
import {BsCloudUploadFill} from "react-icons/bs";

const units = ["px", "em", "%"]

const Title = ({
                   children,
                   displayTitle = 'block',
                   marginX,
                   marginY,
                   height,
                   justify,
                   textSize,
                   title,
                   setUpdateType,
                   setVisibleAddModal,
                   setVisibleFilterModal,
                   sort,
                   sortItems,
                   sortFilterHandle,
                   rowData,
                   handleBulkAction,
                   bulkItems,
                   setImportModal,
                   filters,
                   currentSort,
                   DropWidth,
                   InsetValue,
                   filtering,
                   disabled,
                   xposition
               }) => {


    return (
        <div
            className={
                `
        ${displayTitle}
        h-[68px]
        bg-blue-50
        flex 
        justify-between
        items-center        
        px-${units.some(unit => marginX.endsWith(unit))
                    ? '[' + marginX + ']'
                    : marginX}
        h-${units.some(unit => height.endsWith(unit))
                    ? '[' + height + ']'
                    : height} 
      
          mt-[7.52px]
          
        text-${units.some(unit => textSize.endsWith(unit))
                    ? '[' + textSize + ']'
                    : textSize}
        `
            }
        >
            <p className="text-[24px] font-bold text-blue-600">{title}</p>
            <div className="flex justify-center items-end gap-[6px]">
                {
                    setVisibleAddModal && <button className=" title-icon  border p-[4px] h-[33.5px] rounded"
                                                  onClick={() => {
                                                      setUpdateType("Add")
                                                      setVisibleAddModal(true)
                                                  }
                                                  }
                    >
                        <HiPlus className="h-6 w-6"/>
                    </button>
                }
                {
                    setVisibleFilterModal &&
                    <button className={`relative title-icon border p-[4px] rounded ${filtering && 'title-icon-active'}`}
                            onClick={() => {
                                setVisibleFilterModal(true)
                            }}>
                        <HiFilter className="h-6 w-6"/>
                        <div
                            className={`absolute -top-[6px] -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[14px] w-[14px] ${!filtering && 'hidden'}`}>
                            <span></span>
                        </div>
                    </button>
                }

                {
                    setImportModal && <BsCloudUploadFill onClick={() => {
                        setImportModal(true)
                    }} className="title-icon p-[4px] rounded h-[34px] cursor-pointer w-[32px]"/>
                }

                {
                    sortItems && <ArbitTextDropdown.Title
                        title={sortItems && (
                            <div className="relative ">
                                <HiSortAscending
                                    className={`title-icon rounded h-[34px] w-[32px] ${(Object.values(sort).every(value => value === 1) || currentSort === '') ? '' : 'title-icon-active'}`}/>
                                <div
                                    className={`absolute -top-[6px] -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[14px] w-[14px] ${(Object.values(sort).every(value => value === 1) || currentSort === '') && 'hidden'}`}
                                >
                                    <span></span>
                                </div>
                            </div>
                        )}
                        xposition={xposition}
                    >
                        {
                            sortItems?.map((item, index) => (
                                <ArbitTextDropdown.Item
                                    onClick={() => sortFilterHandle(item)}
                                    key={index}
                                >
                                    <div className="flex justify-center hover:border-b-[1px] hover:border-gray-300 ">
                                        <p className="text-[14px] flex items-center leading-[21px] font-[500] text-black hover:text-gray-800">
                                            {item.replace(/_/g, " ")}
                                            {

                                                (sort[item] === 2 && currentSort === item) && <HiArrowNarrowDown/>
                                            }
                                            {
                                                (sort[item] === 3 && currentSort === item) && <HiArrowNarrowUp/>
                                            }
                                        </p>
                                    </div>
                                </ArbitTextDropdown.Item>
                            ))
                        }
                    </ArbitTextDropdown.Title>
                }


                {/* <button className="title-icon border p-[4px] rounded h-[34px] w-[32px]">
          <HiDotsVertical className="h-6 w-6" />
        </button> */}
                {
                    (handleBulkAction && rowData?.length > 0) && <Dropdown
                        bg_color="white"
                        text_color="blue"
                        border_color="blue"
                        width='111px'
                        height='34px'
                        disabled={disabled}
                        value={`Actions 
            ${(rowData?.length > 0) ? `(${rowData?.length})` : ''}
            `}
                        dropWidth={DropWidth ? DropWidth : ''}
                        insetValue={InsetValue ? InsetValue : ''}
                        items={bulkItems ? bulkItems : ["Activate", "Deactivate", "Delete"]}
                        onSelect={(e) => {
                            handleBulkAction(e)
                        }}
                    />
                }

            </div>
        </div>
    )
}

Title.propTypes = {
    marginX: PropTypes.string,
    marginY: PropTypes.string,
    height: PropTypes.string,
    justify: PropTypes.string,
    textSize: PropTypes.string,
    filters: PropTypes.object
}

Title.defaultProps = {
    marginX: "16px",
    marginY: "2",
    height: "36px",
    justify: "start",
    textSize: "24px",
    filters: {}
}

export default Title;
