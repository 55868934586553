import { createSlice } from "@reduxjs/toolkit";
import PageNames from "../../constant/product-constant";

const initialState = {
  navitems: PageNames,
  navrouter: [],
  currentPageUrl: [],
  currentPage: "",
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    nextNavRouting: (state, action) => {
      state.navrouter = [...state.navrouter, action.payload.item];
    },
    prevNavRouting: (state) => {
      state.navrouter = [
        ...state.navrouter.slice(0, state.navrouter.length - 1),
      ];
    },
    resetRouter: (state, action) => {
      state.navrouter = [...action.payload.newRouter];
    },
    homeNavRouting: (state) => {
      state.navrouter = [];
    },
    setCurrentPageUrl: (state, action) => {
      state.currentPageUrl = action.payload.item;
    },
  },
});

export const {
  nextNavRouting,
  prevNavRouting,
  homeNavRouting,
  resetRouter,
  setCurrentPageUrl,
  updateCurrentPage,
} = productSlice.actions;

export default productSlice.reducer;
