import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchInventory = async (params) => {
    let url = constants.APIURL.GET_MAIN_INVENTORY;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
}

export default {
    fetchInventory
}