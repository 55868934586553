import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchChannels = createAsyncThunk(
    "get:api/v1/channel",
    async (payload, thunkAPI) => {
        const response = await api.fetchChannels(payload);
        return response;
    }
);

const fetchChannelsFilters = createAsyncThunk(
    'https://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/channels/filters',
    async (thunkAPI) => {
        const response = await api.fetchChannelsFilters()
        return response.data
    }
)


const deleteChannels = createAsyncThunk(
    "delete:api/v1channel",
    async (payload, thunkAPI) => {
        const response = await api.deleteChannel(payload);
        return response;
    }
);

const restoreChannels = createAsyncThunk(
    "post:api/v1channelRestore",
    async (payload, thunkAPI) => {
        const response = await api.restoreChannel(payload);
        return response;
    }
);

const updateBulkChannelsStatus = createAsyncThunk(
    'patch:/api/v1/channel/status/bulk-action',
    async (payload, thunkAPI) => {
        const response = await api.updateBulkChannelsStatus(payload)
        return response.data;
    }
)
const deleteBulkChannels = createAsyncThunk(
    'delete:/api/v1/channel/bulk-action',
    async (payload, thunkAPI) => {
        // console.log(payload, 'Thunk')
        const response = await api.deleteBulkChannels(payload)
        return response.data;
    }
)

const createChannel = createAsyncThunk(
    'post:/api/v1/channel',
    async (payload, thunkAPI) => {
        const response = await api.createChannel(payload)
        return response.data;
    }
)

const updateChannel = createAsyncThunk(
    'patch:/api/v1/channel',
    async (payload, thunkAPI) => {
        const response = await api.updateChennel(payload)
        return response.data;
    }
)


export {
    fetchChannels,
    fetchChannelsFilters,
    updateBulkChannelsStatus,
    deleteBulkChannels,
    deleteChannels,
    restoreChannels,
    createChannel,
    updateChannel
};