import { createSlice } from "@reduxjs/toolkit";

import {
  addNote,
  createSupplyListing,
  deleteNote,
  fetchNotes,
  fetchSupplyListingFilters,
  fetchSupplyListings,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  supplyChannelListings: [],
  updatedSupplyListing: [],
  updatedBulkSupplyListing: [],
  filtersMeta: {},
  boundFilters: {},
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filters: {
    IdentifierType: [],
    Status: [],
    listingSourceId: [],
  },
  filterLoading: "idle",
};

export const supplyChannelListingsSlice = createSlice({
  name: "supplyChannelListings",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchSupplyListings.fulfilled, (state, action) => {
        state.loading = "idle";
        state.supplyChannelListings = action.payload?.append
          ? [...state.supplyChannelListings, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchSupplyListings.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchSupplyListingFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchSupplyListingFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export const { updateFilter, updateBoundFilter, updateRow, fetchLoading } =
  supplyChannelListingsSlice.actions;

export default supplyChannelListingsSlice.reducer;

export {
  fetchSupplyListings,
  fetchSupplyListingFilters,
  createSupplyListing,
  fetchNotes,
  addNote,
  deleteNote,
};
