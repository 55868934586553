import React, {useEffect, useRef, useState} from 'react';
import DynamicForm from '../../../../../dynamic-form';
import addProductView from './../model-view/addProduct';
import UI from '../../../../../dynamic-form/elements';
import {HiArrowRight} from 'react-icons/hi';
import util from '../../../../../dynamic-page/util';


const AddProduct = React.memo(({callBack, editProduct, itemList, type}) => {

    const formRef = useRef();
    const [formData, setFormData] = useState();


    // ---------------------------------------------------------
    // Add new order item
    // ---------------------------------------------------------

    const handleAddProduct = async () => {
        const formDataValidation = await formRef?.current?.validate(true);
        if (formDataValidation.isValid) {
            // if (!formData?.id) {
            if (!formData) {
                util.notifier({message: 'Please select a product'})
                return
            }
            callBack(formData);
            setFormData(null);
            // formRef.current.reset();
            // } else {
            //     editProduct.callBack(formData); 
            //     setFormData(null);
            // }
        }
    };

    // ---------------------------------------------------------
    // Edit Items
    // ---------------------------------------------------------

    // Set form data if edit order items
    useEffect(() => {
        if (editProduct && editProduct.item) {
            /*const fList = {
                ...editProduct.item,
                skuList: formData.map(i => i.sku),
                listingIdList: formData.map(i => i.listing_id)
            };*/
            setFormData(editProduct.item);
        }
    }, [editProduct]);

    // Cancel
    const handleCancel = () => {
        setFormData(null);
        editProduct.callBack(null);
    };

    // ---------------------------------------------------------

    // Update form data to state
    const handleFormUpdate = (data, bind) => {
        setFormData({
            ...formData,
            qty: 1,
            ...data,
            _data: {...formData?._data, ...bind}
        });
    };


    return (
        <>
            {/* Add Product Form */}
            <div className="grid-container gap-2 flex left-[0px] right-[0px] bottom-[0px] px-[16px] bg-white py-[4px] pb-4">
                {/*Dynamic form*/} 
                <DynamicForm
                    items={addProductView(formData, itemList)}
                    style={{
                        container: {
                            className: 'items-center !w-full !gap-0',
                        },
                        label: {className: 'hidden'},
                        validation: {className: 'hidden'},
                    }}
                    ref={formRef}
                    onChange={handleFormUpdate}
                />
                {/* Conditional styling for selected row */}
                {formData?.id && (
                    <style>
                        {`
                            /* Rest of the selected row styles */
                            #dynamicTableRow_${formData?.id} {background-color: rgba(28,100,242,0.1);}
                        `}
                    </style>
                )}


                {/* Button actions */}
                <div className="justify-center items-center flex flex-col gap-[4px]">

                    

                    <UI.Button
                        item={{
                            label: <div className='flex justify-center gap-2 w-full items-center'>Add
                                product <HiArrowRight className='w-5'/></div>,
                            buttonType: 'alt',
                            className: 'h-[40px] !border-blue-700 !text-blue-700 font-medium !min-w-[154px] !px-5 !py-2.5',
                        }}
                        onClick={() => handleAddProduct()}
                    />

                </div>
            </div>
        </>
    )
})

export default AddProduct