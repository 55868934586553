import React, { useEffect, useState } from "react";
import DynamicTable from "../../../../../dynamic-page";

import tableView from "../model-view/table";
import mapData from "../model-view/mapData";
import getActionList from "../../../../../dynamic-page/util/getActionList";

import updateState from "../../../../../dynamic-form/util/updateState";

import { useDispatch } from "react-redux";
import {
  apiActions,
  createSupplyRequestOrderItem,
} from "../../../../../../store/orders";
import util from "../../../../../dynamic-page/util";

import { IoDuplicate } from "react-icons/io5";
import updateOrderItemRow from "../util/updateOrderItemRow";
import Icons from "../../../../../orders/icons";
import callEndpoint from "../../../../../dynamic-page/util/callEndpoint";

const ProductList = ({
  orderItems,
  actionButton,
  actionCallBack,
  actionEditCallBack,
  actionAddProduct,
  orderId,
  isView,
  data,
  update,
  type,
  status
}) => {
  const [list, setList] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteList, setDeleteList] = useState([]);

  const dispatch = useDispatch();

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    const checkLocalAndView = (item, mode) =>
      item
        ? item?._data?.isView === mode
          ? [item.id]
          : []
        : selectedRow
            .filter((item) => {
              return item?._data?.isView === mode;
            })
            .map((item) => item.id);
    const localItemId = checkLocalAndView(item);
    const viewItemId = checkLocalAndView(item, true);

    setSelectedRow([]);
    return { local: localItemId, view: viewItemId };
  };

  const handleUpdateList = (data) => {
    let newArray = { new: list };
    newArray = updateState(newArray, `new.[id:${data.id}]`, data);
    actionCallBack(newArray.new);
  };

  useEffect(() => {
    actionButton(actions(selectedRow));
  }, [selectedRow]);

  const handleFetchAndUpdateItem = (
    orderId,
    itemId,
    notification,
    callBack
  ) => {
    updateOrderItemRow(orderId, itemId).then((rs) => {
      let newArray = { new: list };
      newArray = updateState(newArray, `new.[id:${itemId}]`, rs);
      actionCallBack(newArray.new);
      util.notifier(notification);
      callBack && callBack();
    });
  };

  const actionList = {
    editItem: {
      label: (
        <div className={"flex flex-row gap-[10px]"}>
          <Icons.edit className={"w-[15px]"} />
          Edit Item
        </div>
      ),
      onClick: (item) => {
        const updateRow = (data) => {
          let newArray = { new: list };
          newArray = updateState(newArray, `new.[product:${data.id}]`, {
            id : list?.find((i) => i.product === data.id)?.id,
            qty: +data.quantity,
            product: data.id,
            _data: {
              product: {
                product: {
                  ...data?.item
                }
              }
            }
          });
          actionCallBack(newArray.new);
        };
        callEndpoint({
          title: "Update Item QTY",
          method: "put",
          url: `transfers/${orderId}/items/${
            item?.order || item?.product
          }/update`,
          data: {
            quantity: item.qty,
          },
          pureData: true,
        }).then((result) => {
          if (result.type === "success") {
            updateRow(result.data);
          }
        });
        },
      bulkDisable: true,
    },
    removeItem: {
      label: (
        <div className={"text-red-600 flex flex-row gap-[10px]"}>
          <Icons.del className={"w-[15px]"} />
          Remove Item
        </div>
      ),
      onClick: (data) => {
        const changelist = giveSelectedItemId(data);
        const deleteLocal = (items) => {
          let newArray = { new: list };
          items.map((i, index) => {
            newArray = updateState(newArray, `new.[id:${i}]`);
          });
          actionCallBack(newArray.new);
        };

        if (isView) {
          callEndpoint({
            title: "Remove Item",
            method: "DELETE",
            url: `transfers/${orderId}/items/${data?.order || data?.product}`,
          }).then((result) => {
            deleteLocal(changelist.local);
          });
        } else {
          deleteLocal(changelist.local);
        }
      },
    },
    connectProduct: {
      label: "Connect Product",
      onClick: (data) => {
        if (data?._data?.isView) {
          const product_id = data?.arbitbox_product_id;
          const connectionAction = product_id
            ? "connect-product"
            : "disconnect-product";
          dispatch(
            apiActions({
              data: { product_id: product_id, sku: data?.sku },
              action: connectionAction,
              orderId: `${data?._data.order_id}/${data?.id}`,
            })
          ).then((result) => {
            //util.notifier(result?.payload?.data)
            handleFetchAndUpdateItem(
              data._data.order_id,
              data.id,
              result?.payload?.data,
              update
            );
            // if (result?.payload?.data?.type === "success") {
            //
            // }
          });
        } else {
          handleUpdateList(data);
        }
      },
    },

    disconnectProduct: {
      label: "Disconnect Product",
      onClick: (data) => {
        const product_id = data?.arbitbox_product_id;
        const connectionAction = "disconnect-product";
        dispatch(
          apiActions({
            data: { product_id: product_id, sku: data?.sku },
            action: connectionAction,
            orderId: `${data?._data.order_id}/${data?.id}`,
          })
        ).then((result) => {
          //util.notifier(result?.payload?.data)
          handleFetchAndUpdateItem(
            data._data.order_id,
            data.id,
            result?.payload?.data
          );
          if (result?.payload?.data?.type === "success") {
            //handleFetchAndUpdateItem(item._data.order_id, item.id, result?.payload?.data)
            //handleUpdateList(data)
          }
        });
      },
    },
  };

  useEffect(() => {
    setList(mapData(orderItems));
  }, [orderItems]);

  const actions = (row) => {
    return getActionList(row);
  };
  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const handleActions = (data) => {
    actionList[data.action].onClick(data.data);
  };

  return (
    <>
      {list && list.length > 0 && (
        <DynamicTable
          view={tableView}
          data={tableView(list, handleActions, isView, type,status)}
          // actions={actions}
          loading="idle"
          selectedRow={selectedRow}
          onSelect={onSelectRow}
          style={{
            hiddenActions: true,
            hideSelect: true,
            outerContainer: "flex-1",
            table: {
              style: {
                width: "100%",
                minWidth: "100%",
                zIndex: 0,
                height: "470px !important",
                maxHeight: "470px !important",
              },
              className: "overflow-auto",
            },
            // tbody: {
            //     className: "border-t border-gray-200 mx-[16px] block"
            // },
            header: {
              className: "justify-start pl-4 pr-4 !py-4 !h-[50px] ",
              span: "!text-[12px]",
              delete: { className: "hidden !pr-8", th: '!w-[62px]' },
              _actions: { td: "hidden", className: "hidden" },
              "carrier": { className: "!justify-center !w-[88px]", th: '!w-[88px]' },
              "order date": { className: "!justify-start !max-w-[133px] !w-[133px] !pl-[32px]" },
              "product": { className: "!justify-start !pl-[32px]" },
              "order Id": {className: "!justify-start !pl-4 !pr-0", th:data?.status_id === '1' ? "!w-[100%]" : ''},
          "price": { className: "!justify-end !w-[88px]", th: '!w-[88px]' },
              image: { className: "hidden" },
              "dim w.": { className: "!justify-end !w-[88px]", th: '!w-[88px]' },
              "actual w.": { className: "!w-[88px] !justify-end", th: '!w-[88px]' },
              "tracking id": {th: "!w-[100%]"},
              qty: { className: "!justify-end" },
              "T. cost": { className: "!justify-end !w-[88px]", th: '!w-[88px]' },
            },
            row: {
              className: "!px-[0px] !py-[10px] !items-center !justify-center flex text-[12px] flex flex-1 h-[54px]",
              _actions: { className: "!py-[0px] hidden" , td: 'hidden' },
              delete: { className: data?.status_id === '1' ?  "!py-[0px] !w-[62px]" : 'hidden' },
              image: { className: "max-h-[54px] !w-[54px] !p-0" },
              "actual w.": { className: "!justify-end !w-[88px] !px-4" },
              "dim w.": { className: "!justify-end !w-[88px] !px-4" },
              "carrier": { className: "!justify-center !w-[88px]" },
              "tracking id": {className: "!justify-start",td: "!w-[100%]"},
              "order date": { className: "!justify-start !max-w-[133px] !w-[133px] !pl-4" },
              "order Id": {className: "!justify-start",td:data?.status_id === '1' ? "!w-[100%]" : ''},
              "price": { className: "!justify-end !w-[88px]" },
              "product": { className: " !pl-8 !min-w-[630px] !pr-2" },
              qty: { className: "!px-4" }, 
              "T. cost": { className: "!justify-end !w-[88px]" },
            },
            noData: {
              className: "hidden",
            },
          }}
        />
      )}
      <style>
        {` 
                    @keyframes deleteAnimation {
                        0% {   opacity: 100% }
                        100% {  opacity: 0% }
                    }
                `}
        {deleteList.length > 0 &&
          deleteList.map((i, index) => {
            setTimeout(function () {
              setDeleteList(
                updateState({ new: deleteList }, `new.[id:${i.id}]`)
              );
            }, 2000);
            return `
                        #dynamicTableRow_${i.id} td > div{
                            animation: deleteAnimation 0.5s linear forwards; overflow: hidden;
                        }
                    `;
          })}
      </style>
    </>
  );
};
export default ProductList;
