import DynamicForm from "../index";
import React, {useEffect, useRef, useState} from "react";
import UI from "../elements";
import * as Yup from "yup";
import {HiTrash} from "react-icons/hi";

const ProductList = ({item, submit}) => {

    const [list, setList] = React.useState(item?.defaultValue || []);
    const connectProductFrom = () => [
        {
            name: "weight_value",
            label: "Enter Weight",
            type: "Number",
            // reset: false,
            // clear: false,
            className: "col-span-3",
            labelClassName: "hidden",
            placeholder: "Enter Weight",
            validation: Yup.string().required("Weight is required"),
        },
        {
            name: "cost",
            label: "Enter Cost",
            type: "Number",
            // reset: false,
            // clear: false,
            className: "col-span-3",
            labelClassName: "hidden",
            placeholder: "Enter Cost",
            validation: Yup.string().required("Cost is required"),
        },
    ];
    const [show, setShow] = useState(false);

    useEffect(() => {
        submit(list);
    }, [list]);


    const myFormRef = useRef();

// Handle form submit.
    const myFormCallBack = async () => {

        let formData = await myFormRef?.current?.getData();
        if (formData) {
            // ------------------------------
            // Add additional data to form data if required.
            // Ex: formData.created_by = userId;
            // ------------------------------

            // Add id to form data if data is not null.
            // if (data) {
            //     formData.id = data.id;
            // }


            // Call onSubmit function to handle form submit.
            setList([
                ...list,
                {
                    cost: formData.cost,
                    weight_value: formData.weight_value,
                },
            ]);
            // submit(list)


        }
    }

    const sortedList = [...list].sort((a, b) => parseFloat(a.weight_value) - parseFloat(b.weight_value));


    return (
        <div className="w-[100%] rounded-lg border border-sky-100 flex-col justify-start items-start max-w-[800px]">
            <style>
                {`
          .fadeOut {
            transition: opacity 500ms ease-out;
            opacity: 0;
          }
        `}
            </style>
            <table className="w-full ">
                <thead className="bg-gray-50">
                <tr className="text-gray-500 text-[14px] h-[36px] font-medium leading-[17.5px]">
                    <th className="text-center text-gray-500 ">KG</th>
                    <th className="text-center text-gray-500 ">Cost</th>
                    <th className="text-center text-gray-500 ">Cost/KG</th>
                </tr>
                </thead>
                {sortedList.length ? (
                    sortedList.map((productItem, index) => (

                        <tbody className={productItem.isRemoving ? 'fadeOut' : ''}>
                        <tr
                            key={index}
                            className="text-gray-900 text-[14px]  font-normal leading-[17.5px]  "
                        >
                            <td className="text-center py-2 w-1/3 border-b  ">{productItem.weight_value}</td>
                            <td className="text-center py-2 w-1/3 border-b  ">${productItem.cost}</td>
                            <td className="text-center py-2 w-1/3 border-b  ">
                                ${(productItem.cost / productItem.weight_value).toFixed(2)}
                            </td>
                            <td className="text-center py-2  justify-center  min-w-[50px] border-b  ">
                                <HiTrash className="text-red-500 cursor-pointer" onClick={() => {
                                    let newList = [...list];
                                    newList[index] = {...newList[index], isRemoving: true};
                                    setList(newList);

                                    setTimeout(() => {
                                        setList(list => list.filter((item, i) => i !== index));
                                    }, 500); // 500 is the duration of the fade-out effect in milliseconds
                                }}/>
                            </td>
                        </tr>
                        </tbody>

                    ))
                ) : (
                    <tbody>
                    <tr>
                        <td colSpan="3" className="text-center py-2 border-b">
                            <div
                                className="p-[16px] flex flex-col justify-center items-center text-gray-300 text-[22px]">
                                Empty!
                                <span className="text-[14px]">No FBA Shipment Costs Yet.</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                )}
            </table>

            {show ? <div className={"p-[8px] grid  grid-cols-8 gap-[8px]"}>
                <DynamicForm
                    items={connectProductFrom()}
                    ref={myFormRef}
                    onChange={(d, bind) => {
                        // setList([
                        //   ...list,
                        //   {
                        //     cost: d.cost,
                        //     weight: d.weight,
                        //   },
                        // ]);

                    }}
                />
                <div className="col-span-2">
                    <UI.Button
                        item={{
                            label: <div className={"flex flex-row"}>+ Add Cost</div>,
                            buttonType: "alt",
                            className: " !px-[16px] col-span-2 rounded-md"
                        }}
                        onClick={() => {
                            myFormCallBack()
                            //   submit(list)
                            setShow(false)
                        }}
                    />
                </div>

            </div> : (
                <div className="w-full py-2 cursor-pointer text-[14px] leading-[21px] text-center text-blue-700"
                     onClick={() => setShow(true)}
                >
                    Add FBM Shipment Cost
                </div>
            )}
        </div>
    );
};

export default ProductList;
