import axios from "axios"
import constants from "../../constant"

const fetchShippingCost = async (params) => {
    let url = constants.APIURL.GET_SHIPPING_COST
    if (params) url = url.concat(params);

    const response = await axios.get(url,
        {withCredentials: true}
    )
    return response?.data;
}

const searchShippingCost = async (params) => {
    let url = constants.APIURL.GET_SHIPPING_COST + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteShippingCost = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_SHIPPING_COST + payload, {withCredentials: true})
    return response?.data
}

const fetchShippingCostFilters = async (params) => {
    let url = constants.APIURL.GET_SHIPPING_COST_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const bulkDeleteShippingCost = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_SHIPPING_COST, {
        withCredentials: true,
        data: payload
    });
    return response?.data
};


export default {
    fetchShippingCost,
    deleteShippingCost,
    fetchShippingCostFilters,
    bulkDeleteShippingCost,
    searchShippingCost,
}