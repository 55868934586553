import { createSlice } from "@reduxjs/toolkit";
import { fetchCalculatorData } from "./thunk";

const initialState = {
  data: [],
  status: "idle",
  calculatorValues: {},
  billableWeight: null,
  xClicked: false,
  changedSelect:false,
};

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    removeValues: (state) => {
      state.data = [];
      state.status = "idle";
      state.calculatorValues = {};
      state.xClicked = false;
    },
    updateSelect:(state,action)=>{
      state.changedSelect=action.payload
    },
    updateCalculatorValues: (state, action) => {
      state.calculatorValues = action.payload;
    },
    updateXButton: (state, action) => {
      state.status = "idle";
    },
    updateBillableWeight: (state, action) => {
      state.billableWeight = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalculatorData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        if (action.payload.message) {
          state.status = "rejected";
        } else {
          state.status = "succeeded";
        }
      })
      .addCase(fetchCalculatorData.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchCalculatorData.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export const {
  removeValues,
  updateCalculatorValues,
  updateXButton,
  updateBillableWeight,
  updateSelect,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
