/**
 * @author Austin Ames
 * @version 0.1.0
 * @params Object - Product Summary
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "../sub-elements/dropdown";
import ArbitBadge from "../sub-elements/badge";
import ArbitEditableText from "../sub-elements/editable-text";
import ArbitLoading from "../sub-elements/loading";
import { toast } from "react-toastify";
import {
  addProductImages,
  deleteProduct,
  deleteProductImage,
  duplicateProduct,
  fetchProduct,
  getBrands,
  UpdateProduct,
} from "../../store/products";
import moment from "moment";
import ArbitProductDeleteModal from "./Product-delete-modal";
import ArbitUpload from "../sub-elements/upload-button";
import ArbitImageContainer from "../sub-elements/image-container";
import copy from "copy-to-clipboard";
import axios from "../../middleware/axios";
import constants from "../../constant";
import quantityHandler from "../../utils/quantityHandler";
import util from "../dynamic-page/util";
import HandmadeSkeleton from "../../utils/skeleton";
import { BsThreeDotsVertical } from "react-icons/bs";
import descriptiveContent from "../../utils/descriptiveContent";
import priceHandler from "../../utils/priceHandler";
import { IoDuplicate } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import Icons from "../orders/icons";
import Util from "../dynamic-page/util";

const ProductHeader = ({
  datas,
  data,
  groupData,
  id,
  productLanguage,
  listingCount,
}) => {
  const navigate = useNavigate();
  const brands = useSelector((state) => state.productReducer.brands);
  const units = useSelector((state) => state.units.units);
  const purchase = useSelector((state) => state.productReducer.purchase);
  const purchaseStatus = useSelector(
    (state) => state.productReducer.purchaseStatus
  );
  const dispatch = useDispatch();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  let lnk = window.location.href;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getBrands());
  }, []);

  const onChange = (lastvalue) => {
    updateProduct(lastvalue, datas.brand_id, datas?.unit?.id);
  };

  const onBrandChange = (lastvalue) => {
    updateProduct(datas?.title, lastvalue.value, datas?.unit?.id);
  };

  const onUnitChange = (lastvalue) => {
    updateProduct(datas?.title, datas?.brand_id, lastvalue.value);
  };

  const updateProduct = async (productTitle, productBrands, unit_id) => {
    const payload = {
      type: datas?.type,
      // "category_id": datas?.category_id,
      title: productTitle,
      brand_id: productBrands,
      ...(unit_id && { unit_id: unit_id }),
    };
    await dispatch(UpdateProduct({ id, payload }))
      .unwrap()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchProduct({ id, productLanguage }));
          toast.success(response?.data?.message);
        } else if (response?.status === 422) {
          util.notifier(response?.data);
        }
      });
  };

  const handleProductDeletion = async () => {
    // setLoading(true);
    await dispatch(deleteProduct(id)).then(({ payload }) => {
      util.notifier(payload?.data);

      // setLoading(false);
      if (payload?.status === 200) {
        setDeleteModalVisible(false);
        navigate("/product/list");
      }
    });
  };
  const getSumOfProducts = (arr) => {
    let sum = 0;
    arr?.map((item) => {
      sum += item?.cost;
    });
    return sum;
  };

  const handleProductDuplicate = async () => {
    setLoading(true);
    await dispatch(duplicateProduct(id)).then(({ payload }) => {
      setLoading(false);
      if (payload?.status === 200) {
        toast.success("Product has been duplicated successfully.", {
          toastId: "success1",
        });
      } else {
        util.notifier(payload?.data);
      }
    });
  };

  const handleAction = (item) => {
    // console.log(item, 'Delete')

    switch (item) {
      case "Delete":
        setDeleteModalVisible(true);
        break;
      case "Duplicate":
        handleProductDuplicate(id);
        break;
      default:
        break;
    }
  };

  const handleHeaderFileChange = async (e) => {
    var files = e.target.files;

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images[]", files[i]);
      formData.append("is_main", true);
    }

    await dispatch(addProductImages({ id, formData }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(fetchProduct({ id, productLanguage }));
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        } else {
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        util.notifier(err);
      });
  };

  const onCopyUrl = (url) => {
    copy(url);
    toast.info("Copied to clipboard", {
      toastId: "success1",
    });
  };
  const onDelete = async (imageId) => {
    const payload = {
      is_main: true,
    };
    await dispatch(deleteProductImage({ id, imageId, payload }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(fetchProduct({ id, productLanguage }));
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        } else {
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        util.notifier(err);
      });
  };

  const mapOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: option?.name,
    }));
  };

  const fetchBrands = async (params) => {
    let url = constants.APIURL.GET_BRANDS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    // console.log(response);
    return response.data;
  };

  const loadBrandsOptions = async (inputValue) => {
    let url;

    url = "?term=" + inputValue;

    // console.log(url);
    return new Promise((resolve) => {
      fetchBrands(url)
        .then((response) => {
          // console.log(response);
          const options = mapOptionsToValues(response.data);
          // console.log(options);
          // setProducts(options)
          resolve(options);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  };

  const fetchUnits = async (params) => {
    let url = constants.APIURL.GET_UNITS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    // console.log(response);
    return response.data;
  };
  const [isClicked, setClicked] = useState(false);

  const loadUnitsOptions = async (inputValue) => {
    let url;

    url = "/search?term=" + inputValue;

    // console.log(url);
    return new Promise((resolve) => {
      fetchUnits(url)
        .then((response) => {
          // console.log(response);
          const options = mapOptionsToValues(response.data);
          // console.log(options);
          // setProducts(options)
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  return (
    <>
      <div className={`flex w-full overflow-x-auto relative   `}>
        <div className=" flex-shrink-0 flex justify-center items-center">
          {
            <ArbitImageContainer
              src={datas?.image}
              key={1}
              onCopyUrl={onCopyUrl}
              sourceId={datas?.id}
              onDelete={onDelete}
            />
          }
        </div>
        <div
          className={`flex flex-col flex-[1_0_0] p-2 gap-2 flex-grow space-y-[8px] min-w-[500px] overflow-x-hidden `}
        >
          <div className="flex justify-between  items-center ">
            <div className="flex space-x-[16px] w-[calc(100%-60px)] trun whitespace-pre text-[24px]  font-[600] text-gray-800">
              <Util.truncateText
                tooltip={true}
                hideCopy={true}
                innerClassName="whitespace-normal	"
                tooltipClassName="whitespace-pre-wrap max-w-[700px] break-words  "
              >
                {datas?.title}
              </Util.truncateText>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => {
                  handleProductDuplicate(id);
                }}
                className=" "
              >
                <Icons.fileCopy className="w-5 h-5  text-gray-300 hover:text-gray-500" />
              </button>
              <button
                onClick={() => {
                  setDeleteModalVisible(true);
                }}
                className=" "
              >
                <Icons.trash className="w-5 h-5 text-gray-300 hover:text-gray-500" />
              </button>
            </div>
          </div>
          <div className={`grid grid-cols-5 gap-[4px] !mt-0  `}>
            <ArbitBadge
              title="APID"
              value={datas?.["apid"]}
              bgColor="indigo-50"
              copy={true}
              tooltip={{
                title: "Arbitbox Product ID",
                desc: "A unique identifier for each product in Arbitbox, ensuring accurate tracking, management, and integration across all modules.",
                position: "bottom",
              }}
            />
            <ArbitBadge
              title="BRAND"
              value={datas?.brand?.name}
              onChange={onBrandChange}
              options={brands}
              loadOptions={loadBrandsOptions}
              placeholder={"Search Brands"}
              tooltip={{
                title: "Brand",
                desc: "Represents the manufacturer or label associated with a product in Arbitbox, ensuring clear identification and categorization.",
                position: "bottom",
              }}
            />
            <ArbitBadge
              title="W. AVG. COST"
              value={
                <span>
                  {datas?.average_cost_usd
                    ? "$" + priceHandler(datas?.average_cost_usd)
                    : "-"}
                </span>
              }
              tooltip={{
                title: "Weighed Average Cost",
                desc: "Represents the weighted average supply cost over the last 6 months, considering quantity and dollar value on the supply date.",
                position: "bottom",
              }}
            />

            <ArbitBadge
              title="WAREHOUSE INV."
              value={
                datas?.inventory_count
                  ? quantityHandler(datas?.inventory_count, datas?.unit)
                  : "-"
              }
              tooltip={{
                title: "Warehouse Inventory",
                desc: "Indicates the total quantity of products available across all warehouses.",
                position: "bottom",
              }}
            />
            <ArbitBadge
              title="L. PURCHASE PRICE"
              value={
                datas?.last_purchase_cost ? (
                  <span>${priceHandler(datas?.last_purchase_cost)}</span>
                ) : (
                  "-"
                )
              }
              secondValue={
                datas?.last_purchase_cost_try ? (
                  <span className="text-gray-500">
                    {priceHandler(datas?.last_purchase_cost_try)}₺
                  </span>
                ) : (
                  "-"
                )
              }
              tooltip={{
                title: "Last Purchase Price",
                desc: "Indicates the most recent price paid for the product.",
                position: "bottom",
              }}
            />
            <ArbitBadge
              title="PRODUCT TYPE"
              value={datas?.type === 1 ? "Single Product" : "Grouped Product"}
              bgColor="indigo-50"
              tooltip={{
                title: "Product Type",
                desc: (
                  <div>
                    <span className=" font-bold ">Single products</span> are
                    individual items, while{" "}
                    <span className=" font-bold ">Grouped products</span> are
                    combinations of multiple Single products.
                  </div>
                ),
                position: "top",
              }}
            />
            <ArbitBadge
              title="BRAND ASSIGN"
              value={
                datas?.brand?.assigned?.name === undefined
                  ? "-"
                  : datas?.brand?.assigned?.name
              }
              tooltip={{
                title: "Brand Assign",
                desc: "Each brand in Arbitbox is assigned to a specific user responsible for managing and maintaining that brand’s products.",
                position: "top",
              }}
            />
            <ArbitBadge
              title="T. INV. VALUE"
              value={
                <span>
                  {(datas?.average_cost_usd || 0) *
                  ((datas?.inventory_count || 0) +
                    (listingCount?.reserved_quantity !== 0)) ? (
                    <span>
                      $
                      {priceHandler(
                        (datas?.average_cost_usd || 0) *
                          ((datas?.inventory_count || 0) +
                            (listingCount?.reserved_quantity !== 0))
                      )}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </span>
              }
              tooltip={{
                title: "Total Inventory Value",
                desc: (
                  <span className="flex flex-col gap-2">
                    Represents the total value of all products in inventory,
                    calculated based on their Weighted Average Cost (WAC).
                    <span>TIV = WAC x Total Inventory (WH + FC)</span>
                  </span>
                ),
                position: "top",
              }}
            />
            <ArbitBadge
              title="FC INVENTORY"
              value={
                listingCount?.available_quantity +
                  listingCount?.reserved_quantity !==
                0
                  ? listingCount?.available_quantity +
                    listingCount?.reserved_quantity
                  : "-"
              }
              tooltip={{
                title: "Fulfillment Center Inventory",
                desc: "Indicates the total quantity of products available in Fulfillment Centers.",
                position: "top",
              }}
            />
            <ArbitBadge
              title="L. PURCHASE QTY."
              value={
                purchaseStatus !== "pending" ? (
                  purchase?.data && purchase?.data[0]?.quantity ? (
                    <span>{purchase?.data[0]?.quantity}</span>
                  ) : (
                    "-"
                  )
                ) : (
                  <HandmadeSkeleton width={90} height={25} />
                )
              }
              tooltip={{
                title: "Last Purchase Quantity",
                desc: "Indicates the quantity of the product in the most recent purchase.",
                position: "top",
              }}
            />
            {/* <ArbitBadge title="Unit" value={datas?.unit?.name} editable onChange={onUnitChange} options={units} loadOptions={loadUnitsOptions} placeholder={'Search Units'}/> */}
          </div>
        </div>
      </div>
      {loading && <ArbitLoading />}
      {deleteModalVisible && (
        <ArbitProductDeleteModal
          name={datas?.title}
          show={deleteModalVisible}
          center={true}
          onClose={() => setDeleteModalVisible(false)}
          handleProductDeletion={() => handleProductDeletion()}
        />
      )}
    </>
  );
};

ProductHeader.defaultProps = {
  data: {
    img: "assets/images/product/insulin injector.png",
    title: "Novofine 6mm * 31g 100ctmm",
    created_at: "22.10.2021",
    created_by: "Gamz Sahin",
    apid: "APS438k123",
    product_type: "Single Product",
    brand: "NOVOFINE",
    assign: "Gamz Sahin",
    inv_on_hand: "482",
    suppliable_quantity: "20",
    supplied_price: "$4.82",
    suppliable_price: "$6.5",
  },
};

export default ProductHeader;
