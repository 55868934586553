import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchUsers = createAsyncThunk(
    "get:api/v1/users",
    async (payload, thunkAPI) => {
        const response = await api.fetchUsers(payload);
        return response.data;
    }
);
const addUser = createAsyncThunk(
    'post:api/v1/user',
    async (payload) => {
        // console.log(payload, 'thunk 2nd console');
        const response = await api.addUser(payload)
        return response.data
    }
)
const updateUser = createAsyncThunk(
    'post:api/v1/user/:id',
    async ({id, payload}) => {
        // console.log(id, payload, 'thunk now');
        const response = await api.updateUser(id, payload)
        return response.data
    }
)
const fetchUsersFilters = createAsyncThunk(
    "get:api/v1/users/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchUsersFilters(payload);
        // console.log(response, 'Users Filters Thunk')
        return response;
    }
);
const fetchUsersPermissions = createAsyncThunk(
    'get:api/v1/user/:id/permissions',
    async (id, thunkAPI) => {
        // console.log(id,thunkAPI, 'thunk now');
        const response = await api.fetchUsersPermissions(id)

        return response
    }
)

const bulkUpdateUser = createAsyncThunk(
    'patch:api/v1/user/bulk-update',
    async (payload) => {
        // console.log(payload, 'thunk 2nd console');
        const response = await api.bulkUpdateUser(payload)
        // console.log(response, 'thunk 2nd console');
        return response.data
    }
)

const fetchAudits = createAsyncThunk(
    'get:api/v1/user/:id/audits',
    async (id, thunkAPI) => {
        // console.log(id,thunkAPI, 'thunk now');
        const response = await api.fetchAudits(id)

        return response
    }
)

const createDepartment = createAsyncThunk(
    'post:api/v1/department',
    async (payload) => {
        // console.log(payload, 'thunk 2nd console');
        const response = await api.createDepartment(payload)
        // console.log(response, 'thunk 2nd console');
        return response.data
    }
)

const fetchUserActivities = createAsyncThunk(
    'get:api/v1/:id/activities',
    async (id) => {
        const response = await api.fetchUserActivities(id)
        return response.data
    }
)

// const createPosition = createAsyncThunk(
//   'post:api/v1/position',
//   async (payload) => {
//     // console.log(payload, 'thunk 2nd console');
//     const response = await api.createPosition(payload)
//     // console.log(response, 'thunk 2nd console');
//     return response.data
//   }
// )


// const fetchAccountsFilters = createAsyncThunk(
//   'https://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/channels/filters',
//   async (thunkAPI) => {
//     const response = await api.fetchAccountsFilters()
//     return response.data
//   }
// )


export {
    fetchUsers,
    fetchUsersFilters,
    addUser,
    updateUser,
    fetchUsersPermissions,
    bulkUpdateUser,
    fetchAudits,
    createDepartment,
    fetchUserActivities
};