function blobToBase64(data) {
    return new Promise((resolve) => {
        if (data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result); // Don't remove the initial part of the data URL
            };
            reader.readAsDataURL(data);
        } else {
            resolve(data);
        }
    });
}

export default blobToBase64;