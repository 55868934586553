const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Created At",
                value: "created_at",
                onClick: (item) => {
                    callback("created_at");
                }
            },
            {
                label: "Estimated Delivery Date",
                value: "estimated_delivery_date",
                onClick: (item) => {
                    callback("estimated_delivery_date");
                }
            },
            {
                label: "Product",
                value: "product",
                onClick: (item) => {
                    callback("product");
                }
            },
            {
                label: "Supplier",
                value: "supplier",
                onClick: (item) => {
                    callback("supplier");
                }
            },
            {
                label: "Created By",
                value: "created_by",
                onClick: (item) => {
                    callback("created_by");
                }
            },
            {
                label: "Accepted By",
                value: "accepted_by",
                onClick: (item) => {
                    callback("accepted_by");
                }
            },
            {
                label: "Purchase Price",
                value: "purchase_price",
                onClick: (item) => {
                    callback("purchase_price");
                }
            },
            {
                label: "Quantity",
                value: "quantity",
                onClick: (item) => {
                    callback("quantity");
                }
            },
            {
                label: "Subtotal",
                value: "subtotal",
                onClick: (item) => {
                    callback("subtotal");
                }
            },
            {
                label: "Total",
                value: "total",
                onClick: (item) => {
                    callback("total");
                }
            },
            {
                label: "Total Due",
                value: "total_due",
                onClick: (item) => {
                    callback("total_due");
                }
            },
        ]
    };
};

export default sortView;
  