import axios from "./../../middleware/axios";
import {api_endpoint} from "../../config";
import constants from "../../constant";

const fetchLoginUser = async (payload) => {
    const formData = new FormData();
    formData.append("email", payload.user.email);
    formData.append("password", payload.user.password);
    await axios.get(constants.APIURL.CSRF_TOKEN, {withCredentials: true});
    const response = await axios.post(constants.APIURL.LOGIN_URL, formData);
    return response;
};

export const setToken = (token) => {
    localStorage.setItem(api_endpoint + ":usertoken", token);
};

const fetchRegisterUser = async (payload) => {
    const response = await axios.post(constants.APIURL.SIGNUP_URL, payload, {
        withCredentials: true,
    });
    return response;
};

const fetchUserwithToken = async () => {
    const response = await axios.get(constants.APIURL.ME_URL, {
        withCredentials: true,
    });
    return response;
};

const postUserLogout = async () => {
    const response = await axios.post(constants.APIURL.LOGOUT_URL, {
        withCredentials: true,
    });
    return response;
};

const clearToken = () => {
    localStorage.removeItem(api_endpoint + ":usertoken");
};

export const getToken = () => {
    return localStorage.getItem(api_endpoint + ":usertoken");
};

export const setIsLogin = (isLogged) => {
    return localStorage.setItem("isLogin", isLogged);
};
export const dropIsLogin = () => {
    return localStorage.setItem("isLogin", undefined);
};

export default {
    getToken,
    clearToken,
    setToken,
    fetchLoginUser,
    fetchRegisterUser,
    fetchUserwithToken,
    postUserLogout,
};
