import React from "react";
import calculateTotalPrices from "./calculateTotalPrice";
import priceHandler from "../../../../../../utils/priceHandler";
import filterObjectByKeys from "../../../../../dynamic-page/util/filterObjectByKeys";


// Utility function to calculate total prices for each item paid
const itemsPaid = (orderItems) => {
    return orderItems?.length > 0 ? (
        Object.values(calculateTotalPrices(orderItems)).map((item, index) => (
            <span className="flex justify-end w-full" key={index}>
        {priceHandler(item?.total, item.currency.data)}
      </span>
        ))
    ) : null;
};


// Utility function to calculate the total quantity of order items
const totalQty = (orderItems) => {
    return orderItems?.reduce((acc, item) => {
        if (item?.unit_price < 0) return acc;
        return acc + Number(item?.qty);
    }, 0);
};


// Utility function to calculate the total paid amount
const totalPaid = (orderItems, summaryForm) => {


    let orders = orderItems?.length > 0 ? orderItems : [];

    orders = orders.map(item => {
        return (
            filterObjectByKeys(item, [
                "unit_price",
                "qty",
                "_data.unit_price_currency.data:unit_price_currency",
                "_data"
            ])
        )
    })
    const ship = {
        unit_price: summaryForm?.shipping_paid || 0,
        qty: 1,
        unit_price_currency:
            summaryForm?._data?.sales_channel_account_id?.data?.currency || null,
        _data: {unit_price_currency: {data: summaryForm?._data?.sales_channel_account_id?.data?.currency || null}},
    };

    const totalPaidPrice = calculateTotalPrices([
        ...orders,
        ship?.unit_price_currency && ship?.unit_price ? ship : null,
    ]);

    return Object.keys(totalPaidPrice)?.length > 0 ? (
        Object.values(totalPaidPrice)?.map((item, index) => (
            <span className="flex justify-end w-full" key={index}>
                {priceHandler(item?.total, item?.currency?.data)}
            </span>
        ))
    ) : null;

};


// Exporting the utility functions as an object
const orderUtils = {
    itemsPaid, // Calculate total prices for each item paid
    totalQty, // Calculate the total quantity of order items
    totalPaid, // Calculate the total paid amount
};

export default orderUtils;
