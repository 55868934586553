import upperCase from "../../dynamic-page/util/upperCaseString";

const filtersView = (selectedFilters, filtersMeta) => {
    return (

        [
            {
                name: "provider",
                label: "Provider",
                placeholder: "Select Provider",
                type: "Select",
                list: filtersMeta?.data?.providers?.map((item) => ({
                    label: upperCase(item?.name),
                    value: item?.id,
                    count: item?.count,
                })),
                features: {
                    formatOptionLabel: ({label, count}) => {
                        return (
                            <div
                                className={
                                    "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                                }
                            >
                                <span>{upperCase(label, 'first')}</span>
                                <span
                                    className={
                                        "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                                    }
                                >
                          {count}
                        </span>
                            </div>
                        );
                    },
                },
                className: '!bg-white',
                style: {
                    control: {
                        backgroundColor: "white !important",
                    }
                },
                defaultValue: selectedFilters?.provider || null,
                toggle: true,
                clear: true,
            },
            {
                name: "code",
                label: "Code",
                placeholder: "Filter by Code",
                type: "Input",
                defaultValue: selectedFilters?.code,
                toggle: true,
                clear: true,
            },


        ]
    )
}

export default filtersView;