import React from "react";
import moment from "moment";
import {howLongFromNow} from "../../../utils/helper";
import Util from "../../dynamic-page/util";
import Components from "../components";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import FlagIcon from "../../sub-elements/country-flags";
import descriptiveContent from "../../../utils/descriptiveContent";

const TableView = (list, handleTableAction) => {

    const nullHandle = (data, chained) => {
        if (!data || !chained) {
            return "-";
        } else {
            return chained;
        }
    };

    return {
        list: list,
        theme: {
            status: (item) => (
                <div className="flex flex-col space-y-[4px] justify-center items-center w-full h-full">
                    {item?.status === 1 && <ArbitNormalBadge title={"Active"}/>}
                    {item?.status === 0 && (
                        <ArbitNormalBadge title={"Inactive"} bgColor="purple-100"/>
                    )}
                    {item?.status === 2 && (
                        <ArbitNormalBadge title={"Deleted"} bgColor="red-100"/>
                    )}
                    {item?.status === null && <ArbitNormalBadge title={"Active"}/>}
                </div>
            ),

            "last update": (item) => (
                <Util.dateDisplay item={item} date={item?.updated_at} />
            ),
            channel: (item) => (
                <div className="flex  justify-start max-h-[64px] w-[100%] items-start gap-1.5 ">
                    <img
                        src={item?.channel?.marketplace?.logo || "/assets/images/noImagheWide.jpg"}
                        className="w-[20px] h-[20px]  object-contain"
                    />
                    <span>{item?.channel?.name}</span>
                </div>
            ),

            "account name": (item) => (
                <div
                    className={"w-[227px]  flex flex-row gap-[16px] justify-start"}
                    style={{width: "100%"}}
                >
                    <Components.item
                        product={[item?.product]}
                        orderId={item?.id}
                        offer={{title: item.name, sku: item?.email}}
                    />
                </div>
            ),
            "ioss number": (item) => (
                <div
                    className={"w-[227px]  flex flex-row gap-[16px] justify-center"}
                    style={{width: "100%"}}
                >
                    <span>{item?.ioss_number ?? "-"}</span>
                </div>
            ),

            listings: (item) => (
                <div className="w-[132px] h-16   flex-col justify-center items-start gap-1 inline-flex">
                    <div
                        className="text-blue-700 text-xl font-semibold font-['Inter'] leading-tight cursor-pointer"
                        onClick={() => {
                            if (item?.channel?.marketplace?.id === 2) {
                                // open sales listings in a new page
                                window.open(
                                    `/listing/sales_listings?filter[account]=${item?.id}`,
                                    "_blank"
                                );
                            }
                        }}
                    >
                        {descriptiveContent(
                            item?.channel?.marketplace?.id === 2
                                ? item?.listings_count
                                    ? item?.listings_count
                                    : "-"
                                : "-",
                            "Total listings"
                        )}
                    </div>
                    {descriptiveContent(
                        <div className="text-gray-600 text-xs font-normal font-['Inter'] leading-3">
                            on
                            {item?.channel?.marketplace?.id === 2 ? (
                                item?.offers_count ? (
                                    <span className="mx-1">{item?.offers_count}</span>
                                ) : (
                                    " - "
                                )
                            ) : (
                                " - "
                            )}
                            Offers
                        </div>,
                        "Total offers"
                    )}
                </div>
            ),

            "main target audience": (item) => (
                <div className="w-[208px] h-16  flex-col justify-center items-start gap-1 inline-flex">
                    <div className="self-stretch justify-between items-start inline-flex">
                        {item?.currency ?
                            <div className="text-gray-900 text-sm font-bold font-['Inter'] leading-[21px]">
                                {item?.currency?.symbol} {item?.currency?.code}
                            </div> : <div>-</div>}
                        {item?.language ? <div className="justify-center items-center gap-1 flex">
                            <FlagIcon
                                code={
                                    item?.language?.code == "en" ? "us" : item?.language?.code
                                }
                                className="w-3.5 h-2.5"
                            />
                            <div className="text-gray-900 text-sm font-normal font-['Inter'] leading-[21px]">
                                {item?.language?.name}
                            </div>
                        </div> : <div>-</div>}
                    </div>
                    {item?.country ? <div className="justify-center items-center gap-1 inline-flex">
                        <img className="w-7 h-5" src={item?.country?.flag}/>
                        <div className="text-gray-900 text-sm font-normal font-['Inter'] leading-[14px]">
                            {item?.country?.name}
                        </div>
                    </div> : '-'}
                </div>
            ),
            note: (item) => (
                <div
                    className="w-[100%] max-w-[350px] max-h-[32px] min-w-[200px]  text-gray-600 text-xs font-normal font-['Inter'] break-words leading-[18px] ">
                    {item?.note ? (
                        item.note.length > 100 ? (
                            <Util.popUp
                                toggle={() => <p>{item.note.substring(0, 90)}...</p>}
                                action={"hover"}
                            >
                                <div className="max-w-[300px] break-words p-2">{item.note}</div>
                            </Util.popUp>
                        ) : (
                            item.note
                        )
                    ) : (
                        "-"
                    )}
                </div>
            ),
            files: (item) => (
                <div className="w-[120px] h-16   flex-col justify-center items-start gap-0.5 inline-flex">
                    <div
                        className={`${
                            item?.documents_count
                                ? "text-blue-700 cursor-pointer "
                                : "text-gray-500"
                        } text-base font-medium font-['Inter'] leading-normal`}
                        onClick={() => {
                            if (item?.documents_count) {
                                handleTableAction("download", item);
                            }
                        }}
                    >
                        {item?.documents_count
                            ? `Show (${item?.documents_count})`
                            : "No files"}
                    </div>
                    <div
                        className="text-blue-700 text-xs font-normal cursor-pointer font-['Inter'] leading-[18px]"
                        onClick={() => {
                            handleTableAction("download", item);
                        }}
                    >
                        Upload new
                    </div>
                </div>
            ),
        },
    };
};
export default TableView;
