// getActionsList.js
// -----------------------------------------

const getActions = (row = [], actions, pageData, onlySomeMetCondition) => {
  // Get list row.
  const list = !row ? [] : Array.isArray(row) ? row : [row];

  // -----------------------------------------
  // Conditionally remove actions from list.
  // You can add more conditions here.

  const actionsList = Object.values(actions).filter((item) => {
    // Check if the action should be disabled for bulk operations
    if (item?.bulkDisable === true && list?.length > 1) return false;
    // Check if the action should be disabled for single operations
    if (item?.bulkOnly === true && list?.length === 1) return false;
    // check if actions has a condition
    if (item?.condition !== undefined) {
      if (typeof item?.condition === "function") {
        return onlySomeMetCondition
          ? list.some((child) => item.condition(child, pageData))
          : list.every((child) => item.condition(child, pageData));
      } else {
        return item?.condition;
      }
    }
    return true;
  });
  // -----------------------------------------

  // Return available actions.
  return { count: list.length, list: actionsList };
};

export default getActions;
