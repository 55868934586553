const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Name",
                value: "name",
                onClick: (item) => {
                    callback("name");
                }
            },
            {
                label: "Created At",
                value: "created_At",
                onClick: (item) => {
                    callback("created_At");
                }
            },
            {
                label: "Last Update",
                value: "updated_at",
                onClick: (item) => {
                    callback("updated_at");
                }
            },
            {
                label: "SKU",
                value: "sku",
                onClick: (item) => {
                    callback("sku");
                }
            },


        ]
    }
}

export default sortView;