import React from 'react';
import {BsFillExclamationCircleFill, BsFillExclamationTriangleFill} from 'react-icons/bs';
import Icons from '../../../orders/icons';

const TransferType = ({product, order, noType}) => {

    return (
        <div className={` h-[20px] px-2 ${
            product ? 'bg-blue-100' : ''
        } ${
            order ? 'bg-green-100' : ''
        } ${
            noType ? 'bg-gray-100' : ''
        }  rounded-md gap-1 flex justify-center items-center`} >
            {
                product ? <Icons.archive_box className="text-blue-900 w-3 h-3"/> : ''
            }
            {
                order ? <Icons.archive_box className="text-green-900 w-3 h-3"/> : ''
            }
            {
                noType ? <Icons.archive_box className="text-gray-900 w-3 h-3"/> : ''
            }
            <div className={`text-center ${
                product ? 'text-blue-900' : ''
            } ${
                order ? 'text-green-900' : ''
            } ${
                noType ? 'text-gray-900' : ''
            } text-xs font-medium  leading-[18px]`}>
                {product ? "Product Transfer" : ''}
                {order ? "Order Transfer" : ''}
                {noType ? "No Items Yet" : ''}
            </div>
        </div>
    )
}
//${colorMapping[color]}
export default TransferType;