export const currency = {
    "USD": "$",
    "EUR": "€",
    "GBP": "£",
    "JPY": "¥",
    "AUD": "$",
    "CAD": "$",
    "CHF": "CHF",
    "CNY": "¥",
    "HKD": "$",
    "NZD": "$",
    "SEK": "kr",
    "SGD": "$",
    "NOK": "kr",
    "MXN": "$",
    "ILS": "₪",
    "ZAR": "R",
    "TRY": "₺",
}
