import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";


const fetchRows = createAsyncThunk(
    'get:api/v1/dynamicPage/fetchRows',
    async (params, thunkAPI) => {
        if (!params.append) thunkAPI.dispatch(fetchLoading());
        const response = await api.fetchRows(params);
        return {data: response.data , append : params.append}
    }
)

const fetchFilters = createAsyncThunk(
    "get:api/v1/dynamicPage/fetchFilters",
    async (params) => {
        const response = await api.fetchFilters(params);
        return response;
    }
);

export {fetchRows, fetchFilters}