import React from "react";
import ArbitNormalBadge from "../../sub-elements/normal-badge";

const tableView = (list, callBack) => {

    const nullHandle = (data, chained) => {
        if (!data || !chained) {
            return "";
        } else {
            return chained;
        }
    };
    return {
        list: list ?? [],
        theme: {
            status: (item) => (
                <div className="flex flex-col space-y-[4px] justify-center items-center w-full h-full">
                    {
                        item?.status === 1 && <ArbitNormalBadge title={"Active"}/>
                    }
                    {
                        item?.status === 0 && <ArbitNormalBadge title={"Inactive"} bgColor="purple-100"/>
                    }
                    {
                        item?.status === 2 && <ArbitNormalBadge title={"Deleted"} bgColor="red-100"/>
                    }
                    {
                        item?.status === null && <ArbitNormalBadge title={"Active"}/>
                    }
                </div>
            ),
            'unit name': (item) => (
                <div className="flex flex-col h-[40px] space-y-[4px] justify-center items-center w-full ">
                    <p className="text-[16px] font-[400] text-gray-900 text-center">{item?.name}</p>
                </div>
            ),

            'unit code': (item) => (
                <div className="flex flex-col space-y-[4px] justify-center items-center w-full h-full">
                    <p className="text-[16px] font-[400] text-gray-900 text-center">
                        {item?.code ? `${item?.code}` : "-"}
                    </p>
                </div>
            ),

        },
    };
};
export default tableView;
