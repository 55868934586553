// ------------------------------
// Good Acceptance
// ------------------------------

// Import main libraries
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Import dynamic page libraries
import getActions from "../dynamic-page/util/getActions";
import TabButton from "../dynamic-page/elements/TabButton";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import util from "../dynamic-page/util";
import DynamicTable from "../dynamic-page";
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";

// Import model-view libraries
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tableView from "./models-view/table";

// Import redux actions
import { fetchSingleOrder, updateRow } from "../../store/orders";

import formView from "./models-view/form";
import FormModal from "../dynamic-page/FormModal";
import {
  DeleteWarehouse,
  fetchWarehouseFilters,
  GetAllWarehouses,
  updateBoundFilter,
  updateFilters,
  updateRow as updateSingleRow,
  updateTransferCost,
} from "../../store/warehouses";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import Icons from "../orders/icons";
import Util from "../dynamic-page/util";
import { Tooltip } from "react-tooltip";

export const TransferCostTooltip = (type) => (
  <span className="">
    <Icons.info
      className="w-5 h-5  text-gray-300"
      data-tooltip-id={`TransferCost${type || ""}`}
    />
    <Tooltip
      id={`TransferCost${type || ""}`}
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
        padding: "0px 0px 0px 0px",
      }}
    >
      <div className="flex flex-col gap-2 py-2.5 px-3 w-[270px]  text-start">
        <span className="text-[14px] leading-[14px] font-medium text-gray-900">
          Warehouse Transfer Cost Estimated
        </span>
        <span className="text-gray-500 text-[12px] leading-[15px] font-normal whitespace-pre-wrap">
          {`Estimated cost per kilogram to ship from {Primary Warehouse} to this warehouse.`}
        </span>
      </div>
    </Tooltip>
  </span>
);

// Main Component
const Warehouses = () => {
  const [currentTab, setCurrentTab] = useState("all");
  // Define hooks.
  const location = useLocation();
  const dispatch = useDispatch();

  // First page load.
  useEffect(() => {
    const startPageByFilters = {
      search: "",
      sort: null,
      page: 1,
      filters: { status: "" },
      includes: "country,state",
      limit: 10,
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term",true);
    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilters(storeQuery));
    dispatch(GetAllWarehouses(urlQuery));
    // dispatch(fetchWarehouseFilters(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.warehouses);
  const tableData = useSelector((state) => state.warehouses.warehouses ?? []);

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    GetAllWarehouses,
  });

  // Handle modal.
  const [modalVisibility, setModalVisibility] = useState(null);
  const [formData, setFormData] = useState({});

  const onHandleSave = (data, clear) => {
    let payload = null;
    const { state_id, ...rest } = data;
    if (state_id === null) {
      payload = rest;
    } else {
      payload = data;
    }

    // remove the null keys
    Object.keys(payload).forEach(
      (key) => payload[key] == null && delete payload[key]
    );

    if (data?.id) {
      callEndpoint({
        title: `Updating ${
          data?.type === 1 ? "Warehouse" : "Fulfillment Center"
        }`,
        url: `warehouses/${data?.id}`,
        method: "PUT",
        data: payload,
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          setSelectedRow(null)
          setFormData(null);
          clear();
          dispatch(
            updateSingleRow({
              path: `warehouses.[id:${data?.id}]`,
              value: {
                ...res.data,
                _updatedRow: true,
              },
            })
          );
          // remove the updated row after 5 seconds
          setTimeout(() => {
            dispatch(
              updateSingleRow({
                path: `warehouses.[id:${data?.id}]._updatedRow`,
                value: false,
              })
            );
          }, 5000);
          // setFormData(null);
          //  setModalVisibility(false);
        }
      });
    } else {
      callEndpoint({
        title: `Create ${
          data?.type === 1 ? "Warehouse" : "Fulfillment Center"
        }`,
        url: `warehouses`,
        method: "POST",
        data: payload,
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          clear();
          setTimeout(() => {
            onPaginationApply();
          }, 500);
          // setFormData(null);
          //  setModalVisibility(false);
        }
      });
    }
  };

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const actionList = {
    edit: {
      label: "Edit",
      onClick: (item) => {
        if (item?.id) {
          setFormData(item);
          setModalVisibility(true);
        } else {
          setFormData(selectedRow[0]);
          setModalVisibility(true);
        }
      },
      bulkDisable: true,
    },
    delete: {
      label: "Delete",
      bulkDisable: true,
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to delete the selected warehouse/s?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const rows = item?.id ? [item] : selectedRow;
            const ids = item?.id
              ? [item?.id]
              : selectedRow.map((item) => item?.id);
            callEndpoint({
              title: "Delete Warehouse",
              url: `warehouses`,
              method: "DELETE",
              data: { warehouses: ids },
              pureData: true,
            }).then((res) => {
              if (res.type === "success") {
                setConfirmationModalVisibility(false);
                setSelectedRow([]);
                ids.map((id) => {
                  dispatch(
                    updateSingleRow({
                      path: `warehouses.[id:${id}]`,
                      value: {
                        ...rows.find((item) => item?.id === id),
                        _updatedRow: true,
                        _updatedColor: "red",
                      },
                    })
                  );
                  setTimeout(() => {
                    dispatch(
                      updateSingleRow({
                        path: `warehouses.[id:${id}]._deletedRow`,
                        value: true,
                      })
                    );
                    setTimeout(() => {
                      dispatch(
                        updateSingleRow({
                          path: `warehouses.[id:${id}]`,
                        })
                      );
                    }, 1000);
                  }, 1000);
                });
              }
            });
          },
        });
      },
    },
  };

  // handle actions of statuses
  const actions = (row) => {
    return {
      count: row?.length,
      list: row?.length>1
        ? [actionList.delete]
        : [actionList.edit, actionList.delete],
    };
  };

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  // Handle Pagination

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilters(storeQuery));
    dispatch(GetAllWarehouses(urlQuery));
    dispatch(fetchWarehouseFilters(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(updateFilters(storeQuery));
    dispatch(GetAllWarehouses(urlQuery));
    dispatch(fetchWarehouseFilters(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilters(storeQuery));
    dispatch(GetAllWarehouses(urlQuery));
    dispatch(fetchWarehouseFilters(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });

    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilters(storeQuery), currentStoreFilters);
    await dispatch(GetAllWarehouses(urlQuery));
    await dispatch(fetchWarehouseFilters(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const handleUpdateSingleRow = (orderId) => {
    dispatch(fetchSingleOrder(orderId)).then((rs) => {
      dispatch(
        updateRow({
          path: `orders.[id:${orderId}]`,
          value: { ...rs.payload.data[0], _updatedRow: true },
        })
      );
    });
  };
  const handleCallBackActions = ({ action, data }) => {
    actionList[action].onClick(data);
  };
  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };
  const onUpdateNote = (data) => {
    callEndpoint({
      url: `warehouses/${data?.id}`,
      method: "put",
      data: { note: data.note },
      pureData: true,
    }).then((rs) => {
      if (rs?.type === "success") {
   
        dispatch(
          updateSingleRow({
            path: `warehouses.[id:${data?.id}]`,
            value: {
              ...pageData?.warehouses?.find((item) => item?.id === data?.id),
              note: rs?.data?.note,
              _updatedRow: true,
            },
          })
        );
        setTimeout(() => {
          dispatch(
            updateSingleRow({
              path: `warehouses.[id:${data?.id}]`,
              value: {
                ...pageData?.warehouses?.find((item) => item?.id === data?.id),
                note: rs?.data?.note,
                _updatedRow: false,
              },
            })
          );
        }, 3000);
      }
    });

  };

  const onUpdateValue = (data, callback) => {
    callEndpoint({
      url: `warehouses/${data?.id}`,
      method: "put",
      data: data?.payload,
      pureData: true,
    }).then((rs) => {
      if (rs?.type === "success") {
        callback(rs?.data?.[data?.type]);
        dispatch(
          updateSingleRow({
            path: `warehouses.[id:${data?.id}]`,
            value: {
              ...pageData?.warehouses?.find((item) => item?.id === data?.id),
              [data?.type]: rs?.data?.[data?.type],
              _updatedRow: true,
            },
          })
        );
        setTimeout(() => {
          dispatch(
            updateSingleRow({
              path: `warehouses.[id:${data?.id}]`,
              value: {
                ...pageData?.warehouses?.find((item) => item?.id === data?.id),
                [data?.type]: rs?.data?.[data?.type],
                _updatedRow: false,
              },
            })
          );
        }, 3000);
      }
    });
  };
  const tableActions = {
    onUpdateNote,
    onUpdateValue,
  };

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        <ArbitProductPage.Title
          title={"Warehouses"}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(pageData?.filters?.filters),
            discard: ["type"],
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.status === undefined ||
              pageData?.filters?.filters?.status === null
                ? 0
                : 1),
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          actionList={actions(selectedRow)}
        />

        <div
          className={
            "flex flex-row justify-between px-[16px] py-[6px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
        </div>

        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(tableData ?? [], tableActions, actionList)}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                actions={actions}
                meta={pageData?.meta}
                appending={loading}
                loadMoreData={loadMoreData}
                loading={pageData.loading}
                style={{
                  hiddenActions: true,
                  header: {
                    className:
                      "flex justify-start !px-4 !py-4 whitespace-nowrap",
                    th: "!h-[50px]",
                
                    "WAREHOUSES T. COST EST.": {
                      className: "text-right flex !justify-end",
                    },
                  },
                  row: {
                    className:
                      "flex items-start justify-start  !w-max !px-4 !py-2 ",
                    td: "!h-[54px]",
                    country: {
                      className: "!py-0 !min-w-[220px]",
                    },
                    name: {
                      className: "!min-w-[220px]",
                    },
                    "city, state": {
                      className: "!min-w-[222px]",
                    },
                    "WAREHOUSES T. COST EST.": {
                      className: "!min-w-[220px] text-right flex !justify-end",
                      icon: TransferCostTooltip("Table"),
                    },

                    "": {
                      className: " !py-1",
                      td: "w-[100%] ",
                    },
                  },
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>
      {modalVisibility && (
        <FormModal
          title={formData?.name ? `${formData?.name}` : "Warehouse"}
          prefix={"Create"}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          buttonsContainer=" !mt-[2px] !pt-0 !mb-[4px] !px-0"
          onClose={() => {
            setModalVisibility(false);
            setFormData(null);
          }}
          saveText="Create"
          createNew={formData?.id ? false : true}
          onSubmit={onHandleSave}
          container={(children) => (
            <div className="w-[668px]  grid grid-cols-6 items-start gap-[8px] justify-center  border-t border-t-gray-200  py-4  ">
              {children}
            </div>
          )}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default Warehouses;
