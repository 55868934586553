import MainModal from "../../../dynamic-page/MainModal"
import React, {memo, useEffect, useRef} from "react";
import CreateShipmentView from "./createShipmentView";

import DynamicForm from "../../../dynamic-form";
import combinedWarehouseSelect from "./model-view/combinedWarehouseSelect";
import axios from "axios";

const CreateShipment = memo(({ order, cancel, submit, awaiting_transfer }) => {
    
    const shipmentItem = {
        orderId: order?.id,
        from: order?.from,
        to: order?.to,
        billable_weight: order?.billable_weight,
        fresh: 0,
        items_qty: order?.items_qty,
    }





    return (
        <div className={"createShipmentModal"}>
            <style>
                {`
                    .createShipmentModal .css-1u9des2-indicatorSeparator {display: none; }
                    .createShipmentModal .css-1xc3v61-indicatorContainer {padding-left: 0px; padding-right:6px }
                    .createShipmentModal .css-1xc3v61-indicatorContainer {padding-right: 12px}
                    .createShipmentModal .css-1fdsijx-ValueContainer,
                    .createShipmentModal .css-qbdosj-Input {padding-top:0px; padding-bottom:0px}
                    .css-1hb7zxy-IndicatorsContainer {height:42px !important}
                    .createShipmentModal .css-1xc3v61-indicatorContainer svg {color:transparent !important; background: url('/assets/images/arrow.svg'); width:12px; height:12px; opacity:1}
                `}
            </style>

            <MainModal
                className='!h-[602px] !w-[924px]  !min-w-[924px]'
                containerClassName='!px-0'
                headerClassName='!px-5 !py-4 !h-[74px] !items-center !justify-between'
                item={{
                    title: `Create Shipment for ${order?.billable_weight} kg`,

                    cancel: cancel,
                    view: (item) => (
                        <CreateShipmentView item={shipmentItem} submit={(data) => submit(data)} cancel={cancel} awaiting_transfer={awaiting_transfer} />),
                }}
            />
        </div>


    )
})


export default CreateShipment;


