// FormModal.js
// -----------------------------------------
// Create container for Normal Form Modal.

// Import general libraries
import React, { useCallback, useEffect, useRef } from "react";

// Import Elements
import MainModal from "./MainModal";
import DynamicForm from "../dynamic-form";
import { HiCheck } from "react-icons/hi";
import UI from "../dynamic-form/elements";

// Main Component: AddEditModal
const FormModal = React.memo(
  ({
    title, // Title of form modal.
    noPrefix,
    prefix = null, // Title prefix, if exists.
    formView, // View Model of form.
    data = null, // Data to be edited. If null, form will be in add mode.
    onSubmit, // Function to handle form submit.
    onClose, // Function to handle close form modal.
    container,
    createNew,
    saveText,
    visibility, // Boolean to handle visibility of form modal.,
    params,
    headerClassName,
    buttonsContainer,
    createAnotherClassName,
    containerClassName,
    editPrefix,
  }) => {
    // Create ref to handle form data.
    const myFormRef = useRef();
    const checkboxRef = useRef();
    const createNewRef = useCallback((node) => {
      checkboxRef.current = node;
      // if (node)
    }, []);
    useEffect(() => {}, [checkboxRef.current?.checked]);
    // console.log(checkboxRef.current.checked);
    // Handle form submit.
    const myFormCallBack = async () => {
      let formData = await myFormRef?.current?.getData();
      if (formData) {
        // ------------------------------
        // Add additional data to form data if required.
        // Ex: formData.created_by = userId;
        // ------------------------------

        // Add id to form data if data is not null.
        if (data) {
          formData.id = data.id;
        }

        // Call onSubmit function to handle form submit.
        onSubmit(formData, async () => {
          if (checkboxRef.current?.checked) {
            await myFormRef.current?.reset();
          } else {
            onClose();
          }
        });

        // Close modal
        //  onClose()
      }
    };
    // Render Component
    return (
      // Configure Modal
      <MainModal
        containerClassName={`!pb-1 ${
          containerClassName ? containerClassName : ""
        }`}
        headerClassName={headerClassName}
        item={{
          title: `${ noPrefix ? "" :
            !data?.id
              ? prefix
                ? prefix
                : "Add New"
              : `${editPrefix ? editPrefix : "Edit"}`
          }  ${title}`, // If data is null, show "Add New" else "Edit".,
          submit: myFormCallBack,
          cancel: onClose,
          view: (item) => (
            <div>
              {container ? (
                container(
                  <DynamicForm items={formView(data, params)} ref={myFormRef} />
                )
              ) : (
                <div className="min-w-[775px] grid grid-cols-2 items-start gap-[16px] w-full justify-center px-[16px] border-t border-t-gray-200 pt-[24px] mt-[10px]">
                  {/* Dynamic form is here */}
                  <DynamicForm items={formView(data, params)} ref={myFormRef} />
                </div>
              )}

              {/* Form control Buttons are here */}
              <div
                className={`flex w-full sticky bg-white bottom-0 ${
                  createNew ? "justify-between" : "justify-end"
                } items-center pt-[8px] pb-2   px-[16px] gap-[8px] ${
                  buttonsContainer && buttonsContainer
                }`}
              >
                {createNew && (
                  <div className={`flex items-center ${createAnotherClassName && createAnotherClassName}`}>
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      ref={createNewRef}
                      disabled={false}
                      className={
                        "w-[16px] h-[16px] mr-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-[4px] "
                      }
                    />
                    <label
                      for="scales"
                      className="text-[14px] font-medium leading-[14px]"
                    >
                      Create another
                    </label>
                  </div>
                )}

                <div className="flex flex-row gap-[8px]">
                  <UI.Button
                    item={{
                      label: <div className={"flex flex-row"}>Cancel</div>,
                      buttonType: "alt",
                      className: "h-[41px] !w-[120px] !px-[16px]",
                    }}
                    onClick={() => onClose()}
                  />
                  <UI.Button
                    item={{
                      label: (
                        <div className={"flex flex-row"}>
                          {saveText ? (data?.id ? "Save" : saveText) : "Save"}
                        </div>
                      ),
                      buttonType: "primary",
                      className: "h-[41px] !w-[120px]  !px-[16px]",
                    }}
                    onClick={myFormCallBack}
                  />
                </div>
              </div>
            </div>
          ),
        }}
      />
    );
  }, (prevProps, nextProps) => {
    return  prevProps.data === nextProps.data
  }
);

export default FormModal;
