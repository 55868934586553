import FlagIcon from "../../sub-elements/country-flags";

const CountryCurrencies = ({item, className}) => {
    return (
        <FlagIcon
            code={
                item?.toLowerCase() === "jpy"
                    ? "jp"
                    : item?.toLowerCase() === "try"
                        ? "tr"
                        : item?.toLowerCase() === "aud"
                            ? "au"
                            : item?.toLowerCase() === "usd"
                                ? "us"
                                : item?.toLowerCase() === "cad"
                                    ? "ca"
                                    : item?.toLowerCase()
            }
            className={` w-4 h-4 ${className && className}`}
        />
    );
};

export default CountryCurrencies;
