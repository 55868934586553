import axios from './../../middleware/axios';
import constants from '../../constant';

const fetchRequests = async (params) => {
    let url = constants.APIURL.GET_SUPPLY_REQUESTS
    if (params)
        url = url.concat(params)

    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const createRequest = async (payload) => {
    const response = await axios.post(
        constants.APIURL.POST_SUPPLY_REQUEST,
        payload,
        {withCredentials: true}
    )
    return response
}

const supplyFulfilment = async (payload) => {
    const response = await axios.post(
        constants.APIURL.POST_SUPPLY,
        payload,
        {withCredentials: true}
    )
    return response
}

const fetchFilters = async () => {
    const response = await axios.get(
        constants.APIURL.GET_SUPPLY_REQUESTS_FILTERS,
        {withCredentials: true}
    )
    return response
}

const deleteRequest = async (ids) => {
    const response = await axios.delete(
        constants.APIURL.DELETE_SUPPLY_REQUEST,
        {withCredentials: true, data: {ids: ids}}
    )
    return response
}


const updateSupplyRequest = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_SUPPLY_REQUEST + id, payload, {withCredentials: true})
    return response
}


export default {
    fetchRequests,
    createRequest,
    supplyFulfilment,
    fetchFilters,
    deleteRequest,
    updateSupplyRequest
}