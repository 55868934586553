import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchCurrencies = createAsyncThunk(
    "get:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.fetchCurrencies(payload);
        return response.data;
    }
);

const createCurrency = createAsyncThunk(
    "post:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.createCurrency(payload);
        return response;
    }
);

const updateCurrency = createAsyncThunk(
    "put:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.updateCurrency(payload);
        return response;
    }
);

const deleteCurrency = createAsyncThunk(
    "delete:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.deleteCurrency(payload);
        return response;
    }
);

const searchCurrencies = createAsyncThunk(
    "get:api/v1/currencies/search",
    async (payload, thunkAPI) => {
        const response = await api.searchCurrencies(payload);
        return response.data;
    }
);

const bulkDeleteCurrencies = createAsyncThunk(
    "delete:api/v1/currencies/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteCurrencies(payload);
        return response;
    }
);

const updateCurrenciesStatus = createAsyncThunk(
    "put:api/v1/currencies/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdateCurrenciesStatus(payload);
        return response;
    }
);

const bulkRestoreCurrencies = createAsyncThunk(
    "put:api/v1/currencies/bulk/restore",
    async (payload, thunkAPI) => {
        const response = await api.bulkRestoreCurrencies(payload);
        return response;
    }
);

const fetchCurrenciesFilters = createAsyncThunk(
    "get:api/v1/currencies/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchCurrenciesFilters(payload);
        return response;
    }
);


export {
    fetchCurrencies,
    createCurrency,
    updateCurrency,
    deleteCurrency,
    searchCurrencies,
    bulkDeleteCurrencies,
    updateCurrenciesStatus,
    bulkRestoreCurrencies,
    fetchCurrenciesFilters
};
