import React from 'react';

const StatBox = ({title, value, elements, externallink, icon}) => {
    return (
        <div className="flex flex-col justify-between items-center py-5 px-3 rounded-lg text-center" style={{
            boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)'
        }}>
            <div className='flex flex-row justify-center items-center gap-x-1.5'>
                {elements}
                <span className="text-gray-900 text-[24px] font-bold">{value}</span>
                {externallink}
            </div>
            <div className='flex flex-row justify-center items-center gap-x-1.5'>
                <p className="text-gray-500 text-[14px] font-normal">{title}</p>
                {icon}
            </div>
        </div>
    );
};

const StatBoxList = ({data}) => {
    return (
        <>
            {data.map((item, index) => (
                <StatBox key={index} title={item.title} value={item.value} elements={item.elements}
                         externallink={item.externallink} icon={item.icon}/>
            ))}
        </>
    );
};

export default StatBoxList;
