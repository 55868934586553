import Dropdown from "../../sub-elements/dropdown";
import React, {useEffect, useMemo, useState} from "react";
import constants from "../../../constant";
import CustomAsyncSelect from "../customAsyncSelect";
import moment from "moment";
import {FaPlus,FaCheck,FaMinus} from "react-icons/fa6";
import AsyncSelect from "react-select/async";
import axios from "axios";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import CountryCurrencies from "../../dynamic-page/util/countryCurrencies";

export default function AddProduct({addItemToArray,setLoading,productClickedId,setProductClickedId}) {
    const [selectedProduct, setSelectedProduct] = useState({
        value: null,
        product: null,
        assign: null,
        profile: null,
        request_reason: null,
        title: null,
        image: null,
        apid: null,
        note: null,
        url: null,
        supplier_id: null,
        qty: 1,
        max_acceptable_price: null,
        max_acceptable_price_currency_id: {code: "TRY",
            image: undefined,
            label: "Turkish lira",
            logo: undefined,
            position: "0",
            product: 1,
            symbol: "₺",
            title: "Turkish lira",
            value: 1,},
        warehouse_id: {value: 3, label: "Istanbul Warehouse"},
    });
    useEffect(() => {
        setProduct(productClickedId)
        setSelectedProduct({
            ...selectedProduct,
            product: productClickedId?.value,
            title: productClickedId?.label,
            image: productClickedId?.image,
            assign: productClickedId?.assign,
            profile: productClickedId?.profile,
        });
    },[productClickedId])
    const defaultCurrency = {
        code: "TRY",
        image: undefined,
        label: "Turkish lira",
        logo: undefined,
        position: "0",
        product: 1,
        symbol: "₺",
        title: "Turkish lira",
        value: 1,
    };
    const fetchCurrencies = async (params) => {
        let url = "api/v1/currencies";
        if (params) url = url.concat(params);
        const response = await axios.get(url, {withCredentials: true});
        return response.data;
    };
    const [supplyError, setSupplyError] = useState("")
    const [priceError, setPriceError] = useState("")
    const [productError, setProductError] = useState("")
    const [reasonError, setReasonError] = useState("")

    
    const mapOptionsToValues2 = (options) => {
        return options?.map((option) => ({
            value: option?.id,
            label: option?.name || option?.display_name,
            image: option?.image,
            code: option?.code,
            title: option?.name,
            product: option?.id,
            position: option?.position,
            symbol: option?.symbol,
            logo: option?.logo,
        }));
    };
    const [editMode] = useState(false);
    const currenciesOptions = async (inputValue) => {
        return new Promise((resolve) => {
            fetchCurrencies("")
                .then((response) => {
            
                    const options = mapOptionsToValues2(response.data);
                    resolve(options);
                })
                .catch((error) => {
                });
        });
    };
    const mapProductsOptionsToValues = (options) => {
        return options.map((option) => ({
            value: option?.id,
            label: `#${option?.id}  |  ` + (option?.title ? option?.title : ""),
            description:
                "APID:  " +
                option?.apid +
                " | " +
                "Created At:  " +
                moment(option?.request_date_time).format("DD-MM-YYYY"),
            name: option?.name,
            image: option?.image,
            logo: option?.logo,
            assign: option?.assign?.name,
            profile: option?.assign?.profile_image,
        }));
    };
    const [requestReason, setRequestReason] = useState("");
    const [errorMessage] = useState("");
    const [isQuantityClicked, setIsQuantityClicked] = useState(false);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [product, setProduct] = useState([]);

    const getRequestReasonLabel = (reason) => {
        if (!reason) return "Reason for Supply";
        switch (reason) {
          case "warehouse_restocking":
            return "Warehouse Stock";
          case "fulfilment_restocking":
            return "Fulfilment Stock";
          case "bulk_order":
            return "Bulk Order";
          default:
            return "Order";
        }
      };
    const productsData = useSelector((state) => state.productReducer.products);
    const defaultProducts = useMemo(() => {
        return mapProductsOptionsToValues(productsData);
    }, [productsData]);


const mapReasonToCode = (reason) => {
    switch (reason) {
      case "Warehouse Stock":
        return "warehouse_restocking";
      case "Fulfilment Stock":
        return "fulfilment_restocking";
      case "Bulk Order":
        return "bulk_order";
      default:
        return "order";
    }
  };
    return (
        <div className="flex flex-col gap-2 z-10  px-8 pr-6 pb-4  ">
            <div className=" w-full space-y-2   ">
                <babel className="text-sm font-medium text-gray-900">Product*

                </babel>
                <CustomAsyncSelect
                    url={constants.APIURL.GET_PRODUCT}
                    mapOptions={mapProductsOptionsToValues}
                    defaultOptions={defaultProducts}
                    defaultParams="&filter[type]=1"
                    placeholder="Select the product you would like to make a supply request for."
                    product={product?.length!==0 ? product : productClickedId}
                    onChange={(data) => {
                        setProduct(data);
                        setSelectedProduct({
                            ...selectedProduct,
                            product: data?.value,
                            title: data?.label,
                            image: data?.image,
                            assign: data?.assign,
                            profile: data?.profile,
                        });
                    }}
                    optionHeight={50}
                    features={{
                        formatOptionLabel: (option) => (
                            <div className="m-0 p-0 flex  ">
                                <img
                                    className="w-8 h-8 mr-2 rounded-full"
                                    src={option?.image || "/assets/images/noImage.png"}
                                    alt="icon"
                                    onError={(e) => {
                                        e.target.src = "/assets/images/noImage.png";
                                    }}
                                />
                                <div className="custom-container">
                                    <label className="block text-sm font-semibold  title">
                                        {option.label}
                                    </label>
                                    <label className="block text-xs text-gray-400  descr">
                                        {option.description}
                                    </label>
                                </div>
                            </div>
                        ),
                    }}
                    customStyles={{
                        control: (provided, state) => {
                            let borderColor;
    if (state.isFocused) {
      borderColor = "#00A3FF";
    } else if (errorMessage && !selectedProduct.product) {
      borderColor = "#FF0000";
    } else {
      borderColor = "#E5E5E5";
    }
                            return {
                              ...provided,
                              height: 42,
                              width: "100%",
                              backgroundColor: "#F9FAFB",
                              borderRadius: 8,
                              overflow: "auto",
                              border: `1px solid ${borderColor}`,
                              boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                              "&:hover": {
                                border: `1px solid ${state.isFocused ? "#00A3FF" : "#E5E5E5"}`,
                              },
                            };
                          },
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? "#00A3FF" : "white",
                            color: state.isSelected ? "white" : "black",

                            "&:hover": {
                                backgroundColor: "#00A3FF",
                                color: "white",
                            },
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            zIndex: 999,
                            // borderRadius: 10,
                            // overflow: 'hidden',
                            border: state.isFocused
                                ? "1px solid #00A3FF"
                                : "1px solid #E5E5E5",
                            boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                            "&:hover": {
                                border: state.isFocused
                                    ? "1px solid #00A3FF"
                                    : "1px solid #E5E5E5",
                            },
                        }),
                        container: (provided, state) => ({
                            ...provided,
                            width: "100%",
                            height: 42,
                        }),
                    }}
                />
                {productError && (
                    <span className="text-sm text-red-500">{productError}</span>
                )}
            </div>
            <div className={`flex gap-3.5   ${(priceError || reasonError || supplyError) && ""} `}>
                <div className="flex flex-col space-y-2 ">
                    <babel className="text-gray-900 text-[14px] font-medium">
                        Reason*

                    </babel>
                    <Dropdown
                        bg_color="red"
                        text_color="gray"
                        degree={selectedProduct?.request_reason ? "600" : "500"}
                        // justify="between"

                        className={`bg-gray-50 border focus:bg-white focus:border-2 focus:border-blue-400  text-gray-900 h-[42px] p-3 rounded-xl w-[100%] ${
                            errorMessage && !selectedProduct?.request_reason
                                ? "border-red-500"
                                : "border-gray-200"
                        }`}
                        name="request_reason"
                        items={["Warehouse Stock", "Fulfilment Stock", "Bulk Order"]}
                        value={getRequestReasonLabel(selectedProduct?.request_reason)}
                        onSelect={(e) => {
                           
                            const reason = mapReasonToCode(e);
                            setRequestReason(reason);
                            setSelectedProduct({
                                ...selectedProduct,
                                request_reason: reason,
                            });
                        }}
                        placeholder="Reason for Supply"
                        width="200px"
                        height={42}
                    />
                    {reasonError && (
                        <span className="text-sm  text-red-500">{reasonError}</span>
                    )}
                </div>
                <div className="flex flex-col space-y-2">
                    <babel className="text-gray-900 text-[14px] font-medium">
                        Supply Channel*

                    </babel>
                    <CustomAsyncSelect
                        url={constants.APIURL.GET_CHANNELS}
                        mapOptions={mapProductsOptionsToValues}
                        //  defaultOptions={defaultProducts}
                        //  defaultParams="?not_deleted=true"
                        placeholder="Select the supply channel you want to request supply from."
                        //  isDisabled={!!data?.product || editMode}
                        product={selectedProduct?.supplier_id || ""}
                        onChange={(data) => {
                        
                            setSelectedProduct({
                                ...selectedProduct,
                                supplier_id: data,
                                supplier: {
                                    name: data?.label,
                                    id: data?.value,
                                },
                            });
                        }}
                        optionHeight={50}
                        features={{
                            formatOptionLabel: (item) => (
                                <div className={"flex flex-row gap-[12px]  items-center"}>
                                    <div className="w-[50px] h-auto max-h-[50px]">
                                        <img
                                        alt="product"
                                            className={
                                                "w-full h-auto max-h-[50px]  object-cover rounded-full border-0 bg-gray-200"
                                            }
                                            src={item?.logo || "/assets/images/img-placehoder.png"}
                                        />
                                    </div>

                                    <span>{item?.name}</span>
                                </div>
                            ),
                        }}
                        customStyles={{
                            control: (provided, state) => {
                                let borderColor;
                                if (state.isFocused) {
                                  borderColor = "#00A3FF";
                                } else if (errorMessage && !selectedProduct.product) {
                                  borderColor = "#FF0000";
                                } else {
                                  borderColor = "#E5E5E5";
                                }
                                return {
                                  ...provided,
                                  height: 42,
                                  width: "100%",
                                  backgroundColor: "#F9FAFB",
                                  borderRadius: 8,
                                  overflow: "auto",
                                  border: `1px solid ${borderColor}`,
                                  boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                                  "&:hover": {
                                    border: `1px solid ${state.isFocused ? "#00A3FF" : "#E5E5E5"}`,
                                  },
                                };
                              },
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? "#00A3FF" : "white",
                                color: state.isSelected ? "white" : "black",

                                "&:hover": {
                                    backgroundColor: "#00A3FF",
                                    color: "white",
                                },
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                position: "absolute",
                                zIndex: 999,
                                // borderRadius: 10,
                                // overflow: 'hidden',
                                border: state.isFocused
                                    ? "1px solid #00A3FF"
                                    : "1px solid #E5E5E5",
                                boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                                "&:hover": {
                                    border: state.isFocused
                                        ? "1px solid #00A3FF"
                                        : "1px solid #E5E5E5",
                                },
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                width: "673px",
                                height: 42,
                            }),
                        }}
                    />
                    {supplyError && (
                        <span className="text-sm  text-red-500">{supplyError}</span>
                    )}
                </div>
                <div className="flex flex-col  space-y-[8px]">
                    <babel className="text-[14px] leading-[21px] font-medium text-gray-900">
                        Quantity*
                    </babel>
                    <div className="flex w-[160px] h-[42px] ">
                        <button
                            type="button"
                            onClick={() => {
                                if (Number(selectedProduct?.qty) - 1 !== 0) {
                                    setSelectedProduct({
                                        ...selectedProduct,
                                        qty: Number(selectedProduct?.qty) - 1,
                                    });
                                }
                            }}
                            className="border border-gray-300 border-r-0 rounded-r-none hover:bg-gray-50 bg-gray-100 py-3 px-5 rounded-lg"
                        >
                            <FaMinus className="w-[12.8px] h-[12.8px] text-gray-900"/>
                        </button>
                        {!isQuantityClicked ? (
                            <button
                                onClick={() => setIsQuantityClicked(true)}
                                className="text-[14px] border bg-gray-50 px-5  py-3 border-gray-300 leading-[17.5px] text-gray-900"
                            >
                {Number(selectedProduct?.qty) || 1}
              </button>
                        ) : (
                            <input
                                type="number"
                                min={1}
                                className="text-[14px] border text-center focus:border-gray-300 focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none bg-gray-50  w-[50px]  py-3 border-gray-300 leading-[17.5px] text-gray-900"
                                onChange={(e) => {

                                    if (e.target.value) {
                                        setSelectedProduct({
                                            ...selectedProduct,
                                            qty: Number(e.target.value),
                                        });
                                    }
                                }}
                                value={Number(selectedProduct?.qty) || 1}
                            />
                        )}
                        <button
                            type="button"
                            onClick={() => {
                                setSelectedProduct({
                                    ...selectedProduct,
                                    qty: Number(selectedProduct?.qty) + 1,
                                });
                            }}
                            className="border border-gray-300 border-l-0  bg-gray-100 hover:bg-gray-50 py-3 px-5 rounded-lg rounded-l-none"
                        >
                            <FaPlus className="w-[12.8px] h-[12.8px] text-gray-900"/>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col space-y-[8px]  ">
                    <babel className="text-gray-900 text-[14px] font-medium">
                    Max. Acceptable Price*

                    </babel>
                    <div className="flex  ">
                        <input
                            type="number"
                            className={`bg-gray-50 border-gray-300   border focus:border-gray-300 [&::-webkit-inner-spin-button]:appearance-none  focus:ring-0 h-[42px] !w-[91px] text-center text-[14px] leading-[17.5px] text-gray-900  p-3 !rounded-r-none border-r-0 rounded-lg`}
                            name="purchase_price"
                            placeholder="Price"
                            value={
                                selectedProduct?.max_acceptable_price
                            }
                            onChange={(e) => {
                                // it should be a number bigger than 0
                                if (e.target.value >= 0) {
                                    setSelectedProduct({
                                        ...selectedProduct,
                                        max_acceptable_price: Number(e.target.value),
                                    });
                                }
                            }}
                            // onBlur={formik.handleBlur}
                        />
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={currenciesOptions}
                            className="border-gray-300 h-[42px] bg-transparent placeholder:text-red-500 !w-[110px] rounded-l-none border-l-0 focus:border-gray-300   focus:ring-0"
                            name="currency"
                            //   isDisabled={formik.values.bank_account_id ? true : false}
                            value={
                                selectedProduct?.max_acceptable_price_currency_id ||
                                defaultCurrency
                            }
                            onChange={(value) => {
                                setSelectedProduct({
                                    ...selectedProduct,
                                    max_acceptable_price_currency_id: value,
                                });
                            }}
                            formatOptionLabel={(item) => {
                             
                                return (
                                    <div className="flex items-center gap-2">
                                        <CountryCurrencies
                                            className={"w-4 h-[11.2px] object-cover"}
                                            item={item?.code}
                                        />
                                        <span className="text-[14px] leading-[17.5px] font-medium text-gray-900">
                      {item?.code}
                    </span>
                                    </div>
                                );
                            }}
                            //   onBlur={formik.handleBlur}
                            styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  height: 42,
                                  minHeight: 42,
                                  width: "100%",
                                  backgroundColor: "#F3F4F6",
                                  borderRadius: "0 10px 10px 0", // Simulates rounded-l-none
                                  borderLeft: "none", // Simulates border-l-0
                                  border: "1px solid #D1D5DB", // Default border color updated to gray-300
                                  boxShadow: "none", // Remove default box-shadow
                                  "&:hover": {
                                    border: "1px solid #D1D5DB", // Border color on hover updated to gray-300
                                  },
                                  ...(state.isFocused && {
                                    border: "1px solid #D1D5DB", // Border color on focus updated to gray-300
                                    boxShadow: "none", // Remove box-shadow on focus
                                  }),
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected ? "#00A3FF" : "white",
                                  color: state.isSelected ? "white" : "black",
                                  "&:hover": {
                                    backgroundColor: "#00A3FF",
                                    color: "white",
                                  },
                                }),
                                menu: (provided, state) => ({
                                  ...provided,
                                  position: "absolute",
                                  width: "100%",
                                  zIndex: 999,
                                  overflow: "hidden",
                                  border: "1px solid #D1D5DB", // Border color updated to gray-300
                                  boxShadow: "none",
                                  "&:hover": {
                                    border: "1px solid #D1D5DB", // Border color on hover updated to gray-300
                                  },
                                }),
                                container: (provided, state) => ({
                                  ...provided,
                                  width: "100%",
                                  height: 42,
                                  minHeight: 42,
                                }),
                              }}
                              
                        />
                    </div>
                    {priceError && (
                        <span className="text-sm  text-red-500">{priceError}</span>
                    )}
                </div>
            </div>
            {(requestReason !== "order" || editMode) && (
                <div className="flex gap-3.5 w-full  items-center">
                    <div className="flex flex-col space-y-2 w-full">
                        <babel className="text-gray-900 text-[14px] font-medium">
                            Purchase Link
                            {invalidUrl && (
                                <span className="text-sm ml-3 text-red-500">Invalid URL</span>
                            )}
                        </babel>
                        <input
                            className={`bg-gray-50 border border-gray-200 focus:text-gray-900  focus:bg-white focus:border-2 focus:border-blue-400 text-gray-900 py-2 px-4 h-[41px] rounded-lg w-full ${
                                errorMessage && !selectedProduct?.url
                                    ? "border-red-500"
                                    : "border-gray-200"
                            }`}
                            placeholder="You can share the purchase link for the product with the purchasing team."
                            name="url"
                            value={selectedProduct?.url || ""}
                            onChange={(e) => {
                                setSelectedProduct({
                                    ...selectedProduct,
                                    url: e.target.value,
                                });
                            }}
                            onInput={(e) => {
                                // validate with this simpler regex
                                if (!e.target.value.match(/^https?:\/\/[^/\s]+\/?.*$/)) {
                                    // set the border to red
                                    e.target.style.border = "1px solid red";
                                    setInvalidUrl(true);
                                } else {
                                    e.target.style.border = "1px solid #E5E5E5";
                                    setInvalidUrl(false);
                                }
                            }}
                        />
                    </div>
                    <div className="flex flex-col w-full space-y-2">
                        <babel className="text-gray-900 text-[14px] font-medium">
                            Supply Request Note
                        </babel>
                        <input
                            className={`bg-gray-50 border border-gray-200 focus:bg-white text-gray-900 focus:text-gray-900 focus:border-2 focus:border-blue-400  py-2 px-4 h-[41px] rounded-lg w-full ${
                                errorMessage && !selectedProduct?.note
                                    ? "border-red-500"
                                    : "border-gray-200"
                            }`}
                            placeholder="This note will appear at the time of purchase of the products."
                            name="comments"
                            value={selectedProduct?.note || ""}
                            onChange={(e) => {
                                setSelectedProduct({
                                    ...selectedProduct,
                                    note: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <button
                        onClick={() => {
                            let temp = false;
                            Object.keys(selectedProduct).forEach((value, index) => {
                                if ((selectedProduct[value] === null && selectedProduct[value] !== "") && value === "supplier_id") {
                                    setSupplyError("Supply channel is required.")
                                    temp = true
                                } else if (((selectedProduct[value] === undefined || selectedProduct[value] === null || selectedProduct[value] === "")) && value === "product") {
                                    setProductError("Product is required.")
                                    temp = true
                                } else if ((selectedProduct[value] === "" ||  selectedProduct[value] === null) && value === "max_acceptable_price") {
                                    setPriceError("Price is required.")
                                    temp = true
                                } else if ((selectedProduct[value] === null && selectedProduct[value] !== "") && value === "request_reason") {
                                    setReasonError("Request reason is required.")
                                    temp = true
                                }
                            })
                            if (temp === false) {
                                addItemToArray(selectedProduct);
                                setLoading(false)
                                setProduct(null)
                                setSelectedProduct({
                                    value: null,
                                    product: null,
                                    assign: null,
                                    profile: "",
                                    request_reason: selectedProduct?.request_reason,
                                    title: "",
                                    image: "",
                                    apid: "",
                                    note: "",
                                    url: "",
                                    supplier_id: selectedProduct?.supplier_id,
                                    qty: 1,
                                    max_acceptable_price: "",
                                    max_acceptable_price_currency_id: defaultCurrency,
                                    warehouse_id: {value: 3, label: "Istanbul Warehouse"},

                                });
                                setSupplyError("")
                                setProductError("")
                                setPriceError("")
                                setReasonError("")
                                setProductClickedId("")
                            }

                        }}
                        className="h-[41px] border border-blue-700 hover:bg-blue-700 group flex justify-center items-center px-3 py-2 mt-7 rounded-[8px] cursor-pointer"
                    >
                        <FaCheck className="text-blue-700 group-hover:text-white "/>
                    </button>
                </div>
            )}
        </div>
    );
}

AddProduct.propTypes = {
    addItemToArray: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    productClickedId: PropTypes.object,
    setProductClickedId: PropTypes.func.isRequired,
  };