import axios from '../../middleware/axios';
import constants from '../../constant';

const fetchCategories = async (params) => {
    let url = constants.APIURL.GET_CATEGORIES
    if (params)
        url = url.concat(params)

    const response = await axios.get(url, {withCredentials: true})
    return response
}

const fetchListingSources = async (params) => {
    let url = constants.APIURL.GET_LISTING_SOURCES
    if (params)
        url = url.concat(params)

    const response = await axios.get(url, {withCredentials: true})
    return response
}

const fetchListingSourceCategories = async (params) => {
    let url = constants.APIURL.GET_LISTING_SOURCE_CATEGORIES
    if (params)
        url = url.concat(params)
    const response = await axios.get(url, {withCredentials: true})
    // console.log(response, 'Listing Source Category')
    return response
}

const updateCategoryMap = async (id, payload) => {
    const response = await axios.put(constants.APIURL.PUT_CATEGORY_MAP + id + '/map', payload, {withCredentials: true})

    // console.log(response, 'Map Response')
    return response
}

const fetchCategoriesFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_CATEGORIES_FILTERS, {withCredentials: true})
    return response
}

const createCtegory = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_CATEGORY, payload, {withCredentials: true})
    return response
}

const updateCategory = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_CATEGORY + id, payload, {withCredentials: true})
    return response
}

const deleteCategory = async (id, thunkAPI) => {
    const response = await axios.delete(constants.APIURL.DEL_Category + id, {withCredentials: true})
    if (response.status === 200) {
        return response
    } else {
        return thunkAPI.rejectWithValue(response.data)
    }

}

const bulkDeleteCategory = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_CATEGORY, {data: payload}, {withCredentials: true})
    return response
}

const bulkUpdateCategory = async (payload) => {
    const response = await axios.post(constants.APIURL.BULK_UPDATE_CATEGORY, payload, {withCredentials: true})
    return response
}

const restoreCategory = async (id) => {
    const response = await axios.post(constants.APIURL.PUT_UPDATE_CATEGORY + id + '/restore', {withCredentials: true})
    return response
}


export default {
    fetchCategories,
    fetchListingSources,
    fetchListingSourceCategories,
    updateCategoryMap,
    fetchCategoriesFilters,
    createCtegory,
    updateCategory,
    deleteCategory,
    bulkDeleteCategory,
    bulkUpdateCategory,
    restoreCategory
}