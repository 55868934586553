import React, { useState, useEffect, useRef } from "react";
import { howLongFromNow } from "../../../../../utils/helper";
import util from "../../../../dynamic-page/util";
import moment from "moment";
import Icons from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addNote,
  deleteNote,
  fetchNotes,
  updateRow,
} from "../../../../../store/orders";
import PropTypes from "prop-types";

const CommentTimeline = ({ id, allNotes, setAllNotes }) => {
  id = id?.order;
  const scrollableDivRef = useRef(null);
  const dispatch = useDispatch();
  const [deleteList, setDeleteList] = useState([]);
  const [lastAction, setLastAction] = useState(null);
  const [note, setNote] = useState("");
  const [updateNotesState, setUpdateNotesState] = useState(false);

  const currentUserId = useSelector((state) => state?.authUser?.authUser?.id);
  const currentUserImage = useSelector(
    (state) => state?.authUser?.authUser?.image_url
  );

  const handleChangeNoteInput = (data) => {
    setNote(data);
  };

  const handleAddNote = async () => {
    if (!note) return false;
    setUpdateNotesState(true);
    await dispatch(addNote({ orderId: id, note: note }));
    dispatch(
      updateRow({
        path: `orders.[id:${id}].notes_count`,
        value: allNotes.length + 1,
      })
    );

    dispatch(
      updateRow({
        path: `orders.[id:${id}]._updatedRow`,
        value: true,
      })
    );
    // remove the updated row after 5 seconds
    setTimeout(() => {
      dispatch(
        updateRow({
          path: `orders.[id:${id}]._updatedRow`,
          value: false,
        })
      );
    }, 5000);

    let getNotes = await dispatch(fetchNotes({ orderId: id }));
    setLastAction("add");
    getNotes = getNotes?.payload?.data?.data;
    dispatch(
      updateRow({
        path: `orders.[id:${id}].internal_notes`,
        value: getNotes?.map((item) => ({
          id: item?.id,
          created_at: item?.created_at,
          note: item?.note,
          created_by: {
            id: item?.created_by,
            name: item?.employee?.first_name,
            profile_image: item?.employee?.profile_image,
          },
        })),
      })
    );

    setUpdateNotesState(false);
    setAllNotes(
      getNotes?.map((item) => ({
        id: item?.id,
        created_at: item?.created_at,
        note: item?.note,
        created_by: {
          id: item?.created_by,
          name: item?.employee?.first_name,
          profile_image: item?.employee?.profile_image,
        },
      }))
    );
    setNote("");
  };
  const handleDeleteNote = async (noteId) => {
    setDeleteList((prevData) => [...prevData, noteId]);
    setLastAction("delete");
    setUpdateNotesState(true);

    await dispatch(deleteNote({ orderId: id, noteId: noteId }));

    let getNotes = await dispatch(fetchNotes({ orderId: id }));
    getNotes = getNotes?.payload?.data?.data;
    dispatch(
      updateRow({
        path: `orders.[id:${id}].notes_count`,
        value: allNotes.length - 1,
      })
    );

    dispatch(
      updateRow({
        path: `orders.[id:${id}]._updatedRow`,
        value: true,
      })
    );
    // remove the updated row after 5 seconds
    setTimeout(() => {
      dispatch(
        updateRow({
          path: `orders.[id:${id}]._updatedRow`,
          value: false,
        })
      );
    }, 5000);

    dispatch(
      updateRow({
        path: `orders.[id:${id}].internal_notes`,
        value: getNotes?.map((item) => ({
          id: item?.id,
          created_at: item?.created_at,
          note: item?.note,
          created_by: {
            id: item?.created_by,
            name: item?.employee?.first_name,
            profile_image: item?.employee?.profile_image,
          },
        })),
      })
    );

    setUpdateNotesState(false);
    setAllNotes(
      getNotes?.map((item) => ({
        id: item?.id,
        created_at: item?.created_at,
        note: item?.note,
        created_by: {
          id: item?.created_by,
          name: item?.employee?.first_name,
          profile_image: item?.employee?.profile_image,
        },
      }))
    );
  };
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [allNotes]);

  const reversedNotesArray = [...allNotes].reverse();

  return (
    <div className="w-full mx-auto p-4 h-[600px] min-h-[600px]">
      <style>
        {" "}
        {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`}{" "}
      </style>
      <div className="bg-white  rounded-lg relative">
        <div className="absolute top-0 h-full left-[3.5px] flex items-center">
          <div className="w-px bg-gray-200 h-full"></div>
        </div>
        <div className="flex items-start mb-6 gap-4">
          <div className="flex flex-col items-center relative top-[18px] -left-[4.5px] z-10">
            <div className="bg-blue-500 h-4 w-4 rounded-full mb-2" />
            <div className="flex-1 w-px bg-transparent"></div>
          </div>
          <div className="flex mb-4 w-full">
            <div className="flex-shrink-0">
              <img
                className="h-12 w-12 rounded-full"
                src={currentUserImage || "/assets/images/defaultAvatar.png"}
                alt="User"
              />
            </div>
            <div className="ml-3 w-full flex items-center gap-2 relative">
              <input
                type="text"
                placeholder="Write comment here..."
                className="flex-1 p-2 border border-gray-300 rounded-lg h-[42px] text-sm"
                onFocus={() => {}}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddNote();
                  }
                }}
                onChange={(e) => handleChangeNoteInput(e.target.value)}
                value={note}
              />
              <button
                type="button"
                className="cursor-pointer text-blue-700 rounded-full p-1"
                onClick={() => {
                  if (note.length < 1 || updateNotesState) return;
                  handleAddNote();
                }}
                disabled={note.length < 1 || updateNotesState} // disabled özelliği ekleyerek erişilebilirlik sağlar
              >
                <Icons.Send
                  className={`w-4 h-4 ${
                    note.length < 1 || updateNotesState
                      ? "text-gray-300"
                      : "text-blue-700"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
        <div ref={scrollableDivRef} className="overflow-y-auto h-[450px]">
          {reversedNotesArray?.map((comment, index) => (
            <div
              key={comment.id}
              className={`flex items-start mb-6 gap-4 ${
                lastAction === "add" && index === allNotes.length - 1
                  ? "new-item"
                  : ""
              } rounded-[8px]
          ${
            deleteList.find((item) => item === comment.id) ? "bg-red-100" : ""
          }`}
            >
              <div className="flex flex-col items-center relative top-4 z-10">
                <div className="bg-blue-500 h-2 w-2 rounded-full mb-2" />
                <div className="flex-1 w-px bg-transparent"></div>
              </div>
              <div className="ml-2 flex flex-col gap-1 w-full">
                <div className="flex items-center justify-between">
                  <div>
                    <span className="text-sm text-blue-700 font-semibold">
                      {howLongFromNow(comment.created_at, true, true)}
                    </span>
                    <span className="mx-2 text-gray-500 text-sm">
                      {moment(comment.created_at).format(
                        "MMMM D, YYYY hh:mm a"
                      )}
                    </span>
                  </div>
                  <button
                    type="button"
                    aria-label="Delete comment"
                    className={`justify-center items-center flex cursor-pointer hover:text-red-600 rounded-full transition 
    ${deleteList.find((item) => item === comment.id) ? "hidden" : ""}
    ${
      (comment?.created_by?.id?.id || comment?.created_by?.id) !== currentUserId
        ? "cursor-not-allowed text-gray-200 hover:bg-none hover:text-gray-200"
        : "hover:bg-red-100"
    }
  `}
                    onClick={() => {
                      if (
                        (comment?.created_by?.id?.id ||
                          comment?.created_by?.id) !== currentUserId
                      )
                        return;

                      dispatch(
                        updateRow({
                          path: `orders.[id:${id}].notes_count`,
                          value: allNotes.length - 1,
                        })
                      );

                      dispatch(
                        updateRow({
                          path: `orders.[id:${id}]._updatedRow`,
                          value: true,
                        })
                      );
                      setTimeout(() => {
                        dispatch(
                          updateRow({
                            path: `orders.[id:${id}]._updatedRow`,
                            value: false,
                          })
                        );
                      }, 5000);

                      handleDeleteNote(comment?.id);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        e.stopPropagation();
                        if (
                          (comment?.created_by?.id?.id ||
                            comment?.created_by?.id) !== currentUserId
                        )
                          return;
                        handleDeleteNote(comment?.id);
                      }
                    }}
                    disabled={
                      (comment?.created_by?.id?.id ||
                        comment?.created_by?.id) !== currentUserId
                    }
                  >
                    <Icons.TrashBin className="h-6 w-6 text-gray-300 hover:text-red-500" />
                  </button>
                </div>
                {comment?.created_by ? (
                  <div className=" flex items-center font-bold gap-2.5">
                    <img
                      className="h-6 w-6 rounded-full"
                      src={
                        comment?.created_by?.image_url ||
                        comment?.created_by?.id?.image_url ||
                        "/assets/images/defaultAvatar.png"
                      }
                      alt="User"
                    />
                    {util.upperCaseString(
                      comment?.created_by?.name ||
                        comment?.created_by?.id?.name,
                      "word"
                    )}
                  </div>
                ) : (
                  <div className="text-gray-400">Deleted User</div>
                )}
                <div className="text-gray-800">{comment.note}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommentTimeline;

CommentTimeline.propTypes = {
  id: PropTypes.shape({
    order: PropTypes.any,
  }),
  allNotes: PropTypes.array,
  setAllNotes: PropTypes.func.isRequired,
};
