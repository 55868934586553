import React from 'react'
import {MdError} from "react-icons/md"

const ErrorComponent = ({errorMessage}) => {
    return (
        <div className='text-red-600'>
            <MdError className='inline mr-[5px]'/>
            <span>
                {errorMessage}
            </span>
        </div>
    )
}

export default ErrorComponent