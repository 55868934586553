import {useEffect, useState} from "react";
import MainModal from "../../dynamic-page/MainModal";
import Icons from "../../orders/icons";
import Modalfooter from "../../dynamic-page/elements/ModalFooter";
import axios from "axios";
import Pending from "../../dynamic-page/elements/pending";
import callEndpoint from "../../dynamic-page/util/callEndpoint";
import Util from "../../dynamic-page/util";
import {toast} from "react-toastify";

const useFetch = (url, dependencyArray) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await axios.get(url);
                setData(res?.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };
        fetchData();
    }, [url, ...dependencyArray]);

    // also export a function to refetch the data

    const refetch = async () => {
        setLoading(true);
        try {
            const res = await axios.get(url);
            setData(res?.data);
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    };

    return {data: data, loading, error, refetch};
};

const DownloadDocs = ({onClose, id}) => {
    const [dragging, setDragging] = useState(false);
    const [files, setFiles] = useState();
    const [uploading, setUploading] = useState(false);
    const [url, setUrl] = useState(`api/v1/accounts/${id}/documents`);
    const baseUrl = `accounts/${id}/documents`;

    const {data, loading, error, refetch} = useFetch(url, [files]);
    const handleFileChange = (e) => {
        uploadFile(e.target.files);
        e.target.value = null;
        e.target.removeEventListener("change", handleFileChange);
        e.target.addEventListener("change", handleFileChange);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const droppedFile = e.dataTransfer.files;
        const filesArray = Array.from(droppedFile);
        // check for the files types
        const allowedTypes = [
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
            "text/plain",
            "text/csv",
        ];
        if (!filesArray.every((file) => allowedTypes.includes(file.type))) {
            toast.info("Invalid file type");
            return;
        }
        uploadFile(droppedFile);
    };

    const uploadFile = (file) => {
        if (file.length === 0) return;
        let files = Array.from(file);

        const notifications = []; // Array to store all notifications

        // Check for files larger than 10MB
        const bigFiles = files.filter((file) => file.size > 10000000);
        if (bigFiles.length > 0) {
            bigFiles.forEach((file) => {
                notifications.push(
                    <li className={"opacity-50"}>{file.name} is too large.</li>
                );
            });
            files = files.filter((file) => file.size <= 10000000);
        }

        // Check for invalid file types
        const allowedTypes = [
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
            "text/plain",
            "text/csv"
        ];
        const invalidFiles = files.filter((file) => !allowedTypes.includes(file.type));
        if (invalidFiles.length > 0) {
            invalidFiles.forEach((file) => {
                notifications.push(
                    <li className={"opacity-50"}>{file.name} is invalid.</li>
                );
            });
            files = files.filter((file) => allowedTypes.includes(file.type));
        }

        // Display combined notification
        if (notifications.length > 0) {
            toast.info(
                <>
                    <b>File Upload Warnings:</b>
                    <ul className={"list-disc list-inside my-[5px]"}>{notifications}</ul>
                </>
            );
        }

        const formData = {identity_documents: files};
        setUploading(true);
        callEndpoint({
            title: "Uploading",
            url: baseUrl + "/upload",
            method: "POST",
            data: formData,
            toast: (res) => {
                return res?.data?.type === "success"
                    ? {
                        type: "success",
                        message: "File uploaded successfully",
                        successes: res?.data?.data?.map(
                            (val) => val.file_name + " uploaded successfully"
                        ),
                    }
                    : {
                        type: "error",
                        message: res.data?.message,
                        errors: res.data?.errors,
                    };
            },
        })
            .then((res) => {
                // setFiles(res?.data);
                refetch();
            })
            .catch((error) => {
            })
            .finally(() => setUploading(false));
    };

    const onHandlePrevious = () => {
        let current_page = data?.meta?.current_page;
        setUrl(`api/v1/accounts/${id}/documents?page=${current_page - 1}`);
    };

    const onHandleNext = () => {
        let current_page = data?.meta?.current_page;
        setUrl(`api/v1/accounts/${id}/documents?page=${current_page + 1}`);
    };

    const onHandlePage = (page) => {
        setUrl(`api/v1/accounts/${id}/documents?page=${page}`);
    };

    const handleDelete = (id, callBack) => {
        callBack(true);
        callEndpoint({
            title: "Deleting",
            url: baseUrl + "/delete",
            method: "DELETE",
            data: {identity_documents: [id]},
            pureData: true,
            toast: (res) =>
                res?.data?.type === "success"
                    ? {type: "success", message: "File deleted successfully"}
                    : null,
        })
            .then((res) => {
                // setFiles(res?.data);
                refetch();
            })
            .catch((error) => {
            })
            .finally(() => callBack(false));
    };

    const handleDownload = (fileId) => {
        const base = process.env.REACT_APP_ARBITBOX_API_ENDPOINT;
        const url = `${base}/api/v1/accounts/${id}/documents${
            fileId ? "/" + fileId : ""
        }/download`;

        const link = document.createElement("a");
        link.href = url;
        link.download = "account-documents.zip";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const footerFunction = () => {
        return (
            data?.data?.length > 0 && (
                <span
                    className="text-blue-700 text-[14px] font-medium cursor-pointer"
                    onClick={() => handleDownload()}
                >
          Download All
        </span>
            )
        );
    };

    const handleClose = () => {
        onClose(loading ? null : data);
    };

    return (
        <MainModal
            containerClassName="!pb-0 !px-0"
            item={{
                title: "Account Identity Documents",
                cancel: handleClose,
                // submit: formik.handleSubmit,
                view: (item) => (
                    <>
                        <style>{`
                      .drag-drop-area {
                        border: 2px dashed #E5E7EB;
                        padding: 20px;
                        text-align: center;
                        cursor: pointer;
                        transition: all 0.9s ease;
                        position: relative; 
                    }
                    
                    .drag-drop-area.dragging {
                        border-color: #007bff; /* Change the color as per your design */
                        background-color: rgba(0, 123, 255, 0.05);
                        transition: all 0.9s ease;
                    }

                    .drag-drop-area.dragging:after {
                        width: 100%;
                        content: "Drop here";
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        top: 50%;
                        align-items: center;
                        left: 50%;
                        border-radius: 10px;
                        transform: translate(-50%, -50%);
                        color: #007bff;
                        background-color: #ebf5ff;
                        font-size: 24px;
                        height: 100%;
                        font-weight: bold;
                        transition: background-color 0.9s ease;
                    }
                    
                    
                      `}</style>
                        <div className="flex flex-col gap-2 px-4 py-2 w-[672px] border-t border-b">
                            <label htmlFor="file-upload">
                                <div
                                    className={`flex flex-col items-center h-[160px] justify-center bg-gray-50 border-2 border-dashed border-gray-400 rounded-lg p-4  drag-drop-area ${
                                        dragging ? "dragging" : ""
                                    }`}
                                    onDragOver={handleDragOver}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    <input
                                        type="file"
                                        id="file-upload"
                                        multiple
                                        className="hidden"
                                        accept=".pdf,.jpg,.jpeg,.png,text/plain, text/csv"
                                        onChange={handleFileChange}
                                        disabled={uploading}
                                    />
                                    <label
                                        htmlFor="file-upload"
                                        className="flex flex-col items-center gap-2 cursor-pointer text-gray-500"
                                    >
                                        <Icons.uploadOutline className="w-5 h-5"/>
                                        <span className="text-gray-500 text-sm">
                      <span className="font-semibold text-sm">
                        Click to upload
                      </span>{" "}
                                            or drag and drop
                    </span>
                                    </label>
                                    <span className="mt-2 text-xs text-gray-500 font-semibold">
                    Max. File Size: 10MB
                  </span>
                                    <span className="text-xs text-gray-500">
                    Accepted formats are pdf, jpg, jpeg, png and txt.
                  </span>
                                </div>
                            </label>
                            {loading ? (
                                <div className="w-[640px] h-[197px]">
                                    <Pending/>
                                </div>
                            ) : (
                                <div className=" grid grid-cols-3 grid-rows-3 gap-1 w-[640px] h-[197px] ">
                                    {data?.data?.length > 0 ? (
                                        data?.data?.map((item, index) => (
                                            <FileItem
                                                item={item}
                                                key={item?.id}
                                                handleDelete={handleDelete}
                                                handleDownload={handleDownload}
                                            />
                                        ))
                                    ) : (
                                        <div
                                            className="text-gray-500 text-lg w-full col-span-3 h-full flex justify-center items-center text-center font-bold font-['Inter'] leading-[18px]">
                                            No files uploaded yet
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="px-2">
                            <Modalfooter
                                meta={data?.meta}
                                onPrevious={onHandlePrevious}
                                onNext={onHandleNext}
                                onPage={onHandlePage}
                                loading={loading}
                                footerFunction={footerFunction}
                            />
                        </div>
                    </>
                ),
            }}
        />
    );
};

function formatFileSize(sizeInMB) {
    if (sizeInMB < 1) {
        const sizeInKB = sizeInMB * 1024;
        return sizeInKB?.toFixed(2).replace(".", ",") + " KB";
    } else {
        return sizeInMB?.toFixed(2).replace(".", ",") + " MB";
    }
}

const FileItem = ({item, handleDelete, handleDownload}) => {
    const [disabled, setDisabled] = useState(false);
    return (
        <div
            className=" h-[63px] col-span-1 p-1 bg-gray-50 rounded-lg border-[3px] border-gray-100 justify-start items-center gap-6 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-center items-start gap-1.5 inline-flex">
                <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <Icons.download
                        className="w-5 h-5 text-gray-600 hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                            handleDownload(item?.id);
                        }}
                    />
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                        <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                            <div
                                className="grow shrink basis-0 text-gray-900 text-sm font-medium font-['Inter'] leading-[21px]">
                                {item?.file_name ? (
                                    //   <Util.popUp
                                    //     toggle={() => <>{item?.file_name?.slice(0, 10) + "..."}</>}
                                    //     action={"hover"}
                                    //   >
                                    //     <div className="p-3">{item?.file_name}</div>
                                    //   </Util.popUp>
                                    // <Util.expandableText
                                    //   maxLines={1}
                                    //   tooltip={true}
                                    //   hideCopy={true}
                                    // >
                                    //   {item?.file_name}
                                    // </Util.expandableText>
                                    <TruncatedText
                                        text={item?.file_name}
                                        maxWidth={118}
                                        className="text-gray-900 text-sm font-medium font-['Inter'] leading-[21px]"
                                    />
                                ) : (
                                    "-"
                                )}
                            </div>
                        </div>
                        <div className="self-stretch justify-between items-center inline-flex">
                            <div className="justify-start items-start gap-2.5 flex">
                                <div
                                    className="text-center text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]">
                                    {formatFileSize(item?.size_in_mb)}
                                </div>
                            </div>
                            <div className="justify-start items-start gap-2.5 flex">
                                <div
                                    className="text-center text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]">
                                    {/* the extention in the item url */}
                                    {item?.url.split(".").pop()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Icons.trash
                className={`w-4 h-4 text-gray-300 hover:text-red-400 ${
                    disabled ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() => {
                    if (disabled) return;
                    handleDelete(item?.id, setDisabled);
                }}
            />
        </div>
    );
};

export default DownloadDocs;

function TruncatedText({text, maxWidth, className}) {
    const style = {
        maxWidth: `${maxWidth}px`,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    };

    return (
        <Util.popUp
            toggle={() => (
                <div className={className} style={style}>
                    {text}
                </div>
            )}
            action={"hover"}
        >
            {text}
        </Util.popUp>
    );
}
