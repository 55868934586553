import * as Yup from "yup";

import upperCaseString from "../../dynamic-page/util/upperCaseString";

const FormView = (item) => {
    const innerSubmit = [
        {
            label: "Save",
            type: "primary",
            action: "save",
        },
    ];

    return {
        title: upperCaseString(item?.id ? "Edit" : "Add") + " Brand ",
        submit: innerSubmit,
        // close: !item?.id,
        // cancel: !item?.id ? false : true,
        viewModel: (formDefaultValue) => {
            return [
                {
                    name: "name",
                    label: "Name",
                    placeholder: "Enter Name of brand",
                    type: "Input",
                    defaultValue: formDefaultValue?.name || null,
                    validation: Yup.string().required("Name is required"),
                    className: "col-span-2",
                },
            ];
        },
    };
};
export default FormView;
