import upperCaseString from "../../dynamic-page/util/upperCaseString";

const filtersView = (selectedFilers, filtersMeta) => {
    return [
        {
            name: "supply_channel_type",
            label: "Type",
            placeholder: "Filter by Type",
            type: "Checkbox",
            list: filtersMeta?.map((item) => ({
                label: upperCaseString(item?.name),
                value: item?.id,
                count: item?.count,
            })),
            defaultValue: selectedFilers?.["supply_channel_type"],
            toggle: true,
            clear: true,
            features: {
                isMulti: true,
            },
            checkAll: true,
        },
        {
            name: "country",
            label: "Country",
            placeholder: "Filter by Country",
            type: "Select",
            api: {
                url: "/api/v1/country",
                query: "?filter[name]={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: item?.name,
                    flag: item?.flag,
                }),
            },
            features: {
                isMulti: true,
                formatOptionLabel: ({label, flag}) => (
                    <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
                className={
                    "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
                }
            >
              <img src={flag.toLowerCase()}/>
            </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                ),
            },
            defaultValue: selectedFilers?.country,
            toggle: true,
            clear: true,
        },
    ];
};

export default filtersView;
