import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";
import {fetchShippingCostSuccess, appendShippingCostSuccess, fetchLoading} from "./index";

const fetchShippingCost = createAsyncThunk(
    "shippingCost/fetchShippingCost",
    async (payload, { dispatch, getState }) => {
        // todo payload can either be an object that contains query , append or a string
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchShippingCost(typeof payload === "string" ? payload : payload.query);
        // todo : we return the data and the append boolean to check if we should append the data or not
        return {data: data , append : payload?.append};
    }
);

const deleteShippingCost = createAsyncThunk(
    "delete:api/v1/shipping-costs",
    async (payload, thunkAPI) => {
        const response = await api.deleteShippingCost(payload)
        return response;
    }
)

const fetchShippingCostFilters = createAsyncThunk(
    "get:api/v1/carrier/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingCostFilters(payload);
        return response;
    }
);

const bulkDeleteShippingCost = createAsyncThunk(
    "delete:api/v1/carrier/delete/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteShippingCost(payload);
        return response;
    }
);

const searchShippingCost = createAsyncThunk(
    "get:api/v1/carrier/search",
    async (payload, thunkAPI) => {
        const response = await api.searchShippingCost(payload);
        return response.data;
    }
);


export {
    fetchShippingCost,
    deleteShippingCost,
    fetchShippingCostFilters,
    bulkDeleteShippingCost,
    searchShippingCost,
};