// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./models-view/table";
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tabsView from "./models-view/tabs";
import actionsView from "./models-view/actions";
import addForm from "./models-view/addForm";
import optionsView from "./models-view/options";

// Main Component
const Categories = () => {

    // Initial Page
    const Page = usePage({
        pageTitle: "Categories",
        api: {
            fetch: "category",
            filters: "category/filters",
            query: "filter[type]=all&showAll=true "
        },
        ModelsView: {
            table: tableView,
            filter: filtersView,
            sort: sortView,
            tabs: tabsView,
            actions: actionsView,
            headerOption: optionsView,
            forms: {
                add: addForm,
            }
        },
        pageSettings: {
            newDesign: true,
            rowStyles: {
                className: '!px-[16px] !py-[8px] text-[14px] !items-center justify-center flex  !w-max !min-w-auto',
                'category tree': {
                    td: 'w-[100%]',
                    className: '!min-w-[300px] !w-[100%] !items-center !justify-center !text-center'
                },
                'name': {
                    className: '!min-w-[300px] max-w-[500px] !w-[100%] !items-center !justify-center !text-center'
                },
            }
        }
    })

    // return View
    return Page

};

export default Categories;