import summary from "./summary"
import recipient from "./recipient"
import Icon from "../../icons";
import React from "react";

export default {
    summary,
    recipient
}


const tabList = [
    {id: "summary", name: "Summary", icon: <Icon.adjustment className="w-[12px] h-[12px]"/>},
    // {id: "recipient", name: "Recipient", icon: <Icon.user className="w-[12px] h-[12px]"/>}
]
export {tabList}