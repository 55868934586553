import axios from "axios";
import constant from "../../constant";

const fetchProducts = async (data) => {
  let url = constant.APIURL.CALCULATOR_DATAS;

  const { payload, refresh, from_state_id } = data;
  let billableWeight;

  if (
    (payload.length * payload.width * payload.height) / payload.dim_divison >
    payload.weight
  ) {
    billableWeight =
      (payload.length * payload.width * payload.height) / payload.dim_divison;
  } else {
    billableWeight = parseFloat(payload.weight);
  }

  if (!refresh) {
    const response = await axios.get(url, {
      params: {
        to_state_id: payload.state,
        billable_weight: billableWeight,
        // todo it has to be fixed with state?.id
        from_state_id: from_state_id,
      },
      withCredentials: true,
    });
    return response.data;
  } else {
    const response = await axios.get(url, {
      params: {
        to_state_id: payload.state,
        billable_weight: billableWeight,
        from_state_id: from_state_id,
        fresh: 1,
      },
      withCredentials: true,
    });
    return response.data;
  }
};

export default {
  fetchProducts,
};
