import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {updateRow, updateWeight} from '../../../store/target-seller-folder';
import notifier from '../../dynamic-page/util/notifier';
import SaveWeight2 from './SaveWeight2';
import {HiTrash, HiXCircle} from 'react-icons/hi';

function SupplyCost({cost}) {

    const [typing, setTyping] = React.useState(false)

    const [initial, setInitial] = React.useState(false)
    React.useEffect(() => {
        if (cost?.supply_cost) {
            setInitial(true)
        }
    }, [cost])


    const dispatch = useDispatch()
    const data = useSelector((state) => state?.targetSellerFolder.targetSellerData);
    const weightInRedux = data?.find(item => item?.id === cost?.orderId)?.supply_cost || ""
    const handleSaveWeight = async (t) => {
        await dispatch(updateWeight(
            {id: cost?.orderId, payload: {supply_cost: Number(t)}}
        )).then((rs) => {

            if (rs?.payload?.type === "success") {
                const w = rs?.payload?.data?.supply_cost
                notifier({message: `Supply Cost updated to  ${w}`, type: rs?.payload?.type})
                // dispatch(fetchSingleOrder(weight?.orderId)).then((rs)=> {
                //     console.log(rs.payload.data[0])
                dispatch(updateRow({path: `targetSellerData.[id:${cost?.orderId}]`, value: rs.payload.data}))
                // })

            } else {
                notifier({message: rs?.payload?.message, type: rs?.payload?.type})
            }

        })

        return true
    }

    return (
        <div className={"flex flex-col gap-[4px] justify-center"}>
            {
                (!initial) ?
                    <div>
                        <div className=" flex-col justify-start items-start inline-flex">
                            <div className="self-stretch    flex-col justify-between items-start gap-1 flex">
                                <div className="flex-col justify-start items-start flex">
                                    <div className="text-gray-700 text-xs font-medium leading-3">FBA Max. Ideal Cost
                                    </div>
                                    <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                                        <div className="text-orange-700 text-[24px] font-bold leading-[24px]">
                                            {cost?.fba_max_ideal_cost ? '₺' + cost?.fba_max_ideal_cost : '-'}
                                        </div>
                                        <div className="w-4 h-4 relative"/>
                                    </div>
                                </div>
                                <div className="flex-col justify-start items-start flex">
                                    <div className="text-gray-700 text-xs font-medium leading-3">FBM Max. Ideal Cost
                                    </div>
                                    <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                                        <div className="text-blue-700 text-[24px] font-bold leading-[24px]">
                                            {cost?.fbm_max_ideal_cost ? '₺' + cost?.fbm_max_ideal_cost : '-'}
                                        </div>
                                        <div className="w-4 h-4 relative"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='text-blue-700 text-start text-xs font-normal leading-3 cursor-pointer'
                           onClick={() => {
                               setTyping(true)
                               setInitial(true)
                           }}>Enter Supply Cost</p>
                    </div> : (
                        <div className='flex justify-center items-center'>
                            <div className={"flex flex-col gap-[4px] justify-center items-end"}>

                                <SaveWeight2 text={cost?.supply_cost} onSave={handleSaveWeight}
                                             className={"min-w-max whitespace-no-wrap group-active:text-pink-800 hover:text-pink-600 flex text-[24px] text-pink-700 font-[700]"}/>

                                <div
                                    className="text-gray-500 text-[16px]  font-medium leading-[16px]">{cost?.supply_cost_usd ? '$' + cost?.supply_cost_usd : '-'}</div>
                            </div>
                            <div className='flex flex-col items-center justify-center'>
                                <HiTrash className={"text-[#F87171] text-[20px] ml-[4px] cursor-pointer"} onClick={() => {
                                    dispatch(updateWeight({
                                        id: cost?.orderId,
                                        payload: {supply_cost: 0}
                                    })).then((rs) => {
                                        if (rs?.payload?.type === "success") {
                                            notifier({message: `Supply Cost deleted`, type: "success"})
                                            dispatch(updateRow({
                                                path: `targetSellerData.[id:${cost?.orderId}]`,
                                                value: rs.payload.data
                                            }))
                                            setTyping(false)
                                            setInitial(false)
                                        } else {
                                            notifier({message: rs?.payload?.message, type: rs?.payload?.type})
                                        }
                                    })
                                }}/>
                                <HiXCircle className={"text-[#F87171] text-[20px] ml-[4px] cursor-pointer"} onClick={() => {
                                    setTyping(false)
                                    setInitial(false)
                                }}/>
                            </div>
                        </div>

                    )

            }
        </div>
    )
}

export default SupplyCost