import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchBrands = createAsyncThunk(
    "get:api/v1/brands",
    async (payload, thunkAPI) => {
        const response = await api.fetchBrands(payload);
        return response;
    }
);

const createBrand = createAsyncThunk(
    "post:api/v1/brands",
    async (payload, thunkAPI) => {
        const response = await api.createBrand(payload);
        return response;
    }
);

const updateBrand = createAsyncThunk(
    "put:api/v1/brands",
    async (payload, thunkAPI) => {
        const response = await api.updateBrand(payload);
        return response;
    }
);

const deleteBrand = createAsyncThunk(
    "delete:api/v1/brands",
    async (payload, thunkAPI) => {
        const response = await api.deleteBrand(payload);
        return response
    }
);

const searchBrands = createAsyncThunk(
    "get:api/v1/brands/search",
    async (payload, thunkAPI) => {
        const response = await api.searchBrands(payload);
        return response.data;
    }
);

const assignEmployee = createAsyncThunk(
    "put:api/v1/brands/assign",
    async (payload, thunkAPI) => {
        const response = await api.assignEmployee(payload);
        return response;
    }
);

const unassignEmployee = createAsyncThunk(
    "put:api/v1/brands/unassign",
    async (payload, thunkAPI) => {
        const response = await api.unassignEmployee(payload);
        return response;
    }
);

export {fetchBrands, createBrand, updateBrand, deleteBrand, searchBrands, assignEmployee, unassignEmployee};
