import React, { useEffect, useState } from "react";
import axios from "axios";

import MainModal from "../../dynamic-page/MainModal";
import Util from "../../dynamic-page/util";
import UI from "../../dynamic-form/elements";
import Icons from "../../orders/icons";
import { HiExternalLink } from "react-icons/hi";
import upperCase from "../../dynamic-page/util/upperCaseString";
import SelectItems from "./searchAndConnect";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { updateSupplyListing } from "../../../store/supply-channel-listings/thunk";
import { Tooltip } from "react-tooltip";

// Main Component: ConnectChannelModal
const ConnectChannelModal = React.memo(
  ({ onClose, product_id, callBack, defaultListings, updateRows }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState({
      products: [],
      supplyChannels: [],
    });

    const [defaultProduct, setDefaultProduct] = useState(null);
    const [defaultListingItems, setDefaultListingItems] = useState(null);
    const [connectLoading, setConnectLoading] = useState({});

    useEffect(() => {
      if (product_id) {
        const fetchProducts = async (params) => {
          let url = "api/v1/product/" + product_id;
          if (params) url = url.concat(params);
          const response = await axios.get(url, { withCredentials: true });
          setDefaultProduct(mapProducts(response.data?.data));
          setData((prev) => ({
            ...prev,
            products: [mapProducts(response.data?.data)],
          }));
          // return
        };
        fetchProducts();
      }
      if (defaultListings) {
        setData((prev) => ({
          ...prev,
          supplyChannels: defaultListings.map(mapListings),
        }));
        setDefaultListingItems(defaultListings.map(mapListings));
      }
    }, [product_id]);

    const mapProducts = (item) => ({
      id: item.id,
      label: item.title,
      image: item.image,
      apid: item.apid,
      type: item.type,
      brand: item?.brand?.name,
      assign: item?.brand?.assigned,
      numbers: {
        "Supply CL": item.supply_channels_count,
        "Sales CL": item.sales_channels_count,
        Inventory: item.inventory_count,
        Orders: 0,
        "Avg. Cost": item?.average_cost_usd
          ? `$${item?.average_cost_usd}`
          : "-",
      },
    });

    const mapListings = (item) => ({
      id: item.id,
      identifier: item.identifier,
      url: item.url,
      label: item.title,
      channel: item.channel?.name,
      brand: item?.brand?.name,
      image: item?.image,
      is_trackable: item?.is_trackable,
      ...(item?.is_trackable && { quantity: item?.quantity }),
    });

    const onConnect = () => {
      data?.supplyChannels
        ?.filter((item) => {
          // return only the ones that eiither not in connectLoading or failed
          return (
            connectLoading[item.id] === "Failed!" || !connectLoading[item.id]
          );
        })
        ?.forEach((supplyChannel) => {
          setConnectLoading((prevState) => ({
            ...prevState,
            [supplyChannel.id]: "Connecting...",
          }));
          dispatch(
            updateSupplyListing({
              id: supplyChannel?.id,
              payload: {
                is_trackable: supplyChannel?.is_trackable,
                product_id: data?.products[0]?.id,
                ...(supplyChannel?.is_trackable && {
                  quantity: supplyChannel?.quantity,
                }),
              },
            })
          ).then((result) => {
            if (result?.payload?.type === "success") {
              if (product_id) callBack();
              else updateRows([supplyChannel.id]);

              setConnectLoading((prevState) => ({
                ...prevState,
                [supplyChannel.id]: "Connected!",
              }));
              // setData(prev => ({
              //   ...prev,
              //   supplyChannels: prev.supplyChannels.filter((item) => item.id !== supplyChannel.id)
              // }))
            } else {
              setConnectLoading((prevState) => ({
                ...prevState,
                [supplyChannel.id]: "Failed!",
              }));
            }
          });
          //   util.notifier({
          //     type: "success",
          //     message: result?.payload?.message,
          //     errors: result?.payload?.errors
          // })
        });
    };

    return (
      <MainModal
        className="max-w-[1000px]"
        containerClassName="!px-5"
        item={{
          title: "Connect Product",
          cancel: onClose,
          view: () => (
            <div className="app flex flex-col gap-3 border-t pt-2 border-gray-300">
              <SelectItems
                title="Product"
                url="product"
                mapItems={mapProducts}
                createNewRedirect={true}
                defaultItem={defaultProduct}
                ItemCard={ProductItem}
                key="Products"
                data={data}
                setData={setData}
                tooltip={productTooltip}
              />
              <SelectItems
                title="Supply Channel Listings"
                url="supply-channel-listing"
                mapItems={mapListings}
                createNewRedirect={false}
                defaultItem={defaultListingItems}
                ItemCard={SupplyListingItem}
                multiple
                key="supplies"
                data={data}
                connectLoading={connectLoading}
                setData={setData}
                params="filter[has_product]=false&include=product.brand.assigned"
                tooltip={salesTooltip}
              />
              {data?.products?.length > 0 &&
                data?.supplyChannels?.length > 0 &&
                !data?.supplyChannels.every(
                  (item) => connectLoading[item.id] === "Connected!"
                ) && (
                  <div className="flex flex-row justify-end gap-[8px]">
                    <UI.Button
                      item={{
                        label: <div className={"flex flex-row"}>Cancel</div>,
                        buttonType: "alt",
                        className: "h-[37px] !w-[120px] !px-[16px]",
                      }}
                      onClick={() => onClose()}
                    />
                    <UI.Button
                      item={{
                        label: <div className={"flex flex-row"}>Connect</div>,
                        buttonType: "primary",
                        className: "h-[37px] !w-[120px]  !px-[16px]",
                      }}
                      onClick={() => onConnect()}
                    />
                  </div>
                )}

              {Object.keys(connectLoading).length > 0 &&
                data?.supplyChannels.every(
                  (item) => connectLoading[item.id] === "Connected!"
                ) && (
                  <div className="flex flex-row justify-end gap-[8px] sticky bg-white bottom-0 pb-2">
                    <UI.Button
                      item={{
                        label: <div className={"flex flex-row"}>Close</div>,
                        buttonType: "alt",
                        className: "h-[37px] !w-[120px] !px-[16px]",
                      }}
                      onClick={() => onClose()}
                    />
                  </div>
                )}
            </div>
          ),
        }}
      />
    );
  }
);

export default ConnectChannelModal;

const ProductItem = ({
  item,
  index,
  results,
  searching,
  setSelectedProduct,
  setSearching,
  selectedProduct,
  setData,
  data,
}) => {
  const [orders, setOrders] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item && !searching) {
      setLoading(true);
      const fetchOrders = async () => {
        const response = await axios.get(
          `/api/v1/orders/filters?filter[product]=${item.id}`,
          { withCredentials: true }
        );
        setLoading(false);
        if (response.data.data) {
          setOrders(
            response.data.data.statuses?.find((item) => item?.id === 10)
              ?.count || "-"
          );
        }
      };

      fetchOrders();
    }
  }, [item, searching]);

  return (
    <div
      key={index}
      className={`result-item  w-[630px]  ${
        searching ? "h-[144px]" : "h-[176px]"
      } flex items-center p-3 mb-1 border drop-shadow-sm rounded-lg`}
    >
      <img
        src={item.image || "/assets/images/noImage.png"}
        alt={item.label}
        className={`result-image 
           w-[120px] h-[120px]
       object-contain rounded`}
      />
      <div className="result-details flex-1 flex flex-col h-full pl-4 justify-between">
        {/* apid and badge here */}
        <div className="flex justify-between items-center">
          <div className={"flex flex-row gap-[4px]"}>
            <div
              className=" text-xs font-normal leading-[18px] hover:text-blue-800 cursor-pointer"
              onClick={() => window.open(`/product/${item?.id}`, "_blank")}
            >
              {item?.apid}
            </div>
            <Util.copyText
              className={"text-[12px]"}
              text={item?.apid}
              hint={false}
            />
            <HiExternalLink
              className="w-4 h-4  text-blue-700 cursor-pointer"
              onClick={() => window.open(`/product/${item?.id}`, "_blank")}
            />
          </div>
          <div className="flex gap-2">
            {item?.type === 1 ? (
              <div className=" h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-teal-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center whitespace-nowrap text-teal-900 text-xs font-medium leading-[18px]">
                    Single Product
                  </div>
                </div>
              </div>
            ) : (
              <div className=" h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-sky-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center whitespace-nowrap text-indigo-800 text-xs font-medium leading-[18px]">
                    Grouped Product
                  </div>
                </div>
              </div>
            )}
            {!searching && results?.results?.length ? (
              <Icons.trash
                className="w-5 h-5 text-gray-400 cursor-pointer hover:text-red-400"
                onClick={() => {
                  setSelectedProduct([]);
                  setData({ ...data, products: [] });
                  setSearching(true);
                }}
              />
            ) : null}
          </div>
        </div>
        {/* title here */}
        <div>
          <p className="text-[16px] leading-[24px] font-semibold w-[430px] break-words whitespace-pre-wrap ">
            {item?.label?.length > 150
              ? Util.shortenString(item?.label, 150, false,true,null,"top")
              : item?.label}
          </p>
        </div>
        {/* assign */}
        {!searching && results?.results?.length && (
          <div className={"flex justify-between"}>
            <div className={"flex flex-row gap-[4px] text-gray-600"}>
              {item?.brand ? (
                <Util.copyText
                  className={"text-[14px] font-medium text-gray-900"}
                  text={item?.brand}
                  hint={false}
                >
                  {Util.shortenString(upperCase(item?.brand), 30)}{" "}
                </Util.copyText>
              ) : (
                ""
              )}
            </div>

            <div className="flex items-center gap-[2px] !text-[12px]">
              <div
                className={"text-gray-900 flex flex-row gap-[4px] "}
                onClick={() => {
                  window.open(`/product/${item?.id}`, "_blank");
                }}
              >
                <img
                  src={
                    item?.assign?.profile_image ||
                    "/assets/images/defaultAvatar.png"
                  }
                  className={"w-[20px] h-[20px] rounded-full object-contain"}
                />
                {Util.upperCaseString(item?.assign?.name, "word")}
              </div>
            </div>
          </div>
        )}
        {/* numbers here */}
        {searching && results?.results?.length ? (
          <div className="flex justify-end">
            <button
              className="text-blue-500 "
              onClick={() => {
                setSelectedProduct([item]);
                setData({ ...data, products: [item] });
                setSearching(false);
              }}
            >
              Select product
            </button>
          </div>
        ) : (
          <div className="flex justify-between px-4">
            {Object.keys(item?.numbers).map((key, index) => (
              <div key={index} className="flex flex-col gap-0.5 ">
                <p className="text-xs font-bold text-gray-600">{key}</p>
                <p className="text-xl font-bold  text-purple-700">
                  {key === "Orders" ? (
                    loading ? (
                      <Skeleton width={50} height={20} />
                    ) : (
                      orders
                    )
                  ) : (
                    item?.numbers[key]
                  )}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const SupplyListingItem = ({
  item,
  index,
  results,
  searching,
  setSelectedProduct,
  setSearching,
  selectedProduct,
  setData,
  connectLoading,
  data,
  selection,
}) => (
  <div
    key={index}
    className={`result-item w-[630px] ${
      searching ? "h-[144px]" : "h-[176px]"
    } flex items-center p-3 mb-2 border shadow-sm rounded-lg`}
  >
    <img
      src={item.image || "/assets/images/noImage.png"}
      alt={item.label}
      className={`result-image ${
        searching ? "w-[152px] h-[152px]" : "w-[120px] h-[120px]"
      } object-contain rounded`}
    />
    <div className="result-details flex-1 flex flex-col h-full pl-4 justify-between">
      {/* apid and badge here */}
      <div className="flex justify-between items-center">
        <div className={"flex flex-row gap-[4px]"}>
          <div className=" text-sm text-gray-900  font-normal leading-[18px] hover:text-blue-800 cursor-pointer">
            {item?.identifier}
          </div>
          <Util.copyText
            className={"text-[12px]"}
            text={item?.identifier}
            hint={false}
          />
          {item?.url && (
            <HiExternalLink
              className="w-4 h-4  text-blue-700 cursor-pointer"
              onClick={() => window.open(item?.url, "_blank")}
            />
          )}
        </div>
        <div className="flex gap-2">
          <div className="w- h-[22px] justify-start items-start inline-flex">
            <div
              className={`px-2.5 py-0.5 ${
                connectLoading?.[item.id] === "Connected!"
                  ? "bg-green-100"
                  : "bg-indigo-100"
              } rounded-md justify-center items-center gap-1 flex`}
            >
              <div
                className={`text-center whitespace-nowrap ${
                  connectLoading?.[item.id] === "Connected!"
                    ? "text-green-900"
                    : "text-indigo-800"
                }
                                 text-xs font-medium leading-[18px]`}
              >
                {connectLoading?.[item.id] || "Supply Channel Listing"}
              </div>
            </div>
          </div>
          {!searching && results?.results?.length ? (
            <Icons.trash
              className="w-5 h-5 text-gray-400 cursor-pointer hover:text-red-400"
              onClick={() => {
                setSelectedProduct([
                  ...selectedProduct.filter(
                    (product) => product.id !== item.id
                  ),
                ]);
                setData({
                  ...data,
                  supplyChannels: [
                    ...selectedProduct.filter(
                      (product) => product.id !== item.id
                    ),
                  ],
                });
                if (selectedProduct.length === 1) {
                  setSearching(true);
                }
              }}
            />
          ) : null}
        </div>
      </div>
      {/* title here */}
      <div>
        <p className="text-[16px] text-gray-900 leading-[24px] font-semibold">
          {item?.label?.length > 100
            ? Util.shortenString(item?.label, 100, false)
            : item?.label}
        </p>
      </div>
      {/* numbers here */}
      {searching && !selection && results?.results?.length ? (
        <div className="flex justify-end">
          <button
            className={`${
              !selectedProduct?.find((channel) => channel.id === item.id)
                ? "text-blue-500"
                : "text-gray-300 cursor-default"
            }`}
            onClick={() => {
              if (selectedProduct?.find((channel) => channel.id === item.id))
                return;
              setSelectedProduct([...selectedProduct, item]);
              setData({ ...data, supplyChannels: [...selectedProduct, item] });
              setSearching(false);
            }}
          >
            {selectedProduct?.find((channel) => channel.id === item.id)
              ? "Selected"
              : "Select Listing"}
          </button>
        </div>
      ) : (
        <div
          className={
            "flex justify-between text-[14px] font-medium text-gray-900"
          }
        >
          <div className={"flex flex-row gap-[4px] text-gray-900"}>
            {item?.brand ? (
              <Util.copyText
                className={"text-[14px] font-medium text-gray-900"}
                text={item?.brand}
                hint={false}
              >
                {Util.shortenString(upperCase(item?.brand), 30)}{" "}
              </Util.copyText>
            ) : (
              ""
            )}
          </div>

          <div className="flex items-center gap-[2px] ">{item?.channel}</div>
        </div>
      )}
    </div>
  </div>
);

const productTooltip = (
  <>
    <span className="flex ">
      <Icons.info
        className="w-5 h-5  text-gray-300"
        data-tooltip-id="productTooltip"
      />
    </span>
    <Tooltip
      id="productTooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[250px]">
        <p className="text-[14px] font-medium text-gray-900">Select Product</p>
        <p className="text-xs text-gray-600 whitespace-pre-wrap">
          Select a product to connect listing(s) and access detailed supply
          costs and inventory levels.
        </p>
      </div>
    </Tooltip>
  </>
);

const salesTooltip = (
  <>
    <span className="flex ">
      <Icons.info
        className="w-5 h-5  text-gray-300"
        data-tooltip-id="my-tooltip"
      />
    </span>
    <Tooltip
      id="my-tooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[250px]">
        <p className="text-[14px] font-medium text-gray-900">
          Select Listing(s)
        </p>
        <p className="text-xs text-gray-600 whitespace-pre-wrap">
          Please select one or more listings to connect to the product.
        </p>
      </div>
    </Tooltip>
  </>
);
