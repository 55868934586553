/**
 *
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArbitButton from "../sub-elements/button";
import Modal from "../sub-elements/modal";
import ArbitNormalInput from "../sub-elements/normal-input";
import ErrorComponent from "../sub-elements/ErrorComponent";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  fetchAttributes,
  fetchProduct,
  UpdateAttribute,
} from "../../store/products";
import validationHelper from "./validation-helper";
import Dropdown from "../sub-elements/dropdown";
import CreatableSelect from "react-select/creatable";

const ArbitNewAttributeModal = ({
  show,
  onClose,
  attributesData,
  attributeGroups,
  attributeTypes,
  createNewAttribute,
  id,
  attributeLocale,
  productLanguage,
}) => {
  const dispatch = useDispatch();

  const [newAttribute, setNewAttribute] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  const [attributeGroup, setAttributeGroup] = useState("");

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  let attribute;

  if (newAttribute !== "") {
    attribute = attributesData.find((item) => item.name === newAttribute);
  }

  const validationRule = attribute?.validation_rules;

  const [errMessage, setErrorMessage] = useState("");
  const [valid, setValid] = useState(true);

  const setAttributeGroupp = (group) => {
    setAttributeGroup(group);
    setAttributeValue("");
    setValid(true);
    setErrorMessage("");
  };

  const handleFileInputChange = (e) => {
    setAttributeValue(e.target.files[0]);
  };

  const attributeOptions = Array.isArray(attributesData)
    ? attributesData
        .map((item) => {
          return {
            value: item.id,
            label: item.name,
            type: item.type,
            validation_rules: item.validation_rules,
            description: item.description,
          };
        })
        .filter(
          (item) =>
            ![
              22, 23, 20, 21, 39, 15, 73, 3, 4, 5, 6, 13, 26, 7, 75, 29, 1, 74,
              47,
            ].includes(item.value)
        )
    : [];

  const AddAttribute = async () => {
    if (attributeValue === "" || !attributeGroup?.value) {
      setValid(false);
      setErrorMessage("Please enter a valid Attribute and  Value");
    } else {
      const response = await validationHelper(
        validationRule,
        attributeValue,
        newAttribute
      );
      let payload = {
        value: attributeValue,
        locale: attributeLocale,
      };
      if (response === true) {
        setValid(true);
        setErrorMessage("");
        await dispatch(
          UpdateAttribute({
            id,
            attributeId: attributeGroup?.value,
            payload,
          })
        )
          .unwrap()
          .then((response) => {
            if (response?.status === 200) {
              onClose();
              dispatch(fetchProduct({ id, productLanguage }));
              toast.success(
                response?.data?.message || "Successfully Update the Attribute",
                {
                  toastId: "success1",
                }
              );
            } else {
              toast.error(response?.data?.message || "Error ", {
                toastId: "success1",
              });
            }
          })
          .catch((err) => {
            toast.error("Error ", {
              toastId: "success1",
            });
          });
      } else {
        setValid(false);
        setErrorMessage(response);
      }
    }
  };
  let attributeValueText = "";
  if (attributeValue) {
    attributeValueText = "TRUE";
  } else if (!attributeValue) {
    attributeValueText = "FALSE";
  }

  return (
    <>
      <Modal
        title="Add Attribute"
        show={show}
        onClose={onClose}
        modalClassName={"!rounded-lg !w-[672px]"}
        childrenClassName={"!py-4 !pb-6"}
        center={true}
      >
        <div className="flex flex-col  h-fit  ">
          <div className="flex gap-[16px] w-full items-center m-0">
            <div className="flex flex-col items-start gap-2  w-[49%]">
              <div className="flex items-center w-full">
                <babel className="text-sm font-medium text-gray-700">
                  Attribute
                </babel>
              </div>
              {/* <SearchInput
              className=""
              hide_submit
              searchLabel={"Attributes"}
              searchResult={searchResult}
              setNewAttribute={(value) => {
                setNewAttribute(value)
                setAttributeGroup(attributesData.find(item => item?.name === value))
              }}
              // value={searchResult}
              keywords={true}
              onChange={
                (value) => onSearchChange(value)
              }
              createNewAttribute={createNewAttribute}
            /> */}
              <CreatableSelect
                options={attributeOptions}
                placeholder={"Select Attribute"}
                defaultOptions
                cacheOptions
                value={attributeGroup}
                formatCreateLabel={(inputValue) => (
                  <div className="cursor-pointer">
                    <span className="text-blue-700 font-medium ">
                      + Add New:{" "}
                    </span>
                    <span>{inputValue}</span>
                  </div>
                )}
                isValidNewOption={(inputValue) => inputValue.trim().length > 0}
                onCreateOption={(inputValue) => {
                  createNewAttribute();
                }}
                onChange={(e) => {
                  setAttributeGroupp(e);
                  setNewAttribute(
                    attributesData.find((item) => item?.name === e?.label)?.name
                  );
                  setAttributeValue("");
                }}
                optionHeight={50}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    height: 42,
                    // minHeight: 50,
                    // width: 300,
                    borderRadius: 10,
                    background: "#f3f4f6",
                    border: state.isFocused
                      ? "1px solid #00A3FF"
                      : "1px solid #E5E5E5",
                    // border: (brand === '' && submit) && '2px solid red',
                    boxShadow: state.isFocused
                      ? "0px 0px 0px 1px #00A3FF"
                      : "none",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    // borderRadius: 10,
                    backgroundColor: state.isSelected ? "#00A3FF" : "white",
                    color: state.isSelected ? "white" : "black",
                    "&:hover": {
                      backgroundColor: "#00A3FF",
                      color: "white",
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    borderRadius: 10,
                    overflow: "hidden",
                    border: state.isFocused
                      ? "1px solid #00A3FF"
                      : "1px solid #E5E5E5",
                    boxShadow: state.isFocused
                      ? "0px 0px 0px 1px #00A3FF"
                      : "none",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                    },
                  }),
                  container: (provided, state) => ({
                    ...provided,
                    width: "100%",
                  }),
                }}
              />
            </div>
            <div className="flex flex-col items-start gap-2 w-[49%]">
              <babel className="text-sm font-medium text-gray-700">Value</babel>
              {attributeGroup?.type === "DROPDOWN" ? (
                <Dropdown
                  placeholder="Attribute Value"
                  bg_color={"gray-50"}
                  // justify={'evenly'}
                  border_color={"gray"}
                  text_color={"gray"}
                  title={"Attribute Type"}
                  value={attributeValue || ""}
                  items={attributeGroup?.validation_rules?.enum}
                  onSelect={(e) => setAttributeValue(e)}
                  type="dropdown"
                  width={"100%"}
                />
              ) : attributeGroup?.type === "BOOLEAN" ? (
                //date picker
                <Dropdown
                  placeholder="Value"
                  bg_color={"gray-50"}
                  // justify={'evenly'}
                  border_color={"gray"}
                  text_color={"gray"}
                  title={"Attribute Type"}
                  value={attributeValueText}
                  items={["TRUE", "FALSE"]}
                  onSelect={(e) => {
                    if (e === "TRUE") {
                      setAttributeValue(true);
                    }
                    if (e === "FALSE") {
                      setAttributeValue(false);
                    }
                  }}
                  type="dropdown"
                  width="100%"
                />
              ) : attributeGroup?.type === "DATETIME" ? (
                //date time picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={(e) => setAttributeValue(e)}
                  placeholder="Type Attribute Value"
                  type="datetime-local"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "TIME" ? (
                //time picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={setAttributeValue}
                  placeholder="Enter Attribute Value"
                  type="time"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "INTEGER" ||
                attributeGroup?.type === "FLOAT" ? (
                //number picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={(e) => setAttributeValue(e)}
                  placeholder={
                    attributeGroup?.type === "INTEGER"
                      ? "E.G 1,2,3,4"
                      : attributeGroup?.type === "FLOAT"
                      ? "E.G 0.25, 0.62, 1.5"
                      : "Enter Attribute Value"
                  }
                  type="number"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "DATE" ? (
                //date picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={setAttributeValue}
                  placeholder="Enter Attribute Value"
                  type="date"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "DOCUMENT" ? (
                //file picker
                <input
                  label="Value"
                  onChange={handleFileInputChange}
                  placeholder="Enter Attribute Value"
                  type="file"
                  className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                  accept=".txt,.pdf,.doc,.docx,.odt"
                />
              ) : (
                //normal input
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={setAttributeValue}
                  placeholder={
                    attributeGroup?.label === "Email"
                      ? "E.G Hi@gmail.com"
                      : attributeGroup?.label === "Phone"
                      ? "E.G 08012345678"
                      : attributeGroup?.type === "DIMENSION"
                      ? "E.G 2.4 x 4 x 6"
                      : "Enter Attribute Value"
                  }
                  type={
                    attributeGroup?.type === "STRING"
                      ? "text"
                      : attributeGroup?.type === "EMAIL"
                      ? "email"
                      : attributeGroup?.type === "PHONE"
                      ? "tel"
                      : "text"
                  }
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              )}
            </div>
          </div>
          {!valid && (
            <div className="mt-[10px]">
              <ErrorComponent errorMessage={errMessage} />
            </div>
          )}
        </div>
        <div className="flex !w-full  items-center  justify-between">
          <div className="flex items-center gap-1 ">
            <input
              type="checkbox"
              id="scales"
              name="scales"
              disabled={false}
              className={
                "w-[20px] h-[20px]  border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-md "
              }
            />
            <babel htmlFor="scales">Add another</babel>
          </div>
          <div className="flex gap-2 items-center">
            <ArbitButton
              width={"120px"}
              text_color={"blue-600"}
              bg_color={"white"}
              onClick={() => onClose()}
            >
              Cancel
            </ArbitButton>
            <ArbitButton width={"120px"} onClick={() => AddAttribute()}>
              Add
            </ArbitButton>
          </div>
        </div>
      </Modal>
    </>
  );
};
ArbitNewAttributeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  attributesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      validation_rules: PropTypes.array,
      description: PropTypes.string,
    })
  ).isRequired,
  attributeGroups: PropTypes.array,
  attributeTypes: PropTypes.array,
  createNewAttribute: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  attributeLocale: PropTypes.string,
  productLanguage: PropTypes.string,
};

export default ArbitNewAttributeModal;
