import axios from '../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

const fetchShippingTemplate = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGTEMPLATE
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const updateOpportunities = async ({payload}) => {
    const response = await axios.post(constants.APIURL.GET_OPPORTUNITIES_UPDATE, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}

const fetchShippingTemplateFilters = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGTEMPLATE_FILTERS + '?' + params
    const response = await axios.get(url, {withCredentials: true})
    return response

}
const fetchShippingTemplateRestrictions = async () => {
    const response = await axios.get(constants.APIURL.GET_SHIPPING_TEMPLATE_RESTRICTIONS, {withCredentials: true})
    return response
}
const updateBulkShippingTemplateStatus = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_SHIPPING_TEMPLATE, payload, {withCredentials: true})
    return response;
}
const deleteBulkShippingTemplate = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BULK_SHIPPING_TEMPLATE, {data: payload})
    return response;
}
export default {
    fetchShippingTemplate,
    updateOpportunities,
    fetchShippingTemplateFilters,
    updateBulkShippingTemplateStatus,
    deleteBulkShippingTemplate,
    fetchShippingTemplateRestrictions
}
