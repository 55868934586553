import axios from './../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

const fetchListingMatches = async (params) => {
    let url = constants.APIURL.GET_LISTING_MATCH
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const updateListingMatches = async ({id, payload}) => {
    const response = await axios.post(constants.APIURL.GET_LISTING_MATCH + "/" + id, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}
const updateSelectionListingMatches = async ({id, payload}) => {
    const response = await axios.post(constants.APIURL.POST_SELECTION_MATCHING_LISTING, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}


const fetchListingMatchesFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_LISTING_MATCH_FILTERS, {withCredentials: true})
    return response
}


export default {
    fetchListingMatches, fetchListingMatchesFilters, updateListingMatches,
    updateSelectionListingMatches
}
