import React, {useRef} from "react";
import DynamicForm from "../../../dynamic-form";
import UI from "../../../dynamic-form/elements";
import Util from "../../../dynamic-page/util";
import MainModal from "../../../dynamic-page/MainModal";
import Icons from "../../../orders/icons";
import { HiExternalLink } from "react-icons/hi";
import upperCase from "../../../dynamic-page/util/upperCaseString";

const ModalForm = ({form, data, actions, cancel, sourceData, title, view}) => {

    const viewTheme = view ? view : (form) => <>{form}</>
    const myFormRef = useRef(null);

    const getFormData = async () => {
        const recipientData = await myFormRef?.current?.getData(true);
        const recipientValidation = await myFormRef?.current?.validate(true);
        return {...recipientData, _validation: recipientValidation};
    };
    return (
        <MainModal
            item={{
                title: "Bulk Edit" + (sourceData.length > 1 ? ` (${sourceData.length} Items)` : " (Single Item)"),
                submit: (data) => {
                    //submit(data);
                },
                cancel: () => {
                    cancel();
                },
                data: data,
                actionButton:
                    actions ?
                        <div className={"mt-[16px] w-full flex flex-row gap-[8px] justify-end px-[12px]"}>
                            <UI.Button
                                onClick={cancel}
                                item={{
                                    buttonType: "alt",
                                    className: "w-max !h-[56px]"
                                }}
                            >
                                Cancel
                            </UI.Button>
                            {
                                actions.map(i => (
                                    <UI.Button
                                        onClick={
                                            async () => i.onClick(await getFormData())
                                        }
                                        item={{
                                            buttonType: "primary",
                                            className: "w-max !h-[56px]"
                                        }}
                                    >
                                        {i.label}
                                    </UI.Button>
                                ))
                            }

                        </div>
                        : null,
                view: (item) => (
                    <div
                        className={"border-t border-t-gray-200 pt-[20px] mt-[16px] min-w-[550px] flex flex-col px-[12px]"}>
                        {viewTheme(
                            <DynamicForm items={form(data)} ref={myFormRef}/>
                        )}
                    </div>
                )
            }}
            mainClassName={"!py-[30px] "}
        />
    )
}

export default ModalForm;


export const OrderItem = ({
    item,
    index,
    results,
    searching,
    setSelectedProduct,
    setSearching,
    selectedProduct,
    setData,
    data,
    connectLoading,
    selection,
  }) => {
    return (
      <div
        key={index}
        className="result-item w-[630px] h-[176px] flex items-center p-3 mb-2 border shadow-sm rounded-lg "
      >
        <img
          src={item.image || "/assets/images/noImage.png"}
          alt={item.label}
          className="result-image w-[120px] h-[120px] mr-4 object-contain rounded"
        />
        <div className="result-details flex-1 flex flex-col h-full justify-between">
          {/* apid and badge here */}
          <div className="flex justify-between items-center">
            <div className={"flex flex-row gap-[4px] items-center"}>
              <div className=" text-[14px] text-gray-900 font-medium leading-normal ">
                {item?.identifier}
              </div>
              <Util.copyText
                className={"text-[12px]"}
                text={item?.identifier}
                hint={false}
              />
            </div>
          </div>
          {/* title here */}
          <div>
            <p className="text-[16px] leading-[24px] text-gray-900 font-semibold whitespace-pre-wrap">
              {item.label}
            </p>
          </div>
          {/* numbers here */}
          {searching && !selection && results?.results?.length ? (
            <div className="flex justify-end">
              <button
                className={`${
                  !selectedProduct?.find((channel) => channel.id === item.id)
                    ? "text-blue-500"
                    : "text-gray-300 cursor-default"
                }`}
                onClick={() => {
                  if (selectedProduct?.find((channel) => channel.id === item.id))
                    return;
                  setSelectedProduct([...selectedProduct, item]);
                  setData({ ...data, salesChannels: [...selectedProduct, item] });
                  setSearching(false);
                }}
              >
                {selectedProduct?.find((channel) => channel.id === item.id)
                  ? "Selected"
                  : "Select Listing"}
              </button>
            </div>
          ) : (
            <div
              className={
                "flex justify-between text-[14px] font-medium text-gray-900"
              }
            >
              <div className={"flex flex-row gap-[4px] text-gray-900"}>
                {item?.brand}
              </div>
  
              <div className="flex items-center gap-[2px] ">{item?.channel}</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  