import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {fetchPermissions} from "../../../store/permissions/index";
import {fetchUsersPermissions} from "../../../store/users";
import MainModal from "../../dynamic-page/MainModal";

const PermissionsModal = ({onClose, onSubmit, data}) => {
    const permissions = useSelector((state) => state.permission.permissions);

    const dispatch = useDispatch();

    const usersPermission = useSelector(state => state.user.uersPermissions);
    const [myPermission, setMyPermission] = useState([]);

    // setting the permissions to the state
    useEffect(() => {
        dispatch(fetchPermissions())
        dispatch(fetchUsersPermissions(data.id)).then((res) => {
            setMyPermission(res.payload.data)
        })
        const p = {none: "none"}
        domains?.flat()?.forEach(({permissions}) => Object.keys(permissions).forEach(title => p[title] = title));
        setPermit(p);
    }, [data.id, dispatch]);

    // destructuring the permissions
    const {domains, admin} = permissions;

    const [permit, setPermit] = useState({}) //sales_listing_listing

    const cleanName = (name) => {
        return name.replace(' ', '_').toLowerCase();
    }

    const cleanPayload = (payload, key) => {
        return typeof payload[key] == 'undefined' ? 0 : payload[key]
    }


    return (
        <MainModal
            // containerClassName='!relative'
            item={{
                title: "New Supply Request",
                cancel: onClose,
                // submit: formik.handleSubmit,
                view: (item) => (
                    <div>

                        {usersPermission && (
                            <div className="flex flex-col h-fit w-fit ">
                                <div
                                    className="flex bg-gray-100 h-[40px] items-center font-medium text-sm pl-[16px] py-[9px] ">
                                    <h5 className=" grow">PERMISSION</h5>
                                    <h5 className="w-[116px] flex justify-center">NONE</h5>
                                    <h5 className="w-[116px] flex justify-center">VIEW</h5>
                                    <h5 className="w-[116px] flex justify-center">EDIT</h5>
                                    <h5 className="w-[116px] flex justify-center">DELETE</h5>
                                </div>

                                <div className="flex items-center justify-between h-[56px] border-b">
                                    <div className="flex flex-col justify-center py-[12px] px-[16px]  ">
                                        <h5 className="font-normal">{admin?.name}</h5>
                                        <p className="text-gray-500 text-xs">Access to all functionality including user
                                            management. Unlimited Access.</p>
                                    </div>

                                    {Object.keys(permit)?.map((permitted, id) => <div
                                        className="w-[116px] flex justify-center grow"><input
                                        checked={function check() {
                                            if (permitted === 'none') {
                                                if (myPermission.length == 0) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else {
                                                const multipleExist = admin.permissions[permitted].every(value => {
                                                    return myPermission?.includes(value);
                                                });
                                                return multipleExist
                                            }

                                        }()}
                                        onClick={() => {
                                            if (permitted === 'none') {
                                                setMyPermission([])
                                            } else if (permitted === 'view') {
                                                setMyPermission(admin.permissions[permitted])
                                            } else if (permitted === 'edit') {
                                                setMyPermission(admin.permissions[permitted].concat(admin.permissions['view']))
                                            } else if (permitted === 'delete') {
                                                setMyPermission(admin.permissions[permitted].concat(admin.permissions['view'].concat(admin.permissions['edit'])))
                                            }
                                        }
                                        }
                                        key={id} type="radio" className=""/></div>)}
                                </div>


                                {domains?.map(({
                                                   name: domainName,
                                                   label,
                                                   subdomain,
                                                   permissions: domainPermissions
                                               }, index) =>
                                    <div key={index}>
                                        <div className="flex items-center h-[56px] border-b">
                                            <div className="flex flex-col justify-center py-[12px] px-[16px] grow "
                                                 key={index}>
                                                <h5 className="font-normal">{domainName}</h5>
                                                <p className="text-gray-500 text-xs">{label}</p>
                                            </div>
                                            {Object.keys(permit)?.map((permitted, id) => <>{
                                                    <div className="w-[116px] flex justify-center">
                                                        <input type="radio"
                                                            //  name={`${cleanName(domainName)}`}
                                                               onClick={() => {
                                                                   if (permitted === 'none') {
                                                                       const notPermitted = Object.keys(permit).filter((item) => item !== 'none');
                                                                       notPermitted.forEach((notPermitted) => {
                                                                           domainPermissions[notPermitted].forEach((perm) => {
                                                                               if (myPermission.includes(perm)) {
                                                                                   setMyPermission(prev => prev.filter((item) => item !== perm))
                                                                               }
                                                                           })
                                                                       })
                                                                   } else if (permitted === 'view') {
                                                                       const notPermitted = Object.keys(permit).filter((item) => item !== permitted && item !== 'none');
                                                                       notPermitted.forEach((notPermitted) => {
                                                                           domainPermissions[notPermitted].forEach((perm) => {
                                                                               if (myPermission.includes(perm)) {
                                                                                   setMyPermission(prev => prev.filter((item) => item !== perm))
                                                                               }
                                                                           })
                                                                       })
                                                                       const multipleExist = domainPermissions[permitted].every(value => {
                                                                           return myPermission?.includes(value);
                                                                       });
                                                                       if (!multipleExist) {
                                                                           setMyPermission(prev => [...prev, ...domainPermissions[permitted]])
                                                                       }
                                                                   } else if (permitted === 'edit') {

                                                                       const notPermitted = Object.keys(permit).filter((item) => item !== permitted && item !== 'none' && item !== 'view');
                                                                       notPermitted.forEach((notPermitted) => {
                                                                           domainPermissions[notPermitted].forEach((perm) => {
                                                                               if (myPermission.includes(perm)) {
                                                                                   setMyPermission(prev => prev.filter((item) => item !== perm))
                                                                               }
                                                                           })
                                                                       })
                                                                       const multipleExist = domainPermissions[permitted].every(value => {
                                                                           return myPermission?.includes(value);
                                                                       });
                                                                       const multipleExistView = domainPermissions['view'].every(value => {
                                                                           return myPermission?.includes(value);
                                                                       });
                                                                       if (!multipleExist && multipleExistView) {
                                                                           setMyPermission(prev => [...prev, ...domainPermissions[permitted]])
                                                                       } else if (!multipleExist && !multipleExistView) {
                                                                           setMyPermission(prev => [...prev, ...domainPermissions['view'], ...domainPermissions[permitted]])
                                                                       }
                                                                   } else if (permitted === 'delete') {
                                                                       const multipleExist = domainPermissions[permitted].every(value => {
                                                                               return myPermission?.includes(value);
                                                                           }
                                                                       );
                                                                       const multipleExistView = domainPermissions['view'].every(value => {
                                                                               return myPermission?.includes(value);
                                                                           }
                                                                       );
                                                                       const multipleExistEdit = domainPermissions['edit'].every(value => {
                                                                               return myPermission?.includes(value);
                                                                           }
                                                                       );
                                                                       if (!multipleExist && multipleExistView && multipleExistEdit) {
                                                                           setMyPermission(prev => [...prev, ...domainPermissions[permitted]])
                                                                       } else if (!multipleExist && multipleExistView && !multipleExistEdit) {
                                                                           setMyPermission(prev => [...prev, ...domainPermissions['edit'], ...domainPermissions[permitted]])
                                                                       } else if (!multipleExist && !multipleExistView && multipleExistEdit) {
                                                                           setMyPermission(prev => [...prev, ...domainPermissions['view'], ...domainPermissions[permitted]])
                                                                       } else if (!multipleExist && !multipleExistView && !multipleExistEdit) {
                                                                           setMyPermission(prev => [...prev, ...domainPermissions['view'], ...domainPermissions['edit'], ...domainPermissions[permitted]])
                                                                       }
                                                                   }
                                                               }}
                                                               value={domainPermissions[permitted]}
                                                               checked={function check() {
                                                                   if (permitted === 'none') {
                                                                       if (myPermission.length == 0) {
                                                                           return true;
                                                                       } else {
                                                                           const check = []
                                                                           const notPermitted = Object.keys(permit).filter((item) => item !== 'none');
                                                                           notPermitted.forEach((notPermitted) => {
                                                                               if (domainPermissions[notPermitted].every(value => {
                                                                                   return !myPermission?.includes(value);
                                                                               })) {
                                                                                   check.push(true)
                                                                               }
                                                                           })
                                                                           return check.length == notPermitted.length
                                                                       }
                                                                   } else {
                                                                       const multipleExist = domainPermissions[permitted].every(value => {
                                                                           return myPermission?.includes(value);
                                                                       });
                                                                       return multipleExist
                                                                   }
                                                               }()}
                                                        />
                                                    </div>
                                                } </>
                                            )}
                                        </div>

                                        {subdomain.map(({name, permissions}, key) => (
                                            <div className="flex items-center h-[56px] border-b pl-[64px]" key={key}>
                                                <div className="flex flex-col justify-center py-[12px] px-[16px] grow">
                                                    <h5 className="font-normal">{name}</h5>
                                                </div>
                                                {Object.keys(permit).map((permitted, id) => <>{
                                                        <div className="w-[116px] flex justify-center">
                                                            <input type="radio"
                                                                //  name={`${cleanName(name)}_${cleanName(domainName)}`}
                                                                   checked={
                                                                       function check() {
                                                                           if (permitted === 'none') {
                                                                               if (myPermission.length == 0) {
                                                                                   return true;
                                                                               } else {
                                                                                   const exists = Object.values(permissions).flat()
                                                                                   const multipleExist = exists.every(value => {
                                                                                           return !myPermission?.includes(value);
                                                                                       }
                                                                                   );
                                                                                   return multipleExist
                                                                               }
                                                                           } else {
                                                                               const multipleExist = permissions[permitted].every(value => {
                                                                                   return myPermission?.includes(value);
                                                                               });
                                                                               return multipleExist
                                                                           }
                                                                       }()
                                                                   }
                                                                   defaultChecked={permitted !== "none" && permissions[permitted].every(value => {
                                                                           return myPermission?.includes(value);
                                                                       }
                                                                   )}

                                                                   value={`${cleanPayload(permissions, permitted)}`}
                                                                   onClick={() => {
                                                                       if (permitted === 'none') {
                                                                           const notPermitted = Object.keys(permit).filter((item) => item !== 'none');
                                                                           notPermitted.forEach((notPermitted) => {
                                                                               permissions[notPermitted].forEach((perm) => {
                                                                                   if (myPermission.includes(perm)) {
                                                                                       setMyPermission(prev => prev.filter((item) => item !== perm))
                                                                                   }
                                                                               })
                                                                           })
                                                                       } else if (permitted === 'view') {
                                                                           const notPermitted = Object.keys(permit).filter((item) => item !== permitted && item !== 'none');
                                                                           notPermitted.forEach((notPermitted) => {
                                                                               permissions[notPermitted].forEach((perm) => {
                                                                                   if (myPermission.includes(perm)) {
                                                                                       setMyPermission(prev => prev.filter((item) => item !== perm))
                                                                                   }
                                                                               })
                                                                           })
                                                                           permissions[permitted].forEach((perm) => {
                                                                               if (!myPermission.includes(perm)) {
                                                                                   setMyPermission(prev => [...prev, perm])
                                                                               }
                                                                           })
                                                                       } else if (permitted === 'edit') {
                                                                           const notPermitted = Object.keys(permit).filter((item) => item !== permitted && item !== 'none' && item !== 'view');
                                                                           notPermitted.forEach((notPermitted) => {
                                                                               permissions[notPermitted].forEach((perm) => {
                                                                                   if (myPermission.includes(perm)) {
                                                                                       setMyPermission(prev => prev.filter((item) => item !== perm))
                                                                                   }
                                                                               })
                                                                           })
                                                                           const notPermitted2 = Object.keys(permit).filter((item) => item !== "delete" && item !== 'none')
                                                                           notPermitted2.forEach((notPermitted) => {
                                                                               permissions[notPermitted].forEach((perm) => {
                                                                                   if (!myPermission.includes(perm)) {
                                                                                       setMyPermission(prev => [...prev, perm])
                                                                                   }
                                                                               })
                                                                           })
                                                                       } else if (permitted === 'delete') {
                                                                           const notPermitted2 = Object.keys(permit).filter((item) => item !== 'none')
                                                                           notPermitted2.forEach((notPermitted) => {
                                                                               permissions[notPermitted].forEach((perm) => {
                                                                                   if (!myPermission.includes(perm)) {
                                                                                       setMyPermission(prev => [...prev, perm])
                                                                                   }
                                                                               })
                                                                           })
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                    } </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        {/* <div className="flex flex-row gap-[8px]"> */}
                        <button
                            onClick={() => {
                                // dispatch(assignPermissions({ id: data.id, payload: myPermission }))
                                onSubmit({id: data.id, payload: myPermission})

                                // return onClose()
                            }}
                            className="bg-blue-500 mt-3 hover:bg-red-400 transition-all duration-200 text-white font-bold p-2 rounded-lg sticky bottom-0  left-[50%] -translate-x-[50%]">Save
                            & close
                        </button>
                        {/* </div> */}
                    </div>
                )
            }}
        />
    )
}

export default PermissionsModal