import React, { useEffect, useRef, useState } from "react";
import DynamicForm from "../../../../../dynamic-form";
import addProductView from "../model-view/addProduct";
import util from "../../../../../dynamic-page/util";

const AddProduct = React.memo(({ callBack, editProduct, type,items, allocation_warehouse_id }) => {
  const formRef = useRef();
  const [formData, setFormData] = useState();

  // ---------------------------------------------------------
  // Add new order item
  // ---------------------------------------------------------


  // ---------------------------------------------------------
  // Edit Items
  // ---------------------------------------------------------

  // Set form data if edit order items
  // useEffect(() => {
  //   if (editProduct && editProduct.item) {
  //     /*const fList = {
  //               ...editProduct.item,
  //               skuList: formData.map(i => i.sku),
  //               listingIdList: formData.map(i => i.listing_id)
  //           };*/
  //     setFormData(editProduct.item);
  //   }
  // }, [editProduct]);

  // Cancel

  // ---------------------------------------------------------

  // Update form data to state
  const handleFormUpdate = (data, bind) => {
    callBack({
      ...formData,
      qty: 1,
      ...data,
      _data: { ...formData?._data, ...bind },
    });
  };

  return (
    <>
      {/* Add Product Form */}
      <div className="grid-container gap-2 flex left-[0px] right-[0px] bottom-[0px] px-[16px] bg-white py-[4px] pb-4">
        {/*Dynamic form*/}
        <DynamicForm
          items={addProductView(formData, type,items,allocation_warehouse_id)}
          style={{
            container: {
              className: "items-center !w-full !gap-0",
            },
            label: { className: "hidden" },
            validation: { className: "hidden" },
          }}
          ref={formRef}
          onChange={handleFormUpdate}
        />
        {/* Conditional styling for selected row */}
        {formData?.id && (
          <style>
            {`
                            /* Rest of the selected row styles */
                            #dynamicTableRow_${formData?.id} {background-color: rgba(28,100,242,0.1);}
                        `}
          </style>
        )}
      </div>
    </>
  );
});

export default AddProduct;
