/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Toolbar - a toolbar of arbit product page layout above the table with toolbar buttons
 */

import PropType from "prop-types";
import classNames from "classnames";

const units = ["px", "em", "%"];

const ToolbarLeft = ({ children, width }) => {
  return (
    <div
      className={classNames(
        width && "w-" + width,
        "flex items-center space-x-[4px]"
      )}
    >
      {children}
    </div>
  );
};

ToolbarLeft.defaultProps = {
  width: null,
};

const ToolbarRight = ({ children }) => {
  return <div className="flex items-center space-x-[4px]">{children}</div>;
};

const Toolbar = ({ children, marginX, marginY, height, justify }) => {
  return (
    <div
      className={`
        mx-${
          units.some((unit) => marginX.endsWith(unit))
            ? "[" + marginX + "]"
            : marginX
        }
        my-${
          units.some((unit) => marginY.endsWith(unit))
            ? "[" + marginY + "]"
            : marginY
        }
        h-${
          units.some((unit) => height.endsWith(unit))
            ? "[" + height + "]"
            : height
        }
        flex
        justify-${justify}
        `}
    >
      {children}
    </div>
  );
};

Toolbar.propTypes = {
  marginX: PropType.string,
  marginY: PropType.string,
  height: PropType.string,
  justify: PropType.string,
};

Toolbar.defaultProps = {
  marginX: "0px",
  marginY: "0",
  height: "46px",
  justify: "between",
};

export { ToolbarLeft, ToolbarRight };
export default Toolbar;
