import axios from 'axios';
import React, {useMemo} from 'react'
import AsyncSelect from 'react-select/async';

function BuyboxSelect() {

    const employees = [
        {id: 1, name: 'mohamad'},
        {id: 2, name: 'ali'},

    ]

    const fetchEmployees = async (params) => {
        let url = 'api/v1/employees'
        if (params) {
            url = url.concat(params)
        }
        const response = await axios.get(url, {withCredentials: true});
        return response.data
    }

    const mapOptionsToValues = options => {
        return options.map(option => ({
            value: option?.id,
            label: option?.name,
            image: option?.profile_image
        }));
    };

    const employeesOptions = useMemo(() => {
        return mapOptionsToValues(employees);
    }, [employees]);

    const loadOptions = async (inputValue) => {
        let url = `?term=` + inputValue
        return new Promise(resolve => {
            fetchEmployees(url)
                .then(response => {
                    const options = mapOptionsToValues(response.data);
                    resolve(options)
                })
                .catch(error => {
                    }
                );
        });
    }


    const renderOption = (option) => {
        return (
            <div className="flex items-center">
                <img src={option.image || "/assets/images/defaultAvatar.png"}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = "/assets/images/defaultAvatar.png";
                     }
                     }
                     alt="profile" className="w-8 h-8 rounded-full mr-2"/>
                <div>{option.label}</div>
            </div>
        );
    };

    return (
        <AsyncSelect
            loadOptions={loadOptions}
            placeholder="BuyBox"
            defaultOptions={employeesOptions}
            cacheOptions
            options={employeesOptions}
            onChange={(selected) => {
            }}
            value={null}

            styles={{
                control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "none",
                    border: 'none',

                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#00A3FF" : "white",
                    color: state.isSelected ? "white" : "black",
                    "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                    },
                }),
                menu: (provided, state) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: 10,
                    border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                    boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                    "&:hover": {
                        border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                    },
                }),
                container: (provided, state) => ({
                    ...provided,
                    width: "100%",
                    border: 'none',
                    backgroundColor: "none",
                    outline: "none",
                }),
            }}


            components={
                // hide the | icon
                {
                    IndicatorSeparator: () => null,
                }
            }
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            formatOptionLabel={renderOption}
        />
    )
}

export default BuyboxSelect