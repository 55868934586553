import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchAttributes = createAsyncThunk(
    "get:api/v1/Attributes",
    async (payload, thunkAPI) => {
        const response = await api.fetchAttributes(payload);
        return response.data;
    }
);

const createAttribute = createAsyncThunk(
    "post:api/v1/Attributes",
    async (payload, thunkAPI) => {
        const response = await api.createAttribute(payload);
        return response;
    }
);

const updateAttribute = createAsyncThunk(
    "put:api/v1/Attributes",
    async (payload, thunkAPI) => {
        const response = await api.updateAttribute(payload);
        return response;
    }
);

const updateGroup = createAsyncThunk(
    "put:api/v1/Attributes",
    async (payload, thunkAPI) => {
        const response = await api.updateGroup(payload);
        return response;
    }
);

const deleteAttribute = createAsyncThunk(
    "delete:api/v1/Attributes",
    async (payload, thunkAPI) => {
        const response = await api.deleteAttribute(payload);
        return response;
    }
);

const searchAttributes = createAsyncThunk(
    "get:api/v1/Attributes/search",
    async (payload, thunkAPI) => {
        const response = await api.searchAttributes(payload);
        return response.data;
    }
);

const bulkDeleteAttributes = createAsyncThunk(
    "delete:api/v1/Attributes/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteAttributes(payload);
        return response;
    }
);

const updateAttributesStatus = createAsyncThunk(
    "put:api/v1/Attributes/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdateAttributesStatus(payload);
        return response;
    }
);

const bulkRestoreAttributes = createAsyncThunk(
    "put:api/v1/Attributes/bulk/restore",
    async (payload, thunkAPI) => {
        const response = await api.bulkRestoreAttributes(payload);
        return response;
    }
);

const fetchAttributesFilters = createAsyncThunk(
    "get:api/v1/Attributes/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchAttributesFilters(payload);
        return response;
    }
);

const retoreAttribute = createAsyncThunk(
    "post:api/v1/Attributes/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreAttributes(payload);
        return response;
    }
);

const bulkUpdateAttributesStatus = createAsyncThunk(
    "put:api/v1/Attributes/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdateAttributesStatus(payload);
        return response;
    }
);

const toggleMultiple = createAsyncThunk(
    "put:api/v1/Attributes/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.toggleMultiple(payload);
        return response;
    }
);

const toggleMultilingual = createAsyncThunk(
    "put:api/v1/Attributes/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.toggleMultilingual(payload);
        return response;
    }
);


export {
    fetchAttributes,
    createAttribute,
    updateAttribute,
    deleteAttribute,
    searchAttributes,
    bulkDeleteAttributes,
    updateAttributesStatus,
    bulkRestoreAttributes,
    fetchAttributesFilters,
    retoreAttribute,
    bulkUpdateAttributesStatus,
    toggleMultiple,
    toggleMultilingual,
    updateGroup
};
