import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchAuthUser = createAsyncThunk(
    "get:/api/v1/user",
    async (thunkAPI) => {
        const response = await api.fetchAuthUser()
        return response.data;
    }
)

export {fetchAuthUser}