import filterObjectByKeys from "../../../../../dynamic-page/util/filterObjectByKeys";


const prepareDataToView = (detailView) => {


    let mappedItems = detailView?.summary?.order_items.map(item => {

        let innerItem = filterObjectByKeys(item, [
            "item_image:image",
            "item_title:title",
            "item_sku:sku",
            "item_identifier:listing_id",
            "upc",
            "qty",
            "unit_price",
            "arbitbox_product.id:arbitbox_product_id",
            "unit_price_currency.id:unit_price_currency",
            "id",
            "item_qty_status_code",
            "item_qty_color_code",
            "customer_note", "customer_notes_enabled", "internal_notes", "internal_notes_enabled", "shipping_content", "shipping_content_enabled", "shipping_documents",
            "item_status", "item_status_color_code", "status", "sales_channel_item_id", "sales_channel_product_id",
            "unit_shipping_price", "is_adjustment", "created_at", "updated_at",
            "gtip", "gtip_enabled"

        ])

        return {
            ...innerItem, _data: {
                arbitbox_product_id: {data: item.arbitbox_product},
                unit_price_currency: {data: item?.unit_price_currency},
                total_price_currency: {data: item?.total_price_currency},
                order_id: detailView.id,
                isView: true
            }
        }

    })


    let mappedSummary = filterObjectByKeys(detailView.summary, [
        "order_number:order_id",
        "sales_channel.id:sales_channel_id",
        "sales_channel_account.id:sales_channel_account_id",
        "order_date:order_date",
        "ship_by_date:ship_by_date",
        "shipping_paid:shipping_paid",
        "shipping_paid_currency.id:shipping_paid_currency",
        "order_age",
        "ship_by_date",
        "ship_by_left_days",
        "ship_by_left_hours",
        "ship_by_left_hours",
        "shipping_paid",
        "arbitbox_status_id",
        "arbitbox_status",
        "arbitbox_status_color_code",
        "customer_name",
        "customer_country.id:customer_country",
        "channel_status",
        "channel_status_color_code",
        "channel_status_id"
    ])
    mappedSummary = {
        ...mappedSummary, order_items: mappedItems,
        _data: {
            sales_channel_id: {data: detailView.summary.sales_channel},
            sales_channel_account_id: {data: detailView.summary.sales_channel_account},
            shipping_paid_currency: {data: detailView.summary.shipping_paid_currency},
            customer_country: {data: detailView.summary.customer_country},
            isView: true
        }
    }


    let mappedRecipient = filterObjectByKeys(detailView.recipient, [
        "name",
        "street1",
        "street2",
        "city",
        "state.id:state",
        "zip",
        "country.id:country",
        "phone",
        "email",
        "address_verified",
        "is_residential",
        "company"

    ])
    mappedRecipient = {
        ...mappedRecipient,
        _data: {
            state: {data: detailView.recipient.state},
            country: {data: detailView.recipient.country},
        }
    }


    return {
        summary: mappedSummary,
        recipient: mappedRecipient,
    }
}

export default prepareDataToView;