import React from "react";
import priceHandler from "../../../../../../utils/priceHandler";
import moment from "moment";
import calculation from "../model-view/calculation";
import upperCase from "../../../../../dynamic-page/util/upperCaseString";
import { AiFillInfoCircle } from "react-icons/ai";
import util from "../../../../../dynamic-page/util";
import Util from "../../../../../dynamic-page/util";
import SummaryWeight from "./components/weight";
import shortenString from "../../../../../dynamic-page/util/shortenString";
import Icons from "../../../../../orders/icons";
import UI from "../../../../../dynamic-form/elements";
import descriptiveContent from "../../../../../../utils/descriptiveContent";

const Summary = ({ orderItems, summaryForm, actionCallBack, handleSave, isView,type }) => {
  const primaryLabel = summaryForm?.shipment_labels?.find(
    (label) => label?.is_primary
  );
  const transfer = [
    {
      label: "Transfer Name",
      value: summaryForm?.name?.length > 20 ? summaryForm?.name?.slice(0, 20) + "..." : summaryForm?.name,
      theme: (value) => ( summaryForm?.status_id !== '3' ?
        <SummaryWeight
          notNullable={1}
          text={true}
        value={value}
        callBack={(data) => handleSave(data, "name")}
      /> : value
    ),
    },
    {
      label: "Transfer ID",
      value: summaryForm?.code,
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900  min-w-fit">
          <span className="items-center flex">{value}</span>
        </div>
      ),
      condition : summaryForm?.status_id !== '1' ? true : false,
    },
    {
      label: "Transfer From",
      value: {
        name: summaryForm?.from_warehouse?.name ? summaryForm?.from_warehouse?.name : orderItems?.length > 0 ?  orderItems[0]?._data?.order?.order?.allocation_warehouse?.name : "",
        flag: summaryForm?.from_warehouse?.state?.country?.flag ? summaryForm?.from_warehouse?.state?.country?.flag : orderItems?.length > 0 ? orderItems[0]?._data?.order?.order?.allocation_warehouse?.country?.flag : "",
      },
      theme: (value) => (
        <div className="flex flex-row gap-[6px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.flag && (
            <util.popUp
              action="hover"
              toggle={() => (
                <img
                  src={value.flag}
                  className={"h-[16px] w-[22px] rounded-[2px]"}
                  style={{ objectFit: "cover" }}
                />
              )}
            >
              <div className="px-[8px] py-[4px]">{value?.name}</div>
            </util.popUp>
          )}
          {value?.name && (
            <span className="text-[16px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition: summaryForm?.status_id === '1' ? true : false,
    },
    {
      label: "Status",
      value: summaryForm?.status_id ? summaryForm?.status_id : "1",
      theme: (value) => {
        // const statusChanged = value !== selectedOrder?.order_status;
        return (
          <p
            className={` ${
              value === "1"
                ? "text-yellow-500"
                : value === "2"
                ? "text-blue-500"
                : value === "3"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {value === "1"
              ? "Draft"
              : value === "2"
              ? "Ready to ship"
              :  "Shipped"}
          </p>
        );
      },

    },
    {
      label: "Transfer From",
      value: {
        name: summaryForm?.from_warehouse?.name ? summaryForm?.from_warehouse?.name : orderItems?.length > 0 ?  orderItems[0]?._data?.order?.order?.allocation_warehouse?.name : "",
        flag: summaryForm?.from_warehouse?.state?.country?.flag ? summaryForm?.from_warehouse?.state?.country?.flag : orderItems?.length > 0 ? orderItems[0]?._data?.order?.order?.allocation_warehouse?.country?.flag : "",
      },
      theme: (value) => (
        <div className="flex flex-row gap-[6px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.flag && (
            <util.popUp
              action="hover"
              toggle={() => (
                <img
                  src={value.flag}
                  className={"h-[16px] w-[22px] rounded-[2px]"}
                  style={{ objectFit: "cover" }}
                />
              )}
            >
              <div className="px-[8px] py-[4px]">{value?.name}</div>
            </util.popUp>
          )}
          {value?.name && (
            <span className="text-[16px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition: summaryForm?.status_id !== '1' ? true : false,
    },
    {
      label: "Transfer To",
      value: {
        name: summaryForm?.destination?.name ? summaryForm?.destination?.name : orderItems?.length > 0 ?  orderItems[0]?._data?.order?.order?.destination_warehouse?.name : "",
        flag: summaryForm?.destination?.state?.country?.flag ? summaryForm?.destination?.state?.country?.flag : orderItems?.length > 0 ? orderItems[0]?._data?.order?.order?.destination_warehouse?.country?.flag : "",
      },
      theme: (value) => (
        <div className="flex flex-row gap-[6px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.flag && (
            <util.popUp
              action="hover"
              toggle={() => (
                <img
                  src={value.flag}
                  className={"h-[16px] w-[22px] rounded-[2px]"}
                  style={{ objectFit: "cover" }}
                />
              )}
            >
              <div className="px-[8px] py-[4px]">{value?.name}</div>
            </util.popUp>
          )}
          {value?.name && (
            <span className="text-[16px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition: summaryForm?.status_id !== '1' ? true : false,
    },
    {
      label: "Total Quantity",
      value: calculation.totalQty(orderItems) || "0",
      theme: (value) => <span className={`font-semibold`}>{value}</span>,
    },
    {
      label: "Date",
      value: summaryForm?.order_date
        ? moment(summaryForm?.order_date).format("MMMM D, YYYY")
        : moment().format("MMMM D, YYYY"),
    },
    
  ];

  const financeSection = [
    {
      label: "Total value",
      value: {
        total_paid: type === 'order' ? priceHandler(
          orderItems?.reduce((a, b) => a + b._data?.order?.order?.total_paid, 0)
        ) : priceHandler(
          orderItems?.reduce((a, b) => a + +b?._data?.product?.product?.average_cost_usd * +b?.qty, 0)
        ),
      },
      theme: (value) => (
        <div className="flex flex-row gap-[8px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.total_paid && (
            <span className="text-base font-semibold h-18px items-center flex">
              ${value?.total_paid}
            </span>
          )}
        </div>
      ),
    },
    {
      label: "Cost of Shipment",
      value: `$${primaryLabel?.billed_cost}`,
      condition: isView && primaryLabel ? true : false,
    },
  ];

  const shipmentSection = [
    {
      label: "Status",
      value: primaryLabel?.current_status,
      theme: (value) => <p className="font-semibold">{value}</p>,
      condition:  primaryLabel  ? true : false,
    },
    {
      label: "Content",
      value: summaryForm?.shipping_content || summaryForm?.content|| '-',
      theme: (value) => ( summaryForm?.status_id !== '3' ?
        <SummaryWeight
          value={value}
          text={true}
          notNullable={3}
          callBack={(data) => {
            if (data) {
              handleSave(data , "content") 
            }
          }}
        /> : value
      ),
    },
    {
      label: "To Country",
      value: {
        name: summaryForm?.destination?.state?.country?.name,
        flag: summaryForm?.destination?.state?.country?.flag,
      },
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.flag && (
            <util.popUp
              action="hover"
              toggle={() => (
                <img
                  src={value.flag}
                  className={"h-[16px] w-[22px] rounded-[2px]"}
                  style={{ objectFit: "cover" }}
                />
              )}
            >
              <div className="px-[8px] py-[4px]">{value?.name}</div>
            </util.popUp>
          )}
          {value?.name && (
            <span className="text-[16px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition: summaryForm?.status_id !== '1' ? true : false,
    },
    {
      label: "B. Weight",
      value: summaryForm?.billable_weight,
      theme: (value) => (
        <div className="flex items-center gap-1">
          {value}
          {summaryForm?.billable_weight ? "kg" : ""}
        </div>
      ), 
      condition: summaryForm?.status_id !== '1' ? true : false,
    },
    
    
    {
      label: "Carrier",
      value: {
        name:
          primaryLabel?.international_carrier?.name ||
          primaryLabel?.international_carrier?.name,
        logo:
          primaryLabel?.international_carrier?.logo ||
          primaryLabel?.international_carrier?.logo,
      },
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.logo && <img src={value.logo} className="h-[12px]" />}
          {value?.name && (
            <span className="text-[16px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition:  primaryLabel  ? true : false,
    },
    {
      label: "Service",
      value: primaryLabel?.service_name,
      condition:  primaryLabel  ? true : false,

    },
    {
      label: "Tracking ID",
      value: (
        <span
          className="text-blue-700 cursor-pointer"
          onClick={() =>
            window.open(primaryLabel?.tracking_url, "_blank")
          }
        >
          {primaryLabel?.tracking_number}
        </span>
      ),
      condition:  primaryLabel  ? true : false,

    },
  ];

  const title = (title) => (
    <Util.popUp
      toggle={() => (
        <p>
          Discount - {title?.length > 15 ? title?.slice(0, 15) + "..." : title}
        </p>
      )}
      action={"hover"}
    >
      <p>{title}</p>
    </Util.popUp>
  );

  orderItems?.forEach((item) => {
    if (item.unit_price < 0) {
      financeSection.splice(2, 0, {
        label: title(item.title),
        value: priceHandler(item.unit_price, {
          ...summaryForm?._data?.sales_channel_account_id?.data?.currency,
        })?.replace("$-", "-$"),
      });
    }
  });

  const renderSection = (title, items, hint) => (
    <div className="gap-1 flex flex-col">
      <style>
        {`
        ._updatedRow {
          background-color: #DEF7EC;
          animation: fadeOut 3s forwards;
      }

      @keyframes fadeOut {
          0% {background-color: transparent;}
          50% {background-color: #DEF7EC;}
          100% {background-color: transparent;}
      }
        `}
      </style>
      <div className="flex justify-between items-center font-bold text-gray-900 text-[20px] leading-[30px] ">
        {title} 
        {
          (title === 'Shipment' && summaryForm?.status_id !== '1') && (
            <div
                className={
                  "text-gray-300 h-[16px] flex flex-row items-center gap-[4px]"
                }
              >
                {descriptiveContent( <UI.Button
                  item={{ buttonType: "flat" }}
            onClick={() => actionCallBack({},{ action: "createShipment" })}
                >
                  <Icons.createLabel className={"w-[16px] p-[2px] text-gray-300 hover:text-gray-500"} />
                </UI.Button>, 'Create Label')}

                {descriptiveContent(<UI.Button 
                  item={{ buttonType: "flat" }}
                  onClick={() => actionCallBack({},{ action: "viewLabel" })}
                >
                  <Icons.showLabel className={"w-[16px] p-[0px] text-gray-300 hover:text-gray-500"} />
                </UI.Button> , 'View Label')}

                {descriptiveContent(<UI.Button 
                  item={{ buttonType: "flat"}}
                  onClick={() => actionCallBack({},{ action: "printLabel" })}
                >
                  <Icons.printLabel className={"w-[16px] p-[2px] text-gray-300 hover:text-gray-500"} />
                </UI.Button> , 'Print Label', '', '', {rightSpace:20})}
              </div>
          )
        }
      </div>
      <>{hint}</>
      {items
        .filter((item) => {
          if (item.condition === false) {
            return false;
          }
          return true;
        })
        .map((item, index) => (
          <div
            key={index}
            className="flex flex-row w-full items-center  justify-between"
          >
            <span className="select-none flex text-gray-900 text-[16px] font-medium justify-start items-start whitespace-nowrap">
              {item.label}
            </span>
            <span className="font-normal text-[16px] text-gray-900 text-right ">
              {item.theme ? item.theme(item.value) : item.value}
            </span>
          </div>
        ))}
    </div>
  );

  const hint =
    summaryForm?._data?.sales_channel_account_id?.currency?.code &&
    summaryForm?._data?.sales_channel_account_id?.data?.currency?.code !==
      "USD" ? (
      <div className="flex flex-row items-center px-4 py-2 mt-1 text-[12px] rounded-md leading-[19.5px] text-blue-800 bg-blue-50 w-full">
        <p>
          This order was placed in
          <span className="font-semibold">
            {" "}
            {
              summaryForm?._data?.sales_channel_account_id?.data?.currency?.code
            }{" "}
          </span>
          and has been converted to your organization's default currency (
          <span className="font-semibold">USD</span>) using the current exchange
          rate of
          <span className="font-semibold">
            {" "}
            {(
              summaryForm?.original_items_paid / summaryForm?.items_paid
            ).toFixed(2)}
          </span>
          .
        </p>
      </div>
    ) : null;

  return (
    <div className="flex flex-col p-[16px]  h-[50%]">
      <div className={`flex flex-col ${
        summaryForm?.status_id === '1' ? "gap-4" : "gap-2"
      }`}>
        {renderSection("Transfer", transfer)}
        {renderSection("Finance", financeSection, hint)}
        {renderSection("Shipment", shipmentSection)}
      </div>
    </div>
  );
};

export default Summary;
