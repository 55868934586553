/**
 * @author Austin Ames
 * @description ArbitImageContainer - a image container with delete, move left and move right buttons and copy image url button
 */

import classNames from "classnames";
import {HiChevronLeft, HiChevronRight, HiLink, HiTrash} from "react-icons/hi";

const units = ["px", "em", "%"];

const ArbitImageContainer = ({
                                 width,
                                 height,
                                 src,
                                 onDelete,
                                 onCopyUrl,
                                 onMoveLeft,
                                 onMoveRight,
                                 sourceId,
                             }) => {
    return (
        <div
            className={classNames(
                "w-" +
                (units.some((unit) => width.endsWith(unit))
                    ? "[" + width + "]"
                    : width),
                "h-" +
                (units.some((unit) => height.endsWith(unit))
                    ? "[" + width + "]"
                    : height),
                "relative"
            )}
        >
            <img
                className="rounded-[8px]"
                src={src || "/assets/images/img-placehoder.png"}
                alt="logo"
                style={{maxHeight: "200px", maxWidth: "100%", margin: "auto"}}
            />
        </div>
    );
};

ArbitImageContainer.defaultProps = {
    width: "200px",
    height: "200px",
    src: null,
    onDelete: () => {
    },
    onCopyUrl: () => {
    },
    onMoveRight: () => {
    },
    onMoveLeft: () => {
    },
};

export default ArbitImageContainer;
