import React from "react";
import Util from "../util";

function ItemID({ id }) {
  return (
    <div className="flex justify-center items-center w-full h-full gap-1">
      <p className="text-[12px] font-[500] leading-[18px] text-gray-900 text-center">
        {id}
      </p>
      <Util.copyText text={id} hint={false} />
    </div>
  );
}

export default ItemID;
