import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchOrders = createAsyncThunk(
    'get:api/v1/orders',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchOrders(typeof payload === "string" ? payload : payload.query);
        return {data: data?.data , append : payload?.append};
    }
)
const fetchSingleOrder = createAsyncThunk(
    'get:api/v1/singleOrders/:id',
    async (params, thunkAPI) => {
        const response = await api.fetchSingleOrder(params);
        return response.data;
    }
)

const viewOrder = createAsyncThunk(
    'get:api/v1/orders/:id',
    async (params, thunkAPI) => {
        const response = await api.viewOrder(params);
        return response?.data;
    }
)

const fetchOrdersFilters = createAsyncThunk(
    "get:api/v1/orders/fetchOrdersFilters",
    async (params) => {
        const response = await api.fetchOrdersFilters(params);
        return response;
    }
);

const bulkDeleteOrders = createAsyncThunk(
    'post:api/v1/orders/:id',
    async (params, thunkAPI) => {
        const response = await api.bulkDeleteOrders(params);
        return response.data;
    }
)
const bulkRestoreOrders = createAsyncThunk(
    'post:api/v1/orders/restore/:id',
    async (params, thunkAPI) => {
        const response = await api.bulkRestoreOrders(params);
        return response.data;
    }
)

// Shipment Carrier
const fetchShipmentCarrier = createAsyncThunk(
    'get:api/v1/logistics/calculator',
    async (params, thunkAPI) => {
        const response = await api.fetchShipmentCarrier(params);
        return {orderId: params.orderId, warehouseId: params.warehouseId, data: response.data};
    }
)
const createShipment = createAsyncThunk(
    'get:api/v1/order/create-shipment',
    async (params, thunkAPI) => {
        const response = await api.createShipment(params);
        return {
            orderId: params.order_id,
            warehouseId: params.warehouse_id,
            shipmentPricingId: params.shipment_pricing_id,
            data: response.data
        };
    }
)


// Notes
const fetchNotes = createAsyncThunk(
    'get:api/v1/orders/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.fetchNotes(params);
        return {orderId: params.orderId, data: response.data};
    }
)
const addNote = createAsyncThunk(
    'post:api/v1/orders/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.addNote(params);
        return {orderId: params.orderId, data: response.data};
    }
)
const deleteNote = createAsyncThunk(
    'post:api/v1/orders/:id/notes/:id',
    async (payload, thunkAPI) => {
        const response = await api.deleteNote(payload);
        return response.data;
    }
)

// weight

const updateBillableWeight = createAsyncThunk(
    'post:api/v1/orders/:id/update_billable_weight',
    async (params, thunkAPI) => {
        const response = await api.updateBillableWeight(params);
        return {orderId: params.orderId, data: response.data};
    }
)


const createOrder = createAsyncThunk(
    'post:api/v1/orders',
    async (payload, thunkAPI) => {
        const response = await api.createOrder(payload);
        return response.data;
    }
)

// delete order
const deleteOrder = createAsyncThunk(
    'delete:api/v1/orders/:id',
    async (payload, thunkAPI) => {
        const response = await api.deleteOrder(payload);
        return response.data;
    }
)

const createSupplyRequest = createAsyncThunk(
    'post:api/v1/supply-request/order/:id',
    async (payload, thunkAPI) => {
        return await api.createSupplyRequest(payload);
    }
)
const createSupplyRequestOrderItem = createAsyncThunk(
    'post:api/v1/supply-request/order-item/:id',
    async (payload, thunkAPI) => {
        return await api.createSupplyRequestOrderItem(payload);
    }
)

//, deleteNote,

// Actions
const apiActions = createAsyncThunk(
    'dynamic:api/v1/orders/[:id]/...',
    async (params, thunkAPI) => {
        const response = await api.apiActions(params);
        return {actions: params?.action, data: response?.data}
    }
)

// Actions of detail
const apiDetailActions = createAsyncThunk(
    'dynamic:api/v1/orders/[:id]/order-items/...',
    async (params, thunkAPI) => {
        const response = await api.apiActions(params);
        return {actions: params?.action, data: response?.data}
    }
)


export {
    fetchOrders, fetchSingleOrder, viewOrder, fetchOrdersFilters, bulkDeleteOrders, bulkRestoreOrders,
    apiActions, apiDetailActions, fetchNotes,
    fetchShipmentCarrier, createShipment, // shipment, carrier
    addNote, createOrder, deleteNote, deleteOrder, // Notes
    updateBillableWeight, // Weight

    createSupplyRequest, createSupplyRequestOrderItem // Supply Request
}