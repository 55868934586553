import {ReactComponent as buyerNote} from "./buyerNote.svg";
import {ReactComponent as internalNote} from "./internalNote.svg";
import {ReactComponent as shippingContent} from "./shippingContent.svg";
import {ReactComponent as shippingDocument} from "./shippingDocument.svg";
import {ReactComponent as link} from "./link.svg";
import {ReactComponent as exclamationCircle} from "./exclamationCircle.svg";
import {ReactComponent as cross} from "./cross.svg";

export default {
    buyerNote,
    internalNote,
    shippingContent,
    shippingDocument,
    link,
    exclamationCircle,
    cross
}