import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

export const fetchRepricerListings = createAsyncThunk(
    'repricerListings/fetchRepricerListings',
    async (params) => {
        const response = await api.fetchRepricerListings(params);
        return response.data;
    }
);

export const fetchRepricerListingsFilter = createAsyncThunk(
    'repricerListings/fetchRepricerListingsFilter',
    async (params) => {
        const response = await api.fetchRepricerListingsFilter(params);
        return response.data;
    }
);