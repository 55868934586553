import callEndpoint from "../../dynamic-page/util/callEndpoint";

const actionList = {
    add: {
        label: "Add Product",
        onClick: ({forms, data}) => {
            forms?.["add"].open(data);
        },
        condition: false,
    },

    edit: {
        label: "Edit",
        onClick: ({forms, actions, data}) => {
            forms?.["add"].open(data);
        },
        bulkDisable: true,
    },

    activate: {
        label: "Activate",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to activate the selected unit/s?",
                confirmText: "Yes, Activate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Activate Units",
                        method: "put",
                        url: `units/status`,
                        data: {
                            ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 0;
        },
    },

    deactivate: {
        label: "Deactivate",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText:
                    "Are you sure you want to deactivate the selected unit/s?",
                confirmText: "Yes, Deactivate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Deactivate Units",
                        method: "put",
                        url: `units/status`,
                        data: {
                            ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 1;
        },
    },

    delete: {
        label: "Delete",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to delete the selected unit/s?",
                confirmText: "Yes, Delete",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Delete Units",
                        method: "delete",
                        url: `units/bulk-action`,
                        data: {
                            ids: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status !== 2;
        },
    },

    restore: {
        label: "Restore",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to restore the selected unit/s?",
                confirmText: "Yes, Restore",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Restore Units",
                        method: "post",
                        url: `units/restore`,
                        data: {
                            ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 2;
        },
    },

    save: {
        label: "Save",
        onClick: ({data, refreshPage, close, callBack}) => {
            let payload = {
                ...data,
                status: 1
            };
            callEndpoint({
                title: "Save Unit",
                method: data?.id ? "put" : "post",
                url: data?.id ? `units/${data?.id}` : `units`,
                data: payload,
                pureData: true,
            }).then((response) => {
                callBack();
                if (response?.type === "success") {
                    close();
                    refreshPage();
                }
            });
        },
        condition: false,
    },
};

export default actionList;
