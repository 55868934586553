import upperCaseString from "../../../../../dynamic-page/util/upperCaseString";
import React from "react";

import SingleGroupProductBadge from "../../../../../dynamic-page/components/SingleGroupProductBadge";
import Icons from "../../../../../orders/icons";

const addProductView = (data, type, items, allocation_warehouse_id) => {
    let formItems = [];
    if (type === "product") {
        formItems = [
        {
            name: "product",
            label: "Select product to add to the transfer",
            type: "Select2",
                noPortal: true,
                resetValue: true,
            api: {
                url: "/api/v1/product?include=brand.assigned&filter[type]=1",
                query: "&term={query}",
                filter: items?.length > 0 ?  items?.map((item) => item?._data?.product?.product?.id) : null,

                optionValue: (item) => ({
                    value: item?.id,
                    label: upperCaseString(item?.title, "word", true),
                    product: item,
                }),
            },
                defaultValue: null,
            resetValue: true,
            disablePreload: true,
            reset: false,
            clear: false,
            className: "w-full ",
            features: {
                formatOptionLabel: ({label, product}) => {
                    return (
                        <div
                            className={"flex flex-row gap-[8px] items-center min-h-[25px]"}
                        >
              <span
                  className={
                      " min-w-[45px] gap-1 overflow-hidden flex flex-row justify-center items-center "
                  }
              >
                <SingleGroupProductBadge product={product}/>
                  {product?.image ? (
                      <img
                          className={"w-[20px] h-[20px] rounded-md "}
                          src={product?.image}
                      />
                  ) : (
                      <img
                          className={"w-[20px] h-[20px] rounded-md"}
                          src={"/assets/images/noImage.png"}
                      />
                  )}
              </span>
                            <span>{upperCaseString(label)}</span>
                        </div>
                    );
                },
            },
            style: {
                control: {
                    height: "40px !important",
                    minHeight: "auto",
                    background: "rgba(0,0,0,0.03)",
                },
                menu: {
                    whiteSpace: "wrap !important ",
                    zIndex: 9999,
                },
                option: {
                    padding: "4px 8px !important",
                },
            },
            placeholder: (
                <div className={"flex flex-row gap-[8px] items-center text-[14px]"}>
                    Select a product to add to the transfer
                </div>
            ),
        },
    ];
    } else {
        formItems = [
            {
                name: "order",
                label: "Select an order to add to the transfer",
                type: "Select2",
                noPortal: true,
                api: {
                    url: `/api/v1/orders?include=firstItem&filter[order_status]=15${
                        allocation_warehouse_id ? `&filter[allocation_warehouse_id]=${allocation_warehouse_id}` : ""
                        }`,
                filter: items?.map((item) => item?._data?.order?.order?.id),
                    
                    query: `&term={query}`,
                    // view: "/api/v1/orders?filter[id]=",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: upperCaseString(item?.first_item?.item_title, "word", true),
                        image: item?.first_item?.image_url,
                        order: item,
                    }),
                },
                defaultValue:  null,
                // disablePreload: true,
                reset: false,
            resetValue: true,
            clear: false,
                className: "w-full ",
                features: {
                    formatOptionLabel: ({label,image, order}) => {
                        return (
                            <div
                                className={"flex flex-row gap-[8px] items-center min-h-[25px]"}
                            >
                  <span
                      className={
                          " min-w-[45px] gap-1 overflow-hidden flex flex-row justify-center items-center "
                      }
                  >
                      {image ? (
                          <img
                              className={"w-[20px] h-[20px] rounded-md "}
                              src={image}
                          />
                      ) : (
                          <img
                              className={"w-[20px] h-[20px] rounded-md"}
                              src={"/assets/images/noImage.png"}
                          />
                      )}
                  </span>
                                <span>{upperCaseString(label)}</span>
                            </div>
                        );
                    },
                },
                style: {
                    control: {
                        height: "40px !important",
                        minHeight: "auto",
                        background: "rgba(0,0,0,0.03)",
                    },
                    menu: {
                        whiteSpace: "wrap !important ",
                        zIndex: 9999,
                    },
                    option: {
                        padding: "4px 8px !important",
                    },
                },
                placeholder: (
                    <div className={"flex flex-row gap-[8px] items-center text-[14px]"}>
                        Select an order to add to the transfer
                    </div>
                ),
            },
        ]
    }
    


    return formItems;
};

export default addProductView;
