import axios from "axios";
import React from "react";
import {HiExternalLink} from "react-icons/hi";

const Offers = ({id}) => {

    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const fetchItems = async () => {
            setLoading(true);
            const response = await axios.get(`/api/v1/offers?filter[sales_channel_listing_id]=${id}&include=account`);
            setItems(response.data.data)
            setLoading(false);
        }
        fetchItems()
    }, [id]);

    const lineAnimationStyles = {
        width: '50%',
        height: '100%',
        background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,97,9,0.45475465576855745) 43%, rgba(0,212,255,1) 100%)',
        position: 'absolute',
        left: '0',
        animation: 'line-animation 0.5s linear infinite',
        borderRadius: "5px"
    };

    return (
        <div className={" flex min-w-[500px] flex-col gap-[4px]   px-[4px]"}>
            {loading &&
                <div style={{
                    top: "15px",
                    left: "10px",
                    width: 'calc(100% - 20px)',
                    height: '5px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    overflow: 'hidden',
                    borderRadius: "5px",
                    background: "#jjj"
                }}>
                    <div style={lineAnimationStyles}></div>
                </div>
            }
            <style> {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`} </style>
            <p className={"text-gray-900 text-center !text-[14px] pl-[4px] mt-[6px] font-semibold"}>
                You're Already Selling This Listing
            </p>
            <table className="min-w-full divide-y divide-gray-200 shadow-md rounded-md">
                <thead>
                <tr>
                    <th className="px-2 py-2 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Account Name
                    </th>
                    <th className="px-2 py-2 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        SKU
                    </th>
                    <th className="px-2 py-2 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Fulfillment
                    </th>
                    <th className="px-2 py-2 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Quantity
                    </th>
                    <th className="px-2 py-2 bg-gray-50">
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {items?.length > 0 && items?.map((item, index) => (
                    <tr key={index}>
                        <td className="px-2 py-2 whitespace-nowrap">
                            {item?.account?.name}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap">
                            {item?.sku}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap">
                            {item?.fulfilment}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap">
                            {item?.quantity}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-right text-sm font-medium">
                            <a
                                href={`/listing/offers?search=&filter%5Bidentifier%5D=${item?.identifier}&filter[sku]=${item?.sku}&page=1`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-500 cursor-pointer hover:text-gray-900"
                            >
                                <HiExternalLink className="text-gray-300 group-hover:text-blue-500"/>
                            </a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

        </div>
    )
}
export default Offers;