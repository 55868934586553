import React from "react";
import UI from "../../dynamic-form/elements";
import OffersTableComponent from "../offers-table";
import axios from "axios";
import {howLongFromNow} from "../../../utils/helper";

const YourOffers = ({modalListing, fulfilmentHandler}) => {
    const [offersData, setOffersData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        let url = 'api/v1/offers?' + `filter[identifier]=${modalListing?.sales_channel_listing?.identifier}&sort=updated_at&include=account`
        axios.get(url).then((response) => {
            setOffersData(response?.data?.data);
            setLoading(false);
        })
    }, []);

    const headers = ['ACCOUNT', 'SKU', 'price', 'QTY', 'LAST UPDATE'];
    const hidden = ['hidden'];

    const totalEstAgedInvCost = offersData?.reduce((acc, curr) => {
            return acc + +curr?.estimated_aged_inventory_cost
                ;
        }
        , 0);

    const totalEstStorageCost = offersData?.reduce((acc, curr) => {
            return acc + +curr?.estimated_monthly_storage_fee;
        }
        , 0);


    const Data = [
        {title: 'Offer Count', value: offersData?.length ?? '-'},
        {
            title: 'Total Est. Aged Inv. Cost',
            value: totalEstAgedInvCost || totalEstAgedInvCost === 0 ? `$${totalEstAgedInvCost}` : '-'
        },
        {
            title: 'Total Est. Storage Cost',
            value: totalEstStorageCost || totalEstStorageCost === 0 ? `$${totalEstStorageCost}` : '-'
        },
        {
            title: 'Oldest Update',
            value: offersData[0]?.updated_at ? howLongFromNow(offersData[0]?.updated_at, true) : '-'
        },
        // Add more data items as needed
    ];

    return (
        <div className='mt-5 flex flex-col justify-between items-start gap-y-3 gap-x-2 w-full'>
            <div className='grid grid-cols-4 justify-center items-center gap-x-3 w-full'>
                <UI.StatBoxList data={Data}/>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <OffersTableComponent
                    headers={headers}
                    offersData={offersData}
                    hidden={hidden}
                />
            )}
        </div>
    );
};

export default YourOffers;
