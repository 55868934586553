import React from "react";
import {HiExternalLink, HiInformationCircle,} from "react-icons/hi";
import moment from "moment";
import {howLongFromNow} from "../../../utils/helper";
import Util from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
import {useNavigate} from "react-router-dom";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
// import Components from "../components";
import Icons from "../../orders/icons";
import classNames from "classnames";
import ArbitToggle from "../../sub-elements/toggle";
// import InternalNote from "../components/internalNote";
import Item from "../../sales-listing/components/item";
import OrderRowAction from "../components/rowActions";
import RepricerPricing from "../components/repricerPricing";

const TableView = (list, fulfilmentHandler, openListingModal) => {
    const navigate = useNavigate();
    const activeStyle = (status) => {
        if (status) {
            return "!text-blue-500";
        } else {
            return "!text-gray-500";
        }
    };

    const QuantityTooltip = ({data}) => {
        if (data)
            return (
                <Util.popUp
                    toggle={(selected) => (
                        <HiInformationCircle className="w-3 h-3 text-gray-300 cursor-pointer"/>
                    )}
                    action='hover'
                    // leftSpace={style?.leftSpace}
                >
                    <div
                        className="min-w-[250px] h-[55px] px-3 py-2.5 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                        {data?.map((item) => {
                            return <div className="flex flex-row justify-between w-full">
                                <p className="text-gray-900 text-sm font-normal leading-[21px]">{item?.account?.name}</p>
                                <p className="text-gray-900 text-sm font-semibold leading-[21px]">{item?.val || 0}</p>
                            </div>
                        })}
                    </div>
                </Util.popUp>
            );
    };


    const nullHandle = (data, chained) => {
        if (!data || chained == null) {
            return "-";
        } else {
            return chained;
        }
    };
    return {
        list: list ?? [],
        theme: {

            // "status": (item) => (
            //     <div className="flex flex-col h-full justify-center gap-y-2 w-full">
            //     <div className="flex">
            //       <HiOutlineTag className="w-5 h-5 text-gray-900" />
            //       <HiOutlineTrendingUp className="w-5 h-5 text-gray-900" />
            //       <HiOutlineTrendingDown className="w-5 h-5 text-blue-700" />
            //       <HiOutlineTrendingDown className="w-5 h-5 text-orange-700" />
            //     </div>
            //     <div className="flex">
            //       <HiOutlineTruck className="w-5 h-5 text-green-700" />
            //       <HiOutlineFire className="w-5 h-5 text-gray-400" />
            //       <HiOutlineX className="w-5 h-5 text-blue-700" />
            //       <HiOutlineX className="w-5 h-5 text-orange-700" />
            //     </div>
            //     <div className="flex">
            //       <HiOutlineAnnotation className="w-5 h-5 text-green-700" />
            //       <HiOutlineScissors className="w-5 h-5 text-gray-900" />
            //       <HiOutlineExclamationCircle className="w-5 h-5 text-gray-900" />
            //       <HiOutlineCake className="w-5 h-5 text-gray-900" />
            //     </div>
            //   </div>
            // ),
            "control": (item) => (
                <div className={"w-[50px] h-[50px]"}>
                    <OrderRowAction actions={{
                        order_id: item?.id,
                        customer_note: item?.customer_note,
                        internal_notes: item?.internal_notes,
                        shipping_content: item?.shipping_content,
                        shipping_documents: item?.shipping_documents,

                        customer_note_enabled: item?.customer_note_enabled,
                        internal_notes_enabled: item?.internal_notes_enabled,
                        shipping_content_enabled: item?.shipping_content_enabled,
                        shipping_documents_enabled: item?.shipping_documents_enabled,
                    }}/>
                </div>
            ),
            'last update': (item) => {
                const salesListing = item?.sales_channel_listing
                return (<div className="flex flex-col justify-start items-start gap-1">
                        {
                            <div className="flex flex-row items-center justify-center gap-1">

                                <ArbitToggle status={item?.fba_eligibility ?? false}
                                             bgColor={item?.fba_eligibility ? 'bg-blue-600' : 'bg-gray-400'}
                                    // handleFulfilment={() => fulfilmentHandler('fba', item?.id)}
                                />
                                <p className={` text-gray-900 leading-[17.5px] text-[14px] font-medium`}>Repricer</p>
                            </div>
                        }
                        <util.dateDisplay item={item} date={salesListing?.updated_at} />
                    </div>

                )
            },

            channel: (item) => {
                // const salesListing = item?.sales_channel_listing;
                const salesListing = item?.sales_channel_listing ? item?.sales_channel_listing : item?.sales_channel_listings?.[0]
                const modalListing = item;
                return (
                    <div className="flex flex-col w-full gap-2 !px-[8px] !py-[12px]">
                        <div className="flex flex-col gap-1">
                            <div className="flex gap-1 justify-start">
                                {item?.sold_by_us && <Icons.fire/>}
                                <img
                                    className="h-[20px] object-cover"
                                    src={salesListing?.["channel"]?.marketplace?.logo}
                                />
                                <div className="text-gray-900 text-sm font-normal leading-[14px]">
                                    {salesListing?.["channel"]?.name}
                                </div>


                            </div>
                        </div>

                        <div className="">
                            <p className="text-[12px] leading-[12px] font-[400] text-gray-600">
                                {salesListing?.identifier_type ? salesListing?.identifier_type : "-"}
                            </p>
                            <div className="flex items-center">

                                <Util.copyText className={"text-[12px] text-blue-700"} text={item?.identifier_value}
                                               hint={false}
                                               callBack={() => openListingModal(modalListing)}
                                >
                                    {salesListing?.identifier ? salesListing?.identifier : "-"}
                                </Util.copyText>


                                {/* <Util.copyText className={"text-[12px] text-blue-700"} text={item?.identifier_value} hint={false}
                callBack={() => openListingModal(modalListing)}
              >
                  {salesListing?.identifier ? salesListing?.identifier : "-"}
            </Util.copyText> */}
                                <HiExternalLink className="w-4 h-4 text-gray-300 cursor-pointer"
                                                onClick={() => {
                                                    window.open(salesListing?.url, "_blank");
                                                }}
                                />
                            </div>
                        </div>
                    </div>
                )
            },

            image: (item) => {
                const salesListing = item?.sales_channel_listing
                return <div
                    className={
                        "w-[65px] h-[64px] max-h-[64px]  justify-center items-center flex"
                    }
                >
                    {salesListing?.main_image_path ? (
                        <ImageMagnifier
                            src={salesListing?.main_image_path}
                            className={"!max-h-[64px]"}
                        />
                    ) : (
                        <img
                            src={"/assets/images/noImage.png"}
                            className={"h-[52px] w-[52px]"}
                        />
                    )}
                </div>
            },

            title: (item) => (
                <Item
                    product={item?.sales_channel_listing ? [item?.sales_channel_listing?.product] : [item?.sales_channel_listings?.product]}
                    item={item}/>
            ),

            "LISTING ANALYSIS": (item) => {
                item = item?.sales_channel_listing ? item?.sales_channel_listing : item?.sales_channel_listings[0]
                const offerCount = item?.total_offer_count;
                return (
                    <div className="flex flex-col space-y-[4px] w-[200px] !px-[8px] !py-[12px] ">
                        <div className="flex items-center justify-between">
                            <Util.popUp
                                toggle={(selected) => (
                                    <p
                                        className={classNames(
                                            item?.["buybox_price"] ? "text-teal-600" : "text-teal-200",
                                            "text-[24px] leading-[24px] font-[500] flex items-center gap-1"
                                        )}
                                    >
                                        {item?.["buybox_price"]
                                            ? '$' + item?.["buybox_price"]
                                            : "No BB"}
                                    </p>
                                )}
                                action='hover'
                            >
                                <div
                                    className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                    Buybox Price
                                </div>
                            </Util.popUp>

                            <div className="flex flex-col justify-end gap-[2px]">
                                {/* <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                  avg {consts.currency[item?.["bb_currency"]]}
                  {item?.["bb_avg_price"]}
                </p> */}
                                <div className="w-[61px]  justify-between items-center gap-2.5 inline-flex">
                                    {item?.buybox_seller_type ?
                                        <Util.popUp
                                            toggle={(selected) => (
                                                <div
                                                    className={`text-center ${item?.buybox_seller_type === 'FBM' ? 'text-blue-700' : 'text-orange-700'} text-xs font-normal leading-3`}>
                                                    {item?.buybox_seller_type}
                                                </div>
                                            )}
                                            action='hover'
                                        >
                                            <div
                                                className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                                Buybox Owner Fulfillment Type
                                            </div>
                                        </Util.popUp> : ''}
                                    <Util.popUp
                                        toggle={(selected) => (
                                            <div className="justify-between items-center gap-1 flex cursor-pointer"
                                                 onClick={() => {
                                                     window.open(`https://www.amazon.com/gp/offer-listing/${item?.identifier}`, '_blank')
                                                 }}
                                            >
                                                <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M0.799988 1.03848C0.799988 0.879347 0.863202 0.726734 0.975724 0.614213C1.08825 0.501691 1.24086 0.438477 1.39999 0.438477H8.59999C8.75912 0.438477 8.91173 0.501691 9.02425 0.614213C9.13677 0.726734 9.19999 0.879347 9.19999 1.03848C9.19999 1.19761 9.13677 1.35022 9.02425 1.46274C8.91173 1.57526 8.75912 1.63848 8.59999 1.63848H1.39999C1.24086 1.63848 1.08825 1.57526 0.975724 1.46274C0.863202 1.35022 0.799988 1.19761 0.799988 1.03848ZM0.799988 4.03848C0.799988 3.87935 0.863202 3.72673 0.975724 3.61421C1.08825 3.50169 1.24086 3.43848 1.39999 3.43848H8.59999C8.75912 3.43848 8.91173 3.50169 9.02425 3.61421C9.13677 3.72673 9.19999 3.87935 9.19999 4.03848C9.19999 4.19761 9.13677 4.35022 9.02425 4.46274C8.91173 4.57526 8.75912 4.63848 8.59999 4.63848H1.39999C1.24086 4.63848 1.08825 4.57526 0.975724 4.46274C0.863202 4.35022 0.799988 4.19761 0.799988 4.03848ZM4.39999 7.03848C4.39999 6.87935 4.4632 6.72673 4.57572 6.61421C4.68825 6.50169 4.84086 6.43848 4.99999 6.43848H8.59999C8.75912 6.43848 8.91173 6.50169 9.02425 6.61421C9.13677 6.72673 9.19999 6.87935 9.19999 7.03848C9.19999 7.19761 9.13677 7.35022 9.02425 7.46274C8.91173 7.57526 8.75912 7.63848 8.59999 7.63848H4.99999C4.84086 7.63848 4.68825 7.57526 4.57572 7.46274C4.4632 7.35022 4.39999 7.19761 4.39999 7.03848Z"
                                                          fill="#C81E1E"/>
                                                </svg>
                                                <div className="text-red-700 text-xs font-semibold leading-3">
                                                    {offerCount != null ? offerCount : '-'}
                                                </div>
                                            </div>
                                        )}
                                        action='hover'
                                    >
                                        <div
                                            className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                            Total Offers
                                        </div>
                                    </Util.popUp>

                                </div>
                                <Util.popUp
                                    toggle={(selected) => (
                                        <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                                            low {" "}
                                            <span className="text-[12px] leading-[12px] text-gray-500 text-end">
                    {item?.["bb_low_price"] ? '$' + item?.["bb_low_price"] : '-'}
                  </span>
                                        </p>
                                    )}
                                    action='hover'
                                >
                                    <div
                                        className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                        Lowest Price
                                    </div>
                                </Util.popUp>
                            </div>
                        </div>
                        <hr/>
                        <div>
                            <div className="flex items-center justify-between">
                                <p
                                    className={classNames(
                                        item?.['category_rank_degree']
                                            ? "text-purple-600"
                                            : "text-purple-200",
                                        "text-[24px] leading-[24px] font-[500]"
                                    )}
                                >
                                    {item?.['category_rank_degree']
                                        ? item?.['category_rank_degree']
                                        : "-"}
                                </p>
                                <div className="flex flex-col justify-end">
                                    <Util.popUp
                                        toggle={(selected) => (
                                            <p className="text-[12px] leading-[12px] text-rose-700 text-end">
                                                {item?.["sales_rank"]
                                                    ? "#" + item?.["sales_rank"]
                                                    : "-"}
                                            </p>
                                        )}
                                        action='hover'
                                    >
                                        <div
                                            className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                            Sales Rank
                                        </div>
                                    </Util.popUp>
                                    <div className="flex items-center justify-end">
                                        {item?.["sales_channel_top"] ? (
                                            <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                                                top
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        &nbsp;
                                        <p className="text-[12px] leading-[12px] text-yellow-400 text-end">
                                            {item?.["sales_channel_top"] ? item?.["sales_channel_top"] + '%' : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex items-center justify-start text-[12px] leading-[12px] text-rose-700 font-[400]">
                                {/* <p className="text-gray-400">in&nbsp;</p>{" "} */}
                                {/* {item?.root_category ?? "N/A"} */}
                                {item?.root_category ? item?.root_category
                                    ?.split(" ")
                                    .map(
                                        (word) =>
                                            word.charAt(0).toUpperCase() + word.slice(1)
                                    )
                                    .join(" ") : "N/A"}

                            </div>
                        </div>
                    </div>
                )
            },

            'FBA MIN / MAX PRICE': (item) => {
                return <RepricerPricing type='fba'/>
            },
            'FBM MIN / MAX PRICE': (item) => {
                return <RepricerPricing type='fbm'/>
            }
        },
    };
};
export default TableView;
