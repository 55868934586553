import axios from "./../../middleware/axios";
import constants from "../../constant";

const GetAllWarehouses = async (params) => {
    let url = constants.APIURL.WAREHOUSE_LIST_ALL_URL
    if (params) url = url.concat(params)

    const response = await axios.get(url, {withCredentials: true})
    return response
}


const fetchWarehouseFilters = async (params) => {
    let url = constants.APIURL.WAREHOUSE_FILTER_URL;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
}
const CreateWarehouse = async (payload) => {
    let url = constants.APIURL.WAREHOUSE_CREATE_URL
    const response = await axios.post(url, payload, {
        withCredentials: true,
    })
    return response
}

const UpdateWarehouse = async ({id, payload}) => {
    let url = constants.APIURL.WAREHOUSE_UPDATE_URL + '/'
    const response = await axios.put(url + id, payload, {
        withCredentials: true,
    })
    return response
}

const GetWarehouseById = async (id, params) => {
    let url = constants.APIURL.WAREHOUSE_GET_BY_ID_URL + '/'
    if (params) url = url.concat(params)
    const response = await axios.get(url + id, {withCredentials: true})
    return response
}

const DeleteWarehouse = async (ids) => {
    let url = constants.APIURL.WAREHOUSE_DELETE_URL;
    const response = await axios.delete(url, {
        data: ids,
        withCredentials: true,
    })
    return response
}

export default {
    CreateWarehouse,
    fetchWarehouseFilters,
    UpdateWarehouse,
    GetAllWarehouses,
    GetWarehouseById,
    DeleteWarehouse,
}
