import axios from '../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

const fetchShippingPricingList = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGPRICING
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}
const addShippingPricingList = async (payload) => {
    // console.log(payload, 'api');
    const response = await axios.post(constants.APIURL.POST_SHIPPINGPRICING, apiutils.obj2Form(payload), {withCredentials: true})
    // console.log(response, 'api');
    return response
}
const updateShippingPricingList = async (id, payload) => {
    // console.log(id, payload, 'Update user api');
    const response = await axios.put(constants.APIURL.PUT_SHIPPINGPRICING + "/" + id, payload, {withCredentials: true})
    return response
}
const fetchShippingPricingListFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_SHIPPINGPRICING_FILTERS, {withCredentials: true});
    return response
}
const updateShippingPricingListStatus = async (id, status) => {
    // console.log(id, status, 'Update')
    const response = await axios.put(constants.APIURL.STATUS_SHIPPINGPRICING + id + "/change-status", status, {withCredentials: true})
    return response

}
const duplicateShippingPricingList = async (id) => {
    // console.log(id, 'Duplicate Shipping Pricing')
    const response = await axios.post(constants.APIURL.DUPLICATE_SHIPPINGPRICING + id, {withCredentials: true})
    return response

}
const deleteShippingPricingList = async (id) => {
    // console.log(id, 'Duplicate Shipping Pricing')
    const response = await axios.delete(constants.APIURL.DELETE_SHIPPINGPRICING + id, {withCredentials: true})
    return response

}

const updateBulkShippingPricingListStatus = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_SHIPPING_PRICING_LIST_STATUS, payload, {withCredentials: true})
    return response;
}
const deleteBulkShippingPricingList = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BULK_SHIPPING_PRICING_LIST, {data: payload})
    return response;
}

export default {
    fetchShippingPricingList,
    fetchShippingPricingListFilters,
    addShippingPricingList,
    updateShippingPricingList,
    updateShippingPricingListStatus,
    updateBulkShippingPricingListStatus,
    deleteBulkShippingPricingList,
    duplicateShippingPricingList,
    deleteShippingPricingList
}