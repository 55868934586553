import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeletePaymentMethods,
  bulkRestorePaymentMethods,
  createPaymentMethod,
  deletePaymentMethod,
  fetchPaymentMethods,
  fetchPaymentMethodsFilters,
  searchPaymentMethods,
  updatePaymentMethod,
  updatePaymentMethodsStatus,
} from "./thunk";

const initialState = {
  paymentMethods: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    status_filter: [],
    created_filter: "",
    updated_filter: "",
    balance_filter: [],
  },
  meta: {},
  filtersMeta: {},
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updatePaymentMethodStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.paymentMethods = state.paymentMethods.map((pm) => {
        if (ids.includes(pm.id)) {
          return { ...pm, status };
        }
        return pm;
      });
    },
    removePaymentMethod: (state, action) => {
      const ids = action.payload;
      state.paymentMethods = state.paymentMethods.filter(
        (pm) => !ids.includes(pm.id)
      );
    },
    bulkDeletePaymentMethods: (state, action) => {
      const ids = action.payload;
      state.paymentMethods = state.paymentMethods.filter(
        (paymentMethod) => !ids.includes(paymentMethod.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentMethods.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchPaymentMethods.rejected, (state, action) => {
        state.loading = "idle";
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.loading = "idle";
        state.paymentMethods = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      .addCase(fetchPaymentMethodsFilters.fulfilled, (state, action) => {
        state.loading = "idle";
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  removePaymentMethod,
  updatePaymentMethodStatuses,
} = paymentMethodsSlice.actions;

export {
  fetchPaymentMethods,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  searchPaymentMethods,
  bulkDeletePaymentMethods,
  updatePaymentMethodsStatus,
  bulkRestorePaymentMethods,
  fetchPaymentMethodsFilters,
};

export default paymentMethodsSlice.reducer;
