import ContentLoader from '../../components/ContentLoader'

const ArbitLoading = () => {
    return (
        <div
            className="overflow-y-auto overflow-x-hidden absolute top-5 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full flex items-center justify-center bg-white">
            <ContentLoader/>
        </div>
    )
}

export default ArbitLoading
