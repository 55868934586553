import React, {useState} from "react";
import MainModal from "../dynamic-page/MainModal";
import Tab from './tab';
import TabContent from './tab-content';

const tabs = [
    'Listing',
    'Product',
    'Sales',
    'Fulfillment Center (FBA)',
    'Warehouse (FBM)',
    'Listing Offers',
    'Your Offers'
];

const ListingDetailModal = ({onClose, modalListing, fulfilmentHandler}) => {
    const [activeTab, setActiveTab] = useState('Listing');
    const handleTabChange = tab => {
        setActiveTab(tab);
    };

    return (
        <MainModal
            item={{
                stitle: <span
                    className="text-[#111928] text-lg font-medium overflow-hidden overflow-ellipsis whitespace-nowrap w-[50vw]">{modalListing?.sales_channel_listing?.name || modalListing?.title}</span>,
                cancel: onClose,
                view: () => (
                    <div
                        className="w-[68vw] flex flex-col justify-between items-center relative border-t border-t-gray-200 py-3">
                        <div className="flex flex-col justify-between items-start w-full">
                            <Tab tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange}/>
                            <TabContent activeTab={activeTab} modalListing={modalListing}
                                        fulfilmentHandler={fulfilmentHandler}/>
                        </div>
                    </div>
                )
            }}
        />
    );
};

export default ListingDetailModal;