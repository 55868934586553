import React from 'react';


const DateHour = ({item, value, validationMessage, handleChange, handleFocus, handleBlur, formData}) => {

    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;
    const inputValue = value || "";
    // Return Input in view
    return (
        <input
            name={item.name}
            type={"datetime-local"}
            placeholder={item.placeholder}
            disabled={disabled}
            className={`transition ${disabled && "disabled"} ${inputValue ? " hasValue" : ""} dynamicFormDate disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${item.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
            value={inputValue}
            onChange={(e) => {
                if (handleChange) handleChange(e.target.value, item.name)
            }}
            onFocus={(e) => {
                if (handleFocus) handleFocus(e.target.value, item.name)
            }}
            onBlur={(e) => {
                if (handleBlur) handleBlur(e.target.value, item.name)
            }}
            autoFocus={item?.autoFocus}
        />
    )
}


export default DateHour;