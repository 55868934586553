import React from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import util from "../../dynamic-page/util";

const orderId = ({ order, callBack, orderId }) => {
  return (
    <div className={"justify-start w-full"}>
      <div
        className={
          "flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit"
        }
      >
        {order?.country?.flag && (
          <util.popUp
            toggle={(selected) => (
              <img
                src={order?.country?.flag}
                className={"h-[14px] w-[19.6px] rounded-[2px]"}
                style={{ objectFit: "cover" }}
              />
            )}
            action={"hover"}
          >
            <div className={"px-[8px] py-[4px]"}> {order?.country?.name} </div>
          </util.popUp>
        )}
        {order?.marketplace?.logo && (
          <img
            src={order?.marketplace?.logo}
            className={"h-[14px] w-[14px] "}
          />
        )}
        {order?.name && (
          <span
            className={
              "text-[14px] leading-[14px] font-normal text-[#111928] items-center flex"
            }
          >
            {upperCaseString(order?.name)}
          </span>
        )}
      </div>

      <div
        className={
          "min-w-max whitespace-no-wrap text-blue-600 text-[12px] items-center hover:text-blue-700 cursor-pointer flex flex-row gap-[4px]"
        }
      >
        {order?.shipment_labels_count > 1 && (
          <util.popUp
            toggle={(selected) => (
              <div
                className={`h-[20px] min-w-[20px] flex flex-row justify-center items-center bg-red-100 rounded-[10px] text-red-800 font-medium hover:bg-purple-600 hover:text-white transition cursor-pointer ${
                  selected && " text-white !bg-purple-600"
                }`}
              >
                {order?.shipment_labels_count}
              </div>
            )}
          >
            Order Has {order?.shipment_labels_count} Shipping Labels
          </util.popUp>
        )}
        <span
          onClick={() => {
            window.open(
              `/logistics/orders/#action=details&orderId=${orderId}
                    `,
              "_blank"
            );
          }}
          className={`${
            order?.labels?.length > 1 && "text-red-700 font-[600]"
          }`}
        >
          {order?.order_number}
        </span>
        <div>
          <util.copyText
            className={"text-[12px]"}
            text={order?.order_number}
            hint={false}
          />
        </div>
      </div>
    </div>
  );
};
export default orderId;
