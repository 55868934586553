import {createAsyncThunk} from '@reduxjs/toolkit'
import api from './api'
import { fetchLoading } from '.';

const GetAllWarehouses = createAsyncThunk('get:api/v1/warehouse_fulfillments',
async (payload, { dispatch, getState }) => {
    if (!payload?.append) {
      dispatch(fetchLoading());
    }
    const data = await api.GetAllWarehouses(
      typeof payload === "string" ? payload : payload.query
    );
    return { ...data?.data, append: payload?.append };
  }
)

const fetchWarehouseFilters = createAsyncThunk(
    "get:api/v1/warehouse_fulfillments-filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchWarehouseFilters(payload);
        return response.data;
    }
);

const CreateWarehouse = createAsyncThunk(
    'post:api/v1/warehouse_fulfillments/create',
    async (payload, {rejectWithValue}) => {
        try {
            const response = await api.CreateWarehouse(payload)
            return response
        } catch (err) {
            // Handle the error here. You can also modify err.response.data as per your need
            return rejectWithValue(err.response.data)
        }
    }
)

const GetWarehouseById = createAsyncThunk(
    'get:api/v1/warehouse_fulfillments/{id}',
    async ({id, payload = null}, {rejectWithValue}) => {
        try {
            const response = await api.GetWarehouseById(id, payload)
            return response
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

const UpdateWarehouse = createAsyncThunk(
    'post:api/v1/warehouse_fulfillments/update',
    async ({id, payload}, {rejectWithValue}) => {
        try {
            const response = await api.UpdateWarehouse({id, payload})
            return response
        } catch (err) {
            // Handle the error here. You can also modify err.response.data as per your need
            return rejectWithValue(err.response.data)
        }
    }
)

const DeleteWarehouse = createAsyncThunk(
    'get:api/v1/warehouse_fulfillments/delete/{id}',
    async (ids, {rejectWithValue}) => {
        try {
            const response = await api.DeleteWarehouse(ids)
            return response
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export {
    CreateWarehouse,
    fetchWarehouseFilters,
    UpdateWarehouse,
    GetAllWarehouses,
    GetWarehouseById,
    DeleteWarehouse,
}


// camelCase