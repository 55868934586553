import React, { memo, useEffect, useRef, useState } from "react";
import MainModal from "../../../dynamic-page/MainModal";
import {
  apiActions,
  createOrder,
  viewOrder as viewRow,
} from "../../../../store/orders";
import { useDispatch } from "react-redux";
import Pending from "../../../dynamic-page/elements/pending";
import notifier from "../../../dynamic-page/util/notifier";
import handlePrepareDataToPost from "./summary/util/prepareDataToPost";
import prepareDataToView from "./summary/util/prepareDataToView";
import util from "../../../dynamic-page/util";
import SummaryTab from "./summary";
import callEndpoint from "../../../dynamic-page/util/callEndpoint";
import TabButton from "../../../dynamic-page/elements/TabButton";
import { fetchSingleTransfer } from "../../../../store/transfers";
import zplPrint from "../../../print-page/zpl";


const CreateTransfer = memo(
  ({ submit, transfer, cancel, update,createShipment, selectedOrder, type }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState("summary");
    const [detailData, setDetailData] = useState({});
    const [isView, setIsView] = useState(selectedOrder ? true : false);
    const [loadViewState, setLoadViewState] = useState(true);
    const tabContentRef = useRef(null);
    const [allNotes, setAllNotes] = useState(selectedOrder?.internal_notes);

    const mapTransferableItem = (item) => (
    type === 'order' ? 
      {
      order: item?.id,
      
      _data: {
        order: {
          order: item?.item
        }
      }
        } : {
          product: item?.id,
          qty: item?.quantity,
          _data: {
            product: {
              product: item?.item
            }
          }

        }
    );

    const triggerValidation = (data) => {
      const errors = {}
      if (!isView) {
        if (!data?.order_items || data?.order_items?.length === 0) {
           errors["order_items"] = ["Please add atleast one item"];
        }
        if (!data?._data?.destination?.data?.id) {
           errors["destination"] = ["Please select a destination"];
        }
        if (!data?.name) {
           errors["name"] = ["Please enter a name"];
        }
        if (!data?.shipping_content) {
           errors["shipping_content"] = ["Please enter shipping content"];
        }
        return errors
      }
    }


    useEffect(() => {
      if (transfer?.id) {
        setIsView(true);
        setLoadViewState(false);
        setDetailData(null);
        dispatch(fetchSingleTransfer(transfer?.id)).then((rs) => {
          const transfer = rs?.payload?.data;
        //   const viewData = prepareDataToView(returnData, selectedOrder);
        setDetailData({
          ...transfer,
          dim_weight: transfer?.dimensional_weight,
      actual_weight: transfer?.actual_weight,
          order_items: [...transfer?.transfer_items.map(mapTransferableItem)],
          });
          setLoadViewState(true);
        });
      }
    }, []);

    const primaryLabel = detailData?.shipment_labels?.find((label) => label?.is_primary);
    const handleUpdateDetailData = async (data, state) => {
      if (!isView) {
        const validation = triggerValidation(detailData);
        if (Object.keys(validation).length) {
          util.notifier({
            type: "error",
            message: "Please fill in all required fields",
            errors: validation,
          });
          return
        }
      }
      
      const actionList = {
        shipment: async () => {
         
                createShipment(detailData);
          
        },
        update: async () => {
          const orderDetailData = detailData
          callEndpoint({
            title: "Update Transfer",
            url: `transfers/${transfer.id}/update`,
            method: "PUT",
            data: data,
            pureData: true
          }).then((res) => {
            if (res?.type === "success") {
              setDetailData({
                // ...detailData,
                // ...data,
                ...res?.data,
                order_items: [...res?.data?.transfer_items.map(mapTransferableItem)],
              })
              update(res?.data, res?.data);
            } else {
              setDetailData(orderDetailData);
            }
          }
          );
        },

        createShipment: () => createShipment(detailData),

        printLabel: () => {
            const fileUrl = [primaryLabel?.label_file_pdf];
            fileUrl.forEach((item) => {
              zplPrint.getAndSendFileToPrinter(item);
            });
        },
    
        viewLabel:() => {
            const fileUrl = [primaryLabel?.label_file_png];
            util.pdfViewer(fileUrl, false, `Shipment Label`);
        },

      };

      if (state) {
        actionList[state.action](data);
      }
    };
    return (
      <MainModal
        headerClassName={"!text-[24px]  !pt-6 !pb-2.5 !px-[28px] !items-start !font-semibold !leading-9 !text-gray-900"}
        titleClassName={"!text-[24px]  !font-semibold !leading-9 !text-gray-900"}
        containerClassName= '!px-5 !pb-5'
        item={{
          title:detailData?.name ,
          submit: (data) => {
            submit(data);
          },
          cancel: () => {
            cancel();
          },
          view: () => (
            <div
              className={
                "flex flex-col gap-[8px] w-full h-full  orderDetailModal"
              }
              style={{
                width: "calc(100vw - 80px)",
                maxWidth: "1800px",
              }}
            >
              <TabButton
                    list={[{id: "overview", name: "Overview", }]}
                    active={'overview'}
                    // callBack={(tab) => {
                    //   const validate = isView ? false : true;
                    //   handleTabClick(tab, validate);
                    // }}
                    className={"!border-b  !gap-[6px] relative "}
                    selectedClassName={"  !gap-[8px] relative "}
                    mainClassName={
                      "!justify-start !gap-[16px]  border-b-[#E5E7EB]"
                    }
                  />
              {!transfer || loadViewState ? (
                <>
                  <SummaryTab
                    ref={tabContentRef}
                    type={type}
                    selectedOrder={selectedOrder}
                    setAllNotes={setAllNotes}
                    allNotes={allNotes}
                    data={detailData}
                    callBack={handleUpdateDetailData}
                    selectedTab={selectedTab}
                    isView={isView}
                    update={update}
                    setDetailData={setDetailData}
                    id={isView ? { transfer: transfer } : null}
                  />
                </>
              ) : (
                <Pending count={1} h={20} />
              )}
            </div>
          ),
        }}
        mainClassName={"!py-[30px] "}
      />
    );
  },
  () => true
);

export default CreateTransfer;
