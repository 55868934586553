import React from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import util from "../../dynamic-page/util";

const orderId = ({order, callBack, orderId, country}) => {
    return (
        <div className={"flex flex-col justify-start w-[215px]"}>
            <div className={"flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] "}>


                {order?.country?.flag &&
                    <util.popUp
                        toggle={(selected) => (
                            <img src={order?.country?.flag} className={"h-[12px] w-[17px] rounded-[2px]"} alt=""
                                 style={{objectFit: 'cover'}}/>)}
                        action={"hover"}
                    >
                        <div className={"px-[8px] py-[4px]"}> {order?.country?.name} </div>
                    </util.popUp>


                }


                {order?.logo && <img src={order?.logo} className={"h-[12px]"} alt=""/>}
                {order?.account &&
                    <span className={"text-[14px h-18px items-center flex"}>{upperCaseString(order?.account)}</span>}

            </div>

            <div
                className={" whitespace-no-wrap text-blue-600 text-[12px] hover:text-blue-700 cursor-pointer flex flex-row gap-[4px]"}
            >
                <span onClick={() => {
                    callBack({action: "orderDetails", data: {id: orderId}})
                    //window.open(`/`, '_blank')
                }}>
                    {order?.order_number}
                </span>
                <div>
                    <util.copyText className={"text-[12px]"} text={order?.order_number} hint={false}/>

                </div>
            </div>
        </div>

    )

}
export default orderId;