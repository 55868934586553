import * as Yup from "yup";

import upperCaseString from "../../dynamic-page/util/upperCaseString";


const FormView = (item) => {

    const innerSubmit =
        [
            {
                label: "Save",
                type: "primary",
                action: "save",
            },

        ]

    return ({
        title: upperCaseString(item?.id ? "Edit" : "Add") + " Category",
        submit: innerSubmit,
        // close: !item?.id,
        // cancel: !item?.id ? false : true,
        viewModel: (formDefaultValue) => {
            return [
                {
                    name: "name",
                    label: "Name",
                    placeholder: "Enter Name of channel",
                    type: "Input",
                    defaultValue: formDefaultValue?.name || null,
                    validation: Yup.string().required('Name is required'),

                },
                {
                    name: "type",
                    label: "Category Type",
                    placeholder: "Select Designation",
                    type: "Select",
                    list: [
                        {label: "Main Category", value: "Main Category"},
                        {label: "Sub Category", value: "Sub Category"},
                    ],
                    defaultValue: formDefaultValue?.type || "Main Category",
                },
                {
                    name: "parent_id",
                    label: "Parent Category",
                    placeholder: "Select Category",
                    type: "Select",
                    api: {
                        url: "/api/v1/category",
                        query: "?term={query}&showAll=true",
                        optionValue: (item) => ({
                            value: item.id,
                            label: item.name
                        })
                    },
                    addNew: (formData) => ({
                        api: "category",
                        data: (value) => ({name: value}),
                    }),
                    defaultValue: formDefaultValue?.parent_id || null,
                    className: "col-span-2",
                    condition: (data) => data?.type === 'Sub Category',
                },

            ]
        }
    });
};
export default FormView;
  
