import React from "react";
import {HiPlus} from "react-icons/hi";
import UI from "../../dynamic-form/elements";


const OptionsView = [
    {
        action: "add",
        view: (param) => (
            <UI.Button
                item={{
                    label: <HiPlus className="h-5 w-5"/>,
                    buttonType: "icon",
                    className: "order-[0] !min-w-[28px] !h-[28px] !p-0"
                }}
                onClick={() => {
                    param?.callBack(param)
                }}
            />
        ),
    }

]

export default OptionsView;