import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchShippingCostLists = createAsyncThunk(
    "get:api/v1/ShippingCostList",
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingCostList(payload);
        return response;
    }
);

const createShippingCostList = createAsyncThunk(
    "post:api/v1/ShippingCostList",
    async (payload, thunkAPI) => {
        const response = await api.createShippingCostList(payload);
        return response;
    }
);

const updateShippingCostList = createAsyncThunk(
    "put:api/v1/ShippingCostList",
    async ({id, payload}) => {
        const response = await api.updateShippingCostList(id, payload);
        return response;
    }
);

const deleteShippingCostList = createAsyncThunk(
    "delete:api/v1/ShippingCostList",
    async (payload, thunkAPI) => {
        const response = await api.deleteShippingCostList(payload);
        return response;
    }
);

const searchShippingCostLists = createAsyncThunk(
    "get:api/v1/ShippingCostList/search",
    async (payload, thunkAPI) => {
        const response = await api.searchShippingCostList(payload);
        return response.data;
    }
);

const fetchShippingCostListsFilters = createAsyncThunk(
    "get:api/v1/ShippingCostList/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingCostListsFilters(payload);
        return response;
    }
);

const bulkDeleteShippingCostLists = createAsyncThunk(
    "delete:api/v1/ShippingCostList/delete/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteShippingCostLists(payload);
        return response;
    }
);

const bulkStatusUpdateShippingCostLists = createAsyncThunk(
    "put:api/v1/ShippingCostList/status/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkStatusUpdateShippingCostLists(payload);
        return response;
    }
);

const restoreShippingCostLists = createAsyncThunk(
    "post:api/v1/ShippingCostList/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreShippingCostLists(payload);
        return response;
    }
);


const duplicateShippingCostList = createAsyncThunk(
    "post:api/v1/shipping_template/duplicate/",
    async (payload, thunkAPI) => {
        const response = await api.duplicateShippingCostList(payload);
        return response;
    }
);


export {
    fetchShippingCostLists,
    createShippingCostList,
    updateShippingCostList,
    deleteShippingCostList,
    searchShippingCostLists,
    fetchShippingCostListsFilters,
    bulkDeleteShippingCostLists,
    bulkStatusUpdateShippingCostLists,
    restoreShippingCostLists,
    duplicateShippingCostList
};
