import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
// import data from "./data";
import sortView from "./modals-view/sort";
import ConnectForm from "./modals-view/form";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActionList from "../dynamic-page/util/getActionList";

import {
  fetchSalesListings as fetchData,
  fetchSalesListingsFilter as fetchFilters,
  updateBoundFilter,
  updateFilter,
  updateSalesListingFulfilment,
 updateRow } from "../../store/sales-listings";

import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import notifier from "../dynamic-page/util/notifier";
import {
  disconnectProduct,
  fetchSalesListing,
} from "../../store/sales-listings/thunk";

import {
  closeNotification,
  startNotification,
} from "../dynamic-page/util/notifierLive";
import { refreshAccountListings } from "../../store/fc-restocking";
import ListingDetailModal from "../../components/listing-detail-modal";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import Icons from "../orders/icons";
import useLoadMoreData from "../../utils/useLoadMoreData";
import { useLocation } from "react-router-dom";
import ProductConnectChannelModal from "./modals-view/productDetailForm";
import Button from "../dynamic-form/elements/Button";
import PropTypes from 'prop-types';

const SalesChannelListings = ({
  product_id = null,
  displayTitle = "flex",
  displayTabGroups = "flex",
  showCreateForm = false,
  showSalesModal,
  hideFilter,
  setShowSalesModal,
  updateListingsCount,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Initial page at first load.
  useEffect(() => {
    let filters = {};

    if (product_id) {
      filters = { product: product_id };
    }

    const startPageByFilters = {
      filters: filters,
      sort: null,
      page: 1,
      limit: 10,
      search: "",
      includes: "",
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters, [
        "filter[order_status]",
        "filter[containingProduct]",
        "filter[type]",
      ])
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
    // dispatch(OffersFilters(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.salesListing);


  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleListingModal, setVisibleListingModal] = useState(false);
  const [salesModalListingData, setSalesModalListingData] = useState(null); // State to store salesListing data

  const openListingModal = (modalListing) => {

    setSalesModalListingData(modalListing); // Set salesListing data in the state
    setVisibleListingModal(true);
  };

  const [formData, setFormData] = useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);

  // Handle order details modal.
  const [modalVisibility, setModalVisibility] = useState(false);

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const actionList = {
    disConnectPrduct: {
      label: "Disconnect Product",
      onClick: (row) => {
        dispatch(
          disconnectProduct({
            listId: row?.id,
            // productId: productList?.id,
          })
        ).then((result) => {
          //handleFetchAndUpdateItem(data._data.order_id, data.id, result?.payload?.data)
          if (result?.payload?.type === "success") {
            util.notifier({
              type: "success",
              message: result?.payload?.message,
              errors: result?.payload?.errors,
            });
            if (product_id) {
              onPaginationApply();
              updateListingsCount("account-listings", "sales");
              return;
            }
            dispatch(fetchSalesListing(row?.id)).then((rs) => {
              dispatch(
                updateRow({
                  path: `salesListings.[id:${row?.id}]`,
                  value: rs.payload.data,
                })
              );
            });
          }
        });
      },
      bulkDisable: true,
      condition: (row) =>
        row?.sales_channel_listing?.product != null &&
        row?.sales_channel_listing?.available === 1,
    },
    change_fc_eligibility: {
      label: "Change FC Eligibility",
      onClick: (row) => {
        const rowIds = row?.id ? row?.id : selectedRow.map((item) => item.id);
        fulfilmentHandler("fba", rowIds);
      },
      condition: (row) => row?.sales_channel_listing?.available === 1,
    },
    change_wh_eligibility: {
      label: "Change WH Eligibility",
      onClick: (row) => {
        const rowIds = row?.id ? row?.id : selectedRow.map((item) => item.id);
        fulfilmentHandler("fbm", rowIds);
      },
      condition: (row) => row?.sales_channel_listing?.available === 1,
    },
    create_supply_request: {
      label: "Create Supply Request",
      onClick: (row) => {
        if (!row?.sales_channel_listing?.product) {
          util.notifier({
            message: "Listing Should Be Connected to a Single Product",
          });
          return;
        }
        window.open(
          `/inventory/supply_requests#action=create&product=${row?.sales_channel_listing?.product?.id}`,
          "_blank"
        );
      },
      bulkDisable: true,
      condition: (row) =>
        row?.sales_channel_listing?.product != null &&
        row?.sales_channel_listing?.available === 1,
    },
    create_offer: {
      label: "Create Offer",
      onClick: (row) => {
        // if (!row?.sales_channel_listing?.product) { util.notifier({ message: "No Product Connected" });  return;}
        window.open(
          `/listing/offers#action=create&
      &identifier=${row?.sales_channel_listing?.identifier}
      &identifier_type=${row?.sales_channel_listing?.identifier_type}
      &account=${row?.account?.id}
      &channel=${row?.sales_channel_listing?.channel?.id}
      `,
          "_blank"
        );
      },
      bulkDisable: true,
      condition: (row) => row?.sales_channel_listing?.available === 1,
    },
    delete: {
      label: "Ignore Forever",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText:
            "Are you sure you want to ignore the selected sales channel listing?",
          confirmText: "Yes, Ignore",
          callBack: (setSubmit) => {
            const ids = item?.sales_channel_listing?.id
              ? [item?.sales_channel_listing?.id]
              : selectedRow.map((item) => item?.sales_channel_listing?.id);
            callEndpoint({
              title: "Ignoring Sales Channel Listing",
              url: `sales-channel-listing/delete`,
              method: "DELETE",
              data: { ids },
              pureData: true,
            }).then((res) => {
              setSubmit(false);
              if (res.type === "success" || res?.status === 204) {
                setConfirmationModalVisibility(null);
                onPaginationApply();
              }
            });
          },
        });
      },
      condition: (row) => row?.sales_channel_listing?.available === 0,
    },
    reprocess: {
      label: "Reprocess",
      onClick: (item) => {
        callEndpoint({
          title: "Reprocessing Sales Channel Listing",
          url: `sales-channel-listing/toggle-available`,
          method: "post",
          data: {
            ids: item?.sales_channel_listing?.id
              ? [item?.sales_channel_listing?.id]
              : selectedRow.map((item) => item?.sales_channel_listing?.id),
          },
          pureData: true,
        }).then((res) => {
          if (res.type === "success") {
            onPaginationApply();
          }
        });
      },
      condition: (row) => row?.sales_channel_listing?.available === 0,
    },
    connect: {
      label: "Connect Product",
      onClick: (item) => {
        const items = item?.id ? [item] : selectedRow;
        const filteredItems = items.filter(
          (item) => item?.sales_channel_listing?.product == null
        );
        setFormData(filteredItems);
        setModalVisibility(true);
      },
      condition: (item) => item?.sales_channel_listing?.product == null,
    },
  };

  const statusList = {
    1: {
      label: "Pending",
      actions: [
        actionList.delete,
        actionList.reprocess,
        actionList.connect,
        actionList.create_supply_request,
        actionList.create_offer,
        actionList.disConnectPrduct,
        actionList.change_fc_eligibility,
        actionList.change_wh_eligibility,
      ],
    },
  };

  // handle actions of statuses
  //
  const actions = (row) => {
    return getActionList(row, statusList, undefined, undefined, true);
  };

  const handleRefreshListings = async () => {
    const identifiers = selectedRow?.map((item) => {
      return item?.sales_channel_listing?.identifier;
    });

    const ids = selectedRow?.map((item) => {
      return item?.id;
    });

    if (identifiers?.length > 0) {
      try {
        setRefreshing(true);

        let a = startNotification({
          title: `Refreshing ${identifiers.length} Listings`,
        });

        dispatch(refreshAccountListings({ identifiers }))
          .unwrap()
          .then((rs) => {
            setRefreshing(false);
            // util.notifier({type : rs.type , message: rs.message});
            closeNotification(a, {
              toast: {
                type: rs.type === "validation_error" ? "error" : rs.type,
                message: rs.message,
                errors: rs.errors,
              },
            });
            if (rs.type === "success") {
              identifiers.map((identifier) => {
                const row = rs.data.find(
                  (item) => item.identifier === identifier
                );
                const originalRow = pageData.salesListings.find(
                  (item) => item.sales_channel_listing.identifier === identifier
                );
                dispatch(
                  updateRow({
                    path: `salesListings.[id:${originalRow.id}]`,
                    value: {
                      ...originalRow,
                      sales_channel_listing: row,
                      _updatedRow: true,
                    },
                  })
                );
              });

              ids.map((id) => {
                setTimeout(() => {
                  dispatch(
                    updateRow({
                      path: `salesListings.[id:${id}]._updatedRow`,
                      value: false,
                    })
                  );
                }, 3000);
              });
            }
          });

        setSelectedRow(null);
      } catch (error) {
        // Handle errors if the refresh fails
        console.error("Error refreshing account listings:", error);
      }
    } else {
      util.notifier({ message: "Please Select at Least One Row" });
      console.error("No identifier found in the selected row.");
    }
  };

  // Handle Tabs

  const onHandleTab = (item) => {
    setSelectedRow([]);
    switch (item) {
      case 0:
        onFilterApply({ available: 0, awaiting_connection: null });
        break;
      case "1":
        onFilterApply({ awaiting_connection: item, available: null });
        break;
      default:
        onFilterApply({ awaiting_connection: null, available: null });
        break;
    }
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

if (sort === currentStoreFilters?.sort) {
  changeSort = null;
} else if (currentStoreFilters?.sort === `-${sort}`) {
  changeSort = sort;
} else {
  changeSort = `-${sort}`;
}

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };
  const fulfilmentHandler = async (item, id, index) => {
    await dispatch(
      updateSalesListingFulfilment({
        type: item,
        payload: Array.isArray(id) ? id : [id],
      })
    )
      .then(({ payload }) => {
        if (payload?.data?.type === "success") {
          if (Array.isArray(id)) {
            onPaginationApply();
            util.notifier(payload?.data);
          } else {
            dispatch(fetchSalesListing(id)).then((rs) => {
              if (visibleListingModal) {
                setSalesModalListingData(rs.payload.data);
              }
              dispatch(
                updateRow({
                  path: `salesListings.[id:${id}]`,
                  value: rs.payload.data,
                })
              );
              util.notifier(payload?.data);
            });
          }
        }
      })
      .catch((error) => {
        notifier({ type: "error", content: error });
      });
  };

  let activeFilter = null;
if (pageData?.filters?.filters?.awaiting_connection === "1") {
  activeFilter = pageData.filters.filters.awaiting_connection;
} else if (pageData?.filters?.filters?.available === 0) {
  activeFilter = pageData.filters.filters.available;
}

const Link2=Icons.link2
  // Render View of Page
  let availableAdjustment = 0;
if (pageData?.filters?.filters?.available === 0) {
  availableAdjustment = 1;
}
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          displayTitle={displayTitle}
          title={"Sales Channel Listings"}
          // form={{
          //   callback: (rs) => {
          //     setModalVisibility(true);
          //   },
          // }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(
              pageData?.filters?.filters,
              pageData?.boundFilters
            ),
            discard: ["awaiting_connection", [hideFilter]],
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.awaiting_connection === undefined ||
              pageData?.filters?.filters?.awaiting_connection === null
                ? 0
                : 1) -availableAdjustment
          }}
          actionList={actions(selectedRow)}
          options={[
            {
              callBack: () => {
                setModalVisibility(true);
              },
              view: ({ callBack }) => (
                <Button
                  item={{
                    label: (
                      <div
                        className={`flex flex-row items-center justify-center gap-[4px] `}
                      >
                        <div className={"flip-x transform scale-x-[-1]"}>
                          <Link2 className={"h-5 w-5"} />
                        </div>
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!min-w-[28px] !h-[28px] !p-0 ",
                  }}
                  // onClick={handleRowClick}
                  onClick={() => {
                    callBack();
                  }}
                />
              ),
            },
            {
              callBack: handleRefreshListings,

              data: refreshing,
              view: ({ callBack, data }) => (
                <Button
                  item={{
                    label: (
                      <div
                        className={`flex flex-row items-center  justify-center gap-[4px]`}
                      >
                        <div className={"flip-x transform scale-x-[-1]"}>
                          <Icons.ArrowPath
                            className={`h-5 w-5 ${
                              data ? "animate-spin rotate-[reverse]" : ""
                            }`}
                          />
                        </div>
                        {/* {!data && <TimeAgo timestamp={refreshingDate} /> } */}
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!w-auto !min-w-[28px] !h-[28px] !p-0 ",
                  }}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
              condition: () => selectedRow?.length > 0,
            },
          ]}
        />

        {/* Tabs and search input are here. */}
        {!showCreateForm && (
          <div
            className={
              "flex flex-row justify-between px-[16px] py-[4px] items-center"
            }
          >
            <SearchInput
              className="w-[362px]"
              placeholder="Search..."
              onSubmit={onSearchApply}
              defaultValue={pageData?.filters?.search}
            />
            <div className="flex items-center gap-4">
              <TabButton
                callBack={onHandleTab}
                loading={pageData?.filterLoading}
                active={activeFilter}
                list={[
                  {
                    id: "1",
                    name: "Awaiting Connection",
                    count: pageData?.filtersMeta?.awaiting_connection?.find(
                      (item) => item?.name === "awaiting_connection"
                    )?.count,
                  },
                  {
                    id: 0,
                    name: " Unavailable Listings",
                    count: pageData?.filtersMeta?.availability?.find(
                      (item) => item?.name === "unAvailable"
                    )?.count,
                  },
                ]}
                allTab={true}
                allCount={pageData?.filtersMeta?.availability?.reduce(
                  (acc, item) => acc + item.count,
                  0
                )}
                // noAllCount
              />
            </div>
          </div>
        )}

        {/* Table is here. */}
        <ArbitProductPage.Content className="content">
          <style>
            {!product_id
              ? `
                             @media (768px <= width <= 1000px) {
                                .content .contt {
                                    height:  calc(100vh -300px) !important;
                            
                                }
                            }
                            @media (max-width: 768px) {
                                .content .contt {
                                  height:  calc(100vh -234px) !important;
                                }
                            }
                            @media (1000px <= width <= 1300px) {
                                .content .contt {
                                  height:  calc(100vh - 234px) !important;
                       
                                }
                            }
                            @media (1300px < width ) {
                              .content .contt {
                                height:  calc(100vh - 214px) !important;
                              
                              }
                          }
                    `
              : `
                .content .contt {
                  height: calc(100vh - 400px) !important;

                }
                    `}
          </style>

          <DynamicTable
            view={tableView}
            data={tableView(
              pageData?.salesListings?.map((item) => {
                return {
                  ...item,
                  status: 1,
                };
              }),
              fulfilmentHandler,
              openListingModal,
              actionList.connect
              // data
            )}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            loading={pageData.loading}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              header: {
                className: "justify-center !px-[16px] ",
                "tracking id": { className: "!pr-[30px]" },
                title: { className: "!pr-[88px] " },
                image: { className: "hidden" },
                notes: { className: "hidden" },
                type: { className: "!py-[0px] hidden" },
                _actions: { className: "!py-[0px] !px-[0px]" },
                channel: { className: "!text-start !flex !justify-start " },
                "LISTING ANALYSIS": {
                  className: "!text-start !flex !justify-start ",
                },
                "Fc eligibility": {
                  className: "!text-start !flex !justify-start ",
                },
                "wh eligibility": {
                  className: "!text-start !flex !justify-start ",
                },
                "Listing sales": {
                  className: "!text-start !flex !justify-start ",
                },
              },
              row: {
                className:
                  "!py-[0px] !px-[16px] !items-center !justify-center flex text-[12px] !h-[104px] ",
                // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                description: {
                  className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                },
                title: { className: "!px-[8px] !pr-[20px]" },
                image: { className: "!px-[0px]" },
                notes: { className: "!px[0px] !pr-[0px]" },
                "LISTING ANALYSIS": { className: " !px-[0px]" },
                channel: { className: "!px[0px]  " },
                "Fc eligibility": { className: "!px-[0px]  " },
                "wh eligibility": { className: "!px-[8px]" },
                _actions: {
                  className: "!py-[0px] !px-[0px] !gap-0 !w-[50px]",
                  innerClass: "!gap-0 ",
                  td: "!w-[50px]",
                },
                "Listing sales": {
                  className: "!py-[0px] !px-[12px] !pr-[0px]",
                },
              },
            }}
          />
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {modalVisibility && (
        <ConnectForm
          data={formData}
          show={modalVisibility || showSalesModal}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
            setShowSalesModal(false);
          }}
          defaultListings={formData}
          callBack={() => {
            onPaginationApply();
            updateListingsCount("account-listings", "sales");
          }}
          updateRows={(items) => {
            items.map((item) => {
              dispatch(fetchSalesListing(item)).then((rs) => {
                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]`,
                    value: rs.payload.data,
                  })
                );
                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]._updatedRow`,
                    value: true,
                  })
                );
              });
            });
          }}
          product_id={product_id}
          // onSubmit={onConnect}
        />
      )}
      {showSalesModal && (
        <ProductConnectChannelModal
          data={formData}
          setModalVisibility={setModalVisibility}
          setShowSalesModal={setShowSalesModal}
          show={modalVisibility || showSalesModal}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
            setShowSalesModal(false);
          }}
          defaultListings={formData}
          callBack={() => {
            onPaginationApply();
            updateListingsCount("account-listings", "sales");
          }}
          updateRows={(items) => {
            items.map((item) => {
              dispatch(fetchSalesListing(item)).then((rs) => {
                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]`,
                    value: rs.payload.data,
                  })
                );
                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]._updatedRow`,
                    value: true,
                  })
                );
              });
            });
          }}
          product_id={product_id}
          // onSubmit={onConnect}
        />
      )}

      {visibleListingModal && (
        <ListingDetailModal
          show={visibleListingModal}
          onClose={() => setVisibleListingModal(false)}
          modalListing={salesModalListingData}
          fulfilmentHandler={fulfilmentHandler}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={confirmationModalVisibility?.infoText}
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={confirmationModalVisibility?.confirmText}
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};
SalesChannelListings.propTypes = {
  product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayTitle: PropTypes.string,
  displayTabGroups: PropTypes.string,
  showCreateForm: PropTypes.bool,
  showSalesModal: PropTypes.bool.isRequired, // Ensure this prop is passed
  hideFilter: PropTypes.bool,
  setShowSalesModal: PropTypes.func.isRequired,
  updateListingsCount: PropTypes.func.isRequired,
};


export default SalesChannelListings;
