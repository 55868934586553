import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchSupplyListings = createAsyncThunk(
    'get:api/v1/supply-listings',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchSupplyListings(typeof payload === "string" ? payload : payload.query);
        return {...data?.data, append: payload?.append};
    }
)

const fetchSupplyListingFilters = createAsyncThunk(
    'get:api/v1/supply-listings/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchSupplyListingsFilters(params)
        // console.log(response)
        return response.data
    }
)

const createSupplyListing = createAsyncThunk(
    'post:api/v1/supply-listings',
    async (data, thunkAPI) => {
        const response = await api.CreateSupplyListing(data)
        // console.log(response)
        return response.data
    }
)

const updateSupplyListing = createAsyncThunk(
    'post:api/v1/supply-listings/:id',
    async (data, thunkAPI) => {
        const response = await api.updateSupplyListing(data)
        // console.log(response)
        return response.data
    }
)

const fetchNotes = createAsyncThunk(
    'get:api/v1/supply-listings/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.fetchNotes(params)
        return response.data
    }
)

const addNote = createAsyncThunk(
    'post:api/v1/supply-listings/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.addNote(params)
        return response.data
    }
)

// const updateNote = createAsyncThunk(
//   'put:api/v1/supply-listings/:id/notes/:noteId',
//   async (params, thunkAPI) => {
//     const response = await api.updateNote(params)
//     return response.data
//   }
// )

const deleteNote = createAsyncThunk(
    'delete:api/v1/supply-listings/:id/notes/:noteId',
    async (params, thunkAPI) => {
        const response = await api.deleteNote(params)
        return response.data
    }
)


export {
    fetchSupplyListings,
    fetchSupplyListingFilters,
    createSupplyListing,
    fetchNotes,
    addNote,
    deleteNote,
    updateSupplyListing
}
