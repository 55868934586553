import React from 'react';
import AsyncSelect from 'react-select/async';

const DescriptiveDropDown = ({
                                 loadOptions,
                                 options,
                                 data,
                                 setData,
                                 placeholder,
                                 submit,
                                 width,
                                 height,
                                 disabled,
                                 backgroundColor
                             }) => {
    // options attribute takes an array of objects with the following structure 
    // { label: 'option name', value: 'option value' description: 'option description"}
    // value attribute takes a string
    return (
        <AsyncSelect
            isDisabled={disabled}
            loadOptions={loadOptions}
            placeholder={placeholder}
            defaultOptions={options}
            cacheOptions
            options={options}
            value={data}
            onChange={(e) => {
                setData(e)
            }}
            // menuIsOpen={true}
            getOptionLabel={(option) => (
                <div className='m-0 p-0 flex  '>
                    {option?.image && <img className='w-8 h-8 mr-2 rounded-full' src={option?.image} alt='icon'/>}
                    <div className='custom-container'>
                        <label className='block text-sm font-semibold  title'>{option.label}</label>
                        <label className='block text-xs text-gray-400  descr'>{option.description}</label>
                    </div>
                </div>
            )}
            // optionHeight={50}

            styles={{
                control: (provided, state) => ({
                    ...provided,
                    height: height,
                    width: width,
                    maxWidth: width,
                    backgroundColor: backgroundColor ? backgroundColor : '#F9FAFB',
                    borderRadius: 10,
                    margin: "auto",
                    border: state.isFocused ? '1px solid #00A3FF' : '1px solid #E5E5E5',
                    border: (data === '' && submit) && '2px solid red',
                    boxShadow: state.isFocused ? '0px 0px 0px 1px #00A3FF' : 'none',
                    '&:hover': {
                        border: state.isFocused ? '1px solid #00A3FF' : '1px solid #E5E5E5',
                    }
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? '#00A3FF' : 'white',
                    color: state.isSelected ? 'white' : 'black',
                    '&:hover': {
                        backgroundColor: '#00A3FF',
                        color: 'white'
                    }
                }),
                menu: (provided, state) => ({
                    ...provided,
                    borderRadius: 10,
                    ...width && {width: width},
                    overflow: 'hidden',
                    border: state.isFocused ? '1px solid #00A3FF' : '1px solid #E5E5E5',
                    boxShadow: state.isFocused ? '0px 0px 0px 1px #00A3FF' : 'none',
                    '&:hover': {
                        border: state.isFocused ? '1px solid #00A3FF' : '1px solid #E5E5E5',
                    }
                }),
                container: (provided, state) => ({
                    ...provided,
                    width: width,
                    maxWidth: width,
                    margin: 'auto',
                }),

            }}
        />
    )
}

export default DescriptiveDropDown;
