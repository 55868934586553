import handleIsFile from "../../../../../dynamic-form/util/handleIsFile";
import convertJsonToFormData from "../../../../../dynamic-form/util/convertJsonToFormData";
import filterObjectByKeys from "../../../../../dynamic-page/util/filterObjectByKeys";


const prepareDataToPost = async (detailData) => {


    const mappedItems = detailData?.summary?.order_items.map(item => {
        let innerItem = filterObjectByKeys(item, [

            // "title",
            // "sku",
            // "listing_id",
            // "upc",
            "qty",
            // "unit_price",
            // "arbitbox_product_id",
            // "unit_price_currency",
        ])
        // if (!item.arbitbox_product_id) delete innerItem.arbitbox_product_id;
        innerItem.arbitbox_product_id = item.product || null
        //if (handleIsFile(item.image, true))
        innerItem = {...innerItem, image: handleIsFile(item.image)}
        return innerItem

    })
    let mappedData = filterObjectByKeys(detailData, [
        "summary.sales_channel_id:sales_channel_id",
        "summary.sales_channel_account_id:sales_channel_account_id",
        "summary.order_date:order_date",
        "summary.ship_by_date:ship_by_date",
        "summary.shipping_paid:shipping_paid",
        "summary.shipping_paid_currency:shipping_paid_currency",
        "summary.order_id:order_id",

        "recipient.name:recipient[name]",
        "recipient.company:recipient[company]",
        "recipient.street1:recipient[street1]",
        "recipient.street2:recipient[street2]",
        "recipient.city:recipient[city]",
        "recipient.zip_postal_code:recipient[zip_postal_code]",
        "recipient.phone:recipient[phone]",
        "recipient.email:recipient[email]",
        "recipient.state:recipient[state]",
        "recipient.country:recipient[country]",
    ])

    mappedData = {...mappedData, items: mappedItems || []}
    if (detailData.id) {
        mappedData = {...mappedData, order_id: detailData.id}
    }

    return convertJsonToFormData(mappedData)


};

export default prepareDataToPost;