// dynamic-form/index.js
// -----------------------------------------------------------------------------

// Import General libraries
import React, {forwardRef, memo, useEffect, useImperativeHandle, useState} from "react";
import hasValue from "./util/hasValue";
import isBase64 from "./util/isBase64";
import base64ToBlob from "./util/base64ToBlob";
import validationNotifier from "./util/validationNotifier";
import * as Yup from 'yup';

// Import Elements
import Elements from './elements';

// Main Component: DynamicForm
const DynamicForm = memo(forwardRef((props, ref) => {

    const {items, style, onChange} = props;

    // Initial default value of form data.
    const [dynamicFormData, setDynamicFormData] = useState({});
    const [changedDataBind, setChangedDataBind] = useState(false);
    const [changedDataForm, setChangedDataForm] = useState(false);
    const [dynamicFormBoundData, setDynamicFormBoundData] = useState({});
    const [dynamicFormValidation, setDynamicFormValidation] = useState({});
    const [resetSelects, setResetSelects] = useState(null)

    useEffect(() => {

        // Crete initial data based on items.
        const initialDynamicFormData = items.reduce((acc, {name, defaultBound, defaultValue = null}) => {
            acc[name] = defaultBound?.id || defaultValue;
            return acc;
        }, {});

        // Create initial validation based on items.
        const initialDynamicFormValidation = items.reduce((acc, {name, validation = null}) => {
            acc[name] = validation;
            return acc;
        }, {});

        // Set initial data and validation to seperated state.
        setDynamicFormData(initialDynamicFormData);
        setDynamicFormValidation(initialDynamicFormValidation);

    }, [items, setDynamicFormData, setDynamicFormValidation])

    //call onChange if is available in props


    // useEffect(() => {
    //     //handleChangeCallBack(changedDataForm, setChangedDataForm)
    // }, [dynamicFormData]);

    // useEffect(() => {
    //     //handleChangeCallBack(changedDataBind, setChangedDataBind)
    // }, [dynamicFormBoundData]);

    const handleChangeCallBack = (state, setState) => {
        if (onChange) {
            if (!state) {
                onChange(dynamicFormData, dynamicFormBoundData)
                setState(true)
            }
        }
    }

    // Handle Validation
    const handleFormValidation = async (items, dynamicFormData) => {
        const validationErrors = {};

        for (const {name, validation} of items) {
            if (typeof validation === "function") {
                const validator = Yup.object().shape({[name]: validation(dynamicFormData, dynamicFormBoundData)});
                const innerData = hasValue(dynamicFormData[name]) ? dynamicFormData[name] : "";
                await validator.validate({[name]: innerData}).then((res) => {
                    validationErrors[name] = null
                }).catch((err) => {
                    validationErrors[name] = err.message.substring(0, 50) + (err.message.length > 50 ? "..." : "");
                })

            } else {
                const validator = Yup.object().shape({[name]: validation});
                const innerData = hasValue(dynamicFormData[name]) ? dynamicFormData[name] : "";
                await validator.validate({[name]: innerData}).then((res) => {
                    validationErrors[name] = null
                }).catch((err) => {
                    validationErrors[name] = err.message.substring(0, 50) + (err.message.length > 50 ? "..." : "");
                })
            }
        }
        setDynamicFormValidation(validationErrors)
        return validationErrors;
    };


    // Handle form change
    const handleChange = async (newData, name, bound) => {

        const inlineDynamicFormData = {...dynamicFormData, [name]: newData};
        const inlineDynamicFormBoundData = {...dynamicFormBoundData, [name]: bound};

        if (newData !== dynamicFormData[name]) {
            // handle state for value
            await setDynamicFormData((prevState) => ({
                ...prevState,
                [name]: newData,
            }));

            if (bound) {
                if (dynamicFormBoundData[name]?.id !== bound?.id) {
                    await setDynamicFormBoundData((prevState) => ({
                        ...prevState, [name]: bound
                    }));
                }
            }
            if (onChange) onChange(inlineDynamicFormData, inlineDynamicFormBoundData)

        }
        setChangedDataForm(false)
    };

    const handleBindData = (data, name) => {
        setDynamicFormBoundData((prevState) => ({
            ...prevState, [name]: data
        }));
        setChangedDataBind(false)
    }

    const handleIsFile = (key, value) => {
        const type = items?.find(obj => obj.name === key)?.type
        if (type === "Image") {
            if (isBase64(value)) {
                return base64ToBlob(value)
            } else {
                return null
            }
        }
        if (type === "Module") {
            if (isBase64(value)) {
                return base64ToBlob(value)
            } else {
                return value
            }
        }
        const matchedItem = items?.find(obj => obj.name === key)
        return matchedItem?.encode ? encodeURIComponent(value) : value
    }
    // Handle Submit Form
    useImperativeHandle(ref, () => ({
        // initial function to get data from dynamic form in parent component
        getData: async (withoutValidation) => {

            const validationErrors = await handleFormValidation(items, dynamicFormData);
            if (hasValue(validationErrors) && !withoutValidation) return false;

            const generateFormData = {};
        
            Object.entries(dynamicFormData).forEach(([key, value]) => {
                // handle nested object
                if (key.includes(":")) {
                    // handle nested object
                    const mainKey = key.split(":")[0];
                    const subKey = key.split(":")[1];
                    generateFormData[mainKey] = {
                        ...generateFormData[mainKey],
                        [subKey]: handleIsFile(subKey, value),
                    };
                } else {
                    generateFormData[key] = handleIsFile(key, value);
                }
            });
            return generateFormData;
        },
        validate: async (notification) => {
            const validationErrors = await handleFormValidation(items, dynamicFormData);
            const validationErrorsMessage = Object.fromEntries(Object.entries(validationErrors).filter(([key, value]) => value !== null));
            const isValid = !(hasValue(validationErrors));
            if (notification && !isValid) {
                validationNotifier(validationErrorsMessage)
            }
            return {isValid: isValid, validationErrors: validationErrorsMessage}
        },
        reset: (keysToReset) => {
            // reset all keys if keysToReset is not passed
            if (!keysToReset) {
                // setDynamicFormData((prevState) => {
                //     const inlineDynamicFormData = {...prevState};
                //     Object.keys(inlineDynamicFormData).forEach(key => {
                //         inlineDynamicFormData[key] = items.find(obj => obj.name === key)?.defaultValue || null
                //     });
                //     return inlineDynamicFormData;
                // });
                // loop through all the keys and reset the value to default value
                const inlineDynamicFormData = {...dynamicFormData};
                Object.keys(inlineDynamicFormData).forEach(key => {
                    // inlineDynamicFormData[key] = items.find(obj => obj.name === key)?.defaultValue || null
                    handleChange('', key)
                });
                return;
            }
            // reset only the keys that are passed in keysToReset array and keep the rest of the keys as it is
            const inlineDynamicFormData = {...dynamicFormData};
            keysToReset.forEach(key => {
                inlineDynamicFormData[key] = items.find(obj => obj.name === key)?.defaultValue || null
            });
            setDynamicFormData(inlineDynamicFormData);

        },
        getBoundData: () => {
            return dynamicFormBoundData;
        },


    }));


    // Define a factory function to create the components dynamically
    function createDynamicFormItem({key, type, ...rest}) {
        const Component = Elements[type] || Elements.Input;
        let form = {};
        let visible = true;
        if (type === "Module") {
            form = {
                validation: {},
                value: dynamicFormData[rest.item.name],
                data: dynamicFormData,
                binded: dynamicFormBoundData,
                submit: (data) => handleChange(data, rest.item.name),
                change: handleChange,
                bind: (data, name) => handleBindData(data, name || rest.item.name),
            }
            Object.keys(dynamicFormValidation).map((key) => {
                if (typeof dynamicFormValidation[key] === "string") {
                    form.validation[key] = dynamicFormValidation[key];
                }
            })
        }

        if (rest?.item?.condition) visible = rest?.item?.condition(dynamicFormData, dynamicFormBoundData);
        return (
            <>
                {visible &&
                    <Elements.Container setResetSelect={setResetSelects} key={key} formData={dynamicFormData} {...rest}
                                        style={style ? style : null}>
                        <Component {...rest} form=
                            {form} bind={handleBindData} formData={dynamicFormData}/>
                    </Elements.Container>
                }
            </>

        )
    }


    // Render the dynamic form
    return (
        <>
            {items.map((item, index) => {
                const value = dynamicFormData[item.name]
                let validationMessage = dynamicFormValidation[item.name] || "";
                validationMessage = typeof validationMessage === "string" ? validationMessage : "";
                return createDynamicFormItem({
                    key: index,
                    type: item.type,
                    value,
                    validationMessage,
                    item,
                    handleChange,
                    index,
                    resetSelects: resetSelects,
                    setResetSelect: setResetSelects
                });
            })}
        </>
    )
}));

export default DynamicForm;