import React from 'react'
import MainModal from '../../dynamic-page/MainModal'

function primary({item, onClose}) {


    const headList = [item?.type, 'Product Count', 'AVG 30d Sales Rank', 'Amazon Offer Count']

    const nameKey = item?.type?.toLowerCase()


    return (
        <MainModal

            item={{
                title: `Primary ${item?.type === 'Brand' ? 'Brands' : 'Categories'} for Seller ${item?.name}`,
                cancel: onClose,
                view: () => (
                    <table className={`w-full table-auto mt-5 h-max`}>
                        <thead className={"sticky t-[0px] z-[2] l-[0px] bg-gray-50 w-full"}
                               style={{position: "sticky", top: 0, left: 0, zIndex: 2}}>
                        <tr className={"text-gray-600 text-[12px] font-semibold"}>
                            {
                                headList.map((key, index) => (
                                    <th key={index}
                                        className="justify-start text-left h-[42px] items-center px-[16px] border-b border-gray-200">
                                    <span
                                        className="whitespace-no-wrap block min-w-max  text-gray-500 uppercase text-sm font-semibold select-none">
                                        {key}
                                    </span>
                                    </th>
                                ))
                            }

                        </tr>

                        </thead>
                        <tbody className={"relative"}>
                        {/* {carriers?.status === "pending" ?
                        <div className={"w-full gap-[16px] top-[20px] flex flex-col absolute"}>
                            {[...Array(10)].map((item, index) => (
                                <div className={"px-[0px] py-[1px]"} key={index}>
                                    <div className="skeleton transition" style={{ height: '10px', opacity:0.5+ Math.floor(Math.random()), width: `${Math.floor(Math.random() * 70)+30}%`, borderRadius: '50px', background: 'linear-gradient(to right, #eeeeee 0%, #dddddd 50%, #eeeeee 100%)', animation: 'skeleton-pulse 1.2s infinite' }}></div>
                                </div>
                            ))}
                        </div>
                    : */}
                        {item?.data &&
                            item?.data.map((item, index) => (
                                <tr className={`transition text-[14px] `} key={index}
                                >
                                    <td className={""}>
                                        <div
                                            className={`p-[16px] flex flex-row gap-[8px] items-center font-medium max-w-[350px] min-w-[170px]`}>
                                            {item[nameKey] || ''}
                                        </div>
                                    </td>
                                    <td className={""}>
                                        <div className={`p-[16px] flex flex-row gap-[8px] items-center font-medium`}>
                                            {item?.product_count}
                                        </div>
                                    </td>
                                    <td className={""}>
                                        <div className={`p-[16px] flex flex-row gap-[8px] items-center font-medium`}>
                                            {item?.avg_30_sales_rank}
                                        </div>
                                    </td>
                                    <td className={""}>
                                        <div className={`p-[16px] flex flex-row gap-[8px] items-center font-medium`}>
                                            {item?.product_count_with_amazon_offer}
                                        </div>
                                    </td>

                                </tr>
                            ))


                        }


                        </tbody>
                    </table>
                )
            }}
        />
    )
}

export default primary