import React from 'react';


const Divider = ({item}) => {

    return (
        <div className={`${item.className || ''}`}>
            <div className={"flex flex-col"}>
                <label className={"select-none text-[16px] text-gray-700 border-b border-gray-300"}>
                    <span>{item.label}</span>
                </label>
                <div>
                    {item.hint && <div className={"text-gray-400 text-sm mt-1"}>{item.hint}</div>}
                </div>

            </div>

        </div>
    )
}


export default Divider;