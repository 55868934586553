import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchShippingCostList = createAsyncThunk(
    'get:api/v1/shipping',
    async (params, thunkAPI) => {
        const response = await api.fetchShippingCostList(params)
        // console.log(response, 'response')
        return response.data
    }
)

const updateOpportunities = createAsyncThunk(
    'post:api/v1/shippingtemplate/refresh',
    async (payload, thunkAPI) => {
        const response = await api.updateOpportunities(payload)
        return response.data
    }
)

const fetchShippingCostListFilters = createAsyncThunk(
    'get:api/v1/shipping_cost_list/filters',
    async (thunkAPI) => {
        const response = await api.fetchShippingCostListFilters()
        // console.log(response, 'response')
        return response.data
    }
)

const bulkUpdateShippingCostList = createAsyncThunk(
    'post:api/v1/shipping_cost_list/bulk_update',
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdateShippingCostList(payload)
        return response.data
    }
)
const updateBulkShippingCostListStatus = createAsyncThunk(
    'patch:/api/v1/shipping_cost_list/status/bulk-action',
    async (payload, thunkAPI) => {
        const response = await api.updateBulkShippingCostListStatus(payload)
        return response
    }
)

const deleteBulkShippingCostList = createAsyncThunk(
    'delete:/api/v1/shipping_cost_list/delete/bulk-action',
    async (payload, thunkAPI) => {
        // console.log(payload, 'Thunk')
        const response = await api.deleteBulkShippingCostList(payload)
        return response.data;
    }
)

const restoreShippingCost = createAsyncThunk(
    'post:/api/v1/shipping_cost_list/restore/bulk-action',
    async (params, thunkAPI) => {
        const response = await api.restoreShippingCost(params)
        return response.data;
    }
)
export {
    fetchShippingCostList,
    updateOpportunities,
    fetchShippingCostListFilters,
    bulkUpdateShippingCostList,
    updateBulkShippingCostListStatus,
    deleteBulkShippingCostList,
    restoreShippingCost
}
