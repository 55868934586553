import React from 'react'
import BuyboxSelect from './buyboxSelect'

function RepricerPricing({type}) {
    return (
        <div className="w-80  p-1 gap-2 flex-col justify-between items-center inline-flex">
            <div className="px-2 py-1.5 rounded-lg border-2 border-orange-200 justify-center items-end inline-flex">
                <div className="w-24 flex-col justify-center items-start gap-[2px] inline-flex">
                    <div className="flex-col justify-center items-end gap-0.5 flex">
                        <div className="justify-end items-center gap-0.5 inline-flex">
                            <div className="text-red-700 text-lg font-medium font-['Inter'] !leading-[18px]">$16.82
                            </div>
                            <div className="w-3 h-3 relative"/>
                        </div>
                        <div className="self-stretch justify-between items-center inline-flex">
                            <div className="w-3 h-3 relative"/>
                            <div className="justify-center items-center gap-0.5 flex">
                                <div
                                    className="text-center text-red-700 text-sm font-normal font-['Inter'] !leading-[14px]">10.0%
                                </div>
                                <div className="w-3 h-3 relative"/>
                                <div className="w-3 h-3 relative"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-24 flex-col justify-center items-center gap-[2px] inline-flex">
                    <div className="flex-col justify-center items-center gap-0.5 flex">
                        <div className="justify-end items-center gap-0.5 inline-flex">
                            <div
                                className={`text-center ${type === 'fba' ? 'text-orange-700' : 'text-blue-700'} text-2xl font-bold font-['Inter'] !leading-[24px] `}>$17.75
                            </div>
                        </div>
                        <div className="self-stretch justify-center items-center inline-flex">
                            <div className="justify-center items-center gap-0.5 flex">
                                <div
                                    className={`text-center ${type === 'fba' ? 'text-orange-700' : 'text-blue-700'} text-sm font-medium font-['Inter'] !leading-[14px]`}>12.3%
                                </div>
                                <div className="w-3 h-3 relative"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-24 flex-col justify-center items-end gap-[2px] inline-flex">
                    <div className="flex-col justify-center items-end gap-0.5 flex">
                        <div className="justify-end items-center gap-0.5 inline-flex">
                            <div
                                className="text-center text-emerald-700 text-lg font-medium font-['Inter'] !leading-[18px]">$23.95
                            </div>
                            <div className="w-3 h-3 relative"/>
                        </div>
                        <div className="self-stretch justify-between items-center inline-flex">
                            <div className="w-3 h-3 relative"/>
                            <div className="justify-center items-center gap-0.5 flex">
                                <div
                                    className="text-center text-emerald-700 text-sm font-normal font-['Inter'] !leading-[14px]">25.0%
                                </div>
                                <div className="w-3 h-3 relative"/>
                                <div className="w-3 h-3 relative"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-stretch h-6 px-2 justify-between items-start inline-flex">
                <div className="justify-center items-center flex">
                    <div className="justify-start items-center gap-0.5 flex">
                        <div className="w-4 h-4 relative"/>
                        <div className="justify-start items-center gap-0.5 flex">
                            <div
                                className={`${type === 'fba' ? 'text-orange-700' : 'text-blue-700'} text-base font-medium font-['Inter'] leading-normal`}>4346
                            </div>
                            <div className="w-3 h-3 relative"/>
                        </div>
                    </div>
                </div>
                <div className="w-[157px] self-stretch  flex-col justify-start items-start gap-3 inline-flex">
                    <div className="self-stretch justify-start w-full items-center inline-flex">
                        <div className="grow shrink basis-0 ">
                            <BuyboxSelect/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RepricerPricing