// user-menu.js
// ----------------------------------------------------------------

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Import Elements
import { signout } from "../../store/auth";
import ChangeLanguage from "./../../locale/elements/ChangeLanguage";

// Main Component: UserMenu
const UserMenu = () => {
  // Initialize Refs & Hooks & States & Store
  const userMenuRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.authUser);
  const [isVisibleUserMenu, setVisibleUserMenu] = useState(false);
  const { t } = useTranslation();

  // Initial data model for user menu
  const userMenuData = useMemo(
    () => ({
      name:
        authUser?.name
          ?.split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ") || null,
      position: authUser?.position?.name || null,
      avatar: authUser?.image_url || "/assets/images/defaultAvatar.png",
    }),
    [authUser]
  );

  // Handle Logout
  const handleLogout = useCallback(() => {
    dispatch(signout());
    navigate("/login");
  }, [dispatch, navigate]);

  // Handle User Dropdown Toggle & Click Outside
  const handleUserDropdownToggle = useCallback(() => {
    setVisibleUserMenu((prev) => !prev);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setVisibleUserMenu(false);
      }
    },
    [userMenuRef]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  // Render Component
  return (
    <div className="relative " data-dial-init id={"userMenu"} ref={userMenuRef}>
      {/* User avatar and button is here */}
      <button
        onClick={handleUserDropdownToggle}
        type="button"
        className={`inline-flex items-center gap-x-1 text-sm leading-6 text-gray-900  overflow-hidden rounded-full  hover:border-gray-200  border-${
          isVisibleUserMenu ? "gray-200" : "white"
        } transition`}
        aria-expanded="false"
      >
        <img
          className="rounded-[16px] w-[32px] h-[32px] object-cover"
          src={userMenuData.avatar}
          alt={userMenuData.name}
        />
      </button>

      {/* User dropdown menu is here */}
      <div
        className={`absolute transition right-0 z-[200] mt-1 mr-0 flex w-screen max-w-max ${
          !isVisibleUserMenu && "hidden"
        }`}
      >
        <div className="w-64 divide-y  flex-auto rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 overflow-hidden">
          {/* User description is here */}
          <div className="p-1">
            <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
              <div className="font-bold truncate">{userMenuData.name} </div>
              <div>{userMenuData.position}</div>
            </div>
          </div>

          {/* Menu List is here */}
          <ul className="py-1 text-sm text-gray-700">
            <li className="px-4">
              {/* Change language dropdown is here */}
              {<ChangeLanguage />}
            </li>
          </ul>

          {/* Logout button is here */}
          <div>
            <button
              onClick={handleLogout}
              className="w-full transition px-4 py-4 hover:bg-gray-100 text-red-600 flex flex-row gap-2 items-center"
            >
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                height={"1.3em"}
                width={"1.3em"}
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
                />
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"
                />
              </svg>
              {t("sign_out", "Sign out")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
