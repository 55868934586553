import {createShipment} from "../../../../store/transfers/thunk"
import {useDispatch} from "react-redux";
import notifier from "../../../dynamic-page/util/notifier";


const useCreateShipmentInitializer = () => {
    const dispatch = useDispatch();

    const initializeShipment = async (carrier, callBack) => {
        dispatch(createShipment(carrier)).then((rs) => {

            notifier(rs?.payload?.data)
            carrier.callBack();
            if (rs?.payload?.data?.type === "success")
            callBack(rs?.payload?.data);
        })

        //callBack(rs?.payload?.data);
    };

    return {initializeShipment};
};

export default useCreateShipmentInitializer;
