import PropTypes from "prop-types"

const ArbitInput = ({label, value, placeholder, error, onChange}) => {
    return (
        <div>
            <div className="flex items-start mb-2">
                <label className="text-lg text-black font-bold">{label}</label>
            </div>
            <div className="relative flex items-center">
                <input
                    className="w-full py-4 border border-gray-300 rounded-md pl-4
                    bg-gray-50 focus:bg-white focus:ring-indigo-500 focus:ring-2 
                    focus:ring-offset-2 focus:ring-opacity-90 focus:outline-none"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={
                        (e) => {
                            onChange(e)
                        }
                    }
                />
            </div>
            <div className="flex items-start ml-2 text-[12px] text-red-400">{error}</div>
        </div>
    )
}

ArbitInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
}

ArbitInput.defaultProps = {
    label: "Arbit Input",
    placeholder: "Arbit Input",
    error: "",
    value: null,
    onChange: () => {
    }
}

export default ArbitInput;