import { howLongFromNow } from "../../../utils/helper";
import { FiRefreshCw } from "react-icons/fi";
const RefreshSales = ({ handleRefreshListings, item, refreshing, date }) => {
  return (
    <div className="flex gap-1.5 items-center">
      <div className="text-gray-400 text-[12px]  font-medium font-['Inter'] leading-normal">
        <div
          className={`flex flex-row items-center justify-center  gap-[4px] `}
        >
          <div className={"flip-x transform "}>
            <FiRefreshCw
              onClick={!refreshing ? () => handleRefreshListings(item) : null}
              className={`h-3.5 w-3.5  text-gray-300 hover:text-gray-500 ${
                refreshing === item?.id
                  ? "animate-spin rotate-[reverse] cursor-auto"
                  : refreshing
                  ? "cursor-auto"
                  : "cursor-pointer"
              }`}
            />
          </div>
        </div>
      </div>
      <div className="text-gray-400 text-[12px]  font-medium font-['Inter'] leading-normal">
        {howLongFromNow(date, true)}
      </div>
    </div>
  );
};
export default RefreshSales;
