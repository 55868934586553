import axios from "../../middleware/axios";
import constants from "../../constant";


export const fetchRows = async (params) => {
    let url = constants.APIURL.API_CONSTANT.concat(params.url)
    if (params && params.query) url = url.concat(params.query)
    return await axios.get(
        url, {withCredentials: true, headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json',}}
    )
}

const fetchFilters = async (params) => {
    let url = constants.APIURL.API_CONSTANT.concat(params.url)
    if (params) url = url.concat(params.query)
    return await axios.get(url, {withCredentials: true,})
}

export default {fetchRows, fetchFilters}