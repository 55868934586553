const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Updated At",
                value: "updated_at",
                onClick: (item) => {
                    callback("updated_at");
                }
            },

            // add for channel_id , account_id , qty, price, estimated_aged_inventory_cost, estimated_monthly_storage_fee, identifier, fulfilment
            {
                label: "Identifier",
                value: "identifier",
                onClick: (item) => {
                    callback("identifier");
                }
            },
            {
                label: "Fulfilment",
                value: "fulfilment",
                onClick: (item) => {
                    callback("fulfilment");
                }
            },
            {
                label: "Price",
                value: "price",
                onClick: (item) => {
                    callback("price");
                }
            },
            {
                label: "Quantity",
                value: "qty",
                onClick: (item) => {
                    callback("qty");
                }
            },
            {
                label: "Aged Inventory Cost",
                value: "estimated_aged_inventory_cost",
                onClick: (item) => {
                    callback("estimated_aged_inventory_cost");
                }
            },
            {
                label: "Storage Cost",
                value: "estimated_monthly_storage_fee",
                onClick: (item) => {
                    callback("estimated_monthly_storage_fee");
                }
            },
            {
                label: "Channel",
                value: "channel_id",
                onClick: (item) => {
                    callback("channel_id");
                }
            },
            {
                label: "Account",
                value: "account_id",
                onClick: (item) => {
                    callback("account_id");
                }
            },
            {
                label: "SKU",
                value: "sku",
                onClick: (item) => {
                    callback("sku");
                }
            },
        ]
    };
};

export default sortView;
  