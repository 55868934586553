import {createAsyncThunk} from "@reduxjs/toolkit";

import api from "./api";
import { fetchLoading } from ".";

const fetchOffers = createAsyncThunk(
    "get:api/v1/offers",
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchOffers(typeof payload === "string" ? payload : payload.query);
        return {data: data?.data , append : payload?.append};
    }
);
const fetchOffersFilters = createAsyncThunk(
    'https://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/offers/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchOffersFilters(params)
        return response.data
    }
)
const fetchOffer = createAsyncThunk(
    'get:api/v1/offer/:id',
    async (id, thunkAPI) => {
        const response = await api.fetchOffer(id)

        return response.data
    }
)

const offersUpdateStatus = createAsyncThunk(
    "put:api/v1/offers/:id/activation",
    async (payload, thunkAPI) => {
        const response = await api.offersUpdateStatus(payload);
        return response.data;
    }
);

const connectProduct = createAsyncThunk(
    "post:api/v1/offer/connect/:offerId/product/:productId",
    async (payload, thunkAPI) => {
        const response = await api.connectProduct(payload);
        return response.data;
    }
);

const disconnectProduct = createAsyncThunk(
    "post:api/v1/offer/connect/:offerId/product",
    async (payload, thunkAPI) => {
        const response = await api.disconnectProduct(payload);
        return response.data;
    }
);


export {fetchOffers, fetchOffer, fetchOffersFilters, offersUpdateStatus, connectProduct, disconnectProduct};