// getActionsList.js
// -----------------------------------------

const getActionList = (
  row = [],
  actions,
  statusMap = "status",
  specialStatus,
  onlySomeMetCondition
) => {
  // Get list row.
  const list = Array.isArray(row) ? row : [row];

  // Get list of status.
  const statusList =
    Array.from(new Set(list.map((item) => item?.[statusMap]))) || [];

  // Get list of actions.
  const actionsArray =
    statusMap === "none"
      ? Object.values(actions).map((item) => item.actions)
      : statusList.map((key) => actions[key]?.actions) || [];

  // Get common actions based on status.
  let commonActionsArray = actionsArray.reduce((common, array) => {
    return common.filter((element) => array.includes(element));
  }, actionsArray[0] || []);

  // -----------------------------------------
  // Conditionally remove actions from list.
  // You can add more conditions here.

  commonActionsArray = commonActionsArray.filter((item) => {
    // Check if the action should be disabled for bulk operations
    if (item?.bulkDisable === true && list?.length > 1) return false;
    // Check special case conditions
    if (specialStatus) {
      const specialConditions = specialStatus[item.label];
      if (specialConditions) {
        // check if the special condition is matched for all rows
        return list.every((row) => {
          return Object.entries(specialConditions).every(([key, value]) => {
            return row[key] !== value;
          });
        });
      }
    }
    if (item?.condition !== undefined) {
      if (typeof item?.condition === "function") {
        return onlySomeMetCondition
          ? list.some((child) => item.condition(child))
          : list.every((child) => item.condition(child));
      } else {
        return item?.condition;
      }
    }
    return true;
  });
  // -----------------------------------------

  // Return available actions.
  return { count: list.length, list: commonActionsArray };
};

export default getActionList;
