import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchBrands = async (params) => {
    let url = constants.APIURL.GET_BRANDS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response?.data;
};

const searchBrands = async (params) => {
    let url = constants.APIURL.GET_BRANDS + "/search?term=";
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response?.data;
};

const deleteBrand = async (payload) => {
    const response = await axios.delete(constants.APIURL.GET_BRANDS, {
        withCredentials: true,
        data: {ids: payload},
    });
    return response;
};

const createBrand = async (payload) => {
    const response = await axios.post(
        constants.APIURL.POST_CREATE_BRAND,
        payload,
        {withCredentials: true}
    );
    return response;
};

const updateBrand = async ({id, payload}) => {
    const response = await axios.put(
        constants.APIURL.PUT_UPDATE_BRAND + id,
        payload,
        {withCredentials: true}
    );
    return response;
};

const assignEmployee = async (payload) => {
    const response = await axios.post(
        constants.APIURL.GET_BRANDS + `/bulk-assign`,
        payload,
        {withCredentials: true}
    );
    return response;
};

const unassignEmployee = async (id) => {
    const response = await axios.post(
        constants.APIURL.GET_BRANDS + `/bulk-unassign`,
        id,
        {withCredentials: true}
    );
    return response;
};

export default {
    fetchBrands,
    deleteBrand,
    createBrand,
    updateBrand,
    searchBrands,
    assignEmployee,
    unassignEmployee,
};
