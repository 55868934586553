import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";
import constants from '../../constant';


const fetchOrdersStatusesFilters = createAsyncThunk(
    "get:" + constants.APIURL.GET_ORDERS_FILTERS,
    async (thunkAPI) => {
        const response = await api.fetchOrdersStatuses();
        return response;
    }
);

const fetchDashboardStatuses = createAsyncThunk(
    "get:api/v1/dashboard",
    async (payload, thunkAPI) => {
        const response = await api.fetchDashboardStatuses();
        return response.data;
    }
);

const fetchShipmentsStatusesFilters = createAsyncThunk(
    "get:api/v1/orders/shipment-labels/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchShipmentsFilters(payload);
        return response.data;
    }
);

const fetchOffersStatusesFilters = createAsyncThunk(
    "get:api/v1/offers/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchOffersFilters(payload);
        return response.data;
    }
);

const fetchSalesStatusesFilters = createAsyncThunk(
    "get:api/v1/account-listing/filter",
    async (payload, thunkAPI) => {
        const response = await api.fetchSalesFilters(payload);
        return response.data;
    }
);

const fetchTargetStatusesFilters = createAsyncThunk(
    "get:api/v1/target-seller-listings/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchTargetFilters(payload);
        return response.data;
    }
);

export {
    fetchOrdersStatusesFilters,
    fetchShipmentsStatusesFilters,
    fetchTargetStatusesFilters,
    fetchOffersStatusesFilters,
    fetchSalesStatusesFilters,
    fetchDashboardStatuses
};
