import React, { useEffect, useState } from "react";
import axios from "axios";

import MainModal from "../../dynamic-page/MainModal";
import Util from "../../dynamic-page/util";
import UI from "../../dynamic-form/elements";
import Icons from "../../orders/icons";
import { HiExternalLink } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { connectProduct } from "../../../store/sales-listings";
import ProductDetailSelectItems from "./productDetailSearchAndConnect";
import priceHandler from "../../../utils/priceHandler";
import { toast } from "react-toastify";
import { updateSupplyListing } from "../../../store/supply-channel-listings/thunk";

// Main Component: ProductConnectChannelModal
const ProductConnectChannelModal = React.memo(
  ({ onClose, product_id, callBack, defaultListings, updateRows,setModalVisibility ,setShowSalesModal}) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({
      products: [],
      salesChannels: [],
    });
    const [results, setResults] = useState([]);

    const [defaultProduct, setDefaultProduct] = useState(null);
    const [defaultListingItems, setDefaultListingItems] = useState(null);
    const [connectLoading, setConnectLoading] = useState({});
    const [inputValue, setInputValue] = useState("");
    const [isConnectClicked, setConnectClicked] = useState(false);

    useEffect(() => {
      if (product_id) {
        const fetchProducts = async (params) => {
          let url = "api/v1/product/" + product_id;
          if (params) url = url.concat(params);
          const response = await axios.get(url, { withCredentials: true });

          setDefaultProduct(mapProducts(response.data?.data));
          setData((prev) => ({
            ...prev,
            products: [mapProducts(response.data?.data)],
          }));
          // return
        };
        fetchProducts();
      }
      if (defaultListings) {
        setData((prev) => ({
          ...prev,
          salesChannels: defaultListings.map(mapListings),
        }));
        setDefaultListingItems(defaultListings.map(mapListings));
      }
    }, [product_id]);

    const mapProducts = (item) => ({
      id: item.id,
      label: item.title,
      image: item.image,
      apid: item.apid,
      type: item.type,
      brand: item?.brand?.name,
      assign: item?.brand?.assigned,
      numbers: {
        "Supply CL": item.supply_channels_count,
        "Sales CL": item.sales_channels_count,
        Inventory: item.inventory_count,
        Orders: 0,
        "Avg. Cost": item?.average_cost_usd
          ? `$${item?.average_cost_usd}`
          : "-",
      },
    });

    const mapListings = (item) => ({
      id: item.id,
      identifier: item.identifier,
      url: item.url,
      label: item.title,
      channel: item.channel?.name,
      brand: item?.brand?.name,
      image: item?.image,
      price:item?.price,
      currency:item?.currency,
      is_trackable: item?.is_trackable,
      ...(item?.is_trackable && { quantity: item?.quantity }),
    });

    const onConnect = () => {
      if (data?.salesChannels?.length > 0) {
        setConnectClicked(true);
        data?.salesChannels
          ?.filter((item) => {
            // return only the ones that eiither not in connectLoading or failed
            return (
              connectLoading[item.id] === "Failed!" || !connectLoading[item.id]
            );
          })
          ?.forEach((supplyChannel) => {
            setConnectLoading((prevState) => ({
              ...prevState,
              [supplyChannel.id]: "Connecting...",
            }));
            dispatch(
              updateSupplyListing({
                id: supplyChannel?.id,
                payload: {
                  is_trackable: supplyChannel?.is_trackable,
                  product_id: data?.products[0]?.id,
                  ...(supplyChannel?.is_trackable && {
                    quantity: supplyChannel?.quantity,
                  }),
                },
              })
            ).then((result) => {
    
              if (result?.payload?.type === "success") {
                if (product_id) callBack();
                else updateRows([supplyChannel.id]);
               
                setConnectLoading((prevState) => ({
                  ...prevState,
                  [supplyChannel.id]: "Connected!",
                }));
                setConnectClicked(false);
                // setData(prev => ({
                //   ...prev,
                //   supplyChannels: prev.supplyChannels.filter((item) => item.id !== supplyChannel.id)
                // }))
                setModalVisibility(false)
                setShowSalesModal(false)
              } else {
                setConnectLoading((prevState) => ({
                  ...prevState,
                  [supplyChannel.id]: "Failed!",
                }));
              }
            });
            //   util.notifier({
            //     type: "success",
            //     message: result?.payload?.message,
            //     errors: result?.payload?.errors
            // })
          });
      } else {
        toast.info("Please select at least one product.");
      }
    };

    return (
      <MainModal
        className="max-w-[1200px] "
        mainClassName=" min-h-[672px]"
        containerClassName="!pb-[20px] "
        headerClassName=" !px-[19px] !pb-[16px]  "
        item={{
          title: "Connect Supply Channel Listings",
          cancel: onClose,
          view: () => (
            <div className="app flex flex-col gap-5 border-t pt-[17px]  border-gray-300">
              <ProductDetailSelectItems
                url="supply-channel-listing"
                results={results}
                setResults={setResults}
                inputValue={inputValue}
                setInputValue={setInputValue}
                isConnectClicked={isConnectClicked}
                mapItems={mapListings}
                createNewRedirect={false}
                searchBarClassName={"!w-[1200px]"}
                ItemCard={SalesChannelItem}
                defaultItem={defaultListingItems}
                multiple
                key="supplies"
                data={data}
                setData={setData}
                connectLoading={connectLoading}
                newDesign={true}
                params="filter[has_product]=false&include=product.brand.assigned,currency,channel,brand"
              />
              {inputValue && (
                // not all sales channels are connected
                <div className="flex flex-row justify-end gap-[8px] sticky bg-white bottom-0 ">
                  <UI.Button
                    item={{
                      label: <div className={"flex flex-row"}>Cancel</div>,
                      buttonType: "alt",
                      className: "h-[41px] !w-[120px] !px-[20px]",
                    }}
                    onClick={() => onClose()}
                  />
                  <UI.Button
                    item={{
                      label: (
                        <div className={"flex flex-row"}>
                          {isConnectClicked ? "Connecting..." : "Connect"}
                        </div>
                      ),
                      buttonType: "primary",
                      disabled: isConnectClicked,
                      className: "h-[41px] !w-[120px]  !px-[20px]",
                    }}
                    onClick={() => onConnect()}
                  />
                </div>
              )}
              {}
              {/* {Object.keys(connectLoading).length > 0 &&
                data?.salesChannels.every(
                  (item) => connectLoading[item.id] === "Connected!"
                ) && (
                  <div className="flex flex-row justify-end gap-[8px]">
                    <UI.Button
                      item={{
                        label: <div className={"flex flex-row"}>Close</div>,
                        buttonType: "alt",
                        className: "h-[37px] !w-[120px] !px-[16px]",
                      }}
                      onClick={() => onClose()}
                    />
                  </div>
                )} */}
            </div>
          ),
        }}
      />
    );
  }
);

export default ProductConnectChannelModal;

export const SalesChannelItem = ({
  item,
  index,
  results,
  searching,
  setSelectedProduct,
  setSearching,
  selectedProduct,
  setData,
  data,
  isConnectClicked,
  connectLoading,
  selection,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isConnectClicked) {
      setIsChecked(false);
    }
  }, [isConnectClicked]);
  return (
    <div
      key={index}
      className={` w-[100%] ${
        isChecked ? "bg-blue-100 " : ""
      } flex items-center px-4 gap-4 h-[121px] border-b border-gray-200  shadow-sm `}
    >
      <div>
        <input
          type="checkbox"
          className="rounded-[4px] border border-gray-300 bg-gray-50 cursor-pointer"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);

            if (!isChecked) {
              // If checkbox is checked (adding item)
              const updatedSelection = [...selectedProduct, item];
              setSelectedProduct(updatedSelection);
              setData((prevData) => ({
                ...prevData,
                salesChannels: updatedSelection,
              }));
            } else {
              // If checkbox is unchecked (removing item)
              const updatedSelection = selectedProduct.filter(
                (selected) => selected.id !== item.id // Ensure you compare based on unique IDs
              );
              setSelectedProduct(updatedSelection);
              setData((prevData) => ({
                ...prevData,
                salesChannels: updatedSelection,
              }));
            }
          }}
        />
      </div>

      <img
        src={item.image || "/assets/images/noImage.png"}
        alt={item.label}
        className={`result-image ${
          searching
            ? "w-[129px] h-[110px] max-h-[110px]"
            : "w-[152px] h-[152px] max-h-[152px] "
        } object-contain rounded`}
      />
      <div className="result-details flex-1 flex  flex-col h-full justify-between  py-4 gap-4">
        <div>
          <p className="text-[20px] leading-[25px] text-gray-900 font-semibold whitespace-pre-wrap">
            {item?.label?.length > 170
              ? Util.shortenString(item?.label, 170, false)
              : item?.label
              ? item?.label
              : "-"}
          </p>
        </div>
        <div className="flex gap-4  ">
          <div className="flex flex-col gap-2 w-[25%]">
            <span className="text-[16px] text-gray-500 leading-[16px] ">
              Supply Channel
            </span>
            <div className="flex items-center gap-1.5">
              <span className="text-[16px] font-medium leading-[24px]  text-gray-900 ">
                {item?.channel}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-[25%]">
            <span className="text-[16px] text-gray-500 leading-[16px] ">
              Brand
            </span>
            <span className="text-[16px] font-medium leading-[24px] text-gray-900 ">
              {item?.brand ? item?.brand : "-"}
            </span>
          </div>
          <div className="flex flex-col gap-2 w-[25%]">
            <span className="text-[16px] text-gray-500 leading-[16px] ">
              Current Price
            </span>
            <span className="text-[16px] font-medium leading-[24px] text-gray-900 ">
              {item?.price ? (
                <span>{priceHandler(item?.price,item?.currency)}</span>
              ) : (
                "-"
              )}
            </span>
          </div>
          <div className="flex flex-col gap-2 w-[25%]">
            <span className="text-[16px] text-gray-500 leading-[16px] ">
              Listing ID
            </span>
            <div className="flex gap-[2px] items-center">
              <span className="text-[16px] font-medium leading-[24px] text-gray-900 ">
                {item?.identifier ? item?.identifier : "-"}
              </span>
              {item?.url && <HiExternalLink
                className="w-4 h-4 text-gray-300 hover:text-gray-500 cursor-pointer"
                onClick={() => {
                  window.open(item?.url, "_blank");
                }}
              />}
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};
