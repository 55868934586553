import React, { useEffect, useState } from "react";

const Checkbox = ({
  item,
  value = [],
  validationMessage,
  bind,
  handleChange,
  formData,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);

  // Check if an item is disabled
  const disabled =
    typeof item.disabled === "function"
      ? item.disabled(formData)
      : item.disabled || false;

  // Initialize selected values with default or current values
  useEffect(() => {
    const initialValues = Array.isArray(value) && value.length > 0 ? value : [];
    setSelectedValues(initialValues);
    bind &&
      bind(
        item?.list?.filter((obj) => initialValues.includes(obj.value)),
        item?.name
      );
  }, [value]);

  // Helper function to return checked state for each checkbox
  const isChecked = (option) => {
    return selectedValues.includes(option);
  };

  // Handle checking/unchecking an individual checkbox
  const handleUpdate = (option) => {
    const updatedArray = selectedValues.includes(option)
      ? selectedValues.filter((val) => val !== option)
      : [...selectedValues, option];

    // Update state and call the handleChange method
    setSelectedValues(updatedArray);
    handleChange(updatedArray, item?.name);

    // Bind the full object for external usage
    bind &&
      bind(
        item?.list?.filter((obj) => updatedArray.includes(obj.value)),
        item?.name
      );
  };

  // Handle the 'Select All' functionality
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const allValues = item?.list?.map((obj) => obj.value);
      setSelectedValues(allValues);
      handleChange(allValues, item?.name);
      bind &&
        bind(
          item?.list?.map((obj) => obj),
          item?.name
        );
    } else {
      setSelectedValues([]);
      handleChange([], item?.name);
      bind && bind([], item?.name);
    }
  };

  return (
    <div className={"flex gap-2 flex-col"}>
      {item.checkAll && item?.list && item?.list?.length > 1 && (
        <div
          className={"flex gap-2 items-center border-b border-b-gray-200 pb-2"}
        >
          <input
            type={"checkbox"}
            className={`transition w-[20px] h-[20px] ${
              disabled && "disabled"
            } rounded-md px-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 ${
              validationMessage ? "border-red-500 border-2" : ""
            }`}
            onChange={handleCheckAll}
            checked={selectedValues.length === item?.list?.length}
            indeterminate={
              selectedValues.length > 0 &&
              selectedValues.length < item?.list?.length
            } // Show indeterminate state if some items are checked
          />
          <span className="flex flex-row gap-1">
            <span className="text-gray-400">Select All</span>
            <span className="text-gray-400">
              ({item?.list?.reduce((acc, curr) => acc + curr.count, 0)})
            </span>
          </span>
        </div>
      )}

      {item?.list && item?.list?.length > 0 ? (
        item?.list?.map((option, index) => (
          <div className={"flex gap-2 items-center"} key={index}>
            <input
              name={option.value}
              type={"checkbox"}
              checked={isChecked(option.value)} // Use state to check if this option is selected
              disabled={disabled}
              className={`transition w-[20px] h-[20px] ${
                disabled && "disabled"
              } rounded-md px-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 ${
                validationMessage ? "border-red-500 border-2" : ""
              }`}
              onChange={() => handleUpdate(option.value)} // Update the selected values on change
            />
            <span className="flex flex-row gap-1">
              {option.label}
              {option.count !== undefined && (
                <span className="text-gray-400">({option.count})</span>
              )}
            </span>
          </div>
        ))
      ) : (
        <div className={"text-gray-400"}>No Items</div>
      )}
    </div>
  );
};

export default Checkbox;
