const tabsView = (pageData) => {
    return ({

        orderSummary: {
            label: "Order Summary",
            count: pageData?.filtersMeta?.summary?.find(item => item.name === "order_count")?.count || 0,
            filter: {
                summaryType: 'order'
            },
            api: {
                fetch: "product-summary",
                filters: "product-summary/filters",
            }
        },
        purchaseSummary: {
            label: "Purchase Summary",
            count: pageData?.filtersMeta?.summary?.find(item => item.name === "supply_purchase_count")?.count || 0,
            filter: {
                summaryType: 'supply_purchase'
            },
            api: {
                fetch: "product-summary",
                filters: "product-summary/filters",
            }
        },
        requestSummary: {
            label: "Request Summary",
            count: pageData?.filtersMeta?.summary?.find(item => item.name === "supply_request_count")?.count || 0,
            filter: {
                summaryType: 'supply_request'
            },
            api: {
                fetch: "product-summary",
                filters: "product-summary/filters",
            }
        },
        All: {
            label: "Resupply",
            count: pageData?.filtersMeta?.summary?.find(item => item.name === "resupply_count")?.count || 0,
            filter: {
                summaryType: 'resupply'
            },
            active: true,
            api: {
                fetch: "product-summary",
                filters: "product-summary/filters",
            }
        },
    })
}
export default tabsView;