import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteUnits,
  bulkRestoreUnits,
  createUnit,
  deleteUnit,
  fetchUnits,
  fetchUnitsFilters,
  searchUnits,
  updateUnit,
  updateUnitsStatus,
} from "./thunk";

const initialState = {
  units: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {},
  meta: {},
  filtersMeta: {},
};

export const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchUnits: (state, action) => {
      state.units = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateUnitStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.units = state.units.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeUnit: (state, action) => {
      const ids = action.payload;
      state.units = state.units.filter((curr) => !ids.includes(curr.id));
    },
    bulkDeleteUnits: (state, action) => {
      const ids = action.payload;
      state.units = state.units.filter(
        (currency) => !ids.includes(currency.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnits.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchUnits.rejected, (state, action) => {
        state.loading = "idle";
      })
      .addCase(fetchUnits.fulfilled, (state, action) => {
        state.loading = "idle";
        state.units = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      // .addCase(fetchUnitsFilters.pending, (state, action) => {
      //     state.loading = "pending";
      // })
      .addCase(fetchUnitsFilters.fulfilled, (state, action) => {
        state.loading = "idle";
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeUnit,
  updateUnitStatuses,
} = unitsSlice.actions;

export {
  fetchUnits,
  createUnit,
  updateUnit,
  deleteUnit,
  searchUnits,
  bulkDeleteUnits,
  updateUnitsStatus,
  bulkRestoreUnits,
  fetchUnitsFilters,
};

export default unitsSlice.reducer;
