import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";


const fetchAccounts = createAsyncThunk(
    "get:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.fetchAccounts(payload);
        return response.data;
    }
);

const fetchAccountsFilters = createAsyncThunk(
    "get:api/v1/accountsFilters",
    async (payload, thunkAPI) => {
        const response = await api.fetchAccountsFilters(payload);
        return response;
    }
);

const updateAccountsStatus = createAsyncThunk(
    "patch:api/v1/accountsStatus",
    async (payload, thunkAPI) => {
        const response = await api.updateAccountsStatus(payload);
        return response;
    }
);

const deleteAccount = createAsyncThunk(
    "delete:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.deleteAccount(payload);
        return response;
    }
);

const restoreAccount = createAsyncThunk(
    "post:api/v1/accountsRestore",
    async (payload, thunkAPI) => {
        const response = await api.restoreAccount(payload);
        return response;
    }
);

const bulkDeleteAccounts = createAsyncThunk(
    "delete:api/v1/bulkAccounts",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteAccounts(payload);
        return response;
    }
);

// const fetchAccountsFilters = createAsyncThunk(
//   'https://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/channels/filters',
//   async (thunkAPI) => {
//     const response = await api.fetchAccountsFilters()
//     return response.data
//   }
// )

const createAccount = createAsyncThunk(
    "post:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.createAccount(payload);
        return response;
    }
);

const updateAccount = createAsyncThunk(
    "put:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.updateAccount(payload);
        return response;
    }
);

const attachProxies = createAsyncThunk(
    "post:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.attachProxies(payload);
        return response;
    }
);

const detachProxies = createAsyncThunk(
    "post:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.detachProxies(payload);
        return response;
    }
);

const syncWithMarketplace = createAsyncThunk(
    "post:api/v1/accounts/sync-with-marketplace",
    async (payload, thunkAPI) => {
        return await api.syncWithMarketplace();
    }
);


const authorizeStore = createAsyncThunk(
    "post:api/v1/accounts/authorize-store",
    async ({id}) => {
        const response = await api.authorizeStore(id);
        return response;
    }
);

const authorizeStoreCallBack = createAsyncThunk(
    "post:api/v1/accounts/authorize-store-oauth-callback",
    async ({payload, marketplace}) => {
        return await api.authorizeStoreCallBack(payload, marketplace);
    }
);


export {
    fetchAccounts,
    fetchAccountsFilters,
    updateAccountsStatus,
    deleteAccount,
    restoreAccount,
    bulkDeleteAccounts,
    createAccount,
    updateAccount,
    attachProxies,
    detachProxies,
    syncWithMarketplace,
    authorizeStore,
    authorizeStoreCallBack
};