const sortView = ({callback}) => {
    return {
        list: [

            {
                label: "Name",
                value: "name",
                onClick: (item) => {
                    callback("name");
                }
            },
        ]


    }
}

export default sortView