const tabsView = (pageData) => {
    return ({

        single: {
            label: "Single Products",
            count: pageData?.filtersMeta?.types?.find(item => item.name === "single")?.count || 0,
            filter: {
                type: 1
            },
        },
        group: {
            label: "Grouped Products",
            count: pageData?.filtersMeta?.types?.find(item => item.name === "groupped")?.count || 0,
            filter: {
                type: 2
            },
        },
        all: {
            label: "All",
            count: pageData?.filtersMeta?.types?.reduce((acc, curr) => acc + curr.count, 0),
            active: true,

        },
    })
}
export default tabsView;