import React from 'react'
import ContentLoader from 'react-content-loader'

const GithubProfile = props => {
    return (
        <ContentLoader
            height={300}
            width={1500}
            className='mt-32'
            viewBox="0 0 1500 300"
            backgroundColor="#f5f5f5"
            foregroundColor="#dbdbdb"
            {...props}
        >
            <rect x="50" y="10" rx="3" ry="3" width="200" height="200"/>
            {/* <rect x="200" y="15" rx="3" ry="3" width="100" height="15" />
      <rect x="215" y="15" rx="3" ry="3" width="100" height="15" />
      <rect x="270" y="15" rx="3" ry="3" width="100" height="15" />
      <rect x="325" y="15" rx="3" ry="3" width="100" height="15" /> */}

            {/* <rect x="300" y="35" rx="3" ry="3" width="580" height="1" />

      <rect x="300" y="45" rx="3" ry="3" width="70" height="8" />
      <rect x="380" y="45" rx="3" ry="3" width="140" height="8" /> */}

            <rect x="300" y="2" rx="3" ry="3" width="300" height="30"/>

            <rect x="300" y="40" rx="3" ry="20" width="260" height="75"/>
            <rect x="600" y="40" rx="3" ry="3" width="260" height="75"/>
            <rect x="900" y="40" rx="3" ry="3" width="260" height="75"/>
            <rect x="1200" y="40" rx="3" ry="3" width="260" height="75"/>
            <rect x="1500" y="40" rx="3" ry="3" width="260" height="75"/>


            <rect x="300" y="130" rx="3" ry="3" width="260" height="75"/>
            <rect x="600" y="130" rx="3" ry="3" width="260" height="75"/>
            <rect x="900" y="130" rx="3" ry="3" width="260" height="75"/>
            <rect x="1200" y="130" rx="3" ry="3" width="260" height="75"/>
            <rect x="1500" y="130" rx="3" ry="3" width="260" height="75"/>


            {/* do 6 more boxes with the same width but increase the x  */}


        </ContentLoader>
    )
}

GithubProfile.metadata = {
    name: 'Nikhil Anand', // My name
    github: 'anandnkhl', // Github username
    description: 'Latest Github Profile', // Little tagline
    filename: 'GithubProfile', // filename of your loaderr
}

export default GithubProfile
