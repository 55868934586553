import upperCaseString from "../../../../dynamic-page/util/upperCaseString";

const warehouseListForm = (order, shipmentItem, label) => [
  {
    label: label,
    name: "warehouse",
    type: "Select",
    api: {
      url: "/api/v1/warehouses",
      //url: '/api/v1/warehouses',
      query: "&filter[name]={query}",
      view: "/api/v1/warehouses",
      optionValue: (item) => ({
        value: item?.id,
        label: upperCaseString(item?.name, "word", true),
      }),
    },
    className: "h-[42px]",
    style: {
      control: {
        height: "42px !important",
        minHeight: "auto",
        // marginTop: '-9px !important',
        width: "200px !important",
        minWidth: "200px !important",
        background: "rgba(0,0,0,0.03)",
      },
      menu: {
        whiteSpace: "wrap !important",
      },
      valueContainer: {
        width: "174px !important",
        minWidth: "174px !important",
      },
      singleValue: {
        width: "154px !important",
        minWidth: "154px !important",
      }
    },
    defaultValue: shipmentItem?.warehouseId || order?.defaultWarehouse || null,
    reset: false,
    clear: false,

    placeholder: "Select Warehouse",
  },
];

export default warehouseListForm;
