import axios from "./../../middleware/axios";
import constants from "../../constant";
import {apiutils} from "../../utils";

const fetchUsers = async (params) => {
    let url = constants.APIURL.GET_USERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
};
const addUser = async (payload) => {
    // console.log(payload, 'api');
    const response = await axios.post(constants.APIURL.POST_USER, apiutils.obj2Form(payload), {withCredentials: true})
    // console.log(response, 'api');
    return response
}
const updateUser = async (id, payload) => {
    // console.log(id, payload, 'Update user api');
    const response = await axios.post(constants.APIURL.PUT_USER + "/" + id, apiutils.obj2Form(payload), {withCredentials: true})
    // console.log(response, 'Update user api');
    return response
}

const fetchUsersFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_USERS_FILTERS, {withCredentials: true});
    // console.log(response, 'fetchUsersFilters response In API');
    return response
}
const fetchUsersPermissions = async (id) => {
    let url = constants.APIURL.ME_URL + "/" + id + constants.APIURL.GET_USER_PERMISSIONS;
    // if (params) url = url.concat(params);
    // console.log(id,  'api now');
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    // console.log(response, 'api');

    return response.data

}

const fetchUserActivities = async (id) => {
    let url = constants.APIURL.GET_USER + "/" + id + "/activities/?limit=20";
    const response = await axios.get(url, {withCredentials: true})
    return response.data
}

const bulkUpdateUser = async (payload) => {
    // console.log(payload, 'api');
    const response = await axios.patch(constants.APIURL.BULK_STATUS_UPDATE, payload, {withCredentials: true})
    // console.log(response, 'api');
    return response
}

const fetchAudits = async (id) => {
    let url = constants.APIURL.ME_URL + "/" + id + constants.APIURL.GET_AUDITS;
    // if (params) url = url.concat(params);
    // console.log(id,  'api now');
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    // console.log(response, 'api');
    return response.data

}

const createDepartment = async (payload) => {
    // console.log(payload, 'api');
    const response = await axios.post(constants.APIURL.POST_DEPARTMENT, apiutils.obj2Form(payload), {withCredentials: true})
    // console.log(response, 'api');
    return response
}


export default {
    fetchUsers,
    updateUser,
    fetchUsersFilters,
    addUser,
    fetchUsersPermissions,
    bulkUpdateUser,
    fetchAudits,
    createDepartment,
    fetchUserActivities,
};
