import constants from "../../../constant";

// todo : add an object for all filters and use them inside of the array inside the if check
const filtersView = (selectedFilers) => {
  // if (tab == 'listings') {
  //         //render listing filters
  //     // [status, sales..]
  // }
  // else if (tab == 'orders'  ) {
  //         //render orders filters

  return [
    {
      name: "name",
      label: "Name",
      placeholder: "Filter by Name",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilers?.name,
    },

    {
      name: "channel",
      label: "Channel",
      defaultValue: selectedFilers?.channel || null,
      placeholder: "Filter by Channel",
      type: "Select",
      toggle: true,
      isClearable: true,
      features: {
        formatOptionLabel: (item) => (
          <div className={"flex flex-row gap-[12px] items-center"}>
            <img
              className={
                "w-[30px] h-[30px] object-cover rounded-full border-0 bg-gray-200"
              }
              src={item.data.logo}
            />
            <span>{item?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: constants.APIURL.GET_CHANNELS,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.purchase_no,
        }),
      },
    },
    //      {
    //         name: "status",
    //         label: "Status",
    //         placeholder: "Filter by status",
    //         type: "Checkbox",
    //         list: filtersMeta?.data?.data?.statuses?.map((item) => ({
    //             label: upperCaseString(item?.name),
    //             value: item?.id,
    //             count : item?.count
    //             })) ,
    //         defaultValue: selectedFilers?.order_status,
    //         toggle: true,
    //         clear: true,
    //         features: {
    //             isMulti: true,
    //         },
    //         checkAll: true,
    //     },

    {
      name: "created_at",
      label: "Created At",
      placeholder: "Filter by Create Date",
      type: "DateRange",
      defaultValue: selectedFilers?.created_at,
      toggle: true,
      clear: true,
    },

    //     {
    //         name: "brand",
    //         label: "Brand",
    //         placeholder: "Select Brand",
    //         type: "Select",
    //         api: {
    //             url: "/api/v1/brands",
    //             query: "/search?term={query}",
    //             optionValue: (item) => ({
    //                 value: item.id,
    //                 label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
    //             })
    //         },
    //         defaultValue: formDefaultValue?.brand?.id || null,
    //     },

    //     {
    //         name: "root_category",
    //         label: "Root Category",
    //         placeholder: "Filter by Root Category",
    //         type: "Select",
    //         api: {
    //             url: "/api/v1/category",
    //             query: "?filter[name]={query}",
    //             optionValue: (item) => ({
    //                 value: item?.id,
    //                 label: item?.name,
    //             })
    //         },
    //         defaultValue: selectedFilers?.root_category,
    //         toggle: true,
    //         clear: true,
    //     },
    //     {
    //         name: "item_title",
    //         label: "Item Title",
    //         placeholder: "Filter by item title",
    //         type: "Input",
    //         defaultValue: selectedFilers?.item_title,
    //         toggle: true,
    //         clear: true,
    //     },
    //     {
    //         name: "items_count",
    //         label: "Items Count",
    //         placeholder: "Filter by items count",
    //         type: "Range",
    //         defaultValue: selectedFilers?.items_count,
    //         toggle: true,
    //         clear: true,
    //     },
    //     {
    //         name: "item_sku",
    //         label: "Item SKU",
    //         placeholder: "Filter by item item SKU",
    //         type: "Input",
    //         defaultValue: selectedFilers?.item_sku,
    //         toggle: true,
    //         clear: true,
    //     },
  ];
};

export default filtersView;
