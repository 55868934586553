import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDashboardStatuses,
  fetchOffersStatusesFilters,
  fetchOrdersStatusesFilters,
  fetchSalesStatusesFilters,
  fetchShipmentsStatusesFilters,
  fetchTargetStatusesFilters,
} from "./thunk";

const initialState = {
  statuses: [], // Ensure statuses is initialized as an empty array
  shipment_statuses: [],
  offers_statuses: [],
  sales_statuses: [],
  supply_statuses: [],
  target_statuses: [],
  dashboardStatuses: {},
  loading: "idle",
  error: null,
};

const dashboardSlice = createSlice({
  name: "statuses",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    setStatuses: (state, action) => {
      state.statuses = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersStatusesFilters.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchOrdersStatusesFilters.fulfilled, (state, action) => {
        state.statuses = action.payload.data.statuses;
      })
      .addCase(fetchOrdersStatusesFilters.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchShipmentsStatusesFilters.fulfilled, (state, action) => {
        state.shipment_statuses = action.payload.shipment_statuses;
      })
      .addCase(fetchOffersStatusesFilters.fulfilled, (state, action) => {
        state.offers_statuses = action.payload.dashboardFilters;
      })
      .addCase(fetchSalesStatusesFilters.fulfilled, (state, action) => {
        state.sales_statuses = action.payload.warehouseOos;
      })
      .addCase(fetchTargetStatusesFilters.fulfilled, (state, action) => {
        state.target_statuses = action.payload.dashboardStatuses;
      })
      .addCase(fetchDashboardStatuses.fulfilled, (state, action) => {
        state.dashboardStatuses = action.payload;
      });
  },
});

export const { setStatuses, setLoading, setError } = dashboardSlice.actions;
export {
  fetchOrdersStatusesFilters,
  fetchShipmentsStatusesFilters,
  fetchTargetStatusesFilters,
  fetchOffersStatusesFilters,
  fetchSalesStatusesFilters,
  fetchDashboardStatuses,
};
export default dashboardSlice.reducer;
