import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCategories,
  fetchCategoriesFilters,
  fetchListingSourceCategories,
  fetchListingSources,
  updateCategoryMap,
} from "./thunk";

const initialState = {
  categories: [],
  listingSources: [],
  listingSourceCategories: [],
  links: {},
  filters: {
    name: "",
    type: "",
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  loading: "idle",
  error: null,
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      // console.log(action, 'Update Filter State')
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateCategoriesStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.categories = state.categories.map((account) => {
        if (ids.includes(account.id)) {
          return { ...account, status };
        }
        return account;
      });
    },
    // remove categories when given a list of ids
    removeCategories: (state, action) => {
      const ids = action.payload;
      state.categories = state.categories.filter((account) => {
        return !ids.includes(account.id);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        // console.log(action, 'Fetch Categories')
        if (state.loading === "pending") {
          state.loading = "idle";
          state.categories = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchListingSources.fulfilled, (state, action) => {
        // console.log(action, 'Fetch Listing Source')
        state.listingSources = action.payload["data"];
      })
      .addCase(fetchListingSourceCategories.fulfilled, (state, action) => {
        // console.log(action, 'Fetch Listing Source')
        state.listingSourceCategories = action.payload["data"];
      })
      .addCase(fetchCategoriesFilters.fulfilled, (state, action) => {
        // console.log(action, 'Fetch Categories Filters')
        state.filtersMeta = action.payload["data"];
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  updateCategoriesStatuses,
  removeCategories,
} = categoriesSlice.actions;

export {
  fetchCategories,
  fetchListingSources,
  fetchListingSourceCategories,
  updateCategoryMap,
  fetchCategoriesFilters,
};

export default categoriesSlice.reducer;
