import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchVats = createAsyncThunk(
    "get:api/v1/brands",
    async (payload, thunkAPI) => {
        const response = await api.fetchVat(payload);
        return response;
    }
);

const createVat = createAsyncThunk(
    "post:api/v1/brands",
    async (payload, thunkAPI) => {
        const response = await api.createVat(payload);
        return response;
    }
);

const updateVat = createAsyncThunk(
    "put:api/v1/brands",
    async ({id, payload}) => {
        const response = await api.updateVat(id, payload);
        return response;
    }
);

const deleteVat = createAsyncThunk(
    "delete:api/v1/brands",
    async (payload, thunkAPI) => {
        const response = await api.deleteVat(payload);
        return response;
    }
);

const searchVats = createAsyncThunk(
    "get:api/v1/brands/search",
    async (payload, thunkAPI) => {
        const response = await api.searchVat(payload);
        return response.data;
    }
);

const fetchVatsFilters = createAsyncThunk(
    "get:api/v1/brands/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchVatsFilters(payload);
        return response;
    }
);

const bulkDeleteVats = createAsyncThunk(
    "delete:api/v1/brands/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteVats(payload);
        return response;
    }
);

const bulkStatusUpdateVats = createAsyncThunk(
    "put:api/v1/brands/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkStatusUpdateVats(payload);
        return response;
    }
);

const restoreVats = createAsyncThunk(
    "post:api/v1/brands/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreVats(payload);
        return response;
    }
);


export {
    fetchVats,
    createVat,
    updateVat,
    deleteVat,
    searchVats,
    fetchVatsFilters,
    bulkDeleteVats,
    bulkStatusUpdateVats,
    restoreVats
};
