import axios from "./../../middleware/axios";
import constants from "../../constant";


const fetchAttributes = async (params) => {
    let url = constants.APIURL.GET_ATTRIBUTES;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
};

const searchAttributes = async (params) => {
    let url = constants.APIURL.GET_ATTRIBUTES + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteAttribute = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_ATTRIBUTE + payload, {withCredentials: true});
    return response;
};

const restoreAttributes = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_ATTRIBUTES + '/' + payload + '/restore', {withCredentials: true});
    return response;
};

const createAttribute = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_ATTRIBUTE, payload, {withCredentials: true});
    return response;
};

const updateAttribute = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_ATTRIBUTE + id, payload, {withCredentials: true});
    return response;
};

const updateGroup = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.GET_ATTRIBUTES + '/change-group/' + id, payload, {withCredentials: true});
    return response;
};

// bulk delete , restore , update status
const bulkDeleteAttributes = async (payload) => {

    const response = await axios.delete(constants.APIURL.GET_ATTRIBUTES + '/bulk-delete', {
        withCredentials: true,
        data: payload
    });
    return response;
};

const bulkRestoreAttributes = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_ATTRIBUTES + '/bulk-restore', payload, {withCredentials: true});
    return response;
};

const bulkUpdateAttributesStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.GET_ATTRIBUTES + '/toggle-status', payload, {withCredentials: true});
    return response;

};

const fetchAttributesFilters = async (params) => {

    let url = constants.APIURL.GET_ATTRIBUTE_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );

    return response?.data;
};

const toggleMultiple = async (payload) => {
    const response = await axios.put(constants.APIURL.GET_ATTRIBUTES + '/toggle-multiple/' + payload, {withCredentials: true});

    return response;
};

const toggleMultilingual = async (payload) => {
    const response = await axios.put(constants.APIURL.GET_ATTRIBUTES + '/toggle-multilingual/' + payload, {withCredentials: true});

    return response;
};

export default {
    fetchAttributes,
    deleteAttribute,
    createAttribute,
    updateAttribute,
    searchAttributes,
    bulkDeleteAttributes,
    bulkRestoreAttributes,
    bulkUpdateAttributesStatus,
    fetchAttributesFilters,
    restoreAttributes,
    toggleMultiple,
    toggleMultilingual,
    updateGroup
};
