import React from "react";
import Util from "../../../../../dynamic-page/util";

const summaryEditDescription = (summaryForm, orderItems, callBackAction, re) => {


    return (summaryForm?.type === 1 || summaryForm?.type === 2 || !summaryForm?.type) ? [

        {
            label: 'Warehouse Country',
            value:
                {
                    flag: summaryForm?._data?.warehouse_id?.data?.flag,
                    country: summaryForm?._data?.warehouse_id?.data?.state?.country?.code
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.country ? value?.country : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'Warehouse Recipient',
            value: summaryForm?._data?.warehouse_id?.data?.name,
            theme: (value) => (
                <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                    {value}
                </div>
            ),

        },
    ] : [
        {
            label: 'Provider',
            value: summaryForm?.customer_country ?
                {
                    flag: summaryForm?.customer_country?.flag,
                    code: summaryForm?.customer_country?.code
                } : null,
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <span>{value?.code ? value?.code : "-"}</span>
                </div>
            )
        },
        {
            label: 'Sales Channel',
            value:
                {
                    logo: summaryForm?._data?.account_id?.data?.channel?.logo
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.logo ? <img src={value?.logo} alt=''
                                        className='w-[28px] h-[20px] rounded-[2px] object-contain'/> : null}
                </div>
            )
        },
        {
            label: 'FC country',
            value:
                {
                    flag: summaryForm?._data?.warehouse_id?.data?.state?.country?.flag,
                    country: summaryForm?._data?.warehouse_id?.data?.state?.country?.code
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.country ? value?.country : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'FC code',
            value:
                {
                    code: summaryForm?._data?.warehouse_id?.data?.ucode
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <span>{value?.code ? value?.code : "-"}</span>
                </div>
            )
        },
        {
            label: 'FC note',
            value:
                {
                    note: summaryForm?._data?.warehouse_id?.data?.note
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    <Util.popUp
                        toggle={() => (
                            <div className="text-blue-700 text-base font-normal font-['Inter'] leading-normal">Show
                                Note</div>
                        )}
                        action='click'
                        forcePosition='top'
                    >
                        <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                            {value?.note ? value?.note : "-"}
                        </div>
                    </Util.popUp>
                </div>
            )
        },
    ]


}

export default summaryEditDescription