import Button from "./../dynamic-form/elements/Button";
import DynamicForm from "../dynamic-form";
import React, {useEffect, useRef, useState} from "react";


const PanelModal = ({visible, cancel, onApply, filtersView, skipStatus}) => {

    // Create ref to handle form data.
    const myFilterFormRef = useRef();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 50);

        return () => clearTimeout(timeout);
    }, []);

    const handleClose = () => {
        setIsVisible(false);

        const closeTimeout = setTimeout(() => {
            cancel();
        }, 300);

        return () => clearTimeout(closeTimeout);
    };

    // Handle apply filter.
    const onHandleApply = async () => {
        let formData = await myFilterFormRef?.current?.getData();
        let boundData = await myFilterFormRef?.current?.getBoundData();
        // ------------------------------
        // Add additional data to form data if required.
        // Ex: formData.created_by = userId;
        // ------------------------------

        // if any ky has an empty array, set iit to null
        Object.keys(formData).forEach((key) => {
            if (Array.isArray(formData[key]) && formData[key].length === 0) {
                formData[key] = null;
            }
        });
        // Call onApply function to handle apply filter.
        onApply(formData, boundData)

    }
    const onHandleClear = async () => {
        let formData = await myFilterFormRef.current.getData();
        // formData = Object.keys(formData).reduce((obj, key) => ({...obj, [key]: null}), {});
        // if sckipStatus is true then we will not clear status
        if (skipStatus) {
            formData = Object.keys(formData).reduce((obj, key) => {
                if (key === 'status') {
                    return obj;
                }
                return {...obj, [key]: null}
            }, {});
        } else {
            formData = Object.keys(formData).reduce((obj, key) => ({...obj, [key]: null}), {});
        }

        onApply(formData, {})
    }
    return (
        <div

            tabIndex="-1"

            className={`transition-opacity ${
                isVisible ? "opacity-100" : "opacity-0"
            } overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full flex items-center justify-center bg-gray-700/60`}
            onClick={handleClose}

        >
            <div
                className={`right-0 absolute bottom-0 rounded-tl-[16px] bg-white w-[400px] min-h-[700px] max-h-[100vh]`}
                onClick={
                    (e) => e.stopPropagation()
                }
            >
                <div className="flex flex-col flex-grow items-stretch w-full h-[770px]  font-inter "
                     style={{maxHeight: "calc(100vh - 83px)"}}>
                    <div
                        className=" mx-[16px] pt-[32px] pb-[12px]  font-bold flex justify-between border-b-2 border-blue-500">
                        <h5>Filters</h5>
                        <label className=" font-semibold text-blue-500 cursor-pointer" onClick={() => {
                            onHandleClear()
                        }}>Reset Filters</label>
                    </div>
                    <div className={"overflow-y-auto pt-[16px] pb-[32px] px-[16px] h-full "}>
                        <div className="py-[16px] flex flex-col gap-[16px]">
                            <DynamicForm items={filtersView} ref={myFilterFormRef}/>
                        </div>
                    </div>

                </div>
                <div
                    className="w-full pt-[16px] pb-[24px] px-[16px] flex gap-[8px] relative z-0 justify-end border-t-gray-100 border-t">
                    <Button
                        item={{
                            label: "Cancel",
                            buttonType: "alt",
                        }}
                        onClick={handleClose}
                    />
                    <Button item={{
                        label: "Apply Filters",
                        buttonType: "primary",
                    }}
                            onClick={onHandleApply}
                    />


                </div>

            </div>
        </div>
        //   )}
        // </Transition>
    )
}


export default PanelModal;
