import axios from "./../../middleware/axios";
import constants from "../../constant";


const fetchUnits = async (params) => {
    let url = constants.APIURL.GET_UNITS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
};

const searchUnits = async (params) => {
    let url = constants.APIURL.GET_UNITS + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteUnit = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_UNIT + payload, {withCredentials: true});
    return response;
};

const createUnit = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_UNIT, payload, {withCredentials: true});
    return response;
};

const updateUnit = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_UNIT + id, payload, {withCredentials: true});
    return response;
};

// bulk delete , restore , update status
const bulkDeleteUnits = async (payload) => {

    const response = await axios.delete(constants.APIURL.BULK_DELETE_UNITS, {withCredentials: true, data: payload});
    return response;
};

const bulkRestoreUnits = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_UNITS, payload, {withCredentials: true});
    return response;
};

const bulkUpdateUnitsStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_BULK_UNITS_STATUS, payload, {withCredentials: true});
    return response;

};

const fetchUnitsFilters = async (params) => {

    let url = constants.APIURL.GET_UNITS_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );

    return response?.data;
};


export default {
    fetchUnits,
    deleteUnit,
    createUnit,
    updateUnit,
    searchUnits,
    bulkDeleteUnits,
    bulkRestoreUnits,
    bulkUpdateUnitsStatus,
    fetchUnitsFilters
};
