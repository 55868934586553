const popUPList = ({list}) => {

    return (
        <div className={"flex flex-col"}>
            {list && list.map((item, index) => (
                <div className={"h-[21px] justify-between items-center flex flex-row gap-[70px]"}>
                    <div className="text-gray-900 text-sm font-normal leading-[21px]">{item[0]}:</div>
                    <div className="text-gray-900 text-sm font-semibold leading-[21px]">{item[1]}</div>
                </div>
            ))}
        </div>
    )
}
export default popUPList;