import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchGoodsAcceptance = async (params) => {
    let url = constants.APIURL.GET_GOODS_ACCEPTANCE;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
}

const fetchGoodsAcceptanceFilters = async (params) => {
    let url = constants.APIURL.GET_GOODS_ACCEPTANCE_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
}


const createGoodsAcceptance = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_GOODS_ACCEPTANCE, payload, {withCredentials: true});
    return response;
}

const updateGoodsAcceptance = async (payload) => {
    const {id, ...rest} = payload;
    const response = await axios.put(constants.APIURL.PUT_UPDATE_GOODS_ACCEPTANCE_FROM_PRODUCT + 'purchase/' + id, rest, {withCredentials: true});
    return response;
}

const createGoodsAcceptanceFromProduct = async ({payload, id}) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_GOODS_ACCEPTANCE_FROM_PRODUCT + id, payload, {withCredentials: true});
    return response;
}

const updateGoodsAcceptanceFromProduct = async ({payload, id}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_GOODS_ACCEPTANCE_FROM_PRODUCT + id, payload, {withCredentials: true});
    return response;
}

const deleteGoodsAcceptance = async (id) => {
    const response = await axios.delete(constants.APIURL.DELETE_GOODS_ACCEPTANCE + id, {withCredentials: true});
    return response;
}

const restoreGoodsAcceptance = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_GOODS_ACCEPTANCE, payload, {withCredentials: true});
    return response;
}


export default {
    fetchGoodsAcceptance,
    createGoodsAcceptance,
    createGoodsAcceptanceFromProduct,
    updateGoodsAcceptanceFromProduct,
    updateGoodsAcceptance,
    deleteGoodsAcceptance,
    restoreGoodsAcceptance,
    fetchGoodsAcceptanceFilters

}


