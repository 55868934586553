// SigninPage.js
// ----------------------------------------------------------------

// Import General Components
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

// Import Redux Components
import {useSelector, useDispatch} from "react-redux";
import {fetchLoginUser} from "../store/auth";

// Handle locale
import {useTranslation} from "react-i18next";

// Import Elements
import {validator} from "../utils";
import constant from "../constant";
import ArbitPasswordInput from "./sub-elements/password-input";
import ArbitInput from "./sub-elements/input";
import ArbitSubmitButton from "./sub-elements/submit-button";


// Main Component: SigninPage
const SigninPage = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    //const isLogin = useSelector((state) => (state.auth.isLogin))
    const isLogging = useSelector((state) => (state.auth.isLogging))

    // Handle Login
    const onHandleLogin = async () => {
        // Validate email and password
        const emailValidation = (validator.onEmailValidator(email));
        const passwordValidation = (validator.onEmptyValidator(password));

        // Set email and password errors
        setEmailError(emailValidation);
        setPasswordError(passwordValidation);

        // Return early if there are any validation errors
        if (emailValidation !== "" || passwordValidation !== "") {
            return;
        }

        // Call API to login
        try {
            // Dispatch login action
            const response = await dispatch(
                fetchLoginUser({
                    user: {email, password},
                })
            );

            // Navigate to opportunities page if login is successful
            if (response.payload?.data?.message === "success") {
                // navigate("/logistics/orders");
                navigate("/");
                return;
            }

            // Set error message if login is not successful
            setPasswordError(
                (constant.MESSAGE.LOGIN_FAILED, constant.MESSAGE.LOGIN_FAILED)
            );

            // Handle error
        } catch (error) {
            setPasswordError(
                (constant.MESSAGE.LOGIN_FAILED, constant.MESSAGE.LOGIN_FAILED)
            );
        }
    }

    // Handle Enter Key
    const onHandleKey = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            onHandleLogin()
        }
    }

    // Handle Register
    // -> This code is commented out because it is currently not being used.
    /*
        const onHandleRegister = () => {
            navigate("/register")
        }
    */

    // Render the component
    return (
        <div>
            <div className="lg:flex w-full min-h-screen h-full">

                {/* Background photo for the left side is here */}
                <div className="lg:block flex justify-center lg:w-2/6 lg:pr-8">
                    <img
                        className="mt-5 lg:mt-0 w-1/2 lg:w-full rounded-2xl lg:rounded-none h-full lg:object-cover"
                        src="./assets/images/loginimg.png"
                    />
                </div>

                {/* Right side container is here */}
                <div className="relative lg:px-24 w-full lg:w-4/6">
                    <div className="absolute right-0 inset-0 flex justify-end w-full h-full bg-gray-100 opacity-10">
                        <div className="absolute inset-0 w-full h-full bg-repeat opacity-40"></div>
                        <div className="absolute w-full h-full bg-gradient-to-r from-white to-transparent"></div>
                    </div>
                    <div className="relative flex flex-wrap items-end">
                        <div className="relative w-full px-8 mb-12 lg:mb-0">
                            <div className="relative py-20 text-center md:py-32 xl:py-40">

                                {/* Brand Logo is here */}
                                <a className="flex justify-center items-center w-full mb-6 text-3xl font-bold lg:justify-start font-heading gap-2"
                                   href="/login">
                                    <img src="./assets/newLogo.png" alt="Arbitbox" className={"h-[27px]"}/>
                                </a>

                                {/* Page Title is here */}
                                <div className="flex flex-col mb-6 lg:items-start">
                                    <span className="text-3xl text-black font-bold">
                                        {t("Sign in")}
                                    </span>
                                </div>

                                {/* Login Form is here */}
                                <form onSubmit={(e) => e.preventDefault()}>

                                    <div className="my-5 mb-4">
                                        <ArbitInput
                                            label={t("Your Email")}
                                            value={email}
                                            placeholder="name@example.com"
                                            error={emailError}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                                setEmailError(validator.onEmailValidator(e.target.value))
                                            }}
                                        />
                                    </div>

                                    <div className="my-5">
                                        <ArbitPasswordInput
                                            label={t("Password")}
                                            //value={'password'}
                                            error={passwordError}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className="flex w-full justify-between">
                                        {/*<div><label className="flex items-center mt-1" htmlFor="terms">*/}
                                        {/*    <input className="mr-2 border border-gray-300 rounded-md" type="checkbox"/>*/}
                                        {/*    <span className="text-sm text-gray-500">*/}
                                        {/*        {t("Remember me")}*/}
                                        {/*    </span>*/}
                                        {/*</label></div>*/}
                                        {/*
                                        <div>
                                            <a href="#" className="text-sm text-blue-600 font-medium">Lost Password?</a>
                                        </div>
                                        */}
                                    </div>

                                    <div className="mt-8 md:mt-5">
                                        <ArbitSubmitButton
                                            label={t("Login", "Login")}
                                            disabled={isLogging === "pending"}
                                            onClick={() => onHandleLogin()}
                                        />
                                    </div>
                                    {/*
                                    <div className="mt-4 w-full flex justify-start items-center">
                                        <span className="text-sm font-medium text-gray-900">
                                            {t("Not Registered?")}
                                        </span>
                                        <span className="ml-1 cursor-pointer text-sm font-medium text-blue-600"
                                            onClick={() => navigate("/register")}
                                        >
                                            {t("Create account")}
                                        </span>
                                    </div>
                                    */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SigninPage;
