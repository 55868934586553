import {HiChevronLeft, HiChevronRight} from "react-icons/hi";
import React, {useEffect} from "react";
import UI from "../../dynamic-form/elements";

const Modalfooter = ({meta, onPage, onLimit, loading = false, footerFunction}) => {
    const [innerMera, setInnerMeta] = React.useState(meta);
    const [wait, setWait] = React.useState(true);
    useEffect(() => {
        setInnerMeta(meta);
        setWait(false);
    }, [meta]);

    let Pagination = {
        from: innerMera?.from || 0,
        to: innerMera?.to || 0,
        total: innerMera?.total || 0,
        current_page: innerMera?.current_page || 1,
        last_page: innerMera?.last_page || 1,
        totalPages: Array.from(
            {length: Math.min(Math.ceil((innerMera?.total || 0) / 9), 5)},
            (_, index) => {
                const startPage = Math.max(innerMera?.current_page - 2, 1);
                const pageNumber = startPage + index;
                return pageNumber <= innerMera?.last_page ? pageNumber : null;
            }
        ).filter((pageNumber) => pageNumber !== null),
    };

    const handlePageChange = (pageNumber) => {
        setWait(true);
        setInnerMeta({...innerMera, current_page: pageNumber});
    };

    if (Pagination.totalPages[0] !== 1) {
        if (Pagination.last_page > 2 && Pagination.current_page - 3 !== 1) {
            Pagination.totalPages.unshift("...");
        }

        Pagination.totalPages.unshift(1);
    }
    if (
        Pagination.totalPages[Pagination.totalPages.length - 1] !==
        Pagination.last_page
    ) {
        if (
            Pagination.last_page > 2 &&
            Pagination.current_page + 3 !== Pagination.last_page
        ) {
            Pagination.totalPages.push("...");
        }
        Pagination.totalPages.push(Pagination.last_page);
    }
    const rowPerpageRef = React.useRef();
    return (
        <div
            className={
                "flex items-center justify-between  h-[50px]  w-full border-t border-t-gray-200 bg-white"
            }
        >
            <div className="flex items-center text-blue-600">{footerFunction()}</div>
            <div className="flex ">
                <div
                    className={`!gap-[-1px] flex-row flex ${
                        wait || loading === "pending" ? "opacity-50" : ""
                    }`}
                >
                    <UI.Button
                        item={{
                            label: <HiChevronLeft className="h-6 w-6 text-gray-500"/>,
                            buttonType: "icon",
                            className: `!border-[#D1D5DB] !text-gray-500  ${
                                Pagination.current_page > 1 && !wait
                                    ? " hover:!border-gray-300 hover:!text-gray-500  hover:z-20"
                                    : "opacity-50"
                            } w-[44px] !h-[33px] !rounded-l-[4px] !rounded-r-[0px] `,
                            disabled: wait || Pagination.current_page <= 1,
                        }}
                        onClick={() => {
                            if (!wait) {
                                onPage(Pagination.current_page - 1);
                                handlePageChange(Pagination.current_page - 1);
                            }
                        }}
                    />
                    {Pagination?.totalPages.map((val, i) => (
                        <UI.Button
                            key={i}
                            item={{
                                label: val,
                                buttonType: "icon",
                                className: `${
                                    Pagination?.current_page === val
                                        ? "bg-blue-100 !text-[#1C64F2]"
                                        : "!text-gray-500"
                                } !border-[#D1D5DB]  ${
                                    val !== "..." &&
                                    Pagination?.current_page !== val &&
                                    onPage &&
                                    !wait
                                        ? "hover:!border-gray-400 hover:!text-gray-500 hover:z-20"
                                        : ""
                                } min-w-[33px] !h-[33px] !rounded-[0px] ml-[-1px] `,
                                disabled:
                                    wait ||
                                    val === "..." ||
                                    Pagination?.current_page === val ||
                                    !onPage,
                            }}
                            onClick={() => {
                                if (onPage && !wait) {
                                    onPage(val);
                                    handlePageChange(val);
                                }
                            }}
                        />
                    ))}

                    <UI.Button
                        item={{
                            label: <HiChevronRight className="h-6 w-6 text-gray-500"/>,
                            buttonType: "icon",
                            className: `!border-[#D1D5DB] !text-gray-400 ${
                                Pagination.last_page > Pagination.current_page && !wait
                                    ? " hover:!border-gray-400 hover:!text-gray-500 hover:z-20"
                                    : "opacity-50"
                            }  w-[44px] !h-[33px] !rounded-r-[4px] !rounded-l-[0px] ml-[-1px] `,
                            disabled: wait || Pagination.current_page >= Pagination.last_page,
                        }}
                        onClick={() => {
                            if (!wait) {
                                onPage(Pagination.current_page + 1);
                                handlePageChange(Pagination.current_page + 1);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default Modalfooter;
