import React, {useEffect, useRef} from 'react';

const Image = ({item, value, validationMessage, handleChange, formData}) => {

    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;
    const inputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [selectedFileNames, setSelectedFileNames] = React.useState([]);

    // handle Drop file
    const handleImageChange = async (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const fileArray = Array.from(files);
            setSelectedFiles(fileArray);
            const fileNamesArray = fileArray.map(file => file.name);
            setSelectedFileNames(fileNamesArray);
            handleChange(fileArray, item?.name);
        } else {
            setSelectedFiles([]);
            setSelectedFileNames([]);
            handleChange([], item?.name);
        }
    };

    const handleClick = () => {
        inputRef?.current?.click();
    };

    useEffect(() => {
        setSelectedFiles(value);
        if (value && value.length > 0 && value[0]?.name) {
            const fileNamesArray = value.map(file => file.name);
            setSelectedFileNames(fileNamesArray);
        }
        if (!value || value.length === 0) {
            if (inputRef?.current) {
                inputRef.current.value = null;
            }
        }
    }, [value]);

    // Return Input in view
    return (
        <div className={"flex flex-row gap-[8px] relative block"}>

            <input onChange={handleImageChange} ref={inputRef} type={"file"} className={`hidden`} multiple/>

            <div onClick={() => {
                inputRef?.current?.click();
            }}
                 className={`transition cursor-pointer overflow-hidden flex w-full justify-start flex-row gap-[8px] items-center text-gray-500 disabled:text-gray-400 rounded-md  h-[42px] bg-gray-50 border-gray-300 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block ${item?.innerClassName || ""} `}>
                <div
                    className={"bg-[#1c64f2] text-[14px] text-white h-full w-[115px] justify-center items-center flex border-r border-r-gray-200"}>Choose
                    file
                </div>
                <div className={"flex-grow text-[14px] flex flex-row gap-[8px] items-center text-gray-900 py-[2px]"}>
                    {selectedFileNames && selectedFileNames.map((fileName, index) => (
                        <div key={index}>{fileName}</div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Image;
