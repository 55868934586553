import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchSupplyChannels = createAsyncThunk(
    'get:api/v1/channel',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
          dispatch(fetchLoading());
        }
        const data = await api.fetchSupplyChannels(
          typeof payload === "string" ? payload : payload.query
        );
        return { ...data?.data, append: payload?.append };
      }
)

const fetchSupplyChannelFilters = createAsyncThunk(
    'get:api/v1/channel/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchSupplyListingFilters(params)
        // console.log(response)
        return response.data
    }
)

export {
    fetchSupplyChannels,
    fetchSupplyChannelFilters
}
