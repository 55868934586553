import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchUsersFilters} from "../../../store/users";
import upperCase from "../../dynamic-page/util/upperCaseString";

const FiltersView = (selectedFilters, filtersMeta) => {
    return (
        [
            {
                name: "status",
                label: "Status",
                placeholder: "Select Status",
                type: "Select",
                list: filtersMeta?.statuses?.map((item) => ({
                    label: upperCase(item?.name),
                    value: item?.id,
                    count: item?.count,
                })),
                features: {
                    formatOptionLabel: ({label, count}) => {
                        return (
                            <div
                                className={
                                    "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                                }
                            >
                                <span>{upperCase(label, 'first')}</span>
                                <span
                                    className={
                                        "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                                    }
                                >
                          {count}
                        </span>
                            </div>
                        );
                    },
                },
                className: '!bg-white',
                style: {
                    control: {
                        backgroundColor: "white !important",
                    }
                },
                defaultValue: selectedFilters?.status || null,
                toggle: true,
                clear: true,
            },
            {
                name: "email",
                label: "Email",
                placeholder: "Filter by email",
                type: "Input",
                defaultValue: selectedFilters?.email,
                toggle: true,
                clear: true,
            },
            {
                name: "name",
                label: "Name",
                placeholder: "Filter by name",
                type: "Input",
                defaultValue: selectedFilters?.name,
                toggle: true,
                clear: true,
            },
            {
                name: "created_at",
                label: "Created At",
                placeholder: "Filter by Date",
                type: "DateRange",
                defaultValue: selectedFilters?.created_at,
                toggle: true,
                clear: true,
            },

        ]
    )
}

export default FiltersView;