import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import DynamicForm from "../../dynamic-form";
import {updateRow} from "../../../store/offers";
import {useDispatch} from "react-redux";
import {connectProduct} from "../../../store/offers/thunk";
import {fetchOffer} from "../../../store/offers";
import SingleGroupProductBadge from "../../dynamic-page/components/SingleGroupProductBadge";


const Item = ({product, orderId, offer}) => {
    const productList = product[0];
    const multiple = product.length > 1;
    const dispatch = useDispatch();
    const connectProductFrom = () => ([
        {
            name: "product",
            label: "Quick search on products",
            type: "Select2",
            api: {
                url: '/api/v1/product',
                query: "?term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: upperCaseString(item?.title, "word", true),
                    product: item
                })
            },
            disablePreload: true,
            reset: false,
            clear: false,
            className: "w-full",
            features: {
                formatOptionLabel: ({label, product}) => {
                    return <div className={"flex flex-row gap-[8px] items-center min-h-[25px]"}>
                        <span
                            className={"min-w-[20px] h-[20px] overflow-hidden flex flex-row justify-center items-center "}>
                            <SingleGroupProductBadge product={product}/>
                            {product?.image ?
                                <img className={"w-[20px] h-[20px] rounded-full "} src={product?.image} alt=""/>
                                :
                                <img className={"w-[20px] h-[20px] rounded-full"} src={"/assets/images/noImage.png"}
                                     alt=""/>
                            }
                        </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                }
            },
            style: {
                control: {
                    height: "34px !important",
                    minHeight: "auto",
                    background: "rgba(0,0,0,0.03)"
                },
                menu: {
                    whiteSpace: 'wrap !important',
                },
                option: {
                    padding: "4px 8px !important",
                },
            },
            placeholder: (<div className={"flex flex-row gap-[8px] items-center text-[14px]"}><Icons.shipmentDetail
                className={"w-[16px] h-[16px]"}/>Quick search on products</div>),
        }
    ]);

    return (
        <div className={"w-[300px] flex flex-col justify-between items-start py-3 gap-y-3"}>
            <div className={"flex flex-row gap-[8px] justify-between"}>
                <util.expandableText maxLines={1} className={"text-[12px]"} tooltip={true}>
                    {offer?.title}
                </util.expandableText>
            </div>
            <div className={"flex flex-row justify-between items-center w-full"}>
                <div className={"flex flex-row gap-[4px] text-gray-600"}>{util.shortenString(offer?.identifier, 30)}
                    <util.copyText className={"text-[12px]"} text={offer?.identifier} hint={false}/>
                </div>
                {productList ?
                    <div className="flex items-center gap-[2px] !text-[12px]">
                        <span
                            className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${productList?.type === 1 ? "text-teal-700 bg-teal-100" : "text-indigo-700 bg-indigo-100"} p-[0px]`}>{productList?.type === 1 ? "S" : "G"}</span>
                        <div
                            className={"text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px]"}
                            onClick={() => {
                                window.open(`/product/${productList?.id}`, "_blank")
                            }}
                        >
                            <img src={productList?.brand?.assigned?.profile_image}
                                 className={"w-[20px] h-[20px] rounded-full object-cover"} alt=""/>
                            {util.upperCaseString(productList?.brand?.assigned?.name, "word")}
                        </div>
                    </div>
                    :
                    <div className="flex items-center gap-[2px] !text-[12px]">
                        <util.popUp
                            toggle={(selected) => (
                                <div className={"flex flex-row justify-between items-center gap-x-1.5"}>
                                    <span
                                        className="w-[20px] h-[20px] rounded-full bg-[#E5EDFF] flex justify-center items-center text-xs font-medium text-[#5145CD] p-[0px] uppercase">g</span>
                                    <span className="text-[12px] text-[#111928] font-medium">Sevim Torğut</span>
                                    <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                         className="w-[18px]" alt="Sevim Torğut"/>
                                </div>
                            )}
                            className={"!overflow-visible"}
                            innerClassName={"!overflow-visible"}
                            action={"click"}
                        >
                            <div className={"w-[330px] px-[8px] py-[4px] text-[14px] gap-[9px] flex flex-col"}>
                                <DynamicForm items={connectProductFrom()} onChange={(d, bind) => {
                                    if (bind?.product) {
                                        const product_id = d?.product
                                        dispatch(connectProduct({
                                            offerId: offer?.id,
                                            productId: product_id,
                                        })).then((result) => {
                                            //handleFetchAndUpdateItem(data._data.order_id, data.id, result?.payload?.data)
                                            if (result?.payload?.type === "success") {
                                                dispatch(fetchOffer(offer?.id)).then((rs) => {
                                                    dispatch(updateRow({
                                                        path: `offers.[id:${offer?.id}]`,
                                                        value: rs.payload.data
                                                    }))
                                                    util.notifier({
                                                        type: "success",
                                                        message: result?.payload?.data,
                                                        errors: result?.payload?.errors
                                                    })
                                                })
                                            }
                                        })
                                    }
                                }}/>
                                <div
                                    className="text-blue-700 text-[14px] font-normal leading-none w-full hover:text-blue-800 cursor-pointer"
                                    onClick={() => {
                                        window.open(`/product/list#action=add&title=${offer?.title}&image=${offer?.image}`, '_blank')

                                    }}
                                >
                                    Didn’t find? Create new.
                                </div>
                            </div>
                        </util.popUp>

                    </div>}
            </div>

        </div>

    )

}

export default Item;