import React from 'react';


const Input = ({item, form}) => {
    const Component = item.module;
    return (
        <div className={item?.className ? item?.className : ""}>
            <Component change={form.change}
                       data={form.data}
                       submit={form.submit}
                       validation={form.validation}
                       binded={form.binded}
                       item={item}
                       bind={form.bind}
            />
        </div>)
}

// Return Input in view


export default Input;