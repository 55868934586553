// navItems.js
// ----------------------------------------------------------------

// Import Icons
import {
  BsCurrencyExchange,
  BsFillBagCheckFill,
  BsFillCartCheckFill,
  BsFillCartPlusFill,
  BsFillCloudCheckFill,
} from "react-icons/bs";
import { FaTruckFast } from "react-icons/fa6";
import { MdAttachMoney } from "react-icons/md";
import {
  FaExchangeAlt,
  FaMoneyCheckAlt,
  FaProductHunt,
  FaStore,
  FaThList,
  FaWeightHanging,
  FaWindowRestore,
  FaShoppingCart,
  FaRegBell,
} from "react-icons/fa";
import {
  AiFillAccountBook,
  AiFillBank,
  AiFillCopyrightCircle,
  AiFillSetting,
  AiFillShopping,
} from "react-icons/ai";
import {
  MdAssignmentReturn,
  MdCategory,
  MdCompare,
  MdInventory,
  MdLibraryAdd,
  MdLocalOffer,
  MdLocalShipping,
  MdPriceChange,
  MdRequestPage,
  MdSell,
  MdViewList,
} from "react-icons/md";
import {
  HiCurrencyDollar,
  HiReceiptTax,
  HiTemplate,
  HiUsers,
} from "react-icons/hi";

export const pages = [
    {
        name: "Carriers",

        text: "Manage carriers to streamline your logistics and shipping operations, ensuring the selection of the most efficient and cost-effective options.",
        path: "carriers",
        category: "settings"
    },
    {
        name: "Supply Channels",

        text: "Display all your supply channels and their contact details for efficient tracking and management of your sourcing network.",
        path: 'supply_channels',
        category: "settings"
    },
    {
        name: "Users",

        text: "Manage user access, define roles, oversee user activity, and monitor login history to maintain security and efficiency.",
        path: "users",
        category: "settings"
    },
    // {
    //     name: "Categories",
    //     icon: <MdCategory className="w-5 h-5"/>,
    //     text: "",
    //     path: "categories",
    //     category: "settings"
    // },
    {
        name: "Sales Channel Accounts",

        text: "Manage accounts on sales channels to ensure seamless integration and management.",
        path: "sales_channel_accounts",
        category: "settings"
    },
    {
        name: "Attributes",
        category: "settings",
        path:"attributes",
        text: "Define and manage the classes and types of product attributes, allowing for detailed specifications and better catalog organization.",

    },
    {
        name: "Warehouses ",
        category:"settings",
        path:"warehouses",
        text:"Manage storage facilities to efficiently track inventory and streamline order fulfillment processes, ensuring optimal stock levels and smooth operations.",

    },
    {
        name: "Pick-up Points",
        text: "Manage transfer centers, where orders are sent to local hubs to minimize shipping costs, ensuring cost-effective and efficient distribution.",
        category: "settings",
        path: "pickup_points",
    }
];
