import React from "react";

function Calculations({item}) {
    return (
        <div className="flex flex-col justify-between gap-2 ">
            <div>
                <p className="text-[12px] font-[500] leading-[12px] text-gray-700">
                    {item?.upper_title}
                </p>
                <div className="flex items-center gap-[2px]">
                    <p className={`text-[24px] font-[700] leading-[24px] text-${item?.upper_color || 'orange'}-700`}>
                        {item?.upper_value}
                    </p>
                    {item?.upper_tooltip && item?.upper_tooltip}
                </div>
            </div>
            <div>
                <p className="text-[12px] font-[500] leading-[12px] text-gray-700">
                    {item?.lower_title}
                </p>
                <div className="flex items-center gap-[2px]">
                    <p className={`text-[24px] font-[700] leading-[24px] text-${item?.lower_color || 'blue'}-700`}>
                        {item?.lower_value}
                    </p>
                    {item?.lower_tooltip && item?.lower_tooltip}
                </div>
            </div>
        </div>
    );
}

export default Calculations;
