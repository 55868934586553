import axios from "../../middleware/axios";
import constants from "../../constant";

export const fetchNotifications = async (params) => {
    let url = constants.APIURL.GET_NOTIFICATIONS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

const fetchNotificationsFilters = async (params) => {
    let url = constants.APIURL.GET_NOTIFICATIONS_FILTER;
    if (params) url = url.concat(params).concat("&filter[status]=");
    return await axios.get(url, {withCredentials: true});
};

export default {fetchNotifications, fetchNotificationsFilters};
