import axios from "axios";
import {useEffect, useState} from "react";
import constant from "../../constant";
import AsyncSelect from "react-select/async";

const WarehouseSelect = ({
                             wareHouse,
                             errorMessage,
                             setErrorMessage,
                             setWareHouse,
                         }) => {
    const [wareHouseoptions, setWareHouseoptions] = useState([]);

    const handleChange = (selectedOption) => {
        setWareHouse(selectedOption);
        if (!wareHouse) setErrorMessage(null);
    };

    useEffect(() => {
        const loadDefaultOptions = async () => {
            const response = await axios.get('/api/v1/warehouses');

            const data = response?.data?.data;

            const formattedOptions = data.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));

            setWareHouseoptions(formattedOptions);
        };

        loadDefaultOptions();
    }, []);

    return (
        <div className="ml-2 rounded-lg justify-start items-start gap-1.5 flex flex-col">
            <AsyncSelect
                defaultOptions={wareHouseoptions}
                onChange={handleChange}
                placeholder="Select Warehouse"
                value={wareHouse || null}
            />
            {errorMessage && <div className="ml-2 text-red-500">{errorMessage}</div>}
        </div>
    );
};

export default WarehouseSelect;