import axios from "axios";
import moment from "moment";
import React from "react";
import upperCaseString from "../../../dynamic-page/util/upperCaseString";

const StatusHistory = ({ id, product, primary_label }) => {
  const [orderItems, setOrderItems] = React.useState([]);
  const [labelHistory, setLabelHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const fetchItems = async () => {
      const response = await axios.get(`/api/v1/orders/${id}/stateHistory`);
      setOrderItems(response?.data?.data);
      if (primary_label) {
        const response = await axios.get(
          `/api/v1/orders/shipment-labels?filter[tracking_number]=${primary_label}`
        );
        setLabelHistory(response?.data?.data);
      }

      setLoading(false);
    };
    fetchItems();
  }, [id, product]);

  const unitsQty = (item) => {
    if (item === 1) {
      return item + " unit";
    } else {
      return item + " units";
    }
  };

  const statusLabel = (item) => {
    if (item?.statuses?.length > 0) {
      return item?.statuses?.map((status) => {
        return {
          action: status?.detail,
          date: status?.timestamp,
          user: status?.created_by || "System",
          location: status?.location || "Warehouse",
        };
      });
    } else {
      return [];
    }
  };

  // console.log(items);

  // reverse items
  const reverseItems = [...orderItems].reverse();

  const lineAnimationStyles = {
    width: "50%",
    height: "100%",
    background:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,97,9,0.45475465576855745) 43%, rgba(0,212,255,1) 100%)",
    position: "absolute",
    left: "0",
    animation: "line-animation 0.5s linear infinite",
    borderRadius: "5px",
  };

  return (
    <div className={" flex min-w-[400px] flex-col gap-[4px]   px-[4px] "}>
      {loading && (
        <div
          style={{
            top: "15px",
            left: "10px",
            width: "calc(100% - 20px)",
            height: "5px",
            backgroundColor: "white",
            position: "absolute",
            overflow: "hidden",
            borderRadius: "5px",
            background: "#jjj",
          }}
        >
          <div style={lineAnimationStyles}></div>
        </div>
      )}
      <style>
        {" "}
        {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`}{" "}
      </style>
      <p
        className={
          "text-gray-900 text-start !text-[14px] pl-[4px] mt-[6px] font-semibold"
        }
      >
        Order Status History
      </p>
      {!loading && (
        <>
          {reverseItems.map((item, index) => (
            <div
              className="text-gray-900 text-xs font-normal leading-[18px] gap-[4px] flex flex-row items-center transition cursor-pointer hover:blue-700"
              key={index}
            >
              {<span className="font-bold text-[12px] text-[#111928] ">{upperCaseString(item.status_name, "word")}</span>}
              <></>
              {item?.created_by && (
                <span className="text-[#6B7280] text-[12px] font-normal leading-[18px]">
                  by
                </span>
              )}
              {item?.created_by && (
                <spam className="font-bold text-[12px] text-[#111928] ">{upperCaseString(item.created_by, "word")}</spam>
              )}
              {/* <span className="text-[#6B7280] text-xs font-normal leading-[18px]">
            at
          </span>
          <spam>{upperCaseString(item?.location, "word")}</spam> */}
              <span className="text-[#6B7280] text-[12px] font-normal leading-[18px]">
                at
              </span>
              <spam className="font-bold text-[12px] text-[#111928] ">{moment(item?.timestamp).format("MMM D, YYYY HH:mm")}</spam>
              {/* <Icons.goToTrackURL className={"text-gray-500 w-[10px] h-[10px] mr-[4px] ml-[4px]"} /> */}
            </div>
          ))}

          {labelHistory && statusLabel(labelHistory[0])?.length > 0 && (
            <p
              className={
                "text-gray-900 text-start !text-[14px]  mt-[6px] font-semibold"
              }
            >
              Shipment Status History
            </p>
          )}

          {labelHistory &&
            labelHistory?.length > 0 &&
            statusLabel(labelHistory[0])?.map((item, index) => (
              <div
                className="text-gray-900 text-xs font-normal leading-[18px] gap-[4px] flex flex-row items-center transition cursor-pointer hover:blue-700"
                key={index}
              >
                <spam className="bg-red-500">{upperCaseString(item.action, "word")}</spam>
                <></>
                {item?.user !== "Arbitbox" && (
                  <span className="text-gray-500 text-xs font-normal leading-[18px]">
                    by
                  </span>
                )}
                {item?.user !== "Arbitbox" && (
                  <spam>{upperCaseString(item.user, "word")}</spam>
                )}
                <span className="text-gray-500 text-xs font-normal leading-[18px]">
                  at
                </span>
                <spam>{upperCaseString(item?.location, "word")}</spam>
                <span className="text-gray-500 text-xs font-normal leading-[18px]">
                  at
                </span>
                <spam>{moment(item?.date).format("MMM D, YYYY HH:mm")}</spam>
                {/* <Icons.goToTrackURL className={"text-gray-500 w-[10px] h-[10px] mr-[4px] ml-[4px]"} /> */}
              </div>
            ))}
        </>
      )}
    </div>
  );
};
export default StatusHistory;
