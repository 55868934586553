/**
 * @author Austin Ames
 */
import classNames from "classnames";

const Item = ({ title, active, onClick, total, margin, boarderless, icon }) => {
  return (
    <li role="presentation">
      <button
        className={classNames(
          active
            ? "text-blue-600 border-blue-600 "
            : "text-gray-400 border-transparent hover:text-gray-600 hover:border-gray-400 dark:hover:text-gray-400 ",
          `inline-block  rounded-t-lg `,
          boarderless ? "border-0" : "border-b-4",
          ``,
          "px-1 text-[14px] font-semibold leading-[21px]",
          icon && "flex items-center"
        )}
        type="button"
        role="tab"
        onClick={() => onClick(title)}
      >
        <div className={classNames(active ? "px-1" : "px-2", "gap-2")}>
          <span>
            {icon && icon} {title}
          </span>
          <span>
            {" "}
            {total && total > 0
              ? ` (${total})`
              : total === 0
              ? "(" + total + ")"
              : ""}
          </span>
        </div>
      </button>
    </li>
  );
};

Item.defaultProps = {
  title: "Item",
  active: false,
  onClick: () => {},
};

export default Item;
