import * as Yup from "yup";
import upperCaseString from "../../dynamic-page/util/upperCaseString";

const formView = (item) => {

    const innerSubmit =
        [
            {
                label: "Save",
                type: "primary",
                action: "save",
            },

        ]

    return ({
        title: upperCaseString(item?.id ? "Edit" : "Add") + " Unit",
        submit: innerSubmit,
        // close: !item?.id,
        // cancel: !item?.id ? false : true,
        viewModel: (formDefaultValue) => {
            return [
                {
                    name: "name",
                    label: "Name",
                    placeholder: "Kilo",
                    type: "Input",
                    defaultValue: formDefaultValue?.name || null,
                    validation: Yup.string().required('Name is required'),
                    className: "col-span-1",
                },
                {
                    name: "code",
                    label: "Code",
                    placeholder: "Kg",
                    type: "Input",
                    className: "col-span-1",
                    defaultValue: formDefaultValue?.code || null,
                    disabled: formDefaultValue?.ucode ? true : false,
                    validation: Yup.string().required('Code is required'),
                },

            ]
        }
    });
};
export default formView;
  
