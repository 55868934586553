import React from "react";
import moment from "moment";
import {howLongFromNow} from "../../../utils/helper";
import {useNavigate} from "react-router-dom";
// import Components from "../components";
import priceHandler from "../../../utils/priceHandler";
import Numbers from "../components/numbers";
import util from "../../dynamic-page/util";
// import InternalNote from "../components/internalNote";
// import Item from "../components/item";

const TableView = (list) => {
    const navigate = useNavigate();
    const activeStyle = (status) => {
        if (status) {
            return "!text-blue-500";
        } else {
            return "!text-gray-500";
        }
    };


    const nullHandle = (data, chained) => {
        if (!data || chained == null) {
            return "-";
        } else {
            return chained;
        }
    };
    return {
        list: list ?? [],
        theme: {
            'last update': (item) => {
                return (
                    <util.dateDisplay item={item} date={item?.updated_at} />
                )
            },

            channel: (item) => (
                <div className="w-[90.83px]  flex-col justify-center items-start gap-2 inline-flex">
                    <img className="w-[90.83px] h-8 object-auto object-center "
                         src={item?.channel?.logo || '/assets/images/noImageWide.jpg'}/>
                </div>
            ),
            currency: (item) => (
                <div className="w-[100px] h-16   justify-center items-center gap-2 inline-flex">
                    <img className="w-[20px] h-[20px]"
                         src={item?.currency?.logo || 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/US_flag_49_stars.svg/2560px-US_flag_49_stars.svg.png'}/>
                    <div className="text-gray-600 text-2xl font-bold font-['Inter'] leading-normal">
                        {item?.currency?.code}
                    </div>
                </div>
            ),

            category: (item) => (
                <div className="min-w-[200px] w-[100%] h-16   justify-center items-center inline-flex">
                    <div className="grow shrink basis-0  flex-col justify-center items-center gap-1 inline-flex">
                        <div className="self-stretch justify-center items-center gap-1 inline-flex">
                            <div
                                className="grow shrink basis-0 self-stretch text-gray-900 text-sm text-center font-normal font-['Inter'] leading-[17.50px]">
                                {item?.category?.name}
                            </div>
                        </div>
                    </div>
                </div>
            ),

            'referral fee': (item) => (
                <Numbers text={item?.referral_fee ? priceHandler(item?.referral_fee) + '%' : '-'}/>
            ),
            'min. fee': (item) => (
                <Numbers text={item?.min_fee ? item?.currency?.code + ' ' + priceHandler(item?.min_fee) : '-'}/>
            ),
            'treshold': (item) => (
                <Numbers
                    text={item?.threshold ? item?.currency?.code + ' ' + priceHandler(item?.threshold) : 'No Treshold'}/>
            ),
            'OVER TRESHOLD': (item) => (
                <Numbers
                    text={item?.over_threshold ? item?.currency?.code + ' ' + priceHandler(item?.over_threshold) : 'No Treshold'}/>
            ),
        },
    };
};
export default TableView;
