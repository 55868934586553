import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchRequests = createAsyncThunk(
    'get:api/v1/supply-requests',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
          dispatch(fetchLoading());
        }
        const data = await api.fetchRequests(
          typeof payload === "string" ? payload : payload.query
        );
        return { ...data?.data, append: payload?.append };
      }
)

const createRequest = createAsyncThunk(
    'post:api/v1/supply-requests',
    async (payload, thunkAPI) => {
        const response = await api.createRequest(payload)
        // console.log(response)
        return response.data
    }
)

const supplyFulfilment = createAsyncThunk(
    'post:api/v1/supply-fulfillments',
    async (payload, thunkAPI) => {
        const response = await api.supplyFulfilment(payload)
        // console.log(response)
        return response.data
    }
)

const fetchFilters = createAsyncThunk(
    'get:api/v1/supply-requests/filters',
    async (payload, thunkAPI) => {
        const response = await api.fetchFilters()
        // console.log(response)
        return response.data
    }
)

const deleteRequest = createAsyncThunk(
    'delete:api/v1/supply-requests',
    async (ids, thunkAPI) => {
        const response = await api.deleteRequest(ids)
        // console.log(response)
        return response.data
    }
)

const updateSupplyRequest = createAsyncThunk(
    'put:api/v1/supply-listings/:id',
    async (payload, thunkAPI) => {
        const response = await api.updateSupplyRequest(payload)
        return response.data
    }
)


export {
    fetchRequests,
    createRequest,
    supplyFulfilment,
    fetchFilters,
    deleteRequest,
    updateSupplyRequest
}