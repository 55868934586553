import React from "react";
import upperCaseString from "../../../dynamic-page/util/upperCaseString";
import util from "../../../dynamic-page/util";
import { HiFilter } from "react-icons/hi";
import descriptiveContent from "../../../../utils/descriptiveContent";

const orderId = ({ order, callBack, orderId, item, order_numbers , onFilterApply}) => {
  return (
    <div className={"justify-start gap-1.5 flex flex-col w-full"}>
      <div
        className={
          "flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit"
        }
      >
        {order?.country?.flag && (
          <util.popUp
            toggle={(selected) => (
              <img
                src={order?.country?.flag}
                className={"h-[14px] w-[20px] rounded-[2px]"}
                style={{ objectFit: "cover" }}
              />
            )}
            action={"hover"}
          >
            <div className={"px-[8px] py-[4px]"}> {order?.country?.name} </div>
          </util.popUp>
        )}

        {order?.marketplace?.logo && (
          <img src={order?.marketplace?.logo} className={"h-[14px] w-[14px]"} />
        )}
        {order?.name && (
          <span className={"text-[14px h-18px items-center flex"}>
            {upperCaseString(order?.name)}
          </span>
        )}

{descriptiveContent(
              <HiFilter
                onClick={(e) => {
                  e.stopPropagation();
                  onFilterApply(
                    {
                      sales_account: item?.sales_channel_account?.id,
                    },
                    null,
                    {
                      sales_account: {
                        value: item?.sales_channel_account?.id,
                        label: item?.sales_channel_account?.name,
                      },
                    }
                  );
                }}
                className={` w-[14px] h-[14px] cursor-pointer text-gray-300 hover:text-gray-500`}
              />,
              "Filter by sales channel account",
            )}
      </div>

      <div
        className={
          "min-w-max whitespace-no-wrap text-blue-600 text-[12px] hover:text-blue-700 cursor-pointer flex flex-row gap-[4px]"
        }
      >
        {order_numbers?.length > 1 && (
          <util.popUp
            toggle={() => (
              <div
                className={
                  "bg-blue-100 py-[1px] flex items-center  min-w-[20px] h-[20px] px-[6px] text-blue-800 rounded-full hover:bg-blue-200 transition-all"
                }
              >
                {order_numbers?.length}
              </div>
            )}
            action="click"
          >
            <div className="flex flex-col gap-1 p-2">
              {order_numbers?.map((order, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center gap-2"
                >
                  <span className="text-gray-700 font-medium">{order}</span>
                  <div>
                    <util.copyText
                      iconClass='!w-3 !h-3'
                      className={"text-[12px]"}
                      text={order}
                      hint={false}
                    />
                  </div>
                </div>
              ))}
            </div>
          </util.popUp>
        )}
        <span
          onClick={() => {
            callBack({ action: "orderDetails", data: item });
            //window.open(`/`, '_blank')
          }}
        >
          {order?.order_number}
        </span>
        <div className="flex items-center">
          <util.copyText
            className={"text-[12px]"}
            text={order?.order_number}
            hint={false}
          />
        </div>
      </div>
    </div>
  );
};
export default orderId;
