import axios from './../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

const fetchOpportunities = async (params) => {
    let url = constants.APIURL.GET_OPPORTUNITIES
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const showOpportunity = async (params) => {
    let url = constants.APIURL.GET_OPPORTUNITIES + '/' + params
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const updateOpportunities = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_OPPORTUNITIES_UPDATE, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}

const updateBulkOpportunities = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_BULK_OPPORTUNITIES_UPDATE, payload, {withCredentials: true})
    return response
}

const fetchOpportunitiesFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_OPPORTUNITIES_FILTERS, {withCredentials: true})
    return response
}

const hideOpportunitieID = async (payload) => {
    const response = await axios.put(constants.APIURL.HIDE_OPPORTUNITIE_ID, payload, {withCredentials: true})

    return response
}

const hideOpportunitieBrand = async (payload) => {
    const response = await axios.put(constants.APIURL.HIDE_OPPORTUNITIE_BRAND, payload, {withCredentials: true})
    // console.log(response.data, '----OppurtunityFilter Response')

    return response
}

const unhideOpportunitieID = async (payload) => {

    const response = await axios.put(constants.APIURL.UNHIDE_OPPORTUNITIE_ID, payload, {withCredentials: true})

    return response
}

const unhideOpportunitieBrand = async (payload) => {

    const response = await axios.put(constants.APIURL.UNHIDE_OPPORTUNITIE_BRAND, payload, {withCredentials: true})

    return response
}

const fetchCategories = async () => {
    const response = await axios.get(constants.APIURL.GET_CATEGORIES + '?showAll=true', {withCredentials: true})
    return response
}

const postOppurtunityNotes = async (id, payload) => {
    const response = await axios.post(constants.APIURL.POST_OPPURTUNITY_NOTES + id + "/note", payload, {withCredentials: true})

    return response
}
export default {
    fetchOpportunities,
    updateOpportunities,
    updateBulkOpportunities,
    fetchOpportunitiesFilters,
    hideOpportunitieID,
    hideOpportunitieBrand,
    unhideOpportunitieID,
    unhideOpportunitieBrand,
    fetchCategories,
    postOppurtunityNotes,
    showOpportunity
}
