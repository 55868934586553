import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


export default function HandmadeSkeleton({width, height, borderRadius, className}) {
    return (
        <Skeleton width={width} height={height} borderRadius={borderRadius} className={className}/>
    )
}
