import { createSlice } from "@reduxjs/toolkit";

import {
  deleteBulkShippingTemplate,
  fetchShippingTemplate,
  fetchShippingTemplateFilters,
  fetchShippingTemplateRestrictions,
  updateBulkShippingTemplateStatus,
  updateOpportunities,
} from "./thunk";

const initialState = {
  shippingtemplate: [],
  links: {},
  filters: {
    shippingCostLists_filter: [],
    arbitboxWarehouses_filter: [],
    carrierServices_filter: [],
    carriers_filter: [],
    employee_filter: [],
    additional_cost_filter: [],
    name_filter: "",
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  restrictions: {},
  loading: "idle",
  error: null,
  isLogin: false,
};

export const shippingtemplateSlice = createSlice({
  name: "shippingtemplate",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      // console.log('Update State', action);
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingTemplate.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchShippingTemplate.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.shippingtemplate = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchShippingTemplate.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(updateOpportunities.fulfilled, (state) => {})
      .addCase(fetchShippingTemplateFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
      })
      .addCase(fetchShippingTemplateRestrictions.fulfilled, (state, action) => {
        state.restrictions = action.payload?.data;
      });
  },
});

export const { updateFilter, updateBoundFilter } =
  shippingtemplateSlice.actions;

export {
  fetchShippingTemplate,
  updateOpportunities,
  fetchShippingTemplateFilters,
  updateBulkShippingTemplateStatus,
  deleteBulkShippingTemplate,
  fetchShippingTemplateRestrictions,
};

export default shippingtemplateSlice.reducer;
