import React, {useEffect, useState} from "react";
import util from "../../dynamic-page/util";
import JSONPretty from "react-json-pretty";

const DateFormatter = ({isoDateString}) => {
    const date = new Date(isoDateString);

    // Format the date part
    const datePart = date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });

    // Format the time part
    const timePart = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
    });

    // Combine date and time parts without "at"
    const formattedDate = `${datePart} ${timePart}`;
    return (
        <div className="text-[14px] leading-[21px] font-medium text-gray-900 ">
            {formattedDate}
        </div>
    );
};

export default function SingleActivity({
                                           datetime,
                                           description,
                                           id,
                                           ip_address,
                                           requestData,
                                           url,
                                           index,
                                       }) {

    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleCopy = () => {
        // Copy text to clipboard
        navigator.clipboard.writeText(requestData);

        // Show tooltip
        setShowTooltip(true);

        // Hide tooltip after 1 second
        setTimeout(() => {
            setShowTooltip(false);
        }, 500);
    };

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const processData = (data) => {
        try {

            let parsedData = JSON.parse(data);

            return parsedData;
        } catch (e) {
            try {
                const decodedData = decodeURIComponent(JSON.parse('"' + data + '"'));
                let parsedData = JSON.parse(decodedData);

                const hasEllipsis = (obj) => {
                    return Object.values(obj).some(
                        (value) => typeof value === 'string' && value.endsWith('...')
                    );
                };

                // Ellipsis içeren objeleri filtreleme
                if (Array.isArray(parsedData)) {
                    parsedData = parsedData.filter((item) => !hasEllipsis(item));
                } else if (typeof parsedData === 'object' && parsedData !== null) {
                    parsedData = hasEllipsis(parsedData) ? null : parsedData;
                }

                return parsedData;
            } catch (innerError) {
                console.error('error', innerError);
                return null;
            }
        }
    };

    const data = processData(requestData);

    return (
        <tr className="hover:bg-gray-50">
            <td className="  w-[210px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                <div className="px-4">
                    <DateFormatter isoDateString={datetime}/>
                </div>
            </td>
            <td className="  w-[104px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                <div>
          <span className="text-[14px] leading-[21px] text-gray-500 ">
            {id}
          </span>
                </div>
            </td>
            <td className="  w-[133px] h-[54px] text-start border-b whitespace-nowrap border-gray-200">
                <div>
          <span className="text-[14px] leading-[21px] text-gray-500 ">
            {ip_address}
          </span>
                </div>
            </td>
            <td className="  w-[380px] h-[54px] text-start border-b border-gray-200 whitespace-nowrap">
                <div className={` ${isSmallScreen ? 'w-[140px]' : 'w-full'} whitespace-nowrap overflow-hidden text-ellipsis`}>
          <span className="text-[14px] leading-[21px] text-gray-500  font-medium">
            {description}
          </span>
                </div>
            </td>
            <td className="  w-[180px] h-[54px] text-start border-b border-gray-200 whitespace-nowrap">
                <div className="px-4">
                    <util.popUp
                        toggle={(selected) => (
                            <div className="relative inline-block">
                <span
                    onClick={() => {
                        handleCopy()
                    }}
                    className="text-[14px] leading-[21px] font-medium text-blue-700"
                >
                  Show Requested Data
                </span>
                                {showTooltip && (
                                    <div
                                        className="absolute top-full mt-2 px-3 py-1.5 bg-gradient-to-r from-blue-500 to-blue-700 text-white text-xs rounded shadow-lg">
                                        Copied!
                                    </div>

                                )}
                            </div>
                        )}
                        forcePosition={index !== 0 && index !== 1 && index !== 2 ? "top" : "bottom"}
                        action={"hover"}
                        rightSpace={10}
                        innerClassName={""}
                    >
                        <div className="flex flex-col w-full p-2 gap-1.5  z-50  ">
              <span className="text-gray-900 text-[14px] leading-[14px] font-medium">
                {url}
              </span>
                            <span className="text-gray-500 text-[12px] leading-[15px] w-[400px] ">
                {requestData?.length > 300 ? "The data is very long, you can view the details by copying and pasting it into the editor." : requestData.includes("note") ?
                    <span>{JSON.stringify(processData(requestData))}</span> :
                    requestData.includes("password") ? "Logged in" : <JSONPretty
                        id="json-pretty"
                        data={(() => {
                            let data;
                            try {
                                data = JSON.parse(requestData);
                            } catch (error) {
                                return []; // Return an empty array if JSON is invalid
                            }

                            // Function to check for ellipsis in object values
                            const hasEllipsis = (obj) => {
                                return Object.values(obj).some(
                                    (value) =>
                                        typeof value === "string" && value.endsWith("...")
                                );
                            };

                            // Filter out objects with ellipsis
                            if (Array.isArray(data)) {
                                data = data.filter((item) => !hasEllipsis(item));
                            } else if (typeof data === "object" && data !== null) {
                                data = hasEllipsis(data) ? null : data;
                            }

                            return data;
                        })()}
                    ></JSONPretty>}
              </span>
                        </div>
                    </util.popUp>
                </div>
            </td>
        </tr>
    );
}
