import React, {useEffect} from "react";
import {FaPaste} from "react-icons/fa";
import isBase64 from "../util/isBase64";
import blobToBase64 from "../util/blobToBase64";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";

const ProductImage = ({item, data, submit}) => {

    // control UI
    const [urlFocus, setUrlFocus] = React.useState(false);


    //const [clipboardFile, setClipboardFile] = React.useState(null);
    const [imageUrl, setImageUrl] = React.useState();
    const [imageFile, setImageFile] = React.useState(null);

    const [image, setImage] = React.useState(null);

    const [clipboardText, setClipboardText] = React.useState(null);

    const inputRef = React.useRef(null);
    const textRef = React.useRef(null);

    useEffect(() => {
        if (imageUrl) {
            submit(imageUrl);
        } else if (imageFile) {
            submit(imageFile);
        }

    }, [image]);

    // Handle change value from form
    useEffect(() => {
        blobToBase64(data[item?.name]).then((res) => {
            const value = res;
            if (value === null || value === "" || value === undefined) {
                setImageFile(null);
                setImageUrl(null)
                setImage(null);
                textRef.current.value = null;
            } else {


                const isFile = isBase64(value);

                if (!isFile) {
                    textRef.current.value = value;
                    if (image?.file !== value) {
                        setImageFile(null);
                        setImageUrl(value)

                        textRef.current.value = value;
                    }
                    setImage({file: value, name: value});
                }

            }
        })


    }, [data]);

    // Handle default value and set Event Listener for clipboard
    useEffect(() => {
        if (!image && item?.defaultValue) {
            setImageUrl(item?.defaultValue);
            setImage({file: item?.defaultValue, name: item?.defaultValue});
        }
        window.addEventListener('focus', checkClipboard);
        window.addEventListener('copy', checkClipboard);
        window.addEventListener('cut', checkClipboard);
        return () => {
            window.removeEventListener('focus', checkClipboard);
            window.removeEventListener('copy', checkClipboard);
            window.removeEventListener('cut', checkClipboard);
        };
    }, []);


    // handle check clipboard if has url test show paste icon
    const checkClipboard = () => {
        if (!navigator.clipboard) return false;
        // Read text content from clipboard
        navigator.clipboard.readText().then(clipText => {
            if (checkUrl(clipText)) {
                setClipboardText(clipText);
            } else {
                setClipboardText(null);
            }
        });
    }

    const checkUrl = (string) => {
        try {
            new URL(string);
            return string;
        } catch (_) {
            return false;
        }
    }

    // handle paste file from clipboard
    const checkFileClipboard = () => {
        navigator.permissions.query({name: 'clipboard-read'}).then(result => {
            if (result.state === 'granted' || result.state === 'prompt') {

                navigator.clipboard.read().then(clipboardItems => {
                    for (const clipboardItem of clipboardItems) {
                        for (const type of clipboardItem.types) {
                            if (type.startsWith('image/')) {
                                clipboardItem.getType(type).then(blob => {
                                    handleFileReader(blob, "ClipboardImage." + clipboardItem.types[0].split("/")[1]);
                                });
                                return;
                            }
                        }
                    }
                }).catch(err => {
                    // Handle error case
                    console.error('An error occurred while reading the clipboard: ', err);
                    setImageFile(null);
                });
            }
        });
    }


    // Handle select file from input
    const handleImageChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            handleFileReader(file, file.name);
        } else {
            setImageFile(null);
        }
    };

    // Handle change url from input
    const handleUrlChange = (e) => {
        const url = e.target.value;
        setImageUrl(url);
        if (url) {
            setImage({file: url, name: url});
            setImageFile(null);
        } else {
            setImage(null)
        }

    }

    // handle file reader from input and clipboard
    const handleFileReader = (file, name) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage({file: reader.result, name: name})
            setImageFile(reader.result)
            textRef.current.value = "";
            setImageUrl(null);
        };
    }


    return (
        <div className={"flex flex-row gap-[8px] justify-center items-center w-full"}>


            {/* Image Preview */}
            <div className={"unselectable"}>
                <div onClick={checkFileClipboard}
                     className={`!w-[120px] !h-[120px] flex justify-center items-center bg-gray-50 rounded-full hover:border-gray-400 cursor-pointer border-gray-300 border overflow-hidden active:border-blue-500 group relative`}>
                    <ImageMagnifier
                        src={`${image ? image.file : "/assets/images/noImage.png"}`}
                        className={` max-w-[120px] max-h-[120px] relative ${image ? "rounded-md" : ""} `}
                    />
                    {/* <img src={`${image ? image.file : "/assets/images/noImage.png"}`} className={` max-w-[120px] max-h-[120px] relative ${image ? "rounded-md" : ""} `} alt={"Image"}/> */}
                    <div
                        className="opacity-0 group-hover:opacity-100 text-gray-500 group-active:text-blue-500 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-gray-200 h-[38px] justify-center items-center w-[38px] rounded-full transition border-[3px] flex">
                        <FaPaste/></div>
                </div>

            </div>


            {/* Form field s*/}
            <div className={"flex flex-col gap-[6px] w-full"}>


                {/* Select File */}
                <input onChange={handleImageChange} ref={inputRef} accept="image/*" type={"file"} className={`hidden`}/>
                <div onClick={() => {
                    inputRef?.current?.click();
                }}
                     className={`transition cursor-pointer overflow-hidden flex w-full justify-start flex-row gap-[8px] items-center text-gray-500 disabled:text-gray-400 rounded-md  h-[36px] bg-gray-50 border-gray-300 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block ${item?.innerClassName || ""} `}>
                    <div
                        className={"bg-[#1F2A37] text-[14px] text-white h-full w-[115px] justify-center items-center flex border-r border-r-gray-200"}>Choose
                        file
                    </div>
                    <div
                        className={"flex-grow pl-1 text-[14px] flex flex-row gap-[8px] items-center text-gray-500 py-[2px]"}>

                        {imageFile && image?.name || "Click to upload or drag and drop"}

                    </div>
                </div>


                {/* Separator*/}
                <div
                    className={"text-gray-500 font-bold text-[12px] flex flex-row gap-[16px] justify-center items-center h-[12px]"}>
                    <div className={"h-[1px] w-[100px] bg-gray-300"}/>
                    or <div className={"h-[1px] w-[100px] bg-gray-300"}/></div>


                {/*Enter Url*/}
                <div
                    className={`transition cursor-text overflow-hidden w-full justify-start  items-center text-gray-500 rounded-md  h-[36px] bg-gray-50 border-gray-300 border focus:outline-none ${urlFocus ? "border-blue-500 " : "hover:border-gray-400"} flex-row flex gap-[16px]`}>
                    <div
                        className={"bg-[#1F2A37] text-[14px] text-white h-full w-[115px] justify-center items-center flex border-r border-r-gray-200"}>Import
                        URL
                    </div>
                    <div className={"flex-grow flex flex-row items-center  "}>

                        <input onChange={handleUrlChange} type={"text"} ref={textRef}
                               className={"w-[100%] text-[14px] py-[2px] text-gray-900 px-0 border-0 outline-0 bg-transparent"} placeholder={"Paste image URL"}
                               onFocus={() => setUrlFocus(true)} onBlur={() => setUrlFocus(false)}/>

                    </div>

                    {/* Paste Button */}
                    {clipboardText && (
                        <div
                            className={"bg-gray-200 h-[28px] flex justify-center items-center w-[28px] rounded-md cursor-pointer mr-[4px] hover:bg-gray-300 transition"}
                            onClick={() => {
                                textRef.current.value = clipboardText;
                                setImage({
                                    file: clipboardText,
                                    name: clipboardText.split("/")[clipboardText.split("/").length - 1]
                                });
                                setImageUrl(clipboardText);
                                setImageFile(null);
                            }}
                        >
                            <FaPaste/>
                        </div>
                    )}

                </div>

            </div>

        </div>
    )
}

export default ProductImage;

