import axios from "../../middleware/axios";

const fetchReturns = async (params) => {
    let url = 'api/v1/returned-orders';
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

const fetchReturnsFilters = async (params) => {
    let url = 'api/v1/returned-orders/filters';
    if (params) url = url.concat(params).concat("&filter[current_status_id]=")
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

let urlNote = 'api/v1/returned-orders';

const fetchNotes = async (params) => {
    const url = `${urlNote}/${params?.shipmentId}/notes`;
    return await axios.get(url, {withCredentials: true,})
}


const deleteNote = async (params) => {
    const url = `${urlNote}/notes/${params?.noteId}`;
    return await axios.delete(url, {withCredentials: true,});
}


const addNote = async (params) => {
    const url = `${urlNote}/${params?.shipmentId}/notes`;
    return await axios.post(url, {note: params?.note}, {withCredentials: true,});
}


export default {fetchReturns, fetchReturnsFilters, fetchNotes, deleteNote, addNote};