import { createSlice } from "@reduxjs/toolkit";

import { fetchOffer, fetchOffers, fetchOffersFilters } from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  offers: [],
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  boundFilters: {},
  filters: {},
  filterLoading: "idle",
};

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    // fetchOffer: (state, action) => {
    //   return { ...state, offers: action.payload };
    // },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    getMetaFilter: (state, action) => {
      return { ...state, filtersMeta: action.payload };
    },
    // update offers status when given list of ids
    updateOffersStatus: (state, action) => {
      const { ids, status } = action.payload;
      state.offers = state.offers.map((offer) => {
        if (ids.includes(offer.id)) {
          return { ...offer, status };
        }
        return offer;
      });
    },
    // remove offers when given list of ids
    removeOffers: (state, action) => {
      const { ids } = action.payload;
      state.offers = state.offers.filter((offer) => !ids.includes(offer.id));
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchOffers.pending, (state, action) => {
      //     if (state.loading === "idle") {
      //         state.loading = "pending";
      //     }
      // })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.loading = "idle";
        state.offers = action.payload?.append
          ? [...state.offers, ...action.payload?.data?.data]
          : action.payload?.data?.data || [];
        state.links = action.payload?.data?.["links"];
        state.meta = action.payload?.data?.["meta"];
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchOffersFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchOffersFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export const {
  updateFilter,
  extraReducers,
  getMetaFilter,
  updateOffersStatus,
  removeOffers,
  updateRow,
  updateBoundFilter,
  fetchLoading,
} = offersSlice.actions;

export { fetchOffers, fetchOffersFilters, fetchOffer };

export default offersSlice.reducer;
