/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Footer - a footer of arbit product page at the bottom
 */

import PropType from "prop-types"

const units = ["px", "em", "%"]

const Footer = ({children, marginX, height, justify}) => {
    return (
        <div
            className={
                `
          py-[15px]
          h-${units.some(unit => height.endsWith(unit))
                    ? '[' + height + ']'
                    : height}
          flex
          justify-${justify}
          items-center
          border-t
          border-gray-200
          absolute
          bottom-0
          left-0
          right-0
          bg-white
          w-full
         `
            }
        >
            {children}
        </div>
    )
}

Footer.propTypes = {
    marginX: PropType.string,
    height: PropType.string
}

Footer.defaultProps = {
    marginX: "8px",
    height: "60px",
    justify: "between"
}

export default Footer;
