// DropDownMenu.js
// -----------------------------------------


// Import General libraries
import React, {useEffect, useRef, useState} from "react";

// Main Component: DropDownMenu
function DropDownMenu({item, toggleButton, itemMenu, selected, ver}) {
    // Initial State to handle menu open and close.
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    }

    function handleHide() {
        setIsOpen(false);
    }


    const modalRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside of the modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                // Click is outside of the modal, close it
                setIsOpen(false) // Call the provided function to close the modal
            }
        };

        // Add event listener when component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]); // Make sure to include onClose in the dependencies
    

    // Initial Ref to handle menu position.
    const toggleButtonRef = useRef();
    const menuRef = useRef();


    // Handle menu position when menu is open.
    useEffect(() => {

        if (isOpen) {

            // Get position of toggle button and menu.
            const toggleButtonRect = toggleButtonRef.current.getBoundingClientRect();
            const menuRect = menuRef.current.getBoundingClientRect();

            // Get viewport width and height.
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            // Get button size.
            const buttonSize = {
                w: toggleButtonRect.width,
                h: toggleButtonRect.height,
            }

            // Get available space and required space.
            const availableSpace = {
                top: toggleButtonRect.top,
                bottom: viewportHeight - toggleButtonRect.bottom,
                left: toggleButtonRect.left,
                right: viewportWidth - toggleButtonRect.right
            };

            // Get required space.
            const requiredSpace = {
                width: menuRect.width,
                height: menuRect.height
            };

            // Set position of menu.
            let position = {
                top: (buttonSize.h * 0.7),
                left: (buttonSize.w * 0.3)
            };

            // Check if menu is out of viewport from Right and available space is enough to show menu on the left.
            if (availableSpace.right < requiredSpace.width && availableSpace.left >= requiredSpace.width) {
                position.left = 0 - (requiredSpace.width - (buttonSize.w * 0.7))
            }

            // set position of menu.
            if (menuRef?.current) {
                menuRef.current.style.top = `${position.top}px`;
                menuRef.current.style.left = `${position.left}px`;
            }

        }
    }, [isOpen]);


    // Render DropDownMenu
    return (
        <div className="relative" ref={modalRef}>


            {/* Render Toggle Button from props and send isOpen state to handle style. */}
            <div onClick={handleToggle} ref={toggleButtonRef}>
                {toggleButton(isOpen || false)}
            </div>


            {/* Render Menu from itemMenu. */}
            <div ref={menuRef}
                 className={` ${!isOpen && "hidden"} ${itemMenu?.list?.length!==0 && "p-1 transition absolute w-max left-[5px] top-[25px] z-[99999] flex flex-col text-left rounded-[10px] shadow-xl bg-white w-content overflow-auto max-h-[500px]"}`}>
                {itemMenu?.list?.map((menu, index) => (
                    <div
                        key={index}
                        className={
                            // Handle selected item Style.
                            `${selected && (selected?.startsWith("-") ? selected?.substring(1) : selected) === menu.value && "bg-blue-500 hover:bg-blue-600 text-white hover:text-white"}
                                cursor-pointer justify-start font-medium min-w-full hover:bg-gray-100 pl-[16px] pr-12 py-[9px] rounded-[6px] text-gray-500  hover:text-gray-800 transition text-sm`}
                        onClick={() => {
                            handleToggle();
                            menu.onClick(item)
                            /*if (ver === 2){
                                menu.onClick({data:item})
                            } else {
                                menu.onClick(item)
                            }*/

                        }}
                    >
                        {menu.label}
                    </div>
                ))}

            </div>


        </div>
    );
}

export default DropDownMenu;
