import { createSlice } from "@reduxjs/toolkit";
import { fetchInventory } from "./thunk";

const initialState = {
  mainInventory: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {},
  meta: {},
  filtersMeta: {},
};

export const mainInventorySlice = createSlice({
  name: "mainInventory",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter(state, action) {
      state.filters = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventory.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchInventory.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        state.loading = "idle";
        state.mainInventory = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      });
  },
});

export const { updateFilter, updateBoundFilter } = mainInventorySlice.actions;

export { fetchInventory };

export default mainInventorySlice.reducer;
