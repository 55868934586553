import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {useRoutes} from "react-router-dom";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {routes} from "./routes";
import LoadingBar from "react-top-loading-bar";

const AppRouter = () => {
    const isLogin = useSelector((state) => state.auth.isLogin);
    const appRoutes = useRoutes(routes(isLogin));
    const isLoading = useSelector((state) => state.loading);
    const progressBarRef = useRef(null);

    // Apply the middleware to the store, passing the progressBarRef
    useEffect(() => {
        if (isLoading) {
            progressBarRef.current.continuousStart();
        } else {
            progressBarRef.current.complete();
        }
    }, [isLoading]);

    return (
        <>
            <ToastContainer
                className="toastContainer"
                style={{
                    width: "auto",
                    padding: "10px 30px",
                    bottom: "50px",
                    right: "0px",
                    top: "unset",
                    left: "unset",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",

                }}
            />
            <LoadingBar
                color="#1c64f2"
                height={3}
                shadow={true}
                ref={progressBarRef}
            />
            {appRoutes}
        </>
    );
};

export default AppRouter;
