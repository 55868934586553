import * as Yup from "yup";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import constants from "../../../constant";

const formView = (item) => {
    const innerSubmit = (data) => {
        if (data?.channel_id?.data?.marketplace?.ucode === "AMAZON") {
            return [
                {
                    label: item?.id ? "Save" : "Create",
                    type: "alt",
                    action: "save",
                },
                {
                    label: "Create & Connect ",
                    type: "primary",
                    action: "saveAndConnect",
                },
            ];
        } else {
            return [
                {
                    label: item?.id ? "Save" : "Create",
                    type: "primary",
                    action: "save",
                },
            ];
        }
    };

    return {
        title:
            upperCaseString(item?.id ? "Edit" : "Create") + " Sales Channel Account",
        submit: innerSubmit,
        containerStyle: "!grid-cols-6",
        // close: !item?.id,
        // cancel: !item?.id ? false : true,
        viewModel: (formDefaultValue) => {
            return [
                {
                    name: "name",
                    label: "Name",
                    placeholder: "Sales Account name",
                    type: "Input",
                    clear: true,
                    labelClassName: "!text-gray-900 !font-medium",
                    reset: true,
                    defaultValue: formDefaultValue?.name || null,
                    className: "col-span-2",
                    validation: Yup.string().required("Name is required"),
                },
                {
                    name: "channel_id",
                    defaultValue:
                        formDefaultValue?.channel_id ||
                        formDefaultValue?.channel?.id ||
                        null,
                    defaultBound: formDefaultValue?.channel || null,
                    label: "Sales Channel",
                    placeholder: "Select Sales Channel",
                    type: "Select",
                    labelClassName: "font-medium text-[14px]",
                    isClearable: true,
                    // disabled: item ? true : false,
                    features: {
                        formatOptionLabel: ({label, logo}) => (
                            <div
                                className={"flex flex-row gap-[12px] items-center min-h-[25px]"}
                            >
                                {logo && (
                                    <span
                                        className={
                                            "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
                                        }
                                    >
                    <img src={logo?.toLowerCase()}/>
                  </span>
                                )}
                                <span>{upperCaseString(label)}</span>
                            </div>
                        ),
                    },
                    api: {
                        url: "/api/v1/channel?include=marketplace&filter[designation]=TARGET",
                        view: "/api/v1/channel",
                        query: "&term={query}",
                        optionValue: (item) => ({
                            value: item.id,
                            label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                            logo: item?.logo,
                        }),
                    },
                    validation: Yup.string().required("Sales Channel is Required"),
                    className: "col-span-2",
                },
                {
                    name: "deposit_account",
                    label: "Deposit Account",
                    placeholder: "Payoneer",
                    type: "Input",
                    clear: true,
                    labelClassName: "!text-gray-900 !font-medium",
                    reset: false,
                    defaultValue: formDefaultValue?.deposit_account || null,
                    className: "col-span-2",
                    validation: Yup.string().required("Deposit Account is required"),
                },
                {
                    label: "Mail Address",
                    name: "email",
                    defaultValue: formDefaultValue?.email || null,
                    placeholder: "info@website.com",
                    // innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
                    reset: false,
                    clear: true,
                    labelClassName: "!text-gray-900 !font-medium",
                    className: "col-span-2",
                    validation: Yup.string().required("Email is required"),
                },
                {
                    name: "phone",
                    label: "Phone Number",
                    defaultValue: formDefaultValue?.phone || null,
                    placeholder: "+90 (541) 230 49 97",
                    // innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
                    reset: false,
                    clear: true,
                    labelClassName: "!text-gray-900 !font-medium",
                    className: "col-span-2",
                    optional: true,
                },
                {
                    name: "ioss_number",
                    label: "IOSS Number",
                    placeholder: "Enter your IOSS Number",
                    type: "Input",
                    clear: true,
                    labelClassName: "!text-gray-900 !font-medium",
                    reset: true,
                    defaultValue: formDefaultValue?.ioss_number || null,
                    className: "col-span-2",
                    optional: true,
                },
                {
                    name: "currency_id",
                    label: "Currency",
                    placeholder: "TRY",
                    type: "Select",
                    api: {
                        url: constants.APIURL.GET_CURRENCIES + "?filter[status]=1",
                        view: constants.APIURL.GET_CURRENCIES,
                        query: "&term={query}",
                        optionValue: (item) => ({
                            value: item.id,
                            label: upperCaseString(item.code),
                        }),
                    },
                    defaultValue:
                        formDefaultValue?.currency?.id ||
                        formDefaultValue?.currency_id ||
                        null,
                    defaultBound: formDefaultValue?.currency || null,
                    validation: Yup.string().required("Currency is required"),
                    className: "col-span-2",
                    clear: true,
                },
                {
                    name: "country_id",
                    label: "Country", //+ (data?.country ? typeof data?.country === "object" ? data?.country?.id : data?.country : null),
                    placeholder: "Select country",
                    type: "Select",
                    api: {
                        url: "/api/v1/country",
                        query: "?filter[name]={query}",
                        optionValue: (item) => ({
                            value: item?.id,
                            label: item?.name,
                            flag: item?.flag,
                        }),
                    },
                    features: {
                        formatOptionLabel: ({label, flag}) => (
                            <div
                                className={
                                    "flex flex-row gap-[12px] items-center min-h-[25px] "
                                }
                            >
                                {flag && (
                                    <span
                                        className={
                                            "w-[28px] h-[20px] overflow-hidden flex flex-col justify-center items-center  "
                                        }
                                    >
                    <img
                        src={flag.toLowerCase()}
                        className={"object-cover w-[28px] h-[20px] rounded-[2px]"}
                    />
                  </span>
                                )}
                                <span>{upperCaseString(label)}</span>
                            </div>
                        ),
                    },
                    className: "col-span-2",
                    defaultValue:
                        formDefaultValue?.country_id ||
                        formDefaultValue?.country?.id ||
                        null,
                    reset: true,
                    clear: true,
                    validation: Yup.string().required("Country is required"),
                    labelClassName: "!text-gray-900 !font-medium",
                },
                {
                    name: "language_id",
                    label: "Main Language", //+ (data?.country ? typeof data?.country === "object" ? data?.country?.id : data?.country : null),
                    placeholder: "American English",
                    type: "Select",
                    api: {
                        url: "/api/v1/language",
                        query: "?filter[name]={query}",
                        optionValue: (item) => ({
                            value: item?.id,
                            label: item?.name,
                        }),
                    },
                    features: {
                        formatOptionLabel: ({label, flag}) => (
                            <div
                                className={
                                    "flex flex-row gap-[12px] items-center min-h-[25px] "
                                }
                            >
                                {flag && (
                                    <span
                                        className={
                                            "w-[28px] h-[20px] overflow-hidden flex flex-col justify-center items-center  "
                                        }
                                    >
                    <img
                        src={flag.toLowerCase()}
                        className={"object-cover w-[28px] h-[20px] rounded-[2px]"}
                    />
                  </span>
                                )}
                                <span>{upperCaseString(label)}</span>
                            </div>
                        ),
                    },
                    className: "col-span-2",
                    defaultValue:
                        formDefaultValue?.language_id ||
                        formDefaultValue?.language?.id ||
                        null,
                    reset: true,
                    clear: true,
                    validation: Yup.string().required("Language is required"),
                    labelClassName: "!text-gray-900 !font-medium",
                },
                {
                    name: "shipstation_store_id",
                    label: "Shipstation Store",
                    placeholder: "Select Shipstation Store",
                    type: "Select",
                    className: "col-span-6",
                    api: {
                        url: "/api/v1/shipstation/stores?filter[connection_eligible]=true&filter[status]=true",
                        query: "&term={query}",
                        optionValue: (item) => ({
                            value: item.id,
                            label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                            logo: item.logo,
                        }),
                    },
                    features: {
                        formatOptionLabel: ({label, logo}) => (
                            <div
                                className={"flex flex-row gap-[12px] items-center min-h-[25px]"}
                            >
                                {logo && (
                                    <span
                                        className={
                                            "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
                                        }
                                    >
                    <img src={logo?.toLowerCase()}/>
                  </span>
                                )}
                                <span>{upperCaseString(label)}</span>
                            </div>
                        ),
                    },
                    defaultValue:
                        formDefaultValue?.shipstation_store?.id ||
                        formDefaultValue?.shipstation_store_id ||
                        null,
                    defaultBound: formDefaultValue?.shipstation_store || null,
                    optional: true,
                },

                // {
                //     name: "identity_documents",
                //     label: "Update Identity Document",
                //     // placeholder: "Enter your notes",
                //   type: "File",
                //   reset: true,
                //     clear : true,
                //     defaultValue: formDefaultValue?.identity_documents || null,
                //     className: "col-span-6",
                //     optional : true,
                //     labelClassName: '!text-gray-900 !font-medium',
                // },
                {
                    name: "note",
                    label: "Note",
                    placeholder: "Enter your notes",
                    type: "Textarea",
                    rows: "3",
                    defaultValue: formDefaultValue?.note || null,
                    className: "col-span-6",
                    optional: true,
                    labelClassName: "!text-gray-900 !font-medium",
                },
            ];
        },
    };
};
export default formView;
