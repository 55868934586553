/**
 * @author Austin Ames
 * @description ArbitUploader - an uploader to upload file or image
 * @params string placeholder
 * @params array accept
 * @params func onFile
 */
import {useState} from "react";
import classNames from "classnames";
import {HiLink} from "react-icons/hi";

const units = ["px", "em", "%"];

const ArbitUploadUrlInput = ({
                                 width,
                                 height,
                                 bgColor,
                                 placeholder,
                                 accept,
                                 onHandleLinkSrc,
                             }) => {
    const [linkData, setLinkData] = useState("");
    return (
        <div
            className={classNames(
                "w-[275px]",
                // "w-" +
                //   (
                //     units.some(unit => width.endsWith(unit))
                //     ? "[" + width + "]"
                //     : width
                //   ),
                "h-" +
                (units.some((unit) => height.endsWith(unit))
                    ? "[" + height + "]"
                    : height)
            )}
        >
            <div
                className="flex flex-col border-[2px] bg-gray-50 p-[8px]
                        border-dashed border-gray-200 rounded-[16px] w-full h-full
                        text-[12px] text-gray-500 font-[500]"
            >
                <div className="flex space-x-[4px] items-center">
                    <HiLink/>
                    <p>Add URL(s)</p>
                </div>
                <div className="mt-[4px] h-full relative">
          <textarea
              className=" border-[1px] border-gray-300 rounded-[8px] w-full h-full text-[14px]"
              name="link-src_input"
              id="link-src_input"
              cols="30"
              onChange={(e) => {
                  setLinkData(e.target.value);
              }}
              value={linkData}
          ></textarea>
                    <p
                        className="absolute bottom-[4px] right-[2px] bottom-[2px] pr-1 cursor-pointer  bg-white"
                        onClick={() => {
                            setLinkData("");
                            onHandleLinkSrc(linkData);
                        }}
                    >
                        Upload
                    </p>
                    {/* <div className="relative border-[1px] border-gray-300 rounded-[8px] w-full h-full">
            <p className="absolute bottom-[4px] right-[4px]">Upload</p>
          </div> */}
                </div>
            </div>
        </div>
    );
};

ArbitUploadUrlInput.defaultProps = {
    width: "275px",
    height: "125px",
    bgColor: "gray-200",
    placeholder: "Upload Image(s)",
    accept: ["jpg", "png", "jpeg", "bmp", "tif"],
};

export default ArbitUploadUrlInput;
