import { createSlice } from "@reduxjs/toolkit";

import {
  activateSupplyListings,
  attachSupplyListings,
  deleteSupplyListings,
  duplicateSupplyListings,
  fetchSupplyListingFilters,
  fetchSupplyListings,
  refreshSupplyListig,
  updateBulkSupplyListings,
  updateSupplyListings,
} from "./thunk";

const initialState = {
  supplyListings: [],
  updatedSupplyListing: [],
  updatedBulkSupplyListing: [],
  filtersMeta: {},
  boundFilters: {},
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filters: {
    IdentifierType: [],
    Status: [],
    listingSourceId: [],
  },
  filterLoading: "idle",
};

export const supplyListingSlice = createSlice({
  name: "supplyListing",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplyListings.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchSupplyListings.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.supplyListings = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchSupplyListings.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(updateSupplyListings.fulfilled, (state, action) => {
        state.updatedSupplyListing = action.payload?.data;
      })
      .addCase(updateBulkSupplyListings.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(updateBulkSupplyListings.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.updatedBulkSupplyListing = action.payload?.data;
        }
      })
      .addCase(updateBulkSupplyListings.rejected, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchSupplyListingFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchSupplyListingFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      })
      .addCase(refreshSupplyListig.fulfilled, (state, action) => {
        // replace the supply listing with the updated one
        const index = state.supplyListings.findIndex(
          (supplyListing) => supplyListing.id === action.payload?.data.id
        );
        if (index !== -1) {
          state.supplyListings[index] = action.payload?.data;
        }
      });
  },
});

export const { updateFilter } = supplyListingSlice.actions;

export default supplyListingSlice.reducer;

export {
  fetchSupplyListings,
  updateSupplyListings,
  updateBulkSupplyListings,
  duplicateSupplyListings,
  activateSupplyListings,
  refreshSupplyListig,
  deleteSupplyListings,
  fetchSupplyListingFilters,
  attachSupplyListings,
};
