import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import SaveWeight2 from './SaveWeight2';

function SupplyCost({price, handleSavePrice}) {

    const [typing, setTyping] = React.useState(false)

    const [initial, setInitial] = React.useState(false)
    // React.useEffect(() => {
    //   if (price?.supply_cost) {
    //     setInitial(true)
    //   }
    // }, [price])


    const dispatch = useDispatch()
    const data = useSelector((state) => state?.supplyChannelListings.supplyChannelListings);
    const priceInRedux = data?.find(item => item?.id === price?.channelId)?.price || ""
    // const handleSaveWeight =  (t) => {

    //   const innerData = {
    //     channel_id: price?.data?.channel?.id,
    //     title: price?.data?.title,
    //     is_trackable: price?.data?.is_trackable,
    //     brand_id: price?.data?.brand?.id,
    //     currency_id: price?.data?.currency?.id,
    //     product_id: price?.data?.product?.id,
    //     quantity: price?.data?.quantity,
    //     image: price?.data?.image,
    //     price: String(t),
    //   }

    //   callEndpoint({
    //     title: "Update Supply Channel",
    //     url: `supply-channel-listing/${price?.data?.id}`,
    //     method: "POST",
    //     data: innerData,
    //     pureData: true,
    //   }).then((res) => { 
    //     if (res?.type === "success") {
    //       // const w = rs?.payload?.data?.supply_cost
    //       // notifier({message: `Supply Cost updated to  ${w}`, type : rs?.payload?.type})
    //       // // dispatch(fetchSingleOrder(weight?.orderId)).then((rs)=> {
    //       // //     console.log(rs.payload.data[0])
    //       //     dispatch(updateRow({path: `targetSellerData.[id:${cost?.orderId}]`, value:rs.payload.data}))
    //       // })

    //   }
    //   })
    // }

    return (
        <div className={"flex flex-col gap-[4px] w-full justify-center"}>
            <div className='flex justify-end items-center w-full'>
                <div className={"flex flex-col gap-[4px] justify-center items-end"}>

                    <div
                        className="text-purple-700 text-[24px]  font-bold leading-[16px]">{price?.usd_price ? '$' + price?.usd_price : '-'}</div>

                    <SaveWeight2 symbol={price?.data?.currency?.symbol} text={price?.price}
                                 onSave={(t) => handleSavePrice(t, price)}
                                 className={"min-w-max whitespace-no-wrap group-active:text-purple-800 hover:text-purple-600 flex text-[16px] text-purple-700 font-[500]"}/>


                </div>
            </div>

        </div>
    )
}

export default SupplyCost