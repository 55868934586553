import convertJsonToFormData from "../../../../../dynamic-form/util/convertJsonToFormData";
import filterObjectByKeys from "../../../../../dynamic-page/util/filterObjectByKeys";


const prepareDataToPost = async (detailData, state) => {

    let innerItems = detailData?.type === 1 ? ["warehouse_id:warehouse_id"] : detailData?.type === 2 ? ["warehouse_id:warehouse_id"] : ["warehouse_id:warehouse_id", "shipment_id:shipment_id", "account_id:account_id"]

    const mappedItems = detailData?.order_items.map(item => {
        return detailData?.type === 1 ? item?.shipment : detailData?.type === 2 ? {
            id: item?.product,
            quantity: item?.qty,
        } : {
            id: item?.offer,
            quantity: item?.qty,
        }
    })
    let mappedData = filterObjectByKeys(detailData, innerItems)

    mappedData = {
        ...mappedData,
        ship: state?.ship ? 1 : 0,
        ...detailData?.type === 1 ? {shipment_labels: mappedItems || []} : detailData?.type === 2 ? {products: mappedItems || []} : {offers: mappedItems || []}
    }
    if (detailData.id) {
        mappedData = {...mappedData, order_id: detailData.id}
    }

    return convertJsonToFormData(mappedData)


};

export default prepareDataToPost;