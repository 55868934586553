/**
 * @author Austin Ames
 */

import classNames from "classnames";

const ArbitButton = ({
                         className,
                         height,
                         width,
                         bg_color,
                         border_color,
                         text_color,
                         text_size,
                         disabled,
                         onClick,
                         children,
                         type,
                         padding
                     }) => {
    return (
        <button
            className={
                classNames(
                    width === "full"
                        ? "w-full"
                        : "w-[" + width + "]",
                    disabled
                        ? `bg-${bg_color}-500`
                        : `bg-${bg_color}-600 hover:bg-${bg_color}-800`,
                    `h-[${height}] py-auto px-[${padding ? padding : '16px'}] 
          text-${text_color} 
          border border-${border_color}-600 font-medium text-[${text_size}] flex items-center justify-center rounded-lg`
                )
            }
            disabled={disabled}
            onClick={
                (e) => onClick(e)
            }
            type={type ? type : 'button'}
        >
            {children}
        </button>
    )
}

ArbitButton.defaultProps = {
    className: null,
    height: "37px",
    bg_color: "blue",
    border_color: "blue",
    text_color: "white",
    text_size: "14px",
    disabled: false,
    onClick: () => {
    }
}

export default ArbitButton;
