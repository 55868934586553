import callEndpoint from "../../dynamic-page/util/callEndpoint";

const actionList = {
    add: {
        label: "Add Product",
        onClick: ({forms, data}) => {
            forms?.["add"].open(data);
        },
        condition: false,
    },

    // edit: {
    //     label: "Edit Product",
    //     onClick: ({forms, actions, data}) => {
    //         console.log("edit", forms, actions, data)
    //         forms?.["add"].open(data)
    //     },
    // },

    // saveAndNew: {
    //     label: "Save And New Product",
    //     onClick: ({data, callBack, clear, close, refreshPage}) => {
    //         console.log("🚀 ~ file: actions.js:28 ~ data:", data)
    //         const products = data?.type === 2 ? data.products.map((item)=>{
    //             return filterObjectByKeys(item, [
    //                 "id:product_id",
    //                 "",
    //             ])
    //         }) : null
    //         let innerData = {};
    //         innerData = data?.type === 2? {...data, products: products}: {...data}
    //         if (innerData?.category_id === null) { delete innerData?.category_id }
    //         callEndpoint({
    //             title: "Save Product: "+ data?.title,
    //             url: `product`,
    //             method: "POST",
    //             data: innerData
    //         }).then((res)=>{
    //             if (res?.type === "success") {
    //                 clear()
    //                 refreshPage()
    //             }
    //             callBack()
    //         })

    //     },
    //     condition: false
    // },
    save: {
        label: "Save Product",
        onClick: ({data, callBack, clear, close, refreshPage}) => {
            let innerData = data;
            if (innerData?.reason == null) {
                delete innerData?.reason;
            }
            callEndpoint({
                title: "Adding Adjustment",
                url: `inventory`,
                method: "POST",
                data: innerData,
                pureData: true,
            }).then((res) => {
                callBack();
                if (res?.type === "success") {
                    close();
                    refreshPage();
                }
            });
        },
        condition: false,
    },
};

export default actionList;
