import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchSalesListings = createAsyncThunk(
    'get:api/v1/account-listing',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchSalesListings(typeof payload === "string" ? payload : payload.query);
        return {...data?.data, append: payload?.append};
    }
)

const fetchSalesListing = createAsyncThunk(
    'get:api/v1/account-listing/:id',
    async (id, thunkAPI) => {
        const response = await api.fetchSalesListing(id)

        return response.data
    }
)

const fetchEmployees = createAsyncThunk(
    'get:api/v1/employees',
    async (thunkAPI) => {
        const response = await api.fetchEmployees()
        return response.data
    }
)

const updateSalesListingFulfilment = createAsyncThunk(
    'put:api/v1/account-listing/:id',
    async (params, thunkAPI) => {
        const response = await api.updateSalesListingFulfilment(params)
        return response
    }
)

const fetchSalesListingsFilter = createAsyncThunk(
    'get:api/v1/account-listing-filter',
    async (params, thunkAPI) => {
        const response = await api.fetchSalesListingsFilter(params)
        return response.data
    }
)

const fetchOffersFilters = createAsyncThunk(
    'get:api/v1/offers',
    async (thunkAPI) => {
        const response = await api.fetchOffersFilters()
        return response.data
    }
)

const connectProduct = createAsyncThunk(
    'post:api/v1/account-listing/connect/:listId/product/:productId',
    async (payload, thunkAPI) => {
        const response = await api.connectProduct(payload)
        return response.data
    }
)

const disconnectProduct = createAsyncThunk(
    'post:api/v1/account-listing/connect/:listId/product',
    async (payload, thunkAPI) => {
        const response = await api.disconnectProduct(payload)
        return response.data
    }
)

// const fetchTargetSellerListingsFilters = createAsyncThunk(
//   'get:api/v1/targetsellerlistings/filters',
//   async (params,thunkAPI) => {
//     const response = await api.fetchTargetSellerListingsFilters(params)
//     return response.data
//   }
// )

const fetchNotes = createAsyncThunk(
    'get:api/v1/account-listing/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.fetchNotes(params)
        return response.data
    }
)

const addNote = createAsyncThunk(
    'post:api/v1/account-listing/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.addNote(params)
        return response.data
    }
)

// const updateNote = createAsyncThunk(
//   'put:api/v1/account-listing/:id/notes/:noteId',
//   async (params, thunkAPI) => {
//     const response = await api.updateNote(params)
//     return response.data
//   }
// )

const deleteNote = createAsyncThunk(
    'delete:api/v1/account-listing/:id/notes/:noteId',
    async (params, thunkAPI) => {
        const response = await api.deleteNote(params)
        return response.data
    }
)


export {
    fetchSalesListings,
    updateSalesListingFulfilment,
    fetchSalesListingsFilter,
    fetchEmployees,
    fetchSalesListing,
    connectProduct,
    disconnectProduct,
    fetchNotes,
    addNote,
    deleteNote,
    fetchOffersFilters
}
