// uperCaseString.js
// -----------------------------------------
// Convert string to uppercase with type.

const upperCase = (string = " ", type, force) => {

    let text = String(string)
    if (force) text = text.toLowerCase()
    // all - Convert all string to uppercase.
    if (type === 'all') {
        return text?.toUpperCase();
    }

    // first - Convert first letter of string to uppercase and rest to lowercase.
    if (type === 'first') {
        return text?.charAt(0)?.toUpperCase() + text.slice(1).toLowerCase();
    }

    // word - Convert first letter of each word to uppercase.
    if (type === 'word') {
        return text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    // Default - Convert first letter of string to uppercase.

    return text?.charAt(0)?.toUpperCase() + text.slice(1)
}
export default upperCase;