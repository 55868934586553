import React from 'react';
import Icons from "../../icons";

const salesChannelStatus = ({statusId, text, color}) => {


    const colorMapping = {
        "green": {color: "#057A55"},
        "yellow": {color: "#FACA15"},
        null: {color: "#fafafa"},
    }
    if (!statusId) return null;
    const bulletColor = colorMapping[color] || "gray-400";
    return (
        <div
            className={`w-fit h-[22px] py-[2px] flex justify-center items-center gap-[6px] text-[12px] min-w-max whitespace-no-wrap font-[500]`}
            style={bulletColor}>
            <Icons.bullet className={`w-[12px] h-[12px] `}/>
            <span className={"text-gray-900"}>{text}</span>
        </div>
    )
}
//${colorMapping[color]}
export default salesChannelStatus;