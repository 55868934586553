import * as Yup from "yup";

const formView = (data) => [
  {
    name: "type",
    label: "Transfer Type",
    type: "RadioButton",
    defaultValue: data?.type || 1,
    className: "col-span-1",
    outerClassName: "!flex-col",
    list: [
      { label: "Warehouse Shipment Transfer", value: 1 },
      { label: "Warehouse Inventory Transfer", value: 2 },
      { label: "Fulfillment Center Inventory Transfer", value: 3 },
    ],
    clear: false,
  },
  {
    name: "warehouse_id",
    label: "Warehouse",
    placeholder: " warehouse",
    type: "Select",
    api: {
      url: "api/v1/warehouses?include=state",
      query: "&filter[name]={query}",
      optionValue: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
    },
    container: "!flex-row",
    defaultValue: data?.warehouse_id,
    clear: true,
    condition: (data) => data?.type !== 3,
    validation: Yup.string().required("Warehouse is required"),
  },
  {
    name: "warehouse_id",
    label: "Fulfillment Center",
    placeholder: " Istanbul",
    type: "Select",
    api: {
      url: "api/v1/warehouses?filter[type]=2&include=state",
      query: "&filter[name]={query}",
      optionValue: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
    },
    container: "!flex-row",
    defaultValue: data?.warehouse_id,
    clear: true,
    condition: (data) => data?.type === 3,
    // validation: Yup.string().required('Fulfillment Center is required'),
  },
  {
    name: "account_id",
    label: "Account",
    placeholder: " Routa",
    type: "Select",
    api: {
      url: "api/v1/accounts",
      query: "?filter[name]={query}",
      optionValue: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
    },
    container: "!flex-row",
    defaultValue: data?.account_id,
    clear: true,
    condition: (data) => data?.type === 3,
    // validation: Yup.string().required('Account is required'),
  },
  {
    name: "shipment_id",
    label: "Shipment ID",
    placeholder: "Enter ID",
    type: "Input",
    defaultValue: data?.shipment_id || null,
    innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
    container: "!flex-row",
    reset: false,
    clear: false,
    condition: (data) => data?.type === 3,
    // validation: Yup.string().required('Shipment ID is required'),
  },
];

export default formView;
