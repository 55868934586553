import React from "react";
import UI from "../../dynamic-form/elements";
import {HiExternalLink, HiInformationCircle} from "react-icons/hi";
import ArbitToggle from "../../sub-elements/toggle";
import {FaRegEdit} from "react-icons/fa";
import Util from "../../dynamic-page/util";

const Fba = ({modalListing, fulfilmentHandler}) => {
    const QuantityTooltip = ({data}) => {
        if (data)
            return (
                <Util.popUp
                    toggle={(selected) => (
                        <HiInformationCircle className="text-gray-300 cursor-pointer mt-1"/>
                    )}
                    action={"hover"}
                >
                    <div
                        className="min-w-[250px] h-[55px] px-3 py-2.5 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                        {data?.map((item) => {
                            return (
                                <div className="flex flex-row justify-between w-full">
                                    <p className="text-gray-900 text-sm font-normal leading-[21px]">
                                        {item?.account?.name}
                                    </p>
                                    <p className="text-gray-900 text-sm font-semibold leading-[21px]">
                                        {item?.val || 0}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </Util.popUp>
            );
    };

    const fbaData01 = [
        {
            title: "Show FBA restocking sugg. for this listing.",
            value: "FBA Restocking",
            elements: (
                <ArbitToggle
                    status={modalListing?.fba_eligibility ?? false}
                    bgColor={
                        modalListing?.fba_eligibility ? "bg-orange-600" : "bg-gray-400"
                    }
                    handleFulfilment={() => fulfilmentHandler("fba", modalListing?.id)}
                />
            ),
        },
        {
            title: "FBA Total Inventory",
            value: // total inventory is the sum of available, reserved, inbound, and unfulfillable quantities
                modalListing?.fba_eligibility ? (
                    modalListing?.fba_available_qty || modalListing?.available_quantity || 0 +
                    modalListing?.fba_reserved_qty || modalListing?.reserved_quantity || 0 +
                    modalListing?.fba_inbound_quantity || modalListing?.inbound_quantity || 0 +
                    modalListing?.fba_unfulfillable_quantity || modalListing?.unfulfillable_quantity || 0
                ) : (
                    modalListing?.fba_available_qty || modalListing?.available_quantity || 0 +
                    modalListing?.fba_reserved_qty || modalListing?.reserved_quantity || 0 +
                    modalListing?.fba_inbound_quantity || modalListing?.inbound_quantity || 0 +
                    modalListing?.fba_unfulfillable_quantity || modalListing?.unfulfillable_quantity || 0
                ) || "-",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 text-gray-300 cursor-pointer hover:text-blue-500"
                    onClick={() => {
                        window.open(
                            modalListing?.sales_channel_listing?.url || modalListing?.url,
                            "_blank"
                        );
                    }}
                />
            ),
        },
        // Add more data items as needed
    ];
    const fbaData02 = [
        {
            title: "FBA Available",
            value: modalListing?.fba_eligibility ? modalListing?.fba_available_qty || modalListing?.available_quantity || 0 : modalListing?.fba_available_qty || modalListing?.available_quantity || '-'
        },
        {
            title: "FBA Reserved",
            value: modalListing?.fba_eligibility ? modalListing?.fba_reserved_qty || modalListing?.reserved_quantity || 0 : modalListing?.fba_reserved_qty || modalListing?.reserved_quantity || '-'
        },

        {
            title: "FBA Inbound", value:
                modalListing?.fba_eligibility ? modalListing?.fba_inbound_quantity || modalListing?.inbound_quantity || 0 : modalListing?.fba_inbound_quantity || modalListing?.inbound_quantity || '-'
        },
        {
            title: "FBA Unfulfillable",
            value: modalListing?.fba_eligibility ? modalListing?.fba_unfulfillable_quantity || modalListing?.unfulfillable_quantity || 0 : modalListing?.fba_unfulfillable_quantity || modalListing?.unfulfillable_quantity || '-'
        },
        // Add more data items as needed
    ];
    const fbaData03 = [
        {
            title: "7 Days Sugg. Restocking",
            value: modalListing?.["fba_7_days_restock"] ?? "-",
            icon: <QuantityTooltip data={modalListing?.tooltips?.available}/>,
        },
        {
            title: "30 Days Sugg. Restocking",
            value: modalListing?.["fba_30_days_restock"] ?? "-",
            icon: <QuantityTooltip data={modalListing?.tooltips?.available}/>,
        },
        {
            title: "60 Days Sugg. Restocking",
            value: modalListing?.["fba_60_days_restock"] ?? "-",
            icon: <QuantityTooltip data={modalListing?.tooltips?.available}/>,
        },
        // Add more data items as needed
    ];
    const fbaData04 = [
        {
            title: "7 Days of Inv. Left",
            value: modalListing?.["fba_7_days_remaining"] ?? "-",
            icon: <QuantityTooltip data={modalListing?.tooltips?.available}/>,
        },
        {
            title: "30 Days of Inv. Left",
            value: modalListing?.["fba_30_days_remaining"] ?? "-",
            icon: <QuantityTooltip data={modalListing?.tooltips?.available}/>,
        },
        {
            title: "60 Days of Inv. Left",
            value: modalListing?.["fba_60_days_remaining"] ?? "-",
            icon: <QuantityTooltip data={modalListing?.tooltips?.available}/>,
        },
        // Add more data items as needed
    ];
    const fbaData05 = [
        {
            title: "Use min & max values when FBA pricing.",
            value: "FBA Repricer",
            elements: (
                <ArbitToggle
                    status={modalListing?.fba_eligibility ?? false}
                    bgColor={
                        modalListing?.fba_eligibility ? "bg-orange-600" : "bg-gray-400"
                    }
                    handleFulfilment={() => fulfilmentHandler("fba", modalListing?.id)}
                />
            ),
        },
        {
            title: "FBA Repricer Rule",
            value: "Match Buybox",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 text-gray-300 cursor-pointer hover:text-blue-500"
                    onClick={() => {
                        window.open(
                            modalListing?.sales_channel_listing?.url || modalListing?.url,
                            "_blank"
                        );
                    }}
                />
            ),
        },
        // Add more data items as needed
    ];
    const fbaData06 = [
        {
            title: "FBA Min. Price",
            value: modalListing?.fba_min_price || modalListing?.fba_min_price === 0 ? `$${modalListing?.fba_min_price}` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        {
            title: "FBA Current Price",
            value: modalListing?.fba_price || modalListing?.fba_price === 0 ? `$${modalListing?.fba_price}` : "-",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 text-gray-300 cursor-pointer hover:text-blue-500"
                    onClick={() => {
                        window.open(
                            modalListing?.sales_channel_listing?.url || modalListing?.url,
                            "_blank"
                        );
                    }}
                />
            ),
        },
        {
            title: "FBA Max. Price",
            value: modalListing?.fba_max_price || modalListing?.fba_max_price === 0 ? `$${modalListing?.fba_max_price}` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        // Add more data items as needed
    ];
    const fbaData07 = [
        {
            title: "FBA Min. Margin",
            value: modalListing?.fba_min_margin || modalListing?.fba_min_margin === 0 ? `${modalListing?.fba_min_margin}%` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        {
            title: "FBA Current Margin",
            value: modalListing?.fba_margin || modalListing?.fba_margin === 0 ? `${modalListing?.fba_margin}%` : "-",
            externallink: (
                <HiExternalLink
                    className="w-4 h-4 text-gray-300 cursor-pointer hover:text-blue-500"
                    onClick={() => {
                        window.open(
                            modalListing?.sales_channel_listing?.url || modalListing?.url,
                            "_blank"
                        );
                    }}
                />
            ),
        },
        {
            title: "FBA Max. Margin",
            value: modalListing?.fba_max_margin || modalListing?.fba_max_margin === 0 ? `${modalListing?.fba_max_margin}%` : "-",
            externallink: (
                <FaRegEdit className="w-4 h-4 text-gray-300 cursor-pointer"/>
            ),
        },
        // Add more data items as needed
    ];

    return (
        <div className="mt-5 flex flex-col justify-between items-start gap-y-3 gap-x-2 w-full">
            <h3 className="text-left text-gray-900 text-2xl font-bold">Inventory</h3>
            <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbaData01}/>
            </div>
            <div className="grid grid-cols-4 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbaData02}/>
            </div>
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbaData03}/>
            </div>
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbaData04}/>
            </div>
            {/* <h3 className="text-left text-gray-900 text-2xl font-bold">Repricer</h3>
      <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
        <UI.StatBoxList data={fbaData05} />
      </div> */}
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbaData06}/>
            </div>
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={fbaData07}/>
            </div>
        </div>
    );
};

export default Fba;
