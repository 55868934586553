import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchBankAccounts = createAsyncThunk(
    "get:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.fetchBankAccounts(payload);
        return response.data;
    }
);

const createBankAccount = createAsyncThunk(
    "post:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.createBankAccount(payload);
        return response;
    }
);

const updateBankAccount = createAsyncThunk(
    "put:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.updateBankAccount(payload);
        return response;
    }
);

const deleteBankAccount = createAsyncThunk(
    "delete:api/v1/accounts",
    async (payload, thunkAPI) => {
        const response = await api.deleteBankAccount(payload);
        return response;
    }
);

const searchBankAccounts = createAsyncThunk(
    "get:api/v1/accounts/search",
    async (payload, thunkAPI) => {
        const response = await api.searchBankAccounts(payload);
        return response.data;
    }
);

const updateBankAccountStatus = createAsyncThunk(
    "put:api/v1/accounts/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.updateBankAccountsStatus(payload);
        return response;
    }
);

const bulkDeleteBankAccount = createAsyncThunk(
    "delete:api/v1/accounts/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteBankAccounts(payload);
        return response;
    }
);

const restoreBankAccount = createAsyncThunk(
    "put:api/v1/accounts/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreBankAccount(payload);
        return response;
    }
);

const fetchBankAccountsFilters = createAsyncThunk(
    "get:api/v1/accounts/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchBankAccountsFilters(payload);
        return response.data;
    }
);


export {
    fetchBankAccounts,
    createBankAccount,
    updateBankAccount,
    deleteBankAccount,
    searchBankAccounts,
    updateBankAccountStatus,
    bulkDeleteBankAccount,
    restoreBankAccount,
    fetchBankAccountsFilters
};