import HandmadeSkeleton from "../../../utils/skeleton";
import upperCaseString from "../util/upperCaseString";

const TabButton = ({
  callBack,
  displayTabGroups,
  active,
  list,
  allTab,
  className,
  selectedClassName,
  setSelectedRow,
  mainClassName,
  allCount,
  noAllCount,
  loading,
  filterLoading,
}) => {
  let innerList = list ? [...list] : [];
  if (allTab) {
    innerList.push({
      id: null,
      name: "All",
      ...(!noAllCount && {
        count: allCount
          ? allCount
          : list
          ? list.reduce((sum, obj) => sum + obj.count, 0)
          : 0,
      }),
    });
  }
  return (
    <div
      className={`${displayTabGroups} flex flex-row justify-end gap-[16px] ${
        mainClassName ? mainClassName : ""
      }`}
    >
      {innerList.map((key) => (
        <div
          className="flex flex-col gap-0.5"
          onClick={() => {
            if(setSelectedRow){
              setSelectedRow([])
            }
            if (active != key.id && loading !== "pending") callBack(key.id);  
          }}
          key={key.id}
        >
          <div
            className={` font-medium items-center flex flex-row gap-[8px] text-[14px] px-1.5 pt-[8px]  rounded-t-lg   mx-[0px] transition  ${
              active == key.id
                ? "text-blue-600 border-blue-600 font-semibold cursor-default " +
                  selectedClassName
                : "text-gray-400 border-transparent hover:text-gray-600  cursor-pointer " +
                  className
            } ${loading && loading === "pending" ? "!cursor-default" : ""}`}
          >
            {key.icon && <span>{key.icon}</span>}
            <div
              className={`flex items-center ${
                loading === "pending" ? "gap-2" : "gap-1"
              }`}
            >
              <span>{upperCaseString(key.name, "word")}</span>
              <span>
                {key.count || String(key.count) === "0" ? (
                  loading === "pending" || filterLoading === "pending" ? (
                    <HandmadeSkeleton width={30} height={12} />
                  ) : (
                    `${key.count}`
                  )
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          {key.id === active && (
            <div
              className={`w-full h-1 rounded-md ${
                active == key.id
                  ? "bg-blue-600 " + selectedClassName
                  : "bg-gray-400"
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default TabButton;
