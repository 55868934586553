// channels/add-edit-modal.js
// -----------------------------------------

// Import general libraries
import React, {useRef} from "react"
import * as Yup from "yup";
import UI from "../../dynamic-form/elements";


// Import Elements
import DynamicForm from "../../dynamic-form";
import constants from "../../../constant";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import MainModal from "../../dynamic-page/MainModal";
import Toggle from "../../dynamic-form/components/toggle";


// Main Component: ChannelAddEditModal
const ChannelAddEditModal = React.memo(({type, userId, data, onClose, onUpdate, onAdd, show}) => {
    // Handle Form actions
    // initial options for Select
    // -----------------------------------------
    // Country & marketplace

    const formDefaultValue = data?.id ? data : {};

    const createNewRef = useRef(false);
    const updateCreateNew = (value) => {
        createNewRef.current = value;
    };
    const handleCheckboxChange = () => {
        updateCreateNew(!createNewRef.current);
    };


    // Form Json
    const dynamicForm = [
        {
            name: "channel_id",
            defaultValue: formDefaultValue?.channel?.id || formDefaultValue?.channel_id || null,
            label: "Sales Channel",
            placeholder: "amazon.com",
            type: "Select",
            labelClassName: "font-medium text-[14px]",
            isClearable: true,
            features: {
                formatOptionLabel: ({label, logo}) => (
                    <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                    <span
                        className={
                            "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
                        }
                    >
                      <img src={logo?.toLowerCase() || '/assets/images/noImage.png'}/>
                    </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                ),
            },
            api: {
                url: constants.APIURL.GET_CHANNELS + "?filter[designation]=TARGET",
                view: constants.APIURL.GET_CHANNELS,
                query: "&filter[name]={query}&not_deleted=true",
                optionValue: (item) => ({
                    value: item.id,
                    label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                    logo: item?.logo,
                }),
            },
            validation: Yup.string().required("Sales Channel is Required"),
            className: "col-span-4",
        },
        {
            name: "category_id",
            label: "Category",
            placeholder: "Automotive",
            type: "Select",
            features: {
                formatOptionLabel: (child) => (
                    <div className={"flex flex-row items-center gap-[8px]"}>
                        <span className={"text-gray-800"}>{child?.data?.name}</span>
                    </div>
                ),
            },
            api: {
                url: constants.APIURL.GET_CATEGORIES,
                query: "?filter[name]={query}",
                optionValue: (item) => ({
                    value: item.id,
                    label: item.name,
                }),
            },
            validation: Yup.string().required("Category is Required"),
            defaultValue: formDefaultValue?.category?.id || null,
            className: "col-span-2",
        },
        {
            name: "referral_fee",
            label: "Referral Fee",
            placeholder: "ex. 15%",
            type: "Input",
            defaultValue: formDefaultValue?.referral_fee || null,
            // disabled: formDefaultValue?.ucode ? true : false,
            className: "col-span-1",
            validation: Yup.string().required("Referral Fee is required"),
        },
        {
            name: "min_fee",
            label: "Min. Fee",
            placeholder: "ex. 15%",
            type: "Input",
            defaultValue: formDefaultValue?.min_fee || null,
            // disabled: formDefaultValue?.ucode ? true : false,
            className: "col-span21",
            validation: Yup.string().required("Min. Fee is required"),
        },

        {
            name: "set_trshold",
            label: "Set Treshold",
            description: 'Use this setting on if the percentage of the Referral Fee is linked to a price threshold.',
            labelClassName: 'hidden',
            placeholder: "blach",
            type: "Module",
            module: Toggle,
            className: "col-span-4",
            defaultValue: (formDefaultValue?.threshold || formDefaultValue?.over_threshold) ? true : false,
            // optional : true,
        },
        {
            name: "threshold",
            label: "Treshold",
            placeholder: "USD 10",
            type: "Input",
            defaultValue: formDefaultValue?.threshold || null,
            // disabled: formDefaultValue?.ucode ? true : false,
            className: "col-span-2",
            condition: (data) => data?.set_trshold,
            validation: (formData, boundData) => {
                // Generate validation dynamically based on formData
                if (formData?.set_trshold === true) {
                    return Yup.string().required('Threshold is required');
                } else {
                    return Yup.string(); // No validation
                }
            },
        },
        {
            name: "over_threshold",
            label: "Over Treshold ",
            placeholder: "15%",
            type: "Input",
            defaultValue: formDefaultValue?.over_threshold || null,
            // disabled: formDefaultValue?.ucode ? true : false,
            className: "col-span-2",
            condition: (data) => data?.set_trshold,
            validation: (formData, boundData) => {
                // Generate validation dynamically based on formData
                if (formData?.set_trshold === true) {
                    return Yup.string().required('Over Threshold is required');
                } else {
                    return Yup.string(); // No validation
                }
            },
        },


        // {
        //     name: "currency_id",
        //     label: "Currency",
        //     placeholder: "TRY",
        //     type: "Select",
        //     api : {
        //         url: "/api/v1/currencies",
        //         query: "?filter[name]={query}",
        //         optionValue: (item) => ({
        //             value: item.id,
        //             label: upperCaseString(item.code),
        //         })
        //     },
        //     defaultValue: data?.currency?.id || 1 ,
        //     validation: Yup.string().required('Currency is required'),
        //     // disablePreload: true,
        //     // style: {
        //     //     control: {
        //     //         width: "205px",
        //     //     },
        //     //     container: {
        //     //         width: "205px",
        //     //     },
        //     //     menu: {
        //     //         whiteSpace: 'wrap !important',
        //     //         width: "200px"
        //     //     },
        //     // },
        //     className: "col-span-2",
        //     clear: false
        // },
    ]


    const myFormRef = useRef();
    const myFormCallBack = async () => {

        let formData = await myFormRef?.current?.getData();
        // return
        if (formData) {

            if (!data?.id) {
                // onClose()
                if (!createNewRef.current) {
                    // myFormRef.current.reset()
                    // reset all of the fields except the channel_id , also keep the create new checkbox checked

                    const keysToReset = Object.keys(formData).filter(key => key !== "channel_id")

                    onAdd(formData).then((res) => {
                        if (res?.type === 'success') {
                            myFormRef.current.reset(keysToReset)
                        }
                    });
                    // onAdd(formData, false);
                } else onAdd(formData, true)
            } else {
                formData = {
                    ...formData,
                    id: data?.id,
                }
                onAdd(formData, true)
            }
        }


    }


    return (
        <MainModal
            className='max-w-[1000px]'
            item={{
                title: `${!data?.id ? "Create" : "Edit"} Referral Fee by Category`, // If data is null, show "Add New" else "Edit".,
                submit: myFormCallBack,
                cancel: onClose,

                view: (item) => (
                    <div>
                        {
                            <div
                                className="min-w-[500px] grid grid-cols-4 items-start gap-[16px]  justify-center px-[16px] border-t border-t-gray-200 pt-[24px] mt-[10px]">

                                {/* Dynamic form is here */}
                                <DynamicForm items={dynamicForm} ref={myFormRef}
                                             style={{
                                                 label: {className: '!text-gray-900 !font-medium',}
                                             }}
                                />

                            </div>


                        }

                        {/* Form control Buttons are here */}
                        <div
                            className="flex w-full justify-between items-center pt-[8px] mb-[20px] px-[16px] gap-[8px] sticky bottom-0 bg-white">

                            <div className="">
                                <input type="checkbox" id="scales" name="scales"
                                       ref={createNewRef}
                                       onChange={handleCheckboxChange}
                                       disabled={false}
                                       className={"w-[20px] h-[20px] mr-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-md "}

                                />
                                <label for="scales">Create Another</label>
                                {/* <Checkbox
                                    item={{
                                        list : [
                                            {label : "Create New", value : true}
                                        ],
                                        clear: false,
                                        labelClassName: '!text-gray-900 !font-medium',
                                        checkAll : false,
                                    }}
                                    handleChange={handleCheckboxChange}
                                    value={createNewRef.current}
                                    disabled={false}
                                    className={"w-[20px] h-[20px]"}
                                /> */}
                            </div>

                            <div className="flex gap-2 ">
                                <UI.Button
                                    item={{
                                        label: <div className={"flex flex-row"}>Cancel</div>,
                                        buttonType: "alt",
                                        className: "h-[37px] !px-[16px]"
                                    }}
                                    onClick={() => onClose()}
                                />
                                <UI.Button
                                    item={{
                                        label: <div className={"flex flex-row"}>
                                            {data?.id ? "Update" : "Create"}
                                        </div>,
                                        buttonType: "primary",
                                        className: "h-[37px] !px-[16px]"
                                    }}
                                    onClick={myFormCallBack}
                                />
                            </div>


                        </div>
                    </div>
                ),
            }}

        />
    )
})

export default ChannelAddEditModal;
