import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';


const addProductImages = createAsyncThunk(
    'post:api/v1/product/images',
    async ({id, formData}) => {
        // console.log(formData, 'thunk 2nd console');
        const response = await api.addProductImages(id, formData)
        return response
    }
)
const selectMainImage=createAsyncThunk(
    'post:api/v1/product/id/images/imageId',
    async ({id,imageId, formData}) => {
        // console.log(formData, 'thunk 2nd console');
        const response = await api.selectMainImage(id,imageId, formData)
        return response
    }
)
const fetchAttributeGroups = createAsyncThunk(
    'get:api/v1/attribute-groups',
    async (thunkAPI) => {
        const response = await api.fetchAttributeGroups()

        return response.data
    }
)
const fetchAttributes = createAsyncThunk(
    'get:api/v1/attribute',
    async (thunkAPI) => {
        const response = await api.fetchAttributes()

        return response.data
    }
)
const fetchProduct = createAsyncThunk(
    'get:api/v1/product/:id',
    async ({id, productLanguage}, thunkAPI) => {
        const response = await api.fetchProduct(id, productLanguage)

        return response.data;
    }
)
const fetchProducts = createAsyncThunk(
    'get:api/v1/product/',
    async (id, thunkAPI) => {
        const response = await api.fetchProducts(id)

        return response.data;
    }
)
const deleteProductImage = createAsyncThunk(
    'delete:api/v1/product/:id/images/:image',
    async ({id, imageId, payload}) => {
        const response = await api.deleteProductImage(id, imageId, payload)
        return response
    }
)

const UpdateAttribute = createAsyncThunk(
    'post:api/v1/attribute-value/',
    async ({id, attributeId, payload}) => {
        const response = await api.UpdateAttribute(id, attributeId, payload)
        return response;
    }
)

const deleteAttribute = createAsyncThunk(
    'delete:api/v1/attribute-value/',
    async ({id, payload}) => {
        const response = await api.deleteAttribute(id, payload)
        return response;
    }
)


const UpdateProduct = createAsyncThunk(
    'put:api/v1/product/:id',
    async ({id, payload}) => {
        const response = await api.UpdateProduct(id, payload)
        // console.log(response, 'Response')
        return response;
    }
)
const getBrands = createAsyncThunk(
    'get:api/v1/brand',
    async (thunkAPI) => {
        const response = await api.getBrands()

        return response.data
    }
)
const getCategories = createAsyncThunk(
    'get:api/v1/categories',
    async (thunkAPI) => {
        const response = await api.getCategories()

        return response.data
    }
)
const getAttributeTypes = createAsyncThunk(
    'get:api/v1/attribute/types',
    async (thunkAPI) => {
        const response = await api.getAttributeTypes()

        return response.data
    }
)

const getRule = createAsyncThunk(
    'get:api/v1/attribute/rules/types',
    async (type, thunkAPI) => {
        const response = await api.getRule(type)

        return response.data
    }
)

const createAttributes = createAsyncThunk(
    'post:api/v1/attribute',
    async (payload, thunkAPI) => {
        const response = await api.createAttributes(payload)

        return response
    }
)
const deleteProduct = createAsyncThunk(
    'delete:api/v1/product/:id',
    async (id) => {
        const response = await api.deleteProduct(id)
        // console.log(response)
        return response
    }
)


const createProductFromData = createAsyncThunk(
    'post:api/v1/product/from-data-source',
    async (payload, thunkAPI) => {
        const response = await api.createProductFromData(payload)
        // console.log(response, 'in hOuse')
        return response
    }
)


const duplicateProduct = createAsyncThunk(
    'post:api/v1/product/duplicate',
    async (id, thunkAPI) => {
        const response = await api.duplicateProduct(id)
        // console.log(response, 'Duplicate')
        return response
    }
)

const getProductLocale = createAsyncThunk(
    'get:api/v1/languages',
    async (params, thunkAPI) => {
        const response = await api.getProductLocale(params)

        // console.log(response)
        return response
    }
)

const createProduct = createAsyncThunk(
    'post:api/v1/product',
    async (payload, thunkAPI) => {
        const response = await api.createProduct(payload)

        return response
    }
)

const editAttribute = createAsyncThunk(
    'put:api/v1/attribute/:id',
    async ({id, attributeId, payload}) => {
        const response = await api.editAttribute(id, attributeId, payload)
        return response
    }
)

const bulkUploadProduct = createAsyncThunk(
    "post:api/v1/product/bulk-upload",
    async (payload, thunkAPI) => {
        const response = await api.bulkUploadProduct(payload);
        return response;
    }
);


const fetchBundledProducts = createAsyncThunk(
    'get:api/v1/product/bundle',
    async (params, thunkAPI) => {
        const response = await api.fetchProducts(params)

        return response.data;
    }
)


export {
    addProductImages,
    fetchAttributeGroups,
    selectMainImage,
    fetchProduct,
    fetchProducts,
    deleteProductImage,
    UpdateAttribute,
    fetchAttributes,
    UpdateProduct,
    getBrands,
    getAttributeTypes,
    getRule,
    getCategories,
    createAttributes,
    createProductFromData,
    deleteProduct,
    duplicateProduct,
    getProductLocale,
    createProduct,
    editAttribute,
    deleteAttribute,
    bulkUploadProduct,
    fetchBundledProducts,
}

