import * as Yup from "yup";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import ProductImage2 from "../components/productImage2";

const formView = (item) => {

    const innerSubmit = [
        {
            label: item?.id ? "Save" : "Create",
            type: "primary",
            action: "save",
        },
    ];

    return {
        title: upperCaseString(item?.id ? "Edit" : "Add") + " User",
        submit: innerSubmit,
        // close: !item?.id,
        // cancel: !item?.id ? false : true,
        viewModel: (formDefaultValue) => {
            return [
                {
                    name: "first_name",
                    label: "Full Name",
                    placeholder: "Tunahan KORKMAZ",
                    type: "Input",
                    defaultValue: formDefaultValue?.first_name || "",
                    validation: Yup.string().required("Name is required"),
                    className: " my-1   ",
                    innerClassName: "border border-[#D1D5DB] bg-[#F9FAFB] rounded-[8px] w-[300px]"
                },
                {
                    name: "email",
                    label: "Email",
                    placeholder: "tunahankorkmaz@arbitbox.com",
                    type: "Input",
                    defaultValue: formDefaultValue?.email || "",
                    validation: Yup.string().required("Email is required"),
                    className: " my-1",
                    innerClassName: "border border-[#D1D5DB] bg-[#F9FAFB] rounded-[8px]"
                },
                {
                    name: "password",
                    label: "Password",
                    placeholder: "Enter Password",
                    type: "Password",
                    defaultValue: formDefaultValue?.password || "",
                    validation: !formDefaultValue?.id
                        ? Yup.string().required("Password is required")
                        : null,
                    className: " my-1",
                    innerClassName: "border border-[#D1D5DB] bg-[#F9FAFB] rounded-[8px]"
                },
                {
                    name: "password_confirmation",
                    label: "Confirm Password",
                    placeholder: "Confirm Password",
                    type: "Password",
                    defaultValue: formDefaultValue?.password_confirmation || "",
                    validation: (formData) => {
                        // Generate validation dynamically based on formData
                        if (formData?.password !== formData?.password_confirmation) {
                            return Yup.string().required("Password does not match");
                        } else {
                            return Yup.string(); // No validation
                        }
                    },
                    innerClassName: "border border-[#D1D5DB] bg-[#F9FAFB] rounded-[8px]",
                    className: " my-1"
                },
                {
                    name: "image",
                    label: "Upload Avatar",
                    placeholder: "Upload Avatar",
                    type: "Module",
                    module: ProductImage2,
                    className: "col-span-2 my-1",
                    defaultValue: formDefaultValue?.image || "",
                },
            ];
        },
    };


};
export default formView;
