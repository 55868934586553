import axios from "../../../../middleware/axios";
import api from "../../../../store/auth/api";
import hasValue from "../../util/hasValue";

const fetchOptions = async (api, query = "", value, defaultValue, list, formData, defaultBound) => {
    return new Promise((resolve, reject) => {

        const replaceFormData = (value) => {
            let innerValue = value;
            if (formData) {
                Object.keys(formData).forEach((key) => {
                    innerValue = innerValue?.replaceAll("{" + key + "}", formData[key]);
                });
            }
            return innerValue;
        };

        const baseUrl = replaceFormData(api.url?.replaceAll("{query}", query));
        const baseUrl2 = api.url2 ? replaceFormData(api.url2?.replaceAll("{query}", query)) : null;
        const queryUrl = query ? replaceFormData(api.query?.replaceAll("{query}", query)) || "" : "";
        const viewUrl = replaceFormData(api.view?.replaceAll("{query}", query)) || baseUrl;

        const innerValue = mergeArrays(convertToArray(value), convertToArray(defaultValue));

        
        if (baseUrl2) {
            // Fetch data from the first URL
        const fetchBaseUrlData = callApi(baseUrl + queryUrl, api?.skipCancel)
        .then(response => mapApiData(response.data, api.optionValue))
        .catch(error => {
            reject(error);
        });

    // Fetch data from the second URL if available
    const fetchBaseUrl2Data = baseUrl2 ? callApi(baseUrl2 + queryUrl,api?.skipCancel)
        .then(response => mapApiData(response.data, api.optionValue2 || api.optionValue))
        .catch(error => {
            reject(error);
        }) : Promise.resolve([]);
            
            Promise.all([fetchBaseUrlData, fetchBaseUrl2Data]).then(([data1, data2]) => {
                let mergedData = mergeArrays(data1, data2);

                if (hasValue(innerValue) && (!defaultBound)) {
                    const valueItem = {};
                    innerValue.map((val, index) => {
                        if (api?.ignoreView) return;
                        callApi(viewUrl + "/" + val).then((item) => {
                            valueItem[index] = mapApiData(item.data, api.optionValue);
                            if (Object.keys(valueItem).length === innerValue.length) {
                                let valueExtendArray = [];
                                if (value) {
                                    valueExtendArray = Object.values(valueItem).map(i => i[0]);
                                }
                                resolve(
                                    extendByList(
                                        mergeArrays(mergedData, valueExtendArray),
                                        list
                                    )
                                );
                            }
                        });
                    });
                } else {
                    resolve(extendByList(mergedData, list));
                }
            });
        } else { 
            callApi(baseUrl + queryUrl, api?.skipCancel).then((response) => {
                let mappedData = mapApiData(response.data, api.optionValue)
                
                if (hasValue(innerValue) && (!defaultBound)) {
                    if (innerValue?.includes(",")) {
                        innerValue = innerValue.split(",");
                    }
                    const valueItem = {}
                    innerValue.map((val, index) => {
                        // if (api?.ignoreView) return
                        callApi(viewUrl + "/" + val).then((item) => {
                            valueItem[index] = mapApiData(item.data, api.optionValue)
                            if (Object.keys(valueItem).length === innerValue.length) {
                                let valueExtendArray = []
                                if (value) {
                                    valueExtendArray = Object.values(valueItem).map(
                                        (i) => {
                                            return i[0]
                                        }
                                    )
                                }
                                resolve(
                                    extendByList(
                                        mergeArrays(
                                            mappedData,
                                            valueExtendArray
                                        ),
                                        list
                                    )
                                )
                            }
                        })
                    })
    
    
                } else {
                    resolve(
                        extendByList(mappedData, list)
                    )
                }
            })
    
                .catch((error) => {
                    reject(error);
                });
        }
    });
};

const callApi = async (url, skipCancel) => {
    return new Promise((resolve, reject) => {
        axios.get(url, { withCredentials: true, skipCancel: skipCancel })
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const mapApiData = (data, optionValue) => {
    return convertToArray(data).map((res) => (
        {
            ...optionValue(res),
            data: res,
        }
    ));
};

const convertToArray = (value) => {
    if (hasValue(value)) {
        if (Array.isArray(value)) {
            return value;
        } else if (hasValue(value)) {
            return [value];
        }
    } else {
        return [];
    }
};

const extendByList = (data, list) => {
    if (list) {
        return mergeArrays(data, list);
    } else {
        return data;
    }
};

function mergeArrays(arr1, arr2) {
    const merged = arr2;

    for (let i = 0; i < arr1.length; i++) {
        if (!merged.find(item => item.value === arr1[i].value)) {
            merged.push(arr1[i]);
        }
    }


    return merged;
}

export { mergeArrays };
export default fetchOptions;