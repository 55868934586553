import React from 'react'
import {HiInformationCircle} from 'react-icons/hi'
import Util from '../../dynamic-page/util'

function numbers({data, processFunction, list, color, currency}) {
    return (
        <div className={`${(!data || +data === 0) ? "text-gray-500" : color} text-2xl w-[100%] flex items-center gap-1 justify-center
     font-bold leading-normal `}>
            {data != null ? processFunction(data, currency || null) : '-'}
            {(data && +data !== 0 && list) && <Util.popUp
                toggle={(selected) => (
                    <HiInformationCircle className={"text-gray-400 text-[18px]"}/>
                )}
                action={"hover"}
            >
                <div className={"px-[8px] w-[213px] py-[4px] flex flex-col gap-1"}>
                    {
                        list.map((item, index) => (
                            <div key={index} className={"flex  justify-between items-ceter"}>
                                <div className={" text-gray-900 text-sm font-normal leading-[21px]"}>{item?.title}</div>
                                <div className={"text-gray-900 text-sm font-bold leading-[21px]"}>{item?.value}</div>
                            </div>
                        ))

                    }
                </div>
            </Util.popUp>}


        </div>
    )
}

export default numbers