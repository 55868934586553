import calculateColor from "./calculateColor";


const mapData = (innerData) => {


    return innerData?.map((item, index) => {
        let isView = item?._data.isView
        /*if (item?.hasOwnProperty('created_at')) {
            isLocal = false
        }*/

        return {
            ...item,
            status: isView ? item?.status : "no status",
            id: isView ? item?.id : "id_" + (index + 1),
            item_qty_color_code: !isView ? item?.item_qty_color_code : calculateColor(item?.qty),
            total_price: item?.unit_price * item?.qty,
            //unit_price_currency: !isLocal ? item?.unit_price_currency : item?._data?.unit_price_currency?.data,
            //total_price_currency: !isLocal ? item?.total_price_currency : item?._data?.unit_price_currency?.data,
        }
    })

}
export default mapData