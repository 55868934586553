// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./models-view/table";
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tabsView from "./models-view/tabs";
import actionList from "./models-view/actions";

// Main Component
const ProductSummary = () => {
  // Initial Page
  const Page = usePage({
    pageTitle: "Order & Supply Summary",
    api: {
      fetch: "product-summary",
      filters: "product-summary/filters",
      query: "include=brand.assigned",
      defaultSort: "-restocking_qty",
    },
    ModelsView: {
      table: tableView,
      filter: filtersView,
      sort: sortView,
      tabs: tabsView,
      actions: actionList,
      // headerOption: OptionsView,
    },
    pageSettings: {
      headerStyles: {
        "order count": {
          className: " !justify-end",
        },
        "t. ordered": {
          className: "   !justify-end",
        },
        "s. requested": {
          className: "   !justify-end",
        },
       "s. purchased": {
          className: "   !justify-end",
        },
        "in stock": {
          className: "   !justify-end",
        },
        "resupply": {
          className: "   !justify-end",
        },
      },
      hideSelect: (pageData) =>
        pageData?.filters?.filters?.summaryType !== "resupply",
      hiddenActions: true,
      ignoredFilters: ["summaryType"],
      mediaQuery: (
        <style>
          {`
                             @media (768px <= width <= 1090px) {
                                .content .contt {
                                    height: calc(100vh - 257px) !important;
                                }
                            }
                            @media (max-width: 768px) {
                                .content .contt {
                                    height: calc(100vh - 340px) !important;
                                }
                            }
                    `}
        </style>
      ),
      allowExport: (pageData) =>
        pageData?.filters?.filters?.summaryType === "resupply",
      exports: (pageData, selectedRow) => ({
        exportMapper: (data) => {
          return data?.length
            ? data?.map((item) => {
                return {
                  APID: item?.product?.apid || "-",
                  "Product Title": item?.product?.title || "-",
                  "Ordered Qty": item?.total_ordered_product_qty,
                  "Supply Requested Qty": item?.total_supply_requested_qty,
                  "Supply Purchased Qty": item?.total_supply_purchased_qty,
                  "In Stock Qty": item?.total_inventory_qty,
                  "Suggested Resupply Qty": item?.restock_qty,
                  SKUS: item?.skus?.join(", ") || "-",
                };
              })
            : [];
        },
        exportData: selectedRow?.length > 0 ? selectedRow : pageData?.rows,

        headers: [
          "APID",
          "Product Title",
          "Ordered Qty",
          "Supply Requested Qty",
          "Supply Purchased Qty",
          "In Stock Qty",
          "Suggested Resupply Qty",
          "SKUS",
        ],
      }),
    },
  });

  // return View
  return Page;
};

export default ProductSummary;
