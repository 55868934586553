import React, {forwardRef, memo} from 'react';

import DynamicForm from "../../../../../dynamic-form";
import summaryItemsForm from "./../model-view/summaryItemsForm";
import summaryEditFormStyle from "./../model-view/summaryEditFormStyle";
import summaryEditDescription from "./../model-view/summaryEditDescription";

const SummaryEdit = forwardRef((props, ref) => {

    let {orderItems, summaryForm, callBack, actionCallBack} = props;


    const formItems = summaryItemsForm(summaryForm)

    const description = summaryEditDescription(summaryForm, orderItems, actionCallBack)
    return (<div className='flex flex-col px-[16px] mb-[16px] '>
            {/* summary content */}
            <DynamicForm onChange={callBack} items={formItems} style={summaryEditFormStyle} ref={ref}/>

            <div className='flex flex-col  gap-[4px] pb-[32px] '>
                {description.map((item, index) => (
                    <div key={index}>

                        <div key={index} className='flex flex-row  items-start pt-[8px] justify-between w-full'>
                                <span
                                    className='select-none flex text-gray-700 text-[16px] font-semibold justify-start items-start '>
                                    {item?.label}
                                </span>
                            <span className={"font-normal text-[16px] text-gray-700"}>{item?.theme(item?.value)}</span>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
});

export default memo(SummaryEdit);


