import upperCaseString from "../../dynamic-page/util/upperCaseString";
import { useSelector } from "react-redux";
import React from "react";

const FiltersView = (selectedFilers) => {
  const filtersMeta = useSelector((state) => state.supplyPurchase?.filtersMeta);

  return [
    {
      name: "product_id",
      label: "Product",
      type: "Select",
      api: {
        url: "/api/v1/product",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.title, "word", true),
        }),
      },
      features: {
        isMulti: true,
      },
      reset: true,
      clear: true,
      toggle: true,
      style: {
        control: {
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      placeholder: "Filter by products",
      defaultValue: selectedFilers?.product_id,
    },
    {
      name: "brand_id",
      label: "Brand",
      placeholder: "Select Brand",
      type: "Select",
      api: {
        url: "/api/v1/brands",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.brand_id || null,
      toggle: true,
      clear: true,
    },
    {
      name: "assign_id",
      label: "Assigned Employee",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users?filter[status]=1",
        view: "/api/v1/employees",
        skipCancel: true,
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.employee_id || item?.id,
          label: item?.name,
          avatar: item?.image_url || item?.profile_image,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.assign_id,
      toggle: true,
      clear: true,
    },
    {
      name: "purchase_date",
      label: "Purchase Date",
      placeholder: "Filter by purchase date",
      type: "DateRange",
      defaultValue: selectedFilers?.purchase_date,
      toggle: true,
      clear: true,
    },
    {
      name: "created_by",
      label: "Created By",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users?filter[status]=1",
        skipCancel: true,
        view: "/api/v1/employees",
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.employee_id || item?.id,
          label: item?.name,
          avatar: item?.image_url || item?.profile_image,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.created_by,
      toggle: true,
      clear: true,
    },
    {
      name: "sub_total",
      label: "Sub Total",
      placeholder: "Filter by sub total",
      type: "Range",
      defaultValue: selectedFilers?.sub_total,
      toggle: true,
      clear: true,
    },
    {
      name: "quantity",
      label: "Quantity",
      placeholder: "Filter by quantity",
      type: "Range",
      defaultValue: selectedFilers?.quantity,
      toggle: true,
      clear: true,
    },
    {
      name: "purchase_price",
      label: "Purchase Price",
      placeholder: "Filter by purchase price",
      type: "Range",
      defaultValue: selectedFilers?.purchase_price,
      toggle: true,
      clear: true,
    },
    {
      name: "acceptable_quantity",
      label: "Acceptable Quantity",
      placeholder: "Filter by acceptable quantity",
      type: "Range",
      defaultValue: selectedFilers?.acceptable_quantity,
      toggle: true,
      clear: true,
    },
    {
      name: "supplier_id",
      label: "Supplier",
      placeholder: "Filter by Supply Channel",
      type: "Select",
      api: {
        url: "/api/v1/channel",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          logo: item?.logo,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img src={logo || "/assets/images/img-placehoder.png"} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.supplier_id,
      toggle: true,
      clear: true,
    },
    {
      name: "reason",
      label: "Reason",
      placeholder: "Filter by Reason",
      type: "RadioButton",
      list: [
        {
          label: "Order",
          value: "order",
        },
        {
          label: "Warehouse Stock",
          value: "warehouse_restocking",
        },
        {
          label: "Fulfilment Stock",
          value: "fulfilment_restocking",
        },
      ],

      defaultValue: selectedFilers?.reason,
      toggle: true,
      radioContainerClass: "flex",
    },
    {
      name: "supply_channel_type",
      label: "Supply Channel Type",
      placeholder: "Filter by Supply Channel Type",
      type: "RadioButton",
      list: [
        {
          label: "WEBSITE",
          value: "WEBSITE",
        },
        {
          label: "WHOLESALER",
          value: "WHOLESALER",
        },
        {
          label: "MANUFACTURER",
          value: "MANUFACTURER"
        }
      ],
      defaultValue: selectedFilers?.supply_channel_type || null,
      toggle: true,
      radioContainerClass: "flex",
    },
  ];
};

export default FiltersView;
