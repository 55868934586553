import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchOpportunities = createAsyncThunk(
    'get:api/v1/opportunities',
    async (params, thunkAPI) => {
        const response = await api.fetchOpportunities(params)
        // console.log(response)
        return response.data
    }
)

const showOpportunity = createAsyncThunk(
    'get:api/v1/opportunities/:id',
    async (params, thunkAPI) => {
        const response = await api.showOpportunity(params)
        // console.log(response)
        return response.data
    }
)


const updateOpportunities = createAsyncThunk(
    'post:api/v1/opportunities/refresh',
    async (payload, thunkAPI) => {
        const response = await api.updateOpportunities(payload)

        return {data: response.data, status: response.status}
    }
)
const updateBulkOpportunities = createAsyncThunk(
    'post:api/v1/opportunities/bulk-refresh',
    async (payload, thunkAPI) => {
        const response = await api.updateBulkOpportunities(payload)

        return response.data
    }
)

const fetchOpportunitiesFilters = createAsyncThunk(
    'get:api/v1/opportunities/filters',
    async (thunkAPI) => {
        const response = await api.fetchOpportunitiesFilters()
        //console.log(response.data, '----OppurtunityFilter Response')
        return response.data
    }
)

const hideOpportunitieID = createAsyncThunk(
    'put:api/v1/opportunities/hide',
    async (payload, thunkAPI) => {
        const response = await api.hideOpportunitieID(payload)
        // console.log(response, '----OppurtunityFilter Response')
        return response.data
    }
)

const hideOpportunitieBrand = createAsyncThunk(
    'put:api/v1/opportunities/brand/hide',
    async (payload, thunkAPI) => {
        const response = await api.hideOpportunitieBrand(payload)
        // console.log(response, '----OppurtunityFilter Response')
        return response.data
    }
)

const unhideOpportunitieID = createAsyncThunk(
    'put:api/v1/opportunities/unhide',
    async (payload, thunkAPI) => {
        const response = await api.unhideOpportunitieID(payload)
        // console.log(response, '----OppurtunityFilter Response')
        return response.data
    }
)

const unhideOpportunitieBrand = createAsyncThunk(
    'put:api/v1/opportunities/brand/unhide',
    async (payload, thunkAPI) => {
        const response = await api.unhideOpportunitieBrand(payload)

        return response.data
    }
)

const fetchCategories = createAsyncThunk(
    'get:api/v1/category',
    async (thunkAPI) => {
        const response = await api.fetchCategories()

        return response.data
    }
)
const postOppurtunityNotes = createAsyncThunk(
    `opportunities/:id/notes`,
    async ({id, payload}) => {
        const response = await api.postOppurtunityNotes(id, payload)


        return response

    }
)
export {
    fetchOpportunities,
    updateOpportunities,
    updateBulkOpportunities,
    fetchOpportunitiesFilters,
    hideOpportunitieID,
    hideOpportunitieBrand,
    unhideOpportunitieID,
    unhideOpportunitieBrand,
    fetchCategories,
    postOppurtunityNotes,
    showOpportunity
}
