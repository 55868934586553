import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";

import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";

import filtersView from "./models-view/filters";
import tableView from "./models-view/table";
// import formView from "./models-view/form";
import CreateShipments from "./models-view/create-shipment";
import zplPrint from "./../print-page/zpl";

// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";

import {
  fetchTransfers as fetchData,
  updateRow as updateSingleRow,
  fetchSingleTransfer,
  updateBoundFilter,
  updateFilter,
  fetchTransfersFilters as fetchFilters,
} from "../../store/transfers";

import getActionList from "../dynamic-page/util/getActionList";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import SearchInput from "../dynamic-page/debt/searchInput";
import useLoadMoreData from "../../utils/useLoadMoreData";
import sortView from "./models-view/sort";
import useCreateShipmentInitializer from "./models-view/create-shipment/createShipmentInitializer";
import TabButton from "../dynamic-page/elements/TabButton";
import TransfersModal from "./models-view/create-transfer";
import FormModal from "../dynamic-page/FormModal";
import formView from "./models-view/method";
import { useLocation, useNavigate } from "react-router-dom";
import { joinArray } from "../../utils/helper";
import moment from "moment";
const Transfers = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pageData = useSelector((state) => state.transfers);
  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      filters: {
        status: pageData?.filters?.filters?.status===null ?null:1,
      },
      includes:
        "fromWarehouse.state.country,destination.state.country,shipmentLabels.internationalCarrier",
      sort: null,
      page: 1,
      search: "",
      limit: 20,
    };

    
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );

    const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);


    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );

    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters());
  }, [location]);

  // Get data from store.
  
  
  const tableData = useSelector((state) => state.transfers?.transfers || []);

  const [selectedRow, setSelectedRow] = useState(null);

  // Handle form modal.
  const [modalVisibility, setModalVisibility] = useState(false);
  const [calledItemInAction, setCalledItemInAction] = useState(null);
  const [statesModalVisibility, setStatesModalVisibility] = useState(false);
  const [formData, setFormData] = useState({});
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  const handleUpdateSingleRow = async (transfer, res) => {
    let ignored = [];
    if (res) {
      const temp_status = pageData?.transfers?.find(
        (item) => item?.id === res?.id
      )?.status;
      dispatch(
        updateSingleRow({
          path: `transfers.[id:${res?.id}]`,
          value: {
            ...res,
            _updatedRow: true,
          },
        })
      );
      // remove the updated row after 5 seconds
      setTimeout(() => {
        dispatch(
          updateSingleRow({
            path: `transfers.[id:${res?.id}]._updatedRow`,
            value: false,
          })
        );
      }, 5000);
      if (temp_status === res?.status) {
        ignored.push(res?.id);
      }
    } else {
      const temp_status = pageData?.transfers?.find(
        (item) => item?.id === transfer[0]
      )?.status_id;
      await dispatch(fetchSingleTransfer(transfer[0])).then((transfer) => {
        if (transfer?.payload?.data?.status_id === temp_status) {
          ignored.push(transfer?.payload?.data?.id);
        }
        dispatch(
          updateSingleRow({
            path: `transfers.[id:${transfer?.payload?.data?.id}]`,
            value: {
              ...transfer?.payload?.data,
              _updatedRow: true,
            },
          })
        );
        // remove the updated row after 5 seconds
        setTimeout(() => {
          dispatch(
            updateSingleRow({
              path: `transfers.[id:${transfer?.payload?.data?.id}]._updatedRow`,
              value: false,
            })
          );
        }, 5000);
      });
    }

    return ignored;
  };
  const handleRemoveItemFromList = async (orderId, color, res) => {
    const orderItemId = orderId?.id ? [orderId?.id] : orderId;

    const ignoredOrders = await handleUpdateSingleRow(orderItemId, color, res);
    orderItemId.forEach((item) => {
      if (
        pageData?.filters?.filters?.status !== null &&
        !ignoredOrders.includes(item)
      ) {
        setTimeout(() => {
          dispatch(
            updateSingleRow({
              path: `transfers.[id:${item}]._deletedRow`,
              value: true,
            })
          );
          setTimeout(() => {
            dispatch(updateSingleRow({ path: `transfers.[id:${item}]` }));
          }, 1000);
          setSelectedRow([]);
        }, 3000);
      }
    });

    // updat the filters
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {});
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");
    dispatch(fetchFilters());
  };

  const [update, setUpdate] = useState(false);
  useEffect(() => {
    if (update && !modalVisibility) {
      handleUpdateSingleRow(update?.id, update);
      setUpdate(false);
    }
  }, [update, modalVisibility, calledItemInAction]);

  const rowUpdated = useCallback(
    (res, data) => {
      setUpdate(res, data);
    },
    [update] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Initial action list.
  const actionList = {
    edit: {
      label: "Update",
      onClick: (item) => {
        const target = item?.id ? item : selectedRow[0];
        setFormData(target);
        setModalVisibility(target?.type);
      },
      bulkDisable: true,
    },
    delete: {
      label: <span className={"text-red-600"}>Delete</span>,
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to delete the selected transfer/s?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const ids = item?.id
              ? item?.id
              : selectedRow[0]?.id
            callEndpoint({
              title: "Delete Transfer",
              url: `transfers/${ids}`,
              method: "DELETE",
            }).then((res) => {
              if (res.type === "success") {
                dispatch(fetchFilters());
                setConfirmationModalVisibility(false);

                [ids].map((id) => {
                  dispatch(
                    updateSingleRow({
                      path: `transfers.[id:${id}]._updatedRow`,
                      value: true,
                    })
                  );
                  setTimeout(() => {
                    dispatch(
                      updateSingleRow({
                        path: `transfers.[id:${id}]._deletedRow`,
                        value: true,
                      })
                    );
                    setTimeout(() => {
                      dispatch(
                        updateSingleRow({ path: `transfers.[id:${id}]` })
                      );
                    }, 500);
                  }, 3000);
                });
                setSelectedRow([]);
              }
            });
          },
        });
      },
      condition: (data) => data?.status_id == 1,
      bulkDisable: true,
    },
    createShipment: {
      label: "Create Shipment",
      bulkDisable: true,
      onClick: (item) => {
        setCalledItemInAction({
          id: item?.id,
          items_qty: item?.items_qty,
          from: item?.from_warehouse,
          to: item?.to_warehouse,
          billable_weight: item?.billable_weight,
        });
        setModalVisibility("createShipment");
      },
    },
    markAsShipped: {
      label: "Mark as Shipped",
      onClick: (item) => {
        // setConfirmationModalVisibility(true);
        setConfirmationModalVisibility({
          infoText:
            "Are you sure you want to mark the selected transfer/s as shipped?",
          confirmText: "Yes, Mark as Shipped",
          cancelText: "No, Cancel",
          callBack: (setSubmit) => {
            callEndpoint({
              title: "Mark as Shipped",
              url: `transfers/${item?.id || item}/mark-as-shipped`,
              method: "Put",
            }).then((res) => {
              if (res.type === "success") {
                setSubmit(true);
                setSelectedRow([]);
                onPaginationApply();
                dispatch(fetchFilters());
                setConfirmationModalVisibility(null);
              }
            });
          },
        });
      },
      condition: (data) => data?.status_id === "2",
    },
    printLabel: {
      label: "Print Label",
      // bulkDisable: true,
      onClick: (item) => {
        const fileUrl = [item?.file];
        // const fileUrl = result?.payload?.data?.data?.map(
        //   (item) => item?.label_file_png
        // );
        fileUrl.forEach((item) => {
          zplPrint.getAndSendFileToPrinter(item);
        });
      },
      condition: (data) => false,
    },

    viewLabel: {
      label: "View Label",
      bulkDisable: true,
      onClick: (item) => {
        const fileUrl = [item?.file];
        util.pdfViewer(fileUrl, false, `Shipment Label`);
      },
      condition: false,
    },
  };

  const handleCallBackActions = ({ action, data }) => {
    actionList[action].onClick(data);
  };

  //   // Initial status list
  const statusList = {
    null: {
      label: "",
      actions: [actionList.edit, actionList.markAsShipped, actionList.delete],
    },
  };

  //   // handle action of statuses
  const actions = (row) => {
    return { ...getActionList(row, statusList, "none"), count: row?.length };
  };

  const { initializeShipment } = useCreateShipmentInitializer();
  const handleSaveShipment = (carrier) => {
    initializeShipment(carrier, (data) => {
      if (data.type === "success") {
        handleRemoveItemFromList({ id: data?.data?.id });
        setModalVisibility(null);
      }
    });
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };

  const onHandleTab = (id) => {
    setSelectedRow([]);
    onFilterApply({ status: id });
  };
  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);
    // --------------------------------------------------
    // when search is not available in url
    // --------------------------------------------------
    // const currentStoreFilters = pageData?.filters?.filters
    // onFilterApply({...currentStoreFilters, "name": search})

    // --------------------------------------------------
    // when search is available in url
    // --------------------------------------------------
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: currentStoreFilters?.filters,
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(updateFilter(storeQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });

    const filterStoreQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    const filterUrlQuery = urlQueryBuilder(filterStoreQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(filterUrlQuery));
    dispatch(updateFilter(storeQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery);

    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      page: page || currentStoreFilters.page || 1,
      limit: limit || currentStoreFilters?.limit || 20,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");
    const filters = { ...currentStoreFilters?.filters };
    if (filters.status != null) filters.status = null;

    const filterStoreQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const filterUrlQuery = urlQueryBuilder(filterStoreQuery, null, "term", "");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(filterUrlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onSubmitApply = async (data, clear) => {
    const payload = {
      ...data,
      destination_id: data?.destination?.split("|")[1],
      destination_type: data?.destination?.split("|")[0],
    };
    if (!data?.id) {
      callEndpoint({
        title: "Create Transfer",
        url: "transfers",
        method: "post",
        data: payload,
        pureData: true,
      }).then((rs) => {
        if (rs?.type === "success") {
          onPaginationApply();
          clear();
          setModalVisibility(null);
        }
      });
    }
  };

  const exportMapper = (data) => {
    return data?.length
      ? data?.map((item) => {
          return {
            'Transfer Type': item?.type || "-",
            'Transfer ID': item?.code || "-",
            Name : item?.name || "-",
            Status : item?.status || "-",
            "Shipping Content": item?.content || "-",
            "Billable Weight": item?.billable_weight || "-",
            "Actual Weight": item?.actual_weight || "-",
            "Dimensional Weight": item?.dimensional_weight || "-",
            "From Warehouse": item?.from_warehouse?.name || "-",
            "Destination Warehouse": item?.destination?.name || "-",
            'Items Count': item?.transfer_items_count || "-",
            "Primary Shipment Label ID":
              item?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.id || "-",
            "Primary Shipment Label Carrier Name":
              item?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.international_carrier
              ?.name || "-",
            "Primary Shipment Label Carrier Service Name":
              item?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.service_name || "-",
            "Primary Shipment Label Tracking Number":
              item?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.tracking_number || "-",
            "Primary Shipment Label Tracking URL":
              item?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.tracking_url || "-",
            "Primary Shipment Label Label File":
              item?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.label_file_pdf || "-",
          };
        })
      : [];
  };

  const headers = [
    'Transfer Type',
    'Transfer ID',
    'Name',
    'Status',
    'Shipping Content',
    'Billable Weight',
    'Actual Weight',
    'Dimensional Weight',
    'From Warehouse',
    'Destination Warehouse',
    'Items Count',
    'Primary Shipment Label ID',
    'Primary Shipment Label Carrier Name',
    'Primary Shipment Label Carrier Service Name',
    'Primary Shipment Label Tracking Number',
    'Primary Shipment Label Tracking URL',
    'Primary Shipment Label Label File',
  ];

  const mappedStatuses = tableData?.map((item) => ({
    ...item,
    status: item?.status,
  }));

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          onSearch={onSearchApply}
          title={"Transfers"}
          form={{
            callback: (rs) => {
              setModalVisibility("method");
            },
          }}
          sort={{
            items: sortView({ callback: onSortApply }),
            selected: pageData?.filters?.sort,
          }}
          allowExport={true}
          loading={pageData?.loading}
          exported={{
            exportData: selectedRow?.length > 0 ? selectedRow : tableData,
            exportMapper: exportMapper,
            headers: headers,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: ["status"],
            items: filtersView(
              pageData?.filters?.filters,
              pageData?.filtersMeta,
              pageData?.boundFilters
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.status == undefined ? 0 : 1),
          }}
          actionList={actions(selectedRow)}
        />

        <ArbitProductPage.Tab>
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            // filterLoading={pageData?.filterLoading}
            loading={pageData?.loading}
            // displayTabGroups={displayTabGroups}
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.status}
            //list={pageData?.filtersMeta?.data?.data?.statuses}
            list={[
              {
                id: 1,
                name: "Draft",
                count:
                  pageData?.filtersMeta?.statuses?.find(
                    (item) => item?.id === 1
                  )?.count ?? 0,
              },
              {
                id: 2,
                name: "Ready To Ship",
                count:
                  pageData?.filtersMeta?.statuses?.find(
                    (item) => item?.id === 2
                  )?.count ?? 0,
              },
              {
                id: 3,
                name: "Shipped",
                count:
                  pageData?.filtersMeta?.statuses?.find(
                    (item) => item?.id === 3
                  )?.count ?? 0,
              },
            ]}
            allCount={
              pageData?.filtersMeta?.statuses?.reduce(
                (a, b) => a + (b?.count || 0),
                0
              ) ?? 0
            }
            allTab={true}
          />
        </ArbitProductPage.Tab>

        {/* Table is here. */}
        <ArbitProductPage.Content className="content">
          <style>
            {`
                             @media (768px <= width <= 1000px) {
                                .content .contt {
                                    height: calc(100vh - 270px) !important;
                                }
                            }
                            @media (max-width: 768px) {
                                .content .contt {
                                    height: calc(100vh - 334px) !important;
                                }
                            }
                            @media (1000px <= width <= 1300px) {
                                .content .contt {
                                    height: calc(100vh - 244px) !important;
                                    // on other browsers should be 230px
                                    
                                }
                            }
                    `}
          </style>

          <DynamicTable
            view={tableView}
            data={tableView(
              mappedStatuses,
              handleCallBackActions
              //   pageData?.filters?.filters?.status === 3,
            )}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            loading={pageData.loading}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            style={{
              // hideSelect: true,
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              outerContainer: "min-w-full",
              header: {
                th: "!text-[14px]  !font-semibold ",
                className:
                  "justify-start pl-[8px] pr-[8px] !h-[38px] !text-[14px] font-semibold",
                "tracking id": { className: "!pr-[30px]" },
                "created at": {
                  className: "!justify-start !pl-[8px] !pr-[8px]",
                },
                "transfer id": {
                  className: "!justify-start !pl-[16px] !pr-[16px]",
                },
                qty: {
                  className: "!justify-center",
                },
                weight: {
                  className: "!justify-center",
                  th: "!w-[96px]",
                },
                route: {
                  th: "!min-w-[157px]",
                },
                name: {
                  th: "w-[100%] !max-w-[260px]",
                },
                image: { className: "hidden" },
                status: { className: "hidden" },
                control: { className: "!py-[0px] hidden" },
              },
              row: {
                className:
                  "!px-[8px] !pr-2 !pl-2 !py-[0px] !items-center !h-[62px] !justify-center flex text-[12px] !whitespace-normal",
                td: "!h-[64px]",
                "tracking id": {
                  // className: "!pr-[30px]",
                  td: "w-[100%] min-w-[280px]",
                },
                "created at": {
                  className: " !px-3 ",
                },
                "transfer id": {
                  className: " !px-4",
                },
                route: {
                  className: " !px-2",
                },
                qty: {
                  className: "!w-[62px] ",
                },
                weight: {
                  className: " !px-3 !w-[96px]",
                  td: "!w-[96px]",
                },
                image: { className: "!py-[0px]" },
                "order id": { className: "!justify-start " },
                "ship to": { className: "!justify-start !items-start" },
                name: {
                  td: "w-[100%] !max-w-[260px]",
                  className: "!justify-start",
                },
                _actions: {
                  className: "!py-[0px]",
                  td: "!w-[84px]",
                  innerClass: "!gap-0",
                },
              },
            }}
          />
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
          onPrevious={onHandlePrevious}
          onNext={onHandleNext}
          onPage={onHandlePage}
          loading={pageData.loading}
          onLimit={onHandleLimit}
          resultsText={"transfers"}
        />
      </ArbitProductPage.Page>

      {/* Form Modal is here. */}
      {/* {formModalVisibility && (
        
      )} */}

      {modalVisibility === "createShipment" && (
        <div className={"!text-[14px]"}>
          {/* this component calling from here and shipment label, in 2 place */}
          <CreateShipments
            order={calledItemInAction}
            cancel={() => {
              setCalledItemInAction(null);
              setModalVisibility(null);
            }}
            awaiting_transfer={
              pageData?.filtersMeta?.data?.data?.statuses?.find(
                (item) => item?.name === "Awaiting Transfer"
              )?.count
            }
            submit={(data) => {
              handleSaveShipment(data, calledItemInAction);
            }}
          />
        </div>
      )}

      {modalVisibility === "method" && (
        <FormModal
          title={"Create Transfer"}
          noPrefix={true}
          // editPrefix="Update"
          formView={formView}
          visibility={modalVisibility === "method"}
          // data={formData}
          onClose={() => {
            // setFormData(null);
            setModalVisibility(null);
          }}
          // createNew={ true}
          // className="w-[600px]"
          onSubmit={onSubmitApply}
          buttonsContainer="!px-0  !pt-0  !pb-3"
          saveText="Create"
          // containerClassName="!max-w-[668px]"
          headerClassName="!p-4"
          container={(child) => (
            <>
              <div
                className={` w-[668px] grid  grid-cols-2 mb-4 items-start gap-y-4 gap-x-2  justify-center  border-t border-t-gray-200 pt-[16px]`}
              >
                {child}
              </div>
            </>
          )}
        />
      )}

      {(modalVisibility === "order" || modalVisibility === "product") && (
        <div className={"!text-[14px]"}>
          <TransfersModal
            transfer={formData}
            cancel={() => {
              // handleUpdateSingleRow(calledItemInAction)
              setFormData(null);
              setModalVisibility(null);
            }}
            type={modalVisibility}
            submit={() => {
              setFormData(null);
              setModalVisibility(null);
              onPaginationApply();
            }}
            update={(data, res) => {
              rowUpdated(res, data);
            }}
            createShipment={(data) => {
              actionList.createShipment.onClick({
                id: data?.id,
                items_qty: data?.items_qty,
                from_warehouse: {
                  state: {
                    id: data?.from_warehouse?.state_id,
                  },
                },
                to_warehouse: {
                  state: {
                    id: data?.destination?.state_id,
                  },
                },
                billable_weight: data?.billable_weight,
              });
            }}
          />
        </div>
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this transfer as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default Transfers;
