import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchCarriers = createAsyncThunk(
    "get:api/v1/carrier",
    async (payload, thunkAPI) => {
        const response = await api.fetchCarrier(payload);
        return response;
    }
);

const createCarrier = createAsyncThunk(
    "post:api/v1/carrier",
    async (payload, thunkAPI) => {
        const response = await api.createCarrier(payload);
        return response;
    }
);

const updateCarrier = createAsyncThunk(
    "put:api/v1/carrier",
    async ({id, payload}) => {
        const response = await api.updateCarrier(id, payload);
        return response;
    }
);

const deleteCarrier = createAsyncThunk(
    "delete:api/v1/carrier",
    async (payload, thunkAPI) => {
        const response = await api.deleteCarrier(payload);
        return response;
    }
);

const searchCarriers = createAsyncThunk(
    "get:api/v1/carrier/search",
    async (payload, thunkAPI) => {
        const response = await api.searchCarrier(payload);
        return response.data;
    }
);

const fetchCarriersFilters = createAsyncThunk(
    "get:api/v1/carrier/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchCarriersFilters(payload);
        return response;
    }
);

const bulkDeleteCarriers = createAsyncThunk(
    "delete:api/v1/carrier/delete/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteCarriers(payload);
        return response;
    }
);

const bulkStatusUpdateCarriers = createAsyncThunk(
    "put:api/v1/carrier/status/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkStatusUpdateCarriers(payload);
        return response;
    }
);

const restoreCarriers = createAsyncThunk(
    "post:api/v1/carrier/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreCarriers(payload);
        return response;
    }
);


export {
    fetchCarriers,
    createCarrier,
    updateCarrier,
    deleteCarrier,
    searchCarriers,
    fetchCarriersFilters,
    bulkDeleteCarriers,
    bulkStatusUpdateCarriers,
    restoreCarriers
};
