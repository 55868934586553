import { useState, useEffect } from "react";
import Util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import descriptiveContent from "../../../utils/descriptiveContent";
import UI from "../../dynamic-form/elements";

const Note = ({ item, callBack ,icon}) => {
  const activeStyle = (status) => {
    return status ? "!text-blue-500" : "text-gray-300 hover:text-gray-500";
  };

  // Ensure note state is updated when item changes
  useEffect(() => {
    setNote(item?.note || "");
  }, [item]);

  const [note, setNote] = useState(item?.note || "");
  const [isEditClicked, setEditClicked] = useState(true);

  const onSubmit = (e) => {
    e.preventDefault();
    if (item?.note !== note) {
      callBack({ id: item?.id, note: note ? note : null });
    }
    setEditClicked(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  return (
    <div>
      <Util.oldPopUp
        toggle={() => (
          <div className="flex justify-start gap-1.5 items-center">
            {descriptiveContent(
              <div>
                <UI.Button
                  item={{
                    buttonType: "flat",
                    className: `p-[2px] ${activeStyle(!!item?.note)}`,
                  }}
                >
                  {icon ?  icon:<Icons.internalNote className="w-[18px] h-[18px]" />}
                </UI.Button>
              </div>,
              item?.note ? item?.note : "Add Note",
              null,
              "top"
            )}
          </div>
        )}
        action="click"
        forcePosition={"bottom"}
      >
        <div className="flex flex-col gap-2 py-1 px-2 z-[999999] w-[300px]">
          <div className="flex items-center justify-between">
            <span className="text-[14px] font-medium leading-[21px] text-gray-900">
              Note
            </span>
            <button
              type="button"
              onClick={() => setEditClicked(!isEditClicked)}
            >
              <Icons.pencilAlt className="w-4 h-4 text-gray-400" />
            </button>
          </div>
          {!isEditClicked ? (
            <span
              className="text-[12px] max-h-[200px] overflow-y-auto leading-[15px] block"
              style={{
                maxWidth: "260px",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {item?.note}
            </span>
          ) : (
            <form
              onSubmit={onSubmit}
              className="border border-gray-300 bg-gray-50 flex items-start pr-3 rounded-[4px] py-0.5"
            >
              <textarea
                className="resize-none border-none pl-2 bg-gray-50 text-[13px] leading-[18px] text-gray-900 placeholder-gray-500 rounded-[4px] h-[65px] w-[260px]"
                placeholder="Type note..."
                value={note}
                autoFocus
                onChange={(e) => setNote(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button type="submit" className="pt-2.5">
                <Icons.check className="w-3 h-3 text-blue-600" />
              </button>
            </form>
          )}
        </div>
      </Util.oldPopUp>
    </div>
  );
};

export default Note;
