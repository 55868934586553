import React from 'react';
import store, {persistor} from './store';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import 'intro.js/introjs.css';
import withClearCache from './ClearCache';


const root = ReactDOM.createRoot(document.getElementById('root'));
const CachedApp = withClearCache(App);
root.render(
    <>
        {/* <React.StrictMode>*/}
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <CachedApp/>
            </PersistGate>
        </Provider>
        {/*</React.StrictMode>*/}
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
