import React, {useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

const CustomAsyncSelect = ({
                               url,
                               mapOptions,
                               customStyles,
                               defaultParams,
                               onInputChange,
                               onChange,
                               placeholder,
                               isDisabled,
                               product,
                               features,
                               defaultOptions,
                               noDefault
                           }) => {
    const fetchProducts = async (params) => {
        let finalUrl = url
        if (params)
            finalUrl = finalUrl.concat(params)
        // if (defaultParams)
        //     finalUrl = finalUrl.concat(defaultParams)
        const response = await axios.get(finalUrl, {withCredentials: true});
        return response.data
    }


    const [defaultData, setDefaultData] = useState(null)

    // useEffect(() => {
    //     if (!defaultOptions) {
    //         fetchProducts()
    //         .then(response => {
    //             let options = mapOptions(response.data);
    //             setDefaultData(options)
    //         })
    //         .catch(error => {});
    //     }
    // }, [])

    const [input, setInput] = useState('')
    const debouncedSetInput = debounce((input) => {
        setInput(input)
    }, 300)

    const debouncedLoadOptions = debounce(async (inputValue, callBack) => {
        debouncedSetInput(inputValue);
        // if inputvalue is starting with " or ' reomve the space after it 
        if (inputValue[0] === '"' || inputValue[0] === "'") {
            inputValue = inputValue.slice(0, 1) + inputValue.slice(1, inputValue.length).trim()
        }
        // if inputvalue is ending with " or ' reomve the space before it
        if (inputValue[inputValue.length - 1] === '"' || inputValue[inputValue.length - 1] === "'") {
            inputValue = inputValue.slice(0, inputValue.length - 1).trim() + inputValue.slice(inputValue.length - 1, inputValue.length)
        }
        // if (inputValue[0] === '"' && inputValue[inputValue.length - 1] === '"') {
        //     inputValue = inputValue.slice(0, 1) + inputValue.slice(1, inputValue.length - 1).trim() + inputValue.slice(inputValue.length - 1, inputValue.length)
        // }
        const response = await fetchProducts("?term=" + inputValue + "&not_deleted=true" + defaultParams)
        const options = mapOptions(response.data);
        callBack(options)
    }, 500)

    return (
        <AsyncSelect
            loadOptions={debouncedLoadOptions}
            placeholder={placeholder}
            options={defaultOptions}
            defaultOptions={noDefault ? false : true}
            cacheOptions
            // onInputChange={onInputChange}
            isDisabled={isDisabled}
            value={product}
            onChange={onChange}
            styles={customStyles}
            {...features}
        />
    );
}

export default CustomAsyncSelect;
