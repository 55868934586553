function classNames() {
    let result = "";
    for (let i = 0; i < arguments.length; i++) {
        const arg = arguments[i];
        if (!arg) {
            continue;
        }
        if (typeof arg === "string" || typeof arg === "number") {
            result += " " + arg;
        } else if (typeof arg === "object") {
            for (let key in arg) {
                if (arg.hasOwnProperty(key) && arg[key]) {
                    result += " " + key;
                }
            }
        } else if (Array.isArray(arg)) {
            result += " " + classNames.apply(null, arg);
        }
    }
    return result.trim();
}

export default classNames;