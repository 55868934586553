import React from 'react'
import Icons from '../../../orders/icons'
import { HiArrowDown } from 'react-icons/hi'

const Route = ({
    from, to
}) => {
  return (
    <div className="flex-col justify-between items-start inline-flex">
  <div className="justify-start items-center gap-1 inline-flex">
      <img className="w-[20px] h-[14px]  shadow-inner" src={from?.state?.country?.flag} />
              <div className="text-[#111928] text-xs font-normal font-['Inter'] leading-3">
        {from?.name}
    </div>
  </div>
  <div className="self-stretch justify-center items-center gap-2.5 inline-flex">
    <HiArrowDown className='text-[#111928] h-2 w-2 font-normal leading-3' />
  </div>
  <div className="justify-start items-center gap-1 inline-flex">
  <img className="w-[20px] h-[14px]  shadow-inner" src={to?.state?.country?.flag} />
    
              <div className="text-[#111928] text-xs font-medium font-['Inter'] leading-3">
        {to?.name}
    </div>
  </div>
</div>
  )
}

export default Route