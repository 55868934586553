import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchShippingCostList = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGCOSTLIST;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const searchShippingCostList = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGCOSTLIST + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteShippingCostList = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_SHIPPINGCOSTLIST + payload, {withCredentials: true});
    return response?.data
};

const createShippingCostList = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_SHIPPINGCOSTLIST, payload, {withCredentials: true});
    return response;
};

const updateShippingCostList = async (id, payload) => {
    let url = constants.APIURL.PUT_SHIPPINGCOSTLIST + id;
    const response = await axios.put(url, payload, {withCredentials: true});
    return response;
};

const fetchShippingCostListsFilters = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGCOSTLIST_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const bulkDeleteShippingCostLists = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BULK_SHIPPING_COST_LIST, {
        withCredentials: true,
        data: payload
    });
    return response?.data
};

const bulkStatusUpdateShippingCostLists = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_SHIPPING_COST_LIST, payload, {withCredentials: true});
    return response?.data
};

const restoreShippingCostLists = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_RESTORE_SHIPPINGCOSTLIST, payload, {withCredentials: true});
    return response?.data
};

const duplicateShippingCostList = async (id) => {
    const response = await axios.post(
        constants.APIURL.DUPLICATE_SHIPPINGCOSTLIST + id,
        {withCredentials: true}
    )
    return response;
}

export default {
    fetchShippingCostList,
    deleteShippingCostList,
    createShippingCostList,
    updateShippingCostList,
    searchShippingCostList,
    fetchShippingCostListsFilters,
    bulkDeleteShippingCostLists,
    bulkStatusUpdateShippingCostLists,
    restoreShippingCostLists,
    duplicateShippingCostList
};
