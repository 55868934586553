import moment from 'moment';
import React from 'react'
import {howLongFromNow} from '../../../utils/helper';

const nullHandle = (data, chained) => {
    if (!data || chained == null) {
        return "-";
    } else {
        return chained;
    }
};

function LastUpdate({updated_at}) {


    return (
        updated_at ? (
            <div className="  flex-col justify-center !px-[8px] !py-[12px] items-start gap-1 inline-flex">
                <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                    <div className="text-gray-900 text-sm font-normal leading-[14px]">
                        {moment(updated_at).format(
                            "MMM D, YYYY h:mm a"
                        )}
                    </div>
                </div>
                <div className="self-stretch justify-between items-center gap-1 inline-flex">
                    <div className="text-emerald-700 text-xs font-medium leading-[18px]">
                        {howLongFromNow(updated_at, true)}
                    </div>
                </div>
            </div>
        ) : (
            <div className="flex flex-col justify-center !px-[8px] !py-[12px] items-start gap-1 inline-flex">
                -
            </div>
        )
    )
}

export default LastUpdate