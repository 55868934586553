// product-navbar.js
// ----------------------------------------------------------------

// Import General Libraries
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CiSettings } from "react-icons/ci";

// Import Redux actions
import { fetchAuthUser } from "../../store/auth-user";
import {
  nextNavRouting,
  homeNavRouting,
  updateCurrentPage,
} from "../../store/product";

// Import Elements
import navItems from "./navItems";
import ArbitTextDropdown from "../sub-elements/arbit-text-dropdown";
import NotificationMenu from "../../utils/notification";
import UserMenu from "./user-menu";
import Notifier from "../dynamic-page/util/notifierLive";
import Calculator from "../calculator";
import { RiSettings5Fill } from "react-icons/ri";

// Main Component: ProductNavbar
const ProductNavbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle Nav Subitem
  const onHandleNavSubitem = useCallback(
    (nav, item) => {
      dispatch(homeNavRouting());
      dispatch(nextNavRouting({ item: nav }));
      dispatch(nextNavRouting({ item }));
      navigate([nav, item].join("/").toLowerCase().replaceAll(" ", "_"));
    },
    [dispatch, navigate]
  );

  // Handle authentication of user
  useEffect(() => {
    dispatch(fetchAuthUser());
  }, []);

  // Get the current url path
  const { pathname } = useLocation();
  const [mainPathSegment, subPathSegment] = pathname.split("/").slice(1, 3);

  // Generate navbar items
  const navLinks = useMemo(() => {
    return navItems.map((item, index) => (
      <ArbitTextDropdown.Title
        key={`navbar_${item?.title}`}
        title={item?.title}
        path={mainPathSegment}
        icon={item?.icon}
        className={"p-[4px]"}
      >
        {item?.options
          .sort((a, b) => a.label.length - b.label.length)
          .map((subItem, index) => (
            <ArbitTextDropdown.Item
              key={subItem.label}
              className={"!p-[0px]"}
              //  onClick={() => onHandleNavSubitem(item.nav_name, sub_item)}
            >
              <Link
                to={subItem.link}
                onClick={() => {
                  dispatch(updateCurrentPage(subItem?.label?.toLowerCase()));
                }}
                className={`min-w-[150px] rounded-[8px] !pl-[12px] !pr-[24px] !py-[8px] flex items-center text-[14px] font-[500] ${
                  pathname === subItem?.link?.toString().toLowerCase()
                    ? "text-blue-600 bg-blue-50 p-2 rounded-md"
                    : "text-gray-400"
                } hover:text-gray-700`}
              >
                {subItem.icon && (
                  <span className="mr-[8px]">{subItem.icon}</span>
                )}
                {subItem.label}
              </Link>
            </ArbitTextDropdown.Item>
          ))}
      </ArbitTextDropdown.Title>
    ));
  }, [navItems, onHandleNavSubitem]);

  // Render the navbar
  return (
    <div className="w-full py-[5px] min-h-[64px] flex items-center px-[20px] border-[1px] border-[#E5E7EB]">
      <div className="flex flex-col w-full gap-[8px] h-full md:gap-[32px] justify-between md:flex-row">
        <div className="flex flex-col items-start md:items-center gap-[8px] md:gap-[32px] md:flex-row">
          {/* Brand logo is here */}
          <Link
            to="/"
            onClick={() => {
              dispatch(updateCurrentPage(""));
            }}
            className="flex items-center relative top-[-2px]"
          >
            <img
              src={"/assets/newLogo.png"}
              alt="Arbitbox"
              className={"w-[155px] h-[32px]"}
            />
          </Link>

          {/* Navigation items are here */}
          <div className="flex items-center">
            <div className="flex space-x-4 !text-[16px] !font-medium leading-6 items-center text-gray-500 ">
              {navLinks}
            </div>
          </div>
        </div>

        <div className="flex items-center gap-6">
          {/* <SearchInput hide_submit={true} disabled /> */}
          <div className="flex items-center gap-4 w-[56px]">
            <Calculator />
            <Link to="/settings/pages">
              <RiSettings5Fill
                className={`${
                  subPathSegment ===
                  "pages".toString().toLowerCase().replace(/\s+/g, "_")
                    ? "text-blue-600 hover:text-blue-400"
                    : "text-gray-300 hover:text-gray-500"
                } w-[25px]  h-[25px]`}
              />
            </Link>
          </div>
          <Notifier />
          <UserMenu />
        </div>
      </div>
    </div>
  );
};

export default ProductNavbar;
