import React from "react";
import Util from "../components/dynamic-page/util";
import upperCase from "../components/dynamic-page/util/upperCaseString";

function descriptiveContent(toggle, content, className, forcePosition = 'bottom', spaces) {
    return content ? (
        <Util.popUp toggle={(selected) => <>{toggle}</>} action="hover" rightSpace={spaces?.rightSpace}  leftSpace={spaces?.leftSpace} forcePosition={forcePosition} toggleClassName='!cursor-default'>
            <div
                className={`text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 text-sm font-normal ${
                    className ? descriptiveContent(className) : ""
                }`} 
            >
                {content?.split(" ")?.map(upperCase)?.join(" ")}
            </div>
        </Util.popUp>
    ) : (
        toggle
    );
}

export default descriptiveContent;
