/**
 * @author Austin Ames
 */

import classNames from "classnames";

const ArbitNormalBadge = ({icon, title, bgColor, textColor, onClick, className}) => {
    return (
        <div
            className={
                classNames(
                    "bg-" + bgColor,
                    "text-" + textColor,
                    "h-[22px] py-[2px] px-[10px] flex justify-center rounded-[6px] items-center gap-1 text-[12px] font-[600]",
                    className
                )
            }
            onClick={() => onClick()}
        >
            {icon && icon}
            {title}
        </div>
    )
}

ArbitNormalBadge.defaultProps = {
    icon: '',
    title: "Badge",
    bgColor: "green-100",
    textColor: "green-800",
    onClick: () => {
    }
}

export default ArbitNormalBadge;
