import React from 'react';

const Summary = ({order, orderItems, summaryForm}) => {


    const summaryTexts = (summaryForm?.type === 1 || summaryForm?.type === 2 || !summaryForm?.type) ? [

        {
            label: 'Transfer ID',
            value:
                {
                    name: summaryForm?.tranfer_id,
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.name ? value?.name : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'Transfer Type',
            value:
                {
                    type: summaryForm?.type === 1 ? "Warehouse Shipment " : summaryForm?.type === 2 ? "Warehouse Inventory " : "FC Inventory ",
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.type ? value?.type : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'Warehouse',
            value:
                {
                    name: summaryForm?._data?.warehouse_id?.data?.name,
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.name ? value?.name : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'Warehouse Country',
            value:
                {
                    flag: summaryForm?._data?.warehouse_id?.data?.flag,
                    country: summaryForm?._data?.warehouse_id?.data?.state?.country?.code
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.country ? value?.country : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'Warehouse Recipient',
            value: summaryForm?._data?.warehouse_id?.data?.name,
            theme: (value) => (
                <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                    {value}
                </div>
            ),

        },
    ] : [
        {
            label: 'Transfer ID',
            value:
                {
                    name: summaryForm?.tranfer_id,
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.name ? value?.name : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'Transfer Type',
            value:
                {
                    type: "FC Inventory ",
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.type ? value?.type : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'Provider',
            value: summaryForm?.customer_country ?
                {
                    flag: summaryForm?.customer_country?.flag,
                    code: summaryForm?.customer_country?.code
                } : null,
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <span>{value?.code ? value?.code : "-"}</span>
                </div>
            )
        },
        {
            label: 'Sales Channel',
            value:
                {
                    logo: summaryForm?._data?.account_id?.data?.channel?.logo
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.logo ? <img src={value?.logo} alt=''
                                        className='w-[28px] h-[20px] rounded-[2px] object-contain'/> : null}
                </div>
            )
        },
        {
            label: 'FC country',
            value:
                {
                    flag: summaryForm?._data?.warehouse_id?.data?.flag,
                    country: summaryForm?._data?.warehouse_id?.data?.state?.country?.code
                },
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <div className="text-gray-900 text-base font-normal font-['Inter'] leading-normal">
                        {value?.country ? value?.country : "-"}
                    </div>
                </div>
            )
        },
        {
            label: 'FC code',
            value: summaryForm?.customer_country ?
                {
                    flag: summaryForm?.customer_country?.flag,
                    code: summaryForm?.customer_country?.code
                } : null,
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <span>{value?.code ? value?.code : "-"}</span>
                </div>
            )
        },
        {
            label: 'FC note',
            value: summaryForm?.customer_country ?
                {
                    flag: summaryForm?.customer_country?.flag,
                    code: summaryForm?.customer_country?.code
                } : null,
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <span>{value?.code ? value?.code : "-"}</span>
                </div>
            )
        },
    ]

    return (
        <div className='flex flex-col px-[16px] mb-[16px] h-[50%]'>
            <div className='flex flex-col  gap-[4px] pb-[32px] '>
                {summaryTexts?.map((item, index) => (


                    <div key={index}
                         className='flex flex-row w-full items-start pt-[8px] justify-between '>
                            <span
                                className='select-none flex text-gray-700 text-[16px] font-semibold justify-start items-start whitespace-nowrap '>
                                {item?.label}
                            </span>
                        <span
                            className={"font-normal text-[16px] text-gray-700 text-right"}>{item.theme(item?.value)}</span>
                    </div>


                ))}
            </div>
        </div>

    );

}

export default Summary;