import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteBalance,
  createBalance,
  deleteBalance,
  fetchBalanceFilters,
  fetchBalances,
  restoreBalance,
  searchBalances,
  updateBalance,
  updateBalanceStatus,
} from "./thunk";

const initialState = {
  balances: [],
  links: {},
  meta: {},
  loading: "idle",
  error: null,
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
  },
  filtersMeta: {},
};

export const balancesSlice = createSlice({
  name: "balances",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchBalances: (state, action) => {
      state.balances = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateBalanceStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.balances = state.balances.map((balance) => {
        if (ids.includes(balance.id)) {
          return { ...balance, status };
        }
        return balance;
      });
    },
    removeBalance: (state, action) => {
      const ids = action.payload;
      state.balances = state.balances.filter((balance) => {
        return !ids.includes(balance.id);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalances.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchBalances.fulfilled, (state, action) => {
        state.loading = "idle";
        state.balances = action.payload?.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(fetchBalanceFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeBalance,
  updateBalanceStatuses,
} = balancesSlice.actions;

export {
  fetchBalances,
  createBalance,
  updateBalance,
  deleteBalance,
  searchBalances,
  bulkDeleteBalance,
  updateBalanceStatus,
  fetchBalanceFilters,
  restoreBalance,
};

export default balancesSlice.reducer;
