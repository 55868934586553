import React, {useEffect, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf';
import {useParams} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PrintPage = () => {
    const {url} = useParams();
    const filesUrl = url?.split(',');
    const [numDocLoaded, setNumDocLoaded] = useState(0);


    /*const zplUrls =  url?.split(',');
    const [zplContents, setZplContents] = useState([]);
    const printAllZplFiles = () => {
        zplContents.forEach((zplContent) => {
            
            document.write(zplContent);
            document.close();
            window.print();
        });
    };
    useEffect(() => {
        const fetchZplContents = async () => {
            const zplPromises = zplUrls.map(async (url) => {
                const response = await fetch(url);
                const zplContent = await response.text();
                return zplContent;
            });
            
            const resolvedZplContents = await Promise.all(zplPromises);
            setZplContents(resolvedZplContents);
        };
        
        fetchZplContents();
    }, [zplUrls]);
    useEffect(() => {
        zplContents.forEach((zplContent) => {
            
            document.write(zplContent);
            document.close();
            
        });
        window.print();
    },[zplContents]);
    
    
    
    //const [isLoaded, setIsLoaded] = useState(false);
    //const [pages, setPages] = useState(null);
    
*/

    useEffect(() => {
        if (numDocLoaded === filesUrl?.length) {
            const timeoutId = setTimeout(() => {
                window.print();
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [numDocLoaded]);


    const handlePrinting = () => {
        setNumDocLoaded(numDocLoaded + 1);
    }

    return (
        <div id="pdfContainer" style={{width: "100%", height: "100%", overflow: "auto"}}>
            <style>

                {`
                   
                    * {margin: 0px; padding: 0px;}
                    
                    
                    
                  
                    
                    .react-pdf__Page__textContent, .react-pdf__Page__annotations {display: none !important;}
                    
                 
  
                    div.react-pdf__Page {
                        margin: -1px 0px;
                        padding:2px;
                        border: 1px dashed #e5e7eb !important;
                       
                        box-sizing: border-box;
                        page-break-after: always;
                        page-break-inside: avoid;
                        break-inside: avoid;
                        break-after: always;
                        display: block;
                        
                    }
                    
   
                `}
            </style>
            {filesUrl?.map((item, index) => (
                <PdfFile url={item} key={`document_${index + 1}`} callBack={handlePrinting}/>

            ))}

        </div>
    );
};

export default PrintPage;

const PageOfPdf = ({pages, index}) => {
    return (
        <>{pages[index]}</>
    )
}

const PdfFile = ({url, callBack}) => {
    const [totalPages, setTotalPages] = useState(0);

    function onDocumentLoadSuccess({numPages}) {
        setTotalPages(numPages);
        callBack()
    }

    return (
        <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            className={"w-max"}
        >
            {Array.from(new Array(totalPages), (el, index) => (
                <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderMode="svg"
                    className={"w-max"}
                >
                </Page>
            ))}
        </Document>
    )
}