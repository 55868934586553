import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "../dynamic-page";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
import sortView from "./modals-view/sort";
import formView from "./modals-view/form";
import callEndpoint from "../dynamic-page/util/callEndpoint";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import {
  fetchOffers as fetchData,
  fetchOffersFilters as fetchFilters,
  updateFilter,
} from "../../store/offers";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import FormModal from "../dynamic-page/FormModal";

const RepricerOffers = () => {
  const dispatch = useDispatch();

  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      filters: {},
      sort: null,
      page: 1,
      search: "",
      includes: "account",
    };

    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    let hash = window.location.hash;
    hash = hash.replace("#", "?"); // Replace first '&' with '?'
    const params = new URLSearchParams(hash);
    const action = params.get("action");
    if (action === "create") {
      setModalVisibility(true);
    }

    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [dispatch]);

  let hash = window.location.hash;
  hash = hash.replace("#", "?"); // Replace first '&' with '?'
  const params = new URLSearchParams(hash);

  // Get data from store.
  const pageData = useSelector((state) => state.offers);

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [formData, setFormData] = useState(null);

  // Handle order details modal.
  const [modalVisibility, setModalVisibility] = useState(false);

  const onSubmitApply = async (data) => {
    let { id, ...payload } = data;

    if (payload.quantity === null) {
      delete payload.quantity;
    }

    callEndpoint({
      title: "Create Offer",
      url: `offers`,
      method: "POST",
      data: payload,
    }).then((res) => {
      if (res.type === "success") {
        onPaginationApply();
      }
    });
  };

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  // handle actions of statuses
  const actions = (row) => {
    // todo const innerRow = row.map((item) => { add actions_id to item }

    // return getActionList(row, statusList);
    return [];
  };

  // Handle Tabs
  const onHandleTab = (item) => {
    onFilterApply({ fulfillment: item });
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      page: page || currentStoreFilters.page || 1,
      limit: limit || currentStoreFilters.limit || 20,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  // Render View of Page
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={"Repricer Offers"}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(pageData?.filters?.filters),
            callback: (rs) => {
              onFilterApply(rs);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.fulfillment === undefined ||
              pageData?.filters?.filters?.fulfillment === null
                ? 0
                : 1),
          }}
          actionList={actions(selectedRow)}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.fulfillment}
            list={[
              {
                id: "DEFAULT",
                name: "FBM Offers",
                count: pageData?.filtersMeta?.fulfilments?.find(
                  (item) => item?.name === "FBM"
                )?.count,
              },
              {
                id: "AMAZON_NA",
                name: "FBA Offers",
                count: pageData?.filtersMeta?.fulfilments?.find(
                  (item) => item?.name === "FBA"
                )?.count,
              },
            ]}
            allTab={true}
          />
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className="overflow-x-auto max-w-[100%]">
            <DynamicTable
              view={tableView}
              data={tableView(
                pageData.offers
                // data
              )}
              actions={actions}
              onSelect={onSelectRow}
              selectedRow={selectedRow}
              loading={pageData.loading}
              style={{
                hiddenActions: true,
                table: {
                  style: { width: "100%", minWidth: "100%" },
                },
                header: {
                  control: { className: "hidden" },
                  "channel & SKU": {
                    className: "!w-[215px] text-left !justify-start",
                  },
                  "f. type": { className: "!w-[150px] !text-left" },
                  title: { className: "!text-left !justify-start !text-start" },
                  className: "justify-center !px-[16px] ",
                  "tracking id": { className: "!pr-[30px]" },
                  image: { className: "hidden" },
                  title: {
                    th: "w-[100%]",
                  },
                  "LAST UPDATE": {
                    th: "w-[250px]",
                  },
                  "channel & SKU": {
                    th: "w-[250px]",
                  },
                  "f. type": {
                    th: "w-[250px]",
                  },
                  price: {
                    th: "w-[250px]",
                  },
                  "T. Quantity": {
                    th: "w-[250px]",
                  },
                },
                row: {
                  className:
                    "!px-[8px] !py-[0px] !items-center !justify-center flex text-[12px] ",
                  control: { className: "text-left !justify-start" },
                  "channel & SKU": { className: "!w-[215px]" },
                  "f. type": {
                    className:
                      "!w-[150px] !text-left justify-start items-start",
                  },
                  title: {
                    td: "w-[100%]",
                  },
                  "LAST UPDATE": {
                    td: "min-w-[250px]",
                  },
                  "channel & SKU": {
                    td: "min-w-[250px]",
                  },
                  "f. type": {
                    td: "min-w-[250px]",
                  },
                  price: {
                    td: "min-w-[250px]",
                  },
                  "T. Quantity": {
                    td: "min-w-[250px]",
                  },
                  description: {
                    className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                  },
                  _actions: { className: "!py-[0px]" },
                },
              }}
            />
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          onPrevious={onHandlePrevious}
          onNext={onHandleNext}
          onPage={onHandlePage}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {modalVisibility && (
        <FormModal
          title={"Offer"}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          params={params}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
          }}
          container={(child) => (
            <>
              <style>
                {`
                        .parentForm {
                            display: grid;
                            grid-template-columns: repeat(12, 1fr);
                            grid-template-rows: repeat(3, 1fr);
                           
                        }
                        
                        .parentForm > div:nth-child(1) { grid-area: 1 / 1 / 2 / 5; }
                        .parentForm > div:nth-child(2) { grid-area: 1 / 5 / 2 / 9; }
                        .parentForm > div:nth-child(3) { grid-area: 1 / 9 / 2 / 13; }
                        .parentForm > div:nth-child(4) { grid-area: 2 / 1 / 3 / 4; }
                        .parentForm > div:nth-child(5) { grid-area: 2 / 4 / 3 / 7; }
                        .parentForm > div:nth-child(6) { grid-area: 2 / 7 / 3 / 10; }
                        .parentForm > div:nth-child(7) { grid-area: 2 / 10 / 3 / 13; }
                        .parentForm > div:nth-child(8) { grid-area: 3 / 1 / 4 / 13; }
                    `}
              </style>
              <div className=" w-[900px] parentForm items-start gap-[16px] justify-center px-[16px] border-t border-t-gray-200 pt-[24px] mt-[10px]">
                {child}
              </div>
            </>
          )}
          onSubmit={onSubmitApply}
        />
      )}
    </>
  );
};

export default RepricerOffers;
