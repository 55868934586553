import axios from './../../middleware/axios';
import constants from '../../constant';
import {obj2Form} from '../../utils/apiutils';

const fetchSupplyListings = async (params) => {
    let url = constants.APIURL.GET_SUPPLY_LISTINGS
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const fetchSupplyListingsFilters = async (params) => {
    let url = constants.APIURL.GET_SUPPLY_LISTINGS + '/filters'
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const CreateSupplyListing = async (data) => {
    const response = await axios.post(
        constants.APIURL.GET_SUPPLY_LISTINGS,
        obj2Form(data),
        {withCredentials: true}
    )
    return response
}

const updateSupplyListing = async (data) => {
    const response = await axios.post(
        constants.APIURL.GET_SUPPLY_LISTINGS + '/' + data.id,
        obj2Form(data?.payload),
        {withCredentials: true}
    )
    return response
}


let urlNote = 'api/v1/supply-channel-listing';

const fetchNotes = async (params) => {
    const url = `${urlNote}/${params?.orderId}/notes`;
    return await axios.get(url, {withCredentials: true,})
}

const deleteNote = async (params) => {
    const url = `${urlNote}/${params.orderId}/notes/${params?.noteId}`;
    return await axios.delete(url, {withCredentials: true,});
}

const addNote = async (params) => {
    const url = `${urlNote}/${params?.orderId}/notes`;
    return await axios.post(url, {note: params?.note}, {withCredentials: true,});
}


export default {
    fetchSupplyListings,
    fetchSupplyListingsFilters,
    CreateSupplyListing,
    fetchNotes,
    deleteNote,
    addNote,
    updateSupplyListing
}