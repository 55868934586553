const tabsView = (pageData) => {
    return ({

        active: {
            label: "Active",
            count: pageData?.filtersMeta?.statuses?.find((item) => item.id === 1)?.count || 0,
            filter: {
                status: 'active'
            },
        },
        inactive: {
            label: "Inactive",
            count: pageData?.filtersMeta?.statuses?.find((item) => item.id === 0)?.count || 0,
            filter: {
                status: 'inactive'
            },
        },
        deleted: {
            label: "Deleted",
            count: pageData?.filtersMeta?.statuses?.find((item) => item.id === 0)?.count || 0,
            filter: {
                status: 'deleted'
            },
        },
        all: {
            label: "All",
            count: pageData?.filtersMeta?.statuses?.reduce((acc, item) => acc + item.count, 0) || 0,
            filter: {
                status: ''
            },
            active: true,

        },
    })
}
export default tabsView;