import React, {memo, useState} from "react";
import Box from "../../../../../dynamic-page/elements/Box"
import AddItemForm from "./addItemForm";
import ItemList from "./itemList"


import FooterActions from "./FooterActions";
import callEndpoint from "../../../../../dynamic-page/util/callEndpoint";
import { useSelector } from "react-redux";


const SummaryItems = memo(({orderItems, callBack,leftCallBack, validation, isView, id, actionCallBack, data, update,type}) => {

    const [actionList, setActionList] = useState([])
    const [editProductForm, setEditProductForm] = useState(null)

    const transfers = useSelector(state => state.transfers?.transfers)

    const handleAddProduct = async (item, duplicateLocal) => {
        if (isView && !duplicateLocal) {
            callEndpoint({
                title: 'Add Item',
                method: 'POST',
                url: `transfers/${data?.id}/items`,
                data: {
                    transferable_id: item?.order || item?.product
                }
        
            }).then((res) => {
                if (res?.type === 'success') {
                    const mappedItem = type === 'order' ? {
                        order: res?.data?.id,
                        _data: {
                            order: {
                                order: res?.data?.item
                            }
                        }
                    } : {
                            product: res?.data?.id,
                            qty: 1,
                        _data: {
                            product: {
                                product: res?.data?.item
                            }
                        }
                    }
                
                    
                    callBack([mappedItem, ...orderItems || []]) 
                }
            })
        } else {
            callBack([item, ...orderItems || []])
        }

        //callBack([item, ...orderItems || [] ])
    }
    const handleEditProduct = (item) => {
        // setEditProductForm(item)
    }
    const handleUpdateProduct = (items) => {
        callBack(items)
    }
    const handleActionButton = (actions) => {
        setActionList(actions)
    }
    return ( (
                <Box 
                              className={`flex flex-grow ${data?.status_id !== '1' ?  '!pt-0 ' : '!pt-3'} justify-end`} innerClassName={"justify-end overflow-hidden"}
                >
            <div className=' flex flex-col contentBox justify-between flex-1  overflow-hidden rounded-[0px] h-full'>
                <style>
                    {` 
                    .contentBox .contt {
                        max-height : 520px !important;
                    }
                    `}
                </style>
                { data?.status_id === '1' && <AddItemForm
                    callBack={handleAddProduct}
                    editProduct={editProductForm}
                            itemList={orderItems}
                    type={type} 
                    items={orderItems}
                    allocation_warehouse_id={data?.from_warehouse?.id}
                    // type = {summaryForm?.type}
                />}
                <ItemList
                    orderItems={orderItems}
                    actionButton={handleActionButton}
                    actionCallBack={handleUpdateProduct}
                    actionEditCallBack={handleEditProduct}
                    actionConnectCallBack={editProductForm}
                    actionAddProduct={handleAddProduct}
                    orderId={data?.id}
                    update={update}
                    data={data}
                    status={data?.status_id}
                    type={type}
                    isView={isView}
                />
                <FooterActions
                    list={orderItems}
                    type={type}
                            data={data}
                    isView={isView}
                            callBack={leftCallBack}
                    actionCallBack={actionCallBack}
                />
                    </div>
                </Box>
        )
    )

})
//setProducts={setProducts} products={products} form={form} setForm={setForm}
export default SummaryItems;