import * as Yup from "yup";
import constants from "../../../constant";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import UI from "../../dynamic-form/elements";

const formView = (formDefaultValue) => {
    return [
        {
            name: "name",
            label: "Name",
            placeholder: "Enter Folder name",
            type: "Input",
            labelClassName: "font-medium text-[14px]",
            labelClassName: "font-medium text-[14px]",
            defaultValue: formDefaultValue?.name || null,
            className: "col-span-2",
            validation: Yup.string().required("Name is required"),
        },

        {
            name: "sales_channel_id",
            defaultValue: formDefaultValue?.salesChannel?.id || null,
            label: "Sales Channel",
            placeholder: "Select Sales Channel",
            type: "Select",
            labelClassName: "font-medium text-[14px]",
            isClearable: true,
            disabled: formDefaultValue ? true : false,
            features: {
                formatOptionLabel: ({label, logo}) => (
                    <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                        {logo && (
                            <span
                                className={
                                    "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
                                }
                            >
                <img src={logo?.toLowerCase()}/>
              </span>
                        )}
                        <span>{upperCaseString(label)}</span>
                    </div>
                ),
            },
            api: {
                url: constants.APIURL.GET_CHANNELS + "?filter[designation]=TARGET",
                query: "&filter[name]={query}&not_deleted=true",
                optionValue: (item) => ({
                    value: item.id,
                    label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                    logo: item?.logo,
                }),
            },
            validation: Yup.string().required("Sales Channel is Required"),
            className: "col-span-1",
        },

        {
            name: "currency_id",
            label: "Currency",
            placeholder: "Select currency",
            type: "Select",
            labelClassName: "font-medium text-[14px]",
            api: {
                url: constants.APIURL.GET_CURRENCIES + '?filter[status]=1',
                view: constants.APIURL.GET_CURRENCIES,
                query: "&term={query}",
                optionValue: (item) => ({
                    value: item.id,
                    label: item.name + " (" + item.symbol + ")"
                })

            },
            className: "col-span-1",

            defaultValue: formDefaultValue?.currency?.id || null,
            validation: Yup.string().required('Currency is required'),
        },

        {
            name: "fba_shipment_cost",
            label: "FBA Shipment Cost per KG ($)",
            placeholder: "ex. $5.5",
            type: "Number",
            labelClassName: "font-medium text-[14px]",
            defaultValue: formDefaultValue?.fba_shipment_cost || null,
            className: "col-span-1",
            validation: Yup.string().required("FBA Shipment Cost per KG is required"),
        },
        {
            name: "target_margin",
            label: "Target Margin (%)",
            placeholder: "ex. 10%",
            type: "Number",
            labelClassName: "font-medium text-[14px]",
            defaultValue: formDefaultValue?.target_margin || null,
            className: "col-span-1",
            validation: Yup.string().required("Target Margin is required"),
        },
        {
            name: "payment_provider_fee",
            label: "Payment Provider Fee (%)",
            placeholder: "ex. 2%, 3%",
            type: "Number",
            labelClassName: "font-medium text-[14px]",
            defaultValue: formDefaultValue?.payment_provider_fee || null,
            className: "col-span-1",
            validation: Yup.string().required("Payment Provider Fee % is required"),
        },
        {
            name: "warehouse_processing_fee",
            label: "Warehouse Processing Fee ($)",
            placeholder: "ex. $1, $2",
            type: "Number",
            labelClassName: "font-medium text-[14px]",
            defaultValue: formDefaultValue?.warehouse_processing_fee || null,
            className: "col-span-1",
            validation: Yup.string().required("Warehouse Processing Fee is required"),
        },
        {
            name: "target_seller_ids",
            label: "Target Seller IDs",
            placeholder: "Enter IDs separated by comma",
            type: "Textarea",
            labelClassName: "font-medium text-[14px]",
            rows: "3",
            defaultValue: formDefaultValue?.target_seller_ids || null,
            className: "col-span-2",
            optional: true,
            hint: "Optional, Enter IDs separated by comma",
            condition: (data) => {
                return !data?.sales_channel_id
            }
        },
        {
            name: "description",
            label: "Description",
            placeholder: "Enter Description",
            type: "Textarea",
            labelClassName: "font-medium text-[14px]",
            rows: "3",
            defaultValue: formDefaultValue?.description || null,
            className: "col-span-2",
            optional: true,
        },

        {
            name: "shipment_costs",
            type: "Module",
            labelClassName: "font-medium text-[14px]",
            module: UI.ShippingCostList,
            defaultValue: formDefaultValue?.shipmentCosts || [],
            className: "col-span-2",
            placeholder: "Select a Single Product to add more to the Grouped Product",
        },
    ];
};
export default formView;
