import React from "react";
import UI from "../../dynamic-form/elements";
import DynamicForm from "../../dynamic-form";
import connectProductFrom from "../../dynamic-page/components/connectProductForm";
import {connectProduct, updateRow} from "../../../store/sales-listings";
import {fetchSalesListing} from "../../../store/sales-listings/thunk";
import Util from "../../dynamic-page/util";
import priceHandler from "../../../utils/priceHandler";

const Product = ({modalListing, fulfilmentHandler, productList, dispatch}) => {
    
    const getInventoryCount=(arr)=>{
        if(!Array.isArray(arr)) return 0;
        return arr.reduce((a, v)=>a.available_quantity+v.available_quantity)
    }

    const productData01 = [
        {
            title: "APID",
            value: modalListing?.sales_channel_listing?.product?.apid || modalListing?.product?.apid || "N/A",
        },
        {
            title: "Warehouse Inventory",
            value:getInventoryCount(modalListing?.sales_channel_listing?.product?.inventory_detail || modalListing?.product?.inventory_detail)
        },
        // Add more data items as needed
    ];
    const productData02 = [
        {
            title: "Product Type",
            value: (
                <span className="capitalize">
          {modalListing?.sales_channel_listing?.product?.type === 1 || modalListing?.product?.type === 1
              ? "Single Product"
              : "Grouped Product"}
        </span>
            ),
        },
        {
            title: "Brand",
            value: (
                <span className="uppercase">
          {modalListing?.sales_channel_listing?.brand || modalListing?.brand || "N/A"}
        </span>
            ),
        },
        {
            title: "Assign",
            value: modalListing?.sales_channel_listing?.product?.brand?.assigned?.first_name || modalListing?.product?.brand?.assigned?.first_name || "N/A",
        },
        // Add more data items as needed
    ];
    const productData03 = [
        {
            title: "Last Supply Cost",
            value: priceHandler(modalListing?.sales_channel_listing?.product?.last_purchase_cost_try) || "N/A"
        },
        {
            title: "Last Supply Cost as USD",
            value: priceHandler(modalListing?.sales_channel_listing?.product?.last_purchase_cost) || "N/A",
        },
        // Add more data items as needed
    ];
    const productData04 = [
        {
            title: "WH Shipping Bill. Weight", value: modalListing?.sales_channel_listing?.product?.fbm_billable_weight
                || modalListing?.product?.fbm_billable_weight || "N/A"
        },
        {
            title: "FC Shipping Bill. Weight", value: modalListing?.sales_channel_listing?.product?.fba_actual_weight
                || modalListing?.product?.fba_actual_weight || "N/A"
        },
        // Add more data items as needed
    ];

    return (
        <div className="mt-5 flex flex-col justify-between items-center gap-y-3 gap-x-2 w-full">
            {(modalListing?.product || modalListing?.sales_channel_listing?.product) ? (
                <div className="flex flex-col justify-between items-center w-full">
                    <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
                        <UI.StatBoxList data={productData01}/>
                    </div>
                    <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                        <UI.StatBoxList data={productData02}/>
                    </div>
                    <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
                        <UI.StatBoxList data={productData03}/>
                    </div>
                    <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
                        <UI.StatBoxList data={productData04}/>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-[40vw]">
                    <h3 className="text-left text-gray-900 text-2xl font-bold">
                        No connected product found.
                    </h3>
                    <p className="text-gray-500 text-[16px] font-normal mb-3">
                        Please connect an existing product or create a new one.
                    </p>
                    <DynamicForm
                        items={connectProductFrom(true)}
                        onChange={(d, bind) => {
                            if (bind?.productList) {
                                const product_id = d?.product;
                                dispatch(
                                    connectProduct({
                                        listId: productList?.id,
                                        productId: product_id,
                                    })
                                ).then((result) => {
                                    if (result?.payload?.type === "success") {
                                        dispatch(fetchSalesListing(productList?.id)).then((rs) => {
                                            dispatch(
                                                updateRow({
                                                    path: `salesListings.[id:${productList?.id}]`,
                                                    value: rs.payload.data,
                                                })
                                            );
                                            Util.notifier({
                                                type: "success",
                                                message: result?.payload?.message,
                                                errors: result?.payload?.errors,
                                            });
                                        });
                                    }
                                });
                            }
                        }}
                    />
                    <button
                        className="text-blue-700 text-[14px] font-normal leading-none w-full hover:text-blue-800 mt-1 text-right"
                        onClick={() => {
                            window.open(
                                `/product/list#action=add&title=${productList?.name}&image=${productList?.main_image_path}`,
                                "_blank"
                            );
                        }}
                    >
                        Didn’t find? Create new.
                    </button>
                </div>
            )}
        </div>
    );
};

export default Product;
