const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Supply Channel Type",
                value: "supply_channel_type",
                onClick: (item) => {
                    callback("supply_channel_type");
                },
            },
            {
                label: "Country",
                value: "country_id",
                onClick: (item) => {
                    callback("country_id");
                },
            },
            {
                label: "Listing Count",
                value: "listing_count",
                onClick: (item) => {
                    callback("listing_count");
                },
            },


        ],
    };
};

export default sortView;
  