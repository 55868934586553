/**
 * @author Austin Ames
 */

const Item = ({children, onClick, className}) => {
    return (
        <div className={`cursor-pointer hover:bg-slate-100 px-3 py-2 ${className ? className : ""}`}
             onClick={() => onClick()}>
            {children}
        </div>
    )
}

export default Item;
