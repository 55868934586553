import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteAttributes,
  bulkRestoreAttributes,
  createAttribute,
  deleteAttribute,
  fetchAttributes,
  fetchAttributesFilters,
  searchAttributes,
  updateAttribute,
  updateAttributesStatus,
} from "./thunk";

const initialState = {
  attributes: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    status_filter: [],
    created_filter: "",
    updated_filter: "",
    balance_filter: [],
  },
  filterLoading: "idle",
  meta: {},
  filtersMeta: {},
};

export const attributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchattributes: (state, action) => {
      state.attributes = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateAttributesStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.attributes = state.attributes.map((attr) => {
        if (ids.includes(attr.id)) {
          attr.status = status;
        }
        return attr;
      });
    },
    removeAttribute: (state, action) => {
      const ids = action.payload;
      state.attributes = state.attributes.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    bulkDeleteAttributes: (state, action) => {
      const ids = action.payload;
      state.attributes = state.attributes.filter(
        (attr) => !ids.includes(attr.id)
      );
    },
    toggleMulti: (state, action) => {
      const { id, status } = action.payload;
      state.attributes = state.attributes.map((attr) => {
        if (attr.id === id) {
          attr.is_multiple = status;
        }
        return attr;
      });
    },
    toggleMultiLingual: (state, action) => {
      const { id, status } = action.payload;
      state.attributes = state.attributes.map((attr) => {
        if (attr.id === id) {
          attr.is_multilingual = status;
        }
        return attr;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttributes.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchAttributes.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchAttributes.fulfilled, (state, action) => {
        state.loading = "idle";
        state.attributes = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      .addCase(fetchAttributesFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      })
      .addCase(fetchAttributesFilters.fulfilled, (state, action) => {
        state.loading = "idle";
        state.filterLoading = "idle";
        state.filtersMeta = action.payload?.data;
      });
  },
});

export const {
  updateFilter,
  removeAttribute,
  updateAttributesStatuses,
  toggleMulti,
  toggleMultiLingual,
  updateBoundFilter,
} = attributesSlice.actions;

export {
  fetchAttributes,
  createAttribute,
  updateAttribute,
  deleteAttribute,
  searchAttributes,
  bulkDeleteAttributes,
  updateAttributesStatus,
  bulkRestoreAttributes,
  fetchAttributesFilters,
};

export default attributesSlice.reducer;
