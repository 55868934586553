import * as Yup from "yup";
import constants from "../../../constant"

const productComponentFormView = (formDefaultValue) => {
    return ([
        {
            name: "product_id",
            label: "Products",
            placeholder: "Filter by Product",
            type: "Select",
            features: {
                formatOptionLabel: (child) => (
                    <div className={"flex flex-row items-center gap-[8px]"}>
                        <span className={"text-gray-800"}>
                                {child?.data?.title}
                        </span>
                    </div>
                )
            },
            api: {
                url: constants.APIURL.GET_PRODUCT + '?filter[type]=1&page=1&',
                query: "term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: item?.title

                })

            },
            validation: Yup.string().required('Product is required'),

        },
        {
            name: "quantity",
            label: "Quantity",
            placeholder: "Enter Quantity",
            type: "Number",
            validation: Yup.number().required("Quantity is required"),
        },


    ])
}

export default productComponentFormView