const PageNames = [
    {
        nav_name: 'Product',
        sub_items: ["Single Products", 'Brands'
            // "Grouped Products",  "Product Brands"
        ]
    },
    {
        nav_name: 'Listing',
        // supply listings, Bundles, Brands
        sub_items: ["Sales Listings", "Offers", "Supply Listings"]
    },
    // {
    //   nav_name: 'Inventory',
    //   sub_items: []
    //   // sub_items: ["Inventory", "Warehouses"]
    // },
    // {
    //   nav_name: 'Sales',
    //   sub_items: []
    // },
    // {
    //   nav_name: 'Purchasing',
    //   sub_items: []
    // },
    // {
    //   nav_name: 'CRM',
    //   sub_items: []
    // },
    // {
    //   nav_name: 'Financials',
    //   sub_items: []
    // },
    {
        nav_name: 'Logistics',
        // Shipment
        sub_items: ['Shipping Templates', 'Shipping Cost Lists']
    },
    {
        nav_name: 'Sourcing',
        sub_items: ['Supplies', 'Supply Purchases']
    },
    {
        nav_name: 'Growth',
        //'Multichannel',
        sub_items: ['Opportunities', 'Matches', "Target Seller Listings"]
    },
    {
        nav_name: 'Inventory',
        sub_items: ['Inventory Adjustments']

    },
    {
        nav_name: 'Settings',
        //'Crawlers', 'Integrations',
        sub_items: ['Users', "Channels", "Categories", "Accounts", 'Currrncies', 'Payment Methods']
    },
    {
        nav_name: 'Finance',
        sub_items: ['Bank Accounts', ' Balances', 'Tax Rates']
    },
]

export default PageNames;