import { createSlice } from "@reduxjs/toolkit";
import {
  createChannel,
  deleteBulkChannels,
  deleteChannels,
  fetchChannels,
  fetchChannelsFilters,
  restoreChannels,
  updateBulkChannelsStatus,
} from "./thunk";

const initialState = {
  channels: [],
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  meta: {},
  filters: {
    status_filter: [],
    created_filter: "",
    updated_filter: "",
    account_count_filter: [],
    active_accounts_filter: [],
    sku_counts_filter: [],
  },
  updatedBulkChannelsStatus: [],
  deletedBulkChannels: [],
};

export const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchChannel: (state, action) => {
      return { ...state, channels: action.payload };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    getMetaFilter: (state, action) => {
      return {
        ...state,
        filtersMeta: action.payload,
      };
    },
    // update Channel statuses when given a list of ids
    updateChannelsStatuses: (state, action) => {
      const { status, ids } = action.payload;
      // console.log(channels, 'Ids')
      state.channels = state.channels.map((channel) => {
        if (ids.includes(channel.id)) {
          return { ...channel, status };
        }
        return channel;
      });
    },
    // remove Channel when given a list of ids
    removeChannels: (state, action) => {
      const ids = action.payload;
      state.channels = state.channels.filter((channel) => {
        return !ids.includes(channel.id);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChannels.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.channels = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchChannelsFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
      });
    // .addCase(updateBulkChannelsStatus.pending, (state, action) => {
    //   if (state.loading === 'idle') {
    //     state.loading = 'pending'
    //   }
    // })
    // .addCase(updateBulkChannelsStatus.fulfilled, (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle'
    //     state.updatedBulkChannelsStatus = action.payload?.data
    //   }
    // })
    // .addCase(updateBulkChannelsStatus.rejected, (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle'
    //     state.error = action.error
    //   }
    // })
    // .addCase(deleteBulkChannels.pending, (state, action) => {
    //   if (state.loading === 'idle') {
    //     state.loading = 'pending'
    //   }
    // })
    // .addCase(deleteBulkChannels.fulfilled, (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle'
    //     state.deletedBulkChannels = action.payload?.data
    //   }
    // })
    // .addCase(deleteBulkChannels.rejected, (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle'
    //     state.error = action.error
    //   }
    // })
  },
});

export const {
  fetchChannel,
  updateFilter,
  extraReducers,
  getMetaFilter,
  updateChannelsStatuses,
  removeChannels,
  updateBoundFilter,
} = channelsSlice.actions;

export {
  fetchChannels,
  updateBulkChannelsStatus,
  deleteBulkChannels,
  fetchChannelsFilters,
  deleteChannels,
  restoreChannels,
  createChannel,
};

export default channelsSlice.reducer;
