import convertJsonToFormData from "../../../../../dynamic-form/util/convertJsonToFormData";
import filterObjectByKeys from "../../../../../dynamic-page/util/filterObjectByKeys";


const prepareDataToUpdateRecipient = async (detailData) => {

    let recipient = filterObjectByKeys(detailData, [
        "name:recipient[name]",
        "company:recipient[company]",
        "street1:recipient[street1]",
        "street2:recipient[street2]",
        "city:recipient[city]",
        "zip_postal_code:recipient[zip_postal_code]",
        "phone:recipient[phone]",
        "email:recipient[email]",
        "state:recipient[state]",
        "country:recipient[country]",
    ])

    return convertJsonToFormData(recipient)

};

export default prepareDataToUpdateRecipient;