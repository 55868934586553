import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchInventory = createAsyncThunk(
    "get:api/v1/inventory",
    async (payload, thunkAPI) => {
        const response = await api.fetchInventory(payload);
        return response.data;
    }
);

const createInventory = createAsyncThunk(
    "post:api/v1/inventory",
    async (payload, thunkAPI) => {
        const response = await api.createInventory(payload);
        return response;
    }
);

const updateInventory = createAsyncThunk(
    "put:api/v1/inventory",
    async (payload, thunkAPI) => {
        const response = await api.updateInventory(payload);
        return response;
    }
);

const deleteInventory = createAsyncThunk(
    "delete:api/v1/inventory",
    async (payload, thunkAPI) => {
        const response = await api.deleteInventory(payload);
        return response;
    }
);

const fetchInventoryFilters = createAsyncThunk(
    "get:api/v1/inventory/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchInventoryFilters(payload);
        return response;
    }
);

export {
    fetchInventory,
    createInventory,
    updateInventory,
    deleteInventory,
    fetchInventoryFilters
}
