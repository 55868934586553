import React from "react";

const Heading = ({text, fontSize, fontWeight, color, lineHeight, padding}) => {
    const headingStyle = {
        color: color || "#111928",
        fontSize: fontSize || "24px",
        fontFamily: "Inter",
        fontWeight: fontWeight || 700,
        lineHeight: lineHeight || "30px",
        wordWrap: "break-word",
        padding: padding || "0"
    };

    return <h1 style={headingStyle}>{text}</h1>;
};

export default Heading;
