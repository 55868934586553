import { createSlice } from "@reduxjs/toolkit";

import {
  bulkUpdateShippingCostList,
  deleteBulkShippingCostList,
  fetchShippingCostList,
  fetchShippingCostListFilters,
  restoreShippingCost,
  updateBulkShippingCostListStatus,
  updateOpportunities,
} from "./thunk";

const initialState = {
  shippingcostlist: [],
  links: {},
  filters: {
    carrierServices_filter: [],
    states_filter: [],
    shippingCostDataSources_filter: [],
    carriers_filter: [],
    name_filter: "",
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  loading: "idle",
  error: null,
  isLogin: false,
};

export const shippingcostlistSlice = createSlice({
  name: "shippingcostlist",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      // console.log(action, 'Update Filter State')
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    // update the shipping cost list status (active/inactive) when given ids
    updateShippingCostListStatus: (state, action) => {
      const { status, ids } = action?.payload;
      const updatedShippingCostList = state.shippingcostlist.map(
        (shippingcostlist) => {
          if (ids.includes(shippingcostlist.id)) {
            return {
              ...shippingcostlist,
              status: status,
            };
          }
          return shippingcostlist;
        }
      );
      return {
        ...state,
        shippingcostlist: updatedShippingCostList,
      };
    },
    // remove the shipping cost list when given ids
    removeShippingCostList: (state, action) => {
      const { ids } = action?.payload;
      const updatedShippingCostList = state.shippingcostlist.filter(
        (shippingcostlist) => {
          return !ids.includes(shippingcostlist.id);
        }
      );
      return {
        ...state,
        shippingcostlist: updatedShippingCostList,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingCostList.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchShippingCostList.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.shippingcostlist = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchShippingCostList.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(updateOpportunities.fulfilled, (state) => {})
      .addCase(fetchShippingCostListFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
      });
  },
});

export const {
  updateFilter,
  updateShippingCostListStatus,
  removeShippingCostList,
  updateBoundFilter,
} = shippingcostlistSlice.actions;

export {
  fetchShippingCostList,
  updateOpportunities,
  fetchShippingCostListFilters,
  bulkUpdateShippingCostList,
  updateBulkShippingCostListStatus,
  deleteBulkShippingCostList,
  restoreShippingCost,
};

export default shippingcostlistSlice.reducer;
