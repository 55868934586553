function calculateTotalPrices(products) {


    const totalPrices = {};
    if (products && Array.isArray(products) && products.length > 0) {
        for (const product of products) {

            if (product === null) continue;
            // get data from product
            const {unit_price, qty, unit_price_currency, _data} = product;

            // get currency id and object
            const currencyId = unit_price_currency?.id //typeof unit_price_currency === "object" ? unit_price_currency?.id : unit_price_currency? unit_price_currency : 'return';
            const currencyObject = _data?.unit_price_currency //typeof unit_price_currency === "object" ? unit_price_currency : _data ? _data?.unit_price_currency?.data : null;

            // initial first time object for new currency
            if (!totalPrices[currencyId]) totalPrices[currencyId] = {total: 0, currency: currencyObject};

            // calculate total price
            const totalPrice = unit_price * qty;

            // set currency total
            if (totalPrices[currencyId]) {
                totalPrices[currencyId].total += totalPrice;
            } else {
                totalPrices[currencyId].total = totalPrice;
            }
        }

        return totalPrices;
    } else {
        return [];
    }

}

export default calculateTotalPrices;