/**
 * @author Austin Ames
 * @descritpion ArbitEditableText - a text badge with edit icon and changing as an input box
 */
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { HiCheck, HiX, HiPencil } from "react-icons/hi";
import validationHelper from "../product/validation-helper";
import Dropdown from "../sub-elements/dropdown";
import ArbitNormalInput from "./normal-input";

const units = ["px", "em", "%"];

const ArbitEditableText = ({
  value,
  fontSize,
  fontColor,
  bgColor,
  fontWeight,
  showIcon,
  onChange,
  cols,
  validationRule,
  name,
  type,
}) => {
  const [isEditable, setEditable] = useState(false);
  const [lastValue, setLastValue] = useState("");
  const [localValue, setLocalValue] = useState("");
  const [valid, setValid] = useState(true);

  const onHandleEdit = () => {
    setEditable(true);
    setLastValue(value);
    setLocalValue(value);
  };

  const onHandleSave = async () => {
    const response = await validationHelper(validationRule, localValue, name);
    // console.log(response, "In Editable");
    if (response === true) {
      // console.log(validationRule, "after saving");
      setEditable(false);
    } else {
      setEditable(true);
      setValid(false);
    }
    onChange(localValue);
    //
  };

  const onHandleCancel = () => {
    setLocalValue(lastValue);
    // onChange(lastValue);
    setEditable(false);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  }, [isEditable]);

  return (
    <>
      <div
        className={classNames(
          isEditable &&
            `border-[1px] rounded-[8px] bg-gray-50 p-[4px] border-gray-300 ${
              !valid && "border-red-500"
            }`
        )}
      >
        <form
          action=""
          className="flex space-x-[4px] items-center max-w-[120vh] "
        >
          {!isEditable ? (
            <p
              className={classNames(
                fontColor && "text-" + fontColor,
                units.some((unit) => fontSize.endsWith(unit))
                  ? "text-[" + fontSize + "]"
                  : "text-" + fontSize,
                fontWeight && "font-[" + fontWeight + "]",
                `outline-none p-0 w-full pb-1  border-0`,
                isEditable ? "bg-gray-50" : "bg-" + bgColor,
                "overflowStyle"
              )}
            >
              {type === "DOCUMENT" ? (
                <a
                  href={value}
                  className="font-medium text-blue-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  Open
                </a>
              ) : type === "BOOLEAN" ? (
                value === "1" ? (
                  "TRUE"
                ) : (
                  "FALSE"
                )
              ) : (
                value
              )}
            </p>
          ) : type === "DROPDOWN" ? (
            <Dropdown
              placeholder="Attribute Value"
              bg_color={"gray-50"}
              // justify={'evenly'}
              border_color={"gray"}
              text_color={"gray"}
              title={"Attribute Type"}
              value={localValue || ""}
              items={validationRule?.enum}
              onSelect={setLocalValue}
              type="dropdown"
            />
          ) : type === "BOOLEAN" ? (
            <Dropdown
              placeholder="Value"
              bg_color={"gray-50"}
              // justify={'evenly'}
              border_color={"gray"}
              text_color={"gray"}
              title={"Attribute Type"}
              value={
                localValue === "1" || localValue === true ? "TRUE" : "FALSE"
              }
              items={["TRUE", "FALSE"]}
              onSelect={(e) => {
                if (e === "TRUE") {
                  setLocalValue(true);
                }
                if (e === "FALSE") {
                  setLocalValue(false);
                }
              }}
              type="dropdown"
            />
          ) : type === "DATETIME" ? (
            //date time picker
            <ArbitNormalInput
              label="Value"
              value={localValue}
              onChange={(e) => setLocalValue(e)}
              placeholder="Enter Attribute Value"
              type="datetime-local"
              error={!valid}
              helperText="enter a valid date"
            />
          ) : type === "TIME" ? (
            //time picker
            <ArbitNormalInput
              label="Value"
              value={value}
              onChange={(e) => setLocalValue(e)}
              placeholder="Enter Attribute Value"
              type="time"
              error={!valid}
              helperText="enter a valid time"
            />
          ) : type === "DATE" ? (
            //date picker
            <ArbitNormalInput
              label="Value"
              value={value}
              onChange={setLocalValue}
              placeholder="Enter Attribute Value"
              type="date"
              error={!valid}
              helperText="enter a valid date"
            />
          ) : type === "DOCUMENT" ? (
            //file picker
            <ArbitNormalInput
              label="Value"
              // value={value}
              onChange={setLocalValue}
              placeholder="Enter Attribute Value"
              type="file"
              error={!valid}
              helperText="enter a valid file"
            />
          ) : (
            // : type === 'INTEAGER' || type === 'FLOAT' ? (
            //   //number picker
            //   <ArbitNormalInput
            //     label="Value"
            //     value={value}
            //     onChange={setLocalValue}
            //     placeholder="Enter Attribute Value"
            //     type="number"
            //     error={!valid}
            //     helperText='Enter a valid number'
            //   />
            // )
            <input
              type="text"
              ref={inputRef}
              className={classNames(
                fontColor && "text-" + fontColor,
                units.some((unit) => fontSize.endsWith(unit))
                  ? "text-[" + fontSize + "]"
                  : "text-" + fontSize,
                fontWeight && "font-[" + fontWeight + "]",
                `outline-none p-0 w-[300px] border-0 resize-none`,
                isEditable ? "bg-gray-50" : "bg-" + bgColor
              )}
              disabled={!isEditable}
              value={isEditable ? localValue : value}
              onChange={(e) => setLocalValue(e.target.value)}
            />
          )}

          {showIcon &&
            (!isEditable ? (
              <HiPencil
                className="cursor-pointer text-gray-400"
                onClick={() => onHandleEdit()}
              />
            ) : (
              <div className="flex space-x-[4px] items-center">
                <HiCheck
                  className="cursor-pointer text-gray-400"
                  onClick={() => onHandleSave()}
                />
                <HiX
                  className="cursor-pointer text-gray-400"
                  onClick={() => onHandleCancel()}
                />
              </div>
            ))}
        </form>
      </div>
    </>
  );
};

ArbitEditableText.defaultProps = {
  value: "",
  showIcon: true,
  fontSize: "14px",
  fontColor: "gray-900",
  bgColor: "white",
  fontWeight: null,
  onChange: () => {},
};

export default ArbitEditableText;
