import axios from '../../middleware/axios';
import constants from '../../constant';

const fetchTargetSellerListings = async (params) => {
    let url = constants.APIURL.GET_TARGETSELLERFOLDERS
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    //// console.log(response)
    return response
}

const fetchTargetSellerListingsFilters = async (params) => {
    let url = constants.APIURL.GET_TARGETSELLERFOLDERS_FILTERS

    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const createTargetSellerFolder = async (payload) => {
    const response = await axios.post(
        constants.APIURL.GET_TARGETSELLERFOLDERS,
        payload,
        {withCredentials: true}
    )
    return response
}

const updateTargetSellerFolder = async ({id, payload}) => {
    const response = await axios.post(
        constants.APIURL.GET_TARGETSELLERFOLDERS + '/' + id,
        payload,
        {withCredentials: true}
    )
    return response
}

const deleteTargetSellerFolder = async (payload) => {
    const response = await axios.delete(
        constants.APIURL.GET_TARGETSELLERFOLDERS + '/delete/bulk-action',
        {withCredentials: true, data: payload}
    )
    return response
}

const restoreTargetSellerFolder = async (payload) => {
    const response = await axios.patch(
        constants.APIURL.GET_TARGETSELLERFOLDERS + '/restore/bulk-action',
        payload,
        {withCredentials: true}
    )
    return response
}

const bulkStatusUpdateTargetSellerFolder = async (payload) => {
    const response = await axios.patch(
        constants.APIURL.GET_TARGETSELLERFOLDERS + '/status/bulk-action',
        payload,
        {withCredentials: true}
    )
    return response
}


export default {
    fetchTargetSellerListings,
    fetchTargetSellerListingsFilters,
    createTargetSellerFolder,
    updateTargetSellerFolder,
    deleteTargetSellerFolder,
    restoreTargetSellerFolder,
    bulkStatusUpdateTargetSellerFolder
}
 