import Util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import { useRef, useState } from "react";
import priceHandler from "../../../utils/priceHandler";

const EstTransferCost = ({ item, onSubmit, type, buttonText, title }) => {
  const popupRef = useRef(null);
  const closePopupExternally = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  const handleRequest = async (e) => {
    e.preventDefault();
    if ( Number(item?.[type]) !== Number(cost)) {
      onSubmit(
        {
          id: item?.id,
          payload: { [type]: Number(cost)},
          type,
        },
        (data) => {
          setCost(data);
          closePopupExternally();
        }
      );
    }
  };

  const [cost, setCost] = useState(+item?.[type] || null);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleRequest(e);
    }
  };

  return (
    <div>
      <Util.popUp
        toggle={(selected) => (
          <div className="flex justify-start     gap-1.5 items-center ">
            {+item?.[type] ? (
              <span className="text-purple-700 text-[14px] leading-[21px] cursor-pointer  font-medium ">
                ${priceHandler(+item?.[type])} / kg
              </span>
            ) : (
              <button className="text-blue-700 font-medium text-[14px] leading-normal ">
                {buttonText}
              </button>
            )}
          </div>
        )}
        action="click"
        forcePosition={"bottom"}
        ref={popupRef}
      >
        <div className="flex flex-col gap-2   py-1 px-2 z-[999999]">
          <span className="text-[14px] font-medium leading-[21px] text-gray-900">
            {title}
          </span>
          <form
            onSubmit={handleRequest}
            className="border border-gray-300 bg-gray-50 flex items-center rounded-[4px] pr-2 py-0.5"
          >
            <input
              className="[&::-webkit-inner-spin-button]:appearance-none border-none bg-gray-50 text-[13px] leading-[18px] text-gray-500 rounded-[4px] h-[26px] w-[120px]"
              type="text"
              placeholder={type === "transfer_cost" ? "usd/kg" : "usd/shipment"}
              value={cost === 0 ? "" : cost}
              autoFocus
              onChange={(e) => setCost(e.target.value)}
              onKeyDown={handleKeyDown} // Handle Enter key press
            />
            <button type="submit">
              <Icons.check className="w-3 h-3 text-blue-600" />
            </button>
          </form>
        </div>
      </Util.popUp>
    </div>
  );
};
export default EstTransferCost;
