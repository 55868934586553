import orderStatus from "./table/orderStatus";
import orderItemStatus from "./table/orderItemStatus";
import salesChannelStatus from "./table/salesChannelStatus";
import orderRowAction from "./table/orderRowAction";
import orderId from "./table/orderid";
import Item from "./table/item";
import numberPriorityColor from "./table/numberPriorityColor";
import datePriorityColor from "./table/datePriorityColor";
import weight from "./table/weight";
import shipTo from "./table/shipTo";
import profit from "./table/profit";
import shipmentLabel from "./table/shipmentLabel";
import saveWeight from "./table/saveWeight";
import internalNote from "./table/internalNote";
import shippingContent from "./table/shippingContent";
import OrderStatusOld from "./table/orderStatusOld";
import salesChannelStatusOld from "./table/salesChannelStatusOld";

export default {
    orderStatus,
    orderItemStatus,
    salesChannelStatus,
    orderRowAction,
    orderId,
    Item,
    numberPriorityColor,
    datePriorityColor,
    weight,
    shipTo,
    profit,
    shipmentLabel,
    saveWeight,
    internalNote,
    shippingContent,
    OrderStatusOld,
    salesChannelStatusOld
}