import axios from "./../../middleware/axios";
import constants from "../../constant";
import {apiutils} from "../../utils";

const fetchOffers = async (params) => {
    let url = constants.APIURL.GET_OFFERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
};
const fetchOffer = async (id) => {
    const response = await axios.get(constants.APIURL.GET_OFFER + '/' + id, {withCredentials: true});
    return response;
}

const updateOffers = async ({payload}) => {
    const response = await axios.post(constants.APIURL.GET_OPPORTUNITIES_UPDATE, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}

const fetchOffersFilters = async (params) => {
    let url = constants.APIURL.GET_OFFERS_FILTERS;
    if (params) url = url.concat(params).concat('&filter[fulfillment]=');
    const response = await axios.get(url, {withCredentials: true})
    return response
}

const offersUpdateStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_OFFERS_ACTIVATION + payload + '/activation', {withCredentials: true})
    return response
}

const connectProduct = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_OFFERS + '/connect/' + payload?.offerId + '/product/' + payload?.productId, {withCredentials: true})
    return response
}
const disconnectProduct = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_OFFERS + '/connect/' + payload?.offerId + '/product', {withCredentials: true})
    return response
}


export default {
    fetchOffers,
    fetchOffer,
    updateOffers,
    fetchOffersFilters,
    offersUpdateStatus,
    connectProduct,
    disconnectProduct
};
