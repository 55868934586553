const sortView = ({ callback }) => {
    return {
      list: [
        {
            label: "Order Date",
            value: "order_date",
            onClick: (item) => {
                callback("order_date");
            }
        },
        {
          label: "Created At",
          value: "created_at",
          onClick: (item) => {
            callback("created_at");
          },
        },
        {
          label: "Updated At",
          value: "updated_at",
          onClick: (item) => {
            callback("updated_at");
          },
        },
        {
          label: "Item Title",
          value: "item_title",
          onClick: (item) => {
            callback("item_title");
          },
        },
        {
          label: "Items Count",
          value: "item_count",
          onClick: (item) => {
            callback("item_count");
          },
        },
        {
          label: "Total Qty",
          value: "total_qty",
          onClick: (item) => {
            callback("total_qty");
          },
        },
        {
          label: "Billable Weight",
          value: "billable_weight",
          onClick: (item) => {
            callback("billable_weight");
          },
        },
        {
          label: "Actual Weight",
          value: "actual_weight",
          onClick: (item) => {
            callback("actual_weight");
          },
        },
        {
          label: "Dim Weight",
          value: "dim_weight",
          onClick: (item) => {
            callback("dim_weight");
          },
        },
        {
          label: "Ship To Name",
          value: "ship_to_name",
          onClick: (item) => {
            callback("ship_to_name");
          },
        },
        {
          label: "Ship To State",
          value: "ship_to_state",
          onClick: (item) => {
            callback("ship_to_state");
          },
        },
        {
          label: "Ship To Country",
          value: "ship_to_country",
          onClick: (item) => {
            callback("ship_to_country");
          },
        },
        {
          label: "Sales Channel",
          value: "sales_channel",
          onClick: (item) => {
            callback("sales_channel");
          },
        },
        {
          label: "Sales Account",
          value: "sales_account",
          onClick: (item) => {
            callback("sales_account");
          },
        },
      ],
    };
  };
  
  export default sortView;