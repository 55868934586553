import {createAsyncThunk} from "@reduxjs/toolkit";
import api, {setToken} from './api';


const fetchLoginUser = createAsyncThunk(
    'api/login',
    async (user, thunkAPI) => {
        const response = await api.fetchLoginUser(user)
        setToken(response?.data?.data?.expiresIn)
        return response.data
    }
)

const fetchRegisterUser = createAsyncThunk(
    'api/register',
    async (user, thunkAPI) => {
        const response = await api.fetchRegisterUser(user)
        return response.data
    }
)

const fetchUserwithToken = createAsyncThunk(
    'api/user',
    async (thunkAPI) => {
        const response = await api.fetchUserwithToken()
        return response.data
    }
)

const postUserLogout = createAsyncThunk(
    'api/logout',
    async (thunkAPI) => {
        const response = await api.postUserLogout()
        return response.data
    }
)

export {fetchLoginUser, fetchRegisterUser, fetchUserwithToken, postUserLogout}
