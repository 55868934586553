import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchTargetSellerListings = createAsyncThunk(
    'get:api/v1/targetsellerlistings',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
          dispatch(fetchLoading());
        }
        const data = await api.fetchTargetSellerListings(
          typeof payload === "string" ? payload : payload.query
        );
        return { ...data?.data, append: payload?.append };
      }
)

const fetchTargetSellerListingsFilters = createAsyncThunk(
    'get:api/v1/targetsellerlistings/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchTargetSellerListingsFilters(params)
        return response.data
    }
)

const createTargetSellerFolder = createAsyncThunk(
    'post:api/v1/targetsellerlistings',
    async (payload, thunkAPI) => {
        const response = await api.createTargetSellerFolder(payload)
        return response.data
    }
)

const updateTargetSellerFolder = createAsyncThunk(
    'put:api/v1/targetsellerlistings',
    async (payload, thunkAPI) => {
        const response = await api.updateTargetSellerFolder(payload)
        return response.data
    }
)

const deleteTargetSellerFolder = createAsyncThunk(
    'delete:api/v1/targetsellerlistings',
    async (payload, thunkAPI) => {
        const response = await api.deleteTargetSellerFolder(payload)
        return response.data
    }
)

const restoreTargetSellerFolder = createAsyncThunk(
    'patch:api/v1/targetsellerlistings',
    async (payload, thunkAPI) => {
        const response = await api.restoreTargetSellerFolder(payload)
        return response.data
    }
)

const bulkStatusUpdateTargetSellerFolder = createAsyncThunk(
    'patch:api/v1/targetsellerlistings',
    async (payload, thunkAPI) => {
        const response = await api.bulkStatusUpdateTargetSellerFolder(payload)
        return response.data
    }
)


export {
    fetchTargetSellerListings,
    fetchTargetSellerListingsFilters,
    createTargetSellerFolder,
    updateTargetSellerFolder,
    deleteTargetSellerFolder,
    restoreTargetSellerFolder,
    bulkStatusUpdateTargetSellerFolder
}
