import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchShippingTemplates = createAsyncThunk(
    "get:api/v1/ShippingTemplate",
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingTemplate(payload);
        return response;
    }
);

const createShippingTemplate = createAsyncThunk(
    "post:api/v1/ShippingTemplate",
    async (payload, thunkAPI) => {
        const response = await api.createShippingTemplate(payload);
        return response;
    }
);

const updateShippingTemplate = createAsyncThunk(
    "put:api/v1/ShippingTemplate",
    async ({id, payload}) => {
        const response = await api.updateShippingTemplate(id, payload);
        return response;
    }
);

const deleteShippingTemplate = createAsyncThunk(
    "delete:api/v1/ShippingTemplate",
    async (payload, thunkAPI) => {
        const response = await api.deleteShippingTemplate(payload);
        return response;
    }
);

const searchShippingTemplates = createAsyncThunk(
    "get:api/v1/ShippingTemplate/search",
    async (payload, thunkAPI) => {
        const response = await api.searchShippingTemplate(payload);
        return response.data;
    }
);

const fetchShippingTemplatesFilters = createAsyncThunk(
    "get:api/v1/ShippingTemplate/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingTemplatesFilters(payload);
        return response;
    }
);

const bulkDeleteShippingTemplates = createAsyncThunk(
    "delete:api/v1/ShippingTemplate/delete/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteShippingTemplates(payload);
        return response;
    }
);

const bulkStatusUpdateShippingTemplates = createAsyncThunk(
    "put:api/v1/ShippingTemplate/status/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkStatusUpdateShippingTemplates(payload);
        return response;
    }
);

const restoreShippingTemplates = createAsyncThunk(
    "post:api/v1/ShippingTemplate/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreShippingTemplates(payload);
        return response;
    }
);


const duplicateShippingTemplate = createAsyncThunk(
    "post:api/v1/shipping_template/duplicate/",
    async (payload, thunkAPI) => {
        const response = await api.duplicateShippingTemplate(payload);
        return response;
    }
);


export {
    fetchShippingTemplates,
    createShippingTemplate,
    updateShippingTemplate,
    deleteShippingTemplate,
    searchShippingTemplates,
    fetchShippingTemplatesFilters,
    bulkDeleteShippingTemplates,
    bulkStatusUpdateShippingTemplates,
    restoreShippingTemplates,
    duplicateShippingTemplate
};
