function updateObjectByPath(object, path, value) {
    const keys = path.split(".");

    //const updatedObject = object;

    function extractPartsFromString(str) {
        const regex = /^\[(.+?):(.+?)\]$|^([^[\]]+)$/;
        const match = str.match(regex);
        const key = match[3] || null;
        const child = match[1] || null;
        const val = match[2] || null;

        return {key, child, val};
    }

    function updateRecursive(objProxy, keyIndex) {
        const {key, child, val} = extractPartsFromString(keys[keyIndex]);
        const obj = JSON.parse(JSON.stringify(objProxy))

        if (Array.isArray(obj)) {
            const newArray = obj.map(item => {
                if (String(item[child]) === String(val)) {

                    if (keyIndex === keys.length - 1) {
                        if (value !== undefined) {
                            return value
                        } else {
                            return "x_mustBeDeleted"
                        }
                    } else {
                        return updateRecursive(item, keyIndex + 1)
                    }
                } else {
                    return item;
                }


            });
            return newArray.filter(child => child !== "x_mustBeDeleted");

        } else if (typeof obj === "object") {
            if (keyIndex === keys.length - 1) {
                if (value !== undefined) {
                    return {...obj, [key]: value};
                } else {
                    const {[key]: removedValue, ...updatedObj} = obj;
                    return updatedObj;
                }
            } else {
                return {...obj, [key]: updateRecursive(obj[key], keyIndex + 1)};
            }
        } else {
            return obj;
        }


    }

    return updateRecursive(object, 0);
}

export default updateObjectByPath;