import { createSlice } from "@reduxjs/toolkit";
import updateObjectByPath from "./../../components/dynamic-form/util/updateState";

import { fetchFilters, fetchRows } from "./thunk";

const initialState = {
  rows: [],
  loading: "idle",
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
    limit: 20,
    include: null,
    tab: null,
  },
  boundFilters: {},
  filtersMeta: {},
  boundFilters: {},
  meta: {},
  filterLoading: "idle",
};

export const dynamicPageSlice = createSlice({
  name: "dynamicPage",
  initialState,
  reducers: {
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        `rows.[id:${action.payload.id}]${
          action.payload.path ? "." + action.payload.path : ""
        }`,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.loading = "idle";
        state.rows = action.payload?.append
          ? [
              ...state.rows,
              ...action.payload.data.data?.filter(
                (row) => !state.rows.find((r) => r.id === row.id)
              ),
            ]
          : action.payload.data.data;
        state.meta = action.payload?.data?.meta;
      })
      .addCase(fetchRows.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload.data.data || action.payload.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export const { updateRow, updateFilter, updateBoundFilter, fetchLoading } =
  dynamicPageSlice.actions;
/*export {
    
    fetchOrders, fetchSingleOrder, viewOrder, fetchOrdersFilters, createOrder,
    bulkDeleteOrders, bulkRestoreOrders,
    apiActions, apiDetailActions,
    fetchShipmentCarrier, createShipment, //Shipment, carrier
    addNote, fetchNotes, deleteNote, // Notes
    updateBillableWeight, //Weight
    createSupplyRequest, createSupplyRequestOrderItem // Supply request
}*/
export { fetchRows, fetchFilters };
export default dynamicPageSlice.reducer;
