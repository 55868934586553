import React from "react";
import MainModal from "./MainModal";

const FullScreenModal = ({title, children, onClose, ...props}) => {
    return (
        <MainModal

            mainClassName="h-screen"
            item={{
                title,
                cancel: onClose,

                view: () => (
                    <div className="h-screen flex flex-col">
                        <div className="flex-grow">{children}</div>
                    </div>
                ),
            }}
            {...props}
        />
    );
};

export default FullScreenModal;
