// channels/add-edit-modal.js
// -----------------------------------------

// Import general libraries
import React from "react";
import PropTypes from "prop-types";
// Import Elements
import MainModal from "../../dynamic-page/MainModal";

// Main Component: ChannelAddEditModal
const MethodForm = React.memo(({ setCurrentModal, onClose }) => {
  return (
    <MainModal
      containerClassName="!px-4 !pb-4"
      item={{
        title: `Create Supply Channel Listing(s)`,
        cancel: onClose,
        view: (item) => (
          <div className="w-[632px]   flex-col justify-start items-start gap-2 inline-flex pt-2 border-t border-t-gray-200 ">
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <button
                className="grow shrink basis-0 h-40 p-6 bg-white rounded-lg shadow border border-gray-200 flex-col justify-start items-start inline-flex cursor-pointer"
                onClick={() => setCurrentModal("scratchForm")}
              >
                <div className="self-stretch h-[110px] flex-col justify-start items-start gap-3 flex">
                  <div className="self-stretch h-[110px] flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-gray-900 text-2xl font-bold font-['Inter'] leading-[30px]">
                      from Scratch
                    </div>
                    <div className="self-stretch text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                      Select this if you are creating a new supply channel
                      listing from scratch.
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <button
                className="grow shrink basis-0 p-6 bg-white rounded-lg shadow border border-gray-200 flex-col justify-start items-start inline-flex cursor-pointer"
                onClick={() => setCurrentModal("importForm")}
              >
                <div className="self-stretch  flex-col justify-start items-start gap-3 flex">
                  <div className="self-stretch  flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-gray-900 text-2xl font-bold font-['Inter'] leading-[30px]">
                      Import the Bulk Upload File
                    </div>
                    <div className="self-stretch text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                      Select this if you want to create listings with bulk
                      upload. Accepted formats are csv and xlsx.
                    </div>
                  </div>
                  <button
                    className="justify-start items-center gap-2 inline-flex"
                    onClick={() => {
                      window.open(
                        "https://docs.google.com/spreadsheets/d/1ZGocDuop5RzBv0c-VvWx6IDekrG7GeAat58O3YyK64w/edit#gid=896533631",
                        "_blank"
                      );
                      setTimeout(() => {
                        setCurrentModal("importForm");
                      }, 1000);
                    }}
                  >
                    <div className="text-blue-600 text-base font-normal font-['Inter'] leading-tight">
                      Go to the template.
                    </div>
                    <div className="w-4 h-4 p-[2.67px] justify-center items-center flex" />
                  </button>
                </div>
              </button>
            </div>
          </div>
        ),
      }}
    />
  );
});

MethodForm.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MethodForm;
