import React, {useEffect, useState} from "react";
import DynamicTable from "../../../../../dynamic-page";

import tableView from "./../model-view/table";
import mapData from "./../model-view/mapData";
import getActionList from "../../../../../dynamic-page/util/getActionList";
import Icons from "../../../../icons";
import updateState from "../../../../../dynamic-form/util/updateState";

import {useDispatch} from "react-redux";
import {apiActions, createSupplyRequestOrderItem} from "../../../../../../store/orders";
import util from "../../../../../dynamic-page/util";

import {IoDuplicate} from "react-icons/io5";
import updateOrderItemRow from "../util/updateOrderItemRow";

const ProductList = ({
                         orderItems,
                         actionButton,
                         actionCallBack,
                         actionEditCallBack,
                         actionAddProduct,
                         orderId,
                         isView,
                         type
                     }) => {
    const [list, setList] = useState(null)
    const [selectedRow, setSelectedRow] = useState([])
    const [deleteList, setDeleteList] = useState([])

    const dispatch = useDispatch()

    const giveSelectedItemId = (item) => {
        const orderId = item ? [item.id] : selectedRow.map((item) => item.id)
        const checkLocalAndView = (item, mode) => (
            item ?
                item?._data?.isView === mode ?
                    [item.id]
                    :
                    []
                :
                selectedRow.filter((item) => {
                    return item?._data?.isView === mode;
                }).map((item) => item.id)
        )
        const localItemId = checkLocalAndView(item)
        const viewItemId = checkLocalAndView(item, true)

        setSelectedRow([])
        return {local: localItemId, view: viewItemId}
    }
    const handleCallApiInAction = (action, item, func, method = null) => {
        dispatch(apiActions({
            data: {orders: giveSelectedItemId(item)},
            action: action,
            method: method
        })).then((result) => {
            func(result)
        })
    }

    const handleUpdateList = (data) => {
        let newArray = {new: list}
        newArray = updateState(newArray, `new.[id:${data.id}]`, data)
        actionCallBack(newArray.new)
    }

    useEffect(() => {
        actionButton(actions(selectedRow))
    }, [selectedRow]);


    const handleFetchAndUpdateItem = (orderId, itemId, notification) => {
        updateOrderItemRow(orderId, itemId).then((rs) => {
            let newArray = {new: list}
            newArray = updateState(newArray, `new.[id:${itemId}]`, rs)
            actionCallBack(newArray.new)
            util.notifier(notification)
        })
    }

    const actionList = {

        editItem: {
            label: <div className={"flex flex-row gap-[10px]"}><Icons.edit className={"w-[15px]"}/>Edit Item</div>,
            onClick: (item) => {
                const updateRow = (data) => {
                    let newArray = {new: list}
                    newArray = type === 2 ? updateState(newArray, `new.[product:${data.product}]`, data) : updateState(newArray, `new.[offer:${data.offer}]`, data)
                    actionCallBack(newArray.new)
                }
                updateRow(item)
                // actionEditCallBack({item:item, callBack: (async (data)=> {
                //     if (data) {
                //         if (data?._data?.isView){

                //             const mapData = await prepareDataToAddItem(data)
                //             const orderId = data._data.order_id
                //             dispatch(apiActions({
                //                 data: mapData,
                //                 action: "order-item",
                //                 method: "POST",
                //                 orderId: orderId,
                //                 itemId: data.id
                //             })).then((result) => {
                //                 util.notifier(result?.payload?.data)
                //                 if (result?.payload?.data?.type === "success") {
                //                     updateRow(prepareDataToViewItem(result?.payload?.data?.data))
                //                 }
                //             })
                //         } else {
                //             updateRow(data)
                //         }

                //     }
                //         //actionEditCallBack(null)
                // })})
            },
            bulkDisable: true
        },
        localDuplicateOrderItem: {
            label: <div className={"flex flex-row gap-[10px]"}><IoDuplicate className={"w-[17px] h-[17px]"}/>Duplicate
                Item</div>,
            onClick: (item) => {
                actionAddProduct(item)
            }
        },
        duplicateItem: {
            label: <div className={"flex flex-row gap-[10px]"}><IoDuplicate className={"w-[17px] h-[17px]"}/>Duplicate
                Item</div>,
            onClick: (item) => {
                if (item?._data?.isView) {
                    actionAddProduct(item, false)
                } else {
                    actionAddProduct(item, true)
                }

            }
        },
        removeItem: {
            label: <div className={"text-red-600 flex flex-row gap-[10px]"}><Icons.del className={"w-[15px]"}/>Remove
                Item</div>,
            onClick: (data) => {
                const changelist = giveSelectedItemId(data)
                const deleteLocal = (items) => {
                    let newArray = {new: list}
                    items.map((i, index) => {
                        newArray = updateState(newArray, `new.[id:${i}]`)
                    })
                    actionCallBack(newArray.new)
                }

                if (changelist.view.length > 0) {
                    const orderId = list.find(item => item.id === changelist.view[0])._data.order_id
                    dispatch(apiActions({
                        data: {order_items: changelist.view},
                        action: "order-item",
                        method: "DELETE",
                        orderId: orderId
                    })).then((result) => {
                        util.notifier(result?.payload?.data)
                        if (result?.payload?.data?.type === "success") {
                            deleteLocal([...changelist.local, ...changelist.view])
                        }
                    })
                } else {
                    deleteLocal(changelist.local)
                }

            }
        },

        requestSupply: {
            label: "Request Supply",
            onClick: (item) => {
                const items = item ? [item.id] : selectedRow.map((item) => item.id)
                dispatch(createSupplyRequestOrderItem(
                    {orderId: orderId, items: {order_items: items}},
                )).then((result) => {
                    handleFetchAndUpdateItem(item._data.order_id, item.id, result?.payload?.data)
                })
            }
        },

        refundRequest: {
            label: "Refunded",
            onClick: (item) => {
                const items = item ? [item.id] : selectedRow.map((item) => item.id)
                dispatch(apiActions({
                    data: {order_items: items},
                    action: "order-item/refund-request",
                    orderId: orderId
                })).then((result) => {
                    handleFetchAndUpdateItem(item._data.order_id, item.id, result?.payload?.data)
                })
            }
        },
        cancelByCustomer: {
            label: "Cancel by Customer",
            onClick: (item) => {
                const items = item ? [item.id] : selectedRow.map((item) => item.id)
                dispatch(apiActions({
                    data: {order_items: items},
                    action: "cancelled-by-buyer",
                    orderId: orderId
                })).then((result) => {
                    util.notifier(result?.payload?.data)
                })
            }
        },

        connectProduct: {
            label: "Connect Product",
            onClick: (data) => {
                if (data?._data?.isView) {
                    const product_id = data?.arbitbox_product_id
                    const connectionAction = product_id ? "connect-product" : "disconnect-product"
                    dispatch(apiActions({
                        data: {product_id: product_id, sku: data?.sku},
                        action: connectionAction,
                        orderId: `${data?._data.order_id}/${data?.id}`,
                    })).then((result) => {
                        //util.notifier(result?.payload?.data)
                        handleFetchAndUpdateItem(data._data.order_id, data.id, result?.payload?.data)
                        if (result?.payload?.data?.type === "success") {
                            //handleFetchAndUpdateItem(item._data.order_id, item.id, result?.payload?.data)
                            //handleUpdateList(data)
                        }
                    })
                } else {
                    handleUpdateList(data)
                }
            }
        },
        updateNote: {
            label: "Update Note",
            onClick: (data) => {
                let newArray = {new: list}
                newArray = updateState(newArray, `new.[id:${data.id}].internal_notes`, data.list)
                newArray = updateState(newArray, `new.[id:${data.id}].internal_notes_enabled`, data?.list?.length > 0)
                actionCallBack(newArray.new)
            }
        },
        updateShippingContent: {
            label: "Update Shipping Content",
            onClick: (data) => {

                let newArray = {new: list}
                newArray = updateState(newArray, `new.[id:${data.id}].shipping_content`, data.text)
                newArray = updateState(newArray, `new.[id:${data.id}].shipping_content_enabled`, data?.text?.length > 0)
                actionCallBack(newArray.new)

            }
        },
        updateGtip: {
            label: "Update Gtip",
            onClick: (data) => {

                let newArray = {new: list}
                newArray = updateState(newArray, `new.[id:${data.id}].gtip`, data.text)
                newArray = updateState(newArray, `new.[id:${data.id}].gtip_enabled`, data?.text?.length > 0)
                actionCallBack(newArray.new)

            }
        },
        createProduct: {
            label: "Create Product",
            onClick: (data) => {
            }
        }
    };


    //
    const statusList = {

        1: { //AWAITING_SHIPMENT
            label: "Awaiting Shipment",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]//[actionList.editItem, actionList.requestSupply, actionList.duplicateItem, actionList.refundRequest, actionList.cancelByCustomer, actionList.removeItem]
        },
        2: { //READY_TO_SHIP
            label: "Ready to Ship",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]
        },
        3: { //SUPPLY_REQUIRED
            label: "Supply Required",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]
        },
        4: { //AWAITING_ACCEPTANCE
            label: "Awaiting Acceptance",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]
        },
        5: { //CANCELLED_BY_BUYER
            label: "Cancelled by Buyer",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]
        },
        6: { //REFUNDED
            label: "Refunded",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]
        },
        7: { //CANCEL_REQUESTED
            label: "Shipped",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]
        },
        8: { //AWAITING_CONNECTION
            label: "Awaiting Connection",
            actions: [actionList.removeItem, actionList.editItem, actionList.refundRequest, actionList.requestSupply, actionList.cancelByCustomer]
        },
        "no status": {
            label: "New",
            actions: [actionList.removeItem, actionList.editItem, actionList.duplicateItem]
        }

    }

    useEffect(() => {
        setList(mapData(orderItems))
    }, [orderItems]);

    const actions = (row) => {
        return getActionList(row, statusList)
    }
    const onSelectRow = (Rows) => {
        setSelectedRow(Rows);
    }


    const handleActions = (data) => {
        actionList[data.action].onClick(data.data)
    }


    return (
        <>
            {orderItems && orderItems.length > 0 &&
                <DynamicTable
                    view={tableView}
                    data={tableView(orderItems, handleActions, type, isView)}
                    actions={actions}
                    loading='idle'
                    selectedRow={selectedRow} onSelect={onSelectRow}
                    style={{
                        table: {
                            style: {width: "100%", minWidth: "100%", height: "100%", maxHeight: "400px", zIndex: 0},
                            className: 'overflow-auto'
                        },
                        // tbody: {
                        //     className: "border-t border-gray-200 mx-[16px] block"
                        // },
                        header: {
                            className: 'justify-center ', '_delete': {className: "hidden"},
                            '_actions': {th: "hidden w-0"},
                            image: {className: "hidden"},
                        },
                        row: {
                            className: "!px-[8px] !py-[10px] !items-center !justify-center flex text-[12px] flex flex-1 h-[54px]",
                            "_actions": {td: 'hidden', className: "!py-[0px]"},
                            "_delete": {
                                td: isView ? 'hidden' : '',
                                className: "!py-[0px]"
                            },


                        },
                        noData: {
                            className: "hidden"
                        }
                    }}
                />

            }
            <style>
                {`
                    @keyframes deleteAnimation {
                        0% {  height: 100% }
                        100% { height:0px }
                    }
                `}
                {deleteList.length > 0 && deleteList.map((i, index) => {
                    setTimeout(function () {
                        setDeleteList(updateState({new: deleteList}, `new.[id:${i.id}]`))
                    }, 2000);
                    return (`
                        #dynamicTableRow_${i.id} td > div{
                            animation: deleteAnimation 0.5s linear forwards; overflow: hidden;
                        }
                    `)

                })}
            </style>
        </>

    )
}
export default ProductList