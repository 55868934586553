import React from "react";
import Components from "../../orders/components";
import components from "../components"
import ImageMagnifier from "../../dynamic-page/debt/magnifier";

import moment from "moment";
import util from "../../dynamic-page/util";
import { howLongFromNow } from "../../../utils/helper";
import Route from "../components/table/route";
import StatusHistory from "../components/table/statusHistory";

const statusLabel = (item) => [
  {
    action: "Shipped",
    name: item?.accepted_by?.name,
    date: item?.accpeted_at,
    qty: item?.quantity,
  },
  {
    action: "Dilevered",
    name: item?.purchase?.created_by?.name,
    date: item?.purchase?.created_at,
    qty: item?.purchase?.quantity,
  },
  {
    action: "Created",
    name: item?.purchase?.supply_request?.requester?.name,
    date: item?.purchase?.supply_request?.request_date_time,
    qty: item?.purchase?.supply_request?.qty,
  },
];

const qtyReducer = (items) => {
  return items.reduce((acc, item) => {
    return acc + item?.quantity;
  }, 0);
};

const tableView = (list, callBack, isAwaiting, updateRow) => {
  
  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };


  return {
    list: list,
    theme: {
      control: (item) => (
        <div className={"w-[20px] h-[50px]"}>
          <components.OrderRowAction
            actions={{
              order_id: item?.id,
              internal_notes: item?.notes_count,
              shipping_content: item?.content,
            }}
          />
        </div>
      ),
      status: (item) => (
        <div
          className={
            "w-full h-[64px] flex flex-col gap-1 justify-center items-center relative"
          }
        >
          <components.orderStatus
            isAwaiting={isAwaiting}
            statusId={item?.status_id}
            text={item?.status}
            item={{
              order: item,
            }}
            orderId={item?.id}
            orderHistory={
              <StatusHistory title="Order History" list={statusLabel(item)} />
            }
            callBack={callBack}
          />
        </div> 
      ),
      "created at": (item) => (item?.created_at ? (
            <div className="  flex-col justify-center gap-2 items-start whitespace-nowrap inline-flex w-[124px] ">
              <div className="self-stretch font-medium justify-start items-center gap-0.5 inline-flex">
                <div className="text-gray-900 text-sm font-normal !leading-[14px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.created_at)).format(
                    "MMM D, YYYY"
                  )}
                </div>
              </div>
              <div className="self-stretch justify-between items-center gap-4 inline-flex">
                <div className="text-gray-600 text-xs font-normal !leading-[18px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.created_at)).format("h:mm a")}
                </div>
                <div
                  className={`text-green-600 text-xs font-medium !leading-[18px] whitespace-nowrap`}
                >
                  {moment(item?.created_at).fromNow() === "a few seconds ago"
                    ? "Just now"
                    : howLongFromNow(item?.created_at, true, null, true)}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center !px-[8px] !py-[12px] items-start gap-1 inline-flex">
              -
            </div>
          )
      ),
      "transfer id": (item) => (
        <div
          className={"flex flex-col gap-[0px] relative justify-start  w-full"}
        >
          <components.orderId
            callBack={callBack}
            orderId={item.id}
            order_numbers={item.order_numbers}
            order={{
              order_number: item?.code,
              marketplace: item?.sales_channel_account?.channel?.marketplace,
              ...item?.sales_channel_account,
            }}
            item={item}
          />
        </div>
      ),
      route: (item) => <Route from={item?.from_warehouse} to={item?.destination} />,
      name: (item) => (
        <div
          className={"w-[100%]  flex flex-row gap-[16px] px-1 justify-start"}
          style={{ width: "100%" }}
        >
        <util.truncateText
          maxLines={2}
          innerClassName={"!text-[14px] !font-medium !leading-[17.5px] !max-w-[260px] "}
          tooltip={true}
        >
          {item?.name}
        </util.truncateText>
        </div>
      ),

      qty: (item) => (
        <div className={"flex"}>
          <Components.numberPriorityColor
            number={item?.transfer_items_count}
            color={
              item?.transfer_items_count <= 1
                ? "gray"
                : 1 < item?.transfer_items_count && item?.transfer_items_count <= 5
                ? "yellow"
                : "red"
            }
            className={"text-[14px]"}
          />
        </div>
      ),
      weight: (item) => {
        return (
          <div className={"flex flex-col gap-[0px]"}>
              <components.Weight
              weight={{
                  selected: +item?.actual_weight > +item?.dimensional_weight ? "actual" : "dimensional",
                  billable: item?.billable_weight
                    ? (+item?.billable_weight).toFixed(2)
                    : "-", //item?.billable_weight != null ? item?.billable_weight : "-",
                  actual: item?.actual_weight
                    ? item?.actual_weight
                    : "-", //item?.actual_weight != null ? item?.actual_weight :  "-",
                  dim: item?.dimensional_weight ? item?.dimensional_weight : "-", //item?.dim_weight != null ? item?.dim_weight : "-",
                }}
              />
          </div>
        );
      },
      "tracking id": (item) => (
        <div className={"flex  justify-start !w-full flex-1"}>
          <components.shipmentLabel
            shipment={{
              shipment_labels: item?.shipment_labels,
            }}
            orderItem={item}
            callBack={callBack}
            updateRow={updateRow}
          />
        </div>
      ),
    },
  };
};
export default tableView;

const CopyIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 20 20"
    className="w-4 h-4 "
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
    <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
  </svg>
);
