import React, { useEffect, useState } from "react";
import ShipmentLabelList from "./shipmentLabelList";
import { useSelector } from "react-redux";
import util from "../../../dynamic-page/util";
import { IoStorefront } from "react-icons/io5";
import descriptiveContent from "../../../../utils/descriptiveContent";
import shortenString from "../../../dynamic-page/util/shortenString";
import Icons from "../../../orders/icons";
import CopyText from "../../../dynamic-page/util/copyText";
import PopupUp from "../../../dynamic-page/util/popUp";
import PropTypes from "prop-types";
import Button from "../../../dynamic-form/elements/Button";

const ChevronRight = Icons.chevronRight;
const Track = Icons.track;
const CreateLabel = Icons.createLabel;
const ShowLabel = Icons.showLabel;
const PrintLabel = Icons.printLabel;
const ShipmentLabel = ({ shipment, orderItem, callBack, updateRow }) => {

  const [shipmentData, setShipmentData] = useState(null);
  const data = useSelector((state) => state.orders.orders);

  useEffect(() => {
    const findItem = data?.find((item) => item?.id === orderItem?.id);
    const innerShipment = shipment;
    const billableWeight =
      findItem?.billable_weight || orderItem.billable_weight;
    const returnData = { ...innerShipment, billableWeight: billableWeight };

    setShipmentData(returnData);
  }, [data, orderItem.billable_weight, orderItem?.id, shipment]);

  const handleCreateShipment = () => {
    callBack({
      action: "createShipment",
      data: {
        id: orderItem?.id,
        from_warehouse: orderItem?.from_warehouse,
        to_warehouse: orderItem?.destination,
        items_qty: orderItem?.transfer_items_count,
        billable_weight: orderItem?.billable_weight,
      },
    });
  };

  const handlePrintLabel = (print) => {
    callBack({
      action: "printLabel",
      data: {
        file: orderItem?.shipment_labels?.find((label) => label?.is_primary)
          ?.label_file_png,
      },
    });
  };
  const labelListRef = React.useRef(null);

  const handleViewLabel = (print) => {
    callBack({
      action: "viewLabel",
      data: {
        file: orderItem?.shipment_labels?.find((label) => label?.is_primary)
          ?.label_file_png,
      },
    });
  };

  const handleViewSecodaryLabel = (label) => {
    labelListRef.current?.close();
    callBack({
      action: "viewLabel",
      data: {
        file: label?.label_file_png,
      },
    });
  };

  const primaryShipmentLabel = shipmentData?.shipment_labels?.find((item) => {
    if (item?.is_primary === 1 && item.is_hidden === 0) {
      return item;
    }
  });

  const hiddenPrimaryShipmentLabel = shipmentData?.shipment_labels?.find(
    (item) => {
      return item?.is_primary === 1 && item.is_hidden === 1;
    }
  );

  const toggle = (selected) => (
    <ToggleButton selected={selected} orderItem={orderItem} handleCreateShipment={handleCreateShipment} />
  );

  const listToggle = (selected) => (
    <ToggleComponent selected={selected} shipmentData={shipmentData} />
  );

  return (
    <div className={"max-w-[300px] w-full"}>
      {shipmentData && (
        <div
          className={"flex flex-col gap-[4px]  justify-start w-full"}
          style={{ width: "100%" }}
        >
          <div
            className={
              "flex flex-row justify-between items-center gap-[16px] w-full"
            }
            style={{ width: "100%" }}
          >
            <div className="w-full">
              {primaryShipmentLabel && !hiddenPrimaryShipmentLabel && (
                <div
                  className={
                    "flex flex-row gap-[4px] justify-between box-content w-full items-center"
                  }
                >
                  <div className="flex flex-row gap-[4px] box-content w-full items-center">
                    {orderItem?.from_warehouse?.state?.name ? (
                      descriptiveContent(
                        <img
                          className={
                            "w-[22.4px] h-[16px] cursor-pointer object-cover "
                          }
                          src={orderItem?.from_warehouse?.state?.country?.flag}
                          alt="flag"
                        />,
                        orderItem?.from_warehouse?.state?.name
                      )
                    ) : (
                      <IoStorefront className={"w-4 h-4 text-green-300"} />
                    )}
                    {descriptiveContent(
                      primaryShipmentLabel?.international_carrier?.logo ? (
                        <img
                          className={"min-w-[16px] h-[18px]"}
                          src={
                            primaryShipmentLabel?.international_carrier?.logo
                          }
                          alt={
                            primaryShipmentLabel?.international_carrier?.name
                          }
                        />
                      ) : (
                        <Track className={"w-[16px] h-4 text-gray-300"} />
                      ),
                      primaryShipmentLabel?.international_carrier?.name
                    )}
                    <button
                      onClick={() => {
                        if (primaryShipmentLabel?.tracking_url) {
                          window.open(
                            primaryShipmentLabel?.tracking_url,
                            "_blank"
                          );
                        }
                      }}
                      className={
                        "cursor-pointer flex flex-row gap-[4px] items-center max-w-[120px]"
                      }
                    >
                      <span className="truncate text-[#1A56DB]">
                        {primaryShipmentLabel?.tracking_number}
                      </span>
                    </button>
                    <CopyText
                      className={"text-[12px]"}
                      text={primaryShipmentLabel?.tracking_number}
                      hint={""}
                    />
                  </div>
                  <span className="text-[14px] leading-[21px] font-semibold text-[#BF125D]">
                    ${primaryShipmentLabel?.billed_cost}
                  </span>
                </div>
              )}
            </div>
            {/* list */}
            {((primaryShipmentLabel && hiddenPrimaryShipmentLabel) ||
              shipmentData?.shipment_labels?.length > 1) && (
              <PopupUp toggle={listToggle} ref={labelListRef}>
                <ShipmentLabelList
                  shipmentData={shipmentData}
                  actions={{
                    callBack: callBack,
                    handleViewSecodaryLabel,
                  }}
                />
              </PopupUp>
            )}
          </div>

          {primaryShipmentLabel && !hiddenPrimaryShipmentLabel && (
            <div
              className={
                "flex flex-row w-full max-w- justify-between gap-[16px]"
              }
              style={{ width: "100%" }}
            >
              <span className={"text-gray-600  flex-grow"}>
                {/* <util.expandableText
                  maxLines={1}
                  className={"text-[12px]"}
                  innerClassName="w-max"
                  tooltip={true}
                  forcePosition={"top"}
                  tooltipClassName={"text-[14px] text-gray-900 !max-w-[350px]"}
                  action={"hover"}
                  delay={500}
                  hideCopy={true}
                > */}
                {primaryShipmentLabel?.service_name
                  ? shortenString(
                      util.upperCaseString(
                        primaryShipmentLabel?.service_name,
                        "word"
                      ),
                      30
                    )
                  : "-"}
                {/* </util.expandableText> */}
              </span>
              <div
                className={
                  "text-blue-600 h-[16px] flex flex-row items-center gap-[4px]"
                }
              >
                <Button
                  item={{ buttonType: "flat", title: "Create Label" }}
                  onClick={handleCreateShipment}
                >
                  <CreateLabel className={"w-[14px] p-[2px]"} />
                </Button>

                <Button
                  item={{ buttonType: "flat", title: "Show Label" }}
                  onClick={handleViewLabel}
                >
                  <ShowLabel className={"w-[14px] p-[0px]"} />
                </Button>

                <Button
                  item={{ buttonType: "flat", title: "Print Label" }}
                  onClick={handlePrintLabel}
                >
                  <PrintLabel className={"w-[14px] p-[2px]"} />
                </Button>
              </div>
            </div>
          )}

          {!primaryShipmentLabel && (
            <div
              className={
                "flex flex-row justify-between gap-[40px] items-center"
              }
            >
              <div className={"flex flex-row gap-[4px]"}>
                {!orderItem?.content ||
                +orderItem?.billable_weight === 0 ||
                !orderItem?.transfer_items_count ? (
                  <PopupUp
                    toggle={toggle}
                    action={"hover"}
                  >
                    <div className="flex flex-col gap-1">
                      {+shipmentData?.billableWeight === 0 && (
                        <span>Billable weight is required</span>
                      )}
                      {!orderItem?.content && (
                        <span>
                          {!orderItem?.content &&
                            "Shipping content is required"}
                        </span>
                      )}
                      {!orderItem?.transfer_items_count && (
                        <span>No transfer items added</span>
                      )}
                    </div>
                  </PopupUp>
                ) : (
                  <Button
                    item={{
                      buttonType: "alt",
                      className:
                        "h-[24px] item-center flex gap-[8px]  !text-[#1A56DB] !px-[12px] bg-transparent hover:!text-white hover:bg-[#1A56DB] !rounded-[6px]",
                      disabled:
                        !orderItem?.content ||
                        +orderItem?.billable_weight === 0 ||
                        +orderItem?.transfer_items_count === 0,
                    }}
                    onClick={handleCreateShipment}
                  >
                    <span>Carriers</span>
                    <ChevronRight className={"w-[13px] p-[2px]"} />
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ToggleComponent = ({ selected, shipmentData }) => (
  <div
    className={`h-[20px] min-w-[20px] flex flex-row justify-center items-center bg-purple-200 rounded-[10px] text-purple-900 font-medium hover:bg-purple-600 hover:text-white transition cursor-pointer ${
      selected && " text-white !bg-purple-600"
    }`}
  >
    {shipmentData?.shipment_labels?.length}
  </div>
);

ToggleComponent.propTypes = {
  selected: PropTypes.bool.isRequired,
  shipmentData: PropTypes.object.isRequired,
};

ShipmentLabel.propTypes = {
  shipment: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,
  callBack: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
};

const ToggleButton = ({ selected, orderItem, handleCreateShipment }) => (
  <Button
    item={{
      buttonType: "alt",
      className:
        "!rounded-[6px] h-[24px] item-center flex gap-[8px]  !text-[#1A56DB]  !px-[12px] bg-transparent hover:!text-white hover:bg-[#1A56DB]",
      disabled:
        !orderItem?.content ||
        +orderItem?.billable_weight === 0 ||
        +orderItem?.transfer_items_count === 0,
    }}
    onClick={handleCreateShipment}
  >
    <span>Carriers</span>
    <ChevronRight className={"w-[13px] p-[2px]"} />
  </Button>
);

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  orderItem: PropTypes.object.isRequired,
  handleCreateShipment: PropTypes.func.isRequired,
};



ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  orderItem: PropTypes.object.isRequired,
  handleCreateShipment: PropTypes.func.isRequired,
};

export default ShipmentLabel;
