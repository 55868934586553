import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { debounce } from "lodash";
import { HiChevronLeft, HiChevronRight, HiPlus } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import Icons from "../../orders/icons";
import InfiniteScroll from "react-infinite-scroll-component";

function ProductDetailSelectItems({
  title,
  url,
  searchBarClassName,
  mapItems,
  include,
  createNewRedirect,
  isConnectClicked,
  ItemCard,
  multiple,
  data,
  setData,
  defaultItem,
  connectLoading,
  params,
  urlParams,
  newDesign,
  results,
  setResults,
  noAddMore,
  inputValue,
  setInputValue,
  tooltip,
}) {
  const [loading, setLoading] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [searching, setSearching] = useState(true);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    setInputValue("");
    setResults([]);
  }, [searching]);

  useEffect(() => {
    if (defaultItem != null) {
      setSelectedProduct(defaultItem?.id ? [defaultItem] : defaultItem);
      setSearching(false);
    }
  }, [defaultItem]);

  const fetchItems = useCallback(
    async (inputValue, page) => {
      if (!inputValue) {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        return;
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const newController = new AbortController();
      abortControllerRef.current = newController;

      if (!page) {
        setLoading(true);
      }

      try {
        const response = await axios.get(
          `/api/v1/${url}?term=${inputValue}&${
            params ? params + "&" : ""
          }limit=20&page=${page || 1}${include ? include : ""}`,
          { withCredentials: true, signal: newController.signal }
        );

        if (newController.signal.aborted) {
          return;
        }

        setResults((prevResults) => ({
          results: [
            ...(prevResults?.results || []),
            ...response.data.data.map(mapItems),
          ],
          meta: response?.data?.meta,
        }));
      } catch (e) {
        if (axios.isCancel(e)) {
        } else {
          console.error("Fetch request error", e);
        }
      } finally {
        setLoading(false);
      }
    },
    [params, url, mapItems]
  );

  const debouncedFetchItems = useCallback(debounce(fetchItems, 500), [
    fetchItems,
  ]);

  const handleInputChange = (value) => {
    setInputValue(value);
    setResults([]);
    if (!value) {
      setLoading(false);
      setResults([]);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    } else {
      debouncedFetchItems(value, 1);
    }
  };

  return (
    <div className="app flex flex-col">
      {searching && (
        <SearchBar
          newDesign={newDesign}
          title={title}
          searchBarClassName={searchBarClassName}
          loading={loading}
          inputValue={inputValue}
          setInputValue={handleInputChange}
          setSearching={setSearching}
          multiple={multiple}
          fetchItems={debouncedFetchItems}
          onResults={(newResults) => setResults(newResults)}
          createNewRedirect={createNewRedirect}
          data={data}
          setData={setData}
          selectedProduct={selectedProduct}
          urlParams={urlParams}
          tooltip={tooltip}
        />
      )}
      {inputValue && (
        <div className="">
          <SearchResults
            inputValue={inputValue}
            fetchItems={fetchItems}
            results={!searching ? { results: selectedProduct } : results}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            searching={searching}
            isConnectClicked={isConnectClicked}
            setSearching={setSearching}
            ItemCard={ItemCard}
            data={data}
            setResults={setResults}
            title={title}
            tooltip={tooltip}
            setData={setData}
            loading={loading}
            multiple={multiple}
            noAddMore={noAddMore}
            connectLoading={connectLoading}
          />
        </div>
      )}
    </div>
  );
}

export default ProductDetailSelectItems;

const SearchBar = ({
  fetchItems,
  loading,
  inputValue,
  searchBarClassName,
  setInputValue,
  title,
  createNewRedirect,
  multiple,
  setSearching,
  newDesign,
  selectedProduct,
  urlParams,
  tooltip,
}) => {
  // const debouncedFetchProducts = useCallback(debounce(fetchItems, 500), []);

  const handleInputChange = useCallback(async (inputValue) => {
    setInputValue(inputValue);
    const results = await fetchItems(inputValue);
  });
  return (
    <div className="flex flex-col w-[1170px]  gap-2  ">
      <div className="flex justify-between items-center ">
        <div className="flex items-center gap-2.5">
          <p className="text-[16px] leading-6 font-semibold">{title} </p>
          {tooltip && tooltip}
        </div>
        <div className="flex gap-2.5 items-center">
          {createNewRedirect && (
            <div
              className="text-blue-700 text-[14px] flex font-medium leading-[21px] w-full hover:text-blue-800 cursor-pointer items-center gap-1"
              onClick={() => {
                //window.open(`/product/single_products#action=create&title=${item?.title}&image=${item?.image}`, '_blank')
                window.open(
                  `/product/list#action=add${
                    urlParams?.title ? "&title=" + urlParams?.title : ""
                  }${urlParams?.image ? "&image=" + urlParams?.image : ""}`,
                  "_blank"
                );
              }}
            >
              Didn’t find? Create new...
            </div>
          )}
        </div>
        {/* {multiple && selectedProduct && (
          <Icons.reply
            className="w-4 h-4 text-blue-700 cursor-pointer"
            onClick={() => {
              setInputValue("");
              setSearching(false);
            }}
          />
        )} */}
      </div>
      <div
        className={`relative flex  items-center border  rounded-lg rounded-bl-lg pl-4 bg-gray-50   border-gray-300  `}
      >
        <Icons.search className="text-gray-500 h-5 w-5" />
        <input
          type="text"
          placeholder="Search supply channel listings by title, brand, identifier etc..."
          value={inputValue || ""}
          onChange={(e) => handleInputChange(e.target.value)}
          className="w-full p-2 bg-gray-50 border-none !outline-none rounded-lg "
        />
        {inputValue && (
          <div className="pr-[64px]">
            <Icons.xMark
              onClick={() => setInputValue("")}
              className="text-gray-500 cursor-pointer  w-2.5 h-2.5"
            />
          </div>
        )}

        <button
          className="absolute flex justify-center items-center right-0 border  border-blue-700 bg-blue-700 w-[46px] h-[42px] rounded-r-lg text-white rounded-tr-lg rounded-br-lg  
        "
        >
          <Icons.search className="w-5 h-5 " />
        </button>
      </div>
    </div>
  );
};

const SearchResults = ({
  results,
  fetchItems,
  inputValue,
  setSelectedProduct,
  setResults,
  isConnectClicked,
  setSearching,
  searching,
  ItemCard,
  selectedProduct,
  setData,
  data,
  loading,
  connectLoading,
  selection,
  title,
  tooltip,
  multiple,
  noAddMore,
}) => {
  const meta = results?.meta;

  return (
    <div className="flex flex-col border border-gray-300 rounded-2xl mt-4">
      <div className="flex justify-between items-center gap-1.5">
        {!searching && (
          <div className="flex justify-between items-center gap-1.5">
            <p className="text-[16px] leading-6 font-semibold">{title}</p>
            {tooltip && tooltip}
          </div>
        )}
        {multiple &&
          selectedProduct?.length > 0 &&
          !searching &&
          !noAddMore && (
            <p
              className="text-blue-700 font-medium text-[14px] flex items-center gap-1 justify-end hover:text-blue-800 cursor-pointer"
              onClick={() => {
                setSearching(true);
              }}
            >
              <HiPlus className="w-3 h-3 text-blue-700" />
              Add more
            </p>
          )}
      </div>

      <div className="">
        {!loading ? (
          results?.results?.length > 0 ? (
            <InfiniteScroll
              dataLength={results?.results?.length}
              next={() =>
                fetchItems(inputValue, results?.meta?.current_page + 1)
              } // Fetch next page
              hasMore={results?.meta?.last_page > results?.meta?.current_page} // Check if there are more pages to load
              height={513}
              loader={
                <div className="px-4">
                  <Skeleton className="w-full h-32" count={4} />
                </div>
              }
            >
              {results?.results?.map((item, index) => (
                <ItemCard
                  key={index}
                  item={item}
                  index={index}
                  setResults={setResults}
                  results={results}
                  searching={searching}
                  isConnectClicked={isConnectClicked}
                  setSelectedProduct={setSelectedProduct}
                  setSearching={setSearching}
                  selectedProduct={selectedProduct}
                  data={data}
                  setData={setData}
                  connectLoading={connectLoading}
                  selection={selection}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <></>
          )
        ) : (
          <div className="px-4">
            <Skeleton className="w-full h-32" count={4} />
          </div>
        )}
      </div>
    </div>
  );
};
