import React from 'react'
import UI from '../../../../../dynamic-form/elements';

function FooterActions({list, type, actionCallBack, data}) {

    let compressedValues = {}


    compressedValues = {
        "Products": list?.length,
        "Quantity": list?.reduce((a, b) => a + +(b['qty'] || 0), 0),
        "Total Actual Weight": list?.reduce((a, b) => a + +(b?._data?.product?.product?.fbm_actual_weight || 0) * (b['qty'] || 0), 0).toFixed(2),
        "Total Dim. Weight": list?.reduce((a, b) => a + +(b?._data?.product?.product?.fbm_dimensional_weight || 0) * (b['qty'] || 0), 0).toFixed(2),
        "Billable Weight": Math.max(
            list?.reduce((a, b) => a + +(b?._data?.product?.product?.fbm_dimensional_weight || 0) * (b['qty'] || 0), 0),
            list?.reduce((a, b) => a + +(b?._data?.product?.product?.fbm_actual_weight || 0) * (b['qty'] || 0), 0)
        ).toFixed(2),
    };


    return (
        <div className='flex justify-between items-center px-4 !h-[57px] ' style={{
            boxShadow: '0px -1px 2px 0px rgba(0, 0, 0, 0.3)'
            
        }}>
            {/* table summary */}
            {<div className='flex flex-1 gap-2'>
                {
                    list?.length > 0 && Object.keys(compressedValues).map((key, index) => (
                        <div className="  py-1  flex-col justify-between items-end inline-flex">
                            <div className="text-black text-xs font-normal font-['Inter'] leading-3">
                                {key}
                            </div>
                            <div className="text-black text-sm font-semibold font-['Inter'] leading-[21px]">
                                {(compressedValues[key])}
                            </div>
                        </div>
                    ))
                }
            </div>}
            {/* buttons */}
            <div className="flex  flex-row gap-[8px] justify-end py-[12px]">

                <UI.Button
                    item={{
                        label: <div className={"flex flex-row"}>Create Order </div>,
                        buttonType: "primary",
                        className: "h-[37px] !px-[16px]"
                    }}
                    onClick={() => {
                        //callBackAction("tab", {tab: "recipient"})
                        console.log(data)
                        if (!data?.recipient)
                            actionCallBack("recipient", {action: "switchTab"})
                        else
                            actionCallBack(data, {action: 'save'})
                    }}
                />

            </div>
        </div>
    )
}

export default FooterActions