// StatIcon.js
import React from 'react';

const StatIcon = ({icon}) => {
    return (
        <>
            {icon}
        </>
    );
};

export default StatIcon;
