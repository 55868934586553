// FormModal.js
// -----------------------------------------
// Create container for Normal Form Modal.

// Import general libraries
import React, {useCallback, useEffect, useRef} from "react";

// Import Elements
import MainModal from "../../dynamic-page/MainModal";
import DynamicForm from "../../dynamic-form";

import UI from "../../dynamic-form/elements";

// Main Component: AddEditModal
const FormModal = React.memo(
    ({
         title, // Title of form modal.
         formModel,
         formView, // View Model of form.
         data = null, // Data to be edited. If null, form will be in add mode.
         onSubmit, // Function to handle form submit.
         onClose, // Function to handle close form modal.
         submit,
         container,
         cancel,
         actions,
         createAnother,
         visibleForm,
     }) => {

        const checkboxRef = useRef();
        const createNewRef = useCallback((node) => {
            checkboxRef.current = node;
            // if (node)
        }, []);
        useEffect(() => {
        }, [checkboxRef.current?.checked]);

        // Create ref to handle form data.
        const myFormRef = useRef();
        const [disabled, setDisabled] = React.useState();
        const [formData, setFormData] = React.useState(data);
        const [boundData, setBoundData] = React.useState();

        // Handle form submit.
        const myFormCallBack = async (actionName) => {

            let formData = await myFormRef?.current?.getData();
            if (formData) {
                setFormData(formData);
                setDisabled(true);

                // ------------------------------
                // Add additional data to form data if required.
                // Ex: formData.created_by = userId;
                // ------------------------------

                // Add id to form data if data is not null.
                if (data) {
                    formData.id = data.id;
                }

                // if there is null values in form data, remove them.
                // for (let key in formData) {
                //     if (formData[key] === null) {
                //         delete formData[key];
                //     }
                // }

                // Call onSubmit function to handle form submit.
                onSubmit(
                    actionName,
                    formData,
                     () => {
                        if (checkboxRef.current?.checked) {
                            setFormData(null);
                        } else {
                          onClose();
                        }
                      },
                    () => {
                        setFormData(null);
                    }
                );
                setTimeout(() => {
                    setDisabled(false);
                }, 500);
            }
        };
        // Render Component
        return (
            // Configure Modal
            <MainModal
                containerClassName="!px-5 !pb-3"
                headerClassName="!px-5 !pt-5"
                item={{
                    title: `${title}`, // If data is null, show "Add New" else "Edit".,
                    submit: myFormCallBack,
                    cancel: onClose,

                    view: (item) => (
                        <div>
                            {container ? (
                                container(
                                    <DynamicForm
                                        items={formView(formData)}
                                        ref={myFormRef}
                                        onChange={
                                            title.includes("Sales Channel Account")
                                                ? (data, bound) => {
                                                    setBoundData(bound);
                                                    setFormData(data);
                                                }
                                                : null
                                        }
                                    />
                                )
                            ) : (
                                <div
                                    className="min-w-[775px] grid grid-cols-2 items-start gap-[8px] w-full justify-center px-[16px] border-t border-t-gray-200 pt-[8px] ">
                                    {/* Dynamic form is here */}
                                    <DynamicForm
                                        items={formView(formData)}
                                        ref={myFormRef}
                                        onChange={
                                            title.includes("Sales Channel Account")
                                                ? (data, bound) => {
                                                    setBoundData(bound);
                                                    setFormData(data);
                                                }
                                                : null
                                        }
                                    />
                                </div>
                            )}

                            {/* Form control Buttons are here */}
                            <div
                                className="flex justify-between items-center sticky bottom-0 bg-white"
                            >
                                {/* if any paramther is send it goes right here */}
                                <div className="w-full flex justify-start text-start">
                                    {createAnother && (
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="scales"
                                                name="scales"
                                                ref={createNewRef}
                                                disabled={false}
                                                className={
                                                    "w-[16px] h-[16px] mr-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-md "
                                                }
                                            />
                                            <label for="scales" className="font-medium text-[14px] leading-[14px]">Create another</label>
                                        </div>
                                    )}

                                    {title === "Edit User" &&
                                        <div className="flex justify-center items-center ">
                                            <button onClick={() => {
                                                actions.delete.onClick(formData)
                                                onClose()
                                            }} className="text-red-700 text-[14px] leading-[21px] font-medium">Delete
                                                User
                                            </button>
                                        </div>
                                    }
                                </div>


                                <div className={`flex w-full  !sticky bottom-0 bg-white justify-end ${
                                    (title.includes("Sales Channel Account") && !formData?.id)
                                        ? "gap-2"
                                        : "gap-2"
                                } pt-[12px] pb-2   gap-[8px] `}

                                >
                                    {(cancel !== false) && (
                                        <UI.Button
                                            item={{
                                                label: <div className={"flex flex-row"}>Cancel</div>,
                                                buttonType: "alt",
                                                className: "h-[41px] !w-[120px] w-fit whitespace-nowrap !px-[20px]",
                                                disabled: disabled,
                                            }}
                                            onClick={onClose}
                                        />
                                    )}

                                    <div className={`flex gap-2`}>
                                        {Array.isArray(submit)
                                            ? submit?.map((action, index) => (
                                                <UI.Button
                                                    item={{
                                                        label: (
                                                            <div className={"flex flex-row"}>
                                                                {action?.label}
                                                            </div>
                                                        ),
                                                        buttonType: action?.type ? action.type : "primmary",
                                                        className: "h-[41px] w-[120px] !px-[20px] w-fit whitespace-nowrap " + (action?.className ? action.className : ""),
                                                        disabled: disabled,
                                                    }}
                                                    onClick={() => {
                                                        myFormCallBack(action.action);
                                                    }}
                                                    key={index}
                                                />
                                            ))
                                            : submit(boundData)?.map((action, index) => (
                                                <UI.Button
                                                    item={{
                                                        label: (
                                                            <div className={"flex flex-row"}>
                                                                {action?.label}
                                                            </div>
                                                        ),
                                                        buttonType: action?.type ? action.type : "primmary",
                                                        className: "h-[41px] w-[120px] !px-[20px]",
                                                        disabled: disabled,
                                                    }}
                                                    onClick={() => {
                                                        myFormCallBack(action.action);
                                                    }}
                                                    key={index}
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                }}
            />
        );
    }
);

export default FormModal;
