import React from "react";

const datePriorityColor = ({color, day, hour, className}) => {
    const number = hour && day === 0 ? `${hour}h` : day
    const innerColor = color || "gray"
    return (
        <span
            className={`py-[2px] px-[12px] bg-${innerColor}-100 text-${innerColor}-800 rounded-md text-center font-medium ${className}`}>
        {number}
    </span>
    )
}


export default datePriorityColor