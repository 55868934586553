import upperCaseString from "../../dynamic-page/util/upperCaseString";
import {useSelector} from "react-redux";

const FiltersView = (selectedFilers) => {
    const filtersMeta = useSelector(state => state?.orderNotifications?.filtersMeta)
    return (
        [
            {
                name: "status",
                label: "Status",
                placeholder: "Filter by status",
                type: "Checkbox",
                list: filtersMeta?.statuses?.map((item) => {
                        return {
                            label: upperCaseString(item?.name),
                            value: item?.id,
                            count: item?.count
                        };
                    }
                ),
                defaultValue: selectedFilers?.status,
                toggle: true,
                clear: true,
                features: {
                    isMulti: true,
                },
                checkAll: true,
            },
            {
                name: "fulfillment_type",
                label: "Fulfillment Type",
                placeholder: "Filter by fulfillment type",
                type: "Checkbox",
                list: filtersMeta?.fulfilment_type?.map((item) => {
                    return {
                        label: upperCaseString(item?.name),
                        value: item?.id,
                        count: item?.count
                    };
                }),
                defaultValue: selectedFilers?.fulfillment_type,
                toggle: true,
                clear: true,
                features: {
                    isMulti: true,
                },
                checkAll: true,
            },
            {
                name: "change_reason",
                label: "Change Reason",
                placeholder: "Filter by fulfillment type",
                type: "Checkbox",
                list: filtersMeta?.change_reason?.map((item) => {
                    return {
                        label: upperCaseString(item?.name),
                        value: item?.id,
                        count: item?.count
                    };
                }),
                defaultValue: selectedFilers?.change_reason,
                toggle: true,
                clear: true,
                features: {
                    isMulti: true,
                },
                checkAll: true,
            },
            // {
            //     name: "change_type",
            //     label: "Change Type",
            //     placeholder: "Filter by fulfillment type",
            //     type: "Checkbox",
            //     list: filtersMeta?.change_types?.map((item) => {
            //         return {
            //             label: upperCaseString(item),
            //             value: item,
            //         };
            //     }),
            //     defaultValue: selectedFilers?.change_type,
            //     toggle: true,
            //     clear: true,
            //     features: {
            //         isMulti: true,
            //     },
            //     checkAll: true,
            // },

            {
                name: "purchase_date",
                label: "Purchase Date",
                placeholder: "Filter by Purchase date",
                type: "DateRange",
                defaultValue: selectedFilers?.purchase_date,
                toggle: true,
                clear: true,
            },
            {
                name: "change_date",
                label: "Change Date",
                placeholder: "Filter by Change date",
                type: "DateRange",
                defaultValue: selectedFilers?.change_date,
                toggle: true,
                clear: true,
            },
        ]
    )
}

export default FiltersView;