import React from 'react'

function Numbers({text}) {
    return (
        <div className="w-[175px] h-16   flex-col justify-center items-center gap-0.5 inline-flex">
            <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-indigo-700 text-2xl font-bold font-['Inter'] leading-normal">{text || '-'}</div>
            </div>
        </div>
    )
}

export default Numbers