/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Title - a title of arbit product page
 */
import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import UI from "../../../dynamic-form/elements";
import {HiFilter, HiSortAscending, HiSortDescending, HiX,} from "react-icons/hi";
import {FaFileImport} from "react-icons/fa";

import DropDownMenu from "../../elements/DropDownMenu";
import PanelModal from "../../PanelModal";
import util from "../../util";
import Icons from "../../../orders/icons";
import {utils, writeFileXLSX} from "xlsx";
import SearchInput from "../searchInput";
import upperCase from "../../util/upperCaseString";


const TitleNew = ({
                      onSearch,
                      form,
                      displayTitle = "flex",
                      sort,
                      filter,
                      actionList,
                      options,
                      params,
                      exports,
                      headerOption,
                      logo,
                      title,
                      subTitle,
                      setImportModal,
                      allowExport,
                      filtering,
                      selectedRow,
                      exported,
                      loading,
                  }) => {
    const [filterModalVisibility, setFilterModalVisibility] = useState(false);
    // const mappedFilters = [];
    // filter?.items?.map((item) => {
    //   if (item?.defaultValue) {
    //     mappedFilters.push(item?.label);
    //   }
    // });
    const selectedSort = sort?.items?.list?.find((item) => {
        return (
            sort?.selected &&
            (sort?.selected?.startsWith("-")
                ? sort?.selected?.substring(1)
                : sort?.selected) === item.value
        );
    });

    const dataToBeExported = exported
        ? exported.exportMapper(exported.exportData)
        : [];

    const exportFile = useCallback(() => {
        const ws = utils.json_to_sheet(dataToBeExported);
        utils.sheet_add_aoa(ws, [exported.headers], {origin: "A1"});
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, `${title}.xlsx`);
    }, [dataToBeExported]);

    const exportCSV = useCallback(() => {
        const worksheet = utils.json_to_sheet(dataToBeExported);
        const csvOutput = utils.sheet_to_csv(worksheet);

        const blob = new Blob([csvOutput], {type: "text/csv"});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", `${title}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }, [dataToBeExported]);

    function mapValues(obj) {
        // Helper function to map a single value
        function mapValue(value) {

            if (Array.isArray(value)) {
                // If it's an array
                if (value.length === 0) return ""; // Empty array
                if (typeof value[0] === "object" && value[0] !== null) {
                    // If it's an array of objects
                    return value.map((obj) => upperCase(obj.label, 'first')).join(", ")
                } else {
                    // If it's an array of primitives and has more than one element
                    if (value.length > 1) {
                        return value.join(" to ");
                    }
                }
            } else if (typeof value === "object" && value !== null) {
                // If it's an object
                return upperCase(value.label, 'first');
            } else {
                // If it's a primitive
                return value;
            }
        }

        // Map the keys to their corresponding values
        const mappedObject = {};
        for (const key in obj) {
            mappedObject[key] = mapValue(obj[key]);
        }
        // exclude the keys that are inside the filter.discard array if it exiqts
        if (filter?.discard) {
            filter.discard.map((key) => {
                delete mappedObject[key];
            });
        }
        // delete the keys that includes "status"
        // Object.keys(mappedObject).map((key) => {
        //     if (key.includes("status")) {
        //         delete mappedObject[key];
        //     }
        // });
        return mappedObject;
    }

    const mappedFilters = mapValues(filter?.filters);
    if (Object.keys(mappedFilters).some((key) => mappedFilters[key])) {
        mappedFilters["_"] = (
            <UI.Button
                item={{
                    label: <HiX className="w-3 h-3"/>,
                    buttonType: "icon",
                    className:
                        "order-[0] !p-0 !text-red-700 !border-red-700 !h-[22px] !min-w-[22px]",
                }}
                onClick={() => {
                    filter.callback(
                        {
                            ...filter.pageFilters,
                            ...Object.keys(mappedFilters).reduce((acc, key) => {
                                acc[key] = null;
                                return acc;
                            }, {}),
                        },
                        {
                            ...filter.filters,
                            ...Object.keys(mappedFilters).reduce((acc, key) => {
                                acc[key] = null;

                                return acc;
                            }, {}),
                        }
                    );
                }}
            />
        );
    }

    return (
        <div
            className={`${displayTitle}    bg-white flex flex-col justify-center items-start gap-4 px-[16px]`}
            style={{height: onSearch ? "121px" : "69px"}}
        >
            {onSearch && <div className="flex flex-row items-center justify-between w-full gap-[8px]">
                <p className="text-[24px] leading-[36px] min-w-fit font-bold text-gray-900 flex flex-row items-start gap-[0px]">
                    {logo && subTitle && (
                        <util.popUp
                            toggle={(selected) => (
                                <div className="flex flex-row items-center ml-2">
                                    <img src={logo} className="h-[48px] w-[48px] mr-[8px]"/>{" "}
                                </div>
                            )}
                            action={"hover"}
                            forcePosition={"top"}
                            delay={500}
                        >
                            {subTitle}
                        </util.popUp>
                    )}

                    {util.shortenString(title, 50)}
                    {subTitle && !logo && (
                        <>
                            <span className={"opacity-50"}> / </span>
                            {util.shortenString(subTitle, 50)}
                        </>
                    )}
                </p>

            </div>}
            <div className="flex justify-between items-center w-[100%]">
                {onSearch ? <SearchInput
                        className="w-[362px]"
                        placeholder="Search..."
                        onSubmit={onSearch}
                        defaultValue={null}
                    /> :
                    <p className="text-[24px] leading-[36px] min-w-fit font-bold text-gray-900 flex flex-row items-start gap-[0px]">
                        {logo && subTitle && (
                            <util.popUp
                                toggle={(selected) => (
                                    <div className="flex flex-row items-center ml-2">
                                        <img src={logo} className="h-[48px] w-[48px] mr-[8px]"/>{" "}
                                    </div>
                                )}
                                action={"hover"}
                                forcePosition={"top"}
                                delay={500}
                            >
                                {subTitle}
                            </util.popUp>
                        )}

                        {util.shortenString(title, 50)}
                        {subTitle && !logo && (
                            <>
                                <span className={"opacity-50"}> / </span>
                                {util.shortenString(subTitle, 50)}
                            </>
                        )}
                    </p>}


                <div className=" flex flex-row justify-center items-end gap-[6px]">

                    <div className=" flex flex-row  flex-wrap  justify-end items-end gap-[2px]">
                        {/* {filters} */}
                        {filter?.filters &&
                            Object.keys(mappedFilters)
                                .reverse()
                                .map((key, index) => {
                                    if (!mappedFilters[key]) return null;
                                    if (key === "_") return mappedFilters[key];
                                    return (
                                        <div
                                            className="min-w-fit h-[22px] px-2 py-0.5 rounded border border-blue-300 justify-center items-center gap-2 inline-flex">
                                            <div
                                                className="text-blue-300 text-xs font-medium font-['Inter'] leading-[18px]">
                                                {/* the key woth this edit key_name => Key Name */}
                                                {!key.includes("carrier_ids") ? key
                                                    .replace(/_/g, " ")
                                                    .replace(/\b\w/g, (l) => l.toUpperCase()) : "carrier".replace(/_/g, " ")
                                                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                                            </div>
                                            <div
                                                className="text-blue-700 text-xs font-medium font-['Inter'] leading-[18px]">
                                                {/* the value */}
                                                {key==="carrier_ids" ? params.get("carrierName"):mappedFilters[key]}
                                            </div>
                                            <HiX
                                                className="h-4 w-4 text-blue-300 cursor-pointer hover:text-blue-500"
                                                onClick={() => {
                                                    filter.callback(
                                                        {
                                                            ...filter.pageFilters,
                                                            [key]: null,
                                                        },
                                                        {...filter.filters, [key]: null}
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                    </div>

                    {allowExport && (
                        <DropDownMenu
                            toggleButton={(selected) => (
                                <UI.Button
                                    item={{
                                        label: <Icons.exportIcon className="h-6 w-6"/>,
                                        buttonType: "icon",
                                        className: "order-[]",
                                        disabled: loading !== "idle",
                                    }}
                                />
                            )}
                            // selected={sort?.selected}
                            itemMenu={{
                                list: [
                                    {
                                        label: "XLSX",
                                        onClick: () => {
                                            if (loading !== "idle") return;
                                            exportFile();
                                        },
                                    },
                                    {
                                        label: "CSV",
                                        onClick: () => {
                                            if (loading !== "idle") return;
                                            exportCSV();
                                        },
                                    },
                                ],
                            }}
                        />
                    )}
                    {exports &&
                        exports?.length > 0 &&
                        exports.map((option, index) => {
                            return (
                                <div
                                    className={"relative "}
                                    style={{order: option.order}}
                                    key={index}
                                >
                                    {option.view({data: option.data, callBack: option.callBack})}
                                </div>
                            );
                        })}

                    {sort && (
                        <div className={"relative order-[]"}>
                            <DropDownMenu
                                toggleButton={(selected) => (
                                    <UI.Button
                                        item={{
                                            label: sort?.selected ? (
                                                sort?.selected?.startsWith("-") ? (
                                                    <>
                                                        <div className="flex gap-1.5 p-2 px-3 items-center ">
                                                            <HiSortDescending className="h-[16px] w-[16px] mt-0.5 "/>
                                                            <span>{selectedSort?.label} </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="flex gap-1.5 p-2 px-3 items-center ">
                                                            <HiSortAscending className="h-[16px] mt-0.5 w-[16px]"/>
                                                            <span>{selectedSort?.label} </span>
                                                        </div>
                                                    </>
                                                )
                                            ) : (
                                                <>
                                                    <HiSortDescending className="h-5 w-5   "/>
                                                </>
                                            ),
                                            buttonType: "icon",
                                            className:
                                                `${selected || sort?.selected
                                                    ? " !text-blue-700 !border-blue-700 !h-[37px]  !py-1 px-4"
                                                    : "text-red-600 !p-1 !h-[37px] "} !min-w-[37px] !rounded-[8px]   !flex !items-center !justify-center !gap-[2px]`,
                                        }}
                                        onClick={sort.callback}
                                    />
                                )}
                                selected={sort?.selected}
                                itemMenu={sort.items}
                            />
                        </div>
                    )}
                    {filter && (
                        <div className={"relative"}>
                            <UI.Button
                                item={{
                                    label: <HiFilter className="h-5 w-5"/>,
                                    buttonType: "icon",
                                    className: "order-[1] !rounded-[8px] !w-[37px] !h-[37px] !min-w-[37px] !flex !items-center !justify-center !gap-[2px]",
                                }}
                                onClick={() => {
                                    setFilterModalVisibility(!filterModalVisibility);
                                }}
                            />


                            {filterModalVisibility && (
                                <PanelModal
                                    filtersView={filter.items}
                                    skipStatus={filter.skipStatus}
                                    onApply={(rs, bound) => {
                                        filter.callback(rs, bound);
                                        setFilterModalVisibility(false);
                                    }}
                                    cancel={() => setFilterModalVisibility(false)}
                                    visible={filterModalVisibility}
                                />
                            )}
                            <div
                                className={`absolute flex -top-[6px]  font-bold -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[18px] min-w-[18px] items-center justify-center ${
                                    filter?.count < 1 ? "hidden" : "block"
                                }`}
                            >
                                {filter?.count}
                            </div>
                        </div>
                    )}


                    {options &&
                        options?.length > 0 &&
                        options.map((option, index) => {
                            return (
                                <div
                                    className={"relative "}
                                    style={{order: option.order}}
                                    key={index}
                                >
                                    {option.view({data: option.data, callBack: option.callBack})}
                                </div>
                            );
                        })}

                    {form && (
                        <UI.Button
                            item={{
                                // label: `+   New ${title.slice(0, -1)}`,
                                buttonType: "primary",
                                className: "order-[0] !h-[37px] !px-3 !py-2 !rounded-[8px] !flex !items-center !justify-center !gap-[8px]",
                            }}
                            onClick={form.callback}
                        >
                            <Icons.plus className="h-3 w-3"/>
                            New {title.slice(0, -1)}
                        </UI.Button>
                    )}
                    {headerOption &&
                        headerOption?.length > 0 &&
                        headerOption.map((option, index) => {
                            return (
                                <div
                                    className={"relative "}
                                    style={{order: option.order}}
                                    key={index}
                                >
                                    {option.view}
                                </div>
                            );
                        })}

                    {actionList?.count > 0 && actionList?.list.length > 0 && (
                        <div className={"relative"}>
                            <DropDownMenu
                                toggleButton={(selected) => (
                                    <UI.Button
                                        item={{
                                            label: `Actions`,
                                            buttonType: "icon",
                                            className: `!rounded-[8px] ${
                                                selected ? "bg-blue-200 text-blue-600" : "text-red-600"
                                            } w-[100px]`,
                                        }}
                                        onClick={actionList.callback}
                                    >
                                        <svg
                                            className="w-4 h-4 ml-2"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M19 9l-7 7-7-7"
                                            ></path>
                                        </svg>
                                    </UI.Button>
                                )}
                                item={selectedRow}
                                itemMenu={actionList}
                            />
                            <div
                                className={`absolute flex  -top-[6px]  font-bold -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[18px] min-w-[18px] items-center justify-center ${
                                    actionList?.count < 1 ? "hidden" : "block"
                                }`}
                            >
                                {actionList?.count}
                            </div>
                        </div>
                    )}

                    {
                        <div
                            className={`absolute -top-[6px] -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[14px] w-[14px] ${
                                !filtering && "hidden"
                            }`}
                        >
                            <span></span>
                        </div>
                    }

                    {setImportModal && (
                        <FaFileImport
                            onClick={() => {
                                setImportModal(true);
                            }}
                            className="title-icon p-[4px] rounded h-[34px] w-[32px]"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

TitleNew.propTypes = {
    marginX: PropTypes.string,
    marginY: PropTypes.string,
    height: PropTypes.string,
    justify: PropTypes.string,
    textSize: PropTypes.string,
    filters: PropTypes.object,
};

TitleNew.defaultProps = {
    marginX: "16px",
    marginY: "2",
    height: "36px",
    justify: "start",
    textSize: "24px",
    filters: {},
};

export default TitleNew;
