/**
 * @author Redwan Jemal
 * @description ProductSalesChannel - a component to be displayed for sales channel tab
 */
import Inventory from "../inventory-adjustments/index";

const ProductInventoryAdjustmentsPage = ({product_id}) => {
    return (
        <div className="h-[400px]">
            <Inventory product_id={product_id} displayTitle={'hidden'} displayTabGroups="hidden"/>
        </div>
    )
}

export default ProductInventoryAdjustmentsPage;
