import React from 'react';
import Icon from './icons';

const tabIcons = {
    0: <Icon.FaDownload className='text-base'/>,
    1: <Icon.BiSolidUserCircle className='text-base'/>,
    2: <Icon.BiSolidUserCircle className='text-base'/>,
    3: <Icon.BiSolidUserCircle className='text-base'/>,
    4: <Icon.BiSolidUserCircle className='text-base'/>,
    5: <Icon.BiSolidUserCircle className='text-base'/>,
    6: <Icon.BiSolidUserCircle className='text-base'/>,
    7: <Icon.BiSolidUserCircle className='text-base'/>,
    // Add more icons for other tabs if needed
};

const Tab = ({tabs, activeTab, onTabChange}) => {
    return (
        <div className="tabs flex flex-row justify-start items-start">
            {tabs.map((tab, index) => (
                <button
                    type='button'
                    key={tab}
                    className={`tab text-sm ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => onTabChange(tab)}
                >
                    <div
                        className={`flex flex-row px-4 py-2 rounded-md justify-center items-center gap-x-1.5 ${activeTab === tab ? 'bg-blue-500 text-white' : 'text-gray-500'}`}>
                        {tabIcons[index]}
                        {tab}
                    </div>
                </button>
            ))}
        </div>
    );
};

export default Tab;
