import { useEffect, useState } from "react";
import DirtySelect from "../../../dynamic-form/elements/DirtySelect";
import { toast } from "react-toastify";
import axios from "axios";

const CustomDeclarations = ({ productData, id }) => {
  const [isValuePosted, setValuePosted] = useState(null);
  const [isDeleteClicked, setDeleteClicked] = useState(false);

  const hasValue =
  productData?.attributes?.Other?.find((item) => item?.attribute_id === 39) ??
  null;

  const handleValueClicked = async (props) => {
    if (props?.value) {
      if ((hasValue || isValuePosted) && !isDeleteClicked) {
        await axios
          .put(
            `/api/v1/attribute-value/${isValuePosted?.id || hasValue?.id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setDeleteClicked(false);
              toast.success(res?.data?.message);
              setValuePosted(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
            } else {
              toast.error(res?.data?.message);
            }
          });
      } else {
        await axios
          .post(
            `/api/v1/attribute-value/${props.attributeId}/${id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setValuePosted(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
              setDeleteClicked(false);
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          });
      }
    } else {
      try {
        const res = await axios.delete(
          `/api/v1/attribute-value/${isValuePosted?.id || hasValue?.id}`,
          { withCredentials: true }
        );

        if (res?.status === 204) {
          toast.success("Attribute has been deleted successfuly");
          setValuePosted("");
          setDeleteClicked(true);
          props.setDeleteModal(false);
          props.reset({ value: "" });
        } else {
          toast.error(res?.data?.message);
        }
      } catch (error) {
        toast.error("An error occurred");
        console.error(error);
      }
    }
  };

  const [isValuePosted2, setValuePosted2] = useState(null);
  const [isDeleteClicked2, setDeleteClicked2] = useState(false);
  
  const hasValue2 =
  productData?.attributes?.Identifiers?.find(
    (item) => item?.attribute_id === 73
  ) ?? null;

  const handleValueClicked2 = async (props) => {
    if (props?.value) {
      if ((hasValue2 || isValuePosted2) && !isDeleteClicked2) {
        await axios
          .put(
            `/api/v1/attribute-value/${isValuePosted2?.id || hasValue2?.id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setDeleteClicked2(false);
              toast.success(res?.data?.message);
              setValuePosted2(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
            } else {
              toast.error(res?.data?.message);
            }
          });
      } else {
        await axios
          .post(
            `/api/v1/attribute-value/${props.attributeId}/${id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setValuePosted2(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
              setDeleteClicked2(false);
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          });
      }
    } else {
      try {
        const res = await axios.delete(
          `/api/v1/attribute-value/${isValuePosted2?.id || hasValue2?.id}`,
          { withCredentials: true }
        );

        if (res?.status === 204) {
          toast.success("Attribute has been deleted successfuly");
          setValuePosted("");
          setDeleteClicked2(true);
          props.setDeleteModal(false);
          props.reset({ value: "" });
        } else {
          toast.error(res?.data?.message);
        }
      } catch (error) {
        toast.error("An error occurred");
        console.error(error);
      }
    }
  };

  return (
    <div className="flex gap-3 flex-col border border-gray-100 rounded-[8px] h-[142px]  p-4 w-full sm:min-w-[320px] xl:w-[25%] flex-[1_0_0] box-border">
      <span className="text-gray-900 leading-normal  font-semibold text-[18px]">
        Customs Declaration
      </span>
      <div className="flex gap-4">
        <DirtySelect
          isValue={hasValue}
          id={id}
          texts={{
            tooltipTitle: "Merchant Shipping Content",
            tooltipDesc:
              "Short product content to be used as customs declaration in product orders.",
          }}
          tooltipPosition={"top"}
          attributeId={"39"}
          className={"w-[41.3%] border-none px-0 py-0"}
          title={"Content"}
          placeholder={"ex. book"}
          handleValueClicked={handleValueClicked}
        />
        <DirtySelect
          isValue={hasValue2}
          placeholder={"ex. 419000"}
          id={id}
          texts={{
            tooltipTitle: "Merchant Shipping GTIP Code",
            tooltipDesc:
              "The international GTIP code that the product will use at customs.",
          }}
          title={"GTIP Code"}
          tooltipPosition={"top"}
          attributeId={"73"}
          className={"w-[41.3%] border-none px-0 py-0"}
          handleValueClicked={handleValueClicked2}
        />
      </div>
    </div>
  );
};

export default CustomDeclarations;
