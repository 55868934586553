import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCategories,
  fetchOpportunities,
  fetchOpportunitiesFilters,
  postOppurtunityNotes,
  showOpportunity,
  updateBulkOpportunities,
  updateOpportunities,
} from "./thunk";

const initialState = {
  opportunities: [],
  links: {},
  filters: {
    type_filter: [],
    note_filter: [],
    dollar_filter: [],
    percent_filter: [],
    channel_filter: [],
    supply_channel_filter: [],
    brand_filter: [],
    product_filter: [],
    assign_filter: [],
    bbowner_filter: [],
    category_filter: [],
    bbprice_filter: [],
    lwprice_filter: [],
    sales_channel_id_filter: [],
    created_filter: null,
    updated_filter: null,
    rating_count_filter: [],
    rating_filter: [],
    best_supply_offer_inventory: [],
    offers_count: [],
    bb_avg_price: [],
    sales_rank: [],
    top_percentage: [],
    bb_oos_90: [],
    fba_margin: [],
    fbm_margin: [],
    fba_profit: [],
    fbm_profit: [],
    category_degree: [],
  },
  updatedOpportunity: [],
  updatedBulkOpportunity: [],
  meta: {},
  categories: [],
  filtersMeta: {},
  boundFilters: {},
  loading: "idle",
  error: null,
  isLogin: false,
};

export const opportunitySlice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
    replaceOppurtunity: (state, action) => {
      const index = state.opportunities.findIndex(
        (opp) => opp.id === action.payload.id
      );
      if (index !== -1) {
        state.opportunities[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpportunities.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchOpportunities.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.opportunities = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchOpportunities.rejected, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(updateOpportunities.fulfilled, (state, action) => {
        state.updatedOpportunity = action.payload?.data;
      })
      // .addCase(updateBulkOpportunities.pending, (state, action) => {
      //   if (state.loading === 'idle') {
      //     state.loading = 'pending'
      //   }
      // })
      .addCase(updateBulkOpportunities.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          // state.loading = 'idle'
          state.updatedBulkOpportunity = action.payload?.data;
        }
      })
      .addCase(updateBulkOpportunities.rejected, (state, action) => {
        if (state.loading === "pending") {
          // state.loading = 'idle'
          state.error = action.error;
        }
      })
      .addCase(fetchOpportunitiesFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload?.data;
      })
      .addCase(showOpportunity.fulfilled, (state, action) => {
        const index = state.opportunities.findIndex(
          (opp) => opp.id === action.payload?.data?.id
        );
        if (index !== -1) {
          state.opportunities[index] = action.payload?.data;
        }
      });
  },
});

export const { updateFilter, updateBoundFilter, replaceOppurtunity } =
  opportunitySlice.actions;

export {
  fetchOpportunities,
  updateOpportunities,
  updateBulkOpportunities,
  fetchOpportunitiesFilters,
  fetchCategories,
  postOppurtunityNotes,
  showOpportunity,
};

export default opportunitySlice.reducer;
