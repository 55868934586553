import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';


const fetchPermissions = createAsyncThunk(
    'get:api/v1/permissions',
    async (params, thunkAPI) => {
        const response = await api.fetchPermissions(params)

        return response
    }
)


// const assignPermissions = createAsyncThunk(
//     'post:api/v1/user/:id',
//     async ({id , payload}) => {
//         console.log(id, payload, 'thunk now');
//         const response = await api.assignPermissions(id, payload)

//         return response
//     }
// )

const assignPermissions = createAsyncThunk(
    'post:api/v1/user/:id',
    async ({id, payload}, thunkAPI) => {
        // console.log(id, payload, thunkAPI, 'thunk now');
        const response = await api.assignPermissions(id, payload)
        // console.log(response, 'thunk now');

        return response.data
    }
)


export {fetchPermissions, assignPermissions}