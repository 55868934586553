import {useSelector} from "react-redux";

const FiltersView = (selectedFilers) => {

    const filtersMeta = useSelector(state => state?.shipments?.filtersMeta)
    return (
        [

            // {
            //     name: "order_status_id",
            //     label: "Order Status",
            //     placeholder: "Filter by status",
            //     type: "Checkbox",
            //     list: filtersMeta?.order_statuses?.map((item) => ({
            //         label: upperCaseString(item?.name),
            //         value: item?.id,
            //         count : item?.count
            //         })) ,
            //     defaultValue: selectedFilers?.['order_status_id'],
            //     toggle: true,
            //     clear: true,
            //     features: {
            //         isMulti: true,
            //     },
            //     checkAll: true,
            // },


            {
                name: "label_cost",
                label: "Label Cost",
                placeholder: "Filter by Label Cost",
                type: "Range",
                defaultValue: selectedFilers?.label_cost,
                toggle: true,
                clear: true,
            },

            // refund_amount , order_amount
            {
                name: "refund_amount",
                label: "Refund Amount",
                placeholder: "Filter by Refund Amount",
                type: "Range",
                defaultValue: selectedFilers?.refund_amount,
                toggle: true,
                clear: true,
            },
            {
                name: "order_amount",
                label: "Order Amount",
                placeholder: "Filter by Order Amount",
                type: "Range",
                defaultValue: selectedFilers?.order_amount,
                toggle: true,
                clear: true,
            },


        ]
    )
}

export default FiltersView;