import React from "react";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import Util from "../../dynamic-page/util";
import ItemID from "../../dynamic-page/components/ItemID";

const tableView = (list) => {

    return {
        list: list ?? [],
        theme: {
            "category id": (item) => (
                <ItemID id={item.id}/>
            ),
            status: (item) => (
                <div className="flex flex-col space-y-[4px] justify-center items-center  h-full">
                    {item?.status === "active" && <ArbitNormalBadge title={"Active"}/>}
                    {item?.status === "inactive" && (
                        <ArbitNormalBadge title={"Inactive"} bgColor="purple-100"/>
                    )}
                    {item?.status === "deleted" && (
                        <ArbitNormalBadge title={"Deleted"} bgColor="red-100"/>
                    )}
                    {item?.status === null && <ArbitNormalBadge title={"Active"}/>}
                </div>
            ),
            name: (item) => (
                <div
                    className="flex h-[64px] min-w-[300px] w-[100%] items-center text-[16px] font-[400] text-gray-900 justify-content-center text-center">
                    <Util.truncateText maxLines={1} hideCopy tooltip={true}>
                        {item?.name}
                    </Util.truncateText>
                </div>
            ),
            type: (item) => (
                <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center">
                    {item?.type}
                </div>
            ),
            "category tree": (item) => (
                <div
                    className="flex h-[64px] min-w-[300px] w-[100%] items-center text-[16px] font-[400] text-gray-900 justify-content-center text-center">
                    <Util.truncateText maxLines={1} hideCopy tooltip={true}>
                        {item?.categoryTree}
                    </Util.truncateText>
                </div>
            ),
            MAPPED: (item) => (
                <div
                    className="flex items-center text-[16px] font-[400] text-gray-900 justify-content-center text-center">
                    {item?.matched || '-'}
                </div>
            ),
            UNMAPPED: (item) => (
                <div className="flex items-center text-[16px] font-[500] text-purple-700 justify-center text-center">
                    {item?.unmatched || '-'}
                </div>
            ),
            PRODUCTS: (item) => (
                <div className="flex items-center text-[16px] font-[500] text-purple-700 justify-center text-center">
                    {item?.products}
                </div>
            ),
            //   map: () => (
            //     <UI.Button
            //       // onClick={() => handleMap(item)}

            //       item={{
            //         label: <div className={"flex flex-row"}>
            //         Create Mapping
            //         </div>,
            //         buttonType:'primary',
            //     className:"!px-2 !py-2 bg-blue-500"
            // }}
            //     />
            //   ),
        },
    };
};
export default tableView;
