import React, {useEffect, useRef} from 'react';

import {IoImageOutline} from "react-icons/io5";

const Image = ({item, value, validationMessage, handleChange, formData}) => {

    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedFileName, setSelectedFileName] = React.useState(null);


    // create image url from blob
    const createImageUrl = (image) => {
        if (image instanceof Blob) {
            return URL.createObjectURL(image);
        } else {
            return image;
        }
    }
    // handle Drop file
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFileName(file.name);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setSelectedFile(reader.result);
                handleChange(reader.result, item?.name);
            };
        } else {
            setSelectedFileName(null);
            setSelectedFile(null);
            handleChange(null, item?.name);
        }
    };

    const handleClick = () => {
        inputRef?.current?.click();
    };

    useEffect(() => {
        setSelectedFile(value);
        if (value === item?.defaultValue) {
            setSelectedFileName(item?.defaultValue?.substring(item?.defaultValue?.lastIndexOf("/") + 1));
        }
        if (value === null || value === "") {
            if (inputRef?.current) {
                inputRef.current.value = null
            }

        }
    }, [value]);

    const inputValue = value || "";
    // Return Input in view
    return (
        <div className={"flex flex-row gap-[8px] relative block"}>

            <div
                className={`transition ${disabled && "disabled"} w-full justify-between   flex flex-row gap-[8px] items-center text-gray-500 disabled:text-gray-400 rounded-md px-[2px] py-[2px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block ${item?.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                onClick={handleClick}
            >


                <div className={"h-[100%] flex flex-row gap-[8px] items-center text-gray-900"}>
                    {selectedFile ?
                        <>
                            <img src={createImageUrl(selectedFile)} className={"h-[100%] object-cover rounded-[4px]"}/>
                            {
                                selectedFileName.length > 20 ?
                                    selectedFileName.slice(0, 20) + "..." : selectedFileName
                            }
                        </>
                        :
                        <div className={"px-[6px] text-gray-500"}>{item?.placeholder}</div>
                    }
                </div>
                <div className={"h-[100%] flex flex-row items-center"}>
                    <span className={"w-[1px]"}
                          style={{height: "calc(100% - 12px)", background: "hsl(0, 0%, 80%)"}}></span>
                    <div className={"w-[36px] h-[100%] flex items-center justify-center"}>

                        <IoImageOutline className={"!h-[20px] w-[20px]"}
                                        style={{height: "32px", color: "hsl(0, 0%, 80%)"}}/>
                    </div>
                </div>
            </div>

            <input
                accept="image/*"
                ref={inputRef}

                name={item?.name}
                type={"file"}
                placeholder={item?.placeholder}
                disabled={disabled}
                className={`transition ${item?.disabled && "disabled"} hidden  disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${item?.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                //value={inputValue}
                onChange={(e) => {
                    if (handleChange) {
                        handleImageChange(e);
                    }
                }}

            />

        </div>


    )
}


export default Image;
