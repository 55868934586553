/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Content - a content of arbit product page in the middle
 */

import PropType from "prop-types";

const units = ["px", "em", "%"];

const Content = ({ children, margin, styles, className }) => {
  return (
    <div
      className={`
          m-${
            units.some((unit) => margin.endsWith(unit))
              ? "[" + margin + "]"
              : margin
          }
          flex
          flex-col
          flex-grow
          ${className}
         `}
      style={styles}
    >
      {children}
    </div>
  );
};

Content.propTypes = {
  margin: PropType.string,
  height: PropType.string,
};

Content.defaultProps = {
  margin: "0px",
  minWidth: "1100px",
};

export default Content;
