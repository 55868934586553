import axios from "./../../middleware/axios";
import constants from "../../constant";


const fetchCurrencies = async (params) => {
    let url = constants.APIURL.GET_CURRENCIES;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
};

const searchCurrencies = async (params) => {
    let url = constants.APIURL.GET_CURRENCIES + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteCurrency = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_CURRENCY + payload, {withCredentials: true});
    return response;
};

const createCurrency = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_CURRENCY, payload, {withCredentials: true});
    return response;
};

const updateCurrency = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_CURRENCY + id, payload, {withCredentials: true});
    return response;
};

// bulk delete , restore , update status
const bulkDeleteCurrencies = async (payload) => {

    const response = await axios.delete(constants.APIURL.BULK_DELETE_CURRENCIES, {
        withCredentials: true,
        data: payload
    });
    return response;
};

const bulkRestoreCurrencies = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_CURRENCIES, payload, {withCredentials: true});
    return response;
};

const bulkUpdateCurrenciesStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_BULK_CURRENCIES_STATUS, payload, {withCredentials: true});
    return response;

};

const fetchCurrenciesFilters = async (params) => {

    let url = constants.APIURL.GET_CURRENCIES_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );

    return response?.data;
};


export default {
    fetchCurrencies,
    deleteCurrency,
    createCurrency,
    updateCurrency,
    searchCurrencies,
    bulkDeleteCurrencies,
    bulkRestoreCurrencies,
    bulkUpdateCurrenciesStatus,
    fetchCurrenciesFilters
};
