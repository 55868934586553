import React from 'react'
import {BsCalendarDate, BsHash, BsTextLeft} from 'react-icons/bs'
import {TbDimensions, TbSelector} from 'react-icons/tb'
import {BiImage} from 'react-icons/bi'
import {VscSymbolBoolean} from 'react-icons/vsc'
import {AiOutlineClockCircle} from 'react-icons/ai'
import {MdUpdate} from 'react-icons/md'
import {GrDocumentText} from 'react-icons/gr'
import {IoIosArrowDropdown} from 'react-icons/io'

function TypeComponent({type}) {
    return (
        <>
            {type === 'TEXT' && <BsTextLeft/>}
            {type === 'INTEGER' && <TbSelector/>}
            {type === 'IMAGE' && <BiImage/>}
            {type === 'BOOLEAN' && <VscSymbolBoolean/>}
            {type === 'DATE' && <BsCalendarDate/>}
            {type === 'TIME' && <AiOutlineClockCircle/>}
            {type === 'DATETIME' && <MdUpdate/>}
            {type === 'FLOAT' && <BsHash/>}
            {type === 'DOCUMENT' && <GrDocumentText/>}
            {type === 'DROPDOWN' && <IoIosArrowDropdown/>}
            {type === 'DIMENSION' && <TbDimensions/>}

        </>
    )
}

export default TypeComponent