import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchGoodsAcceptance = async (params) => {
    let url = constants.APIURL.GET_CARRIER_SERVICES;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const searchGoodsAcceptance = async (params) => {
    let url = constants.APIURL.GET_GOODS_ACCEPTANCE + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteGoodsAcceptance = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_GOODS_ACCEPTANCE + payload, {withCredentials: true});
    return response;
};

const createGoodsAcceptance = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_GOODS_ACCEPTANCE, payload, {withCredentials: true});
    return response;
};

const updateGoodsAcceptance = async (id, payload) => {
    let url = constants.APIURL.PUT_UPDATE_GOODS_ACCEPTANCE_FROM_PRODUCT + id;
    const response = await axios.post(url, payload, {withCredentials: true});
    return response;
};

const fetchGoodsAcceptancesFilters = async (params) => {
    let url = constants.APIURL.GET_GOODS_ACCEPTANCE_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

export default {
    fetchGoodsAcceptance,
    deleteGoodsAcceptance,
    createGoodsAcceptance,
    updateGoodsAcceptance,
    searchGoodsAcceptance,
    fetchGoodsAcceptancesFilters
};
