import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchGoodsAcceptances = createAsyncThunk(
    "get:api/v1/goods_acceptance",
    async (payload, thunkAPI) => {
        const response = await api.fetchGoodsAcceptance(payload);
        return response;
    }
);

const createGoodsAcceptance = createAsyncThunk(
    "post:api/v1/goods_acceptance",
    async (payload, thunkAPI) => {
        const response = await api.createGoodsAcceptance(payload);
        return response;
    }
);

const updateGoodsAcceptance = createAsyncThunk(
    "put:api/v1/goods_acceptance",
    async ({id, payload}) => {
        const response = await api.updateGoodsAcceptance(id, payload);
        return response;
    }
);

const deleteGoodsAcceptance = createAsyncThunk(
    "delete:api/v1/goods_acceptance",
    async (payload, thunkAPI) => {
        const response = await api.deleteGoodsAcceptance(payload);
        return response;
    }
);

const searchGoodsAcceptance = createAsyncThunk(
    "get:api/v1/goods_acceptance/search",
    async (payload, thunkAPI) => {
        const response = await api.searchGoodsAcceptance(payload);
        return response.data;
    }
);

const fetchGoodsAcceptancesFilters = createAsyncThunk(
    "get:api/v1/goods_acceptance/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchGoodsAcceptancesFilters(payload);
        return response;
    }
);


export {
    fetchGoodsAcceptances,
    createGoodsAcceptance,
    updateGoodsAcceptance,
    deleteGoodsAcceptance,
    searchGoodsAcceptance,
    fetchGoodsAcceptancesFilters
};
