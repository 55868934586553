import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";
import { fetchLoading } from ".";

const fetchReturns = createAsyncThunk(
    "get:api/v1/orders/shipment-labels",
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchReturns(typeof payload === "string" ? payload : payload.query);
        return {...data?.data, append: payload?.append};
    }
);

const fetchReturnsFilters = createAsyncThunk(
    "get:api/v1/returned-orders/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchReturnsFilters(payload);
        return response.data;

    }
);

const fetchNotes = createAsyncThunk(
    "get:api/v1/returned-orders/notes",
    async (payload, thunkAPI) => {
        const response = await api.fetchNotes(payload);
        return response.data;
    }
);

const deleteNote = createAsyncThunk(
    "delete:api/v1/returned-orders/notes",
    async (payload, thunkAPI) => {
        const response = await api.deleteNote(payload);
        return response.data;
    }
);

const addNote = createAsyncThunk(
    "post:api/v1/returned-orders/notes",
    async (payload, thunkAPI) => {
        const response = await api.addNote(payload);
        return response.data;
    }
);


export {fetchReturns, fetchReturnsFilters, fetchNotes, deleteNote, addNote};