import React, {useEffect, useRef, useState} from "react";
import Icons from "../../orders/icons";


function EditableText({text, onSave, className,}) {
    const [innerText, setInnerText] = useState(text);
    const [isEditing, setIsEditing] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isSaving, setIsSaving] = useState(false)

    const contentRef = useRef(null);


    const handleInput = (event) => {
        setInnerText(event.target.innerText);
        setIsEdited(true)

    }

    const handleCancel = (event) => {
        const {relatedTarget, key} = event;
        if (text !== innerText && contentRef.current) {
            if (relatedTarget && relatedTarget.id === 'weightSaveButton' || key === "Enter") {
                handleSave()
            } else {
                contentRef.current.textContent = text;
            }
        }

        setIsEditing(false);
    }


    const handleKeyUp = (event) => {
        const key = event.key;
        if (key === "Escape") {
            handleCancel(event);
            handleEdit()
            event.preventDefault();
        }
    }
    const handleKeyDown = async (event) => {
        const key = event.key;
        const selection = window.getSelection();
        const range = document.createRange();
        const currentValue = event.currentTarget.textContent;
        const newValue = currentValue.slice(0, selection.anchorOffset) + key + currentValue.slice(selection.anchorOffset)


        if (key === "Enter") {
            handleSave();
            event.preventDefault();
        }
        if (key === "Escape") {
            handleCancel(event);
            handleEdit()
            event.preventDefault();
        }


        if (innerText === "-" && isValid(key)) {
            event.currentTarget.textContent = key
            setInnerText(key);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);

            contentRef?.current?.focus();

        }


        const isValid = (newValue) => (/^[\d.]*$/.test(newValue));


        if (!isValid(newValue)) {
            event.preventDefault();
        }
    };
    const handleSave = async () => {
        const number = contentRef?.current?.textContent
        if (number && number > 0) {
            setIsSaving(true)
            await onSave(number)
            setIsSaving(false)
            handleEdit()
        }

    }

    function handleEdit() {
        if (isEdited) {
            //onSave(innerText);
            setIsEdited(false);

        }
        if (!isEditing) {
            setIsEditing(true);
        }
        contentRef.current.blur();
    }

    useEffect(() => {
        if (isEditing) {


            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(contentRef.current);
            if (innerText !== "-") {
                range.collapse(false);
            }


            selection.removeAllRanges();
            selection.addRange(range);

            contentRef?.current?.focus();
        }
        setInnerText(text);
    }, [isEditing]);

    useEffect(() => {
        //setIsEdited(false)
        //setInnerText(text);
    }, [text]);

    return (
        <div className={"flex flex-row gap[0px] items-center justify-end gap-[4px] w-full"}>
            <span
                className="min-w-max whitespace-no-wrap -mr-0.5 group-active:text-pink-800 hover:text-pink-600 flex text-[24px] text-pink-700 font-[700]">
                ₺ 
            </span>
            <div
                contentEditable={isEditing}
                tabIndex={0}
                ref={contentRef}
                onInput={handleInput}
                onBlur={handleCancel}
                onKeyPress={handleKeyDown}
                onKeyUp={handleKeyUp}
                onFocus={() => setIsEditing(true)}
                className={`focus:outline-none focus:px-[5px] rounded-[6px] focus:bg-[rgb(108,43,217,0.15)] h-[25px] leading-[25px]   ${className} ${isSaving ? "opacity-30" : ""}`}
            >
                {text ? text : 0}
            </div>

            <span
                className={"transition items-start flex-col flex w-[15px] h-[15px] cursor-pointer "}
                onClick={handleEdit}
            >
                    {!isSaving ?
                        isEditing ?
                            <button id={"weightSaveButton"} className={"w-[15px] h-[15px]"}><Icons.check
                                className={"text-blue-500"}/></button>
                            :
                            <Icons.edit className={"text-gray-500"}/>

                        :
                        <Icons.clock className={"text-[rgb(108,43,217,0.15)]"}/>
                    }
                </span>


        </div>
    );
}


export default EditableText;
