// language/thunk.js
// ----------------------------------------------------------------

import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

// Create the fetchLanguages async thunk.
export const fetchLanguages = createAsyncThunk(
    "get:api/v1/language",
    async (lng, thunkAPI) => {

        const response = await api.fetchLanguages(lng);
        return response.data;
    }
);

export default {fetchLanguages};
