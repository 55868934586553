import React from 'react';
import {BsFillExclamationCircleFill, BsFillExclamationTriangleFill} from 'react-icons/bs';

const salesChannelStatus = ({cancel_requested, refund_requested}) => {

    // cancel_requested = true
    // refund_requested = true

    const colorMapping = {
        "green": {color: "#057A55"},
        "yellow": {color: "#FACA15"},
        null: {color: "#fafafa"},
    }
    // if (!statusId) return null;
    // const bulletColor = colorMapping[color] || "gray-400";
    return (
        <div className="w-[140px] h-[18px] px-2.5 bg-rose-100 rounded-md gap-0.5 flex justify-center items-center ">
            {
                cancel_requested ? <BsFillExclamationCircleFill className="text-red-700 w-2.5 h-2.5"/> : ''
            }
            {
                refund_requested ? <BsFillExclamationTriangleFill className="text-red-700 w-2.5 h-2.5"/> : ''
            }
            <div className="text-center text-red-800 text-xs font-medium  leading-[18px]">
                {cancel_requested ? "Cancel Requested" : ''}
                {refund_requested ? "Refunded" : ''}
            </div>
        </div>
    )
}
//${colorMapping[color]}
export default salesChannelStatus;