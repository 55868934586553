import React from "react";
import icons from "../../orders/icons";
import PropTypes from "prop-types";

function ListingSales({item}) {
    const Trading=icons.tradingUp
    return (
        <div className=" justify-start gap-4 items-center px-2 inline-flex h-[104px]">
            {item?.weekly && (
                <div className="   flex-col justify-between items-start  inline-flex">
                    <div className="flex-col justify-start items-start flex">
                        <div className="text-gray-700 text-xs font-medium leading-3">
                            {item?.weekly?.upper_title || "7d Restocking"}
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                            <div className={`text-${item?.color || 'blue'}-700 text-2xl font-bold leading-6`}>
                                {item?.weekly?.upper_value || 0}
                            </div>
                            {item?.weekly?.upper_tooltip ? (
                                item?.weekly?.upper_tooltip
                            ) : (
                                <Trading className="text-gray-300"/>
                            )}
                        </div>
                    </div>
                    <div className="flex-col justify-start items-start flex">
                        <div className="text-gray-700 text-xs font-medium leading-3">
                            {item?.weekly?.lower_title || "Remaining Days"}
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                            <div className={`text-${item?.color || 'blue'}-700 text-2xl font-bold leading-6`}>
                                {item?.weekly?.lower_value
                                    ? `${item?.weekly?.lower_value}`
                                    : 0}
                            </div>
                            {item?.weekly?.lower_tooltip ? (
                                item?.weekly?.lower_tooltip
                            ) : (
                                <Trading className="text-gray-300"/>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {item?.monthly && (
                <div className="   flex-col justify-between items-start  inline-flex">
                    <div className="flex-col justify-start items-start flex">
                        <div className="text-gray-700 text-xs font-medium leading-3">
                            30d Restocking
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                            <div className={`text-${item?.color || 'blue'}-700 text-2xl font-bold leading-6`}>
                                {item?.monthly?.upper_value || 0}
                            </div>
                            {item?.monthly?.upper_tooltip ? (
                                item?.monthly?.upper_tooltip
                            ) : (
                                <Trading className="text-gray-300"/>
                            )}
                        </div>
                    </div>
                    <div className="flex-col justify-start items-start flex">
                        <div className="text-gray-700 text-xs font-medium leading-3">
                            Remaining Days{" "}
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                            <div className={`text-${item?.color || 'blue'}-700 text-2xl font-bold leading-6`}>
                                {item?.monthly?.lower_value
                                    ? `${item?.monthly?.lower_value}`
                                    : 0}
                            </div>
                            {item?.monthly?.lower_tooltip ? (
                                item?.monthly?.lower_tooltip
                            ) : (
                                <Trading className="text-gray-300"/>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {item?.biMonthly && (
                <div className="   flex-col justify-between items-start  inline-flex">
                    <div className="flex-col justify-start items-start flex">
                        <div className="text-gray-700 text-xs font-medium leading-3">
                            60d Restocking
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                            <div className={`text-${item?.color || 'blue'}-700 text-2xl font-bold leading-6`}>
                                {item?.biMonthly?.upper_value || 0}
                            </div>
                            {item?.biMonthly?.upper_tooltip ? (
                                item?.biMonthly?.upper_tooltip
                            ) : (
                                <Trading className="text-gray-300"/>
                            )}
                        </div>
                    </div>
                    <div className="flex-col justify-start items-start flex">
                        <div className="text-gray-700 text-xs font-medium leading-3">
                            Remaining Days{" "}
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                            <div className={`text-${item?.color || 'blue'}-700 text-2xl font-bold leading-6`}>
                                {item?.biMonthly?.lower_value
                                    ? `${item?.biMonthly?.lower_value}`
                                    : 0}
                            </div>
                            {item?.biMonthly?.lower_tooltip ? (
                                item?.biMonthly?.lower_tooltip
                            ) : (
                                <Trading className="text-gray-300"/>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
ListingSales.propTypes = {
    item: PropTypes.shape({
        color: PropTypes.string,
        weekly: PropTypes.shape({
            upper_title: PropTypes.string,
            upper_value: PropTypes.number,
            upper_tooltip: PropTypes.node,
            lower_title: PropTypes.string,
            lower_value: PropTypes.number,
            lower_tooltip: PropTypes.node,
        }),
        monthly: PropTypes.shape({
            upper_value: PropTypes.number,
            upper_tooltip: PropTypes.node,
            lower_value: PropTypes.number,
            lower_tooltip: PropTypes.node,
        }),
        biMonthly: PropTypes.shape({
            upper_value: PropTypes.number,
            upper_tooltip: PropTypes.node,
            lower_value: PropTypes.number,
            lower_tooltip: PropTypes.node,
        }),
    }).isRequired,
};



export default ListingSales;
