import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchInventory = createAsyncThunk(
    "get:api/v1/inventory",
    async (payload, thunkAPI) => {
        const response = await api.fetchInventory(payload);
        return response.data;
    }
);

export {fetchInventory}