const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Price",
                value: "price",
                onClick: (item) => {
                    callback("price");
                },
            },
            {
                label: "Quantity",
                value: "quantity",
                onClick: (item) => {
                    callback("quantity");
                },
            },
        ],
    };
};

export default sortView;
  