import React from 'react';
import Icons from "../../icons";

const orderStatusStyle = {
    content: '',
    width: '100%',
    position: 'absolute',
    height: '5.1px',
    background: '#FDE8E8',
    zIndex: 0,
    boxShadow:
        '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
};

const iconStyle = {
    boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '100%', // You can adjust the value as needed
};

const OrderStatus = ({statusId}) => {
    const statusList = {
        1: <div className='flex flex-row justify-between items-center w-full relative order-status'
                style={orderStatusStyle} // Apply the styles here
        >
            <Icons.link style={iconStyle}/>
            <Icons.exclamationCircle style={iconStyle}/>
        </div>,
        2: <div className='flex flex-row justify-between items-center w-full relative order-status'
                style={orderStatusStyle} // Apply the styles here
        >
            <Icons.link style={iconStyle}/>
            <Icons.exclamationCircle style={iconStyle}/>
            <Icons.cross style={iconStyle}/>
        </div>
    }
    if (statusId) return (statusList[statusId]);
}

export default OrderStatus;