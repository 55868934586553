import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";
import { fetchLoading } from ".";

const fetchSupPurchases = createAsyncThunk(
    "get:api/v1/currencies",
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
          dispatch(fetchLoading());
        }
        const data = await api.fetchSupPurchases(
          typeof payload === "string" ? payload : payload.query
        );
        return { ...data?.data, append: payload?.append };
      }
);


const bulkDeleteSupPurchases = createAsyncThunk(
    "delete:api/v1/currencies/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteSupPurchases(payload);
        return response;
    }
);

const updateSupPurchasesStatus = createAsyncThunk(
    "put:api/v1/currencies/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdateSupPurchasesStatus(payload);
        return response;
    }
);

const bulkRestoreSupPurchases = createAsyncThunk(
    "put:api/v1/currencies/bulk/restore",
    async (payload, thunkAPI) => {
        const response = await api.bulkRestoreSupPurchases(payload);
        return response;
    }
);

const fetchSupPurchasesFilters = createAsyncThunk(
    "get:api/v1/currencies/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchSupPurchasesFilters(payload);
        return response;
    }
);

const updateSupplyPurchase = createAsyncThunk(
    "put:api/v1/currencies",
    async (payload, thunkAPI) => {
        const response = await api.updateSupplyPurchase(payload);
        return response;
    }
);

// const updateSupplyPurchaseFromRequest = createAsyncThunk(
//   "put:api/v1/currencies",
//   async (payload, thunkAPI) => {
//     const response = await api.updateSupplyPurchaseFromRequest(payload);
//     return response;
//   }
// );


export {
    fetchSupPurchases,
    bulkDeleteSupPurchases,
    updateSupPurchasesStatus,
    bulkRestoreSupPurchases,
    fetchSupPurchasesFilters,
    updateSupplyPurchase
};
