import React from "react";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import UI from "../../dynamic-form/elements";
import ArbitToggle from "../../sub-elements/toggle";
import {howLongFromNow} from "../../../utils/helper";

const Listing = ({modalListing, fulfilmentHandler}) => {
    const listData01 = [
        {
            title: "Sales Channel",
            value: modalListing?.sales_channel_listing?.channel?.name || modalListing?.channel?.name || "-",
        },
        {
            title: "Listing ID",
            value: modalListing?.sales_channel_listing?.identifier || modalListing?.identifier || "-",
        },
        {
            title: "Last Update",
            value:
                (
                    <div className="text-emerald-700">
                        {howLongFromNow(
                            modalListing?.sales_channel_listing?.updated_at || modalListing?.updated_at,
                            true
                        )}
                    </div>
                ) || "-",
        },
        // Add more data items as needed
    ];
    const listData02 = [
        {
            title: "Buybox Price",
            value: modalListing?.sales_channel_listing?.["buybox_price"] || modalListing?.["buybox_price"]
                ? "$" + modalListing?.sales_channel_listing?.["buybox_price"]
                : "No BB" || "-",
        },
        {
            title: "Lowest Price",
            value: modalListing?.sales_channel_listing?.["bb_low_price"]
                ? "$" + modalListing?.sales_channel_listing?.["bb_low_price"]
                : "-" || "-",
        },
        {
            title: "Total Offer Count",
            value: modalListing?.sales_channel_listing?.total_offer_count || "-",
        },
        {
            title: "Buybox Owner",
            value: modalListing?.sales_channel_listing?.buybox_seller_type || "-",
        },
        // Add more data items as needed
    ];
    const listData03 = [
        {
            title: "Root Category",
            value: modalListing?.sales_channel_listing?.root_category ?? "-",
        },
        {
            title: "Sales Rank",
            value: modalListing?.sales_channel_listing?.["sales_rank"] || modalListing?.sales_channel_listing?.["sales_rank"] === 0 ? "#" + modalListing?.sales_channel_listing?.["sales_rank"] : "-",
        },
        {
            title: "Brand",
            value: modalListing?.sales_channel_listing?.brand ?? "-",
        },
        // Add more data items as needed
    ];
    const listData04 = [
        {
            title: "Review Count",
            value: modalListing?.sales_channel_listing?.["reviews_count"] || "-",
        },
        {
            title: "Review Rating",
            value: modalListing?.sales_channel_listing?.["ratings"] || "-",
        },
        {
            title: "Variation Count",
            value: modalListing?.sales_channel_listing?.["variation_count"] || "-",
        },
        // Add more data items as needed
    ];
    const listData05 = [
        {
            title: "Show FBA restocking sugg. for this listing.",
            value: "FBA Restocking",
            elements: (
                <ArbitToggle
                    status={modalListing?.fba_eligibility ?? false}
                    bgColor={
                        modalListing?.fba_eligibility ? "bg-orange-600" : "bg-gray-400"
                    }
                    handleFulfilment={() => fulfilmentHandler("fba", modalListing?.id)}
                />
            ),
        },
        {
            title: "Show FBM quantity update sugg. for this listing.",
            value: "FBM Supplying",
            elements: (
                <ArbitToggle
                    status={modalListing?.fbm_eligibility ?? false}
                    bgColor={
                        modalListing?.fbm_eligibility ? "bg-blue-600" : "bg-gray-400"
                    }
                    handleFulfilment={() => fulfilmentHandler("fbm,", modalListing?.id)}
                />
            ),
        },
        // Add more data items as needed
    ];
    const listData06 = [
        {title: "Referral Fee", value: "-"},
        {title: "Amazon Billable Weight", value: "-"},
        // Add more data items as needed
    ];

    return (
        <div className="mt-5 flex flex-col justify-between items-center gap-y-3 gap-x-2 w-full">
            <div className="flex flex-row justify-between items-center gap-x-3 w-full">
                <div className="rounded-md h-full">
                    {modalListing?.sales_channel_listing?.main_image_path || modalListing?.image ? (
                        <ImageMagnifier
                            src={modalListing?.sales_channel_listing?.main_image_path || modalListing?.main_image_path}
                            className={"max-h-[200px] max-w-[200px] min-w-[200px] z-0"}
                        />
                    ) : (
                        <img
                            src={"/assets/images/noImage.png"}
                            className={"h-[52px] w-[52px]"}
                            alt="noImage"
                        />
                    )}
                </div>
                <div className="flex flex-col justify-between items-center gap-3 w-full">
                    <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                        <UI.StatBoxList data={listData01}/>
                    </div>
                    <div className="grid grid-cols-4 justify-center items-center gap-x-3 w-full">
                        <UI.StatBoxList data={listData02}/>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={listData03}/>
            </div>
            <div className="grid grid-cols-3 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={listData04}/>
            </div>
            <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={listData05}/>
            </div>
            <div className="grid grid-cols-2 justify-center items-center gap-x-3 w-full">
                <UI.StatBoxList data={listData06}/>
            </div>
        </div>
    );
};

export default Listing;
