import upperCaseString from "../../dynamic-page/util/upperCaseString";

const FiltersView = (selectedFilers) => {

    return (
        [
            {
                name: "identifier",
                label: "Identifier",
                placeholder: "Filter by identifier",
                type: "Input",
                toggle: true,
                isClearable: true,
                defaultValue: selectedFilers?.identifier,
            },
            {
                name: "sku",
                label: "SKU",
                placeholder: "Filter by sku",
                type: "Input",
                toggle: true,
                isClearable: true,
                defaultValue: selectedFilers?.sku,
            },


            {
                name: "account",
                label: "Account",
                placeholder: "Filter by account",
                type: "Select",
                api: {
                    url: "/api/v1/accounts",
                    query: "?filter[name]={query}",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: item?.name,
                        avatar: item?.channel?.logo
                    })
                },
                features: {
                    isMulti: true,
                    formatOptionLabel: ({label, avatar}) => (
                        <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                                <span
                                    className={"w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "}>
                                    <img src={avatar} className="object-contain w-full h-full"/>
                                </span>
                            <span>{upperCaseString(label)}</span>
                        </div>)
                },
                defaultValue: selectedFilers?.account,
                toggle: true,
                clear: true,
            },

            {
                name: "channel",
                label: "Channel",
                placeholder: "Filter by Channel",
                type: "Select",
                api: {
                    url: "/api/v1/channel",
                    query: "?term={query}",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: item?.name,
                        logo: item?.logo
                    })
                },
                features: {
                    isMulti: true,
                    formatOptionLabel: ({label, logo}) => (
                        <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                                <span
                                    className={"w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "}>
                                    <img src={logo}/>
                                </span>
                            <span>{upperCaseString(label)}</span>
                        </div>)
                },
                defaultValue: selectedFilers?.channel,
                toggle: true,
                clear: true,
            },

            {
                name: "price",
                label: "Price",
                placeholder: "Filter by price",
                type: "Range",
                defaultValue: selectedFilers?.price,
                toggle: true,
                clear: true,
            },


        ]
    )
}

export default FiltersView;