import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchPaymentMethods = createAsyncThunk(
    "get:api/v1/payment_methods",
    async (payload, thunkAPI) => {
        const response = await api.fetchPaymentMethods(payload);
        return response.data;
    }
);

const createPaymentMethod = createAsyncThunk(
    "post:api/v1/payment_methods",
    async (payload, thunkAPI) => {
        const response = await api.createPaymentMethod(payload);
        return response;
    }
);

const updatePaymentMethod = createAsyncThunk(
    "put:api/v1/payment_methods",
    async (payload, thunkAPI) => {
        const response = await api.updatePaymentMethod(payload);
        return response;
    }
);

const deletePaymentMethod = createAsyncThunk(
    "delete:api/v1/payment_methods",
    async (payload, thunkAPI) => {
        const response = await api.deletePaymentMethod(payload);
        return response;
    }
);

const searchPaymentMethods = createAsyncThunk(
    "get:api/v1/payment_methods/search",
    async (payload, thunkAPI) => {
        const response = await api.searchPaymentMethods(payload);
        return response.data;
    }
);

const bulkDeletePaymentMethods = createAsyncThunk(
    "delete:api/v1/payment_methods/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeletePaymentMethods(payload);
        return response;
    }
);

const updatePaymentMethodsStatus = createAsyncThunk(
    "put:api/v1/payment_methods/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdatePaymentMethodsStatus(payload);
        return response;
    }
);

const bulkRestorePaymentMethods = createAsyncThunk(
    "put:api/v1/payment_methods/bulk/restore",
    async (payload, thunkAPI) => {
        const response = await api.bulkRestorePaymentMethods(payload);
        return response;
    }
);

const fetchPaymentMethodsFilters = createAsyncThunk(
    "get:api/v1/payment_methods/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchPaymentMethodsFilters(payload);
        return response;
    }
);


export {
    fetchPaymentMethods,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    searchPaymentMethods,
    bulkDeletePaymentMethods,
    updatePaymentMethodsStatus,
    bulkRestorePaymentMethods,
    fetchPaymentMethodsFilters
};
