import Magnifier from "react-magnifier";
import {useState} from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ImageMagnifier = ({src, className}) => {
    const [imageLoaded, setImageLoaded] = useState(true)
    const handleContext = (e) => {
        e.stopPropagation();
    }


    return (
        <>
            <style>
                {`
                    .magnifierDynamicPage img {object-fit: contain; max-height:inherit}
                    .magnifierDynamicPage {max-height: 100%; width: 100%}
                    .magnifierDynamicPage > div > div {
                        display: flex;
                    }
                `}
            </style>
            <span className={"magnifierDynamicPage"} onContextMenu={handleContext}>
                {imageLoaded && src ? (
                    <Zoom>
                        <Magnifier
                            zoomFactor={2}
                            src={src}
                            onError={(e) => {
                                e.target.src = "/assets/images/noImage.png";
                                setImageLoaded(false);
                            }}
                            //magnifierBorderColor={"rgba(0,0,0,0)"}
                            mgWidth={300}
                            mgHeight={300}
                            mgBorderWidth={0}
                            className={`hover:z-[99999999999999] object-cover z-0 ${
                                className ? className : ""
                            }`}
                            //glassClassName={"!z-[9999] !border-2 !shadow-none !opacity-20"}
                        />
                    </Zoom>
                ) : (
                    <img src="/assets/images/noImage.png"/>
                )}
            </span>

        </>

    )
}

export default ImageMagnifier;