import React, {useEffect, useRef, useState} from "react";

const Textarea = ({
                      item,
                      value,
                      validationMessage,
                      handleChange,
                      formData,
                      handleFocus,
                      handleBlur,
                      handleKeyPress,
                  }) => {
    const disabled =
        typeof item.disabled === "function"
            ? item.disabled(formData)
            : item.disabled || false;
    const textareaRef = useRef(null);
    const [textareaHeight, setTextareaHeight] = useState("auto");

    useEffect(() => {
        // Set the initial height to the scroll height of the textarea
        if (textareaRef.current && item?.dynamicTextarea) {
            setTextareaHeight(`${textareaRef.current.scrollHeight}px`);
        }
    }, []);

    useEffect(() => {
        // Reset the height to 'auto' when the value becomes empty
        if (value === "") {
            setTextareaHeight("auto");
        }
    }, [value]);

    const handleTextareaChange = (e) => {
        handleChange(e.target.value, item.name);
        // Update the height of the textarea as the content changes
        setTextareaHeight(`${textareaRef.current.scrollHeight}px`);
    };

    // Return Textarea in view
    return (
        <textarea
            ref={textareaRef}
            name={item.name}
            placeholder={item.placeholder}
            disabled={disabled}
            style={{height: item?.dynamicTextarea && textareaHeight}} // Set dynamic height
            className={`transition ${
                item.disabled && "disabled"
            } overflow-auto resize-none overflow-hidden  disabled:text-gray-400 rounded-md px-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${
                item.innerClassName || ""
            } ${validationMessage ? "border-red-500 border-2" : ""}`}
            rows={item.rows || "2"}
            onChange={handleTextareaChange}
            value={value || ""}
            onFocus={(e) => {
                if (handleFocus) handleFocus(e.target.value, item.name);
            }}
            onBlur={(e) => {
                if (handleBlur) handleBlur(e.target.value, item.name);
            }}
            onKeyPress={(e) => {
                if (handleKeyPress) {
                    // e.key === "Enter" && e.preventDefault()
                    if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleKeyPress(e);
                    }
                }
            }}
            autoFocus={item?.autoFocus}
        ></textarea>
    );
};

export default Textarea;
