// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./models-view/table";
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tabsView from "./models-view/tabs";
import actionsView from "./models-view/actions";
import addForm from "./models-view/addForm";
import optionsView from "./models-view/options";

// Main Component
const InventoryAdjustmants = ({ product_id }) => {
  // Initial Page
  const Page = usePage({
    pageTitle: "Inventory Adjustments",
    api: {
      fetch: "inventory",
      filters: "inventory/filters",
      query: "include=employee,product.brand.assigned",
    },
    ModelsView: {
      table: tableView,
      filter: filtersView,
      sort: sortView,
      tabs: tabsView,
      actions: actionsView,
      headerOption: optionsView,
      forms: {
        add: addForm,
      },
    },
    pageSettings: {
      hiddenActions: true,
      hideSelect: true,
      ignoredFilters: ["type"],
      product: {
        hideProduct: product_id ? true : false,
        hideHeaddings: product_id ? true : false,
        filter: { product: product_id },
      },
    },
  });

  // return View
  return Page;
};

export default InventoryAdjustmants;
