import {useRef, useState} from "react";
import MainModal from "../../dynamic-page/MainModal";
import Icons from "../../orders/icons";
import {toast} from "react-toastify";
import UI from "../../dynamic-form/elements";

const ImportListings = ({cancel, id, handleSubmit}) => {
    const [dragging, setDragging] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState(null);
    const createNewRef = useRef(false);
    const updateCreateNew = (value) => {
        createNewRef.current = value;
    };
    const handleCheckboxChange = () => {
        updateCreateNew(!createNewRef.current);
    };

    const handleFileChange = (e) => {
        uploadFile(e.target.files);
        e.target.value = null;
        e.target.removeEventListener("change", handleFileChange);
        e.target.addEventListener("change", handleFileChange);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const droppedFile = e.dataTransfer.files;
        const filesArray = Array.from(droppedFile);
        // check for the files types
        const allowedTypes = [
            "text/csv",
            // excel
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        if (!filesArray.every((file) => allowedTypes.includes(file.type))) {
            toast.info("Invalid file type");
            return;
        }
        uploadFile(droppedFile);
    };

    const uploadFile = (file) => {
        if (file.length === 0) return;
        let files = Array.from(file);

        const notifications = []; // Array to store all notifications

        // Check for files larger than 10MB
        const bigFiles = files.filter((file) => file.size > 10000000);
        if (bigFiles.length > 0) {
            bigFiles.forEach((file) => {
                notifications.push(
                    <li className={"opacity-50"}>{file.name} is too large.</li>
                );
            });
            files = files.filter((file) => file.size <= 10000000);
        }

        // Check for invalid file types
        const allowedTypes = [
            "text/csv",
            // excel
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        const invalidFiles = files.filter(
            (file) => !allowedTypes.includes(file.type)
        );
        if (invalidFiles.length > 0) {
            invalidFiles.forEach((file) => {
                notifications.push(
                    <li className={"opacity-50"}>{file.name} is invalid.</li>
                );
            });
            files = files.filter((file) => allowedTypes.includes(file.type));
        }

        // Display combined notification
        if (notifications.length > 0) {
            toast.info(
                <>
                    <b>File Upload Warnings:</b>
                    <ul className={"list-disc list-inside my-[5px]"}>{notifications}</ul>
                </>
            );
        }

        setFile(files[0]);

        // handleSubmit({ listings: files[0] });
    };

    const handleClose = () => {
        cancel();
    };

    return (
        <MainModal
            containerClassName="!pb-5 !px-5"
            item={{
                title: "Create Supply Channel Listings via Upload",
                cancel: handleClose,
                // submit: formik.handleSubmit,
                view: (item) => (
                    <>
                        <style>{`
                      .drag-drop-area {
                        border: 2px dashed #E5E7EB;
                        padding: 20px;
                        text-align: center;
                        cursor: pointer;
                        transition: all 0.9s ease;
                        position: relative; 
                    }
                    
                    .drag-drop-area.dragging {
                        border-color: #007bff; /* Change the color as per your design */
                        background-color: rgba(0, 123, 255, 0.05);
                        transition: all 0.9s ease;
                    }

                    .drag-drop-area.dragging:after {
                        width: 100%;
                        content: "Drop here";
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        top: 50%;
                        align-items: center;
                        left: 50%;
                        border-radius: 10px;
                        transform: translate(-50%, -50%);
                        color: #007bff;
                        background-color: #ebf5ff;
                        font-size: 24px;
                        height: 100%;
                        font-weight: bold;
                        transition: background-color 0.9s ease;
                    }
                    
                    
                      `}</style>
                        <div className="flex flex-col gap-2 pt-2 w-[672px] border-t ">
                            <label htmlFor="file-upload">
                                <div
                                    className={`flex flex-col items-center h-[160px] justify-center bg-gray-50 border-2 border-dashed border-gray-400 rounded-lg p-4  drag-drop-area ${
                                        dragging ? "dragging" : ""
                                    }`}
                                    onDragOver={handleDragOver}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className="hidden"
                                        accept="text/csv , application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={handleFileChange}
                                        disabled={uploading}
                                    />
                                    {file ? (
                                        <div className="flex flex-col gap-2 items-center">
                                            {/* <Icons.FileIcon /> */}
                                            <span className="text-md font-bold text-gray-700">
                        <span className="text-gray-500 font-semibold text-sm">
                          Selected File:
                        </span>{" "}
                                                {file.name}
                      </span>
                                        </div>
                                    ) : (
                                        <label
                                            htmlFor="file-upload"
                                            className="flex flex-col items-center gap-2 cursor-pointer text-gray-500"
                                        >
                                            <Icons.uploadOutline className="w-5 h-5"/>
                                            <span className="text-gray-500 text-sm">
                        <span className="font-semibold text-sm">
                          Click to upload
                        </span>{" "}
                                                or drag and drop
                      </span>
                                        </label>
                                    )}
                                    <span className="mt-2 text-xs text-gray-500 font-semibold">
                    Max. File Size: 10MB
                  </span>
                                    <span className="text-xs text-gray-500">
                    Accepted formats are pdf, jpg, jpeg, png and txt.
                  </span>
                                </div>
                            </label>
                            <div className="flex w-full justify-between    gap-[8px]">
                                <div className="">
                                    <input
                                        type="checkbox"
                                        id="scales"
                                        name="scales"
                                        ref={createNewRef}
                                        onChange={handleCheckboxChange}
                                        disabled={false}
                                        className={
                                            "w-[20px] h-[20px] mr-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-md "
                                        }
                                    />
                                    <label
                                        for="scales"
                                        className="text-[14px] font-medium text-gray-900"
                                    >
                                        Create Another
                                    </label>
                                    {/* <Checkbox
                                    item={{
                                        list : [
                                            {label : "Create New", value : true}
                                        ],
                                        clear: false,
                                        labelClassName: '!text-gray-900 !font-medium',
                                        checkAll : false,
                                    }}
                                    handleChange={handleCheckboxChange}
                                    value={createNewRef.current}
                                    disabled={false}
                                    className={"w-[20px] h-[20px]"}
                                /> */}
                                </div>

                                <div className="flex gap-2">
                                    <UI.Button
                                        item={{
                                            label: <div className={"flex flex-row"}>Cancel</div>,
                                            buttonType: "alt",
                                            className: "h-[37px] !px-[16px]",
                                        }}
                                        onClick={() => cancel()}
                                    />
                                    <UI.Button
                                        item={{
                                            label: <div className={"flex flex-row"}>Create</div>,
                                            buttonType: "primary",
                                            className: "h-[37px] !px-[16px]",
                                        }}
                                        onClick={() => {
                                            handleSubmit(
                                                {listings: file},
                                                createNewRef.current,
                                                setFile
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ),
            }}
        />
    );
};

export default ImportListings;
