import React, { useCallback, useEffect, useState } from "react";
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import DynamicTable from "../dynamic-page";
import CreateFromScratch from "./modals-view/form";
import ConnectForm from "./modals-view/connectModal";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { useDispatch, useSelector } from "react-redux";
import util from "../dynamic-page/util";
import TableView from "./modals-view/table";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import PropTypes from "prop-types";
import {
  fetchSupplyListings as fetchData,
  updateBoundFilter,
  updateFilter,
  updateRow,
} from "../../store/supply-channel-listings";
import sortView from "./modals-view/sort";
import TabButton from "../dynamic-page/elements/TabButton";
import SearchInput from "../dynamic-page/debt/searchInput";
import FiltersView from "./modals-view/filters";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import Import from "./modals-view/import";
import getActions from "../dynamic-page/util/getActions";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import MethodForm from "./modals-view/MethodForm";
import CreateFromProduct from "./modals-view/fromProductForm";
import Icons from "../orders/icons";
import useLoadMoreData from "../../utils/useLoadMoreData";
import { useLocation } from "react-router-dom";
import ProductConnectChannelModal from "./modals-view/productDetailForm";
import Button from "../dynamic-form/elements/Button";

const SupplyChannels = ({
  product_id = null,
  displayTitle = "flex",
  displayTabGroups = "flex",
  hideFilter,
  showCreateForm = false,
  showSupplyModal,
  setShowSupplyModal,
  updateListingsCount,
}) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  const [importModel, setImportModel] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const Link2 = Icons.link2;
  const Cloud = Icons.cloudArrowDown;

  useEffect(() => {
    let filters = {};

    if (product_id) {
      filters = { product_id: product_id };
    }

    const startPageByFilters = {
      filters: filters,
      sort: null,
      page: 1,
      limit: 10,
      search: "",
      includes: "product.brand.assigned,currency,channel",
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.supplyChannelListings);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState(null);

  // handle refreshing
  const [refreshing] = useState(false);

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const actionList = {
    edit: {
      label: "Edit",
      onClick: (item) => {
        if (item?.id) {
          setFormData(item);
          setCurrentModal("scratchForm");
        } else {
          setFormData(selectedRow[0]);
          setCurrentModal("scratchForm");
        }
      },
      bulkDisable: true,
    },
    disconnectProduct: {
      label: "Disconnect Product",
      onClick: (item) => {
        if (item?.id) {
          handledisConnectProduct(null, item);
        }
      },
      bulkDisable: true,
      condition: (item) => item?.product != null,
    },
    delete: {
      label: "Delete",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to delete supply channel listing/s?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const ids = item ? [item.id] : selectedRow.map((item) => item.id);
            callEndpoint({
              // title: "Delete Supply Channel",
              url: `supply-channel-listing/bulk-delete`,
              method: "DELETE",
              data: {
                listings: ids,
              },
              pureData: true,
            }).then((res) => {
              setSubmit(false);
              util.notifier({ type: res.type, message: res.message });
              if (res.type === "success") {
                setSelectedRow([]);
                setConfirmationModalVisibility(null);
                onPaginationApply();
              }
            });
          },
        });
      },
    },
    connect: {
      label: "Connect Product",
      onClick: (item) => {
        const items = item?.id ? [item] : selectedRow;
        const filteredItems = items.filter((item) => item?.product == null);
        setFormData(filteredItems);
        setConnectModal(true);
      },
      condition: (item) => item?.product == null,
    },
  };

  const actions = (row) => {
    return getActions(row, actionList, null, true);
  };

  const onHandleTab = (item) => {
    onFilterApply({ supply_channel_type: item });
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      changeSort = sort;
    } else {
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    const currentStoreFilters = {
      ...pageData?.filters,
      includes: "product.brand.assigned,currency,channel",
    };
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    // await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
    setSelectedRow(null);
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onSubmitApply = useCallback((data, close, callback) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { id, ...payload } = data;

        if (!payload.is_trackable) {
          payload.is_trackable = 0;
          delete payload.quantity;
        } else {
          payload.is_trackable = 1;
        }

        if (!payload.create_product) {
          payload.create_product = 0;
        } else {
          payload.create_product = 1;
        }

        // if url is empty send it as null
        if (!payload.url) {
          delete payload.url;
        }

        // if no image or no product_id send it as null
        if (!payload.image) {
          delete payload.image;
        }
        if (!payload.product_id) {
          delete payload.product_id;
        }

        const innerData = {
          ...payload,
        };

        if (!id) {
          const createResponse = await callEndpoint({
            title: "Create Supply Channel",
            url: `supply-channel-listing`,
            method: "POST",
            data: innerData,
            // pureData: true,
          });
          if (createResponse.type === "success") {
            if (close) {
              onPaginationApply();
              setFormData(null);
              setModalVisibility(false);
              setShowSupplyModal(false);
              setCurrentModal(null);
            } else if (callback) {
              callback(onPaginationApply);
            }
            resolve(createResponse);
          } else {
            reject(new Error(createResponse)); // Reject the promise on an error
          }
        } else {
          const updateResponse = await callEndpoint({
            title: "Update Supply Channel",
            url: `supply-channel-listing/${id}`,
            method: "POST",
            data: innerData,
            pureData: true,
          });
          if (updateResponse.type === "success") {
            setSelectedRow([]);
            onPaginationApply();
            setFormData(null);
            setModalVisibility(false);
            setCurrentModal(null);
            resolve(updateResponse);
          } else {
            reject(new Error(updateResponse)); // Reject the promise on an error
          }
        }
      } catch (error) {
        reject(new Error(error)); // Reject the promise in case of an exception
      }
    });
  }, []);

  const onCreateFromProduct = useCallback((data, close, callback) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { id, ...payload } = data;

        if (!payload.is_trackable) {
          payload.is_trackable = 0;
          delete payload.quantity;
        } else {
          payload.is_trackable = 1;
        }

        // if url is empty send it as null
        if (!payload.url) {
          delete payload.url;
        }

        if (!payload.product_id) {
          delete payload.product_id;
        }

        const innerData = {
          ...payload,
        };

        if (!id) {
          const createResponse = await callEndpoint({
            title: "Create Supply Channel",
            url: `supply-channel-listing/create-from-product`,
            method: "POST",
            data: innerData,
            pureData: true,
          });
          if (createResponse.type === "success") {
            if (close) {
              setFormData(null);
              setModalVisibility(false);
              setShowSupplyModal(false);
              setCurrentModal(null);
              onPaginationApply();
            } else if (callback) {
              callback(onPaginationApply);
            }
            resolve(createResponse);
          } else {
            reject(new Error(createResponse)); // Reject the promise on an error
          }
        } else {
          const updateResponse = await callEndpoint({
            title: "Update Supply Channel",
            url: `supply-channel-listing/${id}`,
            method: "POST",
            data: innerData,
            pureData: true,
          });
          if (updateResponse.type === "success") {
            onPaginationApply();
            setCurrentModal(null);
            setFormData(null);
            setModalVisibility(false);
            resolve(updateResponse);
          } else {
            reject(new Error(updateResponse)); // Reject the promise on an error
          }
        }
      } catch (error) {
        reject(new Error(error)); // Reject the promise in case of an exception
      }
    });
  }, []);

  const handleSavePrice = (t, price) => {
    const innerData = {
      // channel_id: price?.data?.channel?.id,
      // title: price?.data?.title,
      is_trackable: price?.data?.is_trackable,
      // brand_id: price?.data?.brand?.id,
      currency_id: price?.data?.currency?.id,
      // product_id: price?.data?.product?.id,
      ...(price?.data?.is_trackable && { quantity: price?.data?.quantity }),
      // image: price?.data?.image,
      price: String(t),
    };

    callEndpoint({
      title: "Update Supply Channel",
      url: `supply-channel-listing/${price?.data?.id}`,
      method: "POST",
      data: innerData,
      pureData: true,
    }).then((res) => {
      if (res?.type === "success") {
        // onPaginationApply()
        callEndpoint({
          url: `supply-channel-listing/${price?.data?.id}`,
          method: "GET",
        }).then((rs) => {
          dispatch(
            updateRow({
              path: `supplyChannelListings.[id:${price?.data?.id}]`,
              value: rs.data,
            })
          );
        });
      }
    });
  };
  const handleSaveQty = (t, price) => {
    const innerData = {
      is_trackable: price?.is_trackable,
      quantity: String(t),
    };

    callEndpoint({
      title: "Update Supply Channel",
      url: `supply-channel-listing/${price?.id}`,
      method: "POST",
      data: innerData,
    }).then((res) => {
      if (res?.type === "success") {
        // onPaginationApply()
        callEndpoint({
          url: `supply-channel-listing/${price?.id}`,
          method: "GET",
        }).then((rs) => {
          dispatch(
            updateRow({
              path: `supplyChannelListings.[id:${price?.id}]`,
              value: rs.data,
            })
          );
        });
      }
    });
  };

  const handleConnectProduct = (id, data, enable, close) => {
    const innerData = {
      is_trackable: data?.is_trackable,
      product_id: id,
      ...(data?.is_trackable && { quantity: data?.quantity }),
    };

    callEndpoint({
      title: "Update Supply Channel",
      url: `supply-channel-listing/${data?.id}`,
      method: "POST",
      data: innerData,
    }).then((res) => {
      enable();
      if (res?.type === "success") {
        // onPaginationApply()
        close();
        callEndpoint({
          url: `supply-channel-listing/${data?.id}?includes=product.brand`,
          method: "GET",
        }).then((rs) => {
          dispatch(
            updateRow({
              path: `supplyChannelListings.[id:${data?.id}]`,
              value: rs.data,
            })
          );
          dispatch(
            updateRow({
              path: `supplyChannelListings.[id:${data?.id}]._updatedRow`,
              value: true,
            })
          );
        });
      }
    });
  };
  const handledisConnectProduct = (id, data) => {
    const innerData = {
      is_trackable: data?.is_trackable,
      product_id: null,
      ...(data?.is_trackable && { quantity: data?.quantity }),
    };

    callEndpoint({
      title: "Disconnect Product from Supply Channel",
      url: `supply-channel-listing/${data?.id}`,
      method: "POST",
      data: innerData,
      pureData: true,
    }).then((res) => {
      if (res?.type === "success") {
        // onPaginationApply()
        callEndpoint({
          url: `supply-channel-listing/${data?.id}`,
          method: "GET",
        }).then((rs) => {
          if (product_id) {
            onPaginationApply();
            updateListingsCount("supply-channel-listing", "supply");
          }
          dispatch(
            updateRow({
              path: `supplyChannelListings.[id:${data?.id}]`,
              value: rs.data,
            })
          );
        });
      }
    });
  };

  const onConnect = async (bound) => {
    const innerData = {
      // channel_id: data?.channel?.id,
      // title: data?.title,
      is_trackable: bound?.listId?.is_trackable,
      // brand_id: data?.brand?.id,
      // currency_id: data?.currency?.id,
      product_id: product_id,
      ...(bound?.listId?.is_trackable && { quantity: bound?.listId?.quantity }),
      // image: data?.image,
      // price: data?.price,
    };

    callEndpoint({
      title: "Connect Supply Channel",
      url: `supply-channel-listing/${bound?.listId?.id}`,
      method: "POST",
      data: innerData,
    }).then((res) => {
      if (res?.type === "success") {
        onPaginationApply();
        setShowSupplyModal(false);
      }
    });
  };

  const handleImport = (data, createNew, setFile) => {
    callEndpoint({
      title: "Import Supply Channel",
      url: `supply-channel-listing/import`,
      method: "POST",
      data: data,
      // pureData: true,
    }).then((res) => {
      if (res?.type === "success") {
        onPaginationApply();
        if (createNew) {
          setCurrentModal(null);
        } else {
          setFile(null);
        }
      }
    });
  };

  function downloadCSV(csvData, fileName) {
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col w-full">
        <ArbitProductPage.Title
          title={"Supply Channel Listings"}
          displayTitle={displayTitle}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: [[hideFilter]],
            items: FiltersView(
              pageData?.filters?.filters,
              pageData?.filtersMeta
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count: Object.values(pageData?.filters?.filters ?? {}).filter(
              (item) => item !== null && item !== ""
            ).length,
          }}
          actionList={actions(selectedRow)}
          options={[
            {
              callBack: () => setConnectModal(true),
              view: ({ callBack }) => (
                <Button
                  item={{
                    label: (
                      <div
                        className={`flex flex-row items-center justify-center gap-[4px] `}
                      >
                        <div className={"flip-x transform scale-x-[-1]"}>
                          <Link2 className={"h-5 w-5"} />
                        </div>
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!min-w-[28px] !h-[28px] !p-0  ",
                  }}
                  // onClick={handleRowClick}
                  onClick={() => {
                    callBack();
                  }}
                />
              ),
            },
            {
              callBack: () => {
                if (selectedRow?.length) {
                  callEndpoint({
                    title: "Export Supply Channel Listings",
                    url: `supply-channel-listing/export`,
                    method: "POST",
                    data: {
                      listings: selectedRow.map((item) => item.id),
                    },
                  }).then((res) => {
                    // if (res?.type === "success") {
                    downloadCSV(res, "supply-channel-listing.csv");
                    // }
                  });
                } else {
                  callEndpoint({
                    title: "Export Supply Channel Listings",
                    url: `supply-channel-listing/export`,
                    method: "POST",
                  }).then((res) => {
                    // if (res?.type === "success") {
                    downloadCSV(res, "supply-channel-listing.csv");
                    // }
                  });
                }
              },

              data: refreshing,
              view: ({ callBack, data }) => (
                <Button
                  item={{
                    label: (
                      <div className={``}>
                        <div className={" "}>
                          <Cloud className="h-6 w-6" />
                        </div>
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!min-w-[28px] !h-[28px] !p-0  ",
                  }}
                  // onClick={handleRowClick}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
            },
          ]}
        />

        {!showCreateForm && (
          <div
            className={
              "flex flex-row justify-between px-[16px] py-[4px] items-center"
            }
          >
            <SearchInput
              className="w-[362px]"
              placeholder="Search..."
              onSubmit={onSearchApply}
              defaultValue={pageData?.filters?.search}
            />
            <TabButton
              callBack={onHandleTab}
              loading={pageData?.filterLoading}
              // active={pageData?.filters?.filters?.supply_channel_type}
              list={[
                {
                  id: "",
                  name: "Arbitbox",
                  count: pageData?.meta?.total,
                },
                {
                  id: "",
                  name: "Crawlbox",
                  count: pageData?.meta?.total,
                },
              ]}
              allTab={true}
              allCount={pageData?.meta?.total}
            />
          </div>
        )}

        <ArbitProductPage.Content>
          <div className="overflow-x-auto max-w-[100%]">
            <DynamicTable
              view={TableView}
              data={TableView(
                pageData?.supplyChannelListings,
                handleSavePrice,
                handleSaveQty,
                handleConnectProduct,
                handledisConnectProduct,
                actionList.connect
              )}
              actions={actions}
              onSelect={onSelectRow}
              selectedRow={selectedRow}
              meta={pageData?.meta}
              appending={loading}
              loadMoreData={loadMoreData}
              loading={pageData.loading}
              style={{
                table: {
                  style: { width: "100%", minWidth: "100%" },
                },
                header: {
                  className: "justify-center !px-[16px] ",
                  "tracking id": { className: "!pr-[30px]" },
                  channel: { className: "!justify-start" },
                  quantity: { className: "!justify-end" },
                  notes: { className: "!py-[0px] !hidden" },
                  price: { className: "!justify-end" },
                  image: { className: "hidden" },
                  type: { className: "!py-[0px] hidden" },
                },
                row: {
                  className:
                    " !items-center !justify-center flex !px-3 !py-2 text-[12px]  ",
                  // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                  // name: {
                  //   className: " min-w-max !w-fit !max-w-fit",
                  //   },
                  product: {
                    className: "!justify-center !min-w-[400px] w-[100%]",
                    td: "w-[100%]",
                  },
                  _actions: { className: "!py-[0px]" },
                },
              }}
            />
          </div>
        </ArbitProductPage.Content>

        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {modalVisibility && (
        <MethodForm
          setCurrentModal={(item) => {
            setCurrentModal(item);
            setModalVisibility(false);
          }}
          onClose={() => {
            setModalVisibility(false);
            setCurrentModal(null);
          }}
        />
      )}

      {currentModal === "scratchForm" && (
        <CreateFromScratch
          data={formData}
          show={modalVisibility}
          product_id={product_id}
          //    params={params}
          onClose={() => {
            // onPaginationApply();
            setFormData(null);
            // setModalVisibility(true);
            setCurrentModal(null);
          }}
          onAdd={onSubmitApply}
        />
      )}
      {currentModal === "productForm" && (
        <CreateFromProduct
          data={formData}
          show={modalVisibility}
          product_id={product_id}
          //    params={params}
          onClose={() => {
            // onPaginationApply();
            setFormData(null);
            // setModalVisibility(true);
            setCurrentModal(null);
          }}
          onAdd={onCreateFromProduct}
        />
      )}

      {connectModal && (
        <ConnectForm
          data={formData}
          show={connectModal || showSupplyModal}
          onClose={() => {
            setFormData(null);
            setConnectModal(false);
            setShowSupplyModal(false);
          }}
          product_id={product_id}
          defaultListings={formData}
          updateRows={(item) => {
            callEndpoint({
              url: `supply-channel-listing/${item}?includes=product.brand`,
              method: "GET",
            }).then((rs) => {
              dispatch(
                updateRow({
                  path: `supplyChannelListings.[id:${item}]`,
                  value: rs.data,
                })
              );
              dispatch(
                updateRow({
                  path: `supplyChannelListings.[id:${item}]._updatedRow`,
                  value: true,
                })
              );
            });
          }}
          onSubmit={onConnect}
          callBack={() => {
            onPaginationApply();
            updateListingsCount("supply-channel-listing", "supply");
          }}
        />
      )}
      {showSupplyModal && (
        <ProductConnectChannelModal
          data={formData}
          show={connectModal || showSupplyModal}
          setModalVisibility={setModalVisibility}
          setShowSalesModal={setShowSupplyModal}
          onClose={() => {
            setFormData(null);
            setConnectModal(false);
            setShowSupplyModal(false);
          }}
          product_id={product_id}
          defaultListings={formData}
          updateRows={(item) => {
            callEndpoint({
              url: `supply-channel-listing/${item}?includes=product.brand`,
              method: "GET",
            }).then((rs) => {
              dispatch(
                updateRow({
                  path: `supplyChannelListings.[id:${item}]`,
                  value: rs.data,
                })
              );
              dispatch(
                updateRow({
                  path: `supplyChannelListings.[id:${item}]._updatedRow`,
                  value: true,
                })
              );
            });
          }}
          onSubmit={onConnect}
          callBack={() => {
            onPaginationApply();
            updateListingsCount("supply-channel-listing", "supply");
          }}
        />
      )}

      {currentModal === "importForm" && (
        <Import
          setImportModel={setImportModel}
          id={null}
          cancel={() => {
            setCurrentModal(null);
          }}
          show={importModel}
          handleSubmit={handleImport}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

SupplyChannels.propTypes = {
  product_id: PropTypes.string,
  displayTitle: PropTypes.string,
  displayTabGroups: PropTypes.string,
  hideFilter: PropTypes.bool,
  showCreateForm: PropTypes.bool,
  showSupplyModal: PropTypes.bool,
  setShowSupplyModal: PropTypes.func,
  updateListingsCount: PropTypes.func,
};

export default SupplyChannels;
