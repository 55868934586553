import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAndSetLocale} from "./../../store/locale";

import {fetchLanguages} from "../../store/language";


const ChangeLanguage = () => {
    const dispatch = useDispatch()
    const languageData = useSelector(state => state.languages.list) || []
    const localeData = useSelector(state => state.locale)

    const handleChangeLocale = useCallback(async (event) => {
        const selectedLocale = event.target.value;
        dispatch(fetchAndSetLocale(selectedLocale));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchLanguages());
    }, []);

    return (
        <div className="w-full flex flex-row gap-1  items-center">
            {languageData && languageData.length > 0 &&
                <>
                    <div className="w-auto flex flex-row gap-2 items-center">
                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                             aria-hidden={"true"} width={"1.3em"} height={"1.3em"}>
                            <path
                                d="M7.75 2.75a.75.75 0 00-1.5 0v1.258a32.987 32.987 0 00-3.599.278.75.75 0 10.198 1.487A31.545 31.545 0 018.7 5.545 19.381 19.381 0 017 9.56a19.418 19.418 0 01-1.002-2.05.75.75 0 00-1.384.577 20.935 20.935 0 001.492 2.91 19.613 19.613 0 01-3.828 4.154.75.75 0 10.945 1.164A21.116 21.116 0 007 12.331c.095.132.192.262.29.391a.75.75 0 001.194-.91c-.204-.266-.4-.538-.59-.815a20.888 20.888 0 002.333-5.332c.31.031.618.068.924.108a.75.75 0 00.198-1.487 32.832 32.832 0 00-3.599-.278V2.75z"/>
                            <path clipRule="evenodd" fillRule="evenodd"
                                  d="M13 8a.75.75 0 01.671.415l4.25 8.5a.75.75 0 11-1.342.67L15.787 16h-5.573l-.793 1.585a.75.75 0 11-1.342-.67l4.25-8.5A.75.75 0 0113 8zm2.037 6.5L13 10.427 10.964 14.5h4.073z"/>
                        </svg>
                        <div className="w-auto">Language:</div>
                    </div>
                    <select
                        defaultValue={localeData.selectedLocale}
                        onChange={handleChangeLocale}
                        className="w-full bg-right transition hover:bg-gray-100 py-2 pl-2 cursor-pointer rounded text-sm bg-white text-gray-900 p-0 font-medium focus:outline-0 focus:ring-0 focus:text-blue-700 border-0 appearance-none"
                    >
                        {languageData.map(({name, shortName}) => (
                            <option key={shortName} value={shortName}>
                                {name}
                            </option>
                        ))}
                    </select>

                </>
            }
        </div>
    );
}
export default ChangeLanguage;