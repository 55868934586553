import { createSlice } from "@reduxjs/toolkit";
import {
  authorizeStore,
  authorizeStoreCallBack,
  bulkDeleteAccounts,
  deleteAccount,
  fetchAccounts,
  fetchAccountsFilters,
  restoreAccount,
  syncWithMarketplace,
  updateAccountsStatus,
} from "./thunk";

const initialState = {
  accounts: [],
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  filters: {
    status_filter: [],
    created_filter: "",
    updated_filter: "",
    sku_counts_filter: [],
  },
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchAccount: (state, action) => {
      return { ...state, channels: action.payload };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    getMetaFilter: (state, action) => {
      return { ...state, filtersMeta: action.payload };
    },
    // update accounts statuses when given a list of ids
    updateAccountsStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.accounts = state.accounts.map((account) => {
        if (ids.includes(account.id)) {
          return { ...account, status };
        }
        return account;
      });
    },
    // remove accounts when given a list of ids
    removeAccounts: (state, action) => {
      const ids = action.payload;
      state.accounts = state.accounts.filter((account) => {
        return !ids.includes(account.id);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.accounts = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchAccountsFilters.fulfilled, (state, action) => {
        // state.filtersMeta = action.payload?.data
        state.filtersMeta = action.payload?.data;
      });
  },
});

export const {
  fetchChannel,
  updateFilter,
  extraReducers,
  getMetaFilter,
  updateAccountsStatuses,
  removeAccounts,
  updateBoundFilter,
} = accountsSlice.actions;

export {
  fetchAccounts,
  fetchAccountsFilters,
  updateAccountsStatus,
  deleteAccount,
  restoreAccount,
  bulkDeleteAccounts,
  syncWithMarketplace,
  authorizeStore,
  authorizeStoreCallBack,
};

export default accountsSlice.reducer;
