import {toast} from "react-toastify";

const notifier = (rs) => {
    const typeMap = {
        success: "success",
        error: "error",
        warning: "warning",
        info: "info",
        null: "info",
        validation: "error",
        redirect: "info",
        unauthorized: "error",
        not_found: "warning",
    };

    let message = rs?.message || rs?.type?.toUpperCase() + "!"; //|| rs?.data?.message
    const response = rs?.data?.length ? rs?.data : rs;
    let type = typeMap[response?.type] || "info";

    if (response) {
        if (response.errors || response.type === "error") {
            type = "error";
            const errorList = response?.errors ? Object.values(response?.errors) : [];
            let innerError = [];
            if (errorList?.length) {
                innerError = errorList?.map((item) => {
                    return <li className={"opacity-50"}>{item}</li>;
                });
            }
            message = (
                <>
                    <b>{response?.message}</b>
                    {errorList?.length ? (
                        <ul className={"list-disc list-inside my-[5px]"}>{innerError}</ul>
                    ) : null}
                </>
            );
        }
        if (response?.type === "success" || rs?.data?.success) type = "success";
    }

    if (response?.notify !== false) {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
};

export default notifier;
