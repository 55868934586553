/**
 * @author Austin Ames
 */
import classNames from "classnames";
import Item from "./items";

const Group = ({children, border, displayTabGroups = 'flex'}) => {
    return (
        <div
            className={
                classNames(
                    border && "border-b border-gray-200 dark:border-gray-700",
                    "w-full"
                )
            }
        >
            <ul
                className={`${displayTabGroups} flex flex-wrap gap-4 text-sm font-medium text-center`}
                id="myTab"
                data-tabs-toggle="#myTabContent"
                role="tablist"
            >
                {children}
            </ul>
        </div>
    )
}

Group.defaultProps = {
    border: false
}

const ArbitTab = {Group, Item}

export default ArbitTab;
