import React from "react";
import HandmadeSkeleton from "../../utils/skeleton";


export default function UploadImageSkeleton() {
    return (
        <div className="mt-16 ml-5 flex  justify-between">
            <div>
                <HandmadeSkeleton width={80}/>
                <div className="ml-5 flex gap-5">
                    <HandmadeSkeleton width={100} height={100} borderRadius={20} className="rounded-full"/>
                    <HandmadeSkeleton width={250} height={100} borderRadius={20} className="rounded-full"/>
                </div>
            </div>
            <div className="flex gap-3 mr-5">
                <HandmadeSkeleton width={100} height={30}/>
                <HandmadeSkeleton width={250} height={30}/>
            </div>
        </div>
    );
}