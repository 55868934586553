import moment from "moment";

const formView = (formDefaultValue) => {
    return ([

        {
            name: "purchase_view",
            label: "Purchase",
            type: "Module",
            module: PreviewData,
            defaultValue: formDefaultValue?.purchase || null,
            reset: false,
            clear: false,
            className: "col-span-2",
        },
        {
            name: "warehouse_id",
            type: "Hidden",
            defaultValue: formDefaultValue.warehouse_id
        },
        {
            name: "id",
            type: "Hidden",
            defaultValue: formDefaultValue?.purchase_id
        },
        {
            name: "quantity",
            label: "Quantity",
            type: "Number",
            placeholder: "Enter quantity",
            defaultValue: +formDefaultValue?.quantity || null,
            className: "col-span-2 pr-[50%] mr-[8px]",
        },
        {
            name: "delivery_date",
            label: "Delivery Date",
            type: "DateHour",
            placeholder: "Enter delivery date",
            defaultValue: formDefaultValue?.delivery_date || null,
        },

        {
            name: "expiry_date",
            label: "Expiry Date",
            type: "Date",
            placeholder: "Enter expiry date",
            defaultValue: formDefaultValue?.expiry_date || null,
        },
        {
            name: "note",
            label: "Note",
            type: "Textarea",
            rows: "3",
            placeholder: "Enter note...",
            defaultValue: formDefaultValue?.note || null,
            className: "col-span-2",
        },


    ])
}
export default formView


const PreviewData = ({item, binded}) => {
    //const text = priceHandler(binded?.account_id?.data?.availableBalance, binded?.account_id?.data?.currency)
    const purchase = item?.defaultValue
    return (
        <div
            className={"bg-gray-100 min-h-full px-[8px] py-[8px] items-center  border border-gray-200 rounded-md flex flex-row gap-[16px]"}>
            <div className={"w-[56px] max-h-[64px] justify-center items-center "}>
                <img src={purchase?.product?.image} className={"w-[96px]"}/>

            </div>
            <div className={"flex flex-col gap-[4px]"}>
                <div className={"text-gray-400 flex-row gap-[4px] flex"}>
                    <p className={"text-gray-900"}>#{purchase?.purchase_no}</p>|
                    <p className={"text-gray-900"}>{purchase?.product?.title}</p>|
                    <p className={"text-gray-900"}>Qty: {+purchase?.quantity}</p>
                </div>
                <div className={"text-gray-400 flex-row gap-[4px] flex text-[14px]"}>
                    Created by:
                    <p className={"text-gray-400"}>{purchase?.created_by?.name}</p> @
                    <p className={"text-gray-400"}>{moment(purchase?.created_at).format("DD.MM.YYYY HH:mm")}</p>
                </div>

            </div>
        </div>)
}