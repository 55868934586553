const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Name",
                value: "name",
                onClick: (item) => {
                    callback("name");
                },
            },
            {
                label: "Created By",
                value: "created_by",
                onClick: (item) => {
                    callback("created_by");
                },
            },
        ],
    };
};

export default sortView;
