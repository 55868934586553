/**
 * @author Austin
 */
import classNames from "classnames";

const ArbitToggle = ({status, bgColor, handleFulfilment}) => {

    return (
        <div
            className={classNames(
                status ? " justify-end " : " justify-start",
                `w-[36px] h-[20px] flex items-center rounded-[40px] px-[2px] cursor-pointer ${bgColor}`
            )}
            onClick={() => {
                // setToggle(!toggle);
                handleFulfilment();
            }}
        >
            <div className="w-[16px] h-[16px] rounded-[40px] bg-white"></div>
        </div>
    );
};

ArbitToggle.defaultProps = {
    status: true,
    bgColor: "orange",
};

export default ArbitToggle;
