import * as Yup from "yup";
import upperCaseString from "../../../../../dynamic-page/util/upperCaseString";
import React from "react";

const formView = (data) => {
  if (true) {
    return [
      {
        name: "name",
        label: "Name",
        placeholder: "Enter name",
        type: "Input",
        defaultValue: data?.name || "",
        validation: Yup.string().required("Name is required"),
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        reset: false,
        clear: false,
      },
      {
        name: "company",
        label: "Company",
        placeholder: "Enter Company name",
        type: "Input",
        defaultValue: data?.company || "",
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        //validation: Yup.string().required('Company is required'),
        reset: false,
        clear: false,
      },
      {
        name: "street1",
        label: "Street 1",
        placeholder: "Enter Street 1",
        type: "Input",
        defaultValue: data?.street1 || "",
        validation: Yup.string().required("street is required"),
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        reset: false,
        clear: false,
      },
      {
        name: "street2",
        label: "Street 2",
        placeholder: "Enter Street 2",
        type: "Input",
        defaultValue: data?.street2 || "",
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        reset: false,
        clear: false,
      },
      {
        name: "city",
        label: "City",
        placeholder: "Enter city name",
        type: "Input",
        defaultValue: data?.city || "",
        validation: Yup.string().required("City is required"),
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        reset: false,
        clear: false,
      },
      {
        name: "state",
        label: "State ", // +  data?.state,//(data?.state ? typeof data?.state === "object" ? data?.state?.id : data?.state : ''),
        placeholder: "Select state",
        type: "Select",
        api: {
          url: "/api/v1/state?filter[country_id]={country}",
          query: "&term={query}",
          view: "/api/v1/state",
          optionValue: (item) => ({
            value: item?.id,
            label: item?.name,
          }),
        },
        defaultValue: data?.state || "", //4853,//1734,//data?.state || '',
        validation: Yup.string().required("State is required"),
        style: {
          control: {
            minHeight: "auto !important",
            background: "rgb(249 250 251)",
            height: "48px",
            fontSize: "16px",
            paddingLeft: "6px",
          },
          menu: {
            whiteSpace: "wrap !important",
          },
        },
        reset: false,
        clear: false,
      },
      {
        name: "zip_postal_code",
        label: "ZIP / Postal Code",
        placeholder: "Enter zip code",
        type: "Input",
        defaultValue: data?.zip_postal_code || data?.zip || "",
        validation: Yup.string().required("Zip code is required"),
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        reset: false,
        clear: false,
      },
      {
        name: "country",
        label: "Country", //+ (data?.country ? typeof data?.country === "object" ? data?.country?.id : data?.country : ''),
        placeholder: "Select country",
        type: "Select",
        api: {
          url: "/api/v1/country",
          query: "?filter[name]={query}",
          optionValue: (item) => ({
            value: item?.id,
            label: item?.name,
            flag: item?.flag,
          }),
        },
        features: {
          formatOptionLabel: ({ label, flag }) => (
            <div
              className={"flex flex-row gap-[12px] items-center min-h-[25px] "}
            >
              {flag && (
                <span
                  className={
                    "w-[28px] h-[20px] overflow-hidden flex flex-col justify-center items-center  "
                  }
                >
                  <img
                    src={flag.toLowerCase()}
                    className={"object-cover w-[28px] h-[20px] rounded-[2px]"}
                  />
                </span>
              )}
              <span>{upperCaseString(label)}</span>
            </div>
          ),
        },
        style: {
          control: {
            minHeight: "auto !important",
            background: "rgb(249 250 251)",
            height: "48px",
            fontSize: "16px",
            paddingLeft: "6px",
          },
          menu: {
            whiteSpace: "wrap !important",
          },
        },
        defaultValue: data?.country || "",
        validation: Yup.string().required("country is required"),
        reset: false,
        clear: false,
      },
      {
        name: "phone",
        label: "Phone",
        defaultValue: data?.phone || "",
        placeholder: "Enter phone number",
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        reset: false,
        clear: false,
      },
      {
        label: "E-mail",
        name: "email",
        defaultValue: data?.email || "",
        placeholder: "Enter email address",
        innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
        reset: false,
        clear: false,
      },
    ];
  } else {
    return [];
  }
};

export default formView;
