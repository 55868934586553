// DropDownMenu.js
// -----------------------------------------

// Import General libraries
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

// Main Component: DropDownMenu
const PopupUp = forwardRef(
  (
    {
      toggle,
      children,
      action,
      forcePosition,
      className,
      innerClassName,
      outerClassName,
      leftSpace,
      rightSpace,
      topSpace,
      bottomSpace,
      toggleClassName,
      delay = 0,
      close,
    },
    ref
  ) => {
    // Initial State to handle menu open and close.
    const [isOpen, setIsOpen] = useState(false);
    //const [innerChildren, setInnerChildren] = useState(children);

    useImperativeHandle(ref, () => ({
      close: () => {
        setIsOpen(false);
      },
    }));

    const modalRef = useRef(null);

    useEffect(() => {
      // Function to handle clicks outside of the modal
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          // Click is outside of the modal, close it
          setIsOpen(false); // Call the provided function to close the modal
        }
      };

      // Add event listener when component mounts
      document.addEventListener("mousedown", handleClickOutside);

      // Remove event listener when component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen]); // Make sure to include onClose in the dependencies

    // Initial Ref to handle menu position.
    const toggleButtonRef = useRef();
    const menuRef = useRef();
    const arrowRef = useRef();

    let timeoutId;

    const handleHide = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (
          !toggleButtonRef.current.contains(event.target) &&
          event.target.tagName !== "BODY"
        ) {
          document.removeEventListener("click", handleHide);
          setIsOpen(false);
        }
      }
    };
    const handleShow = () => {
      if (!isOpen) document.addEventListener("click", handleHide);
      setIsOpen(!isOpen);

      //setInnerChildren(children);
    };
    const handleMouseLeave = () => {
      if (action === "hover") {
        setIsOpen(false);
        clearTimeout(timeoutId);
      }
    };

    const handleMouseEnter = () => {
      if (action === "hover") {
        timeoutId = setTimeout(() => {
          setIsOpen(true);
          clearTimeout(timeoutId);
        }, delay);
      }
    };

    // Handle menu position when menu is open.
    useEffect(() => {
      if (isOpen) {
        // set children

        // Get position of toggle button and menu.
        const toggleButtonRect =
          toggleButtonRef.current.getBoundingClientRect();
        const menuRect = menuRef.current.getBoundingClientRect();
        // Get viewport width and height.
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Get button size.
        const buttonSize = {
          w: toggleButtonRect.width,
          h: toggleButtonRect.height,
        };

        // Get available space and required space.
        const availableSpace = {
          top: topSpace || toggleButtonRect.top,
          bottom: bottomSpace || viewportHeight - toggleButtonRect.bottom,
          left: leftSpace || toggleButtonRect.left,
          right: rightSpace || viewportWidth - toggleButtonRect.right,
        };

        // Get required space.
        const requiredSpace = {
          width: menuRect.width / 2,
          height: menuRect.height,
        };

        const xCenter = 0 - requiredSpace.width + buttonSize.w / 2;
        let xShift = 0; //shiftLeft ? shiftLeft : shiftRight ? shiftRight * -1 : 0
        let yShift = 0; //shiftTop ? shiftTop : shiftBottom ? shiftBottom * -1 : 0
        // Set position of menu.
        let position = {
          top: 4 + buttonSize.h,
          left: 0,
        };

        // Check if menu is out of viewport
        if (
          availableSpace.right < requiredSpace.width &&
          availableSpace.left >= requiredSpace.width
        ) {
          xShift = requiredSpace.width - availableSpace.right;
        }
        if (
          availableSpace.left < requiredSpace.width &&
          availableSpace.right >= requiredSpace.width
        ) {
          xShift = 0 - (requiredSpace.width - availableSpace.left);
        }
        if (
          forcePosition === "top" ||
          (availableSpace.bottom < requiredSpace.height + 90 &&
            availableSpace.top >= requiredSpace.height)
        ) {
          yShift = 0 - (requiredSpace.height + buttonSize.h + 4);
          menuRef.current.classList.add("flex-col-reverse");
          if (arrowRef.current) {
            arrowRef.current.style.transform = "rotate(180deg)";
          }
        }

        // set position of menu.
        menuRef.current.style.top = `${position.top + yShift}px`;
        menuRef.current.style.left = `${xCenter + position.left - xShift}px`;
        if (arrowRef.current) {
          arrowRef.current.style.left = `${xShift}px`;
        }
      }
    }, [isOpen, forcePosition]);

    const handleContextMenuOnNestedElement = (event) => {
      event.stopPropagation();
    };

    // Render DropDownMenu
    return (
      <div
        ref={modalRef}
        onContextMenu={handleContextMenuOnNestedElement}
        className={`relative ${
          outerClassName ? outerClassName : ""
        }  popOver-${isOpen}`}
      >
        {/* Render Toggle Button from props and send isOpen state to handle style. */}
        <div
          onClick={handleShow}
          ref={toggleButtonRef}
          className={`cursor-pointer ${toggleClassName}`}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          {toggle(isOpen || false)}
        </div>

        {/* Render Menu from itemMenu. */}

        <div
          ref={menuRef}
          className={`popOverWindow   ${
            !isOpen && "hidden"
          } transition absolute w-max ${
            action === "hover" ? "z-[99999]" : "z-[999]"
          } flex flex-col text-left rounded-[4px] l w-content overflow-hidden ${className} `}
          // style={{filter: ['drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))', 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05))'].join(' ')}}
        >
          <div
            className={`flex justify-center  text-white relative w-full h-[8px]`}
            ref={arrowRef}
          >
            <div className={"h-[8px] w-[20px] absolute top-0"}>
              <svg
                className={"relative"}
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 8"
                style={{ enableBackground: "new 0 0 18 8" }}
              >
                <path d="m9.7.7 7 7c.2.2.4.3.7.3H.6c.3 0 .5-.1.7-.3l7-7c.4-.4 1-.4 1.4 0z" />
              </svg>
            </div>
          </div>
          <div
            className={`whitespace-pre-wrap shadow-md bg-white p-[8px] rounded-md overflow-hidden ${innerClassName}`}
          >
            {isOpen && children}
          </div>
        </div>
      </div>
    );
  }
);

export default PopupUp;
