import axios from "axios";
import FormModal from "../../dynamic-page/FormModal";
import callEndpoint from "../../dynamic-page/util/callEndpoint";
import filterObjectByKeys from "../../dynamic-page/util/filterObjectByKeys";
import productComponentFormView from "./connectForm";
import util from "../../dynamic-page/util";
import constant from "../../../constant";

const actionList = {
  detail: {
    label: "Product Detail",
    onClick: ({ data }) => {
      const id = data?.id ? data?.id : data[0]?.id;
      window.open(`/product/${id}`, "_blank");
    },
    bulkDisable: true,
  },

  add: {
    label: "Add Product",
    onClick: ({ forms, data }) => {
      forms?.["add"].open(data);
    },
    condition: false,
  },

  edit: {
    label: "Edit Product",
    onClick: ({ forms, actions, data }) => {
      forms?.["add"].open(data?.id ? data : data[0]);
    },
    bulkDisable: true,
  },

  saveAndOpen: {
    label: "Save And Open Product",
    onClick: ({ data, callBack, clear, close, refreshPage }) => {
      const innerData = prepareGroupedItem(data);
      callEndpoint({
        title: "Save Product: " + data?.title,
        url: `product`,
        method: "POST",
        data: innerData,
      }).then((res) => {
        if (res?.type === "success") {
          callBack();
          refreshPage();
        }
        // callBack();
      });
    },
    condition: false,
  },
  save: {
    label: "Save Product",
    onClick: ({ data, callBack, clear, close, refreshPage }) => {
      const products =
        data?.type === 2
          ? data.products.map((item) => {
              if (item?.new)
                return filterObjectByKeys(item, ["id:product_id", "quantity"]);
            })
          : null;
      let innerData = {};
      innerData =
        data?.type === 2 ? { ...data, products: products } : { ...data };
      if (innerData?.category_id === null) {
        delete innerData?.category_id;
      }
      callEndpoint({
        title: "Save Product: " + data?.title,
        url: `product/${data?.id}`,
        method: "POST",
        data: innerData,
      }).then((res) => {
        refreshPage();
        close();
        callBack();
      });
    },
    condition: false,
  },
  duplicate: {
    label: "Duplicate",
    onClick: ({ data, refreshPage, setSelectedRow }) => {
      let products = [];
      let title = "";
      if (!data.id && data.length > 0) {
        const ids = data.map((item) => {
          return item.id;
        });
        products = {
          products: ids,
        };
        title = "Bulk";
      } else {
        products = {
          products: [data?.id],
        };
        title = data?.title;
      }
      callEndpoint({
        title: "Duplicate Product: " + title,
        url: `product/duplicate`,
        method: "POST",
        data: products,
      }).then((res) => {
        setSelectedRow([]);
        refreshPage();
      });
    },
  },
  delete: {
    label: "Delete",
    onClick: ({
      data,
      refreshPage,
      extra,
      setConfirmationModalVisibility,
      setSelectedRow,
    }) => {
      setConfirmationModalVisibility({
        infoText: "Are you sure you want to delete the selected product/s?",
        confirmText: "Delete",
        callBack: (setSubmit) => {
          if (!data.id && data.length > 0) {
            const ids = data.map((item) => {
              return item.id;
            });
            const products = {
              products: ids,
            };
            callEndpoint({
              title: "Delete Product: " + "Bulk",
              url: ids?.length > 1 ? `product/bulk-delete` : `product/${ids[0]}`,
              method: ids?.length > 1 ? "POST" : "DELETE",
              data: ids?.length > 1 ? products : null,
            }).then((res) => {
              setSubmit(false);
              if (res?.type === "success") {
                setConfirmationModalVisibility(null);
                setSelectedRow([]);
                refreshPage();
              }
            });
          } else {
            callEndpoint({
              title: "Delete Product: " + data?.title,
              url: extra?.product?.id
                ? `product/${extra?.product?.id}/group/${data?.id}`
                : `product/${data?.id}`,

              method: "DELETE",
              data: data.id,
            }).then((res) => {
              setSubmit(false);
              if (res?.type === "success") {
                setConfirmationModalVisibility(null);
                setSelectedRow([]);
                refreshPage();
              }
            });
          }
        },
      });
    },
  },
  connect: {
    onClick: ({
      forms,
      data,
      setVisibleExternalModal,
      extra,
      refreshPage,
      ...rest
    }) => {
      const onSubmitApply = async (data, close) => {
        const existing = extra?.product?.groups.map((item) => {
          return {
            product_id: item.id,
            quantity: item.quantity,
          };
        });
        const updatedComponents = [...existing, data];

        const formData = new FormData();

        formData.append("type", 2);

        if (updatedComponents) {
          updatedComponents.forEach((obj, index) => {
            for (let key in obj) {
              formData.append(`products[${index}][${key}]`, obj[key]);
            }
          });
        }
        const response = await axios.post(
          constant.APIURL.UPDATE_PRODUCT + extra?.product?.id,
          formData,
          { withCredentials: true }
        );
        util.notifier(response?.data);
        if (response?.data?.type === "success") {
          refreshPage();
          extra?.setComponents(updatedComponents);
          close();
        }
      };
      forms?.["connect"].open(
        data,
        <FormModal
          title={"Products"}
          formView={productComponentFormView}
          data={[]}
          onClose={() => {
            setVisibleExternalModal(null);
          }}
          onSubmit={onSubmitApply}
        />
      );
    },
    condition: false,
  },
};

const prepareGroupedItem = (data) => {
  const formData = new FormData();

  const products =
    data?.type === 2
      ? data.products.map((item) => {
          return filterObjectByKeys(item, ["id:product_id", "quantity"]);
        })
      : null;

  if (products) {
    products.forEach((obj, index) => {
      for (let key in obj) {
        formData.append(`${key}_${index}`, obj[key]);
      }
    });
  }

  let innerData = {};
  innerData = data?.type === 2 ? { ...data, products: products } : { ...data };
  if (innerData?.category_id === null) {
    delete innerData?.category_id;
  }
  if (innerData?.products === null) {
    delete innerData?.products;
  }

  return innerData;
};

export default actionList;
