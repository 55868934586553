import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchShippingPrices = createAsyncThunk(
    "get:api/v1/ShippingPrice",
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingPrice(payload);
        return response;
    }
);

const createShippingPrice = createAsyncThunk(
    "post:api/v1/ShippingPrice",
    async (payload, thunkAPI) => {
        const response = await api.createShippingPrice(payload);
        return response;
    }
);

const updateShippingPrice = createAsyncThunk(
    "put:api/v1/ShippingPrice",
    async ({id, payload}) => {
        const response = await api.updateShippingPrice(id, payload);
        return response;
    }
);

const deleteShippingPrice = createAsyncThunk(
    "delete:api/v1/ShippingPrice",
    async (payload, thunkAPI) => {
        const response = await api.deleteShippingPrice(payload);
        return response;
    }
);

const searchShippingPrices = createAsyncThunk(
    "get:api/v1/ShippingPrice/search",
    async (payload, thunkAPI) => {
        const response = await api.searchShippingPrice(payload);
        return response.data;
    }
);

const fetchShippingPricesFilters = createAsyncThunk(
    "get:api/v1/ShippingPrice/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingPricesFilters(payload);
        return response;
    }
);

const bulkDeleteShippingPrices = createAsyncThunk(
    "delete:api/v1/ShippingPrice/delete/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteShippingPrices(payload);
        return response;
    }
);

const bulkStatusUpdateShippingPrices = createAsyncThunk(
    "put:api/v1/ShippingPrice/status/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkStatusUpdateShippingPrices(payload);
        return response;
    }
);

const restoreShippingPrices = createAsyncThunk(
    "post:api/v1/ShippingPrice/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreShippingPrices(payload);
        return response;
    }
);


const duplicateShippingPrice = createAsyncThunk(
    "post:api/v1/shipping_template/duplicate/",
    async (payload, thunkAPI) => {
        const response = await api.duplicateShippingPrice(payload);
        return response;
    }
);


export {
    fetchShippingPrices,
    createShippingPrice,
    updateShippingPrice,
    deleteShippingPrice,
    searchShippingPrices,
    fetchShippingPricesFilters,
    bulkDeleteShippingPrices,
    bulkStatusUpdateShippingPrices,
    restoreShippingPrices,
    duplicateShippingPrice
};
