import ArbitButton from "../sub-elements/button";
import MainModal from "../dynamic-page/MainModal";
// import 'react-Select.js/dist/react-Select.js.css'
// import 'react-virtualized/styles.css'
// import 'react-virtualized-Select.js/styles.css'
import upperCaseString from "../dynamic-page/util/upperCaseString";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../middleware/axios";
import constants from "../../constant";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import priceHandler from "../../utils/priceHandler";
import quantityHandler from "../../utils/quantityHandler";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SupplyTable from "./components/supplyTable";
import FrontendFooterPagination from "../dynamic-page/elements/FrontendFooterPagination";
import PurchaseSelectItems from "./components/purhcaseSelectItems";

const NewSupply = ({
  show,
  setShow,
  user,
  updateItem,
  supplies,
  setSupplies,
  selectedSupply,
  actions,
  supplyList,
  onClose,
  setUpdateItem,
  onFulfill,
  product,
  type,
  onUpdate,
}) => {
  
  const [sourceType, setSourceType] = useState("Create From Product");

  const productsData = useSelector((state) => state.productReducer.products);

  const validationSchema = Yup.object({
    // supplier_id: Yup.mixed().required("Required"),
    // // if the payment is completed, then the bank account is required
    // bank_account_id: Yup.mixed().required("Required"),
  });

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [submit, setSubmit] = useState(false);
  const [mainSubmit, setMainSubmit] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const fetchSources = async (params) => {
    let url = "api/v1/channel";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };



  const mapOptionsToValues = (options) => {
    return options?.map((option) => ({
      value: option?.id,
      label: option?.name || option?.display_name,
      image: option?.image,
      code: option?.code,
      title: option?.name,
      product: option?.id,
      position: option?.position,
      symbol: option?.symbol,
      logo: option?.logo,
    }));
  };

  const sourcesOptions = async (inputValue) => {
    let url = inputValue ? "?term=" + inputValue : "";
    return new Promise((resolve) => {
      fetchSources(url)
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const fetchCurrencies = async (params) => {
    let url = "api/v1/currencies";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const [countries, setCountries] = useState([]);

  const currenciesOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchCurrencies("?term={query}" + inputValue + "&filter[status]=1")
        .then((response) => {
          setCountries(response.data);
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const mapProductsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: `#${option?.id}  |  ` + (option?.title ? option?.title : ""),
      description:
        "APID:  " +
        option?.apid +
        " | " +
        "Created At:  " +
        moment(option?.request_date_time).format("DD-MM-YYYY"),
      image: option?.image,
      assign: option?.assign?.name,
      profile: option?.assign?.profile_image,
    }));
  };

  const mapRequestsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label:
        `#${option?.id}  |  ` +
        (option?.product?.title ? option?.product?.title : "") +
        `  |   QTY: ${quantityHandler(option?.qty)}`,
      description:
        "Created By:  " +
        option?.requester?.name +
        " | " +
        "Created At:  " +
        moment(option?.request_date_time).format("DD-MM-YYYY"),
      qty: parseInt(priceHandler(option?.qty)),
      title: option?.product?.title,
      image: option?.product?.image,
      assign: option?.product?.assign?.name,
      profile: option?.product?.assign?.profile_image,
    }));
  };

  const [purchaseOptions, setPurchaseOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    async function fetchPurchases() {
      await axios
        .get(constants.APIURL.GET_SUPPLY_REQUESTS, { withCredentials: true })
        .then((response) => {
          // setPurchaseOptions(response?.data?.data)
          const validOptions = response?.data?.data?.filter(
            (option) => option?.is_writable === true
          );
          const options = mapRequestsOptionsToValues(validOptions);
          setPurchaseOptions(options);
        });
    }

    fetchPurchases();
  }, []);

  useEffect(() => {
    async function fetchProducts() {
      await axios
        .get(constants.APIURL.GET_PRODUCT + "?filter[type]=1", {
          withCredentials: true,
        })
        .then((response) => {
          // setProductOptions(response?.data?.data)
          const options = mapProductsOptionsToValues(response?.data?.data);
          setProductOptions(options);
        });
    }

    fetchProducts();
  }, []);

  const [purchase, setPurchase] = useState("");

  const fetchPurchases = async (params) => {
    let url = constants.APIURL.GET_SUPPLY_REQUESTS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const loadPurchasesOptions = async (inputValue) => {
    let url;

    url = "/search?term=" + inputValue + "&not_deleted=1";

    return new Promise((resolve) => {
      setTimeout(() => {
        fetchPurchases(url)
          .then((response) => {
            const options = mapRequestsOptionsToValues(response.data);
            resolve(options);
          })
          .catch((error) => {});
      }, 1000);
    });
  };

  const [productOption, setProductOption] = useState("");

  const fetchProducts = async (params) => {
    let url = constants.APIURL.GET_PRODUCT + "/search?term=";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  useEffect(() => {
    if (type === "Edit") {
      if (selectedSupply[0]?.supply_request) {
        setSourceType("Create From Supply Request");
        setPurchase({
          value: selectedSupply[0]?.supply_request?.id,
          label:
            `#${selectedSupply[0]?.supply_request?.id}  |  ` +
            (selectedSupply[0]?.supply_request?.product?.title
              ? selectedSupply[0]?.supply_request?.product?.title
              : "") +
            `|  QTY: ${quantityHandler(
              selectedSupply[0]?.supply_request?.qty
            )}`,
          description:
            "Created By:  " +
            selectedSupply[0]?.supply_request?.requester?.name +
            " | " +
            "Created At:  " +
            moment(selectedSupply[0]?.supply_request?.request_date_time).format(
              "DD-MM-YYYY"
            ),
        });
      } else {
        setProductOption({
          value: selectedSupply[0]?.product?.id,
          label:
            `#${selectedSupply[0]?.product?.id}  |  ` +
            (selectedSupply[0]?.product?.title
              ? selectedSupply[0]?.product?.title
              : ""),
          description:
            "APID:  " +
            selectedSupply[0]?.product?.apid +
            " | " +
            "Created At:  " +
            moment(selectedSupply[0]?.product?.created_at).format("DD-MM-YYYY"),
        });
      }
    }
  }, []);

  const fetchBankAccounts = async (params) => {
    let url = constants.APIURL.GET_BANK_ACCOUNTS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const mapBankAccountsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: upperCaseString(option?.bankName) + " | " + option?.accountNumber,
      currency: {
        value: option?.currency?.id,
        label: option?.currency?.name,
        symbol: option?.currency?.symbol,
        position: option?.currency?.position,
      },
    }));
  };

  const bankAccountsOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchBankAccounts("/search?term=" + inputValue + "&not_deleted=true")
        .then((response) => {
          const options = mapBankAccountsOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };
  const formik = useFormik({
    initialValues:
      type === "Edit"
        ? {
            supplier_id: selectedSupply[0]?.supplier_id?.value,
            bank_account_id: selectedSupply[0]?.bank_account_id?.value,
            products: products,
          }
        : {
            supplier_id: "",
            bank_account_id: "",
            products: [],
          },

    validationSchema,
    onSubmit: (values) => {
      let productsData = products.map((item) => {
        // delete title and return the value for currency id
        return {
          id: item?.product_id,
          quantity: item?.quantity,
          note: item?.comment,
          purchase_price: item?.purchase_price,
          currency_id: item?.currency_id?.value,
          estimate_delivery_date: item?.estimate_delivery_date,
        };
      });
      let payload = {};

      const payload2 = supplies?.map((item) => {
        payload = {
          id: item?.id,
          supplier_id: item?.supplier?.id || item?.supplier_id?.value,
          quantity: Number(item?.qty),
          purchase_price: item?.price,
          currency_id:
            item?.max_acceptable_price_currency?.id ||
            item?.max_acceptable_price_currency?.value,
        };
        return payload;
      });

      const obj = {
        supply_requests: payload2,
      };

      payload = {
        supplier_id: values?.supplier_id?.value,
        // is_paid : values?.is_paid,
        bank_account_id: values?.bank_account_id?.value,
        supply_requests: productsData,
        status: 1,
        ...(selectedSupply[0] && { supply_request_id: selectedSupply[0]?.id }),
      };

      // if there.s selected supply passed remove the add product button and send the payload directly from here with out appending to the table
      if (selectedSupply[0] || product) {
        setSubmit(true);
        // validate the selected product
        

        payload = {
          ...payload,
          supply_requests: [
            {
              id: selectedProduct?.product_id,
              quantity: selectedProduct?.quantity,
              note: selectedProduct?.comment,
              purchase_price: selectedProduct?.purchase_price,
              currency_id: selectedProduct?.currency_id?.value,
              estimate_delivery_date: selectedProduct?.estimate_delivery_date,
            },
          ],
        };
      }

      if (productsData.length === 0 && !selectedSupply[0] && !product) {
        toast.error("Please add at least one product");
        return;
      }

      if (type === "Edit") {
        if (selectedSupply[0]?.supply_request) {
          onFulfill(payload);
        } else {
          onUpdate(selectedSupply[0]?.id, payload);
        }
      } else {
        setMainSubmit(true);
        onFulfill(obj, () => setMainSubmit(false));
      }
      // setSupplyList([...supplyList, values])
      if (addNew) {
        formik.resetForm();
        setProducts([]);
        setProductOption("");
        setSelectedProduct({
          product_id: "",
          quantity: "",
          comment: "",
          purchase_price: "",
          currency_id: "",
          estimate_delivery_date: "",
        });
      }
      if (!addNew) {
        // onClose()
      }
    },
  });

  // if there is a selected bank account id set the currency to the selected bank account currency
  useEffect(() => {
    if (formik.values.bank_account_id) {
      let selectedBankAccountCurrenct = formik.values.bank_account_id?.currency;
      setSelectedProduct({
        ...selectedProduct,
        currency_id: selectedBankAccountCurrenct,
      });
    }
  }, [formik.values.bank_account_id]);

  useEffect(() => {
    if (selectedSupply?.length > 0) {
      const curreny_id = {
        label: selectedSupply[0]?.max_acceptable_price_currency?.name,
        title: selectedSupply[0]?.max_acceptable_price_currency?.name,
        value: selectedSupply[0]?.max_acceptable_price_currency?.id,
        code: selectedSupply[0]?.max_acceptable_price_currency?.code,
      };
      const purchase_price = selectedSupply[0]?.max_acceptable_price
        ? selectedSupply[0]?.max_acceptable_price[
            selectedSupply[0]?.max_acceptable_price_currency?.code
          ]
        : null;
      setProductOption({
        value: selectedSupply[0]?.product?.id,
        label:
          `#${selectedSupply[0]?.product?.id}  |  ` +
          (selectedSupply[0]?.product?.title
            ? selectedSupply[0]?.product?.title
            : ""),
        description:
          "APID:  " +
          selectedSupply[0]?.product?.apid +
          " | " +
          "Created At:  " +
          moment(selectedSupply[0]?.product?.created_at).format("DD-MM-YYYY"),
      });
      setSelectedProduct({
        product_id: selectedSupply[0]?.id,
        currency_id: curreny_id,
        title: selectedSupply[0]?.product?.title,
        purchase_price: purchase_price,
        image: selectedSupply[0]?.product?.image,
        quantity: Number(quantityHandler(selectedSupply[0]?.qty)),
        assign: selectedSupply[0]?.product?.assign?.name,
        profile: selectedSupply[0]?.product?.assign?.profile_image,
      });
    }
    if (product) {
      setSelectedProduct({
        product_id: product?.id,
        title: product?.title,
        image: product?.image,
        purchase_price: product?.purchase_price,
        assign: product?.assign?.name,
        profile: product?.assign?.profile_image,
      });
      setProductOption({
        value: product?.id,
        label: `#${product?.id}  |  ` + (product?.title ? product?.title : ""),
        description:
          "APID:  " +
          product?.apid +
          " | " +
          "Created At:  " +
          moment(product?.created_at).format("DD-MM-YYYY"),
      });
    }
  }, [selectedSupply && selectedSupply[0], product]);

  useEffect(() => {
    if (purchase && type === "Add") {
      formik.setFieldValue("amount", purchase?.qty || "");
    }
    if (selectedSupply && selectedSupply[0]?.requester) {
      formik.setFieldValue(
        "amount",
        +priceHandler(selectedSupply[0]?.qty) || ""
      );
    }
  }, [purchase, selectedSupply && selectedSupply[0]]);

  const defaultProducts = useMemo(() => {
    return mapProductsOptionsToValues(productsData);
  }, [productsData]);

  const [isAddClicked, setAddClicked] = useState(false);

  const changeQuantity = (situation) => {
    if (situation === "minus" && selectedProduct?.quantity === 1) {
    } else {
      setSelectedProduct({
        ...selectedProduct,
        quantity:
          situation === "minus"
            ? Number(selectedProduct?.quantity) - 1
            : Number(selectedProduct?.quantity) + 1,
      });
    }
  };

  // for frontend pagination 507 to 514
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const [currentPosts,setCurrentPosts] =useState(selectedSupply?.slice(indexOfFirstPost, indexOfLastPost));
  let currentPosts = supplies?.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [clickedId, setClickedId] = useState(null);

  const [dateFilter, setDateFilter] = useState("low");
  const filterByDate = () => {
    if (dateFilter === "low") {
      setSupplies((prevVal) =>
        prevVal.sort(
          (a, b) =>
            new Date(a?.request_date_time) - new Date(b?.request_date_time)
        )
      );
    } else {
      setSupplies((prevVal) =>
        prevVal.sort(
          (a, b) =>
            new Date(b?.request_date_time) - new Date(a?.request_date_time)
        )
      );
    }
  };

  const [channelFilter, setChannelFilter] = useState("low");
  const filterByChannel = () => {
    if (channelFilter === "low") {
      setSupplies((prevVal) =>
        prevVal.sort(
          (a, b) =>
            (a?.supplier?.name > b?.supplier?.name) -
            (a?.supplier?.name < b?.supplier?.name)
        )
      );
    } else {
      setSupplies((prevVal) =>
        prevVal.sort(
          (a, b) =>
            (b?.supplier?.name > a?.supplier?.name) -
            (b?.supplier?.name < a?.supplier?.name)
        )
      );
    }
  };

  const [productFilter, setProductFilter] = useState("low");
  const filterByProduct = () => {
    if (productFilter === "low") {
      setSupplies((prevVal) =>
        prevVal.sort(
          (a, b) =>
            (a?.product?.title > b?.product?.title) -
            (a?.product?.title < b?.product?.title)
        )
      );
    } else {
      setSupplies((prevVal) =>
        prevVal.sort(
          (a, b) =>
            (b?.product?.title > a?.product?.title) -
            (b?.product?.title < a?.product?.title)
        )
      );
    }
  };

  const [quantityFilter, setQuantityFilter] = useState("low");
  const filterByQuantity = () => {
    if (quantityFilter === "low") {
      setSupplies((prevVal) => prevVal.sort((a, b) => a?.qty - b?.qty));
    } else {
      setSupplies((prevVal) => prevVal.sort((a, b) => b?.qty - a?.qty));
    }
  };

  const [priceFilter, setPriceFilter] = useState("low");

  const filterByPrice = () => {
    if (priceFilter === "low") {
      setSupplies((prevVal) =>
        prevVal.sort((a, b) => {
          const aPrice =
            a?.max_acceptable_price?.[a?.max_acceptable_price_currency?.code] ??
            0;
          const bPrice =
            b?.max_acceptable_price?.[b?.max_acceptable_price_currency?.code] ??
            0;
          return aPrice - bPrice;
        })
      );
    } else {
      setSupplies((prevVal) =>
        prevVal.sort((a, b) => {
          const aPrice =
            a?.max_acceptable_price?.[a?.max_acceptable_price_currency?.code] ??
            0;
          const bPrice =
            b?.max_acceptable_price?.[b?.max_acceptable_price_currency?.code] ??
            0;
          return bPrice - aPrice;
        })
      );
    }
  };

  return (
    <MainModal
      item={{
        title: type === "Edit" ? "Edit Supply Purchase" : "New Supply Purchase",
        submit: formik.handleSubmit,
        cancel: onClose,
        view: (item) => (
          <form onSubmit={formik.handleSubmit}>
            <div
              className={` ${
                clickedId !== null
                  ? currentPosts?.length > 7
                    ? " h-[600px] overflow-y-scroll sticky "
                    : "h-[600px]"
                  : "h-[600px] "
              } `}
            >
              <SupplyTable
                formik={formik}
                filterByQuantity={filterByQuantity}
                filterByPrice={filterByPrice}
                setPriceFilter={setPriceFilter}
                quantityFilter={quantityFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                filterByDate={filterByDate}
                filterByChannel={filterByChannel}
                channelFilter={channelFilter}
                setChannelFilter={setChannelFilter}
                setQuantityFilter={setQuantityFilter}
                productFilter={productFilter}
                setProductFilter={setProductFilter}
                priceFilter={priceFilter}
                filterByProduct={filterByProduct}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                changeQuantity={changeQuantity}
                setUpdateItem={setSupplies}
                sourcesOptions={sourcesOptions}
                submit={submit}
                currenciesOptions={currenciesOptions}
                selectedSupply={currentPosts}
                actions={actions}
                setAddClicked={setAddClicked}
                isAddClicked={isAddClicked}
                clickedId={clickedId}
                originalItems={updateItem}
                updateItem={currentPosts}
                setClickedId={setClickedId}
              />
            </div>
            {supplies?.length !== 0 && (
              <div className="flex justify-between items-center py-3   w-full  px-5 sticky bottom-0 bg-white">
                <div>
                  <FrontendFooterPagination
                    postsPerPage={postsPerPage}
                    totalPosts={selectedSupply?.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
                <div className="flex justify-end items-center">
                  <div className="mx-1">
                    <ArbitButton
                      width={"120px"}
                      bg_color="white"
                      border_color="blue"
                      text_color="blue-600"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </ArbitButton>
                  </div>
                  <div className="mx-1">
                    <ArbitButton
                      width={"120px"}
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      Create
                    </ArbitButton>
                  </div>
                </div>
              </div>
            )}
          </form>
        ),
      }}
      className="min-w-[1100px]"
      containerClassName=" !px-0"
      option={
        <div
          className="flex items-center gap-1.5 !ml-5 !pl-4 cursor-pointer hover:text-blue-500 text-blue-700"
          onClick={() => {
            setSelectedProduct({
              product_id: "",
              currency_id: "",
              title: "",
              purchase_price: "",
              image: "",
              quantity: 1,
              assign: "",
              profile: "",
            });
            setAddClicked(!isAddClicked);
            setClickedId(null);
          }}
        >
          {/* <FaPlus className="w-2.5 h-2.5" />
          <span className="text-[14px] leading-[21px] font-medium">
            Add Product
          </span> */}
        </div>
      }
      optionClassName="gap-8"
      mainClassName="z-[500]"
    />
  );
};

export default NewSupply;
