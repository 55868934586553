import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';

function PopupUp({
                     toggle,
                     children,
                     action,
                     className,
                     innerClassName,
                     outerClassName,
                     toggleClassName,
                     delay = 0,
                 }) {

    const [isOpen, setIsOpen] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [tooltipStyles, setTooltipStyles] = useState({});
    const toggleButtonRef = useRef();
    const menuRef = useRef();
    const arrowRef = useRef();
    const menuContainerRef = useRef(document.createElement('div'));
    let timeoutId;

    useEffect(() => {
        document.body.appendChild(menuContainerRef.current);

        return () => {
            document.body.removeChild(menuContainerRef.current);
        };
    }, []);

    const handleHide = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            if (!toggleButtonRef.current.contains(event.target) && event.target.tagName !== "BODY") {
                document.removeEventListener('click', handleHide);
                removeMouseMoveListener();
                setIsOpen(false);
            }
        }
    };

    const handleShow = (event) => {
        event.stopPropagation();
        if (!isOpen) {
            const mouseX = event.clientX;
            const mouseY = event.clientY;

            document.addEventListener("click", handleHide);
            window.addEventListener('mousemove', handleMouseMove);

            const tooltipWidth = menuRef.current ? menuRef.current.getBoundingClientRect().width : 0;
            const position = {
                top: mouseY + 10,
                left: mouseX - tooltipWidth / 2,
            };

            setMousePosition({ x: mouseX, y: mouseY });
            setTooltipStyles({
                top: `${position.top}px`,
                left: `${position.left}px`,
                position: 'fixed',
                visibility: 'hidden' // Hide initially to let it appear correctly
            });

            setTimeout(() => {
                setTooltipStyles(prevStyles => ({
                    ...prevStyles,
                    visibility: 'visible'
                }));
            }, 0);

            setIsOpen(true);
        } else {
            document.removeEventListener("click", handleHide);
            removeMouseMoveListener();
            setIsOpen(false);
        }
    };

    const removeMouseMoveListener = () => {
        window.removeEventListener('mousemove', handleMouseMove);
    };

    const handleMouseLeave = () => {
        if (action === "hover") {
            setIsOpen(false);
            clearTimeout(timeoutId);
            removeMouseMoveListener();
        }
    };

    const handleMouseEnter = () => {
        if (action === "hover") {
            timeoutId = setTimeout(() => {
                setIsOpen(true);
                window.addEventListener('mousemove', handleMouseMove);
                clearTimeout(timeoutId);
            }, delay);
        }
    };

    const handleMouseMove = (event) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        const tooltipWidth = menuRef.current ? menuRef.current.getBoundingClientRect().width : 0;
        const position = {
            top: mouseY + 10,
            left: mouseX - tooltipWidth / 2,
        };

        setMousePosition({ x: mouseX, y: mouseY });
        setTooltipStyles({ 
            top: `${position.top}px`, 
            left: `${position.left}px`, 
            position: 'fixed' 
        });

        if (arrowRef.current) {
            arrowRef.current.style.left = `${mousePosition.x - 10}px`;
            arrowRef.current.style.transform = 'rotate(180deg)';
        }
    };

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('mousemove', handleMouseMove);
            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [isOpen]);

    const handleContextMenuOnNestedElement = (event) => {
        event.stopPropagation();
    };

    const renderMenu = (
        <div
            ref={menuRef}
            className={`popOverWindow ${!isOpen && "hidden"} transition w-max ${action === "hover" ? "z-[99999]" : "z-[999]"} flex flex-col text-left rounded-[4px] l w-content overflow-hidden ${className}`}
            style={tooltipStyles}>
            <div className={`flex justify-center text-white relative w-full h-[8px]`} ref={arrowRef}>
                <div className={"h-[8px] w-[20px] absolute top-0"}>
                    <svg className={"relative"} fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 18 8" style={{enableBackground: "new 0 0 18 8"}}>
                        <path d="m9.7.7 7 7c.2.2.4.3.7.3H.6c.3 0 .5-.1 .7-.3l7-7c.4-.4 1-.4 1.4 0z"/>
                    </svg>
                </div>
            </div>
            <div className={`whitespace-pre-wrap bg-white p-[8px] rounded-md overflow-hidden ${innerClassName}`}>
                {isOpen && children}
            </div>
        </div>
    );

    const portalMenu = ReactDOM.createPortal(renderMenu, menuContainerRef.current);

    return (
        <div onContextMenu={handleContextMenuOnNestedElement}
             className={`relative ${outerClassName ? outerClassName : ""} popOver-${isOpen}`}>
            <div onClick={handleShow} ref={toggleButtonRef} className={`cursor-pointer ${toggleClassName}`}
                 onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
                {toggle(isOpen || false)}
            </div>

            {portalMenu}
        </div>
    );
}

export default PopupUp;
