import upperCaseString from "../../dynamic-page/util/upperCaseString";
import React from "react";

const FiltersView = (selectedFilers) => {

    return (
        [
            {
                name: "channel",
                label: "Channel",
                placeholder: "Filter by Channel",
                type: "Select",
                api: {
                    url: "/api/v1/channel",
                    query: "?term={query}",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: item?.name,
                        logo: item?.logo
                    })
                },
                features: {
                    isMulti: true,
                    formatOptionLabel: ({label, logo}) => (
                        <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                                <span
                                    className={"w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "}>
                                    <img src={logo || "/assets/images/img-placehoder.png"}/>
                                </span>
                            <span>{upperCaseString(label)}</span>
                        </div>)
                },
                defaultValue: selectedFilers?.channel,
                toggle: true,
                clear: true,
            },
            {
                name: "created_at",
                label: "Create Date",
                placeholder: "Filter by Create Date",
                type: "DateRange",
                defaultValue: selectedFilers?.created_at || null,
                toggle: true,
                clear: true,
            },
            {
                name: "updated_at",
                label: "Last Update",
                placeholder: "Filter by Last Update",
                type: "DateRange",
                defaultValue: selectedFilers?.updated_at || null,
                toggle: true,
                clear: true,
            },
            {
                name: "sku",
                label: "SKU",
                placeholder: "Filter by SKU",
                type: "Input",
                defaultValue: selectedFilers?.sku || null,
                toggle: true,
                clear: true,
            },
        ]
    )
}

export default FiltersView;