/**
 * @author Austin Ames
 * @description Badge - a badge with key and value
 */

import { useState } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import ArbitEditableDropdown from "./editable-dropDown";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Util from "../dynamic-page/util";
import Icons from "../orders/icons";

const ArbitBadge = ({
  title,
  value,
  bgColor,
  editable,
  secondValue,
  onChange,
  options,
  loadOptions,
  placeholder,
  creatable,
  tooltip,
  copy,
}) => {
  const [isEditable, setEditable] = useState(false);
  let defaultOptions;
  if (options) {
    defaultOptions = options.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }

  const onEditable = () => {
    if (editable) setEditable((state) => !state);
  };

  return (
    <div
      className={classNames(
        bgColor && "bg-" + bgColor,
        "flex flex-col rounded-[8px] gap-0.5 w-full h-[79px] px-[16px] py-[8px] space-y-[4px] min-w-[200px]  "
      )}
    >
      <div className="flex gap-1  items-center justify-between ">
        <p className="text-[16px] font-bold text-gray-600 flex-[1_0_0]  whitespace-nowrap">
          {title}
        </p>
        {tooltip && (
          <Util.popUp
            leftSpace={500}
            toggle={() => <Icons.info className="w-5 h-5 text-gray-300 hover:text-gray-500" />}
            action={"hover"}
            forcePosition={tooltip?.position}
          >
            <div className="flex flex-col gap-1.5  w-[240px] p-2 ">
              <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                {tooltip?.title}
              </span>
              <span className="text-[12px] leading-[15px] text-gray-500 font-normal">
                {tooltip?.desc}
              </span>
            </div>
          </Util.popUp>
        )}
      </div>
      <div
        className={`flex space-x-[8px]     items-center w-full ${
          !editable && "overflow-hidden"
        }`}
      >
        <ArbitEditableDropdown
          creatable={creatable}
          secondValue={secondValue}
          fontSize={"20px"}
          fontWeight={"500"}
          copy={copy}
          fontColor={"gray-800"}
          bgColor={bgColor}
          value={value}
          options={defaultOptions}
          loadOptions={loadOptions}
          placeholder={placeholder}
          showIcon={editable}
          onChange={(value) => onChange(value)}
          width="full"
        />
      </div>
    </div>
  );
};

ArbitBadge.propTypes = {
  editable: PropType.bool,
};

ArbitBadge.defaultProps = {
  title: "Arbit Badge",
  value: "",
  bgColor: "gray-50",
  editable: false,
  onChange: () => {},
};

export default ArbitBadge;
