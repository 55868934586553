/**
 * @author Austin Ames
 */
import { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Item from "./item";
import { Transition } from "react-transition-group";

const Title = ({
  title,
  active,
  children,
  position,
  xposition,
  icon,
  path,
  disabled,
}) => {
  const wrapperRef = useRef(null);
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const dropRef = useRef(null);

  // convert the title to lowercase and remove spaces
  const titlee = title?.toString().toLowerCase().replace(/\s/g, "");

  const defaultStyle = {
    transition: `opacity 200ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisibleDropdown((state) => !state);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const onHandleDrodown = (e) => {
    e.stopPropagation();
    setVisibleDropdown(false);
  };

  return (
    <div className="relative">
      <div
        ref={dropRef}
        className={classNames(
          active ? "text-purple-600" : " hover:text-gray-800",
          `${
            path === titlee ? "text-blue-600 cursor-default " : "text-gray-400"
          }}`,
          "text-[16px] font-medium cursor-pointer leading-6",
          "z-[99]",
          "flex items-center",
          "  transition-all duration-200  rounded-lg "
        )}
        onClick={() => {
          if (!disabled) {
            setVisibleDropdown((state) => !state);
          }
        }}
      >
        {icon && <span className="mr-1">{icon}</span>}
        {title}
      </div>
      {/* { */}
      {/* // (visibleDropdown && Array.from(children).length >= 0) && */}
      <Transition
        in={visibleDropdown && Array.from(children).length >= 0}
        timeout={200}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {(state) => (
          <div
            ref={wrapperRef}
            className={`absolute w-max ${
              position === "top" ? "-bottom-full" : "top-[30px]"
            } ${
              xposition ? "right-[0]" : ""
            }  z-[999] flex flex-col text-center max-h-[70vh]  rounded-[6px] shadow overflow-y-auto  bg-white w-content `}
            onClick={(e) => onHandleDrodown(e)}
            onMouseLeave={() => setVisibleDropdown(false)}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </div>
  );
};

Title.defaultProps = {
  title: "Dropdown",
};

const ArbitTextDropdown = { Title, Item };

export default ArbitTextDropdown;
