import { createSlice } from "@reduxjs/toolkit";
import {
  getAllPickupPoints,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  pickupPoints: [],
  selectedPickupPoint: null,
  deleteId: null,
  loading: "idle",
  page: "list",
  status: null,
  error: null,
  filters: {},
  filtersMeta: {},
  filterLoading: "idle",
  boundFilters: {},
};

export const pickupPointSlice = createSlice({
  name: "pickupPoints",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      if (filters) {
        Object.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    updateFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    pickupPointUpdate: (state, action) => {
      state.pickupPoints = action;
    },
    fetchLoading: (state) => {
      state.loading = "pending";
    },
    updateTransferCost: (state, action) => {
      const findIndex = state.pickupPoints.findIndex(
        (item) => item?.id === action.payload.id
      );
      state.pickupPoints[findIndex].transfer_cost = action.payload.cost;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPickupPoints.fulfilled, (state, action) => {
        state.pickupPoints = action.payload?.append
          ? [...state.pickupPoints, ...action.payload?.data]
          : action.payload?.data;
        state.status = action.payload?.status;
        state.meta = action.payload?.meta;
        state.loading = "idle";
      })
      .addCase(getAllPickupPoints.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.error.message;
        state.pickupPoints = [];
      })
  },
});

export const {
  updateFilters,
  pickupPointUpdate,
  updateRow,
  updateBoundFilter,
  fetchLoading,
  updateTransferCost,
} = pickupPointSlice.actions;

export {
    getAllPickupPoints,
};

export default pickupPointSlice.reducer;