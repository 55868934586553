const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Status",
                value: "status",
                onClick: (item) => {
                    callback("status");
                },
            },
            // fulfillment_type , change_date , purchase_date
            {
                label: "Fulfillment Type",
                value: "fulfillment_type",
                onClick: (item) => {
                    callback("fulfillment_type");
                },
            },
            {
                label: "Change Date",
                value: "change_date",
                onClick: (item) => {
                    callback("change_date");
                },
            },
            {
                label: "Purchase Date",
                value: "purchase_date",
                onClick: (item) => {
                    callback("purchase_date");
                },
            },
        ],
    };
};

export default sortView;
