const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Title",
                value: "title",
                onClick: (item) => {
                    callback("title");
                }
            },
            {
                label: "Created At",
                value: "created_at",
                onClick: (item) => {
                    callback("created_at");
                }
            },
            {
                label: "Warehouse Inventory",
                value: "inventory_count",
                onClick: (item) => {
                    callback("inventory_count");
                }
            },
            {
                label: "Avg. Cost ($)",
                value: "cost",
                onClick: (item) => {
                    callback("cost");
                }
            },
            {
                label: "Latest Supply Cost",
                value: "last_purchase_cost",
                onClick: (item) => {
                    callback("last_purchase_cost");
                }
            },
            {
                label: "FBA Weight",
                value: "fba_weight",
                onClick: (item) => {
                    callback("fba_weight");
                }
            },
            {
                label: "FBM Weight",
                value: "fbm_weight",
                onClick: (item) => {
                    callback("fbm_weight");
                }
            },

        ]


    }
}

export default sortView