import callEndpoint from "../../dynamic-page/util/callEndpoint";

const actionList = {
    add: {
        label: "Add Product",
        onClick: ({forms, data}) => {
            forms?.["add"].open(data);
        },
        condition: false,
    },

    edit: {
        label: "Edit",
        onClick: ({forms, actions, data}) => {
            forms?.["add"].open(data);
        },
        bulkDisable: true,
    },

    activate: {
        label: "Activate",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to activate the selected category/s?",
                confirmText: "Yes, Activate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Activate Categories",
                        method: "post",
                        url: `category/status/bulk-action`,
                        data: {
                            status: 1,
                            categories: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === "inactive";
        },
    },

    deactivate: {
        label: "Deactivate",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to deactivate the selected category/s?",
                confirmText: "Yes, Deactivate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Deactivate Categories",
                        method: "post",
                        url: `category/status/bulk-action`,
                        data: {
                            status: 0,
                            categories: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === "active";
        },
    },

    delete: {
        label: "Delete",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to delete the selected category/s?",
                confirmText: "Yes, Delete",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Delete Categories",
                        method: "delete",
                        url: `category/delete/bulk-action`,
                        data: {
                            categories: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status !== "deleted";
        },
    },

    restore: {
        label: "Restore",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to restore the selected category/s?",
                confirmText: "Yes, Restore",
                callBack: (setSubmit) => {
                    const ids = data?.id;
                    callEndpoint({
                        title: "Restore Categories",
                        method: "post",
                        url: `category/${ids}/restore`,
                        data: {
                            categories: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === "deleted";
        },
        bulkDisable: true,
    },

    save: {
        label: "Save",
        onClick: ({data, refreshPage, close, callBack, user}) => {
            data.created_by = user?.id;
            if (!data?.parent_id) {
                delete data?.parent_id;
            }
            callEndpoint({
                title: "Save Channel",
                method: "post",
                url: data?.id ? `category/${data?.id}` : `category`,
                data: data,
            }).then((response) => {
                callBack()
                if (response?.type === "success") {
                    close();
                    refreshPage();
                }
            });
        },
        condition: false,
    },
};

export default actionList;
  