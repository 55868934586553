import React, {useState} from "react";
import Icons from "../../orders/icons";

export default function CopyText2({text, iconClass, hint}) {
    const handleCopy = () => {
        // Copy text to clipboard
        navigator.clipboard.writeText(text);

        // Show tooltip
        setShowTooltip(true);

        // Hide tooltip after 1 second
        setTimeout(() => {
            setShowTooltip(false);
        }, 500);
    };

    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <div className="relative inline-block ">
      <span
          onClick={() => {
              handleCopy();
          }}
          className="text-[14px] leading-[21px] font-medium text-gray-300 cursor-pointer hover:text-blue-600"
      >
        <Icons.fileCopy className="w-5 h-5 text-gray-300 hover:text-blue-400 transition "/>
      </span>
            {showTooltip && hint && (
                <div
                    className="absolute top-full z-[50] mt-2 px-3 py-1.5 bg-gradient-to-r from-blue-500 to-blue-700 text-white text-xs rounded shadow-lg">
                    Copied!
                </div>
            )}
        </div>
    );
}
