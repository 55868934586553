import React from "react";
import UI from "../../dynamic-form/elements";
import Icons from "../../orders/icons";


const OptionsView = [
    {
        action: "add",
        view: (param) => (
            <UI.Button
                item={{
                    // label: `+   New ${title.slice(0, -1)}`,
                    buttonType: "primary",
                    className: "order-[0] !h-[37px] !px-3 !py-2 !rounded-[8px] !flex !items-center !justify-center !gap-[8px]",
                }}
                onClick={() => {
                    param?.callBack(param)
                }}
            >
                <Icons.plus className="h-3 w-3"/>
                New Currency
            </UI.Button>

        ),
    }

]

export default OptionsView;