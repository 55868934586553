import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";
import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import store from "../../../store";
import {addNotification, deleteAllNotifications, updateNotificationData,} from "../../../store/notifications/index";

const Notifier = () => {
    const dispatch = useDispatch();
    const notificationList = useSelector((state) => state.notification.list);

    useEffect(() => {
        dispatch(deleteAllNotifications());
        // window.Echo.channel("jobs").listen(".job.completed", (data) => {
        //   //data.action_id;
        //   socketNotification(24, data);
        // });
        // window.Echo.channel("jobs").listen(".job.queued", (data) => {
        //   //data.action_id;
        //   socketNotification(24, data);
        // });
        // window.Echo.channel("jobs").listen(".job.processing", (data) => {
        //   //data.action_id;
        //   socketNotification(24, data);
        // });
    }, []);

    const toastConfig = (notif) => ({
        progress: notif.toast.autoClose ? false : notif.toast.progress,
        draggable: false,
        pauseOnHover: true,
        closeButton: true,
        style: {
            width: "fit-content",
        },
        progressStyle: {
            background:
                notif.status === "success"
                    ? "#55BB38"
                    : notif.status === "error"
                        ? "#D65644"
                        : notif.status === "warning"
                            ? "#EAC645"
                            : notif.status === "info"
                                ? "#5296D5"
                                : "#1C64F2",
        },
        autoClose: !notif.toast.autoClose ? false : notif.toast.autoClose,
    });
    useEffect(() => {
        notificationList?.forEach((notif) => {
            const errorList = notif?.toast?.errors
                ? Object.values(notif?.toast?.errors).map((val) => <li>{val}</li>)
                : "";
            const successList = notif?.toast?.successes?.map((val) => <li>{val}</li>);
            if (!toast.isActive(notif.toastId)) {
                if (!notif.toastId) {
                    //+ notif.id
                    const newToastId = toast[notif.status](
                        <>
                            <div className={"font-semibold"}>{notif.toast.title}</div>
                            {notif.toast.message && <div>{notif.toast.message}</div>}

                        </>,
                        toastConfig(notif)
                    );
                    dispatch(
                        updateNotificationData({
                            path: `list.[id:${notif.id}].toastId`,
                            value: newToastId,
                        })
                    );
                }
            } else {
                toast.update(notif.toastId, {
                    ...notif.toast,
                    type: notif.status,
                    isLoading: notif.status === "loading",
                    autoClose: notif.status === "loading" ? 1000 : 3000,
                    progress: notif.status !== "loading" ? 0 : notif.toast.progress,
                    style: {
                        width: "fit-content",
                    },
                    progressStyle: {
                        background:
                            notif.status === "success"
                                ? "#55BB38"
                                : notif.status === "error"
                                    ? "#D65644"
                                    : notif.status === "warning"
                                        ? "#EAC645"
                                        : notif.status === "info"
                                            ? "#5296D5"
                                            : "#1C64F2",
                    },
                    render: () => (
                        <>
                            <div className={"font-semibold"}>{notif.toast.title}</div>
                            <div>{notif.toast.message}</div>
                            <div>{errorList}</div>
                            {successList && (
                                <div>
                                    {successList}
                                </div>
                            )}

                        </>
                    ),
                });
            }
        });
    }, [notificationList]);

    return <div className={"hidden"}>{notificationList?.length}</div>;
};

export default Notifier;

export const startNotification = (notification) => {
    const internalUuid = uuidv4();
    const createdNotification = {
        toast: {
            progress: 0,
            ...notification,
            autoClose: false,
        },
        id: internalUuid,
        toastId: null,
        status: "loading",
        notify: notification?.notify !== false,
        actionId: null,
    };
    store.dispatch(addNotification(createdNotification));
    return internalUuid;
};

export const updateNotification = (id, toastContent) => {
    let data = store.getState().notification.list.find((item) => item.id === id);
    const newData = {
        ...data,
        toast: {...data.toast, ...toastContent.toast},
        status: "loading",
        toastId: toast.isActive(data.toastId) ? data.toastId : null,
    };
    store.dispatch(
        updateNotificationData({path: `list.[id:${id}]`, value: newData})
    );
};

export const closeNotification = (id, toastContent) => {
    let data = store.getState().notification.list.find((item) => item.id === id);
    const newData = {
        ...data,
        toast: {...data.toast, ...toastContent.toast},
        message: toastContent.message,
        title: toastContent.title,
        status: toastContent.toast.type || "info",
        toastId: toast.isActive(data.toastId) ? data.toastId : null,
        autoClose: 5000,
        pauseOnHover: true,
    };
    store.dispatch(
        updateNotificationData({path: `list.[id:${id}]`, value: newData})
    );
};

export const socketNotification = (actionId, jobContent) => {
    let data = store
        .getState()
        .notification.list.find((item) => item.actionId === actionId);

    const newData = {
        ...data,
        toast: {
            ...data?.toast,
            progress: 0.5, //jobContent.progress.total / jobContent.progress.processed,
            title: jobContent.title,
            message:
                jobContent.message +
                (jobContent.progress.failed ? (
                    <div className={"px-[8px] py-[4px] bg-red-100 text-red-600"}>
                        {" "}
                        {jobContent.progress.failed} item(s) failed
                    </div>
                ) : (
                    ""
                )),
            autoClose: jobContent?.finished ? 3000 : false,
        },
        status: "loading",
        toastId: data?.toastId ? data?.toastId : null,
    };
    updateNotification(data?.id, newData);
    //store.dispatch(updateNotificationData({path: `list.[actionId:${actionId}]`, value: newData}));
};
