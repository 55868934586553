import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
import formView from "./modals-view/form";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActionList from "../dynamic-page/util/getActionList";

import {
  bulkStatusUpdateTargetSellerFolder as toggleStatus,
  createTargetSellerFolder as createRow,
  deleteTargetSellerFolder as deleteRow,
  fetchTargetSellerListings as fetchData,
  fetchTargetSellerListingsFilters as fetchFilters,
  restoreTargetSellerFolder as restoreRow,
  updateBoundFilter,
  updateFilter,
  updateTargetSellerFolder as updateRow,
} from "../../store/target-seller-listings";

import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import FormModal from "../dynamic-page/FormModal";
import hasValue from "../dynamic-form/util/hasValue";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import { useLocation } from "react-router-dom";

const TargetSellers = () => {
  const dispatch = useDispatch();
  const location = useLocation()

  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      filters: { status: 1 },
      sort: null,
      page: 1,
      limit: 10,
      search: "",
      includes:
        "salesChannel,marketplace,create_user,update_user,shipmentCosts,currency",
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term",true);

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.targetSellerListings);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  // Handle form modal.
  const [formData, setFormData] = useState(null);

  // Handle order details modal.
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleNotifyUser = (rs) => {
    util.notifier({ type: rs?.type, message: rs?.message, errors: rs?.errors });
  };

  const onSubmitApply = async (data, close) => {
    const { id, ...payload } = data;
    if (!id) {
      await dispatch(createRow(payload))
        .unwrap()
        .then((res) => {
          handleNotifyUser(res);
          if (res?.type === "success") {
            window.location.href = `/growth/target_seller_folders/${res?.data?.id}/sellers`;
            setFormData(null);
            setModalVisibility(false);
            onPaginationApply();
          }
        });
    } else {
      await dispatch(updateRow({ id: id, payload }))
        .unwrap()
        .then((res) => {
          handleNotifyUser(res);
          if (res?.type === "success") {
            setFormData(null);
            setModalVisibility(false);
            onPaginationApply();
          }
        });
    }
  };

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  // Initial action list
  const actionList = {
    edit: {
      label: "Edit",
      onClick: (item) => {
        if (item?.id) {
          setFormData(item);
          setModalVisibility(true);
        } else {
          setFormData(selectedRow[0]);
          setModalVisibility(true);
        }
      },
      bulkDisable: true,
    },
    inactive: {
      label: "Inactive",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText:
            "Are you sure you want to deactivate the selected folder/s?",
          confirmText: "Yes, Deactivate",
          callBack: (setSubmit) => {
            onStatusToggleInactive([item?.id], setSubmit);
          },
        });
      },
    },
    active: {
      label: "Active",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to activate the selected folder/s?",
          confirmText: "Yes, Activate",
          callBack: (setSubmit) => {
            onStatusToggleActive([item?.id], setSubmit);
          },
        });
      },
    },
    delete: {
      label: <span className={"text-red-600"}>Delete</span>,
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to delete the selected folder/s?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            onDeleteApply(item?.id, setSubmit);
          },
        });
      },
    },
    restore: {
      label: "Restore",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to restore the selected folder/s?",
          confirmText: "Yes, Restore",
          callBack: (setSubmit) => {
            onRestoreApply([item?.id], setSubmit);
          },
        });
      },
    },
  };

  const onDeleteApply = async (item, setSubmit) => {
    await dispatch(
      deleteRow({
        target_seller_folders: hasValue(item)
          ? [item]
          : util.getIdList(selectedRow),
      })
    )
      .unwrap()
      .then((rs) => {
        handleNotifyUser(rs);
        setSubmit(false);
        if (rs?.type === "success") {
          setConfirmationModalVisibility(null);
          onPaginationApply();
        }
      });
  };
  const onRestoreApply = async (item, setSubmit) => {
    await dispatch(
      restoreRow({
        target_seller_folders: hasValue(item)
          ? item
          : util.getIdList(selectedRow),
      })
    )
      .unwrap()
      .then((rs) => {
        handleNotifyUser(rs);
        setSubmit(false);
        if (rs?.type === "success") {
          setConfirmationModalVisibility(null);
          onPaginationApply();
        }
      });
  };
  const onStatusToggleActive = async (item, setSubmit) => {
    await dispatch(
      toggleStatus({
        target_seller_folders: hasValue(item)
          ? item
          : util.getIdList(selectedRow),
        status: 1,
      })
    )
      .unwrap()
      .then((rs) => {
        handleNotifyUser(rs);
        setSubmit(false);
        if (rs?.type === "success") {
          setConfirmationModalVisibility(null);
          onPaginationApply();
        }
      });
  };
  const onStatusToggleInactive = async (item, setSubmit) => {
    await dispatch(
      toggleStatus({
        target_seller_folders: hasValue(item)
          ? item
          : util.getIdList(selectedRow),
        status: 0,
      })
    )
      .unwrap()
      .then((rs) => {
        handleNotifyUser(rs);
        setSubmit(false);
        if (rs?.type === "success") {
          setConfirmationModalVisibility(null);
          onPaginationApply();
        }
      });
  };

  const statusList = {
    1: {
      label: "Active",
      actions: [actionList.edit, actionList.inactive, actionList.delete],
    },
    0: {
      label: "Inactive",
      actions: [actionList.edit, actionList.active, actionList.delete],
    },
    2: {
      label: "Deleted",
      actions: [actionList.restore],
    },
  };

  const sortList = {
    list: [
      {
        label: "Name",
        value: "name",
        onClick: (item) => {
          onSortApply("name");
        },
      },
      {
        label: "Last Updated",
        value: "updated_at",
        onClick: (item) => {
          onSortApply("updated_at");
        },
      },
      {
        label: "Created At",
        value: "created_at",
        onClick: (item) => {
          onSortApply("created_at");
        },
      },
      {
        label: "Refreshed At",
        value: "updated_at",
        onClick: (item) => {
          onSortApply("updated_at");
        },
      },
      {
        label: "Monthly Sales",
        value: "monthly_sales",
        onClick: (item) => {
          onSortApply("monthly_sales");
        },
      },
    ],
  };

  // handle actions of statuses
  //
  const actions = (row) => {
    // todo const innerRow = row.map((item) => { add actions_id to item }

    return getActionList(row, statusList);
  };

  // Handle Tabs
  const onHandleTab = (item) => {
    onFilterApply({ status: item });
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(null, limit);
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const handleCallBackActions = ({ action, data }) => {
    actionList[action].onClick(data);
  };
  // Render View of Page
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={"Target Seller Folders"}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          // sort={{
          //     items: sortList,
          //     selected: pageData?.filters?.sort
          // }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: ["status"],
            items: filtersView(pageData?.filters?.filters),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.status === undefined ||
              pageData?.filters?.filters?.status === null
                ? 0
                : 1),
          }}
          actionList={actions(selectedRow)}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            loading={pageData?.filterLoading}
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.status}
            list={[
              {
                id: 1,
                name: "active",
                count: pageData?.filtersMeta?.statuses?.find(
                  (item) => item?.name === "active"
                )?.count,
              },
              {
                id: 0,
                name: "inactive",
                count: pageData?.filtersMeta?.statuses?.find(
                  (item) => item?.name === "inactive"
                )?.count,
              },
              {
                id: 2,
                name: "deleted",
                count: pageData?.filtersMeta?.statuses?.find(
                  (item) => item?.name === "deleted"
                )?.count,
              },
            ]}
            allTab={true}
          />
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(
                  pageData.targetSellerListings,
                  handleCallBackActions
                )}
                actions={actions}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                loading={pageData.loading}
                meta={pageData?.meta}
                appending={loading}
                loadMoreData={loadMoreData}
                style={{
                  table: {
                    style: { width: "100%", minWidth: "100%" },
                  },
                  header: {
                    className: "justify-center !pl-[16px] !pr[8px]",
                    "tracking id": { className: "!pr-[30px]" },
                    image: { className: "hidden" },
                    approve: { className: "!py-[0px] hidden" },
                  },
                  row: {
                    className:
                      "!px-[8px] !py-[0px] !items-center !justify-center flex text-[12px] ",
                    // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                    description: {
                      className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                    },
                  },
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          onLimit={onHandleLimit}
          loading={pageData.loading}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {modalVisibility && (
        <FormModal
          title={"Target Seller Folder "}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
          }}
          saveText={"Create & Go"}
          onSubmit={onSubmitApply}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default TargetSellers;
