const SingleGroupProductBadge = ({product}) => (
    <span
        className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium p-[0px]
                    ${product?.type === 1
            ? "text-teal-700 bg-teal-100"
            : "text-indigo-700 bg-indigo-100"
        }`}
    >
        {product?.type === 1 ? "S" : "G"}
    </span>
)
export default SingleGroupProductBadge;