// storeQueryBuilder.js
// -----------------------------------------
// Create updated filters by merging the current filters and the new filters

const storeQueryBuilder = (currentStoreFilters, {filters, includes, sort, search, page, limit, tab}) => {
    // You can add more items here to store in redux store.
    return {
        filters: {...currentStoreFilters?.filters, ...filters},
        includes: includes === undefined ? currentStoreFilters.includes : includes,
        sort: sort !== undefined ? sort : currentStoreFilters?.sort,
        search: (search || search === "") ? search : currentStoreFilters?.search,
        page: page || 1,
        limit: limit || currentStoreFilters?.limit,
        tab: tab || currentStoreFilters?.tab,
    }

}

export default storeQueryBuilder;