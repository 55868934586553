import React from 'react';
import Components from '../components';
import Icon from '../icons';

const statusList = {
    4: {
        icon: <Icon.airplane/>,
        title: "In Transit",
    },
    3: {
        icon: <Icon.exclamation/>,
        title: "Exception",
    },
    5: {
        icon: <Icon.happy/>,
        title: "Delivered",
    },
};

const StatBoxes = ({statusId, count}) => {
    const status = statusList[statusId] || statusList[0]; // Use 0 as fallback if status ID is not found

    return (
        <div className="flex flex-row justify-start gap-x-4 items-center py-6 px-8 rounded-lg text-center"
             style={{
                 boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
             }}
        >
            <Components.StatIcon icon={status.icon}/>
            <div className='flex flex-col justify-start items-start'>
                <span className="text-gray-900 text-[30px] font-bold">{count}</span>
                <p className="text-gray-500 text-[14px] font-normal">{status.title}</p>
            </div>
        </div>
    );
};

export default StatBoxes;
