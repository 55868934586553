import * as Yup from "yup";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import Toggle from "../../dynamic-form/components/toggle";

const FormView = (item) => {
    const innerSubmit = [
        {
            label: "Save",
            type: "primary",  
            action: "save",
        },
    ];

    return {
        title: upperCaseString(item?.id ? "Edit" : "Add") + " Attribute ",
        submit: innerSubmit,
        containerStyle: "grid-cols-8",
        viewModel: (formDefaultValue) => {
            return [
                {
                    name: "name",
                    label: "Name",
                    placeholder: "Enter Name of Attribute",
                    type: "Input",
                    defaultValue: formDefaultValue?.name || (formDefaultValue && formDefaultValue[0]?.name) || null,
                    validation: Yup.string().required("Name is required"),
                    className: "col-span-4",
                },
                {
                    name: "group_id",
                    label: "Class",
                    placeholder: "Choose a Class",
                    type: "Select",
                    api: {
                        url: '/api/v1/attribute-groups',
                        optionValue: (item) => ({
                            value: item.id,
                            label: item.name
                        })
                    },
                    clear: false,
                    reset: false,
                    className: "col-span-4",
                    defaultValue: formDefaultValue?.group_id || (formDefaultValue && formDefaultValue[0]?.group_id) || null,
                    validation: Yup.string().required("Class is required"),
                },
                {
                    name: "type",
                    label: "Type",
                    placeholder: "Choose a Type",
                    type: "Select",
                    list: [
                        {label: "TEXT", value: "TEXT"},
                        {label: "INTEGER", value: "INTEGER"},
                        {label: "FLOAT", value: "FLOAT"},
                        {label: "DATETIME", value: "DATETIME"},
                        {label: "BOOLEAN", value: "BOOLEAN"},
                        {label: "DIMENSION", value: "DIMENSION"},
                        {label: "DROPDOWN", value: "DROPDOWN"},
                        {label: "DATE", value: "DATE"},
                        {label: "TIME", value: "TIME"},
                        {label: "IMAGE", value: "IMAGE"},
                        {label: "DOCUMENT", value: "DOCUMENT"},
                    ],
                    clear: false,
                    reset: false,
                    defaultValue: formDefaultValue?.type || (formDefaultValue && formDefaultValue[0]?.type) || null,
                    className: "col-span-4 mb-1",
                    style: {
                        menu: {
                            height: "200px !important",
                            maxHeight: "230px !important",
                        },
                        menuList: {
                            height: "200px !important",
                            maxHeight: "230px !important",
                        },
                    },
                    validation: Yup.string().required("Type is required"),
                },
                {
                    name: "minCount",
                    label: "Minimum Count",
                    placeholder: "1,10,100",
                    type: "Input",
                    defaultValue: formDefaultValue?.validation_rules?.minCount || (formDefaultValue && formDefaultValue[0]?.validation_rules?.minCount) || null,
                    className: "col-span-2",
                    condition: (formData) => formData?.type === 'TEXT',
                },
                {
                    name: "maxCount",
                    label: "Maximum Count",
                    placeholder: "1,10,100",
                    type: "Input",
                    defaultValue: formDefaultValue?.validation_rules?.maxCount || (formDefaultValue && formDefaultValue[0]?.validation_rules?.maxCount) || null,
                    className: "col-span-2",
                    condition: (formData) => formData?.type === 'TEXT',
                },
                {
                    name: "minValue",
                    label: "Minimum Value",
                    placeholder: "1,10,100",
                    type: "Input",
                    defaultValue: formDefaultValue?.validation_rules?.minValue || (formDefaultValue && formDefaultValue[0]?.validation_rules?.minValue) || null,
                    className: "col-span-2",
                    condition: (formData) => formData?.type === 'INTEGER' || formData?.type === 'FLOAT',
                },
                {
                    name: "maxValue",
                    label: "Maximum Value",
                    placeholder: "1,10,100",
                    type: "Input",
                    defaultValue: formDefaultValue?.validation_rules?.maxValue || (formDefaultValue && formDefaultValue[0]?.validation_rules?.maxValue) || null,
                    className: "col-span-2",
                    condition: (formData) => formData?.type === 'INTEGER' || formData?.type === 'FLOAT',
                },
                {
                    name: "possibileOptions",
                    label: "Possible Options",
                    placeholder: "male,female / white,black",
                    hint: "Enter possible options separated by comma",
                    type: "Input",
                    defaultValue: (formDefaultValue?.validation_rules?.enum || (formDefaultValue && formDefaultValue[0]?.validation_rules?.enum)) ? (formDefaultValue?.validation_rules?.enum.join(',') || formDefaultValue[0]?.validation_rules?.enum.join(',')) : null,
                    className: "col-span-4",
                    condition: (formData) => formData?.type === 'DROPDOWN',
                },
                {
                    name: "is_multiple",
                    label: "Multiple",
                    description: "Check it if the attribute may include multiple values",
                    placeholder: "blach",
                    type: "Module",
                    module: Toggle,
                    className: "col-span-8",
                    defaultValue: (formDefaultValue?.is_multiple || (formDefaultValue && formDefaultValue[0]?.is_multiple)) ? 1 : 0,
                    labelClassName: "!text-gray-900 !font-medium hidden",
                },
                {
                    name: "is_multilingual",
                    label: "Multilingual",
                    description: "Check it if the attribute may include multiple values across multiple languages",
                    placeholder: "blach",
                    type: "Module",
                    module: Toggle,
                    className: "col-span-8",
                    defaultValue: (formDefaultValue?.is_multilingual || (formDefaultValue && formDefaultValue[0]?.is_multilingual)) ? 1 : 0,
                    labelClassName: "!text-gray-900 !font-medium hidden",
                },
                {
                    name: "description",
                    label: "Description",
                    placeholder: "Enter Description",
                    type: "TextArea",
                    defaultValue: formDefaultValue?.description || (formDefaultValue && formDefaultValue[0]?.description) || null,
                    className: "col-span-8",
                },
            ];
        },
    };
};
export default FormView;
