import React, { useEffect } from "react";
import MainModal from "../../dynamic-page/MainModal";
import HandmadeSkeleton from "../../../utils/skeleton";
import SingleActivity from "../components/singleActivity";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserActivities } from "../../../store/users";
import upperCaseString from "../../dynamic-page/util/upperCaseString";

export default function UserActivity({ onClose, data }) {
  const dispatch = useDispatch();
  const activities = useSelector((state) => state.user.activities);
  const status = useSelector((state) => state.user.activityStatus);

  useEffect(() => {
    if (data?.id) {
      dispatch(fetchUserActivities(data.id));
    }
  }, [dispatch, data?.id]);

  return (
    <MainModal
      item={{
        title: `${upperCaseString(data?.first_name, "word")}'s Activities`,
        cancel: onClose,
        view: () => (
          <div className="  min-h-[589.5px] flex flex-col">
            <hr />
            <div
               id="scrollableDiv"
              className={`max-h-[589.5px]  sticky shadow-md min-w-full min-h-[400px] overflow-x-auto ${
                status === "pending" || activities?.length < 10
                  ? "overflow-y-hidden"
                  : "overflow-y-scroll"
              } flex rounded-[16px] flex-1 mt-4 mb-2 flex-col`}
            >
              <table className="w-full table-auto ">
                <thead className="bg-gray-50 sticky top-0 z-[50]  w-full dynamicTableMain">
                  <tr>
                    <th className="min-w-[210px] px-4 h-[50px] text-start border-b border-gray-200" />
                    <th className="min-w-[104px] h-[50px] text-start border-b border-gray-200 whitespace-nowrap  ">
                      <span className="text-[12px] leading-[18px] font-semibold text-gray-500">
                        ACTIVITY ID
                      </span>
                    </th>
                    <th className="min-w-[133px] text-start h-[50px] border-b border-gray-200 whitespace-nowrap ">
                      <span className="text-[12px] leading-[18px] font-semibold text-gray-500">
                        IP ADDRESS
                      </span>
                    </th>
                    <th className="w-[380px] h-[50px] text-start border-b border-gray-200 whitespace-nowrap ">
                      <span className="text-[12px] leading-[18px] font-semibold text-gray-500">
                        ACTIVITY
                      </span>
                    </th>
                    <th className="w-[180px] px-4 h-[50px] text-start border-b border-gray-200" />
                  </tr>
                </thead>
                <tbody className=" overflow-x-auto sticky min-w-[700px]">
                  {status !== "pending" ? (
                    activities?.map((item, index) => (
                      <SingleActivity {...item} key={item.id} index={index} />
                    ))
                  ) : (
                    <div
                      className={
                        "min-w-[900px]    px-6 py-3 gap-2 flex flex-col absolute"
                      }
                    >
                      {[...Array(10)].map((_, index) => (
                        <HandmadeSkeleton
                          key={index}
                          className={"!w-[955px] h-9 "}
                        />
                      ))}
                    </div>
                  )}
                </tbody>
              </table>
              {activities?.length === 0 && status === "idle" && (
                <div className="flex justify-center items-center mt-52">
                  <span className="text-2xl text-gray-500">
                    {upperCaseString(data?.first_name, "word")} has no activity.
                  </span>
                </div>
              )}
            </div>
          </div>
        ),
      }}
    />
  );
}
