import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchPositions = createAsyncThunk(
    'get:api/v1/position',
    async (params, thunkAPI) => {
        const response = await api.fetchPositions(params)
        return response.data
    }
)

export {fetchPositions}