import axios from "./../../middleware/axios";
import constants from '../../constant';



const fetchTransfers = async (params) => {
    let url = 'api/v1/transfers';
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
}

const fetchSingleTransfer = async (params) => {
    let url = 'api/v1/transfers/' + params;
    const response = await axios.get(url, {withCredentials: true});
    return response;
}

const fetchTransfersFilters = async (params) => {
    let url = 'api/v1/transfers/filters'
    if (params)
        url = url.concat(params)
    return await axios.get(url, {withCredentials: true,})
}

// Notes
const fetchNotes = async (params) => {
    const url = `api/v1/transfers/${params?.orderId}/notes`;
    return await axios.get(url, {withCredentials: true,})
}

const deleteNote = async (params) => {
    const url = `api/v1/transfers/${params?.orderId}/notes/${params?.noteId}`;
    return await axios.delete(url, {withCredentials: true,});
}


const addNote = async (params) => {
    const url = `api/v1/transfers/${params?.orderId}/notes`;
    return await axios.post(url, {note: params?.note}, {withCredentials: true,});
}


const fetchShipmentCarrier = async (payload) => {
    let url = constants.APIURL.CALCULATOR_DATAS + `?from_state_id=${payload?.from?.state?.id}&to_state_id=${payload?.to?.state?.id}&billable_weight=${payload?.billable_weight}&fresh=${payload?.fresh}`
    const response = await axios.get(url,
        {withCredentials: true},
    );
    return response.data
}
const createShipment = async (params) => {
    const url = 'api/v1/transfers/' + params?.order_id + "/create-shipment-label" + `/${params?.shipment_pricing_id}`
    const rs = await axios.post(url,
        {withCredentials: true,});
    return rs
}



export default {
    fetchTransfers,
    fetchNotes,
    deleteNote,
    addNote,
    fetchShipmentCarrier,
    createShipment,
    fetchSingleTransfer,
    fetchTransfersFilters
}