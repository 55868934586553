// DropDownMenu.js
import React, {forwardRef, useEffect} from "react";

const ClickDropDown = forwardRef(function ClickDropDown({itemMenu, isOpen, setIsOpen, item, lastClickEvent}, ref) {


    useEffect(() => {
        if (isOpen) {
            const menuRect = ref.current.getBoundingClientRect();
            const mouseX = lastClickEvent.clientX;
            const mouseY = lastClickEvent.clientY;

            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            const requiredSpace = {
                width: menuRect.width,
                height: menuRect.height
            };

            let position = {
                top: mouseY,
                left: mouseX
            };

            // Adjust position to keep the menu within the viewport
            if (position.left + requiredSpace.width > viewportWidth) {
                position.left -= (position.left + requiredSpace.width) - viewportWidth;
            }

            if (position.top + requiredSpace.height > viewportHeight) {
                position.top -= (position.top + requiredSpace.height) - viewportHeight;
            }

            // Set position of menu
            ref.current.style.top = `${position.top}px`;
            ref.current.style.left = `${position.left}px`;
        }
    }, [isOpen, lastClickEvent]);


    return (
        <div>
            {/* Render Menu from itemMenu */}
            <div
                ref={ref}
                className={` ${!isOpen && "hidden"} p-1 transition absolute z-[99999] flex flex-col text-left rounded-[10px] shadow-xl bg-white w-content overflow-auto max-h-[500px]`}
            >
                {itemMenu?.list?.map((menu, index) => (
                    <div
                        key={index}
                        className={`cursor-pointer justify-start font-medium min-w-full hover:bg-gray-100 pl-[16px] pr-12 py-[9px] rounded-[6px] text-gray-500 hover:text-gray-800 transition text-sm`}
                        onClick={() => {
                            menu.onClick(item);
                        }}
                    >
                        {menu.label}
                    </div>
                ))}
            </div>
        </div>
    );
})

export default ClickDropDown;
