import upperCaseString from "../../dynamic-page/util/upperCaseString";

const FormView = (formDefaultValue) => {
    return [
        {
            name: "assigned_user_id",
            label: "Assigned Employee",
            placeholder: "Filter by employee",
            type: "Select",
            api: {
                url: "/api/v1/users?filter[status]=1",
                view: "/api/v1/employees",
                query: "&filter[name]={query}",
                optionValue: (item) => ({
                    value: item?.employee_id,
                    label: item?.name,
                    avatar: item?.image_url,
                }),
            },
            features: {
                formatOptionLabel: ({label, avatar}) => (
                    <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
                className={
                    "block overflow-hidden flex flex-col justify-center items-center "
                }
            >
              <img
                  src={avatar || "/assets/images/defaultAvatar.png"}
                  className="w-5 h-5 rounded-full object-contain"
              />
            </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                ),
            },
            defaultValue:
                formDefaultValue?.assigned?.employee?.id ||
                formDefaultValue?.employee_id ||
                null,
            clear: true,
            className: "col-span-2",
        },
    ];
};

export default FormView;
