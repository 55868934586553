// Import necessary libraries and components
import {pdfjs} from 'react-pdf';
import util from "../dynamic-page/util";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const setupZPLPrinter = () => {
    return new Promise((resolve, reject) => {
        window.BrowserPrint.getDefaultDevice("printer", function (device) {
            const selected_device = device;
            const deviceList = [];
            if (device.name !== undefined) deviceList.push(device);

            window.BrowserPrint.getLocalDevices((device_list) => {
                for (var i = 0; i < device_list.length; i++) {
                    var device = device_list[i];
                    if (!selected_device || device.uid !== selected_device.uid) {
                        if (device.name !== undefined) {
                            deviceList.push(device)
                        }
                    }
                }
                resolve(deviceList);
            }, function () {
                errorCallback("Error getting local devices")
                resolve([]);
            }, "printer");
        }, function (error) {
            // errorCallback(error)
            reject(error);
        })
    });
}


const getAndSendFileToPrinter = async (url) => {
    try {

        const device = await setupZPLPrinter();
        const selectedDevice = device[0];

        if (selectedDevice) {
            selectedDevice.convertAndSendFile(url, undefined, errorCallback)
        } else {
            errorCallback("No printer selected");
        }

    } catch (err) {
        errorCallback("No printer selected");
    }
}

var errorCallback = function (errorMessage) {
    util.notifier({message: errorMessage, type: "error"});
}

// Export the function


function printComplete() {
    errorCallback('Print completed successfully.');
}

function printerError(errorMessage) {
    errorCallback('Failed to print:' + errorMessage);
}


export default {getAndSendFileToPrinter, setupZPLPrinter}