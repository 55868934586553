const sortView = ({callback}) => {
    return {
        list: [

            {
                label: "Order Date",
                value: "order_date",
                onClick: (item) => {
                    callback("order_date");
                }
            },

            // add for return_request_date , resolution , refund_amount , order_amount , label_to_be_paid_by ,label_type ,  in_policy , 
            {
                label: "Return Request Date",
                value: "return_request_date",
                onClick: (item) => {
                    callback("return_request_date");
                }
            },
            {
                label: "Resolution",
                value: "resolution",
                onClick: (item) => {
                    callback("resolution");
                }
            },
            {
                label: "Refund Amount",
                value: "refund_amount",
                onClick: (item) => {
                    callback("refund_amount");
                }
            },
            {
                label: "Order Amount",
                value: "order_amount",
                onClick: (item) => {
                    callback("order_amount");
                }
            },
            // {
            //     label: "Label Cost",
            //     value: "label_cost",
            //     onClick: (item) => {
            //         callback("label_cost");
            //     }
            // },
            {
                label: "Label To Be Paid By",
                value: "label_to_be_paid_by",
                onClick: (item) => {
                    callback("label_to_be_paid_by");
                }
            },
            {
                label: "Label Type",
                value: "label_type",
                onClick: (item) => {
                    callback("label_type");
                }
            },
            {
                label: "In Policy",
                value: "in_policy",
                onClick: (item) => {
                    callback("in_policy");
                }
            },
            {
                label: "A to Z Claim",
                value: "a_to_z_claim",
                onClick: (item) => {
                    callback("a_to_z_claim");
                }

            },
        ]


    }
}

export default sortView