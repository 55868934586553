import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchPayments = async (params) => {
    let url = constants.APIURL.GET_PAYMENT_PURCHASES;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

// const searchPayments = async (params) => {
//     let url = constants.APIURL.GET_PAYMENTS + '/search?term=';
//     if (params) url = url.concat(params);
//     const response = await axios.get(url, { withCredentials: true });
//     return response?.data;
// };

const deletePayment = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_PAYMENT_PURCHASE + payload, {withCredentials: true});
    return response;
};

const createPayment = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_PAYMENT_PURCHASE, payload, {withCredentials: true});
    return response;
};

const updatePayment = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_PAYMENT_PURCHASE + id, payload, {withCredentials: true});
    return response;
};

const bulkDeletePayments = async (payload) => {
    const response = await axios.delete(constants.APIURL.BULK_DELETE_PAYMENT_PURCHASES, {
        withCredentials: true,
        data: payload
    });
    return response;
};

const bulkRestorePayments = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_PAYMENT_PURCHASES, payload, {withCredentials: true});
    return response;
};

const bulkUpdatePaymentsStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_BULK_PAYMENT_PURCHASE_STATUS, payload, {withCredentials: true});
    return response;
};

const fetchPaymentsFilters = async (params) => {
    let url = constants.APIURL.GET_PAYMENT_PURCHASES_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response?.data;
};

export default {
    fetchPayments,
    deletePayment,
    createPayment,
    updatePayment,
    bulkDeletePayments,
    bulkRestorePayments,
    bulkUpdatePaymentsStatus,
    fetchPaymentsFilters
};
