/**
 * @author Austin Ames
 * @description ProductSalesChannel - a component to be displayed for sales channel tab
 */
import SupplyChannels from "../supply-channel-listings";

const ProductSalesChannelPage = ({
                                     product_id, showSupplyModal, setShowSupplyModal,
                                     updateListingsCount
                                 }) => {
    return (
        <SupplyChannels product_id={product_id} showSupplyModal={showSupplyModal} hideFilter={"product_id"}
                        setShowSupplyModal={setShowSupplyModal} displayTitle={'hidden'} displayTabGroups="hidden"
                        updateListingsCount={updateListingsCount}
                        showCreateForm={true}/>
    )
}

export default ProductSalesChannelPage;
