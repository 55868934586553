// language/index.js
// ----------------------------------------------------------------

import { createSlice } from "@reduxjs/toolkit";
import { fetchLanguages } from "./thunk";

// Initial State of the language slice.
const initialState = {
  list: [],
  loading: "idle",
};

// Create the language slice.
const languageSlice = createSlice({
  name: "languages",
  initialState: initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    // The fetchLanguage action is already handled by extraReducers.
  },
  extraReducers: (builder) => {
    builder
      // Handle the fetchLanguages.pending action.
      .addCase(fetchLanguages.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.list = {};
        }
      })
      // Handle the fetchLanguages.fulfilled action.
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.list = action.payload.data;
        }
      })
      // Handle the fetchLanguages.rejected action.
      .addCase(fetchLanguages.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      });
  },
});

export { fetchLanguages };
export default languageSlice.reducer;
