/**
 * @author Austin Ames
 * @version 0.1.0
 * @description ArbitProductPage - Common Product List Layout Style with Title, Toolbar, SelectTabs, Table, Footer
 * @returns ArbitProductPage Component
 */
import classNames from "classnames";
import Title from "./title";
import Toolbar, { ToolbarLeft, ToolbarRight } from "./toolbar";
import Tab from "./tab";
import Content from "./content";
import Footer from "./footer";

const Page = ({ children, className }) => {
  return <div className={classNames("font-inter", className)}>{children}</div>;
};

const ArbitProductPage = {
  Page,
  Title,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
  Tab,
  Content,
  Footer,
};
export default ArbitProductPage;
