import upperCaseString from "../../dynamic-page/util/upperCaseString";
import { useSelector } from "react-redux";

const FiltersView = (selectedFilers, bound) => {
  
  return [
  
    {
      name: "transferable_type",
      label: "Transferable Type",
      placeholder: "Filter by transferable type",
      type: "RadioButton",
      list: [
        {
          label: "Order",
          value: "order",
        },
        {
          label: "Product",
          value: "product",
        },
      ],
      defaultValue: selectedFilers?.transferable_type,
      toggle: true,
      clear:true,
      radioContainerClass: "!flex-col",
    },
    {
      label: "From Warehouse",
      name: "from_warehouse_ids",
      type: "Select",
      placeholder: "Filter by from warehouse",
      api: {
        url: "/api/v1/warehouses",
        //url: '/api/v1/warehouses',
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        skipCancel: true,
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.name, "word", true),
        }),
      },
      
      defaultValue: selectedFilers?.from_warehouse_ids || [],
      reset: false,
      clear: true,
      toggle: true,
      placeholder: "Select warehouses",
    },
    {
      label: "Destionation",
      name: "destination_ids",
      placeholder: "Filter by destination",
      type: "Select",
      api: {
        url: "/api/v1/warehouses",
        skipCancel: true,
        //url: '/api/v1/warehouses',
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.name, "word", true),
        }),
      },
      defaultValue: selectedFilers?.destination_ids || [],
      reset: false,
      clear: true,
      toggle: true,
      placeholder: "Select warehouses",
    },
    {
      name: "actual_weight",
      label: "Actual Weight",
      placeholder: "Filter by actual weight",
      type: "Range",
      defaultValue: selectedFilers?.actual_weight,
      toggle: true,
      clear: true,
    },
    {
      name: "dimensional_weight",
      label: "Dim Weight",
      placeholder: "Filter by dimensional weight",
      type: "Range",
      defaultValue: selectedFilers?.dimensional_weight,
      toggle: true,
      clear: true,
    },
    {
      name: "code",
      label: "Item code",
      placeholder: "Filter by item code",
      type: "Input",
      defaultValue: selectedFilers?.code ?  decodeURIComponent(selectedFilers?.code) : '',
      toggle: true,
      clear: true,
      encode: true,
    },
    {
      name: "name",
      label: "Item name",
      placeholder: "Filter by item name",
      type: "Input",
      defaultValue: selectedFilers?.name ?  decodeURIComponent(selectedFilers?.name) : '',
      toggle: true,
      clear: true,
      encode: true,
    },
    
  ];
};

export default FiltersView;