function priceHandler(
  num,
  currency,
  thousandsSeparator = ",",
  decimalSeparator = "."
) {
  if (num === null || num === undefined) {
    return "";
  }
  if (typeof num === "string") {
    num = num.replace(/,/g, "");
  }

  if (isNaN(num) || num === "" || num === null || num === undefined) {
    return "-";
  }

  const symbol = currency?.symbol || "";
  const position = currency?.position || "0";

  // Ensure the number is always formatted to two decimal places
  let numString = parseFloat(num).toFixed(2);

  if (decimalSeparator !== ".") {
    numString = numString.replace(".", decimalSeparator);
  }

  if (position === "0") {
    return addSeparator(numString, thousandsSeparator) + symbol;
  } else {
    return symbol + addSeparator(numString, thousandsSeparator);
  }
}

function addSeparator(num, separator) {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export default priceHandler;
