import axios from './../../middleware/axios';
import constants from '../../constant';


const fetchPermissions = async (params) => {
    let url = constants.APIURL.GET_DOMAIN_PERMISSIONS;
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response.data.data
};


const assignPermissions = async (id, payload) => {
    let url = constants.APIURL.POST_USER + "/" + id + constants.APIURL.POST_ASSIGN_PERMISSIONS;
    // if (params) url = url.concat(params);
    // console.log(id, payload, 'api');
    const response = await axios.post(url, {"permission_ids": payload}, {withCredentials: true})
    // console.log(response, 'api');
    return response
}


export default {fetchPermissions, assignPermissions}