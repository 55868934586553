import classNames from "classnames";
import { useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";

const Modal = ({
  title,
  onClose,
  children,
  center,
  state,
  show,
  modalClassName,
  childrenClassName,
}) => {
  const defaultStyle = {
    transition: `opacity 200ms ease-in-out`,
    opacity: 0,
  };
  const modalRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Click is outside of the modal, close it
        onClose(); // Call the provided function to close the modal
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]); // Make sure to include onClose in the dependencies

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition
      in={show}
      timeout={200}
      appear={true}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {(state) => (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={classNames(
            "overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full flex items-center justify-center bg-gray-700/60"
          )}
        >
          <div
            className={`relative p-4  ${
              center ? "flex items-center justify-center h-screen" : "h-full"
            }`}
          >
            <div
              className={`relative bg-white rounded-3xl  shadow dark:bg-gray-700 ${
                modalClassName || ""
              }`}
              ref={modalRef}
            >
              <div
                className={`flex justify-between items-start pl-6 pr-3 py-4 rounded-t border-b dark:border-gray-600 ${
                  title === "" && "hidden"
                }`}
              >
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {title}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => onClose()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className={`p-6 space-y-6 ${childrenClassName || ""}`}>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};
Modal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  center: PropTypes.bool,
  state: PropTypes.string,
  show: PropTypes.bool.isRequired,
  modalClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  center: false,
  state: "entered",
  modalClassName: "",
  childrenClassName: "",
};

export default Modal;
