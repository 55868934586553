import React from "react";

const StoreConnect = ({onClose, onConnectApply, item}) => {

    return (
        <div className="mt-22 flex flex-col items-center justify-center py-10">
            <img className="w-16" src={item?.channel?.logo}/>
            <h1 className="mt-6 text-3xl font-semibold mb-6">
                Connect to {item?.channel?.name} store
            </h1>
            <h2 className="text-2xl font-normal text-gray-600 mb-6">
                Click connect to authorize your store
            </h2>
            <button
                onClick={onConnectApply}
                className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
            >
                Connect
            </button>
        </div>
    );
};

export default StoreConnect;