import upperCaseString from "../../dynamic-page/util/upperCaseString";

const FiltersView = (selectedFilers, filtersMeta) => {
    return [
        {
            name: "name",
            label: "Name",
            placeholder: "Filter by Name",
            type: "Input",
            defaultValue: selectedFilers?.name || null,
            toggle: true,
            clear: true,
        },
        {
            name: "type",
            label: "Type",
            placeholder: "Filter by Type",
            type: "Checkbox",
            list: filtersMeta?.types?.map((item) => ({
                label: upperCaseString(item?.type),
                value: item?.type,
                count: item?.count
            })),
            defaultValue: selectedFilers?.type,
            toggle: true,
            clear: true,
            features: {
                isMulti: true,
            },
            checkAll: true,
        },
        {
            name: "group",
            label: "Class",
            placeholder: "Filter by Class",
            type: "Checkbox",
            list: filtersMeta?.groups?.map((item) => ({
                label: upperCaseString(item?.group),
                value: item?.id,
                count: item?.count
            })),
            defaultValue: selectedFilers?.group,
            toggle: true,
            clear: true,
            features: {
                isMulti: true,
            },
            checkAll: true,
        },
    ];
};

export default FiltersView;
