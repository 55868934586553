import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchListingMatches = createAsyncThunk(
    'get:api/v1/listing_matches',
    async (params, thunkAPI) => {
        const response = await api.fetchListingMatches(params)
        return response.data
    }
)

const updateListingMatches = createAsyncThunk(
    'post:api/v1/listing_matches',
    async (payload, thunkAPI) => {
        const response = await api.updateListingMatches(payload)
        return response.data
    }
)
const updateSelectionListingMatches = createAsyncThunk(
    'post:api/v1/listing_matches/bulk',
    async (payload, thunkAPI) => {
        const response = await api.updateSelectionListingMatches(payload)
        return response.data
    }
)

const fetchListingMatchesFilters = createAsyncThunk(
    'get:api/v1/listing_matches/filters',
    async (thunkAPI) => {
        const response = await api.fetchListingMatchesFilters()
        return response.data
    }
)

export {
    fetchListingMatches,
    fetchListingMatchesFilters,
    updateListingMatches,
    updateSelectionListingMatches
}
