import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteCurrencies,
  bulkRestoreCurrencies,
  createCurrency,
  deleteCurrency,
  fetchCurrencies,
  fetchCurrenciesFilters,
  searchCurrencies,
  updateCurrenciesStatus,
  updateCurrency,
} from "./thunk";

const initialState = {
  currencies: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    status_filter: [],
    created_filter: "",
    updated_filter: "",
    balance_filter: [],
  },
  meta: {},
  filtersMeta: {},
};

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateCurrencyStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.currencies = state.currencies.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeCurrency: (state, action) => {
      const ids = action.payload;
      state.currencies = state.currencies.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    bulkDeleteCurrencies: (state, action) => {
      const ids = action.payload;
      state.currencies = state.currencies.filter(
        (currency) => !ids.includes(currency.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchCurrencies.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchCurrencies.fulfilled, (state, action) => {
        state.loading = "idle";
        state.currencies = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      // .addCase(fetchCurrenciesFilters.pending, (state, action) => {
      //     state.loading = "pending";
      // })
      .addCase(fetchCurrenciesFilters.fulfilled, (state, action) => {
        state.loading = "idle";
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeCurrency,
  updateCurrencyStatuses,
} = currenciesSlice.actions;

export {
  fetchCurrencies,
  createCurrency,
  updateCurrency,
  deleteCurrency,
  searchCurrencies,
  bulkDeleteCurrencies,
  updateCurrenciesStatus,
  bulkRestoreCurrencies,
  fetchCurrenciesFilters,
};

export default currenciesSlice.reducer;
