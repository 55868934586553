// Import Elements
import Input from "./Input";
import Select from "./Select";
import DateRange from "./DateRange";
import Divider from "./Divider";
import Button from "./Button";
import Range from "./Range";
import Container from "./Container";
import Checkbox from "./Checkbox";
import Textarea from "./Textarea";
import Module from "./Module";
import Date from "./Date";
import DateHour from "./DateHour";
import Image from "./Image";
import Number from "./Number";
import Text from "./Text";
import RadioButton from "./RadioButton";
import ProductImage from "../components/productImage";
import ProductList from "../components/productList";
import ShippingCostList from "../components/shippingCostList";
import StatBoxList from "../../dynamic-page/elements/StatBoxList";
import AreaChart from "../../dynamic-page/elements/AreaChart";
import Select2 from "./select2";
import Select3 from "./Select3";
import File from "./File";
import CalculateWeight from "../../calculator/components/calculateWeight";
import CalculatorDatas from "../../calculator/components/calculatorDatas";
import LineCss from "../../calculator/components/lineCss";
import Select4 from "./Select4";
import OrderText from "./OrderText";

//Export Elements as default
export default {
    Container,
    Input,
    Select,
    DateRange,
    Divider,
    Button,
    Range,
    Checkbox,
    Textarea,
    Module,
    Date,
    Image,
    Number,
    Text,
    DateHour,
    RadioButton,
    ProductImage,
    ProductList,
    LineCss,
    ShippingCostList,
    StatBoxList,
    AreaChart,
    Select2,
    Select3,
    File,
    CalculateWeight,
    CalculatorDatas,
    Select4,
    OrderText,
};
