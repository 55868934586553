import React from "react";
import Icon from "../icons";
import ListingOffersTableComponent from "../listing-offers-table";
import {howLongFromNow} from "../../../utils/helper";

const ListingOffers = ({modalListing, fulfilmentHandler}) => {
    const headers = [
        "Seller name",
        "Price",
        "Shipping time (hours)",
        "Condition",
        "Ships from",
    ];
    const data = [
        {
            star: <Icon.Star/>,
            fire: <Icon.Fire/>,
            status: (
                <span className="rounded-md uppercase font-medium text-orange-800 bg-orange-100 py-1 px-3">
          FBA
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span
                        className="text-orange-800 bg-orange-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
            Prime
          </span>
                </div>
            ),
            national_prime: (
                <span
                    className="text-orange-800 bg-orange-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          National Prime
        </span>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        {
            status: (
                <span className="rounded-md uppercase font-medium text-blue-800 bg-blue-100 py-1 px-3">
          FBm
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span className="text-gray-500 font-normal ml-1">US</span>
                </div>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        {
            fire: <Icon.Fire/>,
            status: (
                <span className="rounded-md uppercase font-medium text-blue-800 bg-blue-100 py-1 px-3">
          FBm
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span className="text-gray-500 font-normal ml-1">US</span>
                </div>
            ),
        },
        {
            fire: <Icon.Fire/>,
            status: (
                <span className="rounded-md uppercase font-medium text-orange-800 bg-orange-100 py-1 px-3">
          FBA
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                </div>
            ),
            national_prime: (
                <span
                    className="text-orange-800 bg-orange-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          National Prime
        </span>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        {
            status: (
                <span className="rounded-md uppercase font-medium text-blue-800 bg-blue-100 py-1 px-3">
          FBm
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span className="text-gray-500 font-normal ml-1">US</span>
                </div>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        {
            star: <Icon.Star/>,
            fire: <Icon.Fire/>,
            status: (
                <span className="rounded-md uppercase font-medium text-orange-800 bg-orange-100 py-1 px-3">
          FBA
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span
                        className="text-orange-800 bg-orange-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
            Prime
          </span>
                </div>
            ),
            national_prime: (
                <span
                    className="text-orange-800 bg-orange-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          National Prime
        </span>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        {
            status: (
                <span className="rounded-md uppercase font-medium text-blue-800 bg-blue-100 py-1 px-3">
          FBm
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span className="text-gray-500 font-normal ml-1">US</span>
                </div>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        {
            star: <Icon.Star/>,
            fire: <Icon.Fire/>,
            status: (
                <span className="rounded-md uppercase font-medium text-orange-800 bg-orange-100 py-1 px-3">
          FBA
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span
                        className="text-orange-800 bg-orange-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
            Prime
          </span>
                </div>
            ),
            national_prime: (
                <span
                    className="text-orange-800 bg-orange-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          National Prime
        </span>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        {
            status: (
                <span className="rounded-md uppercase font-medium text-blue-800 bg-blue-100 py-1 px-3">
          FBm
        </span>
            ),
            "Seller name": "Lamodahome",
            Price: "$21.00",
            "Shipping time (hours)": "24 - 24 [NOW]",
            Condition: "New",
            "Ships from": (
                <div className="flex flex-row justify-start items-center">
                    <Icon.US/>
                    <span className="text-gray-500 font-normal ml-1">US</span>
                </div>
            ),
            featured_merchant: (
                <span
                    className="text-green-800 bg-green-100 font-medium rounded-md py-1 px-3 flex justify-center items-center">
          Featured Merchant
        </span>
            ),
        },
        // Add more data as needed
    ];

    const loData01 = [
        {title: "Total Active Offers", value: "19"},
        {title: "Your Active FBA Offers", value: "3"},
        {title: "Your Active FBM Offers", value: "2"},
        {
            title: "Last Update",
            value:
                (
                    <div className="text-emerald-700">
                        {howLongFromNow(
                            modalListing?.sales_channel_listing?.updated_at ||
                            modalListing?.updated_at,
                            true
                        )}
                    </div>
                ) || "N/A",
        },
        // Add more data items as needed
    ];

    return (
        <div className="mt-5 flex flex-col justify-between items-start gap-y-3 gap-x-2 w-full">
            {/* <div className="grid grid-cols-4 justify-center items-center gap-x-3 w-full">
        <UI.StatBoxList data={loData01} />
      </div> */}
            <ListingOffersTableComponent headers={headers} data={data}/>
        </div>
    );
};

export default ListingOffers;
