import React, {memo, useEffect, useRef, useState} from 'react';
import tabs, {tabList} from "./tabs";
import MainModal from "../../../dynamic-page/MainModal";
import TabButton from "../../../dynamic-page/elements/TabButton";
import {viewOrder as viewRow} from "../../../../store/orders";
import {useDispatch} from "react-redux";
import Pending from "../../../dynamic-page/elements/pending";
import handlePrepareDataToPost from "./summary/util/prepareDataToPost";
import prepareDataToView from "./summary/util/prepareDataToView";
import callEndpoint from '../../../dynamic-page/util/callEndpoint';

const Transfers = memo(({submit, order, cancel, createShipment}) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('summary');
    const [detailData, setDetailData] = useState({});
    const [isView, setIsView] = useState(false);
    const [loadViewState, setLoadViewState] = useState(false);
    const tabContentRef = useRef(null);

    const Tab = memo(tabs[selectedTab]);

    const actionCallBack = (data) => {
        const actions = {
            tabs: (tab) => {
                handleTabClick(tab)
            }
        }
        actions[data.action](data.data)
    }
    useEffect(() => {
        if (order) {
            setIsView(true);
            setLoadViewState(false);
            setDetailData(null);
            dispatch(viewRow(order)).then((rs) => {
                const returnData = rs?.payload?.data;
                const viewData = prepareDataToView(returnData);
                setDetailData(viewData);
                setLoadViewState(true);
            });
        } else {
            setIsView(false);
        }
    }, [order]);

    const handleTabClick = async (tabName) => {
        let formData = await tabContentRef?.current?.getData();
        let tabValidation = formData?._validation?.isValid;
        const detail = {...detailData, [selectedTab]: {...detailData[selectedTab], ...formData}};

        if (tabValidation || order) {
            //if (tabName !== selectedTab) {
            setDetailData(detail);
            setSelectedTab(tabName);
            //}
        }

        return {tabValidation: tabValidation, formData: detail};
    };

    const handleUpdateDetailData = async (data, state) => {
        const actionList = {
            "save": async () => {
                let formData = await tabContentRef?.current?.getData();
                let tabValidation = formData?._validation?.isValid;
                if (tabValidation) {
                    const payload = await handlePrepareDataToPost(formData, state);

                    callEndpoint({
                        title: "Create Transfer",
                        url: formData?.type === 1 ? 'transfer/shipment-transfer' : formData?.type === 2 ? 'transfer/warehouse-transfer' : 'transfer/offer-transfer',
                        method: "POST",
                        data: payload,
                        pureData: true
                    })
                        .then((res) => {
                            if (res?.type === "success") {
                                if (res?.data?.order_id) {
                                    createShipment({
                                        id: res?.data?.order_id,
                                        defaultWarehouse: res?.data?.warehouse?.id
                                    })
                                } else {
                                    setIsView(true);
                                    setDetailData({
                                        ...formData,
                                        tranfer_id: res.data?.code || 'fake1234',
                                    })
                                }
                            }
                            state?.callBack(res.payload);
                        });
                }
            },
            "switchTab": (data) => {
                handleTabClick(data);
            },
            "updateContent": (data) => {
            },
            "memet": (data) => {
            }

        };

        if (state) {
            actionList[state.action](data);
        }
    };


    return (
        <MainModal
            item={{
                title: isView ? detailData?.tranfer_id : "New Transfer ",
                submit: (data) => {
                    submit(data);
                },
                cancel: () => {
                    cancel();
                },
                view: (item) => (
                    <div className={"flex flex-col gap-[8px] w-full h-full orderDetailModal"}
                         style={{width: "calc(100vw - 80px)", maxWidth: "1800px"}}>
                        {(!order || loadViewState) ?
                            <>
                                <TabButton
                                    list={tabList}
                                    active={selectedTab}
                                    callBack={handleTabClick}
                                    className={"!border-b !px-[17px] !gap-[6px] relative bottom-[-1px]"}
                                    selectedClassName={"!border-b !px-[17px] !gap-[6px] relative bottom-[-1px]"}
                                    mainClassName={"!justify-start !gap-[32px] border-b border-b-[#E5E7EB]"}
                                />

                                <Tab
                                    ref={tabContentRef}
                                    data={detailData}
                                    callBack={handleUpdateDetailData}
                                    selectedTab={selectedTab}
                                    isView={isView}
                                    id={isView ? {order: order} : null}
                                />
                            </>
                            :
                            <Pending count={1} h={20}/>
                        }
                    </div>
                ),
            }}
            mainClassName={"!py-[30px] "}
        />
    );
});

export default Transfers;
