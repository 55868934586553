import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchShippingTemplate = createAsyncThunk(
    'get:api/v1/shippingtemplate',
    async (params, thunkAPI) => {
        const response = await api.fetchShippingTemplate(params)
        return response.data
    }
)

const updateOpportunities = createAsyncThunk(
    'post:api/v1/shippingtemplate/refresh',
    async (payload, thunkAPI) => {
        const response = await api.updateOpportunities(payload)
        return response.data
    }
)

const fetchShippingTemplateFilters = createAsyncThunk(
    'get:api/v1/shippingtemplate/filters',
    async (payload, thunkAPI) => {
        const response = await api.fetchShippingTemplateFilters(payload)
        return response.data
    }
)

const fetchShippingTemplateRestrictions = createAsyncThunk(
    'get:api/v1/shippingtemplate/restrictions',
    async (thunkAPI) => {
        const response = await api.fetchShippingTemplateRestrictions()
        return response.data
    }
)
const updateBulkShippingTemplateStatus = createAsyncThunk(
    'patch:/api/v1/shipping_template/status/bulk-action',
    async (payload, thunkAPI) => {
        const response = await api.updateBulkShippingTemplateStatus(payload)
        // console.log(response)
        return response
    }
)

const deleteBulkShippingTemplate = createAsyncThunk(
    'delete:/api/v1/shipping_template/delete/bulk-action',
    async (payload, thunkAPI) => {
        // console.log(payload, 'Thunk')
        const response = await api.deleteBulkShippingTemplate(payload)
        return response
    }
)

export {
    fetchShippingTemplate,
    updateOpportunities,
    fetchShippingTemplateFilters,
    updateBulkShippingTemplateStatus,
    deleteBulkShippingTemplate,
    fetchShippingTemplateRestrictions
}
