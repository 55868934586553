import React from 'react';
import TableView from './offers-table-view'; // Assuming your TableView component is in a file named TableView.js

const TableComponent = ({headers, offersData, hidden}) => {
    const isValidData = Array.isArray(offersData) && offersData.length > 0;

    return (
        <table className="table w-full">
            <thead className='bg-[#F9FAFB] border-b-[1px] border-solid border-[#E5E7EB]'>
            <tr>
                <th className='text-xs text-[#6B7280] uppercase py-4 w-16'></th>
                <th className='text-xs text-[#6B7280] uppercase py-4 w-16'></th>
                {headers.map((header, index) => (
                    <th key={index} className='text-xs text-[#6B7280] uppercase py-4 text-left'>{header}</th>
                ))}
                <th className={`text-xs text-[#6B7280] uppercase py-4 w-40 ${hidden}`}></th>
                <th className={`text-xs text-[#6B7280] uppercase py-4 w-40 ${hidden}`}></th>
            </tr>
            </thead>
            {isValidData ? (
                <TableView data={offersData}/>
            ) : (
                <tr>
                    <td colSpan={headers.length + 5}>No data available.</td>
                </tr>
            )}
        </table>
    );
};

export default TableComponent;
