import upperCase from "../../dynamic-page/util/upperCaseString";
import * as Yup from "yup";
import icons from "../icons";
import constant from "../../../constant";
const MapPin = icons.mapPin;

const manualForm = (formDefaultValue) => {
  return [
    {
      name: "international_carrier_id",
      label: "Associated International Identifier",
      placeholder: "ex. UPS",
      api: {
        url: "/api/v1/international-carriers/index",
        query: "?term={query}",
        ignoreView: true,
        // view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      type: "Select",
      defaultBound: formDefaultValue?.international_carrier?.id
        ? {
            id: formDefaultValue?.international_carrier?.id,
            name: formDefaultValue?.international_carrier?.name,
          }
        : null,
      defaultValue: formDefaultValue?.international_carrier_id || null,
      validation: Yup.string().required("International Carrier is required"),
      className: "col-span-6",
      innerClassName: "bg-gray-50",
    },

    {
      name: "country_id",
      label: "Country",
      placeholder: "Select the country",
      type: "Select",
      reset: false,
      clear: true,
      api: {
        url: constant.APIURL.GET_COUNTRIES,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
          flag: item.flag,
        }),
      },
      features: {
        formatOptionLabel: ({ label, flag }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img src={flag?.toLowerCase()} alt='country' />
            </span>
            <span>{upperCase(label)}</span>
          </div>
        ),
      },
      defaultValue: formDefaultValue?.country_id || null,
      // validation: Yup.string().required("Country is required"),
      className: "col-span-3",
      innerClassName: "bg-gray-50",
    },
    {
      name: "from_state_id",
      label: "State",
      placeholder: "Select the state",
      type: "Select",
      reset: false,
      clear: true,
      features: {
        formatOptionLabel: (child) => (
          <div className={"flex flex-row items-center gap-[8px]"}>
            <span className={"text-gray-800"}>{child?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: "/api/v1/state?filter[country_id]={country_id}",
        query: "&term={query}",
        view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: formDefaultValue?.from_state_id || null,
      execludedValues: (data) => data?.state_ids?.map((item) => item.id),
      className: "col-span-3",
      innerClassName: " bg-gray-50",
      // validation: Yup.string().required("State is required"),
    },
    {
      name: "tracking_number",
      label: "Tracking Number",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. 184701857085720",
      defaultValue: formDefaultValue?.tracking_number || null,
      className: "col-span-3",
      icon: <MapPin className="text-gray-500" />,
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("Street 1 is required"),
    },
    {
      name: "billed_cost",
      label: "Billed Cost",
      type: "number",
      reset: false,
      clear: false,
      placeholder: "ex. 7 USD",
      defaultValue: formDefaultValue?.billed_cost || null,
      className: "col-span-3",
      innerClassName: "border border-gray-300 bg-gray-50",
      optional: true,
    },
    {
      name: "label_file",
      label: "Label",
      type: "File",
      reset: false,
      clear: false,
      // placeholder: "ex. 184701857085720",
      defaultValue: formDefaultValue?.label_file || null,
      className: "col-span-6",
      innerClassName: "border border-gray-300 bg-gray-50",
      optional: true,
    },
  ];
};

// const Upload = ({ item, binded, change, data }) => {
//     const [dragging, setDragging] = useState(false);
//     const [uploading, setUploading] = useState(false);
//     const [file, setFile] = useState(null);
//     const createNewRef = useRef(false);
//     const updateCreateNew = (value) => {
//       createNewRef.current = value;
//     };
//     const handleCheckboxChange = () => {
//       updateCreateNew(!createNewRef.current);
//     };

//     const handleFileChange = (e) => {
//       uploadFile(e.target.files);
//       e.target.value = null;
//       e.target.removeEventListener("change", handleFileChange);
//       e.target.addEventListener("change", handleFileChange);
//     };

//     const handleDragOver = (e) => {
//       e.preventDefault();
//       setDragging(true);
//     };

//     const handleDragEnter = (e) => {
//       e.preventDefault();
//       setDragging(true);
//     };

//     const handleDragLeave = (e) => {
//       e.preventDefault();
//       setDragging(false);
//     };

//     const handleDrop = (e) => {
//       e.preventDefault();
//       setDragging(false);
//       const droppedFile = e.dataTransfer.files;
//       const filesArray = Array.from(droppedFile);
//       // check for the files types
//       const allowedTypes = [
//         "text/csv",
//         // excel
//         "application/vnd.ms-excel",
//         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//       ];
//       if (!filesArray.every((file) => allowedTypes.includes(file.type))) {
//         toast.info("Invalid file type");
//         return;
//       }
//       uploadFile(droppedFile);
//     };

//     const uploadFile = (file) => {
//       if (file.length === 0) return;
//       let files = Array.from(file);

//       const notifications = []; // Array to store all notifications

//       // Check for files larger than 10MB
//       const bigFiles = files.filter((file) => file.size > 10000000);
//       if (bigFiles.length > 0) {
//         bigFiles.forEach((file) => {
//           notifications.push(
//             <li className={"opacity-50"}>{file.name} is too large.</li>
//           );
//         });
//         files = files.filter((file) => file.size <= 10000000);
//       }

//       // Check for invalid file types
//       const allowedTypes = [
//         "text/csv",
//         // excel
//         "application/vnd.ms-excel",
//         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//       ];
//       const invalidFiles = files.filter(
//         (file) => !allowedTypes.includes(file.type)
//       );
//       if (invalidFiles.length > 0) {
//         invalidFiles.forEach((file) => {
//           notifications.push(
//             <li className={"opacity-50"}>{file.name} is invalid.</li>
//           );
//         });
//         files = files.filter((file) => allowedTypes.includes(file.type));
//       }

//       // Display combined notification
//       if (notifications.length > 0) {
//         toast.info(
//           <>
//             <b>File Upload Warnings:</b>
//             <ul className={"list-disc list-inside my-[5px]"}>{notifications}</ul>
//           </>
//         );
//       }

//       setFile(files[0]);

//       // handleSubmit({ listings: files[0] });
//     };

//     useEffect(() => {
//       if (file) {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           const base64 = reader.result;
//           const blob = base64ToBlob(base64);
//           if (isBase64(base64)) {
//             change(base64, "cost_list");
//           }
//         };
//         reader.readAsDataURL(file);
//       }
//     }, [file]);

//     return (
//       <>
//         <style>{`
//                         .drag-drop-area {
//                           border: 2px dashed #E5E7EB;
//                           padding: 20px;
//                           text-align: center;
//                           cursor: pointer;
//                           transition: all 0.9s ease;
//                           position: relative;
//                       }

//                       .drag-drop-area.dragging {
//                           border-color: #007bff; /* Change the color as per your design */
//                           background-color: rgba(0, 123, 255, 0.05);
//                           transition: all 0.9s ease;
//                       }

//                       .drag-drop-area.dragging:after {
//                           width: 100%;
//                           content: "Drop here";
//                           position: absolute;
//                           display: flex;
//                           justify-content: center;
//                           top: 50%;
//                           align-items: center;
//                           left: 50%;
//                           border-radius: 10px;
//                           transform: translate(-50%, -50%);
//                           color: #007bff;
//                           background-color: #ebf5ff;
//                           font-size: 24px;
//                           height: 100%;
//                           font-weight: bold;
//                           transition: background-color 0.9s ease;
//                       }

//                         `}</style>
//         <div className="flex flex-col gap-2 w-[100%] ">
//           <div className="flex flex-col items-center justify-center gap-2.5 px-4 py-2 shadow-sm">
//             {/* <span className="text-gray-900 text-sm font-medium">

//             </span> */}

//             <label htmlFor="file-upload" className="w-[100%]">
//               <div
//                 className={`flex flex-col items-center h-[65px] justify-center bg-gray-50 border-2 border-dashed border-gray-400 rounded-lg p-4  drag-drop-area ${
//                   dragging ? "dragging" : ""
//                 }`}
//                 onDragOver={handleDragOver}
//                 onDragEnter={handleDragEnter}
//                 onDragLeave={handleDragLeave}
//                 onDrop={handleDrop}
//               >
//                 <input
//                   type="file"
//                   id="file-upload"
//                   className="hidden"
//                   accept="text/csv , application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
//                   onChange={handleFileChange}
//                   disabled={uploading}
//                 />
//                 {file ? (
//                   <div className="flex flex-col gap-2 items-center">
//                     {/* <Icons.FileIcon /> */}
//                     <span className="text-md font-bold text-gray-700">
//                       <span className="text-gray-500 font-semibold text-sm">
//                         Selected File:
//                       </span>{" "}
//                       {file.name}
//                     </span>
//                   </div>
//                 ) : (
//                   <label
//                     htmlFor="file-upload"
//                     className="flex flex-col items-center gap-2 cursor-pointer text-gray-500"
//                   >
//                     <Icons.uploadSolid className="w-5 h-5" />
//                     <span className="text-gray-500 text-sm">
//                       Drag cost list to upload
//                     </span>
//                   </label>
//                 )}
//               </div>
//             </label>
//           </div>
//         </div>
//       </>
//     );
//   };

export default manualForm;
