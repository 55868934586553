import React, { memo } from "react";
import { twMerge } from "tailwind-merge";
const Row = ({data, theme, rowIndex, tableStyle}) => {
  return (
      <>
          {Object.keys(theme).map(
              (item, index) =>
                  !tableStyle?.row?.[item]?.td?.includes("hidden") && (
                      <td
                          key={index}
                          className={twMerge(` 
                         !px-0 !py-0 border-b box-border
                          ${item.startsWith("_actions") ? "actionRow" : ""}
                          ${item.startsWith("_") ? "w-[90px]" : ""}
                          ${item.startsWith("_actions") && tableStyle?.hideSelect
                                  ? "!w-[35px]"
                                  : ""
                              }
                          ${item === "status" ? "w-[90px]" : ""}
                          ${tableStyle?.row?.td ? tableStyle?.row?.td : ""}
                          
                      `, `${tableStyle?.row?.[item]?.td
                              ? tableStyle?.row?.[item]?.td
                              : ""
                          }`)}
                      >
                          <div
                              className={twMerge(
                                `
                                align-top py-[30px] text-left min-w-max whitespace-nowrap
                                ${
                                    item.startsWith("_")
                                        ? "pl-[0px] pr-[0px] w-[90px] mr-[0px] !justify-start py-[0px]"
                                        : "px-[30px]"
                                }
                                ${
                                    item.startsWith("_actions") 
                                        ? "!w-fit !max-w-[90px]"
                                        : ""
                                }
                                ${
                                    item.startsWith("*sm-")
                                        ? "px-[0px] min-w-max w-fit max-w-fit"
                                        : ""
                                }
                                ${
                                    item.startsWith("*fit-")
                                        ? "min-w-fit min-w-max w-fit max-w-fit"
                                        : ""
                                }
                                ${
                                    !item.startsWith("_")
                                        ? tableStyle?.row?.className
                                        : ""
                                }
                                ${index === 1 ? "pl-[15px]" : ""}
                            `,
                                    tableStyle?.row?.[item]?.className
                              )}
                          >
                              {theme[item](data, rowIndex)}
                          </div>
                      </td>
                  )
          )}
      </>
  );
};

export default memo(Row);

