import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
};

// Add this function to generate new notifications with the `read` property
const createNotification = (message) => ({
  ...message,
  read: false,
});

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    addNotification: (state, action) => {
      state.notifications = [
        ...state.notifications,
        createNotification(action.payload),
      ];
    },
    deleteNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    deleteAllNotifications: (state) => {
      state.notifications = [];
    },
    markAllNotificationsRead: (state) => {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        read: true,
      }));
    },
  },
});

export const {
  addNotification,
  deleteNotification,
  deleteAllNotifications,
  markAllNotificationsRead,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
