import isBase64 from "./isBase64";
import base64ToBlob from "./base64ToBlob";


const handleIsFile = (value, sendUrl) => {
    if (isBase64(value)) {
        return base64ToBlob(value)
    } else {
        if (!sendUrl) {
            return value
        } else {
            return null
        }
    }
}
export default handleIsFile;