import React from "react";

const summaryEditDescription = (summaryForm, orderItems, callBackAction, re) => {

    return [
        // {
        //     label: 'Item(s) Paid',
        //     value: calculation.itemsPaid(orderItems) || "-",
        //     theme: (value) => (<div className={"flex flex-col gap-[4px] items-start justify-end"}>{value}</div>)
        // },
        // {
        //     label: 'Total Paid',
        //     value: calculation.totalPaid(orderItems, summaryForm) || "-",
        //     theme: (value) => (<div className={"flex flex-col gap-[4px] items-start justify-end"}>{value}</div>)
        // }
        // ,
        // {
        //     label: 'Total Qty',
        //     value: calculation.totalQty(orderItems),
        //     theme: (value)=>(value? <Components.numberPriorityColor number={value} color={calculateColor(value)} className={'text-[14px]'} /> : "-")
        // },
        {
            label: 'Recipient Name',
            value: summaryForm?.customer_name,
            theme: (value) => (
                <span
                    className={`text-blue-700  text-base font-normal transition opacity-100 cursor-pointer ${!value ? "opacity-50 hover:opacity-100" : "opacity-100"}`}
                    onClick={() => {
                        //callBackAction("tab", {tab: "recipient"})
                        callBackAction("recipient", {action: "switchTab"})
                    }}>{value ? value : "Enter Recipient"}</span>
            ),

        },
        {
            label: 'Recipient Country',
            value: summaryForm?.customer_country ?
                {
                    flag: summaryForm?.customer_country?.flag,
                    code: summaryForm?.customer_country?.code
                } : null,
            theme: (value) => (
                <div className='flex items-center flex-row gap-[4px]'>
                    {value?.flag ?
                        <img src={value?.flag} alt='' className='w-[28px] h-[20px] rounded-[2px] object-cover'/> : null}
                    <span>{value?.code ? value?.code : "-"}</span>
                </div>
            )
        }
    ]


}

export default summaryEditDescription