import * as Yup from "yup";
import upperCaseString from "../../dynamic-page/util/upperCaseString";

const formView = (item) => {

    const innerSubmit =
        [
            {
                label: "Save",
                type: "primary",
                action: "save",
            },

        ]

    return ({
        title: upperCaseString(item?.id ? "Edit" : "Add") + " Currency",
        submit: innerSubmit,
        // close: !item?.id,
        // cancel: !item?.id ? false : true,
        viewModel: (formDefaultValue) => {
            return [
                {
                    name: "name",
                    label: "Name",
                    placeholder: "Dollar",
                    type: "Input",
                    defaultValue: formDefaultValue?.name || null,
                    validation: Yup.string().required('Name is required'),
                    className: "col-span-1",
                },
                {
                    name: "code",
                    label: "Code",
                    placeholder: "USD",
                    type: "Input",
                    className: "col-span-1",
                    defaultValue: formDefaultValue?.code || null,
                    disabled: formDefaultValue?.ucode ? true : false,
                    validation: Yup.string().required('Code is required'),
                },
                {
                    name: "symbol",
                    label: "Symbol",
                    placeholder: "$",
                    type: "Input",
                    defaultValue: formDefaultValue?.symbol || null,
                    validation: Yup.string().required('Symbol is required'),
                    className: "col-span-1",
                },
                {
                    name: "position",
                    label: "Position",
                    placeholder: "Select Position",
                    type: "Select",
                    list: [
                        {label: "Left", value: "left"},
                        {label: "Right", value: "right"},
                    ],
                    className: "col-span-1",
                    defaultValue: formDefaultValue?.type || null,
                },
                {
                    name: "note",
                    label: "Note",
                    placeholder: "Enter note here...",
                    type: "Textarea",
                    defaultValue: formDefaultValue?.note || null,
                    rows: 3,
                    className: "col-span-2",
                },
            ]
        }
    });
};
export default formView;
  
