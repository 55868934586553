import React from "react";
import icons from "../../../orders/icons";
import {HiExclamationCircle, HiEyeOff} from "react-icons/hi";
import Util from "../../../dynamic-page/util";
import util from "../../../dynamic-page/util";
import StatusHistory from "./statusHistory";



const OrderStatus = ({
                         item,
                         color = "gray",
                         text,
                         callBack,
                         orderId,
                         refund_amount,
                         type,
                         order,
                         isAwaiting,
                         statusId: status
                     }) => {
    const disableCheck = (item) => {
        return false;
        if (!item?.product_connected) {
            return true;
        } else {
            let flag = false;
            item?.order?.order_items?.forEach((orderItem) => {
                if (
                    orderItem?.arbitbox_product?.fbm_actual_weight === null ||
                    orderItem?.arbitbox_product?.fbm_dimensional_weight === null
                ) {
                    flag = true;
                }
            });
            return flag;
        }
    };

    const generateStatusIcon = (
        iconColor,
        iconComponent,
        tooltip,
        border,
        hideShadow
    ) => (
        <div className="w-8 h-8 justify-center items-center flex">
            <div
                className={`grow shrink ${
                    type !== "return" && type !== "notification" && !hideShadow
                        ? "shadow-md"
                        : ""
                } basis-0 self-stretch rounded-[50px] flex-col justify-center items-center inline-flex bg-${iconColor}-100 ${
                    border && "border-2 border-" + iconColor + "-700"
                }`}
            >
                {tooltip ? (
                    <Util.popUp toggle={() => iconComponent} action={"click"}>
                        {tooltip}
                    </Util.popUp>
                ) : (
                    iconComponent
                )}
            </div>
        </div>
    );

    const generateDivider = (dividerColor = "gray") => (
        <div
            className={`grow shrink shadow-md basis-0 h-1.5 bg-${dividerColor}-100`}
        />
    );

    const Wrapper = ({children}) => (
        <Util.popUp
            toggle={() => (
                <div className="w-[124px] h-8  justify-center items-start inline-flex">
                    <div className="grow shrink basis-0 h-8 justify-center items-center flex">
                        <>{children}</>
                    </div>
                </div>
            )}
            action={"hover"}
            forcePosition={"top"}
        >
            {text}
        </Util.popUp>
    );

    const primary_label = item?.order?.shipment?.shipment_labels?.find(
        (label) => label?.is_primary
    )?.tracking_number;

    const tooltip = (
        <StatusHistory
            product={item?.order?.first_item?.arbitbox_product?.id}
            isAwaiting={isAwaiting}
            id={orderId}
            primary_label={primary_label}
        />
    );
    const notificationsTooltip = <p>{text}</p>;

    const handlePrintLabel = (id) => {
        if (disableCheck(item)) {
            util.notifier({
                type: "info",
                message:
                    "Please fill in the FBA/FBM weight and dimensions for all products connected to the order before mark as shipped. Missing weight will block the order from being shipped.",
            });
            return;
        }
        callBack({
            action: "markAsShipped",
            data: {
                id: id,
                modal: true,
            },
        });
    };

    const firstIcon = ({item}) => {
        if (status === '1') {
            return generateStatusIcon(
                "yellow",
                <icons.inbox_arrow_down className="text-yellow-600"/>,
                tooltip
            );
        } else {
            return generateStatusIcon(
                "green",
                <icons.inbox_arrow_down className="text-green-600"/>,
                tooltip
            );
        }
    };

    const secondIcon = ({item}) => {
        if (status !== '1') {
            return generateStatusIcon(
                "green",
                <icons.archive_box className="text-green-600"/>,
                tooltip
            );
        } else {
            return generateStatusIcon(
                "gray",
                <icons.archive_box className="text-gray-600"/>,
                tooltip
            );
        }
    };

    const thirdIcon = ({item}) => {
        if (status === '3') {
            return generateStatusIcon(
                "green",
                <icons.truck2 className="text-green-600"/>,
                tooltip
            );
        } else if (status === '2') {
            return generateStatusIcon(
                 "blue",
                <icons.truck2
                    className={` ${
                       "text-blue-600 cursor-pointer"
                    } `}
                    onClick={() => handlePrintLabel(orderId)}
                />,
                null,
                true
            );
        } else  {
            return generateStatusIcon(
                "gray",
                <icons.truck2 className="text-gray-600"/>,
                tooltip
            );
        }
    };

   const statusId = 1
    const statusList = {
        //awaiting
        1: (
            <Wrapper>
                {firstIcon({item})}
                {generateDivider(status === '1' ? "gray" : "green")}
                {secondIcon({item})}
                {generateDivider(status === '1' ? "gray" : "green")}
                {thirdIcon({item})}
            </Wrapper>
        ),

        6: (
            <Wrapper>
                {generateStatusIcon(
                    "red",
                    <HiExclamationCircle className="text-red-600 w-5 h-5"/>,
                    notificationsTooltip
                )}
                {generateDivider("red")}
                {generateStatusIcon(
                    "red",
                    <icons.xMark className="text-red-600  w-3 h-3"/>,
                    notificationsTooltip
                )}
            </Wrapper>
        ),
        // shipped
        // 10: (
        //   <Wrapper>
        //     {generateStatusIcon(
        //       "green",
        //       <icons.link2 className="text-green-600" />
        //     )}
        //     {generateDivider("green")}
        //     {generateStatusIcon("green", <icons.cube className="text-green-600" />)}
        //     {generateDivider("green")}
        //     {generateStatusIcon(
        //       "green",
        //       <icons.truck2 className="text-green-600" />
        //     )}
        //   </Wrapper>
        // ),
        4: (
            <Wrapper>
                <div className=" h-8 py-1.5 bg-purple-100 rounded-[50px] justify-center items-center w-[96px] flex">
                    {generateStatusIcon(
                        "purple",
                        <HiEyeOff className="text-purple-600  w-5 h-5"/>,
                        tooltip,
                        null,
                        true
                    )}
                </div>
            </Wrapper>
        ),
        5: (
            <Wrapper>
                <div className=" h-8 py-1.5 bg-rose-100 rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "red",
                        <icons.xMark className="text-red-600  w-3 h-3"/>,
                        tooltip
                    )}
                </div>
            </Wrapper>
        ),
        7: (
            <Wrapper>
                <div className="  h-8 py-1.5 bg-rose-100 shadow rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "red",
                        <HiExclamationCircle className="text-red-600  w-5 h-5"/>,
                        notificationsTooltip
                    )}
                </div>
            </Wrapper>
        ),
        8: (
            <Wrapper>
                <div className=" h-8 py-1.5 bg-gray-100 shadow rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "gray",
                        <HiExclamationCircle className="text-gray-600  w-5 h-5"/>,
                        notificationsTooltip
                    )}
                </div>
            </Wrapper>
        ),
        2: (
            <Wrapper>
                <div
                    className=" h-8 py-1.5 bg-red-100 shadow rounded-[50px] justify-center items-center w-[96px]  flex">
                    {generateStatusIcon(
                        "red",
                        <icons.cash className="text-red-600  "/>,
                        text === "Refunded" ? tooltip : notificationsTooltip,
                        null,
                        true
                    )}
                </div>
            </Wrapper>
        ),
        3: (
            <Wrapper>
                <div
                    className=" h-8 py-1.5 bg-yellow-100 shadow rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "yellow",
                        <icons.cash className="text-yellow-600  "/>,
                        notificationsTooltip
                    )}
                </div>
            </Wrapper>
        ),
    };
    if (statusId) return statusList[statusId];
};

export default OrderStatus;
