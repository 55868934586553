import { createSlice } from "@reduxjs/toolkit";

import { fetchRepricerListings, fetchRepricerListingsFilter } from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  repricerListings: [],
  links: {},
  filters: {},
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  employees: [],
  loading: "idle",
  error: null,
  isLogin: false,
};

export const repricerListingsSlice = createSlice({
  name: "repricerListings",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepricerListings.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchRepricerListings.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.repricerListings = action.payload["data"];
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchRepricerListings.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      });
    // .addCase(updateSalesListingFulfilment.pending, (state, action) => {
    //   if (state.loading === 'idle') {
    //     state.loading = 'pending'
    //   }
    // })
    // .addCase(updateSalesListingFulfilment.fulfilled, (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle'

    //   }
    // })
  },
});

export const { updateFilter, updateBoundFilter, updateRow } =
  repricerListingsSlice.actions;

export { fetchRepricerListings, fetchRepricerListingsFilter };

export default repricerListingsSlice.reducer;
