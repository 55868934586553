import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesListing, updateRow } from "../../store/fc-restocking";
import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
// import data from "./data";
import sortView from "./modals-view/sort";
// import formView from "./modals-view/form";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActionList from "../dynamic-page/util/getActionList";

import {
  fetchRepricerListings as fetchData,
  fetchRepricerListingsFilter as fetchFilters,
  updateFilter,
} from "../../store/repricer-listings";

import { updateSalesListingFulfilment } from "../../store/sales-listings/thunk";
import UI from "../dynamic-form/elements";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import notifier from "../dynamic-page/util/notifier";
// import { updateRow , connectProduct} from "../../store/sales-listings";
import { HiPlus } from "react-icons/hi";
import ListingDetailModal from "../../components/listing-detail-modal";

const RepricerListings = ({
  product_id = null,
  displayTitle = "flex",
  displayTabGroups = "flex",
  showCreateForm = false,
}) => {
  const dispatch = useDispatch();

  // Initial page at first load.
  useEffect(() => {
    let filters = {};

    if (product_id) {
      filters = { product: product_id };
    }

    const startPageByFilters = {
      filters: filters,
      sort: null,
      page: 1,
      search: "",
      includes: "",
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters, [
        "filter[order_status]",
        "filter[containingProduct]",
        "filter[type]",
      ])
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, []);

  // Get data from store.
  const pageData = useSelector((state) => state.repricerListings);
  const tableData = useSelector(
    (state) => state.repricerListings.repricerListings
  );

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleListingModal, setVisibleListingModal] = useState(false);
  const [salesModalListingData, setModalSalesListingData] = useState(null); // State to store salesListing data

  const openListingModal = (modalListing) => {
    setModalSalesListingData(modalListing); // Set salesListing data in the state
    setVisibleListingModal(true);
  };

  // Handle form modal.
  const [calledItemInAction, setCalledItemInAction] = useState(null);
  const [formData, setFormData] = useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);
  const [refreshingDate, setRefreshingDate] = useState(null);

  // Handle order details modal.
  const [modalVisibility, setModalVisibility] = useState(false);

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    setSelectedRow([]);
    return orderId;
  };

  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };

  //   const onSubmitApply = async (data) => {
  //     if(product_id){
  //       data['productId'] = product_id;
  //     }
  //     await dispatch(connectProduct(data)).unwrap()
  //         .then(handleNotifyUser);

  //     const currentStoreFilters = pageData?.filters;
  //     const storeQuery = storeQueryBuilder(currentStoreFilters, {});
  //     const urlQuery = urlQueryBuilder(storeQuery, null, "term");

  //     dispatch(fetchData(urlQuery));
  //   };

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const actionList = {
    change_fc_eligibility: {
      label: "Change FC Eligibility",
      onClick: (row) => {
        const rowIds = row?.id ? row?.id : selectedRow.map((item) => item.id);
        //   fulfilmentHandler( 'fba', rowIds)
      },
    },
    change_wh_eligibility: {
      label: "Change WH Eligibility",
      onClick: (row) => {
        const rowIds = row?.id ? row?.id : selectedRow.map((item) => item.id);
        //   fulfilmentHandler('fbm' , rowIds)
      },
    },
    create_supply_request: {
      label: "Create Supply Request",
      onClick: (row) => {
        if (!row?.sales_channel_listing?.product) return;
        window.open(
          `/inventory/supply_requests#action=create&product=${row?.sales_channel_listing?.product?.id}`,
          "_blank"
        );
      },
      bulkDisable: true,
    },
    create_offer: {
      label: "Create Offer",
      onClick: (row) => {
        // if (!row?.sales_channel_listing?.product) { util.notifier({ message: "No Product Connected" });  return;}
        window.open(
          `/listing/offers#action=create&
      &identifier=${row?.sales_channel_listing?.identifier}
      &identifier_type=${row?.sales_channel_listing?.identifier_type}
      &account=${row?.account?.id}
      &channel=${row?.sales_channel_listing?.channel?.id}
      `,
          "_blank"
        );
      },
      bulkDisable: true,
    },
  };

  const statusList = {
    1: {
      label: "Pending",
      actions: [
        actionList.change_fc_eligibility,
        actionList.change_wh_eligibility,
        actionList.create_supply_request,
        actionList.create_offer,
      ],
    },
  };

  // handle actions of statuses
  //
  const actions = (row) => {
    // todo const innerRow = row.map((item) => { add actions_id to item }
    // const innerRow = Array.isArray(row) ? row.map((item) => {
    //   return {
    //     ...item,
    //     status: 1
    //   }
    // }) : {
    //     ...row,
    //     status: 1
    // }
    return getActionList(row, statusList);
  };

  // Handle Tabs
  const onHandleTab = (item) => {
    onFilterApply({ fulfillment: item });
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      page: page || currentStoreFilters.page || 1,
      limit: limit || currentStoreFilters.limit || 20,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onShowCreateModal = () => {
    setModalVisibility(!modalVisibility);
  };

  //   const handleCallBackActions = ({ action, data }) => {
  //     actionList[action].onClick(data);
  //   };

  const fulfilmentHandler = async (item, id, index) => {
    await dispatch(
      updateSalesListingFulfilment({
        type: item,
        payload: Array.isArray(id) ? id : [id],
      })
    )
      .then(({ payload }) => {
        if (payload?.data?.type === "success") {
          if (Array.isArray(id)) {
            onPaginationApply();
            util.notifier(payload?.data);
          } else {
            dispatch(fetchSalesListing(id)).then((rs) => {
              dispatch(
                updateRow({
                  path: `fcRestockings.[id:${id}]`,
                  value: rs.payload.data,
                })
              );
              util.notifier(payload?.data);
            });
          }
        }
      })
      .catch((error) => {
        notifier({ type: "error", content: error });
      });
  };

  // Render View of Page
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col w-full">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          displayTitle={displayTitle}
          title={"Repricer Listings"}
          // form={{
          //   callback: (rs) => {
          //     setModalVisibility(true);
          //   },
          // }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(pageData?.filters?.filters),
            callback: (rs) => {
              onFilterApply(rs);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.fulfillment === undefined ||
              pageData?.filters?.filters?.fulfillment === null
                ? 0
                : 1),
          }}
          actionList={actions(selectedRow)}
          // options={[
          //     {
          //         callBack: () => {
          //             setRefreshing(true)
          //             dispatch(syncWithMarketplace()).then((rs)=>{
          //                 util.notifier(rs.payload.data);
          //                 setRefreshing(false);
          //                 setRefreshingDate (rs.payload.data.data.latest_refresh)
          //             })
          //         },

          //         data: refreshing,
          //         view: ({callBack, data})=>(
          //             <UI.Button
          //                 item={{
          //                     label: (
          //                         <div className={`flex flex-row items-center justify-center gap-[4px] ${!data ? "px-[6px]" : ""}`}>
          //                             <div className={"flip-x transform scale-x-[-1]"}>
          //                                 <FiRefreshCw className={`h-5 w-5 ${data? "animate-spin rotate-[reverse]" : ""}`}/>
          //                             </div>
          //                             {!data && <TimeAgo timestamp={refreshingDate} /> }

          //                         </div>
          //                     ),
          //                     buttonType: "icon",
          //                     className: "!w-auto min-w-[34px] ",
          //                 }}
          //                 onClick={()=>{ if (!data) { callBack() } }}
          //             />
          //         )
          //     }
          // ]}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.fulfillment}
            list={[
              {
                id: "DEFAULT",
                name: "Listings",
                count:
                  pageData?.filtersMeta?.data?.fulfilments?.find(
                    (item) => item?.name === "FBM"
                  )?.count ?? 0,
              },
              {
                id: "AMAZON_NA",
                name: "Offers",
                count:
                  pageData?.filtersMeta?.data?.fulfilments?.find(
                    (item) => item?.name === "FBA"
                  )?.count ?? 0,
              },
              {
                id: "AMAZON_NA",
                name: "Uploads",
                count:
                  pageData?.filtersMeta?.data?.fulfilments?.find(
                    (item) => item?.name === "FBA"
                  )?.count ?? 0,
              },
            ]}
            allCount={
              pageData?.filtersMeta?.data?.data?.fulfilments?.reduce(
                (a, b) => a + (b?.count || 0),
                0
              ) ?? 0
            }
            allTab={false}
          />
          {showCreateForm && (
            <UI.Button
              item={{
                label: <HiPlus className="h-6 w-6" />,
                buttonType: "icon",
                className: "order-[0]",
              }}
              onClick={onShowCreateModal}
            />
          )}
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(
                  pageData?.repricerListings,
                  fulfilmentHandler,
                  openListingModal

                  //   fulfilmentHandler
                  // data
                )}
                actions={actions}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                loading={pageData.loading}
                style={{
                  table: {
                    style: { width: "100%", minWidth: "100%" },
                  },
                  header: {
                    className: "justify-center !px-[16px] ",
                    "tracking id": { className: "!pr-[30px]" },
                    image: { className: "hidden" },
                    type: { className: "!py-[0px] hidden" },
                  },
                  row: {
                    className:
                      "!p-[0px] !items-center !justify-center flex text-[12px]  ",
                    // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                    description: {
                      className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                    },
                    _actions: { className: "!py-[0px]" },
                    "Listing sales": { className: "!py-[0px]" },
                  },
                  hiddenActions: true,
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          onPrevious={onHandlePrevious}
          onNext={onHandleNext}
          onPage={onHandlePage}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {/* {modalVisibility && (
        <FormModal
          title={"Sales Channel Listing"}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
          }}
          onSubmit={onSubmitApply}
        />
      )} */}

      {visibleListingModal && (
        <ListingDetailModal
          show={visibleListingModal}
          onClose={() => setVisibleListingModal(false)}
          modalListing={salesModalListingData}
          fulfilmentHandler={fulfilmentHandler}
        />
      )}
    </>
  );
};

export default RepricerListings;
