import * as Yup from "yup";
import moment from "moment/moment";
import upperCase from "../../../../../dynamic-page/util/upperCaseString";

const formView = (data, type) => {
  return  type === 'order' ?
  [
    {
      name: "name",
      label: "Transfer name",
      placeholder: "Enter name",
      type: "Input",
      defaultValue: data?.name || null, //|| data?.order_number || null,
      validation: Yup.string().required('Name is required'),
      innerClassName: " h-[36px] bg-[rgba(0,0,0,0.03)]",
      clear: false,
      reset: false,
    },

    {
      name: "destination",
      label: "Transfer to",
      placeholder: "Select the destination",
      type: "Select",
      // innerClassName: "flex flex-col ",
      api: {
        url: "/api/v1/warehouses?include=state",
        skipCancel: true,
        url2: "/api/v1/pickup-points?include=state",
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        optionValue: (item) => ({
          // value: item?.id,
          type: "warehouse",
          value: upperCase(item?.name, "word", true) + `id:${item?.id}`,
          label: upperCase(item?.name, "word", true),
        }),
        optionValue2: (item) => ({
          type: "pickup_point",
          value: upperCase(item?.name, "word", true) + `id:${item?.id}`,
          label: upperCase(item?.name, "word", true),
        }),
      },
      style: {
        control: {
          minHeight: "auto !important",
          background: "rgba(0,0,0,0.03)",
          height: "36px",
          paddingTop: "0px",
          paddingBottom: "0px"
        },
        menu: {
          whiteSpace: 'wrap !important',
        },
      },
      clear: false,
      reset: false,
      defaultValue: data?.destination || null, //|| data?.sales_channel?.id || null,
      validation: Yup.string().required('Sales channel is required'),
    },

  ] : [
    {
      name: "name",
      label: "Transfer name",
      placeholder: "Enter name",
      type: "Input",
      defaultValue: data?.name || null, //|| data?.order_number || null,
      validation: Yup.string().required('Name is required'),
      innerClassName: " h-[36px] bg-[rgba(0,0,0,0.03)]",
      clear: false,
      reset: false,
    },
    {
      label: "Transfer from",
      name: "from_warehouse_id",
      type: "Select",
      api: {
        url: "/api/v1/warehouses",
        //url: '/api/v1/warehouses',
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCase(item?.name, "word", true),
        }),
      },
      // className: "h-[42px]",
      style: {
        control: {
          minHeight: "auto !important",
          background: "rgba(0,0,0,0.03)",
          height: "36px",
          paddingTop: "0px",
          paddingBottom: "0px"
        },
        menu: {
          whiteSpace: 'wrap !important',
        },
      },
      defaultValue: data?.from_warehouse_id || null,
      reset: false,
      clear: false,
  
      placeholder: "Select Warehouse",
    },
    {
      name: "destination",
      label: "Transfer to",
      placeholder: "Select the destination",
      type: "Select",
      // innerClassName: "flex flex-col ",
      api: {
        url: "/api/v1/warehouses?include=state",
        skipCancel: true,
        url2: "/api/v1/pickup-points?include=state",
        query: "&filter[name]={query}",
        view: "/api/v1/warehouses",
        optionValue: (item) => ({
          // value: item?.id,
          type: "warehouse",
          value: upperCase(item?.name, "word", true) + `id:${item?.id}`,
          label: upperCase(item?.name, "word", true),
        }),
        optionValue2: (item) => ({
          type: "pickup_point",
          value: upperCase(item?.name, "word", true) + `id:${item?.id}`,
          label: upperCase(item?.name, "word", true),
        }),
      },
      style: {
        control: {
          minHeight: "auto !important",
          background: "rgba(0,0,0,0.03)",
          height: "36px",
          paddingTop: "0px",
          paddingBottom: "0px"
        },
        menu: {
          whiteSpace: 'wrap !important',
        },
      },
      clear: false,
      reset: false,
      defaultValue: data?.destination || null, //|| data?.sales_channel?.id || null,
      validation: Yup.string().required('Sales channel is required'),
    },
  ]
}

export default formView;