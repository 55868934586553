import { HiTrash } from "react-icons/hi";
import descriptiveContent from "../../../utils/descriptiveContent";
import MainModal from "../../dynamic-page/MainModal";
import { useState } from "react";

export default function StatesModal({ data, onClose, onDelete }) {

    const [states , setStates] = useState(data?.states);

    return (
        <MainModal
            headerClassName="!py-2.5 !px-5 border-b border-gray-200"
            containerClassName="!py-2 !px-5 !w-[480px]"
            item={{
                title: "States",
                submit: () => { },
                cancel: onClose,
                view: () => (
                    <div className="h-[120px] flex items-start flex-wrap gap-1 space-y-1 overflow-y-auto">
                        {states.map((item, index) => (
                            <div key={item?.id} className="h-[34px] !mt-0 p-2 gap-3 bg-gray-50 rounded-lg justify-between items-center inline-flex">
                            <div className="justify-center items-center gap-2.5 flex">
                              <img src={item?.country?.flag} className="w-[25.2px] h-[18px] rounded-sm" />
                              <div className="text-[#111928] text-[12px] leading-[18px] font-normal font-['Inter'] whitespace-nowrap">
                                {item.name}
                              </div>
                            </div>
                                {descriptiveContent(<HiTrash className="w-3 h-3 text-gray-400 hover:text-red-400   cursor-pointer" onClick={() => {
                                    onDelete(data, item?.id, setStates)
                                }
                                } /> , 'Delete state')}
                          </div>
                        ))}
                    </div>
                ),
            }}
                    
        />
    )
}