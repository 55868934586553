import axios from "./../../middleware/axios";
import constants from "../../constant";


const fetchBankAccounts = async (params) => {
    let url = constants.APIURL.GET_BANK_ACCOUNTS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
};

const searchBankAccounts = async (params) => {
    let url = constants.APIURL.GET_BANK_ACCOUNTS + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

// const updateBankAccounts = async ({ payload }) => {
// const response = await axios.post(constants.APIURL.GET_BANK_ACCOUNTS_UPDATE, apiutils.obj2Form(payload), { withCredentials: true })
// return response
// }

const fetchBankAccountsFilters = async (params) => {
    let url = constants.APIURL.GET_BANK_ACCOUNTS_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
}

const updateBankAccountsStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_BULK_BANK_ACCOUNTS_STATUS, payload, {withCredentials: true})
    return response
}

const deleteBankAccount = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BANK_ACCOUNT + payload, {withCredentials: true})
    return response
}

const restoreBankAccount = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_BANK_ACCOUNTS, payload, {withCredentials: true})
    return response
}

const bulkDeleteBankAccounts = async (payload) => {
    // const response = fetch('https://api-test.arbitbox.com/' + constants.APIURL.BULK_DELETE_BANK_ACCOUNTS, {
    // method: 'DELETE',
    // headers: {
    // 'Content-Type': 'application/json',
    // 'Accept': 'application/json'
    // },
    // credentials: 'include',
    // body: JSON.stringify(payload)
    // })
    // return response
    const response = await axios.delete(constants.APIURL.BULK_DELETE_BANK_ACCOUNTS, {
        withCredentials: true,
        data: payload
    })
    return response

}

const createBankAccount = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_BANK_ACCOUNT, payload, {withCredentials: true})
    return response
}

const updateBankAccount = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_BANK_ACCOUNT + id, payload, {withCredentials: true})
    return response
}


export default {
    fetchBankAccounts,
    // updateBankAccounts,
    fetchBankAccountsFilters,
    updateBankAccountsStatus,
    deleteBankAccount,
    restoreBankAccount,
    bulkDeleteBankAccounts,
    createBankAccount,
    updateBankAccount,
    searchBankAccounts
};