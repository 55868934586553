import React, { useEffect, useState } from "react";
import { HiXCircle } from "react-icons/hi";

const SearchInput = ({
  className,
  placeholder,
  icon,
  onSubmit,
  defaultValue = "",
}) => {
  const [searchString, setSearchString] = useState(defaultValue);
  const [lastSearchString, setLastSearchString] = useState(defaultValue);

  const onSubmitSearch = (search) => {
    if (search !== lastSearchString) {
      setLastSearchString(search);
      onSubmit(search);
    }
  };
  useEffect(() => {
    setSearchString(defaultValue);
    setLastSearchString(defaultValue);
  }, [defaultValue]);

  const onInputEnter = (e) => {
    if (e.key === "Enter") {
      onSubmitSearch(e.target.value);
    }
  };

  const onInputChange = ({ target: { value } }) => {
    setSearchString(value);
  };

  const onClearSearch = () => {
    setSearchString("");
    onSubmitSearch("");
  };

  return (
    <>
      <div className={`flex ${className && className}`}>
        <div className="relative w-full h-[38px] rounded-lg border transition   bg-gray-50 border-gray-300">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            {icon ? (
              icon
            ) : (
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            )}
          </div>

          <input
            type="text"
            className={`transition ${
              searchString ? "w-[80%]" : "w-full"
            }  pl-10 block p-2.5 h-full z-20 text-sm border-none    rounded-lg   dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500`}
            placeholder={"Search"}
            onChange={(e) => onInputChange(e)}
            onKeyDown={(e) => onInputEnter(e)}
            // required
            autoComplete="off"
            value={searchString}
          />
          {searchString && (
            <HiXCircle
              className={`${
                searchString === lastSearchString
                  ? "right-3 text-blue-500 hover:text-blue-600 "
                  : "right-12 text-gray-300 hover:text-gray-400 "
              } absolute top-[50%] translate-y-[-50%]  transition cursor-pointer w-6 h-6`}
              onClick={onClearSearch}
            />
          )}
          {searchString !== lastSearchString && (
            <button
              type="button"
              className={`absolute top-0 right-0 h-full  px-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
              onClick={() => onSubmitSearch(searchString)}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchInput;
