export default function removeDecimals(number) {
    if (typeof number === "number") {
        return Math.floor(number);
    } else if (typeof number === "string") {
        const parsedNumber = parseFloat(number);
        if (!isNaN(parsedNumber)) {
            return Math.floor(parsedNumber);
        } else {
            throw new Error("Invalid numeric string input");
        }
    } else {
        throw new Error("Invalid input type. Expected number or string.");
    }
}
  