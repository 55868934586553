const FilterBuilder = (function () {
    let newFilters = {}
    let productFilters = {}
    const setLimitQuery = (value) => {
        if (value) newFilters["limit"] = value
    }

    const setLocale = (value) => {
        if (value) productFilters["locale"] = value
    }


    const getUrlParams = () => {
        let params = []
        if (newFilters["limit"])
            params.push("limit=" + newFilters["limit"])

        if (params.length)
            return "?" + params.join("&")
        return ""
    }


    const getProductUrlParams = () => {
        let params = []
        if (productFilters["locale"])
            params.push("locale=" + productFilters["locale"])
        if (params.length)
            return "?" + params.join("&")
        return ""
    }


    return {
        newFilters,
        setLimitQuery,
        setLocale,
        getUrlParams,
        getProductUrlParams
    }
})();

export default FilterBuilder