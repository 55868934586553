import Util from "../util";
import util from "../util";
import React from "react";

const StatusSteps = ({ list, tooltip }) => {
  const Button = ({ item, index, selected }) =>
    tooltip ? (
      <Util.popUp
        toggle={(selected) => (
          <div
            className={`
                            w-[32px] h-[32px] bg-gray-100 items-center justify-center flex rounded-full  relative text-gray-500 selected-${selected}
                            ${
                              item.status === "completed"
                                ? " bg-green-100 text-green-600 completedStatus"
                                : ""
                            }
                            ${
                              item.status === "missing"
                                ? " bg-yellow-100 text-yellow-600 missingStatus"
                                : ""
                            }
                            ${
                              item.action &&
                              item.status === "awaiting" &&
                              (list[index - 1]?.status === "completed" ||
                                index === 0)
                                ? " buttonStatus cursor-pointer bg-blue-100 text-blue-700 border-2 border-blue-700 hover:bg-blue-700 hover:text-white transition"
                                : ""
                            }
                            `}
          >
            {item?.label}
          </div>
        )}
        action={"click"}
        delay={700}
      >
        {tooltip}
      </Util.popUp>
    ) : (
      <div
        className={`
                w-[32px] h-[32px] bg-gray-100 items-center justify-center flex rounded-full  relative text-gray-500 selected-${selected}
                ${
                  item.status === "completed"
                    ? " bg-green-100 text-green-600 completedStatus"
                    : ""
                }
                ${
                  item.status === "missing"
                    ? " bg-yellow-100 text-yellow-600 missingStatus"
                    : ""
                }
                ${
                  item.action &&
                  item.status === "awaiting" &&
                  (list[index - 1]?.status === "completed" || index === 0)
                    ? " buttonStatus cursor-pointer bg-blue-100 text-blue-700 border-2 border-blue-700 hover:bg-blue-700 hover:text-white transition"
                    : ""
                }
            `}
      >
        {item?.label}
      </div>
    );

  const RenderList = () =>
    list.map((item, index) =>
      item.title ? (
        <util.popUp
          toggle={(selected) => (
            <Button item={item} index={index} selected={selected} />
          )}
          action={"click"}
          delay={700}
        >
          {item?.title}
        </util.popUp>
      ) : (
        <Button item={item} index={index} />
      )
    );

  return (
    <>
      <style>
        {`
                    .StatusSteps {
                        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0 2px 1px rgba(0, 0, 0, 0.04));
                        z-index: 1;
                        position: relative;
                    }
                    .StatusSteps:has(.popOver-true) {
                        z-index: 2;
                    }
                    .StatusSteps > div:not(:first-child)::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -24px !important;
                        transform: translateY(-50%);
                        display: block;
                        width: 24px;
                        height: 6px;
                        background: #F3F4F6;
                    }
                    .StatusSteps > div:has(.completedStatus):not(:first-child)::after {
                        background: #DEF7EC;
                    }
                    .StatusSteps > div:has(.missingStatus):not(:first-child)::after {
                        background: #FDF6B2;
                    }
                    .StatusSteps > div:has(.buttonStatus):not(:first-child)::after {
                        background: #E1EFFE;
                    }
                  
                `}
      </style>
      <div className={"flex flex-row px-2 gap-[24px] StatusSteps"}>
        <RenderList />
      </div>
    </>
  );
};
export default StatusSteps;
