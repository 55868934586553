import React from "react";
import util from "../../dynamic-page/util";
import moment from "moment/moment";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import ProductName from "../../dynamic-page/components/productName";
import Icons from "../../orders/icons";
import {BsFillInfoCircleFill} from "react-icons/bs";
import PopUPList from "./../componetns/popUPList";
import priceHandler from "../../../utils/priceHandler";
import shortenString from "../../dynamic-page/util/shortenString";
import descriptiveContent from "../../../utils/descriptiveContent";

const tableView = (list, hanelTableAction, tableProps) => {


    const convertedListCount = (item) => {
        if (item?.inventory_detail?.length === 0) {
            return [['No inventory details available']];
        }
        if (item?.inventory_detail) {
            return item?.inventory_detail?.map(detail => [
                detail?.name,
                `${detail?.available_quantity}`
            ]);
        }
    }

    const matchedQuantity = (id) => {
        const foundItem = tableProps?.components?.find((item) => item?.product_id === id || item?.id === id);
        return foundItem ? foundItem?.quantity : 0;
    };


    const convertedListCost = (item) => {
        if (item?.inventory_detail?.length === 0) {
            return [['No inventory details available']];
        }
        if (item?.inventory_detail) {
            return item?.inventory_detail?.map(detail => [
                detail?.name,
                `${detail?.cost != null ? '$' + priceHandler(detail?.cost) : '-'}`
            ]);
        }
    }

    const getSumOfProducts = (arr) => {
        let sum = 0;
        arr?.map((item) => {
            sum += item?.cost;
        })
        return sum;
    }

    return ({
        list: list ?? [],
        theme: {
            "apid": (item) => (
                <div className={"flex flex-col gap-[4px] justify-start w-full"}>
                    <div>
                        {item?.type === 1 ?
                            <div className="w-[104px] h-[22px] justify-start items-start inline-flex">
                                <div
                                    className="px-2.5 py-0.5 bg-cyan-100 rounded-md justify-center items-center gap-1 flex">
                                    <div className="text-center text-cyan-900 text-xs font-medium leading-[18px]">Single
                                        Product
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="w-[119px] h-[22px] justify-start items-start inline-flex">
                                <div
                                    className="px-2.5 py-0.5 bg-sky-100 rounded-md justify-center items-center gap-1 flex">
                                    <div
                                        className="text-center text-indigo-800 text-xs font-medium leading-[18px]">Grouped
                                        Product
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={"flex flex-row gap-[4px]"}>
                        <div
                            className="text-blue-700 text-xs font-normal leading-[18px] hover:text-blue-800 cursor-pointer"
                            onClick={() => window.open(`/product/${item?.id}`)}
                        >
                            {item?.apid}
                        </div>
                        <util.copyText className={"text-[12px]"} text={item?.apid} hint={false}/>
                    </div>


                </div>
            ),
            "created at": (item) => (
                <div className={"flex flex-col gap-[4px] justify-start w-full"}>
                    <util.dateDisplay item={item} date={item?.created_at} />
                </div>
            ),
            "image": (item) => (
                <div className={"w-[56px] h-[64px] max-h-[64px] justify-center items-center flex"}>
                    <ImageMagnifier src={item?.image} className={"!max-h-[64px]"}/>
                </div>
            ),
            "product": (item) => (
                <ProductName item={item} user={item?.brand?.assigned} hide={{type: true, brandFilter: true, assignFilter: true}}/>


            ),
            ...tableProps?.components && {
                quantity: (item) => (
                    <div
                        className="text-purple-700 text-2xl font-bold flex flex-row justify-end gap-[4px] items-center leading-none">
                        {matchedQuantity(item?.id)}
                    </div>
                ),
            },
            "wh inv.": (item) => (
                <div className={"flex flex-col gap-[0px] leading-none justify-start w-full"}>
                    <div
                        className={`${item?.type === 1 ? 'text-purple-700' : 'text-orange-400'} text-2xl font-bold flex flex-row justify-center gap-[4px] items-center leading-none`}>
                        {descriptiveContent(item?.inventory_count != null ? item?.inventory_count : '-', "Inventory Count")}
                        <util.popUp
                            toggle={(selected) => (
                                <BsFillInfoCircleFill className="w-[16px] h-[16px] hover:text-gray-500 text-gray-300 "/>
                            )}
                            action={"hover"}
                        >
                            <div className="flex flex-col space-y-1">
                                {(item?.type === 2 && item?.groups?.length) ?
                                    <p className="flex flex-col  text-gray-700 font-normal text-sm gap-2">
                                        created from the following:
                                        {item?.groups?.map((group) => (
                                            <div
                                                className="flex justify-between items-center p-2 shadow cursor-pointer gap-2 rounded-md"
                                                onClick={() => window.open(`/product/${group?.product?.id}`, "_blank")}
                                            >
                                            <span className="flex items-center gap-2 "><img src={group?.product?.image}
                                                                                            className="rounded-md w-5 h-5"/> {shortenString(group?.product?.title, 30, true)}
                                            </span>
                                                <span
                                                    className="p-1 bg-gray-100 rounded-md">{group?.quantity} pcs</span>
                                            </div>
                                        ))}
                                    </p> : ""}
                                <PopUPList list={convertedListCount(item)}/>
                            </div>

                        </util.popUp>

                    </div>
                    <div
                        className="text-purple-700  text-base font-medium flex flex-row justify-center gap-[4px] items-center leading-none">
                        {descriptiveContent((getSumOfProducts(item?.inventory_detail) !== 0) ? '$' + priceHandler(getSumOfProducts(item?.inventory_detail)) : '-', "Inventory Cost")}
                        <util.popUp
                            toggle={(selected) => (
                                <BsFillInfoCircleFill className="w-[12px] hover:text-gray-500  h-[12px] text-gray-300 "/>
                            )}
                            action={"hover"}
                        >
                            <>
                                <PopUPList list={convertedListCost(item)}/>
                            </>

                        </util.popUp>

                    </div>
                    {/* <div className="text-purple-700 text-base font-medium flex flex-row justify-end gap-[4px] items-center leading-none"> - </div> */}

                </div>
            ),
            "avg. cost": (item) => (
                <div className={"flex flex-col gap-[0px] justify-start w-full "}>
                    <div
                        className="text-pink-700   text-2xl font-bold leading-normal  flex flex-row justify-center gap-[4px] items-center">{descriptiveContent(item?.average_cost_usd != null ? '$' + (priceHandler(item?.average_cost_usd)) : '-', "Average Inventory Cost")}
                    </div>
                    <div
                        className="text-pink-700 text-base font-medium leading-none  flex flex-row justify-center gap-[4px] items-center">
                        {descriptiveContent(item?.average_cost_try != null ? (priceHandler(item?.average_cost_try)) + '₺' : '-', "Average Inventory Cost")}
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none">
                            <path fill="#D1D5DB" fillRule="evenodd"
                                  d="M7.2 4.238a.6.6 0 1 1 0-1.2h3a.6.6 0 0 1 .6.6v3a.6.6 0 1 1-1.2 0V5.087L7.024 7.663a.6.6 0 0 1-.848 0L4.8 6.287 2.224 8.863a.6.6 0 0 1-.848-.849l3-3a.6.6 0 0 1 .848 0L6.6 6.39l2.152-2.152H7.2Z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                </div>
            ),
            "weight": (item) => (
                <div className={"flex flex-row gap-[6px] items-center justify-center"}>

                    <div className={"flex flex-col gap-[2px] justify-start w-full"}>
                        <div className="text-blue-400 text-xs font-normal leading-3">FBM</div>
                        <div className={"flex flex-row gap-[2px]  items-center text-blue-700"}>
                            {descriptiveContent((item?.fbm_actual_weight != null || item?.fbm_dimensional_weight ? (
                                <>
                                    {item?.fbm_dimensional_weight >= item?.fbm_actual_weight ?
                                        <Icons.weightBox className="h-[16px]"/> :
                                        <Icons.weightTarazo className={"h-[16px]"}/>}
                                    <div className="text-blue-700 text-2xl font-bold leading-normal">
                                        {(item?.fbm_dimensional_weight >= item?.fbm_actual_weight ? item?.fbm_dimensional_weight : item?.fbm_actual_weight)}
                                    </div>
                                </>
                            ) : (
                                <div className="text-blue-700 text-2xl font-bold leading-normal">-</div>
                            )), "FBM Weight")}
                        </div>

                    </div>
                    <div className="flex flex-col gap-[2px] justify-start w-full">
                        <div className="text-orange-400 text-xs font-normal leading-3">FBA</div>
                        <div className="flex flex-row gap-[2px] items-center text-orange-700">
                            {descriptiveContent(item?.fba_actual_weight != null || item?.fba_dimensional_weight ? (
                                <>
                                    {item?.fba_dimensional_weight >= item?.fba_actual_weight ?
                                        <Icons.weightBox className="h-[16px]"/> :
                                        <Icons.weightTarazo className={"h-[16px]"}/>}
                                    <div className="text-orange-700 text-2xl font-bold leading-normal">
                                        {(item?.fba_actual_weight >= item?.fba_dimensional_weight ? item?.fba_actual_weight : item?.fba_dimensional_weight)}
                                    </div>
                                </>
                            ) : (
                                <div className="text-orange-700 text-2xl font-bold leading-normal">-</div>
                            ), "FBA Weight")}
                        </div>
                    </div>


                </div>
            )
        }
    })
}
export default tableView;

