import axios from '../../middleware/axios';
import constants from '../../constant';

const fetchPositions = async (params) => {
    let url = constants.APIURL.GET_POSITIONS
    if (params) {
        url = url.concat(params)
    }
    const response = await axios.get(url, {withCredentials: true})
    //console.log(response, 'positions----')
    return response
}

export default {fetchPositions}