import { createSlice } from "@reduxjs/toolkit";

import {
  fetchLoginUser,
  fetchRegisterUser,
  fetchUserwithToken,
  postUserLogout,
} from "./thunk";
import constants from "./../../constant/index";

const initialState = {
  user: {},
  loading: "idle",
  isLogging: "idle",
  isRegistering: "idle",
  error: null,
  isLogin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    signout: (state, action) => {
      return { ...state, user: {}, isLogin: false };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginUser.pending, (state, action) => {
        if (state.isLogging === "idle") {
          state.isLogging = "pending";
          state.isLogin = false;
        }
      })
      .addCase(fetchLoginUser.fulfilled, (state, action) => {
        //console.log(action, 'Fetching user')
        if (state.isLogging === "pending") {
          state.isLogging = "idle";
          let data = action.payload;

          if (data?.data?.message !== "success") {
            state.error = data?.data?.message;

            state.isLogin = false;
            return;
          }
          state.isLogin = true;
          // state.user = data.data.user
        }
      })
      .addCase(fetchLoginUser.rejected, (state, action) => {
        if (state.isLogging === "pending") {
          state.isLogging = "idle";
          state.isLogin = false;
          state.error = action.error;
        }
      })
      .addCase(fetchRegisterUser.pending, (state, action) => {
        if (state.isRegistering === "idle") {
          state.isRegistering = "pending";
          state.isLogin = false;
        }
      })
      .addCase(fetchRegisterUser.fulfilled, (state, action) => {
        if (state.isRegistering === "pending") {
          state.isRegistering = "idle";
          let data = action.payload;
          if (!data?.id) {
            state.error = constants.MESSAGE.REGISTER_FAILED;
            state.isLogin = false;
          }
        }
      })
      .addCase(fetchRegisterUser.rejected, (state, action) => {
        if (state.isRegistering === "pending") {
          state.isRegistering = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchUserwithToken.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.isLogin = false;
        }
      })
      .addCase(fetchUserwithToken.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          let data = action.payload;
          state.user = data;
          state.isLogin = true;
        }
      })
      .addCase(fetchUserwithToken.rejected, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.isLogin = false;
        }
      })
      .addCase(postUserLogout.pending, (state, action) => {
        state.isLogin = false;
        state.user = {};
      })
      .addCase(postUserLogout.fulfilled, (state, action) => {})
      .addCase(postUserLogout.rejected, (state, action) => {});
  },
});

export const { signout } = authSlice.actions;

export {
  fetchLoginUser,
  fetchRegisterUser,
  fetchUserwithToken,
  postUserLogout,
};

export default authSlice.reducer;
