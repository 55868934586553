import React from 'react'
import VirtualizedSelect from 'react-select'

// import 'react-Select.js/dist/react-Select.js.css'
// import 'react-virtualized/styles.css'
// import 'react-virtualized-Select.js/styles.css'

const SearchableInput = ({options, data, setData, placeholder, dafaultOption}) => {


    // options attribute takes an array of objects with the following structure 
    // { label: 'option name', value: 'option value' }
    // value attribute takes a string

    return (
        <VirtualizedSelect
            options={options}
            onChange={(data) => {
                setData(data.value)
            }}
            placeholder={placeholder}
            defaultValue={options[dafaultOption]}
            // value={data}
        />
    )
}

export default SearchableInput