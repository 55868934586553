import React from 'react';
import Icons from "../../icons";


const statusClassName = "flex justify-center w-fit h-[22px] py-[2px] px-[10px] rounded-[6px] items-center gap-[6px] text-[12px] font-[500] "
const OrderItemStatus = ({statusId, color = "gray", text}) => {
    const statusList = {

        1: // AWAITING_SHIPMENT
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-600 `}>
                <Icons.clock className={"w-[12px]"}/>
                {text}
            </div>,
        2: // READY_TO_SHIP
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-600 `}>
                <Icons.clockReady className={"w-[12px]"}/>
                {text}
            </div>,
        3: // SUPPLY_REQUIRED
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-600 `}>
                <Icons.exclamation className={"w-[12px]"}/>
                {text}
            </div>,
        4: // AWAITING_ACCEPTANCE
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-600 `}>
                <Icons.clock className={"w-[12px]"}/>
                {text}
            </div>,
        5: // CANCELLED_BY_BUYER
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-800 `}>
                <Icons.xMark className={"w-[9px]"}/>
                {text}
            </div>,
        6: // REFUNDED
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-800 `}>
                <Icons.backspace className={"w-[13px]"}/>
                {text}
            </div>,
        7: // CANCEL_REQUESTED
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-800 `}>
                <Icons.clockReady className={`w-[12px] text-${color}-700`}/>
                {text}
            </div>,

        8: // AWAITING_CONNECTION
            <div className={`${statusClassName}  bg-${color}-100 text-${color}-800 `}>
                <Icons.link className={"w-[12px]"}/>
                {text}
            </div>,


    }

    if (statusId) return (statusList[statusId]);
}

export default OrderItemStatus;