import React from 'react';
import {useDispatch} from "react-redux";
import RenderContent from './render-tab-content';

const TabContent = ({activeTab, modalListing, fulfilmentHandler}) => {
    const productList = modalListing?.sales_channel_listing;
    const dispatch = useDispatch();

// Render content based on activeTab
    switch (activeTab) {
        case 'Listing':
            return <RenderContent.Listing modalListing={modalListing} fulfilmentHandler={fulfilmentHandler}/>;
        case 'Product':
            return <RenderContent.Product productList={[productList]} dispatch={dispatch} modalListing={modalListing}/>;
        case 'Sales':
            return <RenderContent.Sales modalListing={modalListing}/>;
        case 'Fulfillment Center (FBA)':
            return <RenderContent.Fba modalListing={modalListing} fulfilmentHandler={fulfilmentHandler}/>;
        case 'Warehouse (FBM)':
            return <RenderContent.Fbm modalListing={modalListing}/>;
        case 'Listing Offers':
            return <RenderContent.ListingOffers modalListing={modalListing} fulfilmentHandler={fulfilmentHandler}/>;
        case 'Your Offers':
            return <RenderContent.YourOffers modalListing={modalListing}/>;
        default:
            return null;
    }
};

export default TabContent;