import axios from './../../middleware/axios';

const fetchReferralFees = async (params) => {
    let url = 'api/v1/referral-fees'
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}


const fetchReferralFeesFilters = async (params) => {
    let url = 'api/v1/referral-fees/filters'
    if (params)
        url = url.concat(params) + '&filter[status]='
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}


export default {
    fetchReferralFees,
    fetchReferralFeesFilters
}