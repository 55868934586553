// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./modals-view/table";
import sortView from "./modals-view/sort";
import actionsView from "./modals-view/actions";
import addForm from "./modals-view/form";
import optionsView from "./modals-view/options";
import FormView from "./modals-view/assignModal";
import FiltersView from "./modals-view/filters";

// Main Component
const Brands = () => {
    // Initial Page
    const Page = usePage({
        pageTitle: "Brands",
        api: {
            fetch: "brands",
            query: "include=assigned",
        },
        searchType: "filter",
        ModelsView: {
            table: tableView,
            filter: FiltersView,
            sort: sortView,
            //   tabs: () => {},
            actions: actionsView,
            headerOption: optionsView,
            forms: {
                add: addForm,
                assign: FormView,
            },
        },
        pageSettings: {
            rowStyles: {
                "created by": {
                    td: "w-[100%]",
                    className: "w-[100%] !justify-start",
                },
            },
            headerStyles: {
                "created by": {
                    className: "w-[100%] !justify-start",
                },
            },
        },
    });

    // return View
    return Page;
};

export default Brands;
