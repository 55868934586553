import orderStatus from "./table/orderStatus";
import StatusHistory from "./table/statusHistory";
import orderId from "./table/orderid";
import Weight from "./table/weight";
import InternalNote from "./table/internalNote";
import ShippingContent from "./table/shippingContent";
import OrderRowAction from "./table/orderRowAction";
import shipmentLabelList from "./table/shipmentLabelList";
import shipmentLabel from "./table/shipmentLabel";
export default {
    orderStatus,
    StatusHistory,
    orderId,
    Weight,
    InternalNote,
    ShippingContent,
    OrderRowAction,
    shipmentLabelList,
    shipmentLabel
};
