import upperCaseString from "../../../../../dynamic-page/util/upperCaseString";
import React from "react";
import Icons from "../../../../icons";
import SingleGroupProductBadge from "../../../../../dynamic-page/components/SingleGroupProductBadge";

const addProductView = (data, type) => {
    let items = []
    if (type === 1 || !type) {
        items = [{
            name: "shipment",
            label: "Quick search ",
            type: "Select3",
            api: {
                url: '/api/v1/orders/shipment-labels',
                query: "?term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: item?.order?.id,
                    data: item
                })
            },
            defaultValue: data?._data?.shipment || null,
            disablePreload: true,
            reset: false,
            clear: false,
            className: "w-full ",
            features: {
                formatOptionLabel: ({label, data}) => {
                    return <div className={"flex flex-row gap-[8px] items-center min-h-[25px]"}>
                        <span
                            className={" min-w-[45px] gap-1 overflow-hidden flex flex-row justify-center items-center "}>
                            {data?.tracking?.number}
                            <span className="flex justify-center items-center">
                            {data?.image ?
                                <img className={"w-[20px] h-[20px] rounded-md "} src={data?.carrier?.logo}/>
                                :
                                <img className={"w-[20px] h-[20px] rounded-md"} src={"/assets/images/noImage.png"}/>
                            }
                                {data?.carrier?.name}
                            </span>
                            {data?.order?.id}
                        </span>
                    </div>
                }
            },
            style: {
                control: {
                    height: "40px !important",
                    minHeight: "auto",
                    background: "rgba(0,0,0,0.03)"
                },
                menu: {
                    whiteSpace: 'wrap !important ',
                    zIndex: 9999
                },
                option: {
                    padding: "4px 8px !important",
                },
            },
            placeholder: (<div className={"flex flex-row gap-[8px] items-center text-[14px]"}><Icons.shipmentDetail
                className={"w-[16px] h-[16px]"}/>Quick search on Shipments</div>),
        }]
    }
    if (type === 2) {
        items = [{
            name: "product",
            label: "Quick search on products",
            type: "Select3",
            api: {
                url: '/api/v1/product',
                query: "?term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: upperCaseString(item?.title, "word", true),
                    data: item
                })
            },
            defaultValue: data?._data?.product || null,
            disablePreload: true,
            reset: false,
            clear: false,
            className: "w-full ",
            features: {
                formatOptionLabel: ({label, data}) => {
                    return <div className={"flex flex-row gap-[8px] items-center min-h-[25px]"}>
                        <span
                            className={" min-w-[45px] gap-1 overflow-hidden flex flex-row justify-center items-center "}>
                            <SingleGroupProductBadge product={data}/>
                            {data?.image ?
                                <img className={"w-[20px] h-[20px] rounded-md "} src={data?.image}/>
                                :
                                <img className={"w-[20px] h-[20px] rounded-md"} src={"/assets/images/noImage.png"}/>
                            }
                        </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                }
            },
            style: {
                control: {
                    height: "40px !important",
                    minHeight: "auto",
                    background: "rgba(0,0,0,0.03)"
                },
                menu: {
                    whiteSpace: 'wrap !important ',
                    zIndex: 9999
                },
                option: {
                    padding: "4px 8px !important",
                },
            },
            placeholder: (<div className={"flex flex-row gap-[8px] items-center text-[14px]"}><Icons.shipmentDetail
                className={"w-[16px] h-[16px]"}/>Quick search on products</div>),
        }]
    }
    if (type === 3) {
        items = [{
            name: "offer",
            label: "Quick search ",
            type: "Select3",
            api: {
                url: '/api/v1/offers',
                query: "?term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: item?.title,
                    data: item
                })
            },
            features: {
                formatOptionLabel: ({label, data}) => {
                    return <div className={"flex flex-row gap-[8px] items-center min-h-[25px]"}>
                        <span
                            className={" min-w-[45px] gap-1 overflow-hidden flex flex-row justify-center items-center "}>
                            {data?.image ?
                                <img className={"w-[20px] h-[20px] rounded-md "} src={data?.image}/>
                                :
                                <img className={"w-[20px] h-[20px] rounded-md"} src={"/assets/images/noImage.png"}/>
                            }
                        </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                }
            },
            defaultValue: data?._data?.offer || null,
            disablePreload: true,
            reset: false,
            clear: false,
            className: "w-full ",
            style: {
                control: {
                    height: "40px !important",
                    minHeight: "auto",
                    background: "rgba(0,0,0,0.03)"
                },
                menu: {
                    whiteSpace: 'wrap !important ',
                    zIndex: 9999
                },
                option: {
                    padding: "4px 8px !important",
                },
            },
            placeholder: (<div className={"flex flex-row gap-[8px] items-center text-[14px]"}><Icons.shipmentDetail
                className={"w-[16px] h-[16px]"}/>Quick search on Offers</div>),
        }]
    }

    return items
}


export default addProductView