import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchPayments = createAsyncThunk(
    "payments/fetchPayments",
    async (params) => {
        const response = await api.fetchPayments(params);
        return response?.data;
    }
);

const deletePayment = createAsyncThunk(
    "payments/deletePayment",
    async (payload) => {
        const response = await api.deletePayment(payload);
        return response?.data;
    }
);

const createPayment = createAsyncThunk(
    "payments/createPayment",
    async (payload) => {
        const response = await api.createPayment(payload);
        return response;
    }
);

const updatePayment = createAsyncThunk(
    "payments/updatePayment",
    async ({id, payload}) => {
        const response = await api.updatePayment({id, payload});
        return response;
    }
);

const bulkDeletePayments = createAsyncThunk(
    "payments/bulkDeletePayments",
    async (payload) => {
        const response = await api.bulkDeletePayments(payload);
        return response;
    }
);

const bulkRestorePayments = createAsyncThunk(
    "payments/bulkRestorePayments",
    async (payload) => {
        const response = await api.bulkRestorePayments(payload);
        return response;
    }
);

const bulkUpdatePaymentsStatus = createAsyncThunk(
    "payments/bulkUpdatePaymentsStatus",
    async (payload) => {
        const response = await api.bulkUpdatePaymentsStatus(payload);
        return response;
    }
);

const fetchPaymentsFilters = createAsyncThunk(
    "payments/fetchPaymentsFilters",
    async (params) => {
        const response = await api.fetchPaymentsFilters(params);
        return response;
    }
);

export {
    fetchPayments,
    deletePayment,
    createPayment,
    updatePayment,
    bulkDeletePayments,
    bulkRestorePayments,
    bulkUpdatePaymentsStatus,
    fetchPaymentsFilters,
};

