import React, { forwardRef, memo, useEffect, useState } from "react";
import Box from "../../../../../dynamic-page/elements/Box";
import SummaryView from "./summaryView";
import SummaryEdit from "./summaryEdit";

const SummaryLeftSide = memo(
  forwardRef((props, ref) => {
    const {
      order,
      id,
      isView,
      update,
      callBack,
      orderItems,
      summaryForm,
      actionCallBack,
      selectedOrder,
      type
    } = props;

    const [isEdit, setIsEdit] = useState(true);
    const [summaryData, setSummaryData] = useState(summaryForm);

    useEffect(() => {
      setSummaryData(summaryForm);
    }, [summaryForm]);

    useEffect(() => {
      if (isView) setIsEdit(false);
    }, [isView]);

    const changeForm = (form, bind) => {
      if (isView) {
        const newData = {
          ...summaryForm,
          ...form,
          _data: { ...summaryForm?._data, ...bind },
        };
        setSummaryData(newData);
      } else {
        callBack(form, bind);
      }
    };

    const handleSave = async (value, type) => {
      if (!isView) {
        callBack({
          [type]: value,
        })
        return
      }
      else {
        actionCallBack({
          [type] : value,
        }, { action: "update" })
      }
    };

    return (
      <Box
        // title={isView ? null : "New Order Details"}
        className="!min-h-fit !h-fit"
      >
        <style>
          {`
                    .css-1u9des2-indicatorSeparator {display: none; }
                    .css-1xc3v61-indicatorContainer {padding-left: 0px; padding-right:6px }
                    .css-1xc3v61-indicatorContainer {padding-right: 12px;}
                    .css-1xc3v61-indicatorContainer svg {color:transparent !important; background: url('/assets/images/arrow.svg'); width:12px; height:12px; opacity:0.7}
                    input[type="date"]::before { opacity:1; right:12px; background: url('/assets/images/arrow.svg') no-repeat center !important; width: 12px !important; height:12px !important; opacity:0.7 !important; border:none !important; right:12px !important }
                    input[type="date"]::-webkit-datetime-edit-month-field { opacity:0.4 !important; }
                    input[type="date"].hasValue::-webkit-datetime-edit-month-field { opacity:1 !important; }
                    
                    input[type="date"]::-webkit-datetime-edit-text { opacity:0.4 !important; }
                    input[type="date"]::-webkit-datetime-edit-text { opacity:1 !important; }
                    
                `}
        </style>
        {/*if isView is true, render SummaryView, else render SummaryEdit*/}
        {/* {isView ? ( */}
        <div className={`flex flex-col  ${
          summaryForm?.status_id === "1" ? "min-h-[342px]" : "min-h-[586px]"
        }`}>
          
        {!isView && <SummaryEdit
            ref={ref}
            callBack={changeForm}
            order={order}
            actionCallBack={actionCallBack}
            orderItems={orderItems}
            summaryForm={summaryData}
            isView={isView}
            type={type}
          />}

            <SummaryView
              id={id}
              orderItems={orderItems}
            selectedOrder={selectedOrder}
            actionCallBack={actionCallBack}
              summaryForm={summaryData}
              callBack={callBack}
            isView={isView}
            type={type}
              handleSave={handleSave}
            />
          </div>
        {/* ) : (
          //summaryData &&
          
        )} */}
      </Box>
    );
  })
);

export default SummaryLeftSide;
