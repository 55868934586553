import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchTargetSellerFolder = createAsyncThunk(
    'get:api/v1/targetsellerfolders',
    async (params, thunkAPI) => {
        const response = await api.fetchTargetSellerFolder(params)
        return response.data
    }
)

const fetchTargetSellers = createAsyncThunk(
    'get:api/v1/targetsellerfolders/:id/sellers',
    async (payload, thunkAPI) => {
        try {
            if (!payload?.append) {
                thunkAPI.dispatch(fetchLoading());
              }
              const data = await api.fetchTargetSellers(
                payload
              );
              return { ...data?.data, append: payload?.append };
        } catch (error) {
            if (error.name === 'AbortError') {
                // You might want to handle it appropriately or return a specific value
                return thunkAPI.rejectWithValue({aborted: true});
            } else {
                console.error('Error:', error);
                throw error; // Propagate the error if it's not an AbortError
            }
        }
    }
)

const fetchTargetSellerListings = createAsyncThunk(
    'get:api/v1/targetsellerfolders/:id/listings',
    async (payload, thunkAPI) => {
        try {
            if (!payload?.append) {
                thunkAPI.dispatch(fetchLoading());
            }
              const data = await api.fetchTargetSellerListings(
                payload
              );
              return { ...data?.data, append: payload?.append };
        } catch (error) {
            if (error.name === 'AbortError') {
                // You might want to handle it appropriately or return a specific value
                return thunkAPI.rejectWithValue({aborted: true});
            } else {
                console.error('Error:', error);
                throw error; // Propagate the error if it's not an AbortError
            }
        }
    }
);


const handleApprovementAction = createAsyncThunk(
    'post:api/v1/targetsellerfolders/:id/:type/:dataId/:action',
    async (params, thunkAPI) => {
        const response = await api.handleApprovementAction(params)
        return response.data
    }
)

const addTargetSeller = createAsyncThunk(
    'post:api/v1/targetsellerfolders/:id/seller',
    async (params, thunkAPI) => {
        const response = await api.addTargetSeller(params)
        return response.data
    }
)

const fetchTargetSellersFilters = createAsyncThunk(
    'get:api/v1/target-sellers/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchTargetSellersFilters(params)

        return response.data
    }
)

const fetchTargetSellerListingsFilters = createAsyncThunk(
    'get:api/v1/target-seller-listings/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchTargetSellerListingsFilters(params)

        return response.data
    }
)

const updateWeight = createAsyncThunk(
    'post:api/v1/target-seller-listings/:id',
    async (params, thunkAPI) => {
        const response = await api.updateWeight(params)

        return response.data
    }
)

const fetchNotes = createAsyncThunk(
    'get:api/v1/target-seller-folders/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.fetchNotes(params)
        return response.data
    }
)

const addNote = createAsyncThunk(
    'post:api/v1/target-seller-folders/:id/note',
    async (payload, thunkAPI) => {
        const response = await api.addNote(payload)
        return response.data
    }
)

const deleteNote = createAsyncThunk(
    'delete:api/v1/target-seller-folders/:id/note',
    async (payload, thunkAPI) => {
        const response = await api.deleteNote(payload)
        return response.data
    }
)


export {
    fetchTargetSellerFolder,
    fetchTargetSellers,
    fetchTargetSellerListings,
    handleApprovementAction,
    addTargetSeller,
    fetchTargetSellersFilters,
    fetchTargetSellerListingsFilters,
    updateWeight,
    fetchNotes,
    addNote,
    deleteNote
}