// --------------------------------------------------------------------------------------------
// summary/index.js
// --------------------------------------------------------------------------------------------
import React, {forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";

// Import components.
import SummaryItems from "./items";
import SummaryLeftSide from "./leftSide";


// Main component.
const SummaryTab = memo(forwardRef((props, ref) => {


    // Get props. and using useMemo to prevent unnecessary re-render.
    const {data, isView, id, callBack} = useMemo(() => ({
        data: props.data,
        isView: props.isView,
        id: props.id,
        callBack: props.callBack,
    }), [props]);


    // Set states.
    const [orderItems, setOrderItems] = useState([]);
    const [summaryForm, setSummaryForm] = useState({type: 1});
    const [validation, setValidation] = useState(true);


    // Create ref for SummaryEdit component.
    const summaryEditRef = useRef(null);


    // Set data to state when data is available.
    useEffect(() => {
        setOrderItems(data?.order_items);
        setSummaryForm(data);
    }, [data]);

    useEffect(() => {
        setOrderItems([])
    }, [summaryForm?.type])


    // Create ImperativeHandle for parent component.
    useImperativeHandle(ref, () => ({
        getData: async () => {
            let summaryEditValidation = await summaryEditRef?.current?.validate(true);
            if (!orderItems?.length) {

                summaryEditValidation.isValid = false;
                summaryEditValidation.validationErrors.order_items = "Orders must have at least one item";
            }
            if (validation !== summaryEditValidation) setValidation(summaryEditValidation);
            return {...summaryForm, order_items: orderItems, _validation: summaryEditValidation};
        }
    }));

    // Update order items data.
    const handleUpdateOrderListData = useCallback((list) => {
        setOrderItems(list);
    }, [summaryForm]);

    // Update summary form data.
    const handleUpdateSummaryFormData = useCallback((form, bind) => {
        setSummaryForm({...summaryForm, ...form, _data: {...summaryForm?._data, ...bind}});
    }, [summaryForm]);

    // Render component.
    return (
        <>
            <div className="flex flex-col gap-[8px] orderDetailModalSummary ">
                <div className={"flex flex-row items-stretch gap-[8px] justify-start"}>
                    {/* Order Left Side */}
                    <div style={{width: "360px", maxHeight: "calc(100vh - 220px)"}}>
                        <SummaryLeftSide
                            ref={summaryEditRef}
                            callBack={handleUpdateSummaryFormData}
                            isView={isView}
                            id={id}
                            summaryForm={summaryForm}
                            orderItems={orderItems}
                            actionCallBack={callBack}
                        />
                    </div>
                    {/* Order Items */}
                    <div style={{width: "calc(100% - 368px)", maxHeight: "calc(100vh - 220px)"}}>
                        <SummaryItems
                            isView={isView}
                            id={id}
                            orderItems={orderItems}
                            callBack={handleUpdateOrderListData}
                            validation={validation}
                            summaryForm={summaryForm}
                            addCallBack={callBack}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}));

export default SummaryTab;
