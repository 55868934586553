// locale/index.js
// ----------------------------------------------------------------

import { createSlice } from "@reduxjs/toolkit";
import { fetchAndSetLocale } from "./thunk";
// import {updateLocaleData} from "../../locale";

// Initial State of the locale slice.
const initialState = {
  selectedLocale: "en",
  showCompareMessage: false,
  list: {},
};

// Initial State of the locale list.
const initialLocale = {
  loading: "idle",
  data: {},
  error: null,
};

// Create the locale slice.
const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    // The fetchAndSetLocale action is already handled by extraReducers.
  },
  extraReducers: (builder) => {
    builder
      // Handle the fetchAndSetLocale.pending action.
      .addCase(fetchAndSetLocale.pending, (state, action) => {
        const locale = action.meta.arg;
        if (!state.list[locale]) {
          state.list[locale] = { ...initialLocale };
        }
        if (state.list[locale].loading === "idle") {
          state.list[locale].loading = "pending";
          state.list[locale].data = {};
        }
      })
      // Handle the fetchAndSetLocale.fulfilled action.
      .addCase(fetchAndSetLocale.fulfilled, (state, action) => {
        const locale = action.meta.arg;
        if (state.list[locale].loading === "pending") {
          state.list[locale].loading = "idle";
          state.list[locale].data = action.payload;
          // set the selected language after the locale is loaded.
          state.selectedLocale = locale;
          // updateLocaleData(locale, action.payload)
        }
      })
      // Handle the fetchAndSetLocale.rejected action.
      .addCase(fetchAndSetLocale.rejected, (state, action) => {
        const locale = action.meta.arg;
        if (state.list[locale].loading === "pending") {
          state.list[locale].loading = "idle";
          state.list[locale].error = action.error;
        }
      });
  },
});

export const { setSelectedLanguage } = localeSlice.actions;

export { fetchAndSetLocale };
export default localeSlice.reducer;
