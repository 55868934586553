import React from "react";
import Icons from "../../icons";
import UI from "../../../dynamic-form/elements";
import util from "../../../dynamic-page/util";

const OrderRowAction = ({actions, style, callBack}) => {
    const activeStyle = (status) => {
        if (status) {
            return "!text-blue-500"
        } else {
            return "!text-gray-500"
        }
    }
    return (
        <div className={"grid grid-cols-2 grid-rows-2 gap-[4px]"}>
            {/* Buyer note is here */}
            <util.popUp
                toggle={(selected) => (
                    <div>
                        <UI.Button item={{
                            buttonType: "flat",
                            className: `p-[2px]  ${activeStyle(actions?.customer_note_enabled)}`
                        }}>
                            <Icons.buyerNote/>
                        </UI.Button>
                    </div>
                )}
                action={"hover"}
                leftSpace={style?.leftSpace}
            >
            </util.popUp>
            {/* Internal note is here */}
            <util.popUp
                toggle={(selected) => (
                    <div className={"relative"}>
                        <UI.Button item={{
                            buttonType: "flat",
                            className: `p-[2px]  ${activeStyle(actions?.internal_notes?.length)}`
                        }}>
                            <Icons.internalNote/>
                        </UI.Button>
                        {actions?.internal_notes?.length > 0 && <div
                            className={"absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"}>{actions?.internal_notes.length}</div>}
                    </div>
                )}
                leftSpace={style?.leftSpace}
            >
            </util.popUp>
            {/* Shipping document is here */}
            <util.popUp
                toggle={(selected) => (
                    <div>
                        <UI.Button item={{
                            buttonType: "flat",
                            className: `p-[2px]  ${activeStyle(actions?.shipping_documents_enabled)}`
                        }}>
                            <Icons.shippingDocument/>
                        </UI.Button>
                    </div>
                )}
                leftSpace={style?.leftSpace}
            >
            </util.popUp>
            {/* Shipping Content is here */}

            <util.popUp
                toggle={(selected) => (
                    <div>
                        <UI.Button item={{
                            buttonType: "flat",
                            className: `p-[2px] ${activeStyle(actions?.shipping_content)}`
                        }}>
                            <Icons.shippingContent/>
                        </UI.Button>
                    </div>
                )}
                leftSpace={style?.leftSpace}
            >
            </util.popUp>
        </div>
    );
}
export default OrderRowAction;