import { createSlice } from "@reduxjs/toolkit";

import {
  createGoodsAcceptance,
  createGoodsAcceptanceFromProduct,
  deleteGoodsAcceptance,
  fetchGoodsAcceptance,
  fetchGoodsAcceptanceFilters,
  restoreGoodsAcceptance,
  updateGoodsAcceptance,
  updateGoodsAcceptanceFromProduct,
} from "./thunk";

const initialState = {
  goodsAcceptance: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {},
  meta: {},
  filtersMeta: {},
};

export const goodsAcceptanceSlice = createSlice({
  name: "goodsAcceptance",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    removeGoodsAcceptance: (state, action) => {
      const ids = action.payload;
      state.goodsAcceptance = state.goodsAcceptance.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoodsAcceptance.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchGoodsAcceptance.fulfilled, (state, action) => {
        state.loading = "idle";
        state.goodsAcceptance = action.payload?.append
          ? [...state.goodsAcceptance, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      .addCase(fetchGoodsAcceptanceFilters.fulfilled, (state, action) => {
        //state.loading = "idle";
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilters,
  removeGoodsAcceptance,
  updateBoundFilter,
  fetchLoading,
} = goodsAcceptanceSlice.actions;

export {
  fetchGoodsAcceptance,
  createGoodsAcceptance,
  createGoodsAcceptanceFromProduct,
  updateGoodsAcceptanceFromProduct,
  updateGoodsAcceptance,
  deleteGoodsAcceptance,
  restoreGoodsAcceptance,
  fetchGoodsAcceptanceFilters,
};

export default goodsAcceptanceSlice.reducer;
