import { createSlice } from "@reduxjs/toolkit";

import {
  fetchDefaultSettings,
  fetchSalesListing,
  fetchSalesListings,
  refreshAccountListings,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  fcRestockings: [],
  identifiers: [],
  defaultSettings: {},
  links: {},
  filters: {
    main_identifier_filter: null,
    created_at_filter: null,
    updated_at_filter: null,
    identifier_type_filter: null,
    brand_filter: [],
    channel_filter: [],
    assigned_filter: [],
    review_count_filter: [],
    rating_filter: [],
    variant_count_filter: [],
    buybox_owner_filter: [],
    buybox_lowest_price: [],
    category_filter: [],
    category_degree_filter: [],
    sales_rank_filter: [],
    bb_oos_90_filter: [],
    sales_channel_top_filter: [],
    fba_offers_count_filter: [],
    fbm_offers_count_filter: [],
    velocitied_daily_sales_filter: [],
    velocitied_monthly_sales_filter: [],
    velocitied_daily_sales_value_filter: [],
    velocitied_monthly_sales_value_filter: [],
    buybox_price_filter: [],
    fba_price_filter: [],
    fbm_sellable_filter: [],
    fba_sellable_filter: [],
    fba_inbound_filter: [],
    fba_reserved_qty_filter: [],
    fba_60_days_restock_limit_filter: [],
    main_identifier_filter: "",
    fba_margin_filter: [],
    fbm_margin_filter: [],
    fba_profit_filter: [],
    fbm_profit_filter: [],
    fbm_price_filter: [],
    stock_left_filter: [],
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  employees: [],
  loading: "idle",
  error: null,
  isLogin: false,
};

export const salesListingsSlice = createSlice({
  name: "fcRestocking",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesListings.fulfilled, (state, action) => {
        state.loading = "idle";
        state.fcRestockings = action.payload?.append
          ? [...state.fcRestockings, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchSalesListings.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(refreshAccountListings.fulfilled, (state, action) => {
        // Assuming you have an identifier to match the updated data in your state
        if (state.loading === "pending") {
          state.loading = "idle";
          state.identifiers = action.payload;
        }
      })
      .addCase(refreshAccountListings.rejected, (state, action) => {
        // Handle refresh failure if needed
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        } // Handle error as needed
      })
      .addCase(fetchDefaultSettings.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchDefaultSettings.fulfilled, (state, action) => {
        // if (state.loading === 'pending') {
        //   state.loading = 'idle'
        state.defaultSettings = action.payload;
        // }
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  updateRow,
  updateTable,
  fetchLoading,
} = salesListingsSlice.actions;

export {
  fetchSalesListings,
  fetchSalesListing,
  refreshAccountListings,
  fetchDefaultSettings,
};

export default salesListingsSlice.reducer;
