import {useSelector} from "react-redux";
import upperCaseString from "../../dynamic-page/util/upperCaseString";

const FiltersView = (selectedFilers) => {
    const filtersMeta = useSelector(state => state.dynamicPage.filtersMeta)
    return (
        [
            {
                name: "type",
                label: "Category Type",
                placeholder: "Filter by Type",
                type: "RadioButton",
                list: filtersMeta?.types?.map((item) => ({
                    label: upperCaseString(item?.name),
                    value: item?.id,
                    count: item?.count
                })),
                defaultValue: selectedFilers?.type,
                toggle: true,
                radioContainerClass: '!flex-col'
            },
        ]
    )
}

export default FiltersView;