import Heading from "./heading";
import ordersStatBoxes from "./ordersStatBoxes";
import supplyStatBoxes from "./supplyStatBoxes";
import shipStatBoxes from "./shipStatBoxes";
import fullStatBoxes from "./fullStatBoxes";
import salesStatBoxes from "./salesStatBoxes";
import targetStatBoxes from "./targetStatBoxes";
import StatIcon from "./statIcon";

export default {
    Heading,
    ordersStatBoxes,
    supplyStatBoxes,
    shipStatBoxes,
    fullStatBoxes,
    salesStatBoxes,
    targetStatBoxes,
    StatIcon
}