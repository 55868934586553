import React from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import PropTypes from "prop-types";

const Input = ({
  item,
  value,
  validationMessage,
  handleChange,
  handleFocus,
  handleBlur,
  handleKeyPress,
  defaultValue,
  formData,
}) => {
  const [type, setType] = React.useState(item.type);
  const disabled =
    typeof item.disabled === "function"
      ? item.disabled(formData)
      : item.disabled || false;
  const inputValue =
    value !== null && value !== undefined ? value : defaultValue || "";
  // Return Input in view
  return (
    <div className="relative">
      {item?.icon && (
        <div className="absolute left-4 w-4 h-4 top-1/2 -translate-y-1/2">
          {item.icon}
        </div>
      )}
      <input
        name={item.name}
        type={type}
        placeholder={item.placeholder}
        disabled={disabled}
        className={`transition text-[14px] leading-[17.5px] ${
          item.disabled && "disabled"
        }  disabled:text-gray-400 rounded-md ${
          item?.icon ? "pl-10 pr-2" : "px-[16px]"
        } h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${
          item.innerClassName || ""
        } ${validationMessage ? "!border-red-500 border-2" : ""}`}
        value={inputValue}
        onChange={(e) => {
          if (handleChange) handleChange(e.target.value, item.name);
        }}
        onKeyDown={(e) => {
          if (
            (e.key === "-" ||
              e.key === "." ||
              e.key === "," ||
              e.key === "+") &&
            item?.onlyDigits
          ) {
            e.preventDefault();
          }
        }}
        onPaste={(e) => {
          const paste = (e.clipboardData || window.clipboardData).getData(
            "text"
          );
          if (
            item?.onlyDigits &&
            (paste.includes("-") ||
              paste.includes(".") ||
              paste.includes(",") ||
              paste.includes("+"))
          ) {
            e.preventDefault();
          }
        }}
        onFocus={(e) => {
          if (handleFocus) handleFocus(e.target.value, item.name);
        }}
        onBlur={(e) => {
          if (handleBlur) handleBlur(e.target.value, item.name);
        }}
        onKeyPress={(e) => {
          if (handleKeyPress) handleKeyPress(e);
        }}
        autoFocus={item?.autoFocus}
      />
      {item?.type === "Password" && (
        <button
          className="absolute left-[90%] top-[50%] -translate-y-[50%] text-[20px] cursor-pointer"
          onClick={() => setType(type === "Password" ? "Text" : "Password")}
        >
          {type === "Password" ? <HiEye /> : <HiEyeOff />}
        </button>
      )}
    </div>
  );
};
Input.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    icon: PropTypes.element,
    innerClassName: PropTypes.string,
    autoFocus: PropTypes.bool,
    onlyDigits: PropTypes.bool, // Validation for `onlyDigits`
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validationMessage: PropTypes.string,
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  handleKeyPress: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formData: PropTypes.object,
};

export default Input;
