import {api_endpoint} from "./../config";
import axios from "axios";
import {clearToken} from "./../store/auth/api";
import store from "../store";
import {signout} from "../store/auth";

axios.defaults.baseURL = api_endpoint;
axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";
// max content length of 50mb
axios.defaults.maxContentLength = 10 * 1000 * 1000;

const changeCursor = (cursor) => {
    const overlay = document.getElementById("overlay");

    if (cursor) {
        document.body.style.cursor = "wait";
        overlay.style.display = "block";
    } else {
        document.body.style.cursor = "default";
        overlay.style.display = "none";
    }
};

const controllersMap = new Map();

axios.interceptors.request.use(
    (config) => {
        const url = config.url.split("?")[0];

        if (!config.skipCancel) {
            if (controllersMap.has(url)) {
                const existingController = controllersMap.get(url);
                existingController.abort();
            }

            // Create a new AbortController for the current request
            const abortController = new AbortController();

            // Provide the AbortSignal to the request
            config.signal = abortController.signal;

            // Update the mapping with the new controller for the endpoint
            controllersMap.set(url, abortController);
        }

        return config;
    },
    (error) => {
        changeCursor(false);
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        changeCursor(false);
        return response;
    },

    // (err) => {
    //     changeCursor(false)

    //   // if (err?.response.status !== 200) {
    //   //   api.clearToken();
    //   //   store.dispatch(signout());
    //   // }

    //   // if it is a cancel request error then return a promise reject error
    //   if (err?.na)
    //   // window.location.href = '/#/login'

    //   // if (err.response.data.message === "Unauthenticated.") {
    //   //   dropIsLogin();
    //   // }

    //   return err.response;

    // }
    (error) => {
        changeCursor(false);
        if (axios.isCancel(error) || error.message === "canceled") {
            return Promise.reject(error);
            // Handle cancellation logic here
        } else {

            if (error?.response?.data?.message === "This action is unauthorized.") {
                return error?.response;
            }
            if (error?.response?.data?.message === "Unauthenticated.") {
                store.dispatch(signout());
                // store.dispatch(postUserLogout())
            }

            // Reject the Promise for non-canceled errors
            return error.response;
        }
    }
);

export default axios;
