// language/api.js
// ----------------------------------------------------------------

import axios from "../../middleware/axios";
import constants from "../../constant";

// Call the API to fetch the language.
const fetchLanguages = async (params) => {
    // Check if api called for specific locale or all locales.
    const url = params
        ?
        constants.APIURL.GET_LANGUAGES.concat(params)
        :
        constants.APIURL.GET_LANGUAGES;
    const response = await axios.get(url, {withCredentials: true});

    /*return response;*/

    // ----------------------------------------------------------------
    // Moc data
    // ----------------------------------------------------------------
    const MocResponse = response;
    MocResponse.data.data = [
        {name: "English", code: 1, shortName: "en"},
        {name: "Turkish", code: 2, shortName: "tr"},
    ];
    return MocResponse;
    // ----------------------------------------------------------------
};

export default {fetchLanguages};
