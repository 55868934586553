import * as Yup from 'yup';


const hasValue = (value) => {
    const anySchema = Yup.mixed();
    if (String(value) === "0") {
        return true
    }
    if (value === null || value === "" || typeof value === "undefined" || value === "null" || value === "undefined") {
        return false;
    }
    if (typeof value === 'string' || typeof value === 'number') {
        return true;
    }

    if (Array.isArray(value)) {
        return value.some(child => {
            return child != null && child != undefined && child != "" && child != " " && hasValue(child);
        }) && value.some(child => {
            return child != null && child != undefined && child != "" && child != " ";
        });
    }

    if (typeof value === 'object' && value !== null) {
        //console.log("memt", value)
        return Object.values(value).some(val => {
            if (val && val?._typeError) return false
            //console.log("memt3", val, val?._typeError)

            //if (val != null || val != "") return false

            return hasValue(val);

        });
    }

    return false;
};

export default hasValue;
