import axios from "../../middleware/axios";
import constants from "../../constant";

export const fetchOrdersStatuses = async () => {
    let url = constants.APIURL.GET_ORDERS_FILTERS;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const fetchDashboardStatuses = async () => {
    let url = 'api/v1/dashboard'
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchShipmentsFilters = async () => {
    let url = 'api/v1/orders/shipment-labels/filters';
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchOffersFilters = async () => {
    let url = 'api/v1/offers/filters';
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchSalesFilters = async () => {
    let url = 'api/v1/account-listings/filter';
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const fetchTargetFilters = async () => {
    let url = 'api/v1/target-seller-listings/filters';
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    fetchOrdersStatuses,
    fetchShipmentsFilters,
    fetchOffersFilters,
    fetchSalesFilters,
    fetchTargetFilters,
    fetchDashboardStatuses
};