import React, {useState} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
//import { useTranslation } from 'react-i18next';

import {validator} from '../utils';
import {fetchRegisterUser} from '../store/auth';

import ArbitInput from './sub-elements/input';
import ArbitPasswordInput from './sub-elements/password-input';
import ArbitSubmitButton from './sub-elements/submit-button';
import {onPasswordConfirmValidator} from '../utils/validations';
import {toast} from "react-toastify";


const SignupPage = () => {
    //const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [confirmationError, setConfirmationError] = useState("")
    const [nameError, setNameError] = useState("")
    const [isPasswordVisible, setPasswordVisible] = useState(false)

    const isLogin = useSelector((state) => (state.auth.isLogin))
    const isRegistering = useSelector((state) => (state.auth.isRegistering))

    const onHandleSignup = () => {

        const nameValidation = validator.onEmptyValidator(name)
        const emailValidation = validator.onEmailValidator(email)
        const passwordValidation = validator.onPasswordValidator(password)
        const passwordConfirmation = validator.onPasswordConfirmValidator(password, password2)
        setNameError(nameValidation)
        setEmailError(emailValidation)
        setPasswordError(passwordValidation)
        setConfirmationError(passwordConfirmation)


        if (emailValidation !== '' || passwordValidation !== '' || nameValidation !== '' || passwordConfirmation !== '') return

        dispatch(fetchRegisterUser({

            email,
            password,
            name: name,
            password_confirmation: password2
        })).unwrap().then((res) => {
            // console.log(res, 'Created')
            if (res) {
                toast.success(res.data.message, {
                    toastId: "success1",
                });
                navigate("/login")

            }
        }).catch((err) => {
            toast.error(err, {
                toastId: "success1",
            });
        })
    }

    const onHandleLogin = () => {
        navigate("/login")
    }

    return (
        <div>
            <div className="lg:flex w-full min-h-screen h-full">
                <div className="lg:block flex justify-center lg:w-2/6 lg:pr-8">
                    <img className="mt-5 lg:mt-0 w-1/2 lg:w-full rounded-2xl lg:rounded-none h-full lg:object-cover"
                         src="./assets/images/loginimg.png"/>
                </div>
                <div className="relative lg:px-24 w-full lg:w-4/6">
                    <div className="absolute right-0 inset-0 flex justify-end w-full h-full bg-gray-100 opacity-10">
                        <div className="absolute inset-0 w-full h-full bg-repeat opacity-40"></div>
                        <div className="absolute w-full h-full bg-gradient-to-r from-white to-transparent"></div>
                    </div>
                    <div className="relative flex flex-wrap items-end">
                        <div className="relative w-full px-8 mb-12 lg:mb-0">
                            <div className="relative py-20 text-center md:py-32 xl:py-40">
                                <a className="flex justify-center items-center w-full mb-6 text-3xl font-bold lg:justify-start font-heading"
                                   href="#">
                                    {/*<svg
                    width="27"
                    height="19"
                    viewBox="0 0 27 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.65 2H18.65C19.0478 2 19.4294 2.15804 19.7107 2.43934C19.992 2.72064 20.15 3.10218 20.15 3.5C20.15 3.89782 19.992 4.27936 19.7107 4.56066C19.4294 4.84196 19.0478 5 18.65 5H6.65C6.25217 5 5.87064 4.84196 5.58934 4.56066C5.30803 4.27936 5.15 3.89782 5.15 3.5C5.15 3.10218 5.30803 2.72064 5.58934 2.43934C5.87064 2.15804 6.25217 2 6.65 2ZM11 8H17H23C23.3978 8 23.7794 8.15804 24.0607 8.43934C24.342 8.72064 24.5 9.10218 24.5 9.5C24.5 9.89782 24.342 10.2794 24.0607 10.5607C23.7794 10.842 23.3978 11 23 11H11C10.6022 11 10.2206 10.842 9.93934 10.5607C9.65803 10.2794 9.5 9.89782 9.5 9.5C9.5 9.10218 9.65803 8.72064 9.93934 8.43934C10.2206 8.15804 10.6022 8 11 8ZM3.5 14H15.5C15.8978 14 16.2794 14.158 16.5607 14.4393C16.842 14.7206 17 15.1022 17 15.5C17 15.8978 16.842 16.2794 16.5607 16.5607C16.2794 16.842 15.8978 17 15.5 17H3.5C3.10217 17 2.72064 16.842 2.43934 16.5607C2.15804 16.2794 2 15.8978 2 15.5C2 15.1022 2.15804 14.7206 2.43934 14.4393C2.72064 14.158 3.10217 14 3.5 14Z"
                      fill="#312E81"
                    />
                  </svg>
                  <span className="font-logo self-center text-2xl text-purple-900 font-bold whitespace-nowrap dark:text-white">
                    arbitbox
                  </span>*/}
                                    <img src="./assets/newLogo.png" className={"h-[27px]"}/>
                                </a>
                                <div className="flex flex-col mb-6 lg:items-start">
                                    <span className="text-3xl text-black font-bold">Create your account</span>
                                </div>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <div className="my-5 mb-4">
                                        <ArbitInput
                                            label="Your Email"
                                            value={email}
                                            placeholder="name@example.com"
                                            error={emailError}
                                            onChange={
                                                (e) => {
                                                    setEmail(e.target.value)
                                                    setEmailError(validator.onEmailValidator(e.target.value))
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="my-5 mb-4">
                                        <ArbitInput
                                            label="Your Name"
                                            value={name}
                                            placeholder="Name"
                                            error={nameError}
                                            onChange={
                                                (e) => {
                                                    setName(e.target.value)
                                                    setNameError(validator.onEmptyValidator(e.target.value))
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="my-5">
                                        <ArbitPasswordInput
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="my-5">
                                        <ArbitPasswordInput
                                            label="Confirm Password"
                                            value={password2}
                                            error={confirmationError}
                                            onChange={
                                                (e) => {
                                                    setPassword2(e.target.value)
                                                    setConfirmationError(onPasswordConfirmValidator(password, e.target.value))
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="flex w-full justify-between">
                                        <div>
                                            <label className="flex items-center mt-1" htmlFor="terms">
                                                <input className="mr-2 border border-gray-300 rounded-md"
                                                       type="checkbox"/>
                                                <span className="text-sm text-gray-500">I accept the <p
                                                    className="text-blue-600 font-[400] inline">Terms and Conditions</p></span>
                                            </label>
                                        </div>
                                        <div>
                                            <a href="#" className="text-sm text-blue-600 font-medium">Lost Password?</a>
                                        </div>
                                    </div>
                                    <div className="mt-8 md:mt-5">
                                        <ArbitSubmitButton
                                            label="Login"
                                            onClick={() => onHandleSignup()}
                                            disabled
                                        />
                                    </div>
                                    <div className="mt-4 w-full flex justify-start items-center">
                                        <span
                                            className="text-sm font-medium text-gray-900">Already have an account?</span>
                                        <span className="ml-1 text-sm font-medium text-blue-600"
                                              onClick={() => onHandleLogin()}>Login here</span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignupPage;
