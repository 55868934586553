/**
 * @author Austin Ames
 * @description a normal input
 */

const ArbitNormalInput = ({placeholder, value, onChange, width, border, type, error}) => {
    return (
        <input
            className={`border-[1px] h-[42px] p-[12px] ${
                error ? "border-red-500" : (border ? border : "border-gray-300")
            } flex flex-grow rounded-[8px] bg-gray-100 ${width && "w-" + width} `}
            placeholder={placeholder}
            value={value}
            type={type}
            onChange={(e) => {
                if (e.target.files) {
                    onChange(e.target.files[0]);
                } else {
                    onChange(e.target.value);
                }
            }}
        />
    );
};

ArbitNormalInput.defaultProps = {
    placeholder: "Arbit Normal Input",
    value: "",
    onChange: () => {
    },
};

export default ArbitNormalInput;
