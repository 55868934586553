import { createSlice } from "@reduxjs/toolkit";

import { fetchSupplyChannelFilters, fetchSupplyChannels } from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  supplyChannels: [],
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  filters: {},
  filterLoading: "idle",
};

export const supplyChannelSlice = createSlice({
  name: "supplyChannels",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    // fetchOffer: (state, action) => {
    //   return { ...state, offers: action.payload };
    // },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplyChannels.fulfilled, (state, action) => {
          state.loading = "idle";
          state.supplyChannels = action.payload?.append
          ? [...state.supplyChannels, ...action.payload.data]
          : action.payload.data;
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
      })
      .addCase(fetchSupplyChannels.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchSupplyChannelFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
  },
});

export const { updateFilter, updateBoundFilter, extraReducers, updateRow , fetchLoading} =
  supplyChannelSlice.actions;

export { fetchSupplyChannels, fetchSupplyChannelFilters };

export default supplyChannelSlice.reducer;
