import axios from "./../../middleware/axios";

const GetAllPickupPoints = async (params) => {
    let url = `/api/v1/pickup-points`;
    if (params) url = url.concat(params);

    const response = await axios.get(url, { withCredentials: true });
    return response;
};

export default {
    GetAllPickupPoints,
};