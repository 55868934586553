import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchReferralFees = createAsyncThunk(
    'get:api/v1/referral-fees',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchReferralFees(typeof payload === "string" ? payload : payload.query);
        return {...data, append: payload?.append};
    }
)

const fetchReferralFeeFilters = createAsyncThunk(
    'get:api/v1/referral-fees/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchReferralFeesFilters(params)
        // console.log(response)
        return response.data
    }
)

export {
    fetchReferralFees,
    fetchReferralFeeFilters
}
