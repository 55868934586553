// --------------------------------------------------------------------------------------------
// summary/index.js
// --------------------------------------------------------------------------------------------
import React, {forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState,} from "react";

// Import components.
import SummaryItems from "./items";
import SummaryLeftSide from "./leftSide";
import { useSelector } from "react-redux";

// Main component.
const SummaryTab = memo(
    forwardRef((props, ref) => {
        // Get props. and using useMemo to prevent unnecessary re-render.
        const {data, isView, id, callBack, update, selectedOrder, type,setDetailData} = useMemo(
            () => ({
                data: props.data,
                isView: props.isView,
                id: props.id,
                callBack: props.callBack,
                update: props.update,
                selectedOrder: props.selectedOrder,
                type: props.type,
                setDetailData: props.setDetailData,
            }),
            [props]
        );

        // Set states.

        const [summaryForm, setSummaryForm] = useState(null);
        const [validation, setValidation] = useState(true);

        // Create ref for SummaryEdit component.
        const summaryEditRef = useRef(null);

        // Set data to state when data is available.
        // useEffect(() => {
        //     setSummaryForm(data);
        // }, [data]);


        const orderItems = data?.order_items;

        const transfers = useSelector(state => state.transfers?.transfers)
        // Update order items data.
        const handleUpdateOrderListData = useCallback(
            (list) => {
                const updatedItem = transfers?.find(t => t.id === data?.id)
                    update && update({
                        ...updatedItem,
                        transfer_items_count : list?.length
                    }, {
                        ...updatedItem,
                        transfer_items_count : list?.length
                    })
                setDetailData({
                    ...data,
                    order_items: list
                });
            },
            [data]
        );

        // Update summary form data. 
        const handleUpdateSummaryFormData = useCallback(
            (form, bind) => {
                setDetailData({
                    ...data,
                    ...form,
                    _data: {...data?._data, ...bind},
                });
            },
            [data]
        );
        const filteredList =orderItems
        // Render component.
        return (
            <>
                <div className="flex flex-row h-[586px]  gap-[8px] flex-1 orderDetailModalSummary "
                >
                        {/* Order Left Side */}
                        <div style={{width: "360px", maxHeight: "calc(100vh - 220px)"}}>
                            <SummaryLeftSide
                                ref={summaryEditRef}
                                callBack={handleUpdateSummaryFormData}
                                isView={isView}
                            id={id}
                            type={type}
                                selectedOrder={selectedOrder}
                                summaryForm={data}
                                orderItems={orderItems}
                                actionCallBack={callBack}
                                update={update}
                            />
                        </div>
                        {/* Order Items */}
                        <div
                            style={{
                                width: "calc(100% - 368px)",
                                ...data?.status_id === '1' && {height: "511px"}
                            }}
                        >
                            <SummaryItems
                                isView={isView}
                            id={id}
                            type={type}
                                data={data}
                                orderItems={filteredList}
                            callBack={handleUpdateOrderListData}
                            leftCallBack={handleUpdateSummaryFormData}
                                validation={validation}
                                actionCallBack={callBack}
                                update={update}
                            />
                        </div>
                    </div>
            </>
        );
    })
);

export default SummaryTab;
