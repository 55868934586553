import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { HiCheck } from "react-icons/hi";
import ArbitButton from "../sub-elements/button";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import quantityHandler from "../../utils/quantityHandler";
import moment from "moment";
import constants from "../../constant";
import CustomAsyncSelect from "../Supplies/customAsyncSelect";
import MainModal from "../dynamic-page/MainModal";

const ShippingCostListAddEditModal = ({
  type,
  userId,
  data,
  onClose,
  onUpdate,
  onAdd,
  show,
  products,
}) => {
  const [product, setproduct] = useState([]);
  const [available_quantity, setAvailable_quantity] = useState("");
  const [sku, setSku] = useState("");

  const productsData = useSelector((state) => state.productReducer.products);

  const validationSchema = Yup.object({
    warehouse_id: Yup.mixed().required("Required"),
    qty: Yup.string().required("Required"),
    product: Yup.mixed().required("Required"),
    reason: Yup.string().required("Required"),
  });

  const mapOptionsToValues = (options) => {
    return options?.map((option) => ({
      value: option?.id,
      label: option?.name || option?.display_name,
      title: option?.name,
      product: option?.id,
    }));
  };

  const [warehouses, setWarehouses] = useState([]);
  useEffect(() => {
    if (type === "Edit") {
      setproduct(data?.product?.title);
    }

    async function fetchWarehouses() {
      await axios
        .get("api/v1/warehouses", { withCredentials: true })
        .then((response) => {
          // setWarehouses(response?.data?.data)
          const options = mapOptionsToValues(response?.data?.data);
          setWarehouses(options);
        });
    }

    fetchWarehouses();
  }, []);

  const fetchWarehouses = async (params) => {
    let url = "api/v1/warehouses";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const warehousesOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchWarehouses("?filter[name]" + inputValue)
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  };

  const mapProductsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: `#${option?.id}  |  ` + (option?.title ? option?.title : ""),
      description:
        "APID:  " +
        option?.apid +
        " | " +
        "Created At:  " +
        moment(option?.request_date_time).format("DD-MM-YYYY"),
      image: option?.image,
      assign: option?.brand?.assigned?.name,
      profile: option?.brand?.assigned?.profile_image,
    }));
  };

  const defaultProducts = useMemo(() => {
    return mapProductsOptionsToValues(productsData);
  }, [productsData]);

  // const [ productsOptions , setProducts ] = useState([])

  // useEffect(() => {
  //   const fetchProducts = async (params) => {
  //     let url = "api/v1/product/search?term="
  //     if (params)
  //         url = url.concat(params)
  //     const response = await axios.get(url, { withCredentials: true });
  //     setProducts(mapProductsOptionsToValues(response.data.data))
  //     return response.data
  // }
  //   fetchProducts()
  // }, [])

  const fetchProducts = async (params) => {
    let url = "api/v1/inventory/listing?term=";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const loadProductsOptions = async (inputValue) => {
    let url = inputValue + "&not_deleted=true";

    return new Promise((resolve) => {
      setTimeout(() => {
        fetchProducts(url)
          .then((response) => {
            const productsOptions = response.data?.map(
              (product) => product?.product
            );

            const options = mapProductsOptionsToValues(productsOptions);
            resolve(options);
          })
          .catch((error) => {
            // Handle error
          });
      }, 1000); // Delay the request by 1 second (1000 milliseconds)
    });
  };

  const [warehouse, setWarehouse] = useState("");

  useEffect(() => {
    if (sku !== "") {
      async function fetchProducts() {
        await axios
          .get(`api/v1/product?filter[sku]=${sku}&not_deleted=1`, {
            withCredentials: true,
          })
          .then((response) => {
            // setWarehouses(response?.data?.data)
            setproduct(response?.data?.data[0]?.title || "Product Not Found");
          });
      }

      fetchProducts();
    }
  }, [sku, type]);

  useEffect(() => {
    if (type === "Edit") {
      setWarehouse(data?.warehouse?.name);
    }
    if (sku !== "") {
      async function fetchWarehouse() {
        await axios
          .get(`api/v1/inventory?filter[sku]=${sku}&limit=1`, {
            withCredentials: true,
          })
          .then((response) => {
            // setWarehouses(response?.data?.data)
            setWarehouse(response?.data?.data[0]?.warehouse?.name);
            formik.setFieldValue(
              "warehouse_id",
              response?.data?.data[0]?.warehouse?.id
            );
            setAvailable_quantity(
              response?.data?.data[0]?.good_acceptance?.available_quantity
            );
          });
      }

      setTimeout(() => {
        fetchWarehouse();
      }, 1000);
    }
  }, [sku, type]);

  const [submit, setSubmit] = useState(false);
  const [qtyError, setQtyError] = useState(false);

  const formik = useFormik({
    initialValues:
      type !== "Add"
        ? {
            warehouse_id: {
              value: data?.warehouse?.id || "",
              label: data?.warehouse?.name || "",
            },
            qty: quantityHandler(data?.qty),
            type: {
              value: data?.type,
              label: data?.type === "in" ? "In" : "Out",
            },
            reason: data?.reason || "",
            product: {
              value: data?.product?.id || "",
              label:
                `#${data?.product?.id}  |  ` +
                (data?.product?.title ? data?.product?.title : ""),
              description:
                "APID:  " +
                data?.product?.apid +
                " | " +
                "Created At:  " +
                moment(data?.product?.request_date_time).format("DD-MM-YYYY"),
              image: data?.product?.image,
            },
          }
        : {
            warehouse_id: "",
            qty: "",
            type: data?.type,
            reason: "",
            product: "",
          },
    validationSchema: validationSchema,
    enableReinitialze: true,
    onSubmit: (values) => {
      let payload = {};
      if (type === "Add") {
        payload = {
          product_id: values?.product?.value,
          warehouse_id: values?.warehouse_id?.value,
          qty: values?.qty,
          type: values?.type?.value,
          accepted_by: userId,
          reason: values?.reason,
        };
        onAdd(payload);
      } else {
        payload = {
          product_id: values?.product?.value,
          warehouse_id: values?.warehouse_id?.value,
          qty: values?.qty,
          type: values?.type?.value,
          reason: values?.reason,
          accepted_by: userId,
        };
        onUpdate(data?.id, payload);
      }
    },
  });

  useEffect(() => {
    if (formik.values.product && formik.values.warehouse_id) {
      async function fetchAvailableQuantity() {
        await axios
          .get(
            `api/v1/good-acceptance/available-quantity?warehouse_id=${formik.values.warehouse_id.value}&product_id=${formik.values.product.value}`,
            { withCredentials: true }
          )
          .then((response) => {
            // setWarehouses(response?.data?.data)
            setAvailable_quantity(response?.data?.availableQuantity);
          });
      }

      fetchAvailableQuantity();
    }
  }, [formik.values.product, formik.values.warehouse_id]);

  return (
    <MainModal
      title={`${type}  ${type === "Add" ? "New" : ""} Inventory Adjustment`}
      show={show}
      onClose={onClose}
      center={true}
      item={{
        title: `${type}  ${type === "Add" ? "New" : ""} Inventory Adjustment`,
        cancel: onClose,
        submit: formik.handleSubmit,
        view: () => (
          <form
            className="w-[65vw] border-t border-t-gray-200 pt-[24px]"
            onSubmit={formik.handleSubmit}
          >
            {
              // console.log(formik, 'formik')
            }
            <div className="flex flex-col w-full gap-[8px] px-4">
              <div className="flex flex-col w-full gap-[8px]">
                <label
                  className="w-[150px] text-[16px] text-gray-700"
                  htmlFor="product"
                >
                  Product
                </label>
                <CustomAsyncSelect
                  url={constants.APIURL.GET_PRODUCT}
                  mapOptions={mapProductsOptionsToValues}
                  defaultOptions={defaultProducts}
                  defaultParams="?not_deleted=true&filter[type]=1"
                  placeholder="Select Product"
                  isDisabled={type === "Edit" ? true : false}
                  product={formik.values.product}
                  onChange={(e) => {
                    formik.setFieldValue("product", e);
                  }}
                  optionHeight={50}
                  features={{
                    formatOptionLabel: (option) => (
                      <div className="m-0 p-0 flex  ">
                        <img
                          className="w-8 h-8 mr-2 rounded-full"
                          src={option?.image || "/assets/images/noImage.png"}
                          alt="icon"
                          onError={(e) => {
                            e.target.src = "/assets/images/noImage.png";
                          }}
                        />
                        <div className="custom-container">
                          <label className="block text-sm font-semibold  title">
                            {option.label}
                          </label>
                          <label className="block text-xs text-gray-400  descr">
                            {option.description}
                          </label>
                        </div>
                      </div>
                    ),
                  }}
                  customStyles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: 42,
                      width: "100%",
                      maxWidth: "100%",
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      overflow: "auto",
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : submit && formik.errors.product
                        ? "1px solid #FF0000"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#00A3FF" : "white",
                      color: state.isSelected ? "white" : "black",

                      "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      zIndex: 999,
                      // borderRadius: 10,
                      // overflow: 'hidden',
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                      maxWidth: "100%",
                      height: 42,
                    }),
                  }}
                />
              </div>

              <div className="grid grid-cols-2 items-start gap-[16px] w-full justify-center">
                <div className="flex flex-col  gap-[8px] ">
                  <label className=" text-[16px] text-gray-700" htmlFor="qty">
                    Quantity
                  </label>
                  <input
                    id="qty"
                    name="qty"
                    type="number"
                    placeholder="Quantity"
                    className={` h-[40px] border border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8] ${
                      formik.touched.qty && formik.errors.qty
                        ? "border-red-500"
                        : ""
                    }`}
                    value={formik.values.qty}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="flex flex-col w-full gap-[8px]">
                  <label
                    className="w-[150px] text-[16px] text-gray-700"
                    htmlFor="warehouse_id"
                  >
                    Warehouse
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={warehouses}
                    loadOptions={warehousesOptions}
                    placeholder={"Search for a warehouse"}
                    className="border-none h-[42px] bg-transparent w-[338px]"
                    name="provider"
                    value={formik.values.warehouse_id}
                    onChange={(value) => {
                      // console.log(value);
                      formik.setFieldValue("warehouse_id", value);
                    }}
                    onBlur={formik.handleBlur}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: 42,
                        minHeight: 42,

                        backgroundColor: "white",
                        borderRadius: 6,
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : formik.errors.warehouse_id
                          ? "1px solid #FF0000"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#00A3FF" : "white",
                        color: state.isSelected ? "white" : "black",

                        "&:hover": {
                          backgroundColor: "#00A3FF",
                          color: "white",
                        },
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        width: 338,
                        zIndex: 999,
                        // borderRadius: 10,
                        overflow: "hidden",
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      container: (provided, state) => ({
                        ...provided,
                        width: "100%",
                        maxWidth: "100%",
                        height: 42,
                      }),
                    }}
                  />
                </div>

                <div className="flex flex-col  gap-[8px]">
                  <label
                    className="w-[150px] text-[16px] text-gray-700"
                    htmlFor="type"
                  >
                    Type
                  </label>
                  <Select
                    placeholder={"In , Out"}
                    className="border-none h-[42px] bg-transparent w-[338px]"
                    name="type"
                    options={[
                      { value: "in", label: "In" },
                      { value: "out", label: "Out" },
                    ]}
                    value={formik.values.type}
                    onChange={(value) => {
                      // console.log(value);
                      formik.setFieldValue("type", value);
                    }}
                    onBlur={formik.handleBlur}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: 42,
                        minHeight: 42,

                        backgroundColor: "white",
                        borderRadius: 6,
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : formik.errors.warehouse_id
                          ? "1px solid #FF0000"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#00A3FF" : "white",
                        color: state.isSelected ? "white" : "black",

                        "&:hover": {
                          backgroundColor: "#00A3FF",
                          color: "white",
                        },
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        width: 338,
                        zIndex: 999,
                        // borderRadius: 10,
                        overflow: "hidden",
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      container: (provided, state) => ({
                        ...provided,
                        width: "100%",
                        maxWidth: "100%",
                        height: 42,
                      }),
                    }}
                  />
                </div>
                <div className="flex flex-col  gap-[8px]">
                  <label
                    className="w-[150px] text-[16px] text-gray-700"
                    htmlFor="available_quantity"
                  >
                    Available Quantity
                  </label>
                  <input
                    id="available_quantity"
                    name="available_quantity"
                    type="text"
                    placeholder="Available Quantity"
                    disabled={true}
                    className={` h-[40px] border border-gray-300 text-gray-600 bg-gray-200 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8]`}
                    value={
                      available_quantity
                        ? quantityHandler(available_quantity)
                        : available_quantity === 0
                        ? 0
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col w-full gap-[8px]">
                <label
                  className="w-[150px]  text-[16px] text-gray-700"
                  htmlFor="reason"
                >
                  Adjustment Reason
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  placeholder="Adjustment Reason"
                  className={`w-full h-[84px] border overflow-hidden border-gray-300 rounded-md p-[8px] focus:outline-none focus:border-[#00A8E8] ${
                    formik.errors.reason ? "border-red-500" : ""
                  }`}
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                />
              </div>

              <div className="flex justify-end mt-2">
                <div className="mx-1">
                  <ArbitButton
                    type="submit"
                    // onClick={() => onHandleSave()}
                    // disabled={(type === 'Add' && available_quantity === 0)}
                    onClick={() => {
                      setSubmit(true);
                    }}
                  >
                    <HiCheck className="mr-2 h-5 w-5" />
                    Save
                  </ArbitButton>
                </div>
                <div className="mx-1">
                  <ArbitButton
                    bg_color="white"
                    border_color="blue"
                    text_color="blue-600"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </ArbitButton>
                </div>
              </div>
            </div>
          </form>
        ),
      }}
    />
  );
};

ShippingCostListAddEditModal.defaultProps = {
  type: "Add",
  onUpdate: () => {},
  onCancel: () => {},
  onAdd: () => {},
  data: {},
};

export default ShippingCostListAddEditModal;
