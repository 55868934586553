import React from "react";
import Skeleton from "react-loading-skeleton";

function SelectItems({
  title,
  ItemCard,
  data,
  setData,
  defaultItem,
  connectLoading,
  loading,
  tooltip,
  setChildren,
  setParent,
  parent,
}) {
  const results = defaultItem?.id ? [defaultItem] : defaultItem;

  return (
    <div className="app flex flex-col gap-2 ">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1.5">
          <p className="text-[16px] leading-6 font-semibold">{title} </p>
          {tooltip && tooltip}
        </div>
        {results?.length > 0 && title !== "Parent Order" && (
          <p
            className={` font-medium text-[14px] flex items-center gap-1 justify-end mt-2   cursor-pointer
                            ${
                              data.children.length === results.length
                                ? "text-red-700"
                                : "text-blue-700"
                            }
                          `}
            onClick={() => {
              if (
                // some are not selected
                results.some((item) => !data.children.includes(item))
              ) {
                setData({
                  ...data,
                  children: defaultItem,
                });
              } else {
                setData({
                  ...data,
                  children: [],
                });
              }
            }}
          >
            {data.children.length === results.length
              ? "Deselect All"
              : "Select All"}
          </p>
        )}
      </div>
      <div className="">
        <SearchResults
          results={results}
          ItemCard={ItemCard}
          setChildren={setChildren}
          data={data}
          setParent={setParent}
          parent={parent}
          setData={setData}
          loading={loading}
          connectLoading={connectLoading}
        />
      </div>
    </div>
  );
}

export default SelectItems;

const SearchResults = ({
  results,
  ItemCard,
  setData,
  data,
  loading,
  connectLoading,
  selection,
  setParent,
  parent,
  setChildren,
}) => {
  return (
    <div className="flex flex-col">
      <div className="max-h-custom lg overflow-y-auto ">
        {!loading ? (
          results?.length > 0 ? (
            results?.map((item, index) => (
              <ItemCard
                key={index}
                item={item}
                index={index}
                results={results}
                setChildren={setChildren}
                data={data}
                setData={setData}
                connectLoading={connectLoading}
                selection={selection}
                setParent={setParent}
                parent={parent}
              />
            ))
          ) : (
            <></>
          )
        ) : (
          <Skeleton width={630} height={176} count={3} />
        )}
      </div>
    </div>
  );
};
