import React from 'react';
import Button from "./Button";
import moment from 'moment';


const DateRange = ({item, value = [undefined, undefined], handleChange, validationMessage, formData}) => {

    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;

    if (typeof value === "string" && value.includes(",")) {
        value = value.split(",");
    }

    // Empty value
    const dateValue = (val) => {
        return (!val || val === "" ? ["", ""] : val).map(child => child === "" || !child ? "" : child);
    };


    // Initial option
    const options = {
        "Today": [0, 0],
        "Yesterday": [1, 1],
        "Last 3 days": [0, 3],
        "Last 7 days": [0, 7],
        "Last 14 days": [0, 14],
        "Last 30 days": [0, 30]
    };

    // Handle change date by options
    const handleDateChange = (d) => {
        const today = moment().subtract(d[0], 'day').format('YYYY-MM-DD');
        const daysAgo = moment().subtract(d[1], 'day').format('YYYY-MM-DD');
        handleChange([daysAgo, today], item.name)
    }

    // Return Date in view
    return (
        <div className={"flex flex-col gap-3"}>

            <div className={"flex flex-row gap-3 items-center justify-center h-full"}>
                <input
                    name={item.name} type={"date"} disabled={disabled}
                    className={`transition ${disabled && "disabled"}  disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${item.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                    placeholder={item.placeholder}
                    value={dateValue(value)[0]}
                    onChange={(e) => {
                        handleChange([e.target.value, dateValue(value)[1]], item.name)
                    }}
                />
                <span><svg className={"text-gray-400 text-sm "} fill="currentColor" viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path clipRule="evenodd"
                                                                                       fillRule="evenodd"
                                                                                       d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"/></svg></span>
                <input
                    name={item.name} type={"date"} disabled={disabled}
                    className={`transition ${disabled && "disabled"}  disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${item.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                    placeholder={item.placeholder}
                    value={dateValue(value)[1]}
                    onChange={(e) => {
                        handleChange([dateValue(value)[0], e.target.value], item.name)
                    }}
                />
            </div>

            <div className={"grid block grid-cols-2 gap-1 grid-col-flow"}>
                {Object.keys(options).map((o, i) => {
                    return (
                        <Button key={i} onClick={() => {
                            handleDateChange(options[o])
                        }}
                                item={{
                                    label: o,
                                    buttonType: "secondary",
                                    className: "h-[32px]",
                                    selected: dateValue(value)[1] === moment().subtract(options[o][0], 'day').format('YYYY-MM-DD') && dateValue(value)[0] === moment().subtract(options[o][1], 'day').format('YYYY-MM-DD')
                                }}/>
                    )
                })}
            </div>

        </div>
    )
}


export default DateRange;