// App.js
// ----------------------------------------------------------------

// Import general libraries.
import React from "react";
import AppRouter from "./router";
import {BrowserRouter} from "react-router-dom";
import "./middleware/axios"
//import i18n  from "./locale";

// Main Component: App
const App = () => (
    //<Provider store={store}>
    <BrowserRouter>
        {/*<CompareLanguages/>*/}
        <AppRouter/>
    </BrowserRouter>
    //</Provider
)
export default App;


