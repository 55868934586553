import React, {forwardRef, memo, useEffect, useState} from "react";
import Box from "../../../../../dynamic-page/elements/Box";
import SummaryView from "./summaryView";
import SummaryEdit from "./summaryEdit";
import {apiActions} from "../../../../../../store/orders";
import {useDispatch} from "react-redux";
import prepareDataToUpdateSummary from "../util/prepareDataToUpdateSummary";
import notifier from "../../../../../dynamic-page/util/notifier";

const SummaryLeftSide = memo(forwardRef((props, ref) => {
    const {order, id, isView, callBack, orderItems, summaryForm, actionCallBack} = props

    const [isEdit, setIsEdit] = useState(true)
    const [summaryData, setSummaryData] = useState(summaryForm)
    const dispatch = useDispatch()

    useEffect(() => {
        setSummaryData(summaryForm)
    }, [summaryForm])

    useEffect(() => {
        if (isView) setIsEdit(false)
    }, [isView])

    const changeForm = (form, bind) => {
        if (isView) {
            const newData = {...summaryForm, ...form, _data: {...summaryForm?._data, ...bind}}
            setSummaryData(newData);
        } else {
            callBack(form, bind)
        }
    }

    const handleSave = async () => {
        setIsEdit(false)
        const {_data, ...form} = summaryData
        const postData = await prepareDataToUpdateSummary(form)
        dispatch(apiActions({action: "summary", orderId: id.order[0], data: postData})).then(res => {
            notifier(res.payload.data)
            if (res.payload.data.success) {
                callBack(form, _data)
            }
        })
    }
    const handleCancel = () => {
        setIsEdit(false)
        setSummaryData({...summaryForm});
    }


    return (
        <Box
            title={isView ? "Transfer Summary" : "New Transfer Details"}

            options={null}/* {isView ? <>
                    {!isEdit ?
                        <div>
                            <UI.Button
                                item={{  buttonType:"icon", className:"p-[6px] relative left-[6px] text-blue-500 hover:text-blue-600", }}
                                onClick={() => {setIsEdit(true)}}
                            >
                                <Icons.edit className={"w-[15px] h-[15px]"}/>
                            </UI.Button>
                        </div>
                        :
                        <div className={"flex flex-row gap-[4px] relative left-[6px]"}>
                            <UI.Button item={{ buttonType:"alt", className:"h-[35px]", }} onClick={handleCancel} >
                                Cancel
                            </UI.Button>
                            <UI.Button item={{  buttonType:"primary", className:"h-[35px]", }} onClick={handleSave} >
                                Save
                            </UI.Button>
                            
                        </div>
                    }
                
                </>
               
                : null}*/
        >


            <style>
                {`
                    .css-1u9des2-indicatorSeparator {display: none; }
                    .css-1xc3v61-indicatorContainer {padding-left: 0px; padding-right:6px }
                    .css-1xc3v61-indicatorContainer {padding-right: 12px;}
                    .css-1xc3v61-indicatorContainer svg {color:transparent !important; background: url('/assets/images/arrow.svg'); width:12px; height:12px; opacity:0.7}
                    input[type="date"]::before { opacity:1; right:12px; background: url('/assets/images/arrow.svg') no-repeat center !important; width: 12px !important; height:12px !important; opacity:0.7 !important; border:none !important; right:12px !important }
                    input[type="date"]::-webkit-datetime-edit-month-field { opacity:0.4 !important; }
                    input[type="date"].hasValue::-webkit-datetime-edit-month-field { opacity:1 !important; }
                    
                    input[type="date"]::-webkit-datetime-edit-text { opacity:0.4 !important; }
                    input[type="date"]::-webkit-datetime-edit-text { opacity:1 !important; }
                    
                `}
            </style>
            {/*if isView is true, render SummaryView, else render SummaryEdit*/}
            {!isEdit ? (
                <SummaryView
                    id={id}
                    orderItems={orderItems}
                    summaryForm={summaryData}
                    callBack={callBack}
                />
            ) : (
                //summaryData &&
                <SummaryEdit
                    ref={ref}
                    callBack={changeForm}
                    order={order}
                    actionCallBack={actionCallBack}
                    orderItems={orderItems}
                    summaryForm={summaryData}

                />
            )}


        </Box>
    )
}))

export default SummaryLeftSide;