import React, { forwardRef, memo } from "react";

import DynamicForm from "../../../../../dynamic-form";
import summaryItemsForm from "../model-view/summaryItemsForm";
import summaryEditFormStyle from "../model-view/summaryEditFormStyle";

const SummaryEdit = forwardRef((props, ref) => {
  let { orderItems, summaryForm, callBack, actionCallBack, type } = props;

  const formItems = summaryItemsForm(summaryForm,type);

  return (
    <div className="flex flex-col px-[16px] mb-[16px] ">
      {/* summary content */}
      <DynamicForm
        onChange={callBack}
        items={formItems}
        style={summaryEditFormStyle}
        ref={ref}
      />

      {/* <Summary /> */}
    </div>
  );
});

export default memo(SummaryEdit);
