import Icons from "../../orders/icons";
import upperCaseString from "../util/upperCaseString";
import SingleGroupProductBadge from "./SingleGroupProductBadge";

const connectProductFrom = (hideLabel) => ([
    {
        name: "product",
        label: "Quick search on products",
        type: "Select2",
        api: {
            url: '/api/v1/product',
            query: "?term={query}",
            optionValue: (item) => ({
                value: item?.id,
                label: upperCaseString(item?.title, "word", true),
                product: item
            })
        },
        noPortal: true,
        disablePreload: true,
        reset: false,
        clear: false,
        labelClassName: hideLabel ? 'hidden' : '',
        className: "w-full",
        features: {
            formatOptionLabel: ({label, product}) => {
                return <div className={"flex flex-row gap-[8px] items-center min-h-[25px]"}>
                    <span className={" min-w-[45px] gap-1 overflow-hidden flex flex-row justify-center items-center "}>
                        <SingleGroupProductBadge product={product}/>
                        {product?.image ?
                            <img className={"w-[20px] h-[20px] rounded-md "} src={product?.image}/>
                            :
                            <img className={"w-[20px] h-[20px] rounded-md"} src={"/assets/images/noImage.png"}/>
                        }
                    </span>
                    <span>{upperCaseString(label)}</span>
                </div>
            }
        },
        style: {
            control: {
                height: "34px !important",
                minHeight: "auto",
                background: "rgba(0,0,0,0.03)"
            },
            menu: {
                whiteSpace: 'wrap !important',
            },
            option: {
                padding: "4px 8px !important",
            },
        },
        placeholder: (<div className={"flex flex-row gap-[8px] items-center text-[14px]"}><Icons.shipmentDetail
            className={"w-[16px] h-[16px]"}/>Quick search on products</div>),
    }
])

export default connectProductFrom;