import constants from "../../../constant";

const formView = (formDefaultValues, params) => {
  const salesChannel = params?.get("channel");
  const account = params?.get("account");
  const identifier = params?.get("identifier");
  return [
    {
      name: "channel_id",
      label: "Sales Channel",
      placeholder: "Select channel",
      type: "Select",
      api: {
        url: "/api/v1/channel?filter[designation]=TARGET",
        view: "/api/v1/channel",
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      clear: false,
      reset: false,
      defaultValue: salesChannel || null,
    },
    {
      name: "account_id",
      label: "Account",
      placeholder: "Select account",
      type: "Select",
      api: {
        url: `/api/v1/accounts?filter[channel]={channel_id}&filter[status]=active`,
        query: "&filter[name]={query}",
        view: "/api/v1/accounts",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      clear: false,
      reset: false,
      defaultValue: +account || null,
    },

    {
      name: "identifier",
      label: "ASIN",
      placeholder: "ex. B08L5M9BTJ",
      type: "Input",
      clear: false,
      reset: false,
      defaultValue: identifier || null,
    },
    {
      name: "fulfillment_type",
      label: "Fulfillment Type",
      placeholder: "Select type",
      type: "Select",
      list: [
        { label: "FBA", value: "AMAZON_NA" },
        { label: "FBM", value: "DEFAULT" },
      ],
      clear: false,
      reset: false,
      defaultValue: "DEFAULT",
    },
    {
      name: "quantity",
      label: "Quantity",
      placeholder: "ex. 100",
      disabled: (formData) => formData?.fulfillment_type !== "DEFAULT",
      type: "Number",
      clear: false,
      reset: false,
      defaultValue: 100,
    },
    {
      name: "currency",
      label: "Currency",
      placeholder: "TRY",
      type: "Select",
      api: {
        url: constants.APIURL.GET_CURRENCIES,
        view: constants.APIURL.GET_CURRENCIES,
        query: "/search?term={query}",
        optionValue: (item) => ({
          value: item.code,
          label: item.name + " (" + item.symbol + ")",
        }),
      },

      defaultValue:
        formDefaultValues?.currency?.id || formDefaultValues?.currency || null,
    },
    {
      name: "price",
      label: "Price",
      placeholder: "ex. 35.95",
      type: "Number",
      clear: false,
      reset: false,
      defaultValue: null,
    },
    {
      name: "sku",
      label: "SKU",
      placeholder: "Enter SKU",
      type: "Input",
      clear: false,
      reset: false,
      defaultValue: null,
      className: "",
    },
    {
      name: "identifier_type",
      type: "Hidden",
      defaultValue: "ASIN",
    },
  ];
};
export default formView;
