import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchBalance = async (params) => {
    let url = constants.APIURL.GET_BALANCE;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const fetchBalanceFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_BALANCE_FILTERS,
        {withCredentials: true}
    );
    return response?.data;
};

const searchBalance = async (params) => {
    let url = constants.APIURL.GET_BALANCE + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteBalance = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BALANCE + payload, {withCredentials: true});
    return response;
};

const createBalance = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_BALANCE, payload, {withCredentials: true});
    return response;
};

const updateBalance = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_BALANCE + id, payload, {withCredentials: true});
    return response;
};

const updateBalanceStatus = async (payload) => {

    const response = await axios.put(constants.APIURL.PUT_BULK_BALANCE_STATUS, payload, {withCredentials: true});
    return response;
};

const bulkDeleteBalance = async (payload) => {

    const response = await axios.delete(constants.APIURL.BULK_DELETE_BALANCE, {withCredentials: true, data: payload});
    return response;
};

const restoreBalance = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_BALANCE, payload, {withCredentials: true});
    return response;
};

export default {
    fetchBalance,
    deleteBalance,
    createBalance,
    updateBalance,
    searchBalance,
    updateBalanceStatus,
    bulkDeleteBalance,
    restoreBalance,
    fetchBalanceFilters
};
