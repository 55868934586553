import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteShippingCostLists,
  bulkStatusUpdateShippingCostLists,
  createShippingCostList,
  deleteShippingCostList,
  duplicateShippingCostList,
  fetchShippingCostLists,
  fetchShippingCostListsFilters,
  restoreShippingCostLists,
  searchShippingCostLists,
  updateShippingCostList,
} from "./thunk";

const initialState = {
  shippingCostLists: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
  },
  filtersMeta: {},
  boundFilters: {},
  selectedShippingCostId: null,
  meta: {},
};

export const shippingCostListsSlice = createSlice({
  name: "shippingCostLists",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchShippingCostLists: (state, action) => {
      state.ShippingCostLists = action.payload;
    },
    updateFilter: (state, action) => {
      state.filters = { ...action.payload };
    },
    setSelectedShippingCostId: (state, action) => {
      state.selectedShippingCostId = action.payload;
    },
    updateShippingCostListStatus: (state, action) => {
      const { status, ids } = action.payload;
      state.ShippingCostLists = state.ShippingCostLists.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeShippingCostList: (state, action) => {
      const ids = action.payload;
      state.ShippingCostLists = state.ShippingCostLists.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    bulkDeleteShippingCostLists: (state, action) => {
      const ids = action.payload;
      state.ShippingCostLists = state.ShippingCostLists.filter(
        (ShippingCostList) => !ids.includes(ShippingCostList.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingCostLists.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchShippingCostLists.fulfilled, (state, action) => {
        state.loading = "idle";
        state.ShippingCostLists = action.payload?.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(fetchShippingCostLists.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchShippingCostListsFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeShippingCostList,
  updateShippingCostListStatus,
  setSelectedShippingCostId,
} = shippingCostListsSlice.actions;

export {
  fetchShippingCostLists,
  createShippingCostList,
  updateShippingCostList,
  deleteShippingCostList,
  searchShippingCostLists,
  bulkDeleteShippingCostLists,
  bulkStatusUpdateShippingCostLists,
  restoreShippingCostLists,
  fetchShippingCostListsFilters,
  duplicateShippingCostList,
};

export default shippingCostListsSlice.reducer;
