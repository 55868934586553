import {ReactComponent as arrowup} from "./arrow-up.svg";
import {FaDownload} from 'react-icons/fa';
import {BiSolidUserCircle} from 'react-icons/bi';
import {ReactComponent as productImg} from "./product.svg";
import {ReactComponent as Graph} from "./Graph.svg";
import {ReactComponent as Star} from "./star.svg";
import {ReactComponent as Fire} from "./fire.svg";
import {ReactComponent as US} from "./us.svg";
import {ReactComponent as Switch} from "./switch-vertical.svg";

export default {
    arrowup,
    FaDownload,
    BiSolidUserCircle,
    productImg,
    Graph,
    Star,
    Fire,
    US,
    Switch
}