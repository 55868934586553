import React from "react";
import Icons from "../../orders/icons";
import Util from "../util";
import descriptiveContent from "../../../utils/descriptiveContent";
import classNames from "classnames";
import priceHandler from "../../../utils/priceHandler";

function ListingAnalysis({item, offerCount}) {
    return (
        <div className="flex flex-col space-y-[4px] w-[200px] !px-[8px] !py-[12px] ">
            <div className="flex items-center justify-between">
                <Util.popUp
                    toggle={(selected) => (
                        <p
                            className={classNames(
                                item?.["buybox_price"] ? "text-teal-600" : "text-[#7EDCE2]",
                                "text-[24px] leading-[24px] font-[500] flex items-center gap-1"
                            )}
                        >
                            {item?.["buybox_price"]
                                ? "$" + priceHandler(item?.["buybox_price"])
                                : "No BB"}
                        </p>
                    )}
                    action="hover"
                >
                    <div
                        className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                        Buybox Price
                    </div>
                </Util.popUp>

                <div className="flex flex-col justify-end gap-[2px]">
                    {/* <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                  avg {consts.currency[item?.["bb_currency"]]}
                  {item?.["bb_avg_price"]}
                </p> */}
                    <div className="  justify-between items-center gap-2.5 inline-flex">
                        {item?.buybox_seller_type ? (
                            <Util.popUp
                                toggle={(selected) => (
                                    <div
                                        className={`text-center ${
                                            item?.buybox_seller_type === "FBM"
                                                ? "text-blue-700"
                                                : "text-orange-700"
                                        } text-xs font-normal !leading-3`}
                                    >
                                        {item?.buybox_seller_type}
                                    </div>
                                )}
                                action="hover"
                            >
                                <div
                                    className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                    Buybox Owner Fulfillment Type
                                </div>
                            </Util.popUp>
                        ) : (
                            ""
                        )}
                        <Util.popUp
                            toggle={(selected) => (
                                <div
                                    className="justify-between items-center gap-1 flex cursor-pointer"
                                    onClick={() => {
                                        window.open(
                                            `https://www.amazon.com/gp/offer-listing/${item?.identifier}`,
                                            "_blank"
                                        );
                                    }}
                                >
                                    <svg
                                        width="10"
                                        height="8"
                                        viewBox="0 0 10 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M0.799988 1.03848C0.799988 0.879347 0.863202 0.726734 0.975724 0.614213C1.08825 0.501691 1.24086 0.438477 1.39999 0.438477H8.59999C8.75912 0.438477 8.91173 0.501691 9.02425 0.614213C9.13677 0.726734 9.19999 0.879347 9.19999 1.03848C9.19999 1.19761 9.13677 1.35022 9.02425 1.46274C8.91173 1.57526 8.75912 1.63848 8.59999 1.63848H1.39999C1.24086 1.63848 1.08825 1.57526 0.975724 1.46274C0.863202 1.35022 0.799988 1.19761 0.799988 1.03848ZM0.799988 4.03848C0.799988 3.87935 0.863202 3.72673 0.975724 3.61421C1.08825 3.50169 1.24086 3.43848 1.39999 3.43848H8.59999C8.75912 3.43848 8.91173 3.50169 9.02425 3.61421C9.13677 3.72673 9.19999 3.87935 9.19999 4.03848C9.19999 4.19761 9.13677 4.35022 9.02425 4.46274C8.91173 4.57526 8.75912 4.63848 8.59999 4.63848H1.39999C1.24086 4.63848 1.08825 4.57526 0.975724 4.46274C0.863202 4.35022 0.799988 4.19761 0.799988 4.03848ZM4.39999 7.03848C4.39999 6.87935 4.4632 6.72673 4.57572 6.61421C4.68825 6.50169 4.84086 6.43848 4.99999 6.43848H8.59999C8.75912 6.43848 8.91173 6.50169 9.02425 6.61421C9.13677 6.72673 9.19999 6.87935 9.19999 7.03848C9.19999 7.19761 9.13677 7.35022 9.02425 7.46274C8.91173 7.57526 8.75912 7.63848 8.59999 7.63848H4.99999C4.84086 7.63848 4.68825 7.57526 4.57572 7.46274C4.4632 7.35022 4.39999 7.19761 4.39999 7.03848Z"
                                            fill="#C81E1E"
                                        />
                                    </svg>
                                    <div className="text-red-700 text-xs font-semibold !leading-3">
                                        {offerCount != null ? offerCount : "-"}
                                    </div>
                                </div>
                            )}
                            action="hover"
                        >
                            <div
                                className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                Total Offers
                            </div>
                        </Util.popUp>
                    </div>
                    <Util.popUp
                        toggle={(selected) => (
                            <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                                low{" "}
                                <span className="text-[12px] leading-[12px] text-gray-500 text-end">
                  {item?.["bb_low_price"] ? "$" + item?.["bb_low_price"] : "-"}
                </span>
                            </p>
                        )}
                        action="hover"
                    >
                        <div
                            className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                            Lowest Price
                        </div>
                    </Util.popUp>
                </div>
            </div>
            <hr/>
            <div className="gap-0.5">
                <div className="flex items-center justify-between">
                    <p
                        className={classNames(
                            item?.["category_rank_degree"]
                                ? "text-purple-600"
                                : "text-purple-200",
                            "text-[24px] leading-[24px] font-[500]"
                        )}
                    >
                        {item?.["category_rank_degree"]
                            ? item?.["category_rank_degree"]
                            : "No Rank"}
                    </p>
                    <div className="flex flex-col justify-end">
                        <Util.popUp
                            toggle={(selected) => (
                                <p className="text-[12px] leading-[12px] text-rose-700 text-end">
                                    {item?.["sales_rank"] ? "#" + item?.["sales_rank"] : "-"}
                                </p>
                            )}
                            action="hover"
                        >
                            <div
                                className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                                Sales Rank
                            </div>
                        </Util.popUp>
                        {/* <div className="flex items-center justify-end">
                  {item?.["sales_channel_top"] ? (
                    <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                      top
                    </p>
                  ) : (
                    ""
                  )}
                  &nbsp;
                  <p className="text-[12px] leading-[12px] text-yellow-400 text-end">
                    {item?.["sales_channel_top"] ? item?.["sales_channel_top"] + '%' : "-"}
                  </p>
                </div> */}
                    </div>
                </div>
                <div className="flex items-center justify-start text-[12px] leading-[18px] text-rose-700 font-[400]">
                    {/* <p className="text-gray-400">in&nbsp;</p>{" "} */}
                    {/* {item?.root_category ?? "N/A"} */}
                    {item?.root_category
                        ? item?.root_category
                            ?.split(" ")
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")
                        : "N/A"}
                </div>
            </div>
        </div>
    );
}

export default ListingAnalysis;
