import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchShippingTemplate = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGTEMPLATE;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const searchShippingTemplate = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGTEMPLATE + '/search?term=';
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response?.data;
};

const deleteShippingTemplate = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_SHIPPINGTEMPLATE + payload, {withCredentials: true});
    return response?.data
};

const createShippingTemplate = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_SHIPPINGTEMPALTE, payload, {withCredentials: true});
    return response?.data
};

const updateShippingTemplate = async (id, payload) => {
    let url = constants.APIURL.PUT_SHIPPINGTEMPLATE + id;
    const response = await axios.put(url, payload, {withCredentials: true});
    return response?.data
};

const fetchShippingTemplatesFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_SHIPPINGTEMPLATE_FILTERS, {withCredentials: true});
    return response?.data;
};

const bulkDeleteShippingTemplates = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BULK_SHIPPING_TEMPLATE, {
        withCredentials: true,
        data: payload
    });
    return response?.data
};

const bulkStatusUpdateShippingTemplates = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_SHIPPING_TEMPLATE, payload, {withCredentials: true});
    return response?.data
};

const restoreShippingTemplates = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_RESTORE_SHIPPINGTEMPLATE, payload, {withCredentials: true});
    return response?.data
};

const duplicateShippingTemplate = async (id) => {
    const response = await axios.post(
        constants.APIURL.DUPLICATE_SHIPPINGTEMPLATE + id,
        {withCredentials: true}
    )
    return response;
}

export default {
    fetchShippingTemplate,
    deleteShippingTemplate,
    createShippingTemplate,
    updateShippingTemplate,
    searchShippingTemplate,
    fetchShippingTemplatesFilters,
    bulkDeleteShippingTemplates,
    bulkStatusUpdateShippingTemplates,
    restoreShippingTemplates,
    duplicateShippingTemplate
};
