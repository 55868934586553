/**
 * @author Austin Ames
 * @version 0.1.0
 * @description ProductDetail - a detail tab page of Product
 */

import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {HiPlusCircle} from "react-icons/hi";
import ArbitButton from "../sub-elements/button";
import ArbitImageContainer from "../sub-elements/image-container";
import ArbitUploadButton from "../sub-elements/upload-button";
import ArbitUploadUrlInput from "../sub-elements/upload-url-input";
import ArbitNewAttributeGroupModal from "./attribute-group-modal";
import ArbitNewAttributeModal from "./attribute-modal";
import ArbitAttributeItem from "./attribute-item";
import {toast} from "react-toastify";
import copy from 'copy-to-clipboard';
import SearchableDropdown from "../sub-elements/SearchableInput";
import {
    addProductImages,
    deleteProductImage,
    fetchAttributeGroups,
    fetchAttributes,
    fetchProduct,
    getAttributeTypes
} from '../../store/products'
import util from "../dynamic-page/util";

const ProductDetail = ({productData, id, locale, productLanguage, setProductLanguage}) => {

    const dispatch = useDispatch()
    const [visibleNewAttributeModal, setVisibleNewAttributeModal] = useState(false)
    const attributeGroups = useSelector(state => state.productReducer.attributeGroups)
    const attributeTypes = useSelector(state => state.productReducer.attributeTypes)
    const [visibleNewAttributeGroupModal, setVisibleNewAttributeGroupModal] = useState(false)
    const attributeGroupsData = attributeGroups?.map(item => item?.name)
    const attributesData = useSelector((state) => state.productReducer.attributes)

    const [dispatchListener, setDispatchListener] = useState(false)

    const localeOptions = locale?.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })

    // console.log(localeOptions, 'Locale Options')
    useEffect(() => {
        dispatch(fetchAttributes())
        dispatch(fetchAttributeGroups())
        dispatch(getAttributeTypes())
    }, [dispatchListener, dispatch])

    // useEffect(() => {
    //   h(fetchProduct({ id, productLanguage }))
    // }, [productLanguage, dispatch, id])

    const handleFileChange = async (e) => {
        var files = e.target.files;

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('images[]', files[i])
        }


        await dispatch(addProductImages({id, formData})).unwrap().then((response) => {
            if (response?.status === 204 || response?.status === 200) {
                dispatch(fetchProduct({id, productLanguage}))
                util.notifier({type: response?.data?.type, message: response?.data?.message});
            } else {
                util.notifier({type: response?.data?.type, message: response?.data?.message});
            }
        }).catch(err => {
            util.notifier(err);
        })
    };

    const onCopyUrl = (url) => {
        copy(url);
        toast.info('Copied to clipboard', {
            toastId: "success1",
        });
    }
    const onDelete = async (imageId) => {

        await dispatch(deleteProductImage({id, imageId})).unwrap().then((response) => {
            if (response?.status === 204 || response?.status === 200 || response?.type === 'success') {
                dispatch(fetchProduct({id, productLanguage}))
                util.notifier({type: response?.data?.type, message: response?.data?.message});
            } else {
                util.notifier({type: response?.data?.type, message: response?.data?.message});
            }
        }).catch(err => {
            util.notifier(err);
        })


    }
    const renderPhotos = (sources) => {
        return sources?.map((source, index) => {
            return <ArbitImageContainer src={source.url} key={index} onCopyUrl={onCopyUrl} sourceId={source?.id}
                                        onDelete={onDelete}/>
        })
    }


    if (productData?.attributes) {
        return (

            <div className="flex flex-col px-[16px] relative overflow-y-auto">
                <div className="flex justify-between ">
                    <div className="flex flex-col flex-wrap">
                        <p className="text-[24px] font-[600] text-black">Images</p>
                        <div className="flex space-x-[16px] px-[16px] pt-[8px] ">
                            {renderPhotos(productData?.images)}
                            <ArbitUploadButton onFile={handleFileChange} inputId={'drop-zone2'} multipleImage={true}/>
                            <ArbitUploadUrlInput width={"275px"} />
                        </div>


                    </div>
                    <div className="flex space-x-[8px] right-5">
                        <ArbitButton
                            width={'157px'}
                            onClick={
                                () => {
                                    setVisibleNewAttributeModal(true)
                                }
                            }
                        >
                            <HiPlusCircle className="text-[16px] font-[500]"/>
                            <p className="ml-[4px] text-[14px] font-[500]">Add Attribute</p>
                        </ArbitButton>
                        <div className=' flex flex-col  items-center searchFix'>
                            <SearchableDropdown
                                placeholder={'Select Language'}
                                options={localeOptions}
                                dafaultOption={1}
                                data={productLanguage}
                                setData={setProductLanguage}
                            />

                        </div>
                    </div>
                </div>
                {

                    [...Object.keys(productData?.attributes)]?.map((item, index) => (

                        <div className="flex flex-col space-y-[8px] mt-[20px] w-max">
                            <p className="text-[24px] font-[600] text-black">{[...Object.keys(productData?.attributes)][index]}</p>
                            <div className="flex flex-col px-[8px]">
                                {
                                    productData?.attributes[item]?.map((item, index) => (
                                        <ArbitAttributeItem key={item["id"]} attribute={item}
                                                            name={item["attribute_name"]} value={item["value"]}
                                                            attributeId={item['id']}
                                                            validationRule={item?.attribute?.validation_rules}
                                                            description={item?.attribute?.description} id={id}
                                                            productLanguage={productLanguage}/>
                                    ))
                                }

                            </div>
                        </div>
                    ))
                }
                {
                    visibleNewAttributeModal &&
                    <ArbitNewAttributeModal
                        show={visibleNewAttributeModal}
                        onClose={
                            () => setVisibleNewAttributeModal(false)
                        }
                        attributesData={attributesData}
                        attributeGroups={attributeGroups}
                        attributeTypes={attributeTypes}
                        createNewAttribute={() => setVisibleNewAttributeGroupModal(true)}
                        id={id}
                        attributeLocale={productLanguage}
                        productLanguage={productLanguage}
                    />
                }
                {
                    visibleNewAttributeGroupModal &&
                    <ArbitNewAttributeGroupModal onClose={() => setVisibleNewAttributeGroupModal(false)}
                                                 attributeGroupsData={attributeGroupsData}
                                                 attributeTypes={attributeTypes} attributeGroupsList={attributeGroups}
                                                 onCloseParent={() => setVisibleNewAttributeModal(false)}
                                                 dispatchListener={dispatchListener}
                                                 setDispatchListener={setDispatchListener}
                                                 show={visibleNewAttributeGroupModal}/>
                }

            </div>
        )
    }

}


export default ProductDetail;
