import {createAsyncThunk} from '@reduxjs/toolkit'
import api from './api'
import { fetchLoading } from '.';


const fetchTransfers = createAsyncThunk(
    "get:api/v1/transfers/shipment-labels",
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
            dispatch(fetchLoading());
        }
        const data = await api.fetchTransfers(typeof payload === "string" ? payload : payload.query);
        return { data: data?.data, append: payload?.append };
    }
); 

const fetchSingleTransfer = createAsyncThunk(
    'get:api/v1/transfers/:id',
    async (params, thunkAPI) => {
        const response = await api.fetchSingleTransfer(params);
        return response.data;
    }
)

const fetchTransfersFilters = createAsyncThunk(
    'get:api/v1/transfers',
    async (params, thunkAPI) => {
        const response = await api.fetchTransfersFilters(params);
        return response.data;
    }
)

const fetchNotes = createAsyncThunk(
    'get:api/v1/transfers/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.fetchNotes(params);
        return {orderId: params.orderId, data: response.data};
    }
)
const addNote = createAsyncThunk(
    'post:api/v1/transfers/:id/notes',
    async (params, thunkAPI) => {
        const response = await api.addNote(params);
        return {orderId: params.orderId, data: response.data};
    }
)
const deleteNote = createAsyncThunk(
    'post:api/v1/transfers/:id/notes/:id',
    async (payload, thunkAPI) => {
        const response = await api.deleteNote(payload);
        return response.data;
    }
)

const createShipment = createAsyncThunk(
    'get:api/v1/transfer/create-shipment',
    async (params, thunkAPI) => {
        const response = await api.createShipment(params);
        return {
            orderId: params.order_id,
            warehouseId: params.warehouse_id,
            shipmentPricingId: params.shipment_pricing_id,
            data: response.data
        };
    }
)

export {
    fetchTransfers,
    fetchNotes,
    deleteNote,
    addNote,
    createShipment,
    fetchSingleTransfer,
    fetchTransfersFilters
}