import React, { useMemo } from "react";
import hasValue from "../util/hasValue";

const Container = ({
  children,
  item,
  validationMessage,
  value,
  handleChange,
  style,
  formData,
  setResetSelect,
}) => {
  // Handle Collapse
  const [collapse, setCollapse] = React.useState(true);
  const handleCollapse = useMemo(() => {
    const toggleCollapse = () => {
      if (item?.toggle) setCollapse(!collapse);
    };
    return toggleCollapse;
  }, [item?.toggle, collapse]);

  // Handle Clear
  const handleClearData = (e) => {
    e.stopPropagation();
    handleChange("", item.name);
  };
  // Handle Reset
  const handleResetData = (e) => {
    e.stopPropagation();
    handleChange(item.defaultValue, item.name);
  };

  const optional = item.optional
    ? typeof item.optional === "function"
      ? item.optional(formData)
      : item.optional
    : false;

  // Return View
  return (
    <div
      className={`${item.className || ""} ${
        item.type === "Hidden" ? "hidden" : ""
      }`}
    >
      <div
        className={`flex flex-col  ${!item.toggle ? "gap-[8px]" : ""} ${
          style?.container?.className ? style?.container?.className : ""
        } ${item?.container || ""}`}
      >
        {(item.type !== "Hidden" && !item?.labelClassName?.includes('hidden')) && (
          <div className="">
            <label
              onClick={handleCollapse}
              className={`
                    select-none flex items-center justify-between
                    text-gray-900 font-medium
                    ${item.type === "Select" && "min-w-[332px]"}
                    ${
                      item.toggle
                        ? "   transition cursor-pointer border-b border-gray-300 pb-2 text-[14px] text-gray-500 hover:border-gray-400 hover:text-gray-900"
                        : " text-gray-900 text-[14px] leading-[21px]"
                    }
                    ${
                      item.toggle ? !collapse && " text-gray-900 font-bold" : ""
                    }
                    ${
                      item.toggle
                        ? hasValue(value) &&
                          " text-blue-500 font-bold hover:text-blue-700"
                        : ""
                    }
                    ${
                      item.toggle
                        ? style?.toggle?.className
                          ? style?.toggle?.className
                          : ""
                        : ""
                    }
                    ${style?.label?.className ? style?.label?.className : ""}
                    ${item.labelClassName ? item.labelClassName : ""}`}
            >
              <div className="flex items-center gap-1">
                <span className={"flex flex-row items-center gap-1"}>
                  {item.label}
                  {item.label &&
                    !item?.disabled &&
                    item.reset !== false &&
                    hasValue(item.defaultValue) &&
                    value !== item.defaultValue && (
                      <span onClick={handleResetData}>
                        {" "}
                        <svg
                          className={`text-blue-500 hover:text-blue-700 transition cursor-pointer w-[18px]`}
                          height="1.22em"
                          width="1.22em"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 864 864"
                        >
                          {" "}
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M432,0C193.41,0,0,193.41,0,432s193.41,432,432,432,432-193.41,432-432S670.59,0,432,0Zm226.97,658.8c-17.21,0-31.8-11.16-36.96-26.64l-.18,.06c-8.62-24.28-20.58-47.6-35.83-69.24-59.05-83.79-158.33-129.69-260.42-120.42l-22.81,2.03,97.42,81.22c16.59,13.83,18.83,38.5,5,55.09-13.83,16.59-38.5,18.83-55.09,5l-169.96-141.7c-7.96-6.63-12.97-16.15-13.92-26.47-.92-10.32,2.29-20.59,8.93-28.54l141.66-169.92c13.83-16.59,38.5-18.83,55.09-5,16.59,13.83,18.83,38.5,5,55.09l-81.18,97.38,22.77-2.06c129.9-11.8,256.23,46.61,331.39,153.22,19.41,27.53,34.63,57.2,45.61,88.11l-.08,.03c1.63,4.29,2.53,8.94,2.53,13.81,0,21.52-17.44,38.96-38.96,38.96Z"
                          />
                        </svg>
                      </span>
                    )}
                  {item.label && hasValue(value) && item.clear !== false && (
                    <span onClick={handleClearData}>
                      {" "}
                      <svg
                        className={`text-gray-200 hover:text-gray-300 transition cursor-pointer w-[18px]`}
                        height="1.22em"
                        width="1.22em"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 864 864"
                      >
                        <path
                          className="cls-1"
                          d="M432,0C193.41,0,0,193.41,0,432s193.41,432,432,432,432-193.41,432-432S670.59,0,432,0Zm192,569.11c17.57,17.57,17.57,46.07,0,63.64-8.79,8.79-20.3,13.18-31.82,13.18s-23.03-4.39-31.82-13.18l-137.11-137.11-137.11,137.11c-8.79,8.79-20.3,13.18-31.82,13.18s-23.03-4.39-31.82-13.18c-17.57-17.57-17.57-46.07,0-63.64l137.11-137.11-137.11-137.11c-17.57-17.57-17.57-46.07,0-63.64s46.07-17.57,63.64,0l137.11,137.11,137.11-137.11c17.57-17.57,46.07-17.57,63.64,0,17.57,17.57,17.57,46.07,0,63.64l-137.11,137.11,137.11,137.11Z"
                        />
                      </svg>
                    </span>
                  )}
                </span>
                <span>
                  {item.toggle && !item?.disabled && (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 20 20"
                      className={`text-gray-300 transition text-[24px] leading-[24px] ${
                        collapse && " rotate-180 "
                      }`}
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </span>
                {item?.labelDiscription && (
                  <div
                    className={`text-gray-400  text-[12px] leading-[18px] font-semibold self-center ${
                      style?.labelDiscription?.className
                        ? style?.labelDiscription?.className
                        : ""
                    }`}
                  >
                    {item.labelDiscription}
                  </div>
                              )}
                              {/* // if an icon compopnent is passed renderi t here  */}
                              {item.labelIcon && (
                                <div className="flex ml-0.5 items-center">
                                    {item.labelIcon} 
                                </div>
                              )}
              </div>
              <div className="flex items-center">
              {item?.links && <span className="">{item?.links}</span>}

                {optional && (
                  <span className="text-xs text-gray-600 self-end font-semibold ">
                    opt.
                  </span>
                )}
              </div>
            </label>
          </div>
        )}

{item?.labelDetails && (
                  <div
                    className={`text-gray-500 text-xs -mt-[6px] !leading-[18px] self-end ${
                      style?.labelDetails?.className
                        ? style?.labelDetails?.className
                        : ""
                    }`}
                  >
                    {item.labelDetails}
                  </div>
                              )}

        <div
          className={` h-[100%] w-full
                    ${
                      item.toggle
                        ? "bg-gray-50 p-3 rounded-b-md"
                        : "bg-transparent "
                    }
                    ${item.toggle ? collapse && "hidden" : ""}
                    
                    `}
        >
          {children}

          {validationMessage && (
            <div
              className={`text-red-500 text-sm mt-1 ${
                style?.validation?.className ? style?.validation?.className : ""
              }`}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                className="inline mr-[5px]"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path fill="none" d="M0 0h24v24H0z"></path>{" "}
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>{" "}
              </svg>
              {String(validationMessage)}
            </div>
          )}
          {item.hint && !validationMessage && (
            <div
              className={`text-gray-400 text-sm mt-1 ${
                style?.hint?.className ? style?.hint?.className : ""
              }`}
            >
              {item.hint}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Container;
