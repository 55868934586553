import React from "react";
import {HiExternalLink, HiMail,} from "react-icons/hi";
import moment from "moment";
import {howLongFromNow} from "../../../utils/helper";
import Util from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
import {useNavigate} from "react-router-dom";
// import Components from "../components";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import Icons from "../../orders/icons";
import UI from "../../dynamic-form/elements";
import {IoLogoWhatsapp} from "react-icons/io5";
import descriptiveContent from "../../../utils/descriptiveContent";
import ItemID from "../../dynamic-page/components/ItemID";
import Note from "../../pickup-points/components/note";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
// import InternalNote from "../components/internalNote";
// import Item from "../components/item";

const TableView = (list,tableActions) => {
    const navigate = useNavigate();
    const activeStyle = (status) => {
        if (status) {
            return "!text-blue-500";
        } else {
            return "text-gray-300 hover:text-gray-500";
        }
    };


    const nullHandle = (data, chained) => {
        if (!data || chained == null) {
            return "-";
        } else {
            return chained;
        }
    };
    return {
        list: list ?? [],
        theme: {
            "supply channel id": (item) => (
                <ItemID id={item.id}/>
            ),
            "notes": (item) => (
                <div className="flex justify-between items-center gap-1 ">
                    <Note item={item} callBack={tableActions.onUpdateNote} />
                </div>
            ),
            "type": (item) => (
                <div className="flex flex-col justify-center w-[100%] items-center gap-1 inline-flex">
                    {item.supply_channel_type ? <ArbitNormalBadge
                        title={
                            item.supply_channel_type
                        }
                        bgColor={
                            item.supply_channel_type === 'WEBSITE' ? "blue-100" :
                                item.supply_channel_type === 'WHOLESALER' ? "green-100" :
                                    'yellow-100'
                        }
                        textColor={
                            item.supply_channel_type === 'WEBSITE' ? "blue-700" :
                                item.supply_channel_type === 'WHOLESALER' ? "green-700" :
                                    'yellow-700'
                        }
                    /> : '-'}

                </div>
            ),
            'last update': (item) => {
                return (
                    <util.dateDisplay item={item} date={item?.updated_at} />
                )
            },

            image: (item) => {
                return <div
                    className={
                        "w-[65px] h-[64px] max-h-[64px]  justify-center items-center flex"
                    }
                >
                    {item?.logo ? (
                        <ImageMagnifier
                        src={item?.logo}
                        className={"!max-h-[64px]"}
                      />


                    ) : (
                        <img
                            src={"/assets/images/noImage.png"}
                            className={"h-[52px] w-[52px]"}
                        />
                    )}
                </div>
            },

            name: (item) => (
                <div className="w-[100%] h-16 p-2   flex-col justify-between items-start inline-flex">
                    <div className="self-stretch justify-between items-center inline-flex">
                        <div className="justify-center items-center gap-1.5 flex">
                            {/* <img className="w-4 h-[16.09px]" src="https://via.placeholder.com/16x16" /> */}
                            {
                                item?.primary_contact === 'PHONE' ? (

                                    <IoLogoWhatsapp className="text-emerald-700 w-4 h-[16.09px]"/>
                                ) : item?.primary_contact === 'EMAIL' ? (
                                    <HiMail className="text-red-700 w-4 h-[16.09px]"/>
                                ) : item?.primary_contact === 'WEBSITE' ? (
                                    <HiExternalLink className="text-blue-700 w-4 h-[16.09px] cursor-pointer"
                                                    onClick={() => {
                                                        window.open(item?.url, "_blank");
                                                    }}
                                    />
                                ) : item?.url ? (
                                    <HiExternalLink className="text-blue-700 w-4 h-[16.09px] cursor-pointer"
                                                    onClick={() => {
                                                        window.open(item?.url, "_blank");
                                                    }}
                                    />
                                ) : ''
                            }
                            {
                                item?.primary_contact === 'PHONE' ? (
                                    <div
                                        className="text-emerald-700 text-sm font-normal font-['Inter'] leading-[14px]">{item?.phone}</div>
                                ) : item?.primary_contact === 'EMAIL' ? (
                                    <div
                                        className="text-red-700 text-sm font-normal font-['Inter'] leading-[14px]">{item?.email}</div>
                                ) : item?.primary_contact === 'WEBSITE' ? (
                                    // <div className="text-blue-700 text-sm font-normal font-['Inter'] leading-[14px]">{item?.url}</div>
                                    <util.popUp
                                        toggle={(selected) => (
                                            // a div that contain the url but shows only 30 characters
                                            <div
                                                className="text-blue-700 text-sm font-normal font-['Inter'] leading-[14px]"
                                                onClick={() => {
                                                    window.open(item?.url, "_blank");
                                                }}
                                            >
                                                {item?.url?.length > 30 ? item?.url?.substring(0, 30) + "..." : item?.url}
                                            </div>
                                        )}
                                        action='hover'
                                    >
                                        {item?.url}
                                    </util.popUp>
                                ) : item?.url ? (
                                    <util.popUp
                                        toggle={(selected) => (
                                            // a div that contain the url but shows only 30 characters
                                            <div
                                                className="text-blue-700 text-sm font-normal font-['Inter'] leading-[14px]"
                                                onClick={() => {
                                                    window.open(item?.url, "_blank");
                                                }}
                                            >
                                                {item?.url?.length > 30 ? item?.url?.substring(0, 30) + "..." : item?.url}
                                            </div>
                                        )}
                                        action='hover'
                                    >
                                        {item?.url}
                                    </util.popUp>
                                ) : '-'
                            }
                        </div>
                        {item?.country ? <div className="justify-start items-center gap-2 flex">
                            <img
                                src={item?.country?.flag}
                                className="w-[21px] h-[15px] "/>

                            <div
                                className="text-center text-gray-900 text-sm font-normal font-['Inter'] leading-[21px]">
                                {item?.country?.name}
                            </div>
                        </div> : '-'}

                    </div>
                    <div className="justify-center items-center gap-1 inline-flex">
                        <div className="text-gray-900 text-base font-medium font-['Inter'] leading-normal">
                            {item?.name}
                        </div>
                    </div>
                </div>
            ),

            'listings': (item) => (
                <div className="w-[131px] h-16  py-3   flex-col justify-center items-end gap-0.5 inline-flex">
                    <div className="justify-center items-center gap-1 inline-flex">
                        <div className="text-blue-700 text-2xl font-bold font-['Inter'] leading-normal">
                            {descriptiveContent(item?.listings_count, 'Total listings')}
                        </div>
                    </div>
                    <div className="justify-center items-center gap-1 inline-flex">
                        <Icons.link2 className="w-3 h-3 text-blue-700"/>
                        <div className="text-blue-700 text-base font-medium font-['Inter'] leading-none">
                            {descriptiveContent(item?.connected_listings_count, 'Connected listings')}
                        </div>
                    </div>
                </div>
            ),
        },
    };
};
export default TableView;
