import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchBalances = createAsyncThunk(
    "get:api/v1/balance",
    async (payload, thunkAPI) => {
        const response = await api.fetchBalance(payload);
        return response;
    }
);

const fetchBalanceFilters = createAsyncThunk(
    "get:api/v1/balance/filters",
    async (thunkAPI) => {
        const response = await api.fetchBalanceFilters();
        return response;
    }
);

const createBalance = createAsyncThunk(
    "post:api/v1/balance",
    async (payload, thunkAPI) => {
        const response = await api.createBalance(payload);
        return response;
    }
);

const updateBalance = createAsyncThunk(
    "put:api/v1/balance",
    async (payload, thunkAPI) => {
        const response = await api.updateBalance(payload);
        return response;
    }
);

const deleteBalance = createAsyncThunk(
    "delete:api/v1/balance",
    async (payload, thunkAPI) => {
        const response = await api.deleteBalance(payload);
        return response;
    }
);

const searchBalances = createAsyncThunk(
    "get:api/v1/balance/search",
    async (payload, thunkAPI) => {
        const response = await api.searchBalance(payload);
        return response.data;
    }
);

const updateBalanceStatus = createAsyncThunk(
    "put:api/v1/balance/bulk/status",
    async (payload, thunkAPI) => {
        const response = await api.updateBalanceStatus(payload);
        return response;
    }
);

const bulkDeleteBalance = createAsyncThunk(
    "delete:api/v1/balance/bulk",
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteBalance(payload);
        return response;
    }
);

const restoreBalance = createAsyncThunk(
    "post:api/v1/balance/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreBalance(payload);
        return response;
    }
);

export {
    fetchBalances,
    createBalance,
    updateBalance,
    deleteBalance,
    searchBalances,
    updateBalanceStatus,
    bulkDeleteBalance,
    restoreBalance,
    fetchBalanceFilters
};
