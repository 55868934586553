import upperCaseString from "../../dynamic-page/util/upperCaseString";
import { useSelector } from "react-redux";
import React from "react";

const FiltersView = (selectedFilers) => {
  const filtersMeta = useSelector((state) => state.supplyPurchase?.filtersMeta);
  return [
    {
      name: "qty",
      label: "Quantity",
      placeholder: "Filter by quantity",
      type: "Range",
      defaultValue: selectedFilers?.qty,
      toggle: true,
      clear: true,
    },

    // add for total , total_due , purchase_price, sub_total, discount

    {
      name: "reason",
      label: "Reason",
      placeholder: "Filter by Reason",
      type: "RadioButton",
      // list:  filtersMeta?.types?.map((item) => ({
      //   label: upperCaseString(item?.name),
      //   value: item?.id,
      //   count: item?.count
      // })),
      list: [
        {
          label: "Order",
          value: "order",
        },
        {
          label: "Warehouse Stock",
          value: "warehouse_restocking",
        },
        {
          label: "Fulfilment Stock",
          value: "fulfilment_stock",
        },
      ],

      defaultValue: selectedFilers?.reason,
      toggle: true,
      radioContainerClass: "flex",
    },
    {
      name: "not_completed_purchases",
      label: "Status",
      placeholder: "Filter by Reason",
      type: "RadioButton",
      // list:  filtersMeta?.types?.map((item) => ({
      //   label: upperCaseString(item?.name),
      //   value: item?.id,
      //   count: item?.count
      // })),
      list: [
        {
          label: "Not Completed",
          value: "Not Completed",
        },

      ],

      defaultValue: selectedFilers?.not_completed_purchases,
      toggle: true,
      radioContainerClass: "flex",
    },
    {
      name: "request_by",
      label: "Requested By",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users?filter[status]=1",
        view: "/api/v1/employees",
        skipCancel: true,
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.employee_id || item?.id,
          label: item?.name,
          avatar: item?.image_url || item?.profile_image,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.request_by,
      toggle: true,
      clear: true,
    },

    {
      name: "product_id",
      label: "Product",
      type: "Select",
      api: {
        url: "/api/v1/product",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.title, "word", true),
        }),
      },
      features: {
        isMulti: true,
      },
      reset: true,
      clear: true,
      toggle: true,
      style: {
        control: {
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      placeholder: "Filter by products",
      defaultValue: selectedFilers?.product_id,
    },
    {
      name: "brand_id",
      label: "Brand",
      placeholder: "Select Brand",
      type: "Select",
      api: {
        url: "/api/v1/brands",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.brand_id || null,
      toggle: true,
      clear: true,
    },
    {
      name: "assign_id",
      label: "Assigned Employee",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users?filter[status]=1",
        view: "/api/v1/employees",
        skipCancel: true,
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.employee_id || item?.id,
          label: item?.name,
          avatar: item?.image_url || item?.profile_image,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.assign_id,
      toggle: true,
      clear: true,
    },
    {
      name: "created_at",
      label: "Created At",
      placeholder: "Filter by Date",
      type: "DateRange",
      defaultValue: selectedFilers?.created_at,
      toggle: true,
      clear: true,
  },
  {
    name: "updated_at",
    label: "Updated At",
    placeholder: "Filter by Date",
    type: "DateRange",
    defaultValue: selectedFilers?.updated_at,
    toggle: true,
    clear: true,
},
    {
      name: "supplier",
      label: "Supply Channel",
      placeholder: "Filter by Supply Channel",
      type: "Select",
      api: {
        url: "/api/v1/channel",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          logo: item?.logo,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img src={logo || "/assets/images/img-placehoder.png"} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.supplier,
      toggle: true,
      clear: true,
    },
  ];
};

export default FiltersView;
