import handleIsFile from "../../../../../dynamic-form/util/handleIsFile";
import convertJsonToFormData from "../../../../../dynamic-form/util/convertJsonToFormData";
import filterObjectByKeys from "../../../../../dynamic-page/util/filterObjectByKeys";


const prepareDataToAddItem = async (detailData) => {

    let innerItem = filterObjectByKeys(detailData, [

        "title",
        "sku",
        "listing_id",
        "upc",
        "qty",
        "unit_price",
        "arbitbox_product_id",
        "unit_price_currency",
    ])
    if (!detailData.arbitbox_product_id) delete innerItem.arbitbox_product_id;
    innerItem.image = handleIsFile(detailData.image)


    return convertJsonToFormData(innerItem)

};

export default prepareDataToAddItem;