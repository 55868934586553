import React, { useRef, useState, useEffect } from 'react';
import util from './index';
import Icons from '../../orders/icons';

function ExpandableText({
                            callBack,
                            children,
                            maxLines = 1,
                            className,
                            tooltip = false,
                            tooltipClassName = '!max-w-[450px]',
                            forcePosition = 'bottom',
                            action = 'hover',
                            delay = 500,
                            hideCopy,
                            innerClassName,
                        }) {
    const innerRef = useRef();
    const [isTruncated, setIsTruncated] = useState(false);

    const checkIfTruncated = () => {
        const element = innerRef.current;
        if (element) {
            const isOverflowing =
                element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
            setIsTruncated(isOverflowing);
        }
    };

    useEffect(() => {
        // Initial check if the text is truncated
        checkIfTruncated();

        // Re-check on window resize
        window.addEventListener('resize', checkIfTruncated);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkIfTruncated);
        };
    }, [children, maxLines]);

    const IsTooltip = ({ children, text }) => (
        <>
            {(tooltip && isTruncated) ? (
                <util.popUp
                    toggle={() => <>{children}</>}
                    forcePosition={forcePosition}
                    className={`!text-[14px] !font-medium !leading-[21px] ${tooltipClassName && tooltipClassName}`}
                    action={'hover'}
                >
                    {text}
                </util.popUp>
            ) : (
                <>{children}</>
            )}
        </>
    );

    return (
        <div className={'relative group w-full'}>
            <style>
                {`
                .expandableText:not(.expanded)  {
                    display: -webkit-box;
                    -webkit-line-clamp: ${maxLines};
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    white-space-collapse:preserve ;
                    word-break: break-word;
                    max-height: calc(${maxLines} * 1.4em) !important;
                }

                .ellipsis-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;
                    right: 0;
                    bottom: 25%;
                }

                .ellipsis-button {
                    display: inline-block;
                    padding-right: 4px;
                }
                `}
            </style>
            {children && (
                <IsTooltip text={children}>
                    <div
                        className={` expandableText ${className} ${
                            tooltip ? 'tooltip' : ''
                        }`}
                    >
                        <div onClick={callBack && callBack}  ref={innerRef} className={`inner ${innerClassName}  !w-[calc(100%-24px)]`}>
                            {children}
                        </div>
                        
                        <div className="ellipsis-container">
                            {/* Copy Icon */}
                            {!hideCopy && (
                                <span
                                    onClick={() => navigator.clipboard.writeText(children)}
                                    className="cursor-pointer px-1 pt-1 rounded text-gray-300 hover:text-gray-500 group-active:text-blue-800"
                                >
                                    <Icons.copyOrders className='w-[14px] h-[14px]' />
                                </span>
                            )}
                        </div>
                    </div>
                </IsTooltip>
            )}
        </div>
    );
}

const CopyIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 20 20"
        className="!w-4 !h-4"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
        <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
    </svg>
);

export default React.memo(ExpandableText);