import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchCategories = createAsyncThunk(
    'get:api/v1/categories',
    async (params, thunkAPI) => {
        const response = await api.fetchCategories(params)
        // console.log(response, 'response Categories Dummy Data')
        return response.data
    }
)

const fetchListingSources = createAsyncThunk(
    'get:api/v1/listing-sources',
    async (params, thunkAPI) => {
        const response = await api.fetchListingSources(params)

        return response.data
    }
)

const fetchListingSourceCategories = createAsyncThunk(
    'get:api/v1/source-categories',
    async (params, thunkAPI) => {
        const response = await api.fetchListingSourceCategories(params)

        return response.data
    }
)
const updateCategoryMap = createAsyncThunk(
    'put:api/v1/source-categories/:id/map',
    async ({id, payload}) => {
        // console.log(id, payload, 'From here')
        const response = await api.updateCategoryMap(id, payload)
        return response
    }
)

const fetchCategoriesFilters = createAsyncThunk(
    'get:api/v1/categories/filters',
    async (params, thunkAPI) => {

        const response = await api.fetchCategoriesFilters(params)
        return response.data
    }
)

const createCtegory = createAsyncThunk(
    'post:api/v1/categories',
    async (params, thunkAPI) => {
        const response = await api.createCtegory(params)
        return response.data
    }
)

const updateCategory = createAsyncThunk(
    'put:api/v1/categories/:id',
    async (payload, thunkAPI) => {
        const response = await api.updateCategory(payload)
        return response.data
    }
)

const deleteCategory = createAsyncThunk(
    'delete:api/v1/categories/:id',
    async (id, thunkAPI) => {

        const response = await api.deleteCategory(id, thunkAPI)
        return response

    }
)

const bulkDeleteCategory = createAsyncThunk(
    'delete:api/v1/categories',
    async (payload, thunkAPI) => {
        const response = await api.bulkDeleteCategory(payload)
        return response.data
    }
)

const bulkUpdateCategory = createAsyncThunk(
    'put:api/v1/categories',
    async (payload, thunkAPI) => {
        const response = await api.bulkUpdateCategory(payload)
        return response.data
    }
)

const restoreCategory = createAsyncThunk(
    'put:api/v1/categories/:id/restore',
    async (id, thunkAPI) => {
        const response = await api.restoreCategory(id)
        return response.data
    }
)

export {
    fetchCategories,
    fetchListingSources,
    fetchListingSourceCategories,
    updateCategoryMap,
    fetchCategoriesFilters,
    createCtegory,
    updateCategory,
    deleteCategory,
    bulkDeleteCategory,
    bulkUpdateCategory,
    restoreCategory
}