import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchShippingPricingList = createAsyncThunk(
    'get:api/v1/shipment_pricing',
    async (params, thunkAPI) => {
        const response = await api.fetchShippingPricingList(params)
        return response.data
    }
)

const fetchShippingPricingListFilters = createAsyncThunk(
    'get:api/v1/shipment_pricing/filters',
    async (thunkAPI) => {
        const response = await api.fetchShippingPricingListFilters()

        return response.data
    }
)

const addShippingPricingList = createAsyncThunk(
    'post:api/v1/shipment_pricing',
    async (payload) => {
        // console.log(payload, 'thunk 2nd // console');
        const response = await api.addShippingPricingList(payload)
        return response.data
    }
)
const updateShippingPricingList = createAsyncThunk(
    'put:api/v1/shipment_pricing/:id',
    async ({id, payload}) => {
        // console.log(id, payload, 'thunk now');
        const response = await api.updateShippingPricingList(id, payload)
        return response.data
    }
)
const updateShippingPricingListStatus = createAsyncThunk(
    'put:api/v1/shipment_pricing/:id/change-status',
    async ({id, payload}) => {
        // console.log(id, payload, 'thunk now For User Status');
        const response = await api.updateShippingPricingListStatus(id, payload)
        return response.data
    }
)
const updateBulkShippingPricingListStatus = createAsyncThunk(
    'patch:/api/v1/shipment_pricing/status/bulk-action',
    async (payload, thunkAPI) => {
        const response = await api.updateBulkShippingPricingListStatus(payload)
        return response.data;
    }
)

const deleteBulkShippingPricingList = createAsyncThunk(
    'delete:/api/v1/shipment_pricing/delete/bulk-action',
    async (payload, thunkAPI) => {
        // console.log(payload, 'Thunk')
        const response = await api.deleteBulkShippingPricingList(payload)
        return response.data;
    }
)
const duplicateShippingPricingList = createAsyncThunk(
    'post:/api/v1/shipment_pricing/duplicate/',
    async (id, thunkAPI) => {
        // console.log(id, 'ID')
        const response = await api.duplicateShippingPricingList(id)
        return response.data;
    }
)
const deleteShippingPricingList = createAsyncThunk(
    'delete:/api/v1/shipment_pricing/',
    async (id, thunkAPI) => {
        // console.log(id, 'ID')
        const response = await api.deleteShippingPricingList(id)
        return response.data;
    }
)

export {
    fetchShippingPricingList,
    fetchShippingPricingListFilters,
    addShippingPricingList,
    updateShippingPricingList,
    updateShippingPricingListStatus,
    updateBulkShippingPricingListStatus,
    deleteBulkShippingPricingList,
    duplicateShippingPricingList,
    deleteShippingPricingList
}
