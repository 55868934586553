import ImageMagnifier from "../../../../../dynamic-page/debt/magnifier";

import priceHandler from "../../../../../../utils/priceHandler";

import upperCaseString from "../../../../../dynamic-page/util/upperCaseString";
import React from "react";
import Icons from "../../../../../orders/icons";
import Util from "../../../../../dynamic-page/util";
import { HiExternalLink } from "react-icons/hi";
import moment from "moment";
import SingleGroupProductBadge from "../../../../../dynamic-page/components/SingleGroupProductBadge";
import PropTypes from "prop-types";
import ExpandableText from "../../../../../dynamic-page/util/expandableText";
import CopyText from "../../../../../dynamic-page/util/copyText";
import PopupUp from "../../../../../dynamic-page/util/popUp";
import EditableText from "../../../../../orders/components/table/saveWeight";

const ProductName = ({ item, hide, user }) => (
  <div className="flex flex-row w-[100%] h-[54px]">
    <div
      className={
        "max-w-[54px] max-h-[54px] flex flex-col justify-center items-center"
      }
    >
      <ImageMagnifier src={item?.image} className={"!max-h-[50px]"} />
    </div>
    <div className={` p-1 flex flex-col justify-center w-[100%] `}>
      <div className={"flex flex-row gap-[8px] cursor-pointer"}>
        <ExpandableText
          maxLines={2}
          iconClass={"!w-[14px] !h-[14px] "}
          productCopyIcon={true}
          className={"text-[14px] leading-[24px] text-gray-900  "}
          tooltip={true}
          callBack={() => {
            window.open(`/product/${item?.id}`, "_blank");
          }}
        >
          {Util.upperCaseString(item?.title)}
        </ExpandableText>
      </div>
      <div className={"flex justify-between "}>
        <div className={"flex flex-row gap-[4px] text-gray-500"}>
          {!hide?.type && <SingleGroupProductBadge product={item} />}

          <button
            className={
              "text-[12px] cursor-pointer leading-[14px] flex items-center gap-1 font-medium text-blue-500 "
            }
            onClick={() => {
              window.open(`/product/${item?.id}`, "_blank");
            }}
          >
            {Util.shortenString(item?.apid, 30)}{" "}
          </button>
          <CopyText
            className={"text-[9px]"}
            text={item?.apid}
            hint={false}
            productCopyIcon={true}
          />
        </div>

        <div className="flex items-center gap-[2px] !text-[12px] cursor-pointer">
          {user ? (
            <div
              className={
                "text-blue-700 font-medium text-[12px] items-center leading-normal flex flex-row gap-[4px] "
              }
            >
              {user && (
                <img
                  alt="pp"
                  src={user?.image_url || "/assets/images/defaultAvatar.png"}
                  className={"w-[20px] h-[20px] rounded-full object-cover"}
                />
              )}
              {Util.upperCaseString(user?.name, "word")}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></button>
            </div>
          ) : (
            <div className="text-gray-400 flex gap-1 items-center">
              <span>Unassigned</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></button>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

const Track = Icons.track;

const tableView = (list, action, isView, type, status) => {
  // a funtion that return true if wich weight is beigger actual or dimentional
  const billableWeight = (item) => {
    if (type === "order") {
      // compare the actual weight and dimentional weight of the order and return 'actual' or 'dimentional' according to which is bigger
      return item?._data?.order?.order?.actual_weight >
        item?._data?.order?.order?.dim_weight
        ? "actual"
        : "dimentional";
    }
    if (type === "product") {
      // compare the actual weight and dimentional weight of the product and return 'actual' or 'dimentional' according to which is bigger
      return item?._data?.product?.product?.fbm_actual_weight >
        item?._data?.product?.product?.fbm_dimensional_weight
        ? "actual"
        : "dimentional";
    }
  };

  let viewList = {};
  if (type === "order") {
    viewList = {
      "order date": (item) => (
        <div className="h-[54px] p-4 justify-start items-center inline-flex">
          <div className="text-gray-500 text-sm w-[85px] font-normal font-['Inter'] leading-[21px]">
            {moment(item?._data?.order?.order?.order_date).format(
              "MMM DD, YYYY"
            )}
          </div>
        </div>
      ),
      image: (item) => (
        <div
          className={
            "max-w-[54px] max-h-[54px] flex flex-col justify-center items-center"
          }
        >
          <ImageMagnifier
            src={item?._data?.order?.order?.image}
            className={"!max-h-[50px]"}
          />
        </div>
      ),
      "order Id": (item) => {
        console.log(item);
        return (
          <div className="h-[54px] p-4 gap-1 justify-start items-center  inline-flex">
            {item?._data?.order?.order?.order_numbers?.length > 1 && (
              <PopupUp
                toggle={() => (
                  <div
                    className={
                      "bg-blue-100 py-[1px] flex items-center  min-w-[20px] h-[20px] px-[6px] text-blue-800 rounded-full hover:bg-blue-200 transition-all"
                    }
                  >
                    {item?._data?.order?.order?.order_numbers?.length}
                  </div>
                )}
                action="click"
              >
                <div className="flex flex-col gap-1 p-2">
                  {item?._data?.order?.order?.order_numbers?.map(
                    (order, index) => (
                      <div
                        key={item?._data?.order?.order?.id}
                        className="flex justify-between items-center gap-2"
                      >
                        <span className="text-gray-700 font-medium">
                          {order}
                        </span>
                        <div>
                          <CopyText
                            className={"text-[12px]"}
                            text={order}
                            hint={false}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </PopupUp>
            )}
            <div className="text-[#111928] text-sm font-medium  font-['Inter'] leading-[21px]">
              {item?._data?.order?.order?.order_numbers?.[0]}
            </div>
            <CopyText
              className={
                "!h-3 !w-3  text-gray-300 hover:text-gray-500 cursor-pointer"
              }
              text={item?._data?.order?.order?.order_numbers?.[0]}
              hint={false}
              // productCopyIcon={true}
            />
            <HiExternalLink
              className="h-3 w-3  text-gray-300 cursor-pointer hover:text-gray-500"
              onClick={() => {
                window.open(
                  `/logistics/orders/#action=details&orderId=${item?._data?.order?.order?.id}
                      `,
                  "_blank"
                );
              }}
            />
          </div>
        );
      },
      carrier: (item) => {
        const data = item?._data?.order?.order;
        const primaryLabel = data?.carrier_name || "-";
        const primaryLogo = data?.carrier_logo;

        return (
          <div
            className={`p-[16px] flex flex-row gap-[4px] text-[14px] items-center font-medium`}
          >
            {primaryLogo ? (
              <img
                src={primaryLogo}
                alt="logo"
                className={"bg-none h-[16px] w-4"}
              />
            ) : (
              <Track className={"w-[16px] h-4 text-gray-300"} />
            )}
            {upperCaseString(primaryLabel)}
          </div>
        );
      },
      "tracking id": (item) => {
        const data = item?._data?.order?.order;
        const trackingNumber = data?.tracking_number;
        const trackingUrl = data?.tracking_url;

        return (
          <div className="h-[54px] p-4 justify-start items-center gap-1 inline-flex">
            <div className="text-gray-500 text-sm font-medium font-['Inter'] leading-[21px]">
              {trackingNumber || "-"}
            </div>
            {trackingNumber && (
              <CopyText
                className={
                  "!h-3 !w-3 text-gray-300 hover:text-gray-500 cursor-pointer"
                }
                text={trackingNumber}
                hint={false}
              />
            )}
            {trackingUrl ? (
              <HiExternalLink
                className="h-3 w-3 text-gray-300 hover:text-gray-500"
                onClick={() => {
                  window.open(trackingUrl, "_blank");
                }}
              />
            ) : (
              <HiExternalLink className="h-3 w-3 text-gray-300" />
            )}
          </div>
        );
      },
      "act. weight": (item) => (
        <div
          className={`text-gray-500 text-sm  ${
            billableWeight(item) === "actual" ? "font-bold" : "font-normal"
          }
         font-['Inter'] leading-[21px]`}
        >
          {item?._data?.order?.order?.actual_weight
            ? (+item?._data?.order?.order?.actual_weight).toFixed(2) + " kg"
            : "-"}
        </div>
      ),
      "dim. weight": (item) => (
        <div
          className={`text-gray-500 text-sm  ${
            billableWeight(item) === "dimentional" ? "font-bold" : "font-normal"
          }
         font-['Inter'] leading-[21px]`}
        >
          {item?._data?.order?.order?.dim_weight
            ? (+item?._data?.order?.order?.dim_weight).toFixed(2) + " kg"
            : "-"}
        </div>
      ),
      "sold price": (item) => (
        <div className="h-[54px] p-4 justify-end items-center inline-flex">
          <div className="text-[#111928] text-sm font-semibold font-['Inter'] leading-[21px]">
            ${priceHandler(item?._data?.order?.order?.total_paid)}
          </div>
        </div>
      ),
      delete: (item) => (
        <div className="text-gray-500 w-[54px] flex justify-center  text-sm font-normal font-['Inter'] leading-[21px]">
          <Icons.TrashBin
            className={
              "w-[18px] h-[18px]  text-red-300 cursor-pointer hover:text-red-500"
            }
            onClick={() => {
              action({
                action: "removeItem",
                data: item,
              });
            }}
          />
        </div>
      ),
    };
  } else {
    viewList = {
      image: (item) => (
        <div
          className={
            "max-w-[54px] max-h-[54px] flex flex-col justify-center items-center"
          }
        >
          <ImageMagnifier
            src={
              item?._data?.product?.product?.image ||
              item?.image ||
              item?.item_image
            }
            className={"!max-h-[50px]"}
          />
        </div>
      ),
      product: (item) => (
        <ProductName
          item={item?._data?.product?.product}
          user={item?._data?.product?.product?.assigned}
          hide={{ assignFilter: true, brandFilter: true }}
        />
      ),
      quantity: (item) => (
        <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
          {status === "1" ? (
            <EditableText
              text={item?.qty || ""}
              buttonClassName={"!h-[12px] !w-[12px]"}
              onSave={(data) => {
                action({
                  action: "editItem",
                  data: {
                    ...item,
                    qty: data,
                  },
                });
              }}
              className={
                "min-w-max whitespace-no-wrap group-active:text-gray-800 hover:text-gray-600 flex text-[14px] text-gray-500 font-[500]"
              }
            />
          ) : (
            item?.qty || ""
          )}
        </div>
      ),
      "act. weight": (item) => (
        <div
          className={`text-gray-500 text-sm  ${
            billableWeight(item) === "actual" ? "font-bold" : "font-normal"
          }
         font-['Inter'] leading-[21px]`}
        >
          {item?._data?.product?.product?.fbm_actual_weight
            ? (
                +item?._data?.product?.product?.fbm_actual_weight * +item?.qty
              ).toFixed(2) + " kg"
            : "-"}
        </div>
      ),
      "dim. weight": (item) => (
        <div
          className={`text-gray-500 text-sm  ${
            billableWeight(item) === "dimentional" ? "font-bold" : "font-normal"
          }
         font-['Inter'] leading-[21px]`}
        >
          {item?._data?.product?.product?.fbm_dimensional_weight
            ? (
                +item?._data?.product?.product?.fbm_dimensional_weight *
                +item?.qty
              ).toFixed(2) + " kg"
            : "-"}
        </div>
      ),
      "T. cost": (item) => (
        <div className="h-[54px] p-4 justify-end items-center inline-flex">
          <div className="text-[#111928] text-sm font-semibold font-['Inter'] leading-[21px]">
            {item?._data?.product?.product?.average_cost_usd
              ? "$" +
                priceHandler(
                  item?._data?.product?.product?.average_cost_usd * +item.qty
                )
              : "-"}
          </div>
        </div>
      ),
      delete: (item) => (
        <div className="text-gray-500  w-[54px] flex justify-center  text-sm font-normal font-['Inter'] leading-[21px]">
          <Icons.Trashbin
            className={
              "w-[18px] h-[18px]  text-red-300 cursor-pointer hover:text-red-500"
            }
            onClick={() => {
              action({
                action: "removeItem",
                data: item,
              });
            }}
          />
        </div>
      ),
    };
  }
  ProductName.propTypes = {
    item: PropTypes.shape({
      image: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      apid: PropTypes.string,
    }),
    hide: PropTypes.shape({
      type: PropTypes.bool,
    }),
    user: PropTypes.shape({
      image_url: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  const initialTheme = () => {
    return type === "order"
      ? {
          "order date": viewList["order date"],
          image: viewList["image"],
          "order Id": viewList["order Id"],
          carrier: viewList["carrier"],
          "tracking id": viewList["tracking id"],
          "actual w.": viewList["act. weight"],
          "dim w.": viewList["dim. weight"],
          price: viewList["sold price"],
          delete: viewList["delete"],
        }
      : {
          product: viewList["product"],
          qty: viewList["quantity"],
          "actual w.": viewList["act. weight"],
          "dim w.": viewList["dim. weight"],
          "T. cost": viewList["T. cost"],
          delete: viewList["delete"],
        };
  };

  return {
    list: list,
    theme: initialTheme(),
  };
};
export default tableView;
