//import { t } from 'i18next';
import validator from 'validator';

export function onEmailValidator(email) {
    if (!validator.isEmail(email)) return "Email is not correct"
    return ""
}

export function onPasswordValidator(password) {

    if (password.length < 8) {
        return "Minimum length is 8"
    }
    return ""
}

export function onPasswordConfirmValidator(password, password2) {
    if (password !== password2) return "Password is not the same"
    return ""
}

export function onEmptyValidator(value) {
    if (value) return ""
    return "This field must be filled."
}