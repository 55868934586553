import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';
import { fetchLoading } from ".";

const fetchSalesListings = createAsyncThunk(
    'get:api/v1/account-listing/fc-restocking',
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
          dispatch(fetchLoading());
        }
        const data = await api.fetchSalesListings(
          typeof payload === "string" ? payload : payload.query
        );
        return { ...data?.data, append: payload?.append };
      }
);

const fetchSalesListing = createAsyncThunk(
    'get:api/v1/account-listing/fc-restocking/:id',
    async (id, thunkAPI) => {
        const response = await api.fetchSalesListing(id)

        return response.data
    }
);

const refreshAccountListings = createAsyncThunk(
    'post:api/v1/account-listing/refresh',
    async (payload, thunkAPI) => {
        try {
            // Assuming payload.identifiers is an array of identifiers
            const response = await api.refreshAccountListings({identifiers: payload.identifiers});
            return response; // Assuming the API response contains the updated data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const fetchDefaultSettings = createAsyncThunk(
    'get:api/v1/account-listing/fc-restocking-settings',
    async (params, thunkAPI) => {
        const response = await api.fetchDefaultSettings(params)
        return response
    }
);

export {fetchSalesListings, fetchSalesListing, refreshAccountListings, fetchDefaultSettings}
