import axios from '../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

const fetchShippingCostList = async (params) => {
    let url = constants.APIURL.GET_SHIPPINGCOSTLIST
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    return response
}

const updateOpportunities = async ({payload}) => {
    const response = await axios.post(constants.APIURL.GET_OPPORTUNITIES_UPDATE, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}

const fetchShippingCostListFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_SHIPPINGCOSTLIST_FILTERS, {withCredentials: true});

    return response
}

const bulkUpdateShippingCostList = async (payload) => {
    const response = await axios.post(constants.APIURL.BULK_UPDATE_SHIPPINGCOSTLIST, apiutils.obj2Form(payload), {withCredentials: true})
    return response
}

const updateBulkShippingCostListStatus = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_SHIPPING_COST_LIST, payload, {withCredentials: true})
    return response;
}
const deleteBulkShippingCostList = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BULK_SHIPPING_COST_LIST, {data: payload})
    return response;
}

const restoreShippingCost = async (params) => {
    const response = await axios.post(constants.APIURL.GET_SHIPPINGCOSTLIST + '/' + params + '/restore', {withCredentials: true})
    return response
}

export default {
    fetchShippingCostList,
    updateOpportunities,
    fetchShippingCostListFilters,
    bulkUpdateShippingCostList,
    updateBulkShippingCostListStatus,
    deleteBulkShippingCostList,
    restoreShippingCost
}
