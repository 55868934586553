const sortView = ({callback}) => {
    return {
        list: [

            {
                label: "Created At",
                value: "created_at",
                onClick: (item) => {
                    callback("created_at");
                }
            },
            // sort for these with small letters
            // [ 'Type', 'Expiry_Date', 'Created_At', 'Last_Update' , 'Quantity', 'Created_By', 'Warehouse' , 'Product' ]
            {
                label: "Type",
                value: "type",
                onClick: (item) => {
                    callback("type");
                }
            },
            {
                label: "Expiry Date",
                value: "expiry_date",
                onClick: (item) => {
                    callback("expiry_date");
                }
            },
            {
                label: "Quantity",
                value: "quantity",
                onClick: (item) => {
                    callback("quantity");
                }
            },
            {
                label: "Warehouse",
                value: "warehouse",
                onClick: (item) => {
                    callback("warehouse");
                }
            },
            {
                label: "Product",
                value: "product",
                onClick: (item) => {
                    callback("product");
                }
            },
            {
                label: "Created By",
                value: "created_by",
                onClick: (item) => {
                    callback("created_by");
                }
            },
            {
                label: "Last Update",
                value: "updated_at",
                onClick: (item) => {
                    callback("updated_at");
                }
            },

        ]
    }
}

export default sortView;