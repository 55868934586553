import * as Yup from "yup";

const formView = (formDefaultValue) => {
    return [
        {
            name: "seller_id",
            label: "Seller ID",
            placeholder: "A1MD5K8C89J673, A1U6QAYTU1AKLE, A1WI1GWXT6NKM4...",
            type: "Input",
            defaultValue: formDefaultValue?.name || null,
            className: "col-span-2",
            validation: Yup.string().required("Seller ID is required"),
        },

        // {
        //   name: "sales_channel_id",
        //   defaultValue: formDefaultValue?.salesChannel?.id || null,
        //   label: "Sales Channel",
        //   placeholder: "Select Sales Channel",
        //   type: "Select",
        //   isClearable: true,
        //   disabled: formDefaultValue ? true : false,
        //   features: {
        //     formatOptionLabel: ({ label, logo }) => (
        //       <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
        //         {logo && (
        //           <span
        //             className={
        //               "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
        //             }
        //           >
        //             <img src={logo?.toLowerCase()} />
        //           </span>
        //         )}
        //         <span>{upperCaseString(label)}</span>
        //       </div>
        //     ),
        //   },
        //   api: {
        //     url: constants.APIURL.GET_CHANNELS,
        //     query: "?filter[name]={query}&not_deleted=true",
        //     optionValue: (item) => ({
        //       value: item.id,
        //       label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        //       logo: item?.logo,
        //     }),
        //   },
        //   validation: Yup.string().required("Sales Channel is Required"),
        //   className: "col-span-2",
        // },

        // {
        //   name: "description",
        //   label: "Description",
        //   placeholder: "Enter Description",
        //   type: "Textarea",
        //   rows: "3",
        //   defaultValue: formDefaultValue?.description || null,
        //   className: "col-span-2",
        //   optional: true,
        // },
    ];
};
export default formView;

