import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";
import { fetchLoading } from "./index";

const fetchGoodsAcceptance = createAsyncThunk(
    "get:api/v1/goods-acceptance",
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
          dispatch(fetchLoading());
        }
        const data = await api.fetchGoodsAcceptance(
          typeof payload === "string" ? payload : payload.query
        );
        return { ...data?.data, append: payload?.append };
      }
);

const fetchGoodsAcceptanceFilters = createAsyncThunk(
    "get:api/v1/goods-acceptance-filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchGoodsAcceptanceFilters(payload);
        return response.data;
    }
);


const createGoodsAcceptance = createAsyncThunk(
    "post:api/v1/goods-acceptance",
    async (payload, thunkAPI) => {
        const response = await api.createGoodsAcceptance(payload);
        return response;
    }
);

const createGoodsAcceptanceFromProduct = createAsyncThunk(
    "post:api/v1/goods-acceptance-from-product",
    async (payload, thunkAPI) => {
        const response = await api.createGoodsAcceptanceFromProduct(payload);
        return response;
    }
);

const updateGoodsAcceptanceFromProduct = createAsyncThunk(
    "put:api/v1/goods-acceptance-from-product",
    async (payload, thunkAPI) => {
        const response = await api.updateGoodsAcceptanceFromProduct(payload);
        return response;
    }
);

const updateGoodsAcceptance = createAsyncThunk(
    "put:api/v1/goods-acceptance",
    async (payload, thunkAPI) => {
        const response = await api.updateGoodsAcceptance(payload);
        return response;
    }
);

const deleteGoodsAcceptance = createAsyncThunk(
    "delete:api/v1/goods-acceptance",
    async (payload, thunkAPI) => {
        const response = await api.deleteGoodsAcceptance(payload);
        return response;
    }
);

const restoreGoodsAcceptance = createAsyncThunk(
    "post:api/v1/restore-goods-acceptance",
    async (payload, thunkAPI) => {
        const response = await api.restoreGoodsAcceptance(payload);
        return response;
    }
);


export {
    fetchGoodsAcceptance,
    createGoodsAcceptance,
    createGoodsAcceptanceFromProduct,
    updateGoodsAcceptanceFromProduct,
    updateGoodsAcceptance,
    deleteGoodsAcceptance,
    restoreGoodsAcceptance,
    fetchGoodsAcceptanceFilters
}