import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";
import constants from '../../constant';
import { fetchLoading } from ".";

const fetchNotifications = createAsyncThunk(
    "get:" + constants.APIURL.GET_NOTIFICATIONS,
    async (payload, { dispatch, getState }) => {
        if (!payload?.append) {
          dispatch(fetchLoading());
        }
        const data = await api.fetchNotifications(
          typeof payload === "string" ? payload : payload.query
        );
        return { ...data?.data, append: payload?.append };
      }
);

const fetchNotificationsFilters = createAsyncThunk(
    "get:" + constants.APIURL.GET_NOTIFICATIONS_FILTER,
    async (thunkAPI) => {
        const response = await api.fetchNotificationsFilters();
        return response;
    }
);

export {fetchNotifications, fetchNotificationsFilters};