/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Tab - a tab of arbit product page layout below the toolbar with tabs
 */
import PropType from "prop-types"

const units = ["px", "em", "%"]

const Tab = ({children, marginX, height}) => {
    return (
        <div
            className={
                `
        flex justify-between align-bottom w-full
        mx-${units.some(unit => marginX.endsWith(unit))
                    ? '[' + marginX + ']'
                    : marginX}
        
          my-[7.52px]
        `
            }
        >
            {children}
        </div>
    )
}

Tab.propTypes = {
    marginX: PropType.string,
    height: PropType.string
}

Tab.defaultProps = {
    marginX: "0",
    height: "48px"
}

export default Tab;
