import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api";

const fetchCarrierServices = createAsyncThunk(
    "get:api/v1/carrier_service",
    async (payload, thunkAPI) => {
        const response = await api.fetchCarrierService(payload);
        return response;
    }
);

const createCarrierService = createAsyncThunk(
    "post:api/v1/carrier_service",
    async (payload, thunkAPI) => {
        const response = await api.createCarrierService(payload);
        return response;
    }
);

const updateCarrierService = createAsyncThunk(
    "put:api/v1/carrier_service",
    async ({id, payload}) => {
        const response = await api.updateCarrierService(id, payload);
        return response;
    }
);

const deleteCarrierService = createAsyncThunk(
    "delete:api/v1/carrier_service",
    async (payload, thunkAPI) => {
        const response = await api.deleteCarrierService(payload);
        return response;
    }
);

const searchCarrierService = createAsyncThunk(
    "get:api/v1/carrier_service/search",
    async (payload, thunkAPI) => {
        const response = await api.searchCarrierService(payload);
        return response.data;
    }
);

const fetchCarrierServicesFilters = createAsyncThunk(
    "get:api/v1/carrier_service/filters",
    async (payload, thunkAPI) => {
        const response = await api.fetchCarrierServicesFilters(payload);
        return response;
    }
);

const bulkdeleteCarrierServices = createAsyncThunk(
    "delete:api/v1/carrier_service/delete/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkdeleteCarrierServices(payload);
        return response;
    }
);

const bulkStatusupdateCarrierServices = createAsyncThunk(
    "put:api/v1/carrier_service/status/bulk-action",
    async (payload, thunkAPI) => {
        const response = await api.bulkStatusupdateCarrierServices(payload);
        return response;
    }
);

const restoreCarrierServices = createAsyncThunk(
    "post:api/v1/carrier_service/restore",
    async (payload, thunkAPI) => {
        const response = await api.restoreCarrierServices(payload);
        return response;
    }
);


export {
    fetchCarrierServices,
    createCarrierService,
    updateCarrierService,
    deleteCarrierService,
    searchCarrierService,
    fetchCarrierServicesFilters,
    bulkdeleteCarrierServices,
    bulkStatusupdateCarrierServices,
    restoreCarrierServices
};
