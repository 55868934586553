import { createSlice } from "@reduxjs/toolkit";
import {
  createBankAccount,
  deleteBankAccount,
  fetchBankAccounts,
  fetchBankAccountsFilters,
  searchBankAccounts,
  updateBankAccount,
} from "./thunk";

const initialState = {
  bankAccounts: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
  },
  filtersMeta: {},
  boundFilters: {},
  meta: {},
};

export const bankAccountsSlice = createSlice({
  name: "bankAccounts",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    fetchBankAccounts: (state, action) => {
      state.bankAccounts = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateBankAccountStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.bankAccounts = state.bankAccounts.map((bankAccount) => {
        if (ids.includes(bankAccount.id)) {
          return { ...bankAccount, status };
        }
        return bankAccount;
      });
    },
    removeBankAccount: (state, action) => {
      const ids = action.payload;
      state.bankAccounts = state.bankAccounts.filter(
        (bankAccount) => !ids.includes(bankAccount.id)
      );
    },
    // bulkDeleteBankAccounts: (state, action) => {
    //     const ids = action.payload;
    //     state.bankAccounts = state.bankAccounts.filter((bankAccount) => !ids.includes(bankAccount.id));
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccounts.pending, (state, action) => {
        state.loading = "pending";
      })

      .addCase(fetchBankAccounts.fulfilled, (state, action) => {
        //console.log(action.payload);
        state.loading = "idle";
        state.bankAccounts = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      .addCase(fetchBankAccounts.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      // filters
      .addCase(fetchBankAccountsFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
      });

    // .addCase(updateBankAccountStatus.fulfilled, (state, action) => {
    //     const { status, id } = action.payload;
    //     state.bankAccounts = state.bankAccounts.map((bankAccount) => {
    //         if (bankAccount.id === id) {
    //             return { ...bankAccount, status };
    //         }
    //         return bankAccount;
    //     });
    // })
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeBankAccount,
  updateBankAccountStatuses,
} = bankAccountsSlice.actions;

export {
  fetchBankAccounts,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  searchBankAccounts,
};

export default bankAccountsSlice.reducer;
