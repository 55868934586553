import {HiChevronLeft, HiChevronRight} from "react-icons/hi";
import React from "react";
import UI from "../../dynamic-form/elements";

const FrontendFooterPagination = ({postsPerPage, totalPosts, paginate, currentPage}) => {

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div
            className={"flex items-center justify-start   w-full  bg-white"}>
            <div className="flex ">
                <div className={`!gap-[-1px] flex-row flex `}>
                    <UI.Button
                        item={{
                            label: <HiChevronLeft className="h-6 w-6"/>,
                            buttonType: "icon",
                            className: `!border-[#D1D5DB] ${currentPage > 1 ? " hover:!border-gray-400 hover:!text-gray-500 hover:z-20" : "opacity-50"} !text-gray-400  w-[44px] !h-[33px] !rounded-l-[4px] !rounded-r-[0px] `,
                            disabled: currentPage <= 1
                        }}
                        onClick={() => {
                            paginate(currentPage - 1)
                        }}
                    />
                    {
                        pageNumbers.map((val, i) => (
                            <UI.Button
                                key={i}
                                item={{
                                    label: val,
                                    buttonType: "icon",
                                    className: ` !border-[#D1D5DB] ${currentPage !== val && "hover:!border-gray-400 hover:!text-gray-500 hover:z-20"} ${currentPage === val ? "bg-blue-100 !text-[#1C64F2]" : ""}  !text-gray-400  min-w-[33px] !h-[33px] !rounded-[0px] ml-[-1px] `,
                                }}
                                onClick={() => paginate(val)}
                            />
                        ))
                    }

                    <UI.Button
                        item={{
                            label: <HiChevronRight className="h-6 w-6"/>,
                            buttonType: "icon",
                            className: `!border-[#D1D5DB] ${pageNumbers[pageNumbers?.length - 1] > currentPage ? " hover:!border-gray-400 hover:!text-gray-500 hover:z-20" : "opacity-50"} !text-gray-400   w-[44px] !h-[33px] !rounded-r-[4px] !rounded-l-[0px] ml-[-1px] `,
                            disabled: currentPage >= pageNumbers[pageNumbers?.length - 1]

                        }}
                        onClick={() => {
                            paginate(currentPage + 1)
                        }}

                    />

                </div>
            </div>
        </div>
    )
}
export default FrontendFooterPagination