import React from "react";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import { HiExternalLink } from "react-icons/hi";
import util from "../../dynamic-page/util";
import Util from "../../dynamic-page/util";
import quantityHandler from "../../../utils/quantityHandler";
import moment from "moment/moment";
import NoteIcon from "../components/Note";
import StatusSteps from "../../dynamic-page/elements/StatusSteps";
import StatusHistory from "../components/SupplyStatusHistory";
import priceHandler from "../../../utils/priceHandler";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import ItemID from "../../dynamic-page/components/ItemID";
import { IoArrowForward } from "react-icons/io5";
import ProductName from "../../dynamic-page/components/productName";
import descriptiveContent from "../../../utils/descriptiveContent";

const tableView = (list, handleTableFilters) => {
  return {
    list: list,
    theme: {
      note: (item) => (
        <NoteIcon
          notes={{
            buyer: item?.purchase?.supply_request?.comment,
            purchaser: item?.purchase?.note,
            accepter: item?.note,
          }}
        />
      ),
      status: (item) => (
        <StatusSteps
          list={[
            {
              label: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  fill="none"
                >
                  <path
                    fill="currentColor"
                    d="M3 1.039a1 1 0 0 0 0 2h1.22l.305 1.222a.99.99 0 0 0 .01.042l1.358 5.43-.893.892c-1.26 1.26-.368 3.414 1.414 3.414H15a1 1 0 0 0 0-2H6.414l1-1H14a1 1 0 0 0 .894-.553l3-6A1 1 0 0 0 17 3.039H6.28l-.31-1.243A1 1 0 0 0 5 1.039H3Zm13 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-9.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                  />
                </svg>
              ),
              status: item?.purchase?.supply_request?.supply_status
                ?.split(" ")[0]
                ?.toLowerCase(),
            },
            {
              label: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  fill="none"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M4 4.039a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1v-2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2v-12Zm3 1h2v2H7v-2Zm2 4H7v2h2v-2Zm2-4h2v2h-2v-2Zm2 4h-2v2h2v-2Z"
                    clipRule="evenodd"
                  />
                </svg>
              ),
              status: item?.status
                ? item?.status?.split(" ")[0] === "Delivered"
                  ? "completed"
                  : item?.status?.split(" ")[0]?.toLowerCase()
                : "awaiting",
            },
          ]}
          tooltip={
            <StatusHistory
              id={item.id}
              url={"good-acceptance"}
              includes={
                "purchase.supplyRequest,acceptedBy,purchase.employee,purchase.supplyRequest.employee,purchase"
              }
            />
          }
        />
      ),
      id: (item) => (
        <div className="flex flex-col justify-center items-center gap-1 inline-flex">
          <ItemID id={item.id} />
          {item?.purchase?.supply_request?.reason === "order" &&
          item?.purchase?.supply_request?.related_reason?.length > 0 ? (
            <Util.popUp
              toggle={() => (
                <ArbitNormalBadge
                  className={` ${
                    item?.purchase?.supply_request?.reason === "order"
                      ? "underline"
                      : ""
                  } !font-medium
                                    `}
                  title={
                    item?.purchase?.supply_request?.reason ===
                    "warehouse_restocking"
                      ? "WHS"
                      : item?.purchase?.supply_request?.reason ===
                        "fulfilment_restocking"
                      ? "FLS"
                      : "ORD"
                  }
                  bgColor={
                    item?.purchase?.supply_request?.reason ===
                    "warehouse_restocking"
                      ? "purple-100"
                      : item?.purchase?.supply_request?.reason ===
                        "fulfilment_restocking"
                      ? "blue-100"
                      : "red-100"
                  }
                  textColor={
                    item?.purchase?.supply_request?.reason ===
                    "warehouse_restocking"
                      ? "purple-800"
                      : item?.purchase?.supply_request?.reason ===
                        "fulfilment_restocking"
                      ? "blue-800"
                      : "red-800"
                  }
                />
              )}
              action="click"
            >
              <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[300px]">
                {item?.purchase?.supply_request?.related_reason?.map(
                  (order, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center gap-2"
                    >
                      <span className="text-gray-700 font-medium">{order}</span>
                      <div>
                        <Util.copyText
                          className={"text-[12px]"}
                          text={order}
                          hint={false}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </Util.popUp>
          ) : (
            <ArbitNormalBadge
              className={` !font-medium 
                        `}
              title={
                item?.purchase?.supply_request?.reason ===
                "warehouse_restocking"
                  ? "WHS"
                  : item?.purchase?.supply_request?.reason ===
                    "fulfilment_restocking"
                  ? "FLS"
                  : "ORD"
              }
              bgColor={
                item?.purchase?.supply_request?.reason ===
                "warehouse_restocking"
                  ? "purple-100"
                  : item?.purchase?.supply_request?.reason ===
                    "fulfilment_restocking"
                  ? "blue-100"
                  : "red-100"
              }
              textColor={
                item?.purchase?.supply_request?.reason ===
                "warehouse_restocking"
                  ? "purple-800"
                  : item?.purchase?.supply_request?.reason ===
                    "fulfilment_restocking"
                  ? "blue-800"
                  : "red-800"
              }
            />
          )}
        </div>
      ),
      "accepted at": (item) => (
        <util.dateDisplay item={item} date={item?.accepted_at} />
      ),

      image: (item) => (
        <div
          className={
            "w-[56px] h-[64px] max-h-[64px] justify-center items-center flex"
          }
        >
          <ImageMagnifier
            src={item?.product?.image}
            className={"!max-h-[64px]"}
          />
        </div>
      ),
      product: (item) => (
        <ProductName
          item={item?.product}
          user={item?.product?.brand?.assigned}
          handleTableFilters={handleTableFilters}
        />
      ),
      "supply channel": (item) => (
        <div className="flex flex-col px-2 justify-start w-full items-start gap-1">
          {item?.supplier
            ? descriptiveContent(
                <div
                  className={`flex justify-start text-base font-medium items-center gap-1 w-[100%] ${
                    item?.purchase?.supply_request?.url && "cursor-pointer"
                  }`}
                  onClick={() => {
                    item?.purchase?.supply_request?.url &&
                      window.open(
                        item?.purchase?.supply_request?.url,
                        "_blank"
                      );
                  }}
                >
                  {
                    <span className=" truncate max-w-[135px] text-base font-medium">
                      {item?.supplier?.name}
                    </span>
                  }
                  {item?.purchase?.supply_request?.url && (
                    <HiExternalLink className="w-4 h-4 text-gray-300 cursor-pointer hover:text-gray-500" />
                  )}
                </div>,
                item?.supplier?.name
              )
            : "-"}
          <p className="text-green-700 font-medium text-[14px] flex gap-1 items-center">
            <IoArrowForward className="w-4 h-4 text-green-700" />
            {item?.warehouse?.name}
          </p>
        </div>
      ),
      accepted: (item) => (
        <div className=" w-[100%]  px-2 flex-col justify-center items-end gap-0.5 inline-flex">
          <div className="justify-end items-center gap-1 inline-flex">
            <div className="text-indigo-700 text-lg  font-bold leading-[18px]]">
              {quantityHandler(item?.quantity)}
            </div>
          </div>
          <div className="justify-end items-center gap-1 inline-flex">
            <div className="text-indigo-700 text-[14px] leading-none">
              {priceHandler(
                item?.quantity * item?.purchase?.purchase_price?.[item?.purchase?.currency?.code],
                item?.purchase?.currency
              )}
            </div>
          </div>
        </div>
      ),

      "exp.date": (item) => (
        <div className="flex-row w-[100%] justify-center items-start gap-0.5 inline-flex">
          {item?.expiry_date ? (
            <div className="flex-row text-gray-700 justify-center items-start gap-1 inline-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                fill="none"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M2.166 5.038A11.954 11.954 0 0 0 10 1.983a11.954 11.954 0 0 0 7.834 3.056c.11.65.166 1.32.166 2 0 5.226-3.34 9.67-8 11.318-4.66-1.648-8-6.093-8-11.318 0-.682.057-1.35.166-2.001Zm11.541 3.708a1 1 0 0 0-1.414-1.414L9 10.625 7.707 9.332a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"
                  clipRule="evenodd"
                />
              </svg>

              <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                <div className="text-gray-700 text-base font-medium leading-[14px]">
                  {moment(item?.expiry_date).format("DD.MM.YYYY")}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  };
};
export default tableView;
