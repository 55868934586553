import React from "react";

const TableComponent = ({headers, data, hidden}) => {
    return (
        <table className="table w-full">
            {/* <thead className='bg-[#F9FAFB] border-b-[1px] border-solid border-[#E5E7EB]'>
        <tr>
          <th className='text-xs text-[#6B7280] uppercase py-4 w-16'></th>
          <th className='text-xs text-[#6B7280] uppercase py-4 w-16'></th>
          {headers.map((header, index) => (
            <th key={index} className='text-xs text-[#6B7280] uppercase py-4 text-left'>{header}</th>
          ))}
          <th className={`text-xs text-[#6B7280] uppercase py-4 w-40 ${hidden}`}></th>
          <th className={`text-xs text-[#6B7280] uppercase py-4 w-40 ${hidden}`}></th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <>            
            <tr className='text-sm text-[#111928]' key={rowIndex}>
              <td className='py-4 border-b-[1px] border-b-gray-200 border-solid'><div className='flex flex-row justify-start items-center'>{row.star}{row.fire}{row.switch}</div></td>
              <td className='py-4 border-b-[1px] border-b-gray-200 border-solid'>{row.status}</td>
              {headers.map((header, columnIndex) => (
                <td className='py-4 border-b-[1px] border-b-gray-200 border-solid' key={columnIndex}>{row[header]}</td>
              ))}
              <td className='py-4 border-b-[1px] border-b-gray-200 border-solid'>{row.national_prime}</td>
              <td className='py-4 border-b-[1px] border-b-gray-200 border-solid'>{row.featured_merchant}</td>
            </tr>
          </>
        ))}
      </tbody> */}
            <div className={" p-[16px] "}>
                <div className={""}>
                    <div
                        className={`flex flex-col    text-center py-[50px] rounded-2xl items-center`}
                    >
                        <h1 className={"text-3xl text-gray-400 mb-6"}>No Data Found!</h1>
                        <p className={"w-[700px] text-gray-400 font-medium"}>
                            Please adjust your search and/or filter settings to find data that
                            matches your criteria.
                            <br/> If you haven't set any filters yet and still don't see any
                            data, it's possible that no records exist in the system.
                        </p>
                    </div>
                </div>
            </div>
        </table>
    );
};
export default TableComponent;
