import { useState } from "react";
import storeQueryBuilder from "../components/dynamic-page/util/storeQueryBuilder";
import urlQueryBuilder from "../components/dynamic-page/util/urlQueryBuilder";

const useLoadMoreData = ({ dispatch, pageData, fetchData }) => {
  const [loading, setLoading] = useState(false);

  const loadMoreData = async () => {
    if (
      !loading &&
      pageData?.loading !== "pending" &&
      pageData?.meta?.to < pageData?.meta?.total
    ) {
      setLoading(true);
      const nextPage = pageData?.meta?.current_page + 1;

      const currentStoreFilters = pageData?.filters;
      const storeQuery = storeQueryBuilder(currentStoreFilters, {
        page: nextPage,
      });
      const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);

      await dispatch(fetchData({ query: urlQuery, append: true }));
      setLoading(false);
    }
  };

  return { loadMoreData, loading };
};

export default useLoadMoreData;
