import { useState } from "react";
import Icons from "../../../orders/icons";
import Util from "../../../dynamic-page/util";

import { Tooltip } from "react-tooltip";

const DirtyBox = ({
  children,
  title,
  className,
  childClassName,
  texts,
  texts2,
}) => {
  const [isClicked, setClicked] = useState(true);

  return (
    <div
      className={`border  border-gray-100 shadow-md box-border rounded-[16px] p-4 flex flex-col gap-4 w-full ${
        className && className
      }  `}
    >
      <div className="flex justify-between items-center border-b-[1px] pb-3 border-gray-100  text-gray-100 ">
        <div className="flex items-start gap-2.5">
          {isClicked ? (
            <Icons.chevronUp
              onClick={() => setClicked(!isClicked)}
              className="w-5 h-5 cursor-pointer text-gray-800"
            />
          ) : (
            <Icons.chevronDown
              onClick={() => setClicked(!isClicked)}
              className="w-5 h-5 cursor-pointer text-gray-800"
            />
          )}
          <span className="text-[18px] font-bold leading-[23px] text-gray-900">
            {title}
          </span>
        </div>
        {texts && (
          <Util.popUp
            toggle={() => (
              <Icons.info className="w-5 h-5 text-gray-300 hover:text-gray-500" />
            )}
            action="hover"
            // forcePosition={'top'}
          >
            <div className="flex flex-col gap-1.5 py-1.5  w-[297px]">
              <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                {texts?.tooltipTitle}
              </span>
              <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                {texts?.tooltipDesc}
              </span>
            </div>
          </Util.popUp>
        )}
        {texts2 && (
          <span className="">
            <Icons.info
              className="w-4 h-4  text-gray-300 hover:text-gray-500"
              data-tooltip-id={`${texts2?.tooltipTitle}`}
            />
            <Tooltip
              id={`${texts2?.tooltipTitle}`}
              place="top"
              positionStrategy="fixed"
              className="z-[99999] bg-white !opacity-100"
              style={{
                backgroundColor: "white",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                borderRadius: "4px",
                padding: "0px 0px 0px 0px",
              }}
            >
              <div className="flex flex-col gap-2 py-2.5 px-3 w-[320px]">
                <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                  {texts2?.tooltipTitle}
                </span>
                <span className="text-gray-500 text-[12px] leading-[15px] font-normal whitespace-pre-wrap">
                  {texts2?.tooltipDesc}
                </span>
              </div>
            </Tooltip>
          </span>
        )}
      </div>
      {isClicked && (
        <div className={`${childClassName && childClassName}`}>{children}</div>
      )}
    </div>
  );
};

export default DirtyBox;
