import { RxCaretSort } from "react-icons/rx";
import Util from "../../dynamic-page/util";
import { HiExternalLink } from "react-icons/hi";
import Icons from "../../orders/icons";
import { FaRegTrashAlt } from "react-icons/fa";
import priceHandler from "../../../utils/priceHandler";
import descriptiveContent from "../../../utils/descriptiveContent";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import PurchaseSelectItems from "./purhcaseSelectItems";
import React from "react";
import moment from "moment";
import CopyText from "../../dynamic-page/util/copyText";
import PropTypes from 'prop-types';
import PopupUp from "../../dynamic-page/util/popUp";

const SupplyTable = ({
  actions,
  formik,
  updateItem,
  setSelectedProduct,
  changeQuantity,
  sourcesOptions,
  originalItems,
  submit,
  setUpdateItem,
  currenciesOptions,
  setAddClicked,
  clickedId,
  setClickedId,
  filterByQuantity,
  filterByPrice,
  setPriceFilter,
  priceFilter,
  quantityFilter,
  filterByProduct,
  setQuantityFilter,
  productFilter,
  setProductFilter,
  channelFilter,
  filterByChannel,
  setChannelFilter,
  filterByDate,
  dateFilter,
  setDateFilter,
}) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "!text-gray-500";
    }
  };

  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const Edit=Icons.edit

  return (
    <table>
      <thead className="bg-gray-100">
        <tr className="text-[12px] h-[50px] leading-[18px] font-semibold text-gray-500 border-t border-b border-gray-200">
          <th className="w-[220px] pl-9">
            <div className="flex gap-1 items-center">
              <span>REQUESTED AT</span>
              <RxCaretSort
                onClick={() => {
                  filterByDate();
                  setDateFilter(dateFilter === "low" ? "high" : "low");
                }}
                className="w-4 h-4 cursor-pointer text-gray-400"
              />
            </div>
          </th>
          <th className="w-[576px]">
            <div className="flex gap-1 items-center">
              <span>PRODUCT</span>
              <RxCaretSort
                onClick={() => {
                  filterByProduct();
                  setProductFilter(productFilter === "low" ? "high" : "low");
                }}
                className="w-4 h-4 cursor-pointer text-gray-400"
              />
            </div>
          </th>
          <th className="w-[72px]">
            <div className="flex gap-1 items-center">
              <span>QTY</span>
              <RxCaretSort
                onClick={() => {
                  filterByQuantity();
                  setQuantityFilter(quantityFilter === "low" ? "high" : "low");
                }}
                className="w-4 h-4 cursor-pointer text-gray-400"
              />
            </div>
          </th>
          <th className="w-[90px]">
            <div className="flex gap-1 items-center">
              <span>PRICE</span>
              <RxCaretSort
                onClick={() => {
                  filterByPrice();
                  setPriceFilter(priceFilter === "low" ? "high" : "low");
                }}
                className="w-4 h-4 cursor-pointer text-gray-400"
              />
            </div>
          </th>
          <th className="w-[145px]">
            <div className="flex gap-1 items-center">
              <span>CHANNEL</span>
              <RxCaretSort
                onClick={() => {
                  filterByChannel();
                  setChannelFilter(channelFilter === "low" ? "high" : "low");
                }}
                className="w-4 h-4 cursor-pointer text-gray-400"
              />
            </div>
          </th>
          <th className="w-[152px] pr-7"></th>
        </tr>
      </thead>
      <tbody>
        {updateItem?.map((item, index) => (
          <React.Fragment key={item?.id}>
            <tr
              className={` border-t border-b  ${
                clickedId === index ? "bg-[#EBF5FF]" : "hover:bg-gray-50"
              } border-gray-200 h-[54px]`}
            >
              <td className="w-[220px] pl-9 ">
                <div>
                  <div className="flex gap-1 items-center whitespace-nowrap">
                    <span className="text-[12px] leading-[18px] text-gray-500 font-medium whitespace-nowrap">
                      #{item?.id}
                    </span>
                    <CopyText
                      className="text-[12px]"
                      text={item?.id}
                      hint={false}
                    />
                  </div>
                  <span className="text-[14px] leading-[21px] font-medium text-gray-900 whitespace-nowrap">
                    {moment(nullHandle(item, item?.created_at)).format(
                      "MMM D, YYYY"
                    )}{" "}
                    {moment(nullHandle(item, item?.created_at)).format(
                      "hh:mm A"
                    )}
                  </span>
                </div>
              </td>
              <td className="w-[576px]">
                <div className="flex gap-1.5 items-center whitespace-nowrap">
                  <Zoom>
                    <img
                      alt="product"
                      className="w-[40px] h-[40px] object-cover rounded-[4px]"
                      src={item?.product?.image}
                    />
                  </Zoom>
                  <div className="flex flex-col">
                    <div className="flex gap-1 items-center w-[160px] justify-between">
                      <span className="text-[12px] whitespace-nowrap leading-[18px] text-gray-500 font-medium">
                        {item?.product?.apid}
                      </span>
                      <div className="flex gap-1 items-center whitespace-nowrap">
                        <CopyText
                          className="text-[12px]"
                          text={item?.product?.apid}
                          hint={false}
                        />
                        <HiExternalLink
                          onClick={() =>
                            window.open(
                              `/product/${item?.product?.id}`,
                              "_blank"
                            )
                          }
                          className="w-4 h-4 text-blue-600 cursor-pointer"
                        />
                      </div>
                    </div>
                    <div className="flex gap-1.5 items-center  whitespace-nowrap">
                      {item?.product?.title?.length > 67 ? (
                        <PopupUp
                          toggle={(selected) => (
                            <span className="text-[14px] whitespace-nowrap text-justify leading-[21px] font-medium text-gray-900">
                              {item?.product?.title.slice(0, 68)}...
                            </span>
                          )}
                          action="hover"
                        >
                          <span className="text-[14px] whitespace-nowrap text-justify leading-[21px] font-medium text-gray-900">
                            {item?.product?.title}
                          </span>
                        </PopupUp>
                      ) : (
                        <span className="text-[14px] whitespace-nowrap leading-[21px] font-medium text-gray-900">
                          {item?.product?.title}
                        </span>
                      )}
                      <CopyText
                        className="text-[12px]"
                        text={item?.product?.title}
                        hint={false}
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td className="w-[72px]">
                <div>
                  <span className="text-[14px] leading-[21px] font-medium text-gray-900">
                    {Number(item?.qty) === 0 ? 1 : Number(item?.qty)}
                  </span>
                </div>
              </td>
              <td className="w-[90px]">
                <div>
                  <span className="text-[14px] leading-[21px] font-medium text-gray-900">
                    {item?.price
                      ? priceHandler(
                          item?.price,
                          item?.max_acceptable_price_currency
                        )
                      : "-"}
                  </span>
                </div>
              </td>
              <td className="w-[90px]">
                <div>
                  <span className="text-[14px] leading-[21px] font-medium text-gray-900">
                    {item?.supplier?.name}
                  </span>
                </div>
              </td>
              <td className="w-[152px] pr-7">
                <div className="flex gap-4 items-center justify-end">
                  {/* Make sure you import and define CopyText2, RxCaretSort, HiExternalLink, etc. */}
                  {item?.url && (
                    <HiExternalLink
                      onClick={() => window.open(item?.url, "_blank")}
                      className="w-5 h-5 text-blue-600 cursor-pointer"
                    />
                  )}
                  {item?.comment && (
                    <PopupUp
                      toggle={() =>
                        descriptiveContent(
                          <div
                            className={`flex items-center mt-1 justify-center p-[2px] transition rounded-full ${activeStyle(
                              item?.comment
                            )}`}
                          >
                            <svg
                              width="18"
                              height="17"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.2 9.7002V2.5002C15.2 2.02281 15.0104 1.56497 14.6728 1.2274C14.3352 0.889838 13.8774 0.700195 13.4 0.700195H2.6C2.12261 0.700195 1.66478 0.889838 1.32721 1.2274C0.989645 1.56497 0.800003 2.02281 0.800003 2.5002V9.7002C0.800003 10.1776 0.989645 10.6354 1.32721 10.973C1.66478 11.3106 2.12261 11.5002 2.6 11.5002H5.3L8 14.2002L10.7 11.5002H13.4C13.8774 11.5002 14.3352 11.3106 14.6728 10.973C15.0104 10.6354 15.2 10.1776 15.2 9.7002ZM3.5 4.3002C3.5 4.0615 3.59482 3.83258 3.76361 3.6638C3.93239 3.49502 4.16131 3.4002 4.4 3.4002H11.6C11.8387 3.4002 12.0676 3.49502 12.2364 3.6638C12.4052 3.83258 12.5 4.0615 12.5 4.3002C12.5 4.53889 12.4052 4.76781 12.2364 4.93659C12.0676 5.10537 11.8387 5.2002 11.6 5.2002H4.4C4.16131 5.2002 3.93239 5.10537 3.76361 4.93659C3.59482 4.76781 3.5 4.53889 3.5 4.3002ZM4.4 7.0002C4.16131 7.0002 3.93239 7.09502 3.76361 7.2638C3.59482 7.43258 3.5 7.6615 3.5 7.9002C3.5 8.13889 3.59482 8.36781 3.76361 8.53659C3.93239 8.70537 4.16131 8.8002 4.4 8.8002H7.1C7.3387 8.8002 7.56762 8.70537 7.7364 8.53659C7.90518 8.36781 8 8.13889 8 7.9002C8 7.6615 7.90518 7.43258 7.7364 7.2638C7.56762 7.09502 7.3387 7.0002 7.1 7.0002H4.4Z"
                                fill="#1C64F2"
                              />
                            </svg>
                          </div>,
                          "Internal Note"
                        )
                      }
                      action="click"
                    >
                      <div
                        style={{ hyphens: "auto" }}
                        className="max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
                      >
                        {item.comment ? (
                          Util.upperCaseString(item.comment)
                        ) : (
                          <span className="text-gray-400">note is empty!</span>
                        )}
                      </div>
                    </PopupUp>
                  )}
                  <button onClick={() => {
                      setAddClicked(false);
                      if (clickedId === index) {
                        setClickedId(null);
                      } else {
                        setClickedId(index);
                      }
                      const currency_id = {
                        label: item?.max_acceptable_price_currency?.name,
                        title: item?.max_acceptable_price_currency?.name,
                        value: item?.max_acceptable_price_currency?.id,
                        code: item?.max_acceptable_price_currency?.code,
                      };
                      const purchase_price =
                        item?.max_acceptable_price[currency_id.code];
                      setSelectedProduct({
                        product_id: item?.id,
                        currency_id: currency_id,
                        title: item?.product?.title,
                        purchase_price: purchase_price,
                        image: item?.product?.image,
                        quantity: Number(item?.qty),
                        assign: item?.product?.assign?.name,
                        profile: item?.product?.assign?.profile_image,
                      });
                    }}>
                  <Edit
                    
                    className="text-gray-500 hover:text-blue-600 w-4 h-4 cursor-pointer"
                  />
                  </button>
                  <FaRegTrashAlt
                    onClick={() => actions.delete.onClick(item)}
                    className="w-4 h-4 hover:text-red-600 text-gray-500 cursor-pointer"
                  />
                </div>
              </td>
            </tr>
            {clickedId === index && (
              <tr className="!w-full">
                <td colSpan={6}>
                  <PurchaseSelectItems
                    formik={formik}
                    originalItems={originalItems}
                    updateItem={updateItem}
                    index={index}
                    selectedProduct={item}
                    setSelectedProduct={setSelectedProduct}
                    changeQuantity={changeQuantity}
                    sourcesOptions={sourcesOptions}
                    setUpdateItem={setUpdateItem}
                    submit={submit}
                    currenciesOptions={currenciesOptions}
                  />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

SupplyTable.propTypes = {
  actions: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  updateItem: PropTypes.array,
  setSelectedProduct: PropTypes.func.isRequired, 
  changeQuantity: PropTypes.func,
  sourcesOptions: PropTypes.array,
  originalItems: PropTypes.array,
  submit: PropTypes.func,
  setUpdateItem: PropTypes.func,
  currenciesOptions: PropTypes.array,
  setAddClicked: PropTypes.func,
  clickedId: PropTypes.number,
  setClickedId: PropTypes.func,
  filterByQuantity: PropTypes.func,
  filterByPrice: PropTypes.func,
  setPriceFilter: PropTypes.func,
  priceFilter: PropTypes.string,
  quantityFilter: PropTypes.string,
  filterByProduct: PropTypes.func,
  setQuantityFilter: PropTypes.func,
  productFilter: PropTypes.string,
  setProductFilter: PropTypes.func,
  channelFilter: PropTypes.string,
  filterByChannel: PropTypes.func,
  setChannelFilter: PropTypes.func,
  filterByDate: PropTypes.func,
  dateFilter: PropTypes.string,
  setDateFilter: PropTypes.func,
};


export default SupplyTable;

