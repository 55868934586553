import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchSupplyListings = createAsyncThunk(
    'get:api/v1/supply-listings',
    async (params, thunkAPI) => {
        const response = await api.fetchSupplyListings(params)
        // console.log(response)
        return response.data
    }
)

const updateSupplyListings = createAsyncThunk(
    'put:api/v1/supply-listings/refresh',
    async (payload, thunkAPI) => {
        const response = await api.updateSupplyListings(payload)
        return response.data
    }
)
const updateBulkSupplyListings = createAsyncThunk(
    'post:api/v1/supply-listings/bulk-refresh',
    async (payload, thunkAPI) => {
        const response = await api.updateBulkSupplyListings(payload)

        return response.data
    }
)

const duplicateSupplyListings = createAsyncThunk(
    'post:api/v1/supply-listings/duplicate',
    async (payload, thunkAPI) => {
        const response = await api.duplicateSupplyListings(payload)

        return response.data
    }
)

const activateSupplyListings = createAsyncThunk(
    'put:api/v1/supply-listings/status',
    async (payload, thunkAPI) => {
        const response = await api.activateSupplyListings(payload)

        return response.data
    }
)

const refreshSupplyListig = createAsyncThunk(
    'post:api/v1/supply-listings/refresh',
    async (payload, thunkAPI) => {
        const response = await api.refreshSupplyListig(payload)

        return response.data
    }
)

const deleteSupplyListings = createAsyncThunk(
    'delete:api/v1/supply-listings',
    async (payload, thunkAPI) => {
        const response = await api.deleteSupplyListings(payload)
        // console.log(response);
        return response.data
    }
)

const fetchSupplyListingFilters = createAsyncThunk(
    'get:api/v1/supply-listings/filters',
    async (payload, thunkAPI) => {
        const response = await api.fetchSupplyListingFilters()
        return response.data
    }
)

const attachSupplyListings = createAsyncThunk(
    'post:api/v1/product/:id/listing',
    async ({params, payload}) => {
        const response = await api.attachSupplyListings(params, payload)
        return response.data
    }
)

const detachSupplyListings = createAsyncThunk(
    'delete:api/v1/product/:id/detach-listings',
    async ({params, payload}) => {
        const response = await api.detachSupplyListings(params, payload)
        return response.data
    }
)


export {
    fetchSupplyListings,
    updateSupplyListings,
    updateBulkSupplyListings,
    duplicateSupplyListings,
    activateSupplyListings,
    refreshSupplyListig,
    deleteSupplyListings,
    fetchSupplyListingFilters,
    attachSupplyListings,
    detachSupplyListings
}
