import React from "react";


function FlagByCountryCode({children, className}) {
    if (!children) {
        return '';
    }

    const codePoints = children
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());

    return (
        <span className={className}>{String.fromCodePoint(...codePoints)}</span>
    );
}

export default React.memo(FlagByCountryCode);