import util from "../../dynamic-page/util";
import callEndpoint from "../../dynamic-page/util/callEndpoint";

const actionList = {
  refresh: {
    label: "Refresh",
    condition: false,
    onClick: ({
      refreshing,
      setRefreshing,
      setSelectedRow,
      selectedRow,
      dispatch,
      refreshPage,
    }) => {
      const ids = selectedRow?.map((item) => {
        return item?.id;
      });

      setRefreshing(true);

      setTimeout(() => {
        setRefreshing(false);
      }, 3000);

      if (ids) {
        try {
          setRefreshing(true);

          // let a = startNotification({
          //     title: `Refreshing ${ids?.length} Products Calculations`,
          // });
          // Dispatch the refreshAccountListings thunk with the identifiers array in the payload
          // dispatch(updateRow({path: `orders.[id:${orderId}]`, value: {...rs.payload.data[0], _updatedRow : true}}))
          // update the selected rows in the table to add the _updatedRow property use the ids
          // ids.map((id) => {
          //   dispatch(updateRow({ path: `salesListings.[id:${id}]`, value: { ...pageData.salesListings.find((item) => item.id === id), _updatedRow: true } }))
          // })

          callEndpoint({
            title: "Refresh Calculations",
            url: `product/refresh-inventory`,
            method: "POST",
            data: { ids },
            pureData: true,
          }).then((rs) => {
            // setRefreshing(false);
            // util.notifier({type : rs.type , message: rs.message});
            // closeNotification(a, {
            //     toast: {
            //         type: rs.type ===
            //         "validation_error" ? 'error' : rs.type, message: rs.message, errors: rs.errors
            //     }
            // });
            if (rs.type === "success") {
              setSelectedRow(null);
              refreshPage();
              // ids.map((id) => {
              //     dispatch(updateRow({path: `shipments.[id:${id}]._updatedRow`, value: true}))
              // })

              // loop on ids and remove the _updatedRow property from the rows after 2 seconds
              // ids.map((id) => {
              //     setTimeout(() => {
              //         dispatch(updateRow({path: `shipments.[id:${id}]._updatedRow`, value: false}))
              //     }, 3000)
              // })

              // ids.map((id) => {
              //     const row = rs.data.find((item) => item.id === id)
              //     // const originalRow = pageData.fcRestockings.find((item) => item.sales_channel_listing.identifier === id)
              //     const originalRow = selectedRow.find((item) => item.id === id)
              //     dispatch(updateRow({id:id,  value: {...originalRow,rowSelectedInTable:false, product: row, _updatedRow : true}}))
              //   }
              //   )

              //   ids.map((id) => {
              //     setTimeout(() => {
              //       dispatch(updateRow({id:id, path: `_updatedRow`, value: false }))
              //     }, 3000)
              //   })
            }

            // setRefreshingDate (rs.meta.timestamp)
          });

          // Fetch data again to refresh the table
          // const urlQuery = urlQueryBuilder(pageData?.filters, null, "term");
          // await dispatch(fetchData(urlQuery));
        } catch (error) {
          // Handle errors if the refresh fails
          console.error("Error refreshing account listings:", error);
        }
      } else {
        util.notifier({ message: "Please Select at Least One Row" });
        console.error("No identifier found in the selected row.");
      }
    },
  },
  createSupplyRequest: {
    label: "Create Supply Request",
    onClick: (item) => {
      const data = Array.isArray(item?.data) ? item?.data : [item?.data];
      const ids = data
        ?.map((item) => {
          return item?.product?.id;
        })
        ?.join(",");
      window.open(
        `/inventory/supply_requests#action=create&qty=1&reason=bulk_order&products=${ids}`,
        "_blank"
      );
    },
    condition: (_, pageData) =>
      pageData?.filters?.filters?.summaryType === "resupply",
  },
};

export default actionList;
