import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchInventory = async (params) => {
    let url = constants.APIURL.GET_INVENTORY;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
}

const fetchInventoryFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_INVENTORY_FILTERS, {withCredentials: true});
    return response;
}


const createInventory = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_INVENTORY, payload, {withCredentials: true});
    return response;
}

const updateInventory = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_INVENTORY + id, payload, {withCredentials: true});
    return response;
}

const deleteInventory = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_INVENTORY + payload, {withCredentials: true});
    return response;
}

export default {
    fetchInventory,
    createInventory,
    updateInventory,
    deleteInventory,
    fetchInventoryFilters
}
