import React from "react";
import DynamicForm from "../../../../../../dynamic-form";

function FooterWeight({value, callBack, onlyNumber, text}) {
    const formItems = [
        {
            name: "bb_weight",
            // label: "Tracking URL",
            // placeholder: "Enter Tracking URL Schema",
            type: "OrderText",
            editableText: text,
            reverse: true,
            defaultValue: value || "-",
            clear: false,
            reset: false,
            unit: "kg",
            onlyNumber: true,
            className: " gap-[0px]",
            disabled: false,

            innerClassName: "!text-[16px] !max-w-[50px] !text-gray-900 leading-[14px]",
        },
    ];

    const ref = React.useRef(null);
    return (
        <DynamicForm
            items={formItems}
            ref={ref}
            style={{
                label: {className: "w-full  !text-gray-400 text-14px"},
                container: {className: "gap-[0px]"},
            }}
            onChange={(data) => callBack(data?.bb_weight)}
        />
    );
}

export default FooterWeight;
