// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./models-view/table";
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tabsView from "./models-view/tabs";
import actionsView from "./models-view/actions";
import addForm from "./models-view/addForm";
import optionsView from "./models-view/options";

// Main Component
const Currencies = () => {

    // Initial Page
    const Page = usePage({
        pageTitle: "Currencies",
        api: {
            fetch: "currencies",
            filters: "currencies/filters",
        },
        ModelsView: {
            table: tableView,
            filter: filtersView,
            sort: sortView,
            tabs: tabsView,
            actions: actionsView,
            headerOption: optionsView,
            forms: {
                add: addForm,
            }
        },
        pageSettings: {
            newDesign: true,
            rowStyles: {
                className: '!px-[16px] !py-[8px] text-[14px] !items-center justify-center flex  !w-max',
                note: {
                    td: 'w-[100%]'
                },
                symbol: {
                    className: '!justify-center !w-[100%] !flex',
                }
            },
            headerStyles: {
                note: {
                    className: '!justify-start'
                },
            }
        }
    })

    // return View
    return Page

};

export default Currencies;