import axios from "./../../middleware/axios";
import constants from "../../constant";

const fetchChannels = async (params) => {
    let url = constants.APIURL.GET_CHANNELS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response.data
};

const updateChannels = async ({payload}) => {
    // const response = await axios.post(constants.APIURL.GET_CHANNELS_UPDATE, apiutils.obj2Form(payload), { withCredentials: true })
    // return response
}

const fetchChannelsFilters = async () => {
    const response = await axios.get(constants.APIURL.GET_CHANNELS_FILTERS, {withCredentials: true})
    return response
}

const updateBulkChannelsStatus = async (payload) => {
    const response = await axios.patch(constants.APIURL.PATCH_BULK_CHANNELS_STATUS, payload, {withCredentials: true})
    return response;
}
const deleteChannel = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_CHANNELS + payload, {withCredentials: true})
    return response
}

// single restore
const restoreChannel = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_CHANNELS + payload + '/restore', {withCredentials: true})
    return response
}
const deleteBulkChannels = async (payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_BULK_CHANNELS, {data: payload})
    return response;
}

const createChannel = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_CREATE_CHANNEL, payload)
    return response;
}

const updateChennel = async ({id, payload}) => {
    const response = await axios.post(constants.APIURL.PUT_UPDATE_CHANNEL + id, payload)
    return response;
}


export default {
    fetchChannels,
    updateChannels,
    updateBulkChannelsStatus,
    deleteBulkChannels,
    fetchChannelsFilters,
    deleteChannel,
    restoreChannel,
    createChannel,
    updateChennel
};
