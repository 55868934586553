const validationHelper = async (validationRule, attributeValue, newAttribute) => {
    if (validationRule === undefined) {
        return true
    }
    // const Regex = new RegExp(validationRule.regex, 'g');
    // parse the regex string to a string that can be used to create a regex
    const regexString = validationRule.regEx
    const Regex = eval(regexString)


    if ('maxCount' in validationRule && attributeValue.length > validationRule.maxCount) {
        return `Incorrect value, ${newAttribute} must not be more than ${validationRule.maxCount} characters`
    }
    if ('minCount' in validationRule && attributeValue.length < validationRule.minCount) {
        return `Incorrect value, ${newAttribute} must not be less than  ${validationRule.minCount}  characters`
    }
    if ('maxValue' in validationRule && attributeValue > (validationRule.maxValue * 1)) {
        return `Incorrect value, ${newAttribute} must not be more than ${validationRule.maxValue} `
    }
    if ('minValue' in validationRule && attributeValue < (validationRule.minValue * 1)) {
        return `Incorrect value, ${newAttribute} must not be less than  ${validationRule.minValue}  `
    }
    if (validationRule?.regEx && !Regex?.test(attributeValue)) {
        return `Incorrect value,Please enter a correct ${newAttribute}`
    }
    if ('dimension' in validationRule && !(attributeValue.includes('x'))) {
        return `Invalid ${newAttribute.toLowerCase()}. It must be of the type width x height`
    }
    if ('enum' in validationRule && !validationRule.enum.includes(attributeValue)) {
        return `Incorrect value, Please enter a correct ${newAttribute}`
    }

    return true;
}

export default validationHelper