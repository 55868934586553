import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "./api"

const fetchCalculatorData = createAsyncThunk("get:api/v1/logistics/calculator",
    async (payload, thunkAPI) => {
        const res = await api.fetchProducts(payload)
        return res;
    }
)

export {fetchCalculatorData}