import React, {useEffect, useState} from "react";
import {FaCheckCircle, FaSpinner, FaTimesCircle} from "react-icons/fa";
import {useDispatch} from "react-redux";
import {authorizeStoreCallBack} from "../../store/accounts";
import localforage from "localforage";

const AmazonCallback = () => {
    const dispatch = useDispatch();
    const [authorizing, setAuthorizing] = useState(true);
    const [authSuccess, setAuthSuccess] = useState(false);

    const redirectToAccount = () => {
        window.location.href = "/settings/sales_channel_accounts";
    };

    useEffect(() => {
        const url = window.location.href;
        const urlObject = new URL(url);
        const query = urlObject.search.substring(1);
        const data = {};

        query.split("&").forEach((param) => {
            const [key, value] = param.split("=");
            data[key] = decodeURIComponent(value);
        });

        const authorizeStore = async () => {
            try {
                const oauthDetails = await localforage.getItem("oauthDetails");
                // Extract encrypted_state and marketplace_code from state
                const decodedState = decodeURIComponent(oauthDetails["state"]);
                const [, marketplace_code] = decodedState.trim().split('-');

                if (decodedState.trim() !== oauthDetails.state.trim()) {
                    setAuthorizing(false);
                    return;
                }

                //await localforage.removeItem("oauthDetails");
                data.state = oauthDetails.state;
                const res = await dispatch(
                    authorizeStoreCallBack({payload: data, marketplace: marketplace_code})
                ).unwrap();

                setAuthorizing(false);

                if (res.status === 200) {
                    setAuthSuccess(true);
                    setTimeout(() => {
                        redirectToAccount();
                    }, 3000);
                } else {
                    setAuthSuccess(false);
                }
            } catch (error) {
                setAuthorizing(false);
            }
        };

        authorizeStore();
    }, []);

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            {authorizing && (
                <div className="flex flex-col items-center">
                    <FaSpinner className="animate-spin text-6xl text-green-500 mb-4"/>
                    <h1 className="text-4xl font-bold mb-4">Authorization in progress</h1>
                </div>
            )}
            {authSuccess && (
                <div className="flex flex-col items-center">
                    <FaCheckCircle className="text-6xl text-green-500 mb-4"/>
                    <h1 className="text-4xl font-bold mb-4">Successfully Authorized</h1>
                </div>
            )}
            {!authSuccess && !authorizing && (
                <div className="flex flex-col items-center">
                    <FaTimesCircle className="text-6xl text-red-500 mb-4"/>
                    <h1 className="text-4xl font-bold mb-4">Authorization Failed</h1>
                    <button
                        className="mt-4 bg-blue-700 text-white px-4 py-2 mt-4 rounded"
                        onClick={redirectToAccount}
                    >
                        Try Again
                    </button>
                </div>
            )}
        </div>
    );
};

export default AmazonCallback;
