import ArbitToggle from "../../sub-elements/toggle";

const Toggle = ({item, binded, validation, change, data}) => {
    return (
        <>
            <div>
                <div className="w-full  py-px justify-start items-start gap-2 inline-flex">
                    {item?.checkbox ? (
                        <input
                            type="checkbox"
                            className={`transition !w-[16px] !h-[16px] ${item.disabled && "disabled"}  disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1  ${item.innerClassName || ""} `}

                            checked={data[item?.name] ?? false}
                            onChange={(e) => change(e.target.checked, item?.name)}
                        />
                    ) : <ArbitToggle
                        status={data[item?.name] ?? false}
                        bgColor={data[item?.name] ? "bg-blue-600" : "bg-gray-200"}
                        handleFulfilment={() => change(!data[item?.name], item?.name)}
                    />}
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                        <div className="text-gray-900 text-sm font-medium font-['Inter'] !leading-[14px]">
                            {item.label || "Trackable Quantity"}
                        </div>
                        <div className="self-stretch text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]">
                            {item.description ||
                                "Turn this on if you can track the inventory quantity and want it to be decreased from the supplier in each supply. Otherwise, the inventory will be operated as unlimited."}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Toggle;
