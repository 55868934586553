/**
 * @author Austin Ames
 * @description ArbitAttributeItem - an attribute item with key and value
 */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { HiInformationCircle, HiTrash } from "react-icons/hi";
import ArbitEditableText from "../sub-elements/editable-text";
import ErrorComponent from "../sub-elements/ErrorComponent";
import { toast } from "react-toastify";
import { HiCheck, HiX, HiPencil } from "react-icons/hi";
import { fetchProduct } from "../../store/products";
import validationHelper from "./validation-helper";
import { deleteAttribute, editAttribute } from "../../store/products/thunk";
import DeleteModal from "./Product-delete-modal";
import Icons from "../orders/icons";
import DirtySelect from "../dynamic-form/elements/DirtySelect";
import Dropdown from "../sub-elements/dropdown";
import ArbitNormalInput from "../sub-elements/normal-input";

const ArbitAttributeItem = ({
  name,
  value,
  attributeId,
  validationRule,
  description,
  id,
  productLanguage,
  attribute,
}) => {
  const dispatch = useDispatch();
  const [errMessage, setErrorMessage] = useState("");
  const [valid, setValid] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const [temp, setTemp] = useState(value || "");

  const [localValue, setLocalValue] = useState("");
  const onChange = async (lastvalue) => {
    const response = await validationHelper(validationRule, lastvalue, name);
    const payload = {
      value: lastvalue,
      locale: productLanguage,
    };
    if (response === true) {
      setValid(true);
      setErrorMessage("");
      await dispatch(editAttribute({ id, attributeId, payload }))
        .unwrap()
        .then((response) => {
          if (response?.status === 200) {
            dispatch(fetchProduct({ id, productLanguage: "40" }));
            toast.success("Successfuly Update the Attribute", {
              toastId: "success1",
            });
          }
        })
        .catch((err) => {
          toast.error("Error ", {
            toastId: "success1",
          });
        });
    } else {
      setValid(false);
      setErrorMessage(response);
    }
  };

  const onChange2 = async (props) => {
    const response = await validationHelper(validationRule, props.value, name);
    const payload = {
      value: props.value,
      locale: productLanguage,
    };
    if (props?.value) {
      if (response === true) {
        setValid(true);
        setErrorMessage("");
        await dispatch(editAttribute({ id, attributeId, payload }))
          .unwrap()
          .then((response) => {
            if (response?.status === 200) {
              toast.success("Successfuly Update the Attribute", {
                toastId: "success1",
              });
              props.reset({ value: props?.value });
            }
          })
          .catch((err) => {
            toast.error("Error ", {
              toastId: "success1",
            });
          });
      } else {
        setValid(false);
        setErrorMessage(response);
      }
    } else {
      await dispatch(
        deleteAttribute({
          id: attributeId,
          payload: {
            locale: productLanguage,
            value: value,
          },
        })
      )
        .unwrap()
        .then((response) => {
          if (response?.status === 200 || response?.status === 204) {
            setDeleteModal(false);
            dispatch(fetchProduct({ id, productLanguage }));
            toast.success("Successfuly Deleted the Attribute", {
              toastId: "success1",
            });
          } else {
            toast.error(response?.data?.message, {
              toastId: "success1",
            });
          }
        })
        .catch((err) => {
          toast.error("Error ", {
            toastId: "success1",
          });
        });
    }
  };

  const handleAttributeDeletion = async () => {
    await dispatch(
      deleteAttribute({
        id: attributeId,
        payload: {
          locale: productLanguage,
          value: value,
        },
      })
    )
      .unwrap()
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          setDeleteModal(false);
          dispatch(fetchProduct({ id, productLanguage }));
          toast.success("Successfuly Deleted the Attribute", {
            toastId: "success1",
          });
        } else {
          toast.error(response?.data?.message, {
            toastId: "success1",
          });
        }
      })
      .catch((err) => {
        toast.error("Error ", {
          toastId: "success1",
        });
      });
  };

  const type = attribute?.attribute?.type;

  const [isEditable, setEditable] = useState(false);
  const [lastValue, setLastValue] = useState("");

  const onHandleEdit = () => {
    setEditable(true);
    setLastValue(value);
    setLocalValue(value);
  };

  const onHandleSave = async () => {
    const response = await validationHelper(validationRule, localValue, name);
    // console.log(response, "In Editable");
    if (response === true) {
      // console.log(validationRule, "after saving");
      setEditable(false);
    } else {
      setEditable(true);
      setValid(false);
    }
    onChange(localValue);
    //
  };

  const onHandleCancel = () => {
    setLocalValue(lastValue);
    // onChange(lastValue);
    setEditable(false);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  }, [isEditable]);

  return (
    <div className={`flex w-[48.5%]  `}>
     
        {type === "DROPDOWN" && (
          <div className="flex flex-col gap-2  !w-full  ">
            <div className="flex gap-2 justify-between items-center ">
              <span className="text-[14px] leading-[21px] font-medium text-gray-900">
                {name}
              </span>
              <button type="button" onClick={handleAttributeDeletion}>
                <Icons.trashBin className="w-4 h-4 text-gray-300 hover:text-gray-500" />
              </button>
            </div>
            <Dropdown
              placeholder="Attribute Value"
              bg_color={"gray-50"}
              className={"!w-full"}
              // justify={'evenly'}
              border_color={"gray"}
              text_color={"gray"}
              title={"Attribute Type"}
              value={value || ""}
              items={validationRule?.enum}
              onSelect={(e) => {
                onChange(e);
              }}
              type="dropdown"
            />
          </div>
        )}
        {type === "BOOLEAN" && (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 justify-between items-center">
              <span className="text-[16px] leading-normal font-medium text-gray-900">
                {name}
              </span>
              <button type="button" onClick={handleAttributeDeletion}>
                <Icons.trashBin className="w-4 h-4 text-gray-300 hover:text-red-500" />
              </button>
            </div>
            <Dropdown
              placeholder="Value"
              bg_color={"gray-50"}
              // justify={'evenly'}
              border_color={"gray"}
              text_color={"gray"}
              title={"Attribute Type"}
              value={value === "1" || value === true ? "TRUE" : "FALSE"}
              items={["TRUE", "FALSE"]}
              onSelect={(e) => {
                if (e === "TRUE") {
                  setLocalValue(true);
                  onChange(true);
                }
                if (e === "FALSE") {
                  setLocalValue(false);
                  onChange(false);
                }
              }}
              type="dropdown"
            />
          </div>
        )}
        {type === "DATETIME" && (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 justify-between items-center">
              <span className="text-[16px] leading-normal font-medium text-gray-900">
                {name}
              </span>
              <button type="button" onClick={handleAttributeDeletion}>
                <Icons.trashBin className="w-4 h-4 text-gray-300 hover:text-red-500" />
              </button>
            </div>
            <ArbitNormalInput
              label="Value"
              value={localValue}
              onChange={(e) => setLocalValue(e)}
              placeholder="Enter Attribute Value"
              type="datetime-local"
              error={!valid}
              helperText="enter a valid date"
            />
          </div>
        )}
        {type === "TIME" && (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 justify-between items-center">
              <span className="text-[16px] leading-normal font-medium text-gray-900">
                {name}
              </span>
              <button type="button" onClick={handleAttributeDeletion}>
                <Icons.trashBin className="w-4 h-4 text-gray-300 hover:text-red-500" />
              </button>
            </div>
            <ArbitNormalInput
              label="Value"
              value={value}
              onChange={(e) => setLocalValue(e)}
              placeholder="Enter Attribute Value"
              type="time"
              error={!valid}
              helperText="enter a valid time"
            />
          </div>
        )}
        {type === "DATE" && (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 justify-between items-center">
              <span className="text-[16px] leading-normal font-medium text-gray-900">
                {name}
              </span>
              <button type="button" onClick={handleAttributeDeletion}>
                <Icons.trashBin className="w-4 h-4 text-gray-300 hover:text-red-500" />
              </button>
            </div>
            <ArbitNormalInput
              label="Value"
              value={value}
              onChange={setLocalValue}
              placeholder="Enter Attribute Value"
              type="date"
              error={!valid}
              helperText="enter a valid date"
            />
          </div>
        )}
        {type === "DOCUMENT" && (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 justify-between items-center">
              <span className="text-[16px] leading-normal font-medium text-gray-900">
                {name}
              </span>
              <button type="button" onClick={handleAttributeDeletion}>
                <Icons.trashBin className="w-4 h-4 text-gray-300 hover:text-red-500" />
              </button>
            </div>
            <ArbitNormalInput
              label="Value"
              // value={value}
              onChange={setLocalValue}
              placeholder="Enter Attribute Value"
              type="file"
              error={!valid}
              helperText="enter a valid file"
            />
          </div>
        )}
   
      {type === "TEXT" && (
        <DirtySelect
          isValue={value}
          id={id}
          tooltipPosition={"top"}
          attributeId={"39"}
          className={"w-full  border-none px-0 py-0"}
          title={name}
          placeholder={"ex. book"}
          handleValueClicked={onChange2}
        />
      )}
      {type === "FLOAT" && (
        <DirtySelect
          isValue={value}
          id={id}
          tooltipPosition={"top"}
          attributeId={"39"}
          className={"w-full  border-none px-0 py-0"}
          title={name}
          placeholder={"ex. book"}
          handleValueClicked={onChange2}
        />
      )}
      {type === "INTEGER" && (
        <DirtySelect
          isValue={value}
          id={id}
          tooltipPosition={"top"}
          attributeId={"39"}
          className={"w-full  border-none px-0 py-0"}
          title={name}
          placeholder={"ex. book"}
          handleValueClicked={onChange2}
        />
      )}
    </div>
  );
};

ArbitAttributeItem.defaultProps = {
  key: "Attribute",
  value: "This attribute is ...",
};

export default ArbitAttributeItem;
