import { createSlice } from "@reduxjs/toolkit";

import { assignPermissions, fetchPermissions } from "./thunk";

const initialState = {
  permissions: [],
  meta: {},
  loading: "idle",
  error: null,
  isLogin: false,
};

export const usersPermissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    // assignPermissionsUser : (state, action) => {
    //     console.log('State', state);
    //     return { ...state, channels: action.payload };

    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.permissions = action?.payload;
          state.links = action?.payload?.["links"];
          state.meta = action?.payload?.["meta"];
        }
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.error = action.error;
        }
      });
    // .addCase(assignPermissions.pending, (state, action) => {
    //     if (state.loading === 'idle') {
    //         state.loading = 'pending'
    //     }
    // }
    // )
    // .addCase(assignPermissions.fulfilled, (state, action) => {
    //     if (state.loading === 'pending') {
    //         state.loading = 'idle'
    //         state.permissions = action.payload
    //         state.links = action.payload["links"]
    //         state.meta = action.payload["meta"]
    //     }
    // }
    // )
    // .addCase(assignPermissions.rejected, (state, action) => {
    //     if (state.loading === 'pending') {
    //         state.loading = 'idle'
    //         state.error = action.error
    //     }
    // }
    // )
  },
});

// export const { assignPermissions } = usersPermissionsSlice.actions;

export default usersPermissionsSlice.reducer;

// export const { assignPermissions } = usersPermissionsSlice.actions;
export { fetchPermissions, assignPermissions };
// export default usersPermissionsSlice.reducer;
